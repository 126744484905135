import * as R from 'ramda';
import React from 'react';
import { Link } from 'react-router-dom';
import type { OutcropQualityParametersPartsFragment } from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import {
  KPCol,
  KPGrid,
} from '~/components/keyParameter/KeyParametersPanel/CombinedView';
import { otherWikiDetailRoute } from '~/paths';

type PropertyDisplayProps = {
  label: React.ReactNode;
  children: React.ReactNode;
  shouldRender: boolean;
};

function PropertyDisplay({
  label,
  shouldRender,
  children,
}: PropertyDisplayProps) {
  if (!shouldRender) return null;

  return (
    <KPGrid>
      <KPCol span={5}>
        <strong>{label}</strong>
      </KPCol>
      <KPCol span={7}>{children}</KPCol>
    </KPGrid>
  );
}

type Props = {
  qualityParameters: OutcropQualityParametersPartsFragment | null | undefined;
  noHeading?: boolean;
};
export function QualityParametersTableOutcrop({
  qualityParameters,
  noHeading = false,
}: Props) {
  if (!qualityParameters) {
    return null;
  }

  const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

  return (
    <>
      {!noHeading && <Heading level={3}>Quality Control Parameters</Heading>}

      <PropertyDisplay
        label="Structural complexity"
        shouldRender={!isNilOrEmpty(qualityParameters.structuralComplexity)}
      >
        <Link to={otherWikiDetailRoute(13)} target="_blank" className="link">
          {qualityParameters.structuralComplexity}
        </Link>
      </PropertyDisplay>

      <PropertyDisplay
        label="Exposure quality"
        shouldRender={!isNilOrEmpty(qualityParameters.exposureQuality)}
      >
        <Link to={otherWikiDetailRoute(9)} target="_blank" className="link">
          {qualityParameters.exposureQuality}
        </Link>
      </PropertyDisplay>

      <PropertyDisplay
        label="3D control"
        shouldRender={!isNilOrEmpty(qualityParameters.outcrop_3d_control)}
      >
        <Link to={otherWikiDetailRoute(6)} target="_blank" className="link">
          {qualityParameters.outcrop_3d_control}
        </Link>
      </PropertyDisplay>

      <PropertyDisplay
        label="Dataset scale"
        shouldRender={!isNilOrEmpty(qualityParameters.datasetScale)}
      >
        <Link to={otherWikiDetailRoute(8)} target="_blank" className="link">
          {qualityParameters.datasetScale}
        </Link>
      </PropertyDisplay>
    </>
  );
}
