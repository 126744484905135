import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import {
  bookmarkParts,
  bookmarkTargetParts,
  fileParts,
  pictureParts,
  publicCompanyParts,
  publicUserParts,
  reportItemParts,
  reportItemSummaryParts,
  reportParts,
} from '~/apollo/fragments';
import type {
  EditReportPageQuery,
  EditReportPageQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { companyReportsRoute, reportsRoute } from '~/paths';

const EDIT_REPORT_PAGE = gql`
  query EditReportPage($reportId: Int!) {
    reportList(reportId: $reportId) {
      ...reportParts
      itemSummary {
        ...reportItemSummaryParts
      }
      items {
        ...reportItemParts
        bookmark {
          ...bookmarkParts
          target {
            ...bookmarkTargetParts
          }
        }
      }
      company {
        ...publicCompanyParts
      }
      user {
        ...publicUserParts
      }
    }
  }

  ${reportParts}
  ${reportItemSummaryParts}
  ${publicUserParts}
  ${publicCompanyParts}
  ${reportItemParts}
  ${bookmarkParts}
  ${bookmarkTargetParts}
  ${pictureParts}
  ${fileParts}
`;

export type EditReportPageOutletContext = {
  report: EditReportPageQuery['reportList'][number];
  refetchQueries: PureQueryOptions[];
};

export default function EditReportPageWrapper() {
  const params = useParams();
  invariant(params.reportId);
  const reportId = parseInt(params.reportId);

  const { data, loading } = useQuery<
    EditReportPageQuery,
    EditReportPageQueryVariables
  >(EDIT_REPORT_PAGE, { variables: { reportId } });

  const refetchQueries: [PureQueryOptions<EditReportPageQueryVariables>] = [
    { query: EDIT_REPORT_PAGE, variables: { reportId } },
  ];

  const report = data?.reportList.find(r => r.id === reportId);
  const isCompany = !!report?.companyId;

  useBreadcrumb(
    'routes/my-safari/reports/$reportId/edit',
    report?.title ?? 'Report',
    null,
    [
      isCompany
        ? {
            routeId: 'routes/my-safari/reports/company',
            pathname: companyReportsRoute(),
            title: 'Company Reports',
          }
        : {
            routeId: 'routes/my-safari/reports',
            pathname: reportsRoute(),
            title: 'My Reports',
          },
    ],
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!report) return <NotFound />;

  const outletContext = {
    report,
    refetchQueries,
  } satisfies EditReportPageOutletContext;

  return <Outlet context={outletContext} />;
}

export function useEditReportOutletContext() {
  return useOutletContext<EditReportPageOutletContext>();
}
