import { faWarning, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { GoogleMapProps, MarkerProps } from '@react-google-maps/api';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { cn } from '~/utils/common';
import { useState } from 'react';
import type { ButtonProps } from 'react-daisyui';
import { Button, Card } from 'react-daisyui';
import type { FieldPicsOutcropListQuery } from '~/apollo/generated/schema';
import { FormLabel } from '~/components/common/FormikField/FormLabel';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { OutcropPicker } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/OutcropPicker';

export type BulkFieldPictureUploaderDefaultValues = {
  author: string;
  outcropId: string;
  location: google.maps.LatLngLiteral | null;
};

type Props = {
  values: BulkFieldPictureUploaderDefaultValues;
  onChange: React.Dispatch<
    React.SetStateAction<BulkFieldPictureUploaderDefaultValues>
  >;
  outcrops: FieldPicsOutcropListQuery['outcropList'];
  outcropId?: number;
};

export function DefaultValuesForm({
  values,
  onChange,
  outcrops,
  outcropId,
}: Props) {
  const [googleMap, setGoogleMap] = useState<google.maps.Map>();

  const [isExpanded, setIsExpanded] = useState(
    Boolean(values.author || values.outcropId),
  );

  function handleAuthorChange(event: React.ChangeEvent<HTMLInputElement>) {
    const author = event.target.value;
    onChange(prev => ({ ...prev, author }));
  }

  function handleOutcropChange(outcropId: string) {
    const center =
      outcrops.find(oc => oc.id === parseInt(outcropId))?.center ?? null;

    if (!values.location && center) {
      // If a location isn't already set, set it to the center of the outcrop
      // and pan the map to its location
      onChange(prevValues => ({
        ...prevValues,
        outcropId,
        location: new google.maps.LatLng(center).toJSON(),
      }));

      googleMap?.setCenter(center);
      googleMap?.setZoom(10);
    } else {
      onChange(prevValues => ({ ...prevValues, outcropId }));
    }
  }

  const handleMapLoaded: GoogleMapProps['onLoad'] = map => {
    map.setCenter({ lat: 30, lng: 0 });
    map.setZoom(2);
    setGoogleMap(map);
  };

  const handleMapClicked: GoogleMapProps['onClick'] = event => {
    onChange(prevValues => ({
      ...prevValues,
      location: event.latLng?.toJSON() ?? null,
    }));
  };

  const handleMarkerDragged: MarkerProps['onDragEnd'] = m => {
    onChange(prevValues => ({
      ...prevValues,
      location: m.latLng?.toJSON() ?? null,
    }));
  };

  const handleClearLocation: ButtonProps['onClick'] = () => {
    onChange(prevValues => ({ ...prevValues, location: null }));
  };

  return (
    <Card className="shadow-md">
      <Card.Body>
        <button
          type="button"
          onClick={() => setIsExpanded(prev => !prev)}
          className="flex justify-between items-center"
        >
          <span className="card-title">Default Values</span>
          <ExpandedIcon expanded={isExpanded} />
        </button>

        <div
          className={cn('space-y-4', {
            hidden: !isExpanded,
          })}
        >
          <div className="grid lg:grid-cols-2 gap-6">
            <div className="form-control">
              <FormLabel name="author" label="Author" />
              <input
                type="text"
                id="author"
                name="author"
                value={values.author}
                onChange={handleAuthorChange}
                className="input input-bordered w-full h-[38px] rounded-sm"
              />
            </div>

            <div className="form-control">
              <FormLabel name="outcropId" label="Outcrop" />
              <OutcropPicker
                value={values.outcropId}
                onChange={handleOutcropChange}
                outcrops={outcrops}
                disabled={!!outcropId}
              />
            </div>
          </div>

          <div className="space-y-2">
            <div className="form-control">
              <label className="label">
                <span className="label-text text-slate-600">
                  Default location
                  <div>
                    <FontAwesomeIcon
                      icon={faWarning}
                      className="text-warning mr-2"
                    />
                    Changing the default location will modify all pending
                    uploads. Pictures with embedded location EXIF data will not
                    be affected.
                  </div>
                </span>
              </label>

              <GoogleMap
                mapContainerClassName="w-full h-[400px]"
                onLoad={handleMapLoaded}
                onClick={handleMapClicked}
                options={{
                  mapTypeId: google.maps.MapTypeId.HYBRID,
                  fullscreenControl: false,
                  streetViewControl: false,
                }}
              >
                {values.location && (
                  <MarkerF
                    position={values.location}
                    onDragEnd={handleMarkerDragged}
                    draggable
                  />
                )}
              </GoogleMap>
            </div>

            {values.location && (
              <div className="text-center">
                <Button
                  type="button"
                  color="ghost"
                  size="sm"
                  onClick={handleClearLocation}
                  startIcon={<FontAwesomeIcon icon={faXmark} />}
                >
                  Clear default location
                </Button>
              </div>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
