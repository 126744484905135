import { gql, useQuery } from '@apollo/client';
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  OutcropGeoreferencesExportQuery,
  OutcropGeoreferencesExportQueryVariables,
} from '~/apollo/generated/schema';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { saveAs } from '~/utils/export';
import { createKmlDocument, createKmlFolder } from '~/utils/kml';

const OUTCROP_GEOREFERENCES_EXPORT = gql`
  query OutcropGeoreferencesExport($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts
      georeferences {
        ...georeferenceParts
      }

      studies {
        ...studyParts
        georeferences {
          ...georeferenceParts
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.studyParts}
  ${fragments.georeferenceParts}
`;

type Props = {
  outcropId: number;
};

export function OutcropGeoreferencesExport({ outcropId }: Props) {
  const { data, loading } = useQuery<
    OutcropGeoreferencesExportQuery,
    OutcropGeoreferencesExportQueryVariables
  >(OUTCROP_GEOREFERENCES_EXPORT, {
    variables: { outcropId },
  });

  async function handleClick() {
    const outcropList = data?.outcropList ?? [];
    const outcrop = outcropList.find(oc => oc.id === outcropId);
    invariant(outcrop, `OutcropId ${outcropId} could not be loaded.`);

    const studyFolders = outcrop.studies.map(study => {
      return createKmlFolder(
        study.name,
        `${study.name} Georeferences`,
        study.georeferences,
      );
    });
    const outcropStudiesFolders = [
      createKmlFolder('Studies', null, [], studyFolders),
    ];

    const outcropFolder = createKmlFolder(
      outcrop.name,
      `${outcrop.name} Georeferences`,
      outcrop.georeferences,
      outcropStudiesFolders,
    );

    const document = await createKmlDocument([outcropFolder]);
    saveAs(
      document,
      `${outcrop.name} georeferences with studies.kml`,
      'application/vnd;charset=utf-8',
    );
  }

  if (loading)
    return (
      <>
        <SpinnerPlaceholder>
          Loading outcrop and study georeferences, this may take a long time...
        </SpinnerPlaceholder>
      </>
    );

  return (
    <Button
      type="button"
      color="primary"
      onClick={handleClick}
      className="gap-1"
    >
      <FontAwesomeIcon icon={faCloudDownload} /> Save KML
    </Button>
  );
}
