import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type { HTMLProps, ReactNode } from 'react';
import { SpinnerIcon } from './SpinnerIcon';
import { cn } from '~/utils/common';

type Props = {
  show?: boolean;
  containerProps?: HTMLProps<HTMLDivElement>;
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>;
  children?: ReactNode;
};

export function SpinnerPlaceholder({
  show = true,
  containerProps,
  iconProps,
  children,
}: Props) {
  if (!show) return null;

  return (
    <div
      {...containerProps}
      className={cn('text-center', containerProps?.className)}
    >
      <SpinnerIcon className="text-4xl text-slate-400" {...iconProps} />
      {children && <div className="mt-2 text-sm">{children}</div>}
    </div>
  );
}
