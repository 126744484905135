import type {
  BasinInput,
  BasinPartsFragment,
  KeyParametersPartsFragment,
  OutcropAddtlFieldsEnumsQuery,
  OutcropInput,
  OutcropPartsFragment,
  OutcropQualityParametersPartsFragment,
  PaleoMapPartsFragment,
  QualityParametersInput,
  RegionPartsFragment,
  SourceAreaInput,
  SourceAreaPartsFragment,
  StudyPartsFragment,
  VomPartsFragment,
} from '~/apollo/generated/schema';
import { hasValue } from '~/utils/common';
import type { LithostratAgeFormValues } from '~/utils/modules/lithostratAge';

const toNumber = (value: string | number): number | null => {
  if (!hasValue(value)) return null;
  return typeof value === 'number' ? value : parseInt(value);
};

// Convert empty strings '' to null values
const valueOrNull = (value: string | null) => value || null;
// const filterEmpty = (values: string[] | null) => values?.filter(v => v !== '');

export interface OutcropFormValues {
  name: string;
  regionId: string | number;
  outcropType: string;
  geologyType: string[];
  dominantGeologyType: string;
  description: string;
  introduction: string;
  environments: string;
  stratigraphy: string;
  notablesAndAnalogues: string;
  location: string;
  safari: string;
  readyForApproval: boolean;
  approved: boolean;
  orientation: string;
  depositionalDipDirection: string;
  qualityParameters: OutcropQualityParametersFormValues;
  basin: BasinFormValues | null;
  sourceArea: SourceAreaFormValues | null;
  qcCompleted: boolean;
  outcropCategory: string;
  keyWords: string;
  diagenesisAndPetrophysicalProperties: string;
  structuralGeology: string;
  systemsTract: string[];
  shorelineTractory: string[];
  duneShape: string[];
  channelMorphology: string[];
  riverProfileLocation: string[];
  dominantLithology: string[];
  waterTemperature: string[];
  netToGross: string;
  diageneticProcess: string[];
  diageneticSetting: string[];
  diageneticGeometry: string[];
  lateralAggregation: string[];
  author: string;

  // structural fields
  tectonicSetting: string[];
  synSedimentaryDeformation: string[];
  faultRocksMembranes: string[];
  interactionNetwork: string[];
  reactivation1stPhase: string[];
  reactivation2ndPhase: string[];
  symmetryGeometry: string[];
  multipleFolds: string[];
  secondaryStructures: string[];
}

export interface OutcropQualityParametersFormValues {
  id: number | null;
  structuralComplexity: string;
  // Field is snake cased due to absinthe bug with camelcasing
  outcrop_3d_control: string;
  exposureQuality: string;
  datasetScale: string;
}

export type InitialOutcrop = OutcropPartsFragment & {
  basin?: BasinPartsFragment | null;
  sourceArea?: SourceAreaPartsFragment | null;
  qualityParameters?: OutcropQualityParametersPartsFragment | null;
};

export function initialOutcropFormValues(
  outcrop?: InitialOutcrop,
): OutcropFormValues {
  return {
    name: outcrop?.name ?? '',
    regionId: outcrop?.regionId ?? '',
    outcropType: outcrop?.outcropType ?? '',
    geologyType: outcrop?.geologyType ?? [],
    dominantGeologyType: outcrop?.dominantGeologyType ?? '',
    description: outcrop?.description ?? '',
    introduction: outcrop?.introduction ?? '',
    environments: outcrop?.environments ?? '',
    stratigraphy: outcrop?.stratigraphy ?? '',
    notablesAndAnalogues: outcrop?.notablesAndAnalogues ?? '',
    location: outcrop?.location ?? '',
    safari: outcrop?.safari ?? '',
    readyForApproval: outcrop?.readyForApproval ?? false,
    approved: outcrop?.approved ?? false,
    orientation: outcrop?.orientation ?? '',
    depositionalDipDirection: outcrop?.depositionalDipDirection ?? '',
    author: outcrop?.author ?? '',
    qualityParameters: defaultOutcropQualityParameters(
      outcrop?.qualityParameters,
    ),
    basin: outcrop?.basin ? defaultBasin(outcrop.basin) : null,
    sourceArea: defaultSourceArea(outcrop?.sourceArea),
    qcCompleted: outcrop?.qcCompleted ?? false,
    outcropCategory: outcrop?.outcropCategory ?? '',
    keyWords: outcrop?.keyWords ?? '',
    diagenesisAndPetrophysicalProperties:
      outcrop?.diagenesisAndPetrophysicalProperties ?? '',
    structuralGeology: outcrop?.structuralGeology ?? '',
    systemsTract: outcrop?.systemsTract ?? [],
    shorelineTractory: outcrop?.shorelineTractory ?? [],
    duneShape: outcrop?.duneShape ?? [],
    channelMorphology: outcrop?.channelMorphology ?? [],
    riverProfileLocation: outcrop?.riverProfileLocation ?? [],
    dominantLithology: outcrop?.dominantLithology ?? [],
    waterTemperature: outcrop?.waterTemperature ?? [],
    netToGross: outcrop?.netToGross ?? '',
    diageneticProcess: outcrop?.diageneticProcess ?? [],
    diageneticSetting: outcrop?.diageneticSetting ?? [],
    diageneticGeometry: outcrop?.diageneticGeometry ?? [],

    tectonicSetting: outcrop?.tectonicSetting ?? [],
    synSedimentaryDeformation: outcrop?.synSedimentaryDeformation ?? [],
    faultRocksMembranes: outcrop?.faultRocksMembranes ?? [],
    interactionNetwork: outcrop?.interactionNetwork ?? [],
    reactivation1stPhase: outcrop?.reactivation1stPhase ?? [],
    reactivation2ndPhase: outcrop?.reactivation2ndPhase ?? [],
    symmetryGeometry: outcrop?.symmetryGeometry ?? [],
    multipleFolds: outcrop?.multipleFolds ?? [],
    secondaryStructures: outcrop?.secondaryStructures ?? [],
    lateralAggregation: outcrop?.lateralAggregation ?? [],
  };
}

export function defaultOutcropQualityParameters(
  qps?: OutcropQualityParametersPartsFragment | null,
): OutcropQualityParametersFormValues {
  return {
    id: qps?.id ?? null,
    structuralComplexity: qps?.structuralComplexity ?? '',
    exposureQuality: qps?.exposureQuality ?? '',
    datasetScale: qps?.datasetScale ?? '',
    // Field is snake cased due to absinthe bug with camelcasing
    outcrop_3d_control: qps?.outcrop_3d_control ?? '',
  };
}

export function toQualityParametersInput(
  values: OutcropQualityParametersFormValues | null,
): QualityParametersInput {
  return {
    id: values?.id ?? null,
    structuralComplexity: values?.structuralComplexity?.trim() || null,
    outcrop_3d_control: values?.outcrop_3d_control?.trim() || null,
    exposureQuality: values?.exposureQuality?.trim() || null,
    datasetScale: values?.datasetScale?.trim() || null,
  };
}

export interface BasinFormValues {
  id: number | null;
  name: string;
  basinType: string;
  catchmentArea: string | number;
  climate: string;
  comments: string;
  description: string;
  terrestrialMarine: string;
  waterDepth: string;
  accommodationRegime: string;
}

export function defaultBasin(
  basin?: BasinPartsFragment | null,
): BasinFormValues {
  return {
    id: basin?.id ?? null,
    basinType: basin?.basinType ?? '',
    catchmentArea: basin?.catchmentArea ?? '',
    climate: basin?.climate ?? '',
    comments: basin?.comments ?? '',
    description: basin?.description ?? '',
    name: basin?.name ?? '',
    terrestrialMarine: basin?.terrestrialMarine ?? '',
    waterDepth: basin?.waterDepth ?? '',
    accommodationRegime: basin?.accommodationRegime ?? '',
  };
}

export function toBasinInput(values: BasinFormValues): BasinInput {
  const input: BasinInput = {
    id: values.id ?? null,
    name: values.name,
    basinType: values.basinType,
    climate: values.climate,
    catchmentArea: toNumber(values.catchmentArea),
    comments: valueOrNull(values.comments),
    description: valueOrNull(values.description),
    terrestrialMarine: valueOrNull(values.terrestrialMarine),
    waterDepth: valueOrNull(values.waterDepth),
    accommodationRegime: valueOrNull(values.accommodationRegime),
  };
  return input;
}

export function toSourceAreaInput(
  values: SourceAreaFormValues,
): SourceAreaInput {
  const input: SourceAreaInput = {
    id: values.id ?? null,
    name: values.name,
    description: valueOrNull(values.description),
    geography: valueOrNull(values.geography),
    structure: valueOrNull(values.structure),
    climate: valueOrNull(values.climate),
    comments: valueOrNull(values.comments),
  };
  input.distanceToSourceArea = toNumber(values.distanceToSourceArea);
  input.distanceToSourceAreaDesc = valueOrNull(values.distanceToSourceAreaDesc);
  input.distanceToShoreline = toNumber(values.distanceToShoreline);
  input.distanceToShorelineDesc = valueOrNull(values.distanceToShorelineDesc);
  input.distanceToShelfEdge = toNumber(values.distanceToShelfEdge);
  input.distanceToShelfEdgeDesc = valueOrNull(values.distanceToShelfEdgeDesc);

  return input;
}

export function toOutcropInput(values: OutcropFormValues): OutcropInput {
  const input: OutcropInput = {
    name: values.name,
    regionId: ~~values.regionId,
    outcropType: valueOrNull(values.outcropType),
    geologyType: values.geologyType,
    dominantGeologyType: valueOrNull(values.dominantGeologyType),
    description: valueOrNull(values.description),
    introduction: valueOrNull(values.introduction),
    environments: valueOrNull(values.environments),
    stratigraphy: valueOrNull(values.stratigraphy),
    notablesAndAnalogues: valueOrNull(values.notablesAndAnalogues),
    location: valueOrNull(values.location),
    safari: valueOrNull(values.safari),
    readyForApproval: values.readyForApproval,
    approved: values.approved,
    orientation: valueOrNull(values.orientation),
    depositionalDipDirection: valueOrNull(values.depositionalDipDirection),
    qcCompleted: values.qcCompleted,
    outcropCategory: valueOrNull(values.outcropCategory),
    keyWords: valueOrNull(values.keyWords),
    author: valueOrNull(values.author),
    diagenesisAndPetrophysicalProperties: valueOrNull(
      values.diagenesisAndPetrophysicalProperties,
    ),
    structuralGeology: valueOrNull(values.structuralGeology),
    systemsTract: values.systemsTract,
    shorelineTractory: values.shorelineTractory,
    duneShape: values.duneShape,
    channelMorphology: values.channelMorphology,
    riverProfileLocation: values.riverProfileLocation,
    dominantLithology: values.dominantLithology,
    waterTemperature: values.waterTemperature,
    netToGross: valueOrNull(values.netToGross),
    diageneticProcess: values.diageneticProcess,
    diageneticSetting: values.diageneticSetting,
    diageneticGeometry: values.diageneticGeometry,

    tectonicSetting: values.tectonicSetting,
    synSedimentaryDeformation: values.synSedimentaryDeformation,
    faultRocksMembranes: values.faultRocksMembranes,
    interactionNetwork: values.interactionNetwork,
    reactivation1stPhase: values.reactivation1stPhase,
    reactivation2ndPhase: values.reactivation2ndPhase,
    symmetryGeometry: values.symmetryGeometry,
    multipleFolds: values.multipleFolds,
    secondaryStructures: values.secondaryStructures,
    lateralAggregation: values.lateralAggregation,
  };

  input.basin = toBasinInput(values.basin ?? defaultBasin());

  input.sourceArea = toSourceAreaInput(
    values.sourceArea ?? defaultSourceArea(),
  );
  input.qualityParameters = toQualityParametersInput(
    values.qualityParameters ?? defaultOutcropQualityParameters(),
  );

  return input;
}

export interface SourceAreaFormValues {
  id: number | null;
  name: string;
  description: string;
  geography: string;
  structure: string;
  climate: string;
  distanceToSourceArea: string | number;
  distanceToSourceAreaDesc: string;
  distanceToShoreline: string | number;
  distanceToShorelineDesc: string;
  distanceToShelfEdge: string | number;
  distanceToShelfEdgeDesc: string;
  comments: string;
}

export function defaultSourceArea(
  sa?: SourceAreaPartsFragment | null,
): SourceAreaFormValues {
  return {
    id: sa?.id ?? null,
    name: sa?.name ?? '',
    description: sa?.description ?? '',
    geography: sa?.geography ?? '',
    structure: sa?.structure ?? '',
    climate: sa?.climate ?? '',
    distanceToSourceArea: sa?.distanceToSourceArea ?? '',
    distanceToSourceAreaDesc: sa?.distanceToSourceAreaDesc ?? '',
    distanceToShoreline: sa?.distanceToShoreline ?? '',
    distanceToShorelineDesc: sa?.distanceToShorelineDesc ?? '',
    distanceToShelfEdge: sa?.distanceToShelfEdge ?? '',
    distanceToShelfEdgeDesc: sa?.distanceToShelfEdgeDesc ?? '',
    comments: sa?.comments ?? '',
  };
}

export type OutcropGeologicalAgeFormValues = {
  startAge: LithostratAgeFormValues;
  endAge: LithostratAgeFormValues;
};

/** Get the label of the given HTML field based on the outcrop's outcropCategory */
export function fieldLabel(
  field: keyof OutcropPartsFragment,
  ocCategory: string | null = 'outcrop',
) {
  if (field === 'description') {
    return 'Description';
  }
  if (field === 'introduction') {
    return 'Introduction';
  }

  if (field === 'environments') {
    switch (ocCategory) {
      case 'modern':
        return 'Sedimentology and Depositional Environment';
      default:
        return 'Lithology and Depositional Environment';
    }
  }

  if (field === 'stratigraphy') {
    switch (ocCategory) {
      case 'production':
        return 'Structural Complexity';
      default:
        return 'Litho- and Sequence Stratigraphy';
    }
  }

  if (field === 'location') {
    switch (ocCategory) {
      case 'seismic':
        return 'Dataset';
      case 'production':
        return 'Reservoir Engineering Aspects';
      default:
        return 'Location and Accessibility';
    }
  }

  if (field === 'notablesAndAnalogues') {
    switch (ocCategory) {
      case 'production':
        return 'Production Aspects';
      default:
        return 'Analogues and Notable Aspects';
    }
  }

  if (field === 'safari') {
    switch (ocCategory) {
      case 'production':
        return 'Key References and Links';
      default:
        return 'Key References';
    }
  }

  if (field === 'keyWords') return 'Keywords';

  if (field === 'diagenesisAndPetrophysicalProperties')
    return 'Diagenesis and Petrophysical Properties';

  if (field === 'structuralGeology') return 'Structural Geology';

  throw new Error(`Field ${field} does not have a defined label`);
}

export type OutcropApprovalState = 'draft' | 'ready for approval' | 'approved';
export function outcropApprovalState(
  oc: Pick<OutcropPartsFragment, 'approved' | 'readyForApproval'>,
): OutcropApprovalState {
  if (oc.approved) return 'approved';
  if (oc.readyForApproval) return 'ready for approval';
  return 'draft';
}

type OutcropQueryResult = Pick<
  OutcropPartsFragment,
  | 'id'
  | 'name'
  | 'geologyType'
  | 'approved'
  | 'readyForApproval'
  | 'qcCompleted'
> & {
  region: Pick<RegionPartsFragment, 'id' | 'name' | 'location'>;
  paleoMaps: Pick<PaleoMapPartsFragment, 'id'>[];
  studies: Pick<StudyPartsFragment, 'id'>[];
  virtualOutcropModels: Pick<VomPartsFragment, 'id'>[];
};
export type SortableOutcrop = OutcropQueryResult & {
  numStudies: number;
  hasPaleomaps: boolean;
  numVoms: number;
  approvalState: OutcropApprovalState;
};

export function toSortableOutcrop(oc: OutcropQueryResult): SortableOutcrop {
  const hasPaleomaps = !!oc.paleoMaps && oc.paleoMaps.length > 0;
  const numStudies = oc.studies?.length || 0;
  const numVoms = oc.virtualOutcropModels?.length || 0;
  return {
    ...oc,
    numStudies,
    hasPaleomaps,
    numVoms,
    approvalState: outcropApprovalState(oc),
  };
}

export type OCAdditionalFieldKey = keyof Omit<
  OutcropAddtlFieldsEnumsQuery,
  '__typename'
>;

export type OCAddtlField = {
  name: OCAdditionalFieldKey;
  multiple: boolean;
  label?: string;
  wikiId?: number;
};

export const clasticFields = (entity: 'outcrop' | 'ae'): OCAddtlField[] => {
  const fields: OCAddtlField[] = [];

  if (entity === 'outcrop') {
    fields.push({ name: 'netToGross', multiple: false, wikiId: 71 });
  }

  const baseFields: OCAddtlField[] = [
    { name: 'systemsTract', multiple: true, wikiId: 39 },
    { name: 'shorelineTractory', multiple: true, wikiId: 37 },
    { name: 'duneShape', multiple: true, wikiId: 30 },
    { name: 'channelMorphology', multiple: true, wikiId: 25 },
    { name: 'riverProfileLocation', multiple: true, wikiId: 35 },
    { name: 'dominantLithology', multiple: true, wikiId: undefined },
    { name: 'lateralAggregation', multiple: true, wikiId: 82 },
  ];
  baseFields.forEach(f => fields.push(f));

  return fields;
};

export const carbonateFields = (): OCAddtlField[] => [
  {
    name: 'waterTemperature',
    multiple: true,
    label: 'Water temp.',
    wikiId: 70,
  },
  { name: 'diageneticProcess', multiple: true, wikiId: 74 },
  { name: 'diageneticSetting', multiple: true, wikiId: 72 },
  { name: 'diageneticGeometry', multiple: true, wikiId: 73 },
  { name: 'systemsTract', multiple: true, wikiId: 39 },
  { name: 'dominantLithology', multiple: true, wikiId: undefined },
];

export type KPFields = Pick<
  KeyParametersPartsFragment,
  | 'grossDepositionalEnvironment'
  | 'depositionalEnvironment'
  | 'depositionalSubEnvironment'
  | 'architecturalElement'
>;

export const structuralFields = (kps: KPFields[]): OCAddtlField[] => {
  const gdes = kps.map(kp => kp.grossDepositionalEnvironment);
  const hasClassification = (c: string) => gdes.find(gde => gde === c);

  const enumFields: OCAddtlField[] = [
    { name: 'tectonicSetting', multiple: true, wikiId: 72 },
    {
      name: 'synSedimentaryDeformation',
      multiple: true,
      label: 'Syn-sedimentary Deformation',
      wikiId: 73,
    },
    { name: 'dominantLithology', multiple: true },
  ];

  const appendFields = (fieldsToAppend: OCAddtlField[]) =>
    fieldsToAppend.forEach(f => enumFields.push(f));

  if (hasClassification('Faults/Fractures')) {
    appendFields([
      {
        name: 'faultRocksMembranes',
        multiple: true,
        label: 'Fault Rocks/Membranes',
        wikiId: 74,
      },
      {
        name: 'interactionNetwork',
        multiple: true,
        label: 'Interaction/Network',
        wikiId: 75,
      },
      {
        name: 'reactivation1stPhase',
        multiple: true,
        label: 'Reactivation 1st Phase',
        wikiId: 76,
      },
      {
        name: 'reactivation2ndPhase',
        multiple: true,
        label: 'Reactivation 2nd Phase',
        wikiId: 76,
      },
    ]);
  }

  if (hasClassification('Fold') || hasClassification('Fault-related fold')) {
    appendFields([
      {
        name: 'symmetryGeometry',
        multiple: true,
        label: 'Symmetry/Geometry',
        wikiId: 77,
      },
      { name: 'multipleFolds', multiple: true, wikiId: 78 },
      {
        name: 'secondaryStructures',
        multiple: true,
        label: 'Secondary Structures Related To Folds',
        wikiId: 79,
      },
    ]);
  }

  return enumFields;
};
