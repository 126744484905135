import { Field, useFormikContext } from 'formik';
import * as R from 'ramda';
import type { HTMLProps } from 'react';
import React, { useEffect, useState } from 'react';
import { Badge, Checkbox } from 'react-daisyui';
import { FormikField } from '~/components/common/FormikField';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { Panel } from '~/components/common/Panel';
import { Tooltip } from '~/components/common/Tooltip';
import { sortByArray } from '~/utils/common';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';

type FilterItemTextProps = HTMLProps<HTMLSpanElement> & {
  value: string;
  count?: number | null;
  expanded?: boolean | null;
  onExpand?: (event: React.MouseEvent) => void;
  renderValue?: (value: string) => string;
};

export const FilterItemText: React.FC<FilterItemTextProps> = ({
  value,
  expanded,
  count,
  onExpand,
  renderValue,
  ...spanProps
}) => {
  return (
    <>
      <span className="whitespace-normal" {...spanProps}>
        {renderValue ? renderValue(value) : value}
      </span>
      &#8205;
      {!R.isNil(count) && (
        <Tooltip
          message={`${count} Architectural Element${count === 1 ? '' : 's'}`}
          position="top"
        >
          <Badge color="ghost" className="ml-1">
            {count}
          </Badge>
        </Tooltip>
      )}
      &#8205;
      {!R.isNil(expanded) && (
        <ExpandedIcon
          expanded={expanded}
          onClick={onExpand}
          className="ml-1 cursor-pointer text-base"
        />
      )}
    </>
  );
};

type Props = {
  title: string;
  fieldName: keyof DataSearchFormValues;
  options: { name: string; count: number }[];
  values: string[];
  defaultCollapsed?: boolean;
  sortOrder?: string[];
};
export type DataSearchFilterProps = Props;

export function DataSearchFilter({
  title,
  fieldName,
  options,
  values,
  defaultCollapsed = false,
  sortOrder,
}: Props) {
  const { setFieldValue } = useFormikContext<DataSearchFormValues>();

  const [panelCollapsed, setPanelCollapsed] = useState(defaultCollapsed);

  const togglePanelCollapsed = () => setPanelCollapsed(prev => !prev);

  const filteredAndSortedOptions = React.useMemo(() => {
    const filtered = options.filter(opt => opt.count === null || opt.count > 0);

    if (!sortOrder) return filtered;

    return sortByArray(filtered, sortOrder, x => x.name);
  }, [options, sortOrder]);

  const totalCount = filteredAndSortedOptions.length;

  useEffect(() => {
    if (totalCount === 0) {
      setPanelCollapsed(true);
    }
  }, [totalCount]);

  useEffect(() => {
    setPanelCollapsed(defaultCollapsed);
  }, [defaultCollapsed]);

  const isAllSelected = filteredAndSortedOptions.reduce((acc, cur) => {
    if (!acc) return false;
    return values.includes(cur.name);
  }, true);

  const toggleAllSelected = () => {
    if (isAllSelected) {
      setFieldValue(fieldName, []);
    } else {
      setFieldValue(
        fieldName,
        filteredAndSortedOptions.map(opt => opt.name),
      );
    }
  };

  const hasOptions = filteredAndSortedOptions.length > 0;

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>
          <button
            type="button"
            onClick={togglePanelCollapsed}
            className="w-full h-full flex justify-between items-center text-base p-0 text-left"
            title={`Toggle ${title} options`}
          >
            <span>
              {title} <Badge color="ghost">{totalCount}</Badge>
            </span>
            {hasOptions && (
              <span>
                <ExpandedIcon expanded={!panelCollapsed} />
              </span>
            )}
          </button>
        </Panel.Title>
      </Panel.Heading>

      {!panelCollapsed && hasOptions && (
        <Panel.Body>
          <div className="form-control">
            <label className="label cursor-pointer justify-start gap-2 border-b border-b-slate-200 mb-1">
              <Checkbox
                checked={isAllSelected}
                onChange={toggleAllSelected}
                value="yes-please"
                size="sm"
              />
              <span className="label-text">Select All</span>
            </label>
          </div>

          {filteredAndSortedOptions.map(opt => (
            <Field
              key={opt.name}
              name={fieldName}
              label={<FilterItemText value={opt.name} count={opt.count} />}
              component={FormikField}
              type="checkbox"
              value={opt.name}
              size="sm"
            />
          ))}
        </Panel.Body>
      )}
    </Panel>
  );
}
