import { gql, useMutation, type PureQueryOptions } from '@apollo/client';
import { faBan, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  DeleteSupplementalDocumentMutation,
  DeleteSupplementalDocumentMutationVariables,
  FilePartsFragment,
  SupplementalDocumentPartsFragment,
} from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { SortTrigger } from '~/components/common/SortTrigger';
import { UpdateSuppDocForm } from '~/components/upload/supportingObject/supplementalDocs/UpdateSuppDocForm';
import { useRefetchQueries } from '~/hooks/apollo';
import { useSortFilter } from '~/hooks/data';
import { fileSizeText } from '~/utils/text';

type SupplementalDocument = SupplementalDocumentPartsFragment & {
  file: FilePartsFragment;
};

export function SuppDocList({
  docs,
  refetchQueries,
}: {
  docs: SupplementalDocument[];
  refetchQueries: PureQueryOptions[];
}) {
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    docs ?? [],
    'file.filename',
    'file.filename',
    'suppDocs',
  );

  return (
    <table className="table table-compact w-full">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="id" sortIndicatorProps={siProps}>
              ID
            </SortTrigger>
          </th>
          <th>
            <SortTrigger colName="file.name" sortIndicatorProps={siProps}>
              Filename
            </SortTrigger>
          </th>
          <th>Note</th>
          <th>
            <SortTrigger
              colName="file.contentType"
              sortIndicatorProps={siProps}
              filterable
            >
              Type
            </SortTrigger>
          </th>
          <th>
            <SortTrigger colName="file.size" sortIndicatorProps={siProps}>
              Size
            </SortTrigger>
          </th>
          <th />
        </tr>
      </thead>

      <tbody>
        <NoItemsRow colSpan={6} show={!items.length} />
        {items.map(doc => (
          <ListItem key={doc.id} doc={doc} refetchQueries={refetchQueries} />
        ))}
      </tbody>
    </table>
  );
}

const DELETE_SUPPLEMENTAL_DOCUMENT = gql`
  mutation DeleteSupplementalDocument($id: Int!) {
    deleteSupplementalDocument(id: $id)
  }
`;

function ListItem({
  doc,
  refetchQueries,
}: {
  doc: SupplementalDocument;
  refetchQueries: PureQueryOptions[];
}) {
  const [refetch] = useRefetchQueries(refetchQueries);
  const [shouldDownload, setShouldDownload] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [deleteSuppDoc, { loading }] = useMutation<
    DeleteSupplementalDocumentMutation,
    DeleteSupplementalDocumentMutationVariables
  >(DELETE_SUPPLEMENTAL_DOCUMENT, {
    variables: { id: doc.id },
    refetchQueries,
  });

  useEffect(() => {
    if (shouldDownload) {
      setShouldDownload(false);
      window.open(doc.file.signedUrl, '_blank');
    }
  }, [shouldDownload, doc.file.signedUrl]);

  async function handleDownload() {
    setShouldDownload(true);
    await refetch();
  }

  async function handleDelete() {
    const msg = 'Are you sure you want to delete this supplemental document?';
    if (!window.confirm(msg)) {
      return;
    }

    try {
      await deleteSuppDoc();
      toast.success('Supplemental document deleted.');
    } catch (err) {
      console.log('Error deleting supplemental document', err);
      toast.error(
        'There was a problem deleting the supplemental document; it may have already been deleted. Please reload the page and try again.',
      );
    }
  }

  const deleteButton = (
    <Button
      type="button"
      onClick={handleDelete}
      color="ghost"
      size="xs"
      startIcon={<FontAwesomeIcon icon={faTrash} />}
      loading={loading}
    />
  );

  if (isEditing) {
    return (
      <tr>
        <td colSpan={6} className="border border-slate-300 p-2 space-y-4">
          <div className="flex justify-center items-start w-full gap-6">
            <Heading level={4} className="grow break-all">
              {doc.file.name}
            </Heading>
            <div className="space-x-1 shrink-0 text-right">
              <Button
                type="button"
                onClick={() => setIsEditing(false)}
                color="ghost"
                size="xs"
                startIcon={<FontAwesomeIcon icon={faBan} />}
              >
                Cancel
              </Button>
              {deleteButton}
            </div>
          </div>

          <UpdateSuppDocForm
            supplementalDocument={doc}
            onUpdateSuccess={() => setIsEditing(false)}
          />
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td>{doc.id}</td>
      <td>
        <Button type="button" onClick={handleDownload} variant="link" size="sm">
          <div className="break-all">{doc.file.name}</div>
        </Button>
      </td>
      <td>{doc.note}</td>
      <td>{doc.file.contentType}</td>
      <td>{doc.file.size && fileSizeText(doc.file.size)}</td>
      <td className="text-right space-x-1">
        <Button
          type="button"
          onClick={() => setIsEditing(true)}
          color="ghost"
          size="xs"
          startIcon={<FontAwesomeIcon icon={faPencil} />}
        />
        {deleteButton}
      </td>
    </tr>
  );
}
