import { Outlet } from 'react-router-dom';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { WikiRouteNavigation } from '~/components/wiki/WikiNavigation';

export default function WikiRoute() {
  useBreadcrumb('routes/wiki', 'Knowledge Base');

  return (
    <div className="grid md:grid-cols-4 gap-6">
      <div className="pt-4">
        <WikiRouteNavigation />
      </div>
      <div className="md:col-span-3">
        <Outlet />
      </div>
    </div>
  );
}
