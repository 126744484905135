import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';

import { CREATE_LITHOSTRAT_OUTCROP_LINK } from '~/apollo/operations/lithostrat';
import type {
  CreateLithostratOutcropLinkMutation,
  CreateLithostratOutcropLinkMutationVariables,
} from '~/apollo/generated/schema';
import { LithostratLinkTargetType } from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import { yup } from '~/utils/validation';
import { LithostratLinkFormFields } from './LinkFormFields';

export type LithostratOutcropLinkFormValues = {
  lithostratTypeId: string;
  targetType: LithostratLinkTargetType;
  targetId: string;
};

type Props = {
  outcropId: number;
  refetchQueries: PureQueryOptions[];
  children: (showModal: () => void) => React.ReactNode;
};

export function CreateLithostratLinkModal({
  outcropId,
  children,
  refetchQueries,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [createLink, { loading, error }] = useMutation<
    CreateLithostratOutcropLinkMutation,
    CreateLithostratOutcropLinkMutationVariables
  >(CREATE_LITHOSTRAT_OUTCROP_LINK, {
    refetchQueries,
  });

  async function handleSubmit(
    values: LithostratOutcropLinkFormValues,
    helpers: FormikHelpers<LithostratOutcropLinkFormValues>,
  ) {
    const lithostratOutcropLink = {
      parentId: outcropId,
      targetType: values.targetType,
      targetId: parseInt(values.targetId),
    };

    try {
      await createLink({ variables: { lithostratOutcropLink } });
      toast.success('Link created successfully.');
      hideModal();
      helpers.resetForm();
    } catch (err) {
      console.log('Error creating link', err);
      toast.error(
        'There was a problem creating the link. Check the data entered and try again.',
      );
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            lithostratTypeId: '',
            targetType: LithostratLinkTargetType.Formation,
            targetId: '',
          }}
          validationSchema={yup.object({
            targetType: yup
              .string()
              .oneOf(Object.values(LithostratLinkTargetType))
              .required(),
            targetId: yup.number().integer().required(),
          })}
        >
          <Form>
            <Modal.Body heading="Create Lithostrat Link">
              <div className="space-y-4">
                <LithostratLinkFormFields />
                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" loading={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
