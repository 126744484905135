import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FieldProps } from 'formik';
import { Field } from 'formik';
import type { PolygonVertexInput } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import { RequiredField } from '~/components/common/icons/RequiredField';
import { DiagramLinkTargetField } from '~/components/upload/diagram/DiagramEditor/DiagramLinkTargetField';
import type { DiagramAreaFormValues } from '~/utils/modules/diagram';
import { linkTargetTypes } from '~/utils/modules/diagram';
import { snakeCapsToHuman } from '~/utils/text';

export function DiagramAreaFormFields() {
  return (
    <div className="space-y-2">
      <Field name="label" label="Label" component={FormikField} required />

      <Field
        name="linkTargetType"
        label="Link Type"
        component={FormikField}
        type="select"
        required
        options={linkTargetTypes.map(t => ({
          value: t,
          label: snakeCapsToHuman(t),
        }))}
      />

      <div>
        <DiagramLinkTargetField />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">
            Polygon
            <RequiredField />
          </span>
        </label>
        <div className="text-center p-4 border border-slate-300 rounded-lg">
          <Field
            name="path"
            label="Area"
            component={FormikField}
            type={({
              field,
            }: FieldProps<PolygonVertexInput[], DiagramAreaFormValues>) => {
              const numVertices = field.value.length;
              if (numVertices === 0) {
                return <p>Click on the image to start drawing a polygon.</p>;
              } else if (numVertices > 0 && numVertices < 3) {
                return <p>Set at least three points to continue.</p>;
              } else {
                return (
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="text-success" />{' '}
                    Coordinates set. Continue adding as many points as needed.
                  </p>
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
