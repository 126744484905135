import { GoogleMap } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import type { GeoreferenceImportInput } from '~/utils/geojson';
import {
  createBounds,
  createMarker,
  createPolygon,
  createPolyline,
  isMarkerType,
  isPolygonType,
  isPolylineType,
} from '~/utils/georeference';

type Props = {
  georeferences: GeoreferenceImportInput[];
};

export function ImportPreviewMap({ georeferences }: Props) {
  const [map, setMap] = useState<google.maps.Map>();

  const pointGeorefs = georeferences.filter(g => isMarkerType(g.dataType));
  const polygonGeorefs = georeferences.filter(g => isPolygonType(g.dataType));
  const polylineGeorefs = georeferences.filter(g => isPolylineType(g.dataType));

  // Call fitBounds when the map finishes mounting
  function handleMapLoad(gMap: google.maps.Map) {
    setMap(gMap);
    const bounds = createBounds(georeferences);
    gMap.fitBounds(bounds);
  }

  // Call fitBounds on map when georeferences are removed from the list
  useEffect(() => {
    if (map) {
      const bounds = createBounds(georeferences);
      map.fitBounds(bounds);
    }
  }, [georeferences.length]);

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '400px' }}
      onLoad={handleMapLoad}
    >
      {pointGeorefs.map(g => createMarker(g, { key: g.tempId }))}
      {polygonGeorefs.map(g => createPolygon(g, { key: g.tempId }))}
      {polylineGeorefs.map(g => createPolyline(g, { key: g.tempId }))}
    </GoogleMap>
  );
}
