import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';

import * as fragments from '~/apollo/fragments';
import type {
  UploadStudyUpdateLithostratigraphyTabQuery,
  UploadStudyUpdateLithostratigraphyTabQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreateLithostratStudyLinkModal } from '~/components/upload/study/lithostratLink/CreateLinkModal';
import { StudyLithostratLinksTable } from '~/components/upload/study/lithostratLink/StudyLithostratLinksTable';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

const LITHOSTRATIGRAPHY_TAB = gql`
  query UploadStudyUpdateLithostratigraphyTab($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      lithostratStudyLinks {
        ...lithostratStudyLinkParts
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.lithostratStudyLinkParts}
`;

export default function UpdateStudyLithostratigraphyRoute() {
  const ctx = useUpdateStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    UploadStudyUpdateLithostratigraphyTabQuery,
    UploadStudyUpdateLithostratigraphyTabQueryVariables
  >(LITHOSTRATIGRAPHY_TAB, {
    variables: { studyId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadStudyUpdateLithostratigraphyTabQueryVariables>,
  ] = [
    {
      query: LITHOSTRATIGRAPHY_TAB,
      variables: { studyId },
    },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const lithostratLinks = study?.lithostratStudyLinks ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>Lithostrat Links</Panel.Title>
        <CreateLithostratStudyLinkModal
          studyId={studyId}
          refetchQueries={refetchQueries}
        >
          {showModal => (
            <Button
              type="button"
              color="primary"
              size="xs"
              onClick={showModal}
              startIcon={<FontAwesomeIcon icon={faLink} />}
            >
              Create Link
            </Button>
          )}
        </CreateLithostratStudyLinkModal>
      </Panel.Heading>
      <Panel.Body>
        {!loading && (
          <StudyLithostratLinksTable
            lithostratLinks={lithostratLinks}
            refetchQueries={refetchQueries}
          />
        )}
      </Panel.Body>
    </Panel>
  );
}
