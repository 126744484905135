import { gql, useQuery } from '@apollo/client';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { studyParts } from '~/apollo/fragments';
import type {
  UploadStudyUpdatePageQuery,
  UploadStudyUpdatePageQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import * as routes from '~/paths';
import { UploadStudyNavigation } from './$studyId/__UploadStudyNavigation';

const UPLOAD_STUDY_UPDATE_PAGE = gql`
  query UploadStudyUpdatePage($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
    }
  }
  ${studyParts}
`;

type UploadStudyPageUpdateOutletContext = {
  study: UploadStudyUpdatePageQuery['studyList'][number];
};

export default function UploadStudyUpdatePage() {
  // Study names are really long now, let's not put it in the breadcrumb
  useBreadcrumb('routes/upload/model/study/$studyId', 'Update Study');

  const params = useParams<{ studyId: string }>();
  invariant(params.studyId, 'studyId param required');
  const studyId = parseInt(params.studyId);

  const { data, loading } = useQuery<
    UploadStudyUpdatePageQuery,
    UploadStudyUpdatePageQueryVariables
  >(UPLOAD_STUDY_UPDATE_PAGE, {
    variables: { studyId },
  });

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  const outletContext: UploadStudyPageUpdateOutletContext = { study };

  return (
    <>
      <div className="float-right">
        {/* {study && <MergeStudyButton study={study} />} */}

        <Link
          to={routes.studyRoute(studyId)}
          className="btn btn-primary btn-sm gap-2"
          target="_blank"
        >
          View Study <FontAwesomeIcon icon={faExternalLink} />
        </Link>
      </div>

      <PageHeading>
        Update Study
        {study && (
          <div className="text-muted font-normal text-base">{study.name}</div>
        )}
      </PageHeading>

      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-2">
          <UploadStudyNavigation studyId={study.id} />
        </div>

        <div className="col-span-12 lg:col-span-10">
          <Outlet context={outletContext} />
        </div>
      </div>
    </>
  );
}

export function useUpdateStudyOutletContext() {
  return useOutletContext<UploadStudyPageUpdateOutletContext>();
}
