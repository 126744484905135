import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { HTMLProps } from 'react';

type Props = Omit<FontAwesomeIconProps, 'icon'> & {
  containerProps?: HTMLProps<HTMLSpanElement>;
};

// Wrapping the icon in a span is needed to support things like tooltips
export function InfoIcon({ containerProps, ...props }: Props) {
  return (
    <span {...containerProps}>
      <FontAwesomeIcon
        className="text-warning cursor-help"
        icon={faQuestionCircle}
        {...props}
      />
    </span>
  );
}
