import React, { createContext, Fragment, useState } from 'react';

const defaultMainClass = ["container", "mx-auto", "max-w-7xl", "mt-3"];

type BodyContextValue = {
  bodyClass: string[];
  addClassName: (name: string) => void;
  removeClassName: (name: string) => void;
  toggleClassName: (name: string) => void;
};

export const BodyContext = createContext<BodyContextValue>({
  bodyClass: defaultMainClass,
  addClassName: (name: string) => {},
  removeClassName: (name: string) => {},
  toggleClassName: (name: string) => {}
});

type BodyProviderProps = {
  children: React.ReactNode;
};

export function BodyProvider({children}: BodyProviderProps) {
  const [bodyClass, setBodyClass] = useState(defaultMainClass);

  const addClassName = (name: string) => {
    if (!bodyClass.includes(name)) {
      setBodyClass((prev) => [...prev, name]);
    }
  };

  const removeClassName = (name: string) => {
    setBodyClass((prev) => prev.filter((item) => item !== name));
  }

  const toggleClassName = (name: string) => {
    if (bodyClass.includes(name)) {
      removeClassName(name);
    } else {
      addClassName(name);
    }
  }

  const classNameValue = {
    bodyClass: bodyClass,
    addClassName: addClassName,
    removeClassName: removeClassName,
    toggleClassName: toggleClassName
  }

  return (
    <BodyContext.Provider value={classNameValue}>
      <Fragment>{children}</Fragment>
    </BodyContext.Provider>
  );
}

export default BodyContext;