import { cn } from '~/utils/common';
import type { ComponentPropsWithoutRef } from 'react';

export type WellProps = {} & ComponentPropsWithoutRef<'div'>;

export function Well({ className, children, ...props }: WellProps) {
  return (
    <div
      className={cn('bg-slate-100 border border-slate-200 p-4', className)}
      {...props}
    >
      {children}
    </div>
  );
}
