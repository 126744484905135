import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';
import * as R from 'ramda';

import * as fragments from '~/apollo/fragments';
import type {
  StudyPicturesRouteQuery,
  StudyPicturesRouteQueryVariables,
} from '~/apollo/generated/schema';
import {
  BookmarkParentType,
  BookmarkTargetType,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { PictureThumbnail } from '~/components/common/PictureThumbnail';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useStudyOutletContext } from '~/routes/study/$studyId';
import { soBookmarkPath } from '~/utils/modules/bookmark';

const STUDY_PICTURES_ROUTE = gql`
  query StudyPicturesRoute($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      pictures {
        ...pictureParts
        file {
          ...fileParts
          signedUrl
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function StudyPicturesRoute() {
  const ctx = useStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    StudyPicturesRouteQuery,
    StudyPicturesRouteQueryVariables
  >(STUDY_PICTURES_ROUTE, { variables: { studyId } });

  const refetchQueries: PureQueryOptions[] = [
    { query: STUDY_PICTURES_ROUTE, variables: { studyId } },
  ];

  const study = data?.studyList.find(s => s.id === studyId);
  const pictures = R.sortBy(
    R.propOr(Infinity, 'priority'),
    study?.pictures ?? [],
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <div className="space-y-6">
      <div className="grid md:grid-cols-2 gap-6">
        {pictures.map(picture => (
          <div key={picture.id}>
            <PictureThumbnail
              picture={picture}
              imgSrc={picture.file.signedUrl}
              bookmarkable={{
                parentType: BookmarkParentType.Study,
                parentId: studyId,
                targetType: BookmarkTargetType.Picture,
                targetId: picture.id,
                path: soBookmarkPath(
                  'study',
                  studyId,
                  BookmarkParentType.Study,
                  picture.outcropTagId,
                ),
              }}
              refetchQueries={refetchQueries}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
