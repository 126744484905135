import { useQuery , gql } from '@apollo/client';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { regionParts } from '~/apollo/fragments';
import type {
  UploadRegionUpdatePageQuery,
  UploadRegionUpdatePageQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import {
  regionRoute,
  uploadRegionUpdateGeoreferencesRoute,
  uploadRegionUpdateOutcropsRoute,
  uploadRegionUpdateRoute,
  uploadRegionUpdateStudiesRoute,
} from '~/paths';

const UPLOAD_REGION_UPDATE_PAGE = gql`
  query UploadRegionUpdatePage($regionId: Int!) {
    regionList(id: $regionId) {
      ...regionParts
    }
  }

  ${regionParts}
`;

type OutletContext = {
  region: UploadRegionUpdatePageQuery['regionList'][number];
};

export default function UploadRegionUpdatePage() {
  const params = useParams<{ regionId: string }>();
  invariant(params.regionId, 'regionId param required');
  const regionId = parseInt(params.regionId);

  const { data, loading } = useQuery<
    UploadRegionUpdatePageQuery,
    UploadRegionUpdatePageQueryVariables
  >(UPLOAD_REGION_UPDATE_PAGE, {
    variables: { regionId },
  });

  const regionList = data?.regionList ?? [];
  const region = regionList.find(r => r.id === regionId);

  useBreadcrumb(
    'routes/upload/model/region/$regionId',
    region?.name ?? 'Update Region',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!region) return <NotFound />;

  const outletContext: OutletContext = { region };

  return (
    <>
      <Link
        to={regionRoute(regionId)}
        target="_blank"
        className="float-right btn btn-sm btn-primary gap-1"
      >
        View Region <FontAwesomeIcon icon={faExternalLink} />
      </Link>

      <PageHeading>{region.name}</PageHeading>

      <NavTabs className="mb-2">
        <NavTabs.Tab to={uploadRegionUpdateRoute(region.id)} end>
          Region
        </NavTabs.Tab>
        <NavTabs.Tab to={uploadRegionUpdateGeoreferencesRoute(region.id)}>
          Georeferences
        </NavTabs.Tab>
        <NavTabs.Tab to={uploadRegionUpdateOutcropsRoute(region.id)}>
          Outcrops
        </NavTabs.Tab>
        <NavTabs.Tab to={uploadRegionUpdateStudiesRoute(region.id)}>
          Studies
        </NavTabs.Tab>
      </NavTabs>

      <Outlet context={outletContext} />
    </>
  );
}

export function useUploadRegionPageContext() {
  const ctx = useOutletContext<OutletContext>();
  return ctx;
}
