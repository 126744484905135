import type { PureQueryOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { Panel } from '~/components/common/Panel';
import { LithostratAgeFormFields } from '~/components/upload/lithostratAge/LithostratAgeFormFields';
import { PaleoMapFormFields } from '~/components/upload/paleomap/PaleoMapFormFields';
import { useModalState } from '~/hooks/modal';
import { uploadPaleoMapUpdateRoute } from '~/paths';
import type { PaleoMapFormValues } from '~/utils/modules/paleomap';
import {
  defaultPaleoMap,
  paleoMapValidationSchema,
} from '~/utils/modules/paleomap';

import type {
  CreatePaleoMapMutation,
  CreatePaleoMapMutationVariables,
} from '~/apollo/generated/schema';

const CREATE_PALEO_MAP = gql`
  mutation CreatePaleoMap(
    $paleoMap: PaleoMapInput!
    $age: LithostratAgeInput!
  ) {
    createPaleoMap(paleoMap: $paleoMap, age: $age) {
      id
    }
  }
`;

type Props = {
  children: (showModal: () => void) => JSX.Element;
  refetchQueries: PureQueryOptions[];
};

export function CreatePaleomapModal({ children, refetchQueries }: Props) {
  const navigate = useNavigate();
  const { show, showModal, hideModal } = useModalState();
  const [createPaleoMap, { loading, error }] = useMutation<
    CreatePaleoMapMutation,
    CreatePaleoMapMutationVariables
  >(CREATE_PALEO_MAP, {
    refetchQueries,
  });

  async function handleSubmit(values: PaleoMapFormValues) {
    try {
      const res = await createPaleoMap({
        variables: {
          paleoMap: values.paleoMap,
          age: values.age,
        },
      });
      const paleoMapId = res.data?.createPaleoMap.id;
      if (paleoMapId) {
        toast.success('Paleomap created successfully.');
        navigate(uploadPaleoMapUpdateRoute(paleoMapId));
      } else {
        throw new Error('Unhandled error creating paleoMap.');
      }
    } catch (err) {
      toast.error('There was a problem creating the paleomap.');
      console.log('Error creating paleomap', err);
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal} size="lg">
        <Formik
          onSubmit={handleSubmit}
          initialValues={defaultPaleoMap()}
          validationSchema={paleoMapValidationSchema()}
        >
          <Form>
            <Modal.Body heading="Create Paleomap" className="space-y-4">
              <div className="grid lg:grid-cols-2 gap-6">
                <div>
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title>Details</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                      <PaleoMapFormFields />
                    </Panel.Body>
                  </Panel>
                </div>
                <div>
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title>Geological Age</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                      <LithostratAgeFormFields ageName="age" />
                    </Panel.Body>
                  </Panel>
                </div>
              </div>

              <FormErrors graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <Button type="button" color="ghost" onClick={hideModal}>
                Cancel
              </Button>

              <Button type="submit" color="primary" loading={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}

export default CreatePaleomapModal;
