import { useQuery } from '@apollo/client';
import { Field, useFormikContext } from 'formik';
import React from 'react';

import { DEPOSITIONAL_HIERARCHY } from '~/apollo/operations/depositional';
import type {
  DepositionalHierarchyQuery,
  DepositionalHierarchyQueryVariables,
} from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import type { KeyParametersFormValues } from '~/utils/modules/keyParameters';
import { keyParamsFields, kpFieldName } from '~/utils/modules/keyParameters';

function clearValuesBelow(
  key: keyof KeyParametersFormValues,
  values: KeyParametersFormValues,
) {
  const idx = keyParamsFields.indexOf(key);
  const rest = keyParamsFields.slice(idx + 1);
  return rest.reduce(
    (acc: KeyParametersFormValues, cur: keyof KeyParametersFormValues) => {
      return { ...acc, [cur]: '' };
    },
    values,
  );
}

const getIsRequired = (field: string) =>
  ['geologyType', 'grossDepositionalEnvironment'].includes(field);

type Props = {
  geologyTypes: string[];
  disabled?: boolean;
};

export function KeyParametersFormFields({
  geologyTypes,
  disabled = false,
}: Props) {
  const { values, setValues } = useFormikContext<KeyParametersFormValues>();
  const { data, loading } = useQuery<
    DepositionalHierarchyQuery,
    DepositionalHierarchyQueryVariables
  >(DEPOSITIONAL_HIERARCHY, {});

  const depositionalHierarchy = data?.depositionalHierarchyFull;

  const getHierarchy = (gt: string) => {
    if (gt === 'clastic') return depositionalHierarchy?.clastic;
    else if (gt === 'carbonate') return depositionalHierarchy?.carbonate;
    else if (gt === 'structural') return depositionalHierarchy?.structural;
    return null;
  };

  const getOptions = (fieldName: keyof KeyParametersFormValues) => {
    const dh = getHierarchy(values.geologyType);

    const gdes = dh?.grossDepositionalEnvironment ?? [];
    const des =
      gdes.find(items => items.name === values.grossDepositionalEnvironment)
        ?.depositionalEnvironment ?? [];
    const ses =
      des.find(items => items.name === values.depositionalEnvironment)
        ?.depositionalSubEnvironment ?? [];
    const aes =
      ses.find(items => items.name === values.depositionalSubEnvironment)
        ?.architecturalElement ?? [];

    const mapOption = (value: string) => ({ value, label: value });

    if (fieldName === 'geologyType') return geologyTypes.map(mapOption);
    else if (fieldName === 'grossDepositionalEnvironment')
      return gdes.map(gde => gde.name).map(mapOption);
    else if (fieldName === 'depositionalEnvironment')
      return des.map(de => de.name).map(mapOption);
    else if (fieldName === 'depositionalSubEnvironment')
      return ses.map(se => se.name).map(mapOption);
    else if (fieldName === 'architecturalElement')
      return aes.map(ae => ae.name).map(mapOption);

    return [];
  };

  const handleChange =
    (fieldName: keyof KeyParametersFormValues) =>
    async (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;

      const nextValues = clearValuesBelow(fieldName, {
        ...values,
        [fieldName]: value,
      });
      setValues(nextValues);
    };

  return (
    <div className="space-y-2">
      {keyParamsFields
        .filter(field => field !== 'dominant')
        .map(field => (
          <Field
            key={field}
            component={FormikField}
            name={field}
            label={
              field === 'geologyType'
                ? 'Geology type'
                : kpFieldName(values.geologyType)(field)
            }
            type="select"
            options={getOptions(field)}
            onChange={handleChange(field)}
            disabled={disabled || loading || getOptions(field).length === 0}
            required={getIsRequired(field)}
          />
        ))}
    </div>
  );
}
