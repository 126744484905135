import { useRef } from "react";
import { Joystick, JoystickShape } from "react-joystick-component"
import type { IJoystickProps, IJoystickUpdateEvent } from "react-joystick-component/build/lib/Joystick";

type signalJoystickProps = IJoystickProps & {
  signalRate: number;
  size: number;
  throttle: number;
  label?: string;
  joystickdirection?: 'AxisX' | 'AxisY';
  callback: (_: IJoystickUpdateEvent | null) => void;
}

export default function SignalJoystick({size, throttle, signalRate, label, joystickdirection, callback, ...props}: signalJoystickProps) {
  const startedRef = useRef<boolean>(false);
  const streamRef = useRef<NodeJS.Timeout | null>(null);
  const joystickStateRef = useRef<IJoystickUpdateEvent | null>(null);
  

  const onStart = () => {
    startedRef.current = true
    streamRef.current = streamUpdates();
  }

  const onMove = (joystickUpdate: IJoystickUpdateEvent) => {
    joystickStateRef.current = joystickUpdate;
}

  const onStop = () => {
    startedRef.current = false;  // Reset started flag
    joystickStateRef.current = null; 
    if (streamRef.current) {  // If there's a stream running, stop it
      clearInterval(streamRef.current);  // Stop the stream
    }
  }

  const streamUpdates = (): NodeJS.Timeout => {
    if (streamRef.current) {
      clearInterval(streamRef.current)
    }
    const streamInterval = setInterval(()=> {
      if (startedRef.current) {
        callback(joystickStateRef.current)
      }
    }, signalRate)
    return streamInterval
  }

  return (
    <>
    {label && <p className='my-2'>{label}</p>}
    <Joystick size={size} 
      move={(update) => {onMove(update)}} 
      stickImage={props.stickImage}
      controlPlaneShape={joystickdirection ? JoystickShape[joystickdirection] : undefined}
      baseShape={props.baseShape ? props.baseShape : undefined}
      stickShape={props.stickShape ? props.stickShape : undefined}
      baseColor={props.baseColor ? props.baseColor : undefined}
      stickColor={props.stickColor ? props.stickColor : undefined}
      stop={onStop} 
      start={onStart}  
      throttle={throttle}
      {...props}>
    </Joystick>
  </>
  )
}