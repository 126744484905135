import {
  faFileExport,
  faFlag,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { FilterSearch } from '~/components/common/FilterSearch';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { Tooltip } from '~/components/common/Tooltip';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { useSortFilter } from '~/hooks/data';
import * as routes from '~/paths';
import type { SortableVom } from '~/routes/upload/vom/_index';
import type { VomInterpretationState } from '~/utils/modules/vom';
import { snakeCapsToHuman } from '~/utils/text';

const Yes = () => <EnabledIndicator value={true} />;
const No = () => <EnabledIndicator value={false} />;
const Flag = () => <FontAwesomeIcon icon={faFlag} className="text-warning" />;

const interpIndicatorText = (state: VomInterpretationState) => {
  switch (state) {
    case 'published':
      return 'all interpretations published';
    case 'unpublished':
      return 'contains unpublished interpretation';
    case 'none':
    default:
      return 'no interpretations';
  }
};

function outcropLink(vom: SortableVom) {
  if (!vom.outcrop) {
    return <>Not linked</>;
  } else {
    return (
      <Link to={`/outcrop/${vom.outcrop.id}`} className="link">
        {vom.outcrop.name}
      </Link>
    );
  }
}

type CountryProps = {
  outcrop: SortableVom['outcrop'];
};
function Country({ outcrop }: CountryProps) {
  if (!outcrop?.region?.location?.country) {
    return <>Not linked</>;
  } else {
    return <>{outcrop.region.location.country}</>;
  }
}

type Props = {
  voms: SortableVom[];
};

export function VomList({ voms }: Props) {
  const { items, filterSearchProps, sortIndicatorProps } = useSortFilter(
    voms,
    'name',
    'name',
    'uploadVomList',
  );

  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>
          Virtual Outcrop Models
          <small className="text-muted ml-2">
            (<strong>{items.length}</strong> results displayed)
          </small>
        </Panel.Title>

        <div className="space-x-1">
          <Link
            to={routes.exportVomsRoute()}
            target="_blank"
            className="btn btn-ghost btn-xs gap-1"
          >
            <FontAwesomeIcon icon={faFileExport} /> Export VOMs
          </Link>
          <Link
            to={routes.uploadVomCreateRoute()}
            className="btn btn-primary btn-xs gap-1"
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Create VOM
          </Link>
        </div>
      </Panel.Heading>

      <Panel.Body>
        <FilterSearch {...filterSearchProps} />

        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>
                <SortTrigger
                  colName="id"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  ID
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="name"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  Name
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="acquisitionType"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Acq. Type
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="acquisitionYear"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  Year
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="authors" // Virtual property
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Authors
                </SortTrigger>
              </th>
              <th className="w-2/12">
                <SortTrigger
                  colName="outcrop.region.location.country"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Country
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="outcrop.name"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  Outcrop Link
                </SortTrigger>
              </th>
              <th className="w-2/12">
                <SortTrigger
                  colName="project.name"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Project
                </SortTrigger>
              </th>
              <th className="w-1/12">
                <SortTrigger
                  colName="hasV3GeoModelLinked"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                  renderFilterOption={value =>
                    value === 'true'
                      ? 'Has a V3Geo model linked'
                      : 'NO V3Geo model linked'
                  }
                >
                  V3Geo
                </SortTrigger>
              </th>
              <th className="w-1/12">
                <SortTrigger
                  colName="interpretationState"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                  renderFilterOption={value =>
                    interpIndicatorText(value as VomInterpretationState)
                  }
                >
                  <Tooltip message="Interpretations">
                    <span>Int.</span>
                  </Tooltip>
                </SortTrigger>
              </th>
              <th className="w-1/12">
                <SortTrigger
                  colName="approved"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  <Tooltip message="Is Approved">
                    <span>Appr.</span>
                  </Tooltip>
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="isClipping"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Clipping
                </SortTrigger>
              </th>
              <th className="w-1/12">
                <SortTrigger
                  colName="cesiumAsset.state"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                  renderFilterOption={snakeCapsToHuman}
                >
                  <Tooltip message="Cesium Asset State">
                    <span>C-State</span>
                  </Tooltip>
                </SortTrigger>
              </th>
              <th className="w-1/12">
                <SortTrigger
                  colName="cesiumAsset.approved"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  <Tooltip message="Cesium Asset Approved">
                    <span>C-Appr.</span>
                  </Tooltip>
                </SortTrigger>
              </th>
            </tr>
          </thead>

          <tbody>
            {items.map(vom => (
              <tr key={vom.id}>
                <td>{vom.id}</td>
                <td className="w-4/12">
                  <Link
                    to={routes.uploadVomUpdateRoute(vom.id)}
                    className="link break-all"
                  >
                    {vom.name}
                  </Link>
                </td>
                <td>{vom.acquisitionType}</td>
                <td>{vom.acquisitionYear}</td>
                <td>{vom.authors}</td>
                <td>{vom.outcrop && <Country outcrop={vom.outcrop} />}</td>
                <td>{outcropLink(vom)}</td>
                <td>{vom.project?.name}</td>
                <td className="text-center">
                  {vom.hasV3GeoModelLinked ? <Yes /> : <No />}
                </td>
                <td className="text-center">
                  <Tooltip
                    message={interpIndicatorText(vom.interpretationState)}
                  >
                    <span>
                      {vom.interpretationState === 'published' && <Yes />}
                      {vom.interpretationState === 'unpublished' && <Flag />}
                      {vom.interpretationState === 'none' && <No />}
                    </span>
                  </Tooltip>
                </td>
                <td className="text-center">
                  {vom.approved ? <Yes /> : <No />}
                </td>

                <td className="text-center">
                  {vom.cesiumAsset?.isClipping === true ? (
                    <EnabledIndicator value={true} />
                  ) : vom.cesiumAsset?.isClipping === false ? (
                    <EnabledIndicator value={false} />
                  ) : (
                    '--'
                  )}
                </td>

                <td>
                  {vom.cesiumAsset && (
                    <a
                      href={routes.uploadVomUpdateModelDataRoute(vom.id)}
                      className="link"
                    >
                      {snakeCapsToHuman(vom.cesiumAsset.state)}
                    </a>
                  )}
                </td>
                <td className="text-center">
                  <CeisumAssetApprovalIcon
                    approved={vom.cesiumAsset?.approved}
                  />
                </td>
              </tr>
            ))}

            <NoItemsRow show={!items.length} colSpan={10} />
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
}

function CeisumAssetApprovalIcon({
  approved,
}: {
  approved: boolean | null | undefined;
}) {
  if (R.isNil(approved)) return null;
  else if (approved) return <Yes />;
  else return <No />;
}
