import { gql, useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  OutcropEnumerationsQuery,
  UpdateOutcropMutation,
  UpdateOutcropMutationVariables,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import type {
  InitialOutcrop,
  OutcropFormValues,
} from '~/utils/modules/outcrop';
import {
  initialOutcropFormValues,
  toOutcropInput,
} from '~/utils/modules/outcrop';
import { BasinFormFields } from './BasinFormFields';
import { OutcropOverviewFormFields } from './OutcropOverviewFormFields';
import { OutcropQPsFormFields } from './OutcropQPsFormFields';
import { SourceAreaFormFields } from './SourceAreaFormFields';

const UPDATE_OUTCROP = gql`
  mutation UpdateOutcrop($id: Int!, $outcrop: OutcropInput!) {
    updateOutcrop(id: $id, outcrop: $outcrop) {
      ...outcropParts
      qualityParameters {
        ...outcropQualityParametersParts
      }
      basin {
        ...basinParts
      }
      sourceArea {
        ...sourceAreaParts
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.outcropQualityParametersParts}
  ${fragments.basinParts}
  ${fragments.sourceAreaParts}
`;

type Props = {
  outcrop: InitialOutcrop;
  outcropEnums: OutcropEnumerationsQuery;
};

const UpdateOutcropForm: React.FC<Props> = ({
  outcrop,
  outcropEnums,
}: Props) => {
  const [updateOutcrop, { loading, error }] = useMutation<
    UpdateOutcropMutation,
    UpdateOutcropMutationVariables
  >(UPDATE_OUTCROP, {});
  async function handleSubmit(
    values: OutcropFormValues,
    helpers: FormikHelpers<OutcropFormValues>,
  ) {
    const outcropInput = toOutcropInput(values);

    try {
      const res = await updateOutcrop({
        variables: {
          id: outcrop.id,
          outcrop: outcropInput,
        },
      });

      invariant(res.data?.updateOutcrop);
      const nextValues = res.data?.updateOutcrop;

      toast.success('Outcrop details saved successfully.');
      helpers.resetForm({
        values: initialOutcropFormValues(nextValues),
      });
    } catch (err) {
      toast.error('There was a problem saving the outcrop.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialOutcropFormValues(outcrop)}
    >
      <Form className="space-y-6">
        <div className="grid lg:grid-cols-12 gap-6">
          <div className="lg:col-span-8">
            <Panel>
              <Panel.Heading>
                <Panel.Title>Overview</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <OutcropOverviewFormFields outcropEnums={outcropEnums} />
              </Panel.Body>
            </Panel>
          </div>

          <div className="lg:col-span-4 space-y-6">
            <Panel>
              <Panel.Heading>
                <Panel.Title>Basin</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <BasinFormFields />
              </Panel.Body>
            </Panel>

            <Panel>
              <Panel.Heading>
                <Panel.Title>Source Area</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <SourceAreaFormFields />
              </Panel.Body>
            </Panel>

            <Panel>
              <Panel.Heading>
                <Panel.Title>Quality Parameters</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <OutcropQPsFormFields enumOptions={outcropEnums} />
              </Panel.Body>
            </Panel>
          </div>
        </div>

        <FormErrors graphQLError={error} />

        <div className="text-center">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            Save
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default UpdateOutcropForm;
