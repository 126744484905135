import { gql, useMutation } from '@apollo/client';
import { faBan, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import * as fragments from '~/apollo/fragments';
import type {
  AddDiagramAreaMutation,
  AddDiagramAreaMutationVariables,
  DiagramAreaPartsFragment,
  DiagramPartsFragment,
  FilePartsFragment,
  RemoveDiagramAreaMutation,
  RemoveDiagramAreaMutationVariables,
} from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';
import { FormErrors } from '~/components/common/FormErrors';
import { Heading } from '~/components/common/Heading';
import { ListGroup } from '~/components/common/ListGroup';
import { Panel } from '~/components/common/Panel';
import { Tooltip } from '~/components/common/Tooltip';
import type { DiagramAreaFormValues } from '~/utils/modules/diagram';
import {
  defaultDiagram,
  diagramValidationSchema,
  linkTarget,
  toDiagramAreaInput,
} from '~/utils/modules/diagram';
import { DiagramAreaFormFields } from './AreaFormFields';
import { DiagramPathDrawingField } from './PathDrawingField';

export const ADD_DIAGRAM_AREA = gql`
  mutation AddDiagramArea($diagramId: Int!, $diagramArea: DiagramAreaInput!) {
    addDiagramArea(diagramId: $diagramId, diagramArea: $diagramArea) {
      ...diagramParts
      file {
        ...fileParts
        signedUrl
      }
      diagramAreas {
        ...diagramAreaParts
      }
    }
  }

  ${fragments.diagramParts}
  ${fragments.diagramAreaParts}
  ${fragments.fileParts}
`;

export const REMOVE_DIAGRAM_AREA = gql`
  mutation RemoveDiagramArea($diagramId: Int!, $diagramAreaId: String!) {
    removeDiagramArea(diagramId: $diagramId, diagramAreaId: $diagramAreaId) {
      ...diagramParts
      file {
        ...fileParts
        signedUrl
      }
      diagramAreas {
        ...diagramAreaParts
      }
    }
  }

  ${fragments.diagramParts}
  ${fragments.diagramAreaParts}
  ${fragments.fileParts}
`;

type Diagram = DiagramPartsFragment & {
  diagramAreas: DiagramAreaPartsFragment[];
  // Technically a diagram shouldn't be able to exist without a file, but if you
  // enforce this typescript blows up even when adding assertions around it existing
  file?: Pick<FilePartsFragment, 'signedUrl'> | null;
};

type Props = {
  diagram: Diagram;
  onHover: (areaId?: string) => any;
  hoveredAreaId?: string;
};

export function DiagramEditor({ diagram, onHover, hoveredAreaId }: Props) {
  const [isDrawing, setIsDrawing] = useState(false);
  const [addArea, { loading: loadingAddArea, error: errorAddArea }] =
    useMutation<AddDiagramAreaMutation, AddDiagramAreaMutationVariables>(
      ADD_DIAGRAM_AREA,
      {},
    );
  const [removeArea, { loading: loadingRemoveArea }] = useMutation<
    RemoveDiagramAreaMutation,
    RemoveDiagramAreaMutationVariables
  >(REMOVE_DIAGRAM_AREA, {});

  async function handleSubmit(
    values: DiagramAreaFormValues,
    helpers: FormikHelpers<DiagramAreaFormValues>,
  ) {
    const diagramArea = toDiagramAreaInput(values);

    try {
      await addArea({
        variables: {
          diagramId: diagram.id,
          diagramArea,
        },
      });
      toast.success('Area saved successfully.');
      setIsDrawing(false);
      helpers.resetForm();
    } catch (err) {
      console.log('Error saving area', err);
      toast.error('There was a problem saving the area.');
    }
  }

  const handleDelete = (areaId: string) => async () => {
    try {
      await removeArea({
        variables: {
          diagramId: diagram.id,
          diagramAreaId: areaId,
        },
      });
      toast.success('Area deleted successfully.');
    } catch (err) {
      console.log('Error deleting area', err);
      toast.error('There was a problem deleting the area.');
    }
  };

  const handleHover = (areaId?: string) => () => {
    onHover(areaId);
  };

  const toggleDraw = (cb?: Function) => () => {
    if (cb) cb();
    const nextDrawing = !isDrawing;
    setIsDrawing(nextDrawing);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultDiagram()}
      validationSchema={diagramValidationSchema}
    >
      {({ resetForm }) => (
        <Form>
          <div className="grid lg:grid-cols-3 gap-6">
            <div className="space-y-4">
              <div className="flex justify-between items-end">
                <Heading level={3}>Image Areas</Heading>
                <div>
                  {!isDrawing && (
                    <Button
                      type="button"
                      color="primary"
                      size="xs"
                      onClick={toggleDraw()}
                      className="gap-1"
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add
                    </Button>
                  )}
                  {isDrawing && (
                    <Button
                      type="button"
                      color="ghost"
                      size="xs"
                      className="gap-1"
                      onClick={toggleDraw(resetForm)}
                    >
                      <FontAwesomeIcon icon={faBan} /> Cancel
                    </Button>
                  )}
                </div>
              </div>

              {diagram.diagramAreas.length === 0 && (
                <p>
                  No areas have been added yet. Use the <em>Add</em> button to
                  get started.
                </p>
              )}

              {isDrawing && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>New Area</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body className="space-y-4">
                    <DiagramAreaFormFields />
                    <FormErrors graphQLError={errorAddArea} />
                  </Panel.Body>
                  <Panel.Footer className="text-right">
                    <Button
                      type="submit"
                      color="primary"
                      loading={loadingAddArea}
                    >
                      Save Area
                    </Button>
                  </Panel.Footer>
                </Panel>
              )}

              <ListGroup border={false}>
                {diagram.diagramAreas.map(area => (
                  <ListGroup.Item
                    key={area.id}
                    onMouseOver={handleHover(area.id)}
                    onMouseOut={handleHover()}
                    className={cn('border-l-4 border-slate-200 p-4 my-2', {
                      'border-l-secondary': area.id === hoveredAreaId,
                    })}
                  >
                    <div className="flex justify-between items-center">
                      {area.label}
                      <div className="text-muted text-sm break-words">
                        {linkTarget(area.linkTargetType, area.linkTargetId)}
                      </div>

                      <Confirm
                        onConfirm={handleDelete(area.id)}
                        text="The area will be permanently deleted. Are you sure you want to proceed?"
                      >
                        {onConfirm => (
                          <Tooltip message="Delete this area">
                            <Button
                              type="button"
                              color="ghost"
                              size="xs"
                              onClick={onConfirm}
                              disabled={loadingRemoveArea}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </Tooltip>
                        )}
                      </Confirm>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>

            <div className="lg:col-span-2">
              {diagram.file && (
                <DiagramPathDrawingField
                  name="path"
                  areas={diagram.diagramAreas}
                  image={diagram.file.signedUrl}
                  hoveredAreaId={hoveredAreaId}
                  onHover={handleHover}
                  isDrawing={isDrawing}
                />
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
