import type { PureQueryOptions } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type { DiagramParent } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import { useRefetchQueries } from '~/hooks/apollo';
import { imageMimes } from '~/utils/forms';

type CreateDiagramFormValues = {
  image: File | '';
};

type Props = {
  parentType: DiagramParent;
  parentId: number;
  refetchQueries: PureQueryOptions[];
};

export function UploadDiagramImageForm({
  parentType,
  parentId,
  refetchQueries,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [refetch] = useRefetchQueries(refetchQueries);

  async function handleSubmit(values: CreateDiagramFormValues) {
    if (!values.image) {
      toast.error('An image is required.');
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('parentType', parentType);
      formData.append('parentId', String(parentId));
      formData.append('file', values.image);

      const res = await fetch('/api/v3/file/diagram', {
        method: 'post',
        body: formData,
      });
      if (!res.ok) {
        console.error(await res.json());
        throw new Error('Upload failed');
      }

      await refetch();

      toast.success('File uploaded successfully.');
      setLoading(false);
    } catch (err) {
      toast.error('An error occurred uploading the image.');
      console.log('Error uploading image', err);
    }
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={{ image: '' }}>
      <Form encType="multipart/form-data" className="space-y-4">
        <Field
          name="image"
          label="Image"
          component={FormikField}
          type="file"
          accept={imageMimes}
          required
        />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Upload
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
