import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { cn } from '~/utils/common';

type Props = Omit<FontAwesomeIconProps, 'icon'> & {
  show?: boolean;
};

export const SpinnerIcon: React.FC<Props> = ({
  show = true,
  className,
  ...props
}) => {
  if (!show) return null;
  return (
    <FontAwesomeIcon
      icon={faSpinner}
      className={cn('animate-spin', className)}
      {...props}
    />
  );
};
