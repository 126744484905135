import { gql } from '@apollo/client';
import * as fragments from '~/apollo/fragments';

export const UPDATE_LITHOSTRAT_GROUP = gql`
  mutation UpdateLithostratGroup($id: Int!, $group: LithostratGroupInput!) {
    updateLithostratGroup(id: $id, group: $group) {
      ...lithostratGroupParts
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
    }
  }

  ${fragments.lithostratGroupParts}
  ${fragments.lithostratAgeParts}
`;

export const UPDATE_LITHOSTRAT_FORMATION = gql`
  mutation updateLithostratFormation(
    $id: Int!
    $formation: LithostratFormationInput!
  ) {
    updateLithostratFormation(id: $id, formation: $formation) {
      ...lithostratFormationParts
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
      group {
        ...lithostratGroupParts
      }
    }
  }

  ${fragments.lithostratFormationParts}
  ${fragments.lithostratAgeParts}
  ${fragments.lithostratGroupParts}
`;

export const UPDATE_LITHOSTRAT_MEMBER = gql`
  mutation updateLithostratMember($id: Int!, $member: LithostratMemberInput!) {
    updateLithostratMember(id: $id, member: $member) {
      ...lithostratMemberParts
      formation {
        ...lithostratFormationParts
      }
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
    }
  }

  ${fragments.lithostratMemberParts}
  ${fragments.lithostratFormationParts}
  ${fragments.lithostratAgeParts}
`;

export const CREATE_LITHOSTRAT_OUTCROP_LINK = gql`
  mutation CreateLithostratOutcropLink(
    $lithostratOutcropLink: LithostratOutcropLinkInput!
  ) {
    createLithostratOutcropLink(lithostratOutcropLink: $lithostratOutcropLink) {
      id
    }
  }
`;

export const CREATE_LITHOSTRAT_STUDY_LINK = gql`
  mutation CreateLithostratStudyLink(
    $lithostratStudyLink: LithostratStudyLinkInput!
  ) {
    createLithostratStudyLink(lithostratStudyLink: $lithostratStudyLink) {
      id
    }
  }
`;
