import type { PureQueryOptions } from '@apollo/client';
import { useMutation , gql } from '@apollo/client';
import { toast } from 'react-toastify';

import type {
  DeleteMemberMutation,
  DeleteMemberMutationVariables,
} from '~/apollo/generated/schema';

const DELETE_MEMBER = gql`
  mutation DeleteMember($id: Int!) {
    deleteLithostratMember(id: $id)
  }
`;

type Props = {
  id: number;
  children: (onDelete: () => void, loading: boolean) => JSX.Element;
  onDeleteSuccess?: () => any;
  refetchQueries?: PureQueryOptions[];
};

export function DeleteMember({
  id,
  children,
  onDeleteSuccess,
  refetchQueries,
}: Props) {
  const [deleteMember, { loading }] = useMutation<
    DeleteMemberMutation,
    DeleteMemberMutationVariables
  >(DELETE_MEMBER, {
    variables: { id },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteMember();
      if (onDeleteSuccess) {
        onDeleteSuccess();
      }
    } catch (err) {
      console.log('Error deleting member', err);
      toast.error('There was a problem deleting the member.');
    }
  }

  return children(handleDelete, loading);
}
