import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import * as fragments from '~/apollo/fragments';
import type {
  OutcropTrainingImagesTabQuery,
  OutcropTrainingImagesTabQueryVariables,
} from '~/apollo/generated/schema';
import { BookmarkParentType } from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportingObjectList } from '~/components/supportingObject/SupportingObjectList';
import { TaggedSupportingObjectList } from '~/components/supportingObject/TaggedSupportingObjectList';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';

const OUTCROP_TRAINING_IMAGES_TAB = gql`
  query OutcropTrainingImagesTab($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts
      trainingImages {
        ...trainingImageParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
            signedUrl
          }
        }
        files {
          ...fileParts
          signedUrl
        }
      }

      studies {
        ...studyParts

        dataHistory {
          ...dataHistoryParts
        }

        trainingImages {
          ...trainingImageParts
          pictures {
            ...pictureParts
            file {
              ...fileParts
              signedUrl
            }
          }
          files {
            ...fileParts
            signedUrl
          }
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.trainingImageParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.studyParts}
  ${fragments.dataHistoryParts}
`;

export default function OutcropTrainingImagesRoute() {
  const ctx = useOutcropOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    OutcropTrainingImagesTabQuery,
    OutcropTrainingImagesTabQueryVariables
  >(OUTCROP_TRAINING_IMAGES_TAB, { variables: { outcropId } });

  const refetchQueries: PureQueryOptions[] = [
    { query: OUTCROP_TRAINING_IMAGES_TAB, variables: { outcropId } },
  ];

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <>
      <SupportingObjectList
        items={outcrop.trainingImages}
        soParentType="outcrop"
        soParentId={outcropId}
        bookmarkParentType={BookmarkParentType.TrainingImage}
        refetchQueries={refetchQueries}
      />

      <TaggedSupportingObjectList
        outcropId={outcropId}
        studies={outcrop.studies}
        supportObjectKey="trainingImages"
        refetchQueries={refetchQueries}
      />
    </>
  );
}
