import type { PureQueryOptions } from '@apollo/client';
import { useMutation , gql } from '@apollo/client';
import { faFileCircleXmark, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';

import type {
  DeleteInterpretationFilesMutation,
  DeleteInterpretationFilesMutationVariables,
  DeleteInterpretationMutation,
  DeleteInterpretationMutationVariables,
  InterpretationFileListQuery,
  InterpretationFileListQueryVariables,
} from '~/apollo/generated/schema';
import { Modal } from '~/components/common/Modal';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useImperativeQuery } from '~/hooks/apollo';
import { useModalState } from '~/hooks/modal';

const INTERPRETATION_FILE_LIST = gql`
  query InterpretationFileList($interpretationId: Int!, $size: Int!) {
    virtualOutcropInterpretationFileList(
      interpretationId: $interpretationId
      size: $size
    ) {
      count
      result
    }
  }
`;

const DELETE_INTERPRETATION_FILES = gql`
  mutation DeleteInterpretationFiles(
    $interpretationId: Int!
    $files: [String!]!
  ) {
    deleteVirtualOutcropInterpretationFiles(
      interpretationId: $interpretationId
      files: $files
    )
  }
`;

const DELETE_INTERPRETATION = gql`
  mutation DeleteInterpretation($id: Int!) {
    deleteVirtualOutcropInterpretation(id: $id)
  }
`;

type Props = {
  interpretationId: number;
  isPublished: boolean;
  refetchQueries: PureQueryOptions[];
  children: (showModal: () => void) => React.ReactNode;
};

export function DeleteVomInterpretation({
  interpretationId,
  isPublished,
  refetchQueries,
  children,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [loadFileList, { data, loading }] = useImperativeQuery<
    InterpretationFileListQuery,
    InterpretationFileListQueryVariables
  >(
    INTERPRETATION_FILE_LIST,
    // Must be less than 1000
    { variables: { interpretationId, size: 999 } },
  );

  const [deleteFiles, { loading: loadingDeleteFiles }] = useMutation<
    DeleteInterpretationFilesMutation,
    DeleteInterpretationFilesMutationVariables
  >(DELETE_INTERPRETATION_FILES, { refetchQueries });

  const [deleteInterpretation, { loading: loadingDeleteInterpretation }] =
    useMutation<
      DeleteInterpretationMutation,
      DeleteInterpretationMutationVariables
    >(DELETE_INTERPRETATION, {
      variables: { id: interpretationId },
      refetchQueries,
    });

  const launchModal = () => {
    loadFileList();
    showModal();
  };

  const files = data?.virtualOutcropInterpretationFileList.result ?? [];

  const handleDeleteFiles = async () => {
    if (!files.length) return;

    const confirmMessage =
      'Are you sure you want to permanently delete all files? THIS CANNOT BE UNDONE!';
    if (!window.confirm(confirmMessage)) return;

    try {
      await deleteFiles({ variables: { interpretationId, files } });
      toast.success(`This interpretation's files were deleted successfully.`);
    } catch (err) {
      console.log('Error deleting files', err);
      toast.error(
        'Something went wrong deleting the files. Check the logs for more information.',
      );
    } finally {
      loadFileList();
    }
  };

  const handleDeleteInterpretation = async () => {
    try {
      await deleteInterpretation();
      hideModal();
      toast.success('The interpretation was deleted successfully.');
    } catch (err) {
      console.log('Error deleting interpretation', err);
      toast.error(
        'There was a problem deleting the interpretation. Please see logs for more details.',
      );
    }
  };

  const isDeletable = !files.length && !isPublished;

  return (
    <>
      {children(launchModal)}

      <Modal open={show} onHide={hideModal}>
        <Modal.Body heading="Delete Interpretation" className="space-y-4">
          {isPublished && (
            <div className="alert alert-info">
              Interpretations cannot be modified while published. If you would
              like to delete this interpretation and/or its files, please
              unpublish it first.
            </div>
          )}

          <SpinnerPlaceholder show={loading} />

          {files.length > 0 && (
            <Panel>
              <Panel.Heading>
                <Panel.Title>Files</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <div>Interpretation files:</div>
                <ul className="list-disc list-inside">
                  {files.map(f => (
                    <li key={f}>{f}</li>
                  ))}
                </ul>

                <div className="text-center">
                  <Button
                    type="button"
                    color="error"
                    onClick={handleDeleteFiles}
                    loading={loadingDeleteFiles}
                    className="gap-1"
                  >
                    <FontAwesomeIcon icon={faFileCircleXmark} /> Delete Files
                  </Button>
                </div>
              </Panel.Body>
            </Panel>
          )}

          <Panel variant={isDeletable ? 'error' : 'default'}>
            <Panel.Heading>
              <Panel.Title>Confirm Deletion</Panel.Title>
            </Panel.Heading>
            <Panel.Body className="space-y-4">
              {isDeletable ? (
                <p>The interpretation will be permanently deleted.</p>
              ) : (
                <p>
                  This interpretation cannot be deleted until all files have
                  been deleted and it is unpublished.
                </p>
              )}

              <div className="text-center">
                <Button
                  type="button"
                  color="error"
                  onClick={handleDeleteInterpretation}
                  disabled={
                    loading ||
                    loadingDeleteFiles ||
                    loadingDeleteInterpretation ||
                    !isDeletable
                  }
                  className="gap-1"
                >
                  <FontAwesomeIcon icon={faTrash} /> Delete Interpretation
                </Button>
              </div>
            </Panel.Body>
          </Panel>
        </Modal.Body>
      </Modal>
    </>
  );
}
