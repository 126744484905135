import { FormationSidebar } from '~/components/wiki/lithostrat/FormationSidebar';
import { WikiDescriptionText } from '~/components/wiki/lithostrat/WikiPageDescriptionText';
import { useFormationDetailOutletContext } from '~/routes/wiki/regional/lithostrat/formation.$formationId';

export default function FormationDetailIndexRoute() {
  const { formation } = useFormationDetailOutletContext();

  return (
    <div className="grid lg:grid-cols-12 gap-6">
      {formation.wikiPage && (
        <div className="lg:col-span-7">
          <WikiDescriptionText wiki={formation.wikiPage} />
        </div>
      )}

      <div className="lg:col-span-5">
        <FormationSidebar formation={formation} />
      </div>
    </div>
  );
}
