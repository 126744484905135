import { Outlet } from 'react-router-dom';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { uploadOutcropsRoute } from '~/paths';

export default function UploadOutcropsBreadcrumbWrapper() {
  // Wrapper component for generating a breadcrumb entry
  useBreadcrumb(
    'routes/upload/model/outcrop',
    'Outcrops',
    uploadOutcropsRoute(),
  );

  return <Outlet />;
}
