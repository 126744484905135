import invariant from 'tiny-invariant';
import { MemberSidebar } from '~/components/wiki/lithostrat/MemberSidebar';
import { WikiDescriptionText } from '~/components/wiki/lithostrat/WikiPageDescriptionText';
import { useMemberDetailOutletContext } from '~/routes/wiki/regional/lithostrat/member.$memberId';

export default function LithostratMemberIndexRoute() {
  const { member } = useMemberDetailOutletContext();
  invariant(member.wikiPage, 'Wiki page does not exist or is not published');

  return (
    <div className="grid lg:grid-cols-12 gap-6">
      <div className="lg:col-span-7">
        <WikiDescriptionText wiki={member.wikiPage} />
      </div>

      <div className="lg:col-span-5">
        <MemberSidebar member={member} />
      </div>
    </div>
  );
}
