import { GoogleMap, InfoWindowF } from '@react-google-maps/api';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import type * as schema from '~/apollo/generated/schema';
import { GeoreferenceDataType } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import { Heading } from '~/components/common/Heading';
import { Well } from '~/components/common/Well';
import { LegendCircle } from '~/components/outcrop/OutcropMap';
import { outcropRoute } from '~/paths';
import {
  createBounds,
  createEntity,
  createPolygon,
  georefColors,
  markerIcon,
  polygonOptions,
  sortGeoreferenceVisibility,
} from '~/utils/georeference';
import { isGlobalRegion } from '~/utils/modules/region';

type QueryStudyType = schema.StudyIndexRouteQuery['studyList'][number];

type Props = {
  study: QueryStudyType;
};

type VisibilityFilters = {
  study: boolean;
  crossSections: boolean;
  sedimentaryLogs: boolean;
  gigaPans: boolean;
  outcrops: boolean;
};

type MapField = {
  label: string;
  color: string;
};

const fields: Record<keyof VisibilityFilters, MapField> = {
  study: {
    label: 'Study',
    color: georefColors.study,
  },
  outcrops: {
    label: 'Outcrops',
    color: georefColors.outcrop,
  },
  crossSections: {
    label: 'Cross Sections',
    color: georefColors.study,
  },
  sedimentaryLogs: {
    label: 'Sedimentary Logs',
    color: georefColors.study,
  },
  gigaPans: {
    label: 'Panoramas',
    color: georefColors.study,
  },
};

type OutcropWithOutline = {
  outcrop: Pick<schema.OutcropPartsFragment, 'id' | 'name'>;
  outline: schema.GeoreferencePartsFragment;
};

export function StudyGeoreferencesMap({ study }: Props) {
  // Hide "global" region-outcrops from the map
  const outcrops = study.outcrops.filter(oc => !isGlobalRegion(oc.region));

  const studyGeoreferences = study.georeferences;

  // If the study has an outline, don't show outcrop outlines. issue #2008
  const studyOutline = studyGeoreferences.find(
    g => g.dataType === GeoreferenceDataType.Outline,
  );

  const outcropOutlines = outcrops.reduce<OutcropWithOutline[]>((acc, cur) => {
    const outline = cur.georeferences.find(
      g => g.dataType === GeoreferenceDataType.Outline,
    );
    if (outline) acc.push({ outcrop: cur, outline });
    return acc;
  }, []);

  const crossSections = study.crossSections.filter(
    cs => cs.georeference.length > 0,
  );

  const sedimentaryLogs = study.sedimentaryLogs.filter(
    sl => sl.georeference.length > 0,
  );

  const gigaPans = study.gigaPans.filter(gp => gp.georeference.length > 0);

  const initialValues: VisibilityFilters = {
    study: studyGeoreferences.length > 0,
    outcrops: !studyOutline && outcropOutlines.length > 0,
    crossSections: crossSections.length > 0,
    sedimentaryLogs: sedimentaryLogs.length > 0,
    gigaPans: gigaPans.length > 0,
  };

  const enabledFields = (Object.keys(fields) as (keyof typeof fields)[]).filter(
    f => initialValues[f],
  );

  const showOutcropKey = initialValues.outcrops;
  const showStudyKey =
    initialValues.study ||
    initialValues.crossSections ||
    initialValues.sedimentaryLogs ||
    initialValues.gigaPans;

  if (!enabledFields.length) {
    return null;
  }

  return (
    <div className="space-y-0">
      <Formik<VisibilityFilters>
        onSubmit={() => {}}
        initialValues={initialValues}
      >
        <Form>
          <GeoreferenceMap
            studyGeoreferences={studyGeoreferences}
            outcropOutlines={outcropOutlines}
            crossSections={crossSections}
            sedimentaryLogs={sedimentaryLogs}
            gigaPans={gigaPans}
          />
          <Well>
            <div className="space-y-2">
              <div className="flex gap-2 justify-center">
                {enabledFields.map(field => (
                  <Field
                    key={field}
                    name={field}
                    label={fields[field].label}
                    component={FormikField}
                    type="checkbox"
                    size="sm"
                  />
                ))}
              </div>

              <div className="flex gap-2 items-center justify-center text-sm">
                <div className="text-xs text-muted">Key:</div>
                {showStudyKey && (
                  <div className="flex gap-1 items-center">
                    <span>Study Data</span>
                    <LegendCircle color={georefColors.study} />
                  </div>
                )}
                {showOutcropKey && (
                  <div className="flex gap-1 items-center">
                    <span>Outcrop Data</span>
                    <LegendCircle color={georefColors.outcrop} />
                  </div>
                )}
              </div>
            </div>
          </Well>
        </Form>
      </Formik>
    </div>
  );
}

type GeoreferenceMapProps = {
  studyGeoreferences: schema.GeoreferencePartsFragment[];
  outcropOutlines: OutcropWithOutline[];
  crossSections: QueryStudyType['crossSections'];
  sedimentaryLogs: QueryStudyType['sedimentaryLogs'];
  gigaPans: QueryStudyType['gigaPans'];
};

function GeoreferenceMap({
  studyGeoreferences,
  outcropOutlines,
  crossSections,
  sedimentaryLogs,
  gigaPans,
}: GeoreferenceMapProps) {
  const { values } = useFormikContext<VisibilityFilters>();
  const [map, setMap] = useState<google.maps.Map>();
  const [infoWindow, setInfoWindow] = useState<JSX.Element | null>(null);

  const fitVisibleGeoreferences = useCallback(
    (map: google.maps.Map) => {
      const bounds = createBounds([
        ...(values.study ? studyGeoreferences : []),
        ...(values.outcrops ? outcropOutlines.map(oco => oco.outline) : []),
        ...(values.crossSections
          ? crossSections.flatMap(cs => cs.georeference)
          : []),
        ...(values.sedimentaryLogs
          ? sedimentaryLogs.flatMap(cs => cs.georeference)
          : []),
        ...(values.gigaPans ? gigaPans.flatMap(gp => gp.georeference) : []),
      ]);

      map.fitBounds(bounds);
    },
    [
      values,
      studyGeoreferences,
      outcropOutlines,
      crossSections,
      sedimentaryLogs,
      gigaPans,
    ],
  );

  function handleLoad(map: google.maps.Map) {
    setMap(map);
  }

  useEffect(() => {
    if (map) {
      fitVisibleGeoreferences(map);
      setInfoWindow(null);
    }
  }, [map, values, fitVisibleGeoreferences]);

  function handleStudyElementClicked(
    georeference: schema.GeoreferencePartsFragment,
    event: google.maps.MapMouseEvent,
  ) {
    const position = event.latLng;
    if (!position) {
      setInfoWindow(null);
      return;
    }

    setInfoWindow(
      <InfoWindowF position={position} onCloseClick={() => setInfoWindow(null)}>
        <>
          <Heading level={4}>Study</Heading>
          <div className="space-y-0">
            <p>{georeference.name}</p>
            {georeference.description && (
              <p className="text-sm">{georeference.description}</p>
            )}
          </div>
        </>
      </InfoWindowF>,
    );
  }

  function handleOutcropOutlineClick(
    oco: OutcropWithOutline,
    event: google.maps.MapMouseEvent,
  ) {
    const position = event.latLng;
    if (!position) {
      setInfoWindow(null);
      return;
    }

    setInfoWindow(
      <InfoWindowF position={position} onCloseClick={() => setInfoWindow(null)}>
        <>
          <Heading level={4}>{oco.outcrop.name}</Heading>
          <Link to={outcropRoute(oco.outcrop.id)} className="link">
            View outcrop &raquo;
          </Link>
        </>
      </InfoWindowF>,
    );
  }

  function handleSOClicked(
    label: string,
    so: { name: string },
    georeference: schema.GeoreferencePartsFragment,
    event: google.maps.MapMouseEvent,
  ) {
    const position = event.latLng;
    if (!position) {
      setInfoWindow(null);
      return;
    }

    setInfoWindow(
      <InfoWindowF position={position} onCloseClick={() => setInfoWindow(null)}>
        <>
          <Heading level={4}>{label}</Heading>
          <div className="space-y-0">
            <p>
              <strong>{so.name}</strong>
            </p>
            <p>{georeference.name}</p>
            {georeference.description && <p>{georeference.description}</p>}
          </div>
        </>
      </InfoWindowF>,
    );
  }

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '400px' }}
      mapTypeId={google.maps.MapTypeId.TERRAIN}
      onLoad={handleLoad}
      onClick={() => setInfoWindow(null)}
    >
      {values.outcrops &&
        outcropOutlines.map(oco =>
          createPolygon(oco.outline, {
            key: oco.outline.id,
            options: polygonOptions({
              strokeColor: fields.outcrops.color,
              fillColor: fields.outcrops.color,
            }),
            onClick: e => handleOutcropOutlineClick(oco, e),
          }),
        )}

      {values.crossSections &&
        crossSections.map(cs =>
          cs.georeference
            .slice()
            .sort(sortGeoreferenceVisibility)
            .map(g =>
              createEntity(g, {
                sharedProps: {
                  key: g.id,
                  onClick: e => handleSOClicked('Cross Section', cs, g, e),
                },
                markerProps: {
                  icon: markerIcon({
                    stroke: fields.crossSections.color,
                    fill: fields.crossSections.color,
                  }),
                },
                polygonProps: {
                  options: polygonOptions({
                    strokeColor: fields.crossSections.color,
                  }),
                },
                polylineProps: {
                  options: polygonOptions({
                    strokeColor: fields.crossSections.color,
                  }),
                },
              }),
            ),
        )}

      {values.sedimentaryLogs &&
        sedimentaryLogs.map(sl =>
          sl.georeference
            .slice()
            .sort(sortGeoreferenceVisibility)
            .map(g =>
              createEntity(g, {
                sharedProps: {
                  key: g.id,
                  onClick: e => handleSOClicked('Sedimentary Log', sl, g, e),
                },
                markerProps: {
                  icon: markerIcon({
                    stroke: fields.sedimentaryLogs.color,
                    fill: fields.sedimentaryLogs.color,
                  }),
                },
                polygonProps: {
                  options: polygonOptions({
                    strokeColor: fields.sedimentaryLogs.color,
                  }),
                },
                polylineProps: {
                  options: polygonOptions({
                    strokeColor: fields.sedimentaryLogs.color,
                  }),
                },
              }),
            ),
        )}

      {values.gigaPans &&
        gigaPans.map(gp =>
          gp.georeference
            .slice()
            .sort(sortGeoreferenceVisibility)
            .map(g =>
              createEntity(g, {
                sharedProps: {
                  key: g.id,
                  onClick: e =>
                    handleSOClicked(fields.gigaPans.label, gp, g, e),
                },
                markerProps: {
                  icon: markerIcon({
                    stroke: fields.gigaPans.color,
                    fill: fields.gigaPans.color,
                  }),
                },
                polygonProps: {
                  options: polygonOptions({
                    strokeColor: fields.gigaPans.color,
                  }),
                },
                polylineProps: {
                  options: polygonOptions({
                    strokeColor: fields.gigaPans.color,
                  }),
                },
              }),
            ),
        )}

      {values.study &&
        studyGeoreferences
          .slice()
          .sort(sortGeoreferenceVisibility)
          .map(g =>
            createEntity(g, {
              sharedProps: {
                key: g.id,
                onClick: e => handleStudyElementClicked(g, e),
              },
              markerProps: {
                icon: markerIcon({
                  stroke: fields.crossSections.color,
                  fill: fields.crossSections.color,
                }),
              },
              polygonProps: {
                options: polygonOptions({
                  strokeColor: fields.crossSections.color,
                }),
              },
              polylineProps: {
                options: polygonOptions({
                  strokeColor: fields.crossSections.color,
                }),
              },
            }),
          )}

      {infoWindow}
    </GoogleMap>
  );
}
