import type { HTMLProps } from 'react';
import React from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type {
  CesiumAssetPartsFragment,
  FilePartsFragment,
  PicturePartsFragment,
  VomPartsFragment,
} from '~/apollo/generated/schema';
import { envVars } from '~/environment';
import { v3GeoModelViewerRoute, vomViewerRoute } from '~/paths';
import { isMini } from '~/utils/modules/vom';

// As described in: https://github.com/omt-tech/safari/issues/2109#issue-2225692875
// 1. If cesiumAsset does not exist, old link behaviour with old viewer
// 2. If VOM has cesiumAsset and no interpretations, goto new viewer
// 3. If VOM has cesiumAsset and has interpretations, main big div box link goes to new viewer and also display button (long rectangel? or similar) below div box with something like "Old viewer (with interpretations)" on it that goes to old viewer
// 4. (coming up soon, so plan for it) If vom has cesiumAsset and has interpretations and has <some to be defined cesium/vom interpretation data structure> only show goto new viewer
type UseCAViewerVom = Pick<VomPartsFragment, 'acquisitionType'> & {
  cesiumAsset?: Pick<CesiumAssetPartsFragment, 'approved'> | null;
};
export function isCesiumAssetReady(vom: UseCAViewerVom) {
  return vom.cesiumAsset?.approved;
}
export function shouldUseCesiumViewer(vom: UseCAViewerVom) {
  return !isMini(vom) && isCesiumAssetReady(vom);
}
export function shouldUseOptionalCesiumViewer(
  vom: UseCAViewerVom,
  interpreted: boolean,
) {
  return shouldUseCesiumViewer(vom) && interpreted;
}

type Props = {
  vom: Pick<VomPartsFragment, 'id' | 'v3GeoId' | 'outcropId'> & {
    cesiumAsset?: Pick<CesiumAssetPartsFragment, 'approved'> | null;
  };
  interpreted: boolean;
  picture?: PicturePartsFragment & {
    file: Pick<FilePartsFragment, 'signedUrl'>;
  };
  linkText?: React.ReactNode;
  children?: (externalUrl: string) => JSX.Element;
};

export function ExternalVOLink({
  vom,
  interpreted,
  picture,
  linkText = 'Click to start the virtual outcrop viewer.',
  children,
}: Props) {
  const showCesiumLink = shouldUseCesiumViewer(vom);
  const showOptionalOldLink =
    showCesiumLink && shouldUseOptionalCesiumViewer(vom, interpreted);

  const oldLink = `${envVars.VITE_CLIENT_URL}/vogviewer/index.html#/${vom.id}`;

  const link = (() => {
    if (vom.v3GeoId) {
      return v3GeoModelViewerRoute(vom.v3GeoId);
    } else if (showCesiumLink && vom.outcropId) {
      return vomViewerRoute(vom.outcropId, vom.id);
    } else {
      return oldLink;
    }
  })();

  if (children) return children(link);

  const linkType = showCesiumLink ? 'cesium' : 'external';

  const oldLinkProps: LinkComponentProps = {
    t: 'external',
    href: oldLink,
    target: '_blank',
  };

  const linkCmpProps: LinkComponentProps =
    linkType === 'cesium' ? { t: 'cesium', to: link } : oldLinkProps;

  if (!picture) {
    return <LinkComponent {...linkCmpProps}>{linkText}</LinkComponent>;
  }

  return (
    <div className="space-y-1">
      <LinkComponent
        {...linkCmpProps}
        className="thumbnail border-2 border-primary block shadow-md link"
      >
        <img src={picture.file.signedUrl} alt="Model thumbnail" />
        <div className="text-center text-sm">{linkText}</div>
      </LinkComponent>
      {showOptionalOldLink && <OldLink {...oldLinkProps} />}
    </div>
  );
}

type LinkComponentProps =
  | ({ t: 'cesium' } & LinkProps)
  | ({ t: 'external' } & HTMLProps<HTMLAnchorElement>);

function LinkComponent(props: LinkComponentProps) {
  if (props.t === 'cesium') {
    const { t, ...linkProps } = props;
    return <Link {...linkProps}>{props.children}</Link>;
  }

  const { t, ...aProps } = props;
  return (
    <a {...aProps} rel="noreferrer">
      {props.children}
    </a>
  );
}

function OldLink(props: LinkComponentProps) {
  return (
    <LinkComponent {...props} className="btn btn-primary btn-xs btn-block">
      Old Viewer (with Interpretations)
    </LinkComponent>
  );
}
