import type { LithostratAgePartsFragment , LithostratAgeInput } from '~/apollo/generated/schema';

export type LithostratAgeFormValues = {
  eon: string;
  era: string;
  system: string;
  series: string;
  stage: string;
  comments: string;
};

export const initialLithostratAge = (
  age?: LithostratAgePartsFragment | null,
): LithostratAgeFormValues => ({
  eon: age?.eon ?? '',
  era: age?.era ?? '',
  system: age?.system ?? '',
  series: age?.series ?? '',
  stage: age?.stage ?? '',
  comments: age?.comments ?? '',
});

export function mostSpecificAge(
  age?: Pick<LithostratAgePartsFragment, 'eon' | 'era' | 'system'> | null,
): string | null {
  if (!age) return null;
  return age.system ?? age.era ?? age.eon ?? null;
}

export const ageSortOrder = [
  'Quaternary',
  'Neogene',
  'Paleogene',
  'Cretaceous',
  'Jurassic',
  'Triassic',
  'Permian',
  'Carboniferous',
  'Devonian',
  'Silurian',
  'Ordovician',
  'Cambrian',
];

export function toLithostratAgeInput(
  values: LithostratAgeFormValues,
  id?: number,
): LithostratAgeInput {
  const valueOrNull = (val: string) => (val.length > 0 ? val : null);

  return {
    id,
    eon: valueOrNull(values.eon),
    era: valueOrNull(values.era),
    system: valueOrNull(values.system),
    series: valueOrNull(values.series),
    stage: valueOrNull(values.stage),
    comments: valueOrNull(values.comments),
  };
}
