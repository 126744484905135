import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';
import * as fragments from '~/apollo/fragments';
import type { KPsMissingGtQuery } from '~/apollo/generated/schema';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useSortFilter } from '~/hooks/data';
import {
  uploadLithostratFormationUpdateWikiRoute,
  uploadLithostratGroupUpdateWikiRoute,
  uploadLithostratMemberUpdateWikiRoute,
  uploadOutcropUpdateRoute,
  uploadStudyUpdateRoute,
} from '~/paths';
import { ucwords } from '~/utils/text';

const KPMGT_QUERY = gql`
  query KPsMissingGT {
    keyParametersMissingGeologyType {
      ...keyParametersParts
      outcropId
      studyId
      lithostratWikiPageId
      outcrop {
        id
        name
      }
      study {
        id
        name
      }
      lithostratWikiPage {
        id
        name
      }
    }

    lithostratGroupList {
      id
      wikiPageId
    }
    lithostratFormationList {
      id
      wikiPageId
    }
    lithostratMemberList {
      id
      wikiPageId
    }
  }

  ${fragments.keyParametersParts}
`;

type KeyParameters =
  KPsMissingGtQuery['keyParametersMissingGeologyType'][number];

type KPParentType = 'outcrop' | 'study' | 'lithostratWikiPage';
const kpParentType = (kp: KeyParameters): KPParentType => {
  if (kp.outcropId) return 'outcrop';
  if (kp.studyId) return 'study';
  if (kp.lithostratWikiPageId) return 'lithostratWikiPage';

  console.log('Invalid KP:', kp);
  throw new Error(`could not determine parent type`);
};

const kpParentId = (kp: KeyParameters) => {
  const id = kp.outcropId ?? kp.studyId ?? kp.lithostratWikiPageId;
  if (!id) {
    console.log('Invalid KP:', kp);
    throw new Error('could not determine parent id');
  }
  return id;
};
const kpParentName = (kp: KeyParameters): string => {
  const pt = kpParentType(kp);
  if (pt === 'outcrop' && kp.outcrop) return kp.outcrop?.name;
  if (pt === 'study' && kp.study) return kp.study?.name;
  if (pt === 'lithostratWikiPage' && kp.lithostratWikiPage)
    return kp.lithostratWikiPage?.name;

  console.log('Could not determine parent name', kp);
  throw new Error('Could not determine parent name');
};

const KeyParametersMissingGeologyTypePage: React.FC = () => {
  useBreadcrumb(
    'routes/upload/kps-missing-gt',
    'Key Parameters Missing Geology Type',
  );

  const { data, loading } = useQuery<KPsMissingGtQuery>(KPMGT_QUERY, {});

  type SortableKP = KeyParameters & {
    parentType: KPParentType;
    parentName: string;
    parentId: number;
    parentRoute: string | null;
  };

  const sortableKPs: SortableKP[] = (
    data?.keyParametersMissingGeologyType ?? []
  ).map(kp => ({
    ...kp,
    parentType: kpParentType(kp),
    parentName: kpParentName(kp),
    parentId: kpParentId(kp),
    parentRoute: kpParentRoute(kp),
  }));

  function kpParentRoute(kp: KeyParameters) {
    const pt = kpParentType(kp);
    const pid = kpParentId(kp);
    if (pt === 'outcrop') return uploadOutcropUpdateRoute(pid);
    if (pt === 'study') return uploadStudyUpdateRoute(pid);
    if (pt === 'lithostratWikiPage') return lithowikiParentRoute(pid);
    return null;
  }

  function lithowikiParentRoute(wikiPageId: number) {
    const groups = data?.lithostratGroupList ?? [];
    const group = groups.find(g => g.wikiPageId === wikiPageId);
    if (group) {
      return uploadLithostratGroupUpdateWikiRoute(group.id);
    }

    const formations = data?.lithostratFormationList ?? [];
    const formation = formations.find(fm => fm.wikiPageId === wikiPageId);
    if (formation) {
      return uploadLithostratFormationUpdateWikiRoute(formation.id);
    }

    const members = data?.lithostratMemberList ?? [];
    const member = members.find(mb => mb.wikiPageId === wikiPageId);
    if (member) {
      return uploadLithostratMemberUpdateWikiRoute(member.id);
    }

    return null;
  }

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    sortableKPs,
    'parentType',
    'parentName',
    'keyParametersMissingGeologyTypeTable',
  );

  return (
    <>
      <PageHeading>Key Parameters Missing Geology Type</PageHeading>

      <p className="text-base mb-4">
        The following key parameters could not be automatically assigned a
        geology type in the database. This most likely means that the values are
        no longer valid against the current version of the depositional
        hierarchy and need to be updated and/or deleted.
      </p>

      <Panel>
        <Panel.Heading>
          <Panel.Title>Key Parameters</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <table className="table table-auto table-compact w-full">
            <thead>
              <tr>
                <th className="w-2/12">
                  <SortTrigger
                    colName="parentType"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Type
                  </SortTrigger>
                </th>
                <th className="w-4/12">
                  <SortTrigger
                    colName="parentName"
                    sortIndicatorProps={siProps}
                  >
                    Parent
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="grossDepositionalEnvironment"
                    sortIndicatorProps={siProps}
                  >
                    GDE
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="depositionalEnvironment"
                    sortIndicatorProps={siProps}
                  >
                    DE
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="depositionalSubEnvironment"
                    sortIndicatorProps={siProps}
                  >
                    DSE
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="architecturalElement"
                    sortIndicatorProps={siProps}
                  >
                    AE
                  </SortTrigger>
                </th>
              </tr>
            </thead>

            <tbody>
              {loading && (
                <tr>
                  <td colSpan={6}>
                    <SpinnerPlaceholder />
                  </td>
                </tr>
              )}
              {!loading &&
                items.map(item => (
                  <tr key={item.id}>
                    <td>{ucwords(item.parentType)}</td>
                    <td>
                      {item.parentRoute ? (
                        <Link to={item.parentRoute} className="link">
                          {item.parentName}
                        </Link>
                      ) : (
                        item.parentName
                      )}
                    </td>
                    <td>{item.grossDepositionalEnvironment}</td>
                    <td>{item.depositionalEnvironment}</td>
                    <td>{item.depositionalSubEnvironment}</td>
                    <td>{item.architecturalElement}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
};

export default KeyParametersMissingGeologyTypePage;
