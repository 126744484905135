import React from 'react';
import { Field } from 'formik';
import { LithostratTypeSelector } from '~/components/upload/lithostratType/LithostratTypeSelector';
import { FormikField } from '~/components/common/FormikField';

export function GroupFormFields() {
  return (
    <div className="space-y-2">
      <Field name="group.name" label="Name" component={FormikField} required />
      <Field
        name="group.description"
        label="Description"
        component={FormikField}
        type="textarea"
        required
      />

      <Field
        name="group.lithostratTypeId"
        label="Lithostrat type"
        component={FormikField}
        type={LithostratTypeSelector}
      />

      <Field
        name="group.comments"
        label="Comments"
        component={FormikField}
        type="textarea"
      />
    </div>
  );
}
