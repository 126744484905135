import { cn } from '~/utils/common';
import type { ComponentPropsWithoutRef } from 'react';

export type ProseProps = ComponentPropsWithoutRef<'div'>;

export function Prose({ className, ...props }: ProseProps) {
  return (
    <div
      {...props}
      className={cn(
        // 'prose-p:text-justify',
        'max-w-full prose prose-p:text-base prose-p:leading-normal',
        'prose-headings:font-semibold prose-h5:text-base prose-h4:text-lg prose-h3:text-xl prose-h2:text-2xl prose-h1:text-3xl',
        'prose-img:inline-block prose-img:max-w-full prose-img:max-h-auto prose-a:link prose-a:link-primary',
        'first:prose-p:mt-0', // Remove initial margin on the first line of text, we will position this manually if needed
        'prose-li:my-0 prose-li:text-base',
        className,
      )}
    />
  );
}
