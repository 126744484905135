import invariant from 'tiny-invariant';
import type {
  OutcropSubregionInput,
  UpdateOutcropSubregionPartsFragment,
} from '~/apollo/generated/schema';
import { yup } from '~/utils/validation';

export type OutcropSubregionFormValues = {
  name: string;
  description: string;
  projectId: number | null;
  outcropIds: number[];
  regionIds: number[];
};

export function outcropSubregionInitialFormValues(
  sr?: UpdateOutcropSubregionPartsFragment,
): OutcropSubregionFormValues {
  return {
    name: sr?.name ?? '',
    description: sr?.description ?? '',
    projectId: sr?.projectId ?? null,
    regionIds: sr?.regions.map(r => r.id) ?? [],
    outcropIds: sr?.outcrops.map(oc => oc.id) ?? [],
  };
}

export const outcropSubregionValidationSchema = yup.object({
  name: yup.string().min(1).required(),
  description: yup.string().nullable().optional(),
  projectId: yup.number().integer().positive().required(),
  outcropIds: yup.array(yup.number().integer().required()).min(1).required(),
  regionIds: yup.array(yup.number().integer().required()).min(1).required(),
});

export function outcropSubregionFormValuesToInput(
  fv: OutcropSubregionFormValues,
): OutcropSubregionInput {
  invariant(fv.projectId, 'Project ID missing!');

  return {
    name: fv.name,
    description: fv.description,
    projectId: fv.projectId,
    regionIds: fv.regionIds,
    outcropIds: fv.outcropIds,
  };
}
