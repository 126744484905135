import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  UpdateLithostratFormationMutation,
  UpdateLithostratFormationMutationVariables,
} from '~/apollo/generated/schema';
import { UPDATE_LITHOSTRAT_FORMATION } from '~/apollo/operations/lithostrat';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { LithostratAgeFormFields } from '~/components/upload/lithostratAge/LithostratAgeFormFields';
import { useUpdateFormationOutletContext } from '~/routes/upload/lithostrat/formation/$formationId';
import {
  formValuesToFormationInput,
  initialLithostratFormation,
} from '~/utils/modules/lithostrat';
import type { LithostratAgeFormValues } from '~/utils/modules/lithostratAge';
import { initialLithostratAge } from '~/utils/modules/lithostratAge';

type FormValues = {
  startAge: LithostratAgeFormValues;
  endAge: LithostratAgeFormValues;
};

export default function FormationUpdateGeologicalAgeRoute() {
  const { formation, refetchQueries } = useUpdateFormationOutletContext();

  const [updateFormation, { loading, error }] = useMutation<
    UpdateLithostratFormationMutation,
    UpdateLithostratFormationMutationVariables
  >(UPDATE_LITHOSTRAT_FORMATION, {
    refetchQueries,
  });

  async function handleSubmit(values: FormValues) {
    const formationFormValues = initialLithostratFormation(formation);
    const formationInput = {
      ...formValuesToFormationInput(formationFormValues),
      startAge: values.startAge ?? null,
      endAge: values.endAge ?? null,
    };

    try {
      await updateFormation({
        variables: {
          id: formation.id,
          formation: formationInput,
        },
      });
      toast.success('Formation updated successfully.');
    } catch (err) {
      console.log('Error updating formation', err);
      toast.error(
        'There was a problem updating the formation. Please try again.',
      );
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        startAge: initialLithostratAge(formation.startAge),
        endAge: initialLithostratAge(formation.endAge),
      }}
    >
      <Form className="space-y-4">
        <div className="grid lg:grid-cols-2 gap-6">
          <div>
            <Panel>
              <Panel.Heading>
                <Panel.Title>Start Age</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <LithostratAgeFormFields ageName="startAge" />
              </Panel.Body>
            </Panel>
          </div>

          <div>
            <Panel>
              <Panel.Heading>
                <Panel.Title>End Age</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <LithostratAgeFormFields ageName="endAge" />
              </Panel.Body>
            </Panel>
          </div>
        </div>

        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
