import { gql, useQuery } from '@apollo/client';
import * as R from 'ramda';
import type {
  UploadRegionStudiesPageQuery,
  UploadRegionStudiesPageQueryVariables,
} from '~/apollo/generated/schema';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import StudyList from '~/components/upload/study/StudyList';
import { useSortFilter } from '~/hooks/data';
import { toSortableStudy } from '~/routes/upload/model/__uploadModelsPage/studies';
import { useUploadRegionPageContext } from '~/routes/upload/model/region/$regionId';

const UPLOAD_REGION_STUDIES_PAGE = gql`
  query UploadRegionStudiesPage($regionId: Int!) {
    regionList(id: $regionId) {
      id
      outcrops {
        id
        studies {
          id
          name
          type
          approved
          readyForApproval
          redundant
          qcCompleted

          qualityParameters {
            id
            publicationType
          }
          dataHistory {
            id
            collectedBy
          }
          project {
            id
            name
          }
          outcrops {
            id
            name
          }
          architecturalElements {
            id
            measurementCount
          }
        }
      }
    }
  }
`;

export function UploadRegionStudiesPage() {
  const { region } = useUploadRegionPageContext();

  const { data, loading } = useQuery<
    UploadRegionStudiesPageQuery,
    UploadRegionStudiesPageQueryVariables
  >(UPLOAD_REGION_STUDIES_PAGE, {
    variables: { regionId: region.id },
  });

  const studies = R.pipe(
    () => data?.regionList ?? [],
    regions => regions.flatMap(region => region.outcrops),
    outcrops => outcrops.flatMap(oc => oc.studies),
    studies => studies.map(toSortableStudy),
    studies => R.uniqBy(s => s.id, studies),
  )();

  const { items, sortIndicatorProps, filterSearchProps } = useSortFilter(
    studies,
    'id',
    'name',
    'uploadRegionStudyList',
  );

  if (loading) {
    return <SpinnerPlaceholder />;
  }

  return (
    <StudyList
      studies={items}
      sortIndicatorProps={sortIndicatorProps}
      filterSearchProps={filterSearchProps}
    />
  );
}
