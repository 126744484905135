import { faMobileScreen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ComponentPropsWithoutRef } from 'react';
import type { VirtualOutcropDetailTabQuery } from '~/apollo/generated/schema';
import {
  BookmarkParentType,
  BookmarkTargetType,
} from '~/apollo/generated/schema';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import type { HeadingProps } from '~/components/common/Heading';
import { Heading } from '~/components/common/Heading';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { Prose } from '~/components/common/Prose';
import { Tooltip } from '~/components/common/Tooltip';
import { ExternalVOLink } from '~/components/vom/ExternalVOLink';
import { VomGeoreferencesMap } from '~/components/vom/VomDetailPage/GeoreferencesMap';
import { vomRoute } from '~/paths';
import { isMini } from '~/utils/modules/vom';

const SectionHeading = (props: Omit<HeadingProps, 'level'>) => (
  <Heading {...props} level={3} />
);

const TableUnbordered = ({ children }: ComponentPropsWithoutRef<'table'>) => (
  <table className="table table-compact w-full bg-transparent">
    {children}
  </table>
);

type Props = {
  vom: VirtualOutcropDetailTabQuery['virtualOutcropModelList'][number];
};

export function VomDetailPage({ vom }: Props) {
  const pictures = vom.pictures ?? [];

  return (
    <>
      {vom.outcropId && (
        <div className="float-right">
          <TargetBookmarksManagerModal
            parentType={BookmarkParentType.Outcrop}
            parentId={vom.outcropId}
            targetType={BookmarkTargetType.Vom}
            targetId={vom.id}
            path={vomRoute(vom.outcropId, vom.id)}
          />
        </div>
      )}

      <PageHeading>
        {isMini(vom) && (
          <Tooltip message="Mini-Model">
            <FontAwesomeIcon
              icon={faMobileScreen}
              className="text-warning mr-2"
            />
          </Tooltip>
        )}
        {vom.name}
        {vom.interpreted && (
          <em style={{ marginLeft: '10px' }}>(interpreted)</em>
        )}
      </PageHeading>
      <div className="clear-both" />

      <div className="grid lg:grid-cols-12 gap-6">
        <div className="lg:col-span-7 space-y-4">
          <ExternalVOLink
            vom={vom}
            picture={pictures[0]}
            interpreted={vom.interpreted}
          />

          {vom.quality && (
            <div>
              <SectionHeading>Virtual Outcrop Quality</SectionHeading>
              <Prose dangerouslySetInnerHTML={{ __html: vom.quality }} />
            </div>
          )}

          {vom.notableAspects && (
            <div>
              <SectionHeading>Notable Aspects</SectionHeading>
              <Prose dangerouslySetInnerHTML={{ __html: vom.notableAspects }} />
            </div>
          )}
        </div>

        <div className="lg:col-span-5 space-y-4">
          {vom.georeference && vom.georeference.length > 0 && (
            <VomGeoreferencesMap georeferences={vom.georeference} />
          )}

          {pictures && pictures[1] && (
            <div>
              <img
                src={pictures[1].file.signedUrl}
                className="w-full h-auto"
                alt={pictures[1].file.name ?? undefined}
              />
            </div>
          )}

          <Panel>
            <Panel.Body className="space-y-4">
              <div>
                <SectionHeading>Authorship &amp; copyright</SectionHeading>
                {vom.copyright &&
                  vom.copyright.length &&
                  vom.copyright.join(', ')}
              </div>

              <div>
                <SectionHeading>VO Model Size</SectionHeading>
                <TableUnbordered>
                  <tbody>
                    <tr>
                      <th>Max VO Width</th>
                      <td>{vom.size}</td>
                    </tr>
                    <tr>
                      <th>Smallest Visible Feature</th>
                      <td>{vom.smallestVisibleFeature}</td>
                    </tr>
                  </tbody>
                </TableUnbordered>
              </div>

              <div>
                <SectionHeading>VO Acquisition</SectionHeading>
                <TableUnbordered>
                  <tbody>
                    <tr>
                      <th>Year</th>
                      <td>{vom.acquisitionYear}</td>
                    </tr>
                    <tr>
                      <th>Type</th>
                      <td>{vom.acquisitionType}</td>
                    </tr>
                    <tr>
                      <th>Equipment</th>
                      <td>{vom.acquisitionEquipment}</td>
                    </tr>
                  </tbody>
                </TableUnbordered>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </>
  );
}
