import type { PureQueryOptions } from '@apollo/client';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import * as R from 'ramda';
import { Badge } from 'react-daisyui';
import type { BookmarkParentType } from '~/apollo/generated/schema';
import { BookmarkTargetType } from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { InfoIcon } from '~/components/common/icons/InfoIcon';
import { PictureThumbnail } from '~/components/common/PictureThumbnail';
import { Tooltip } from '~/components/common/Tooltip';
import { soBookmarkPath } from '~/utils/modules/bookmark';
import type { SupportObject } from '~/utils/modules/supportObject';
import { fields } from '~/utils/modules/supportObject';

type NumberFieldProps = {
  label: string;
  value?: number | null;
  helpText?: string;
};
function NumberField({ label, value, helpText }: NumberFieldProps) {
  if (typeof value !== 'number') return null;

  return (
    <tr>
      <td className="text-right p-1 cursor-pointer">
        <Tooltip disabled={!helpText} message={helpText ?? ''}>
          <span className="space-x-1">
            {label}
            {helpText && <InfoIcon />}
          </span>
        </Tooltip>
      </td>
      <td>
        <div className="p-1 bg-slate-100 font-mono">{value}</div>
      </td>
    </tr>
  );
}

export type BookmarkableSOProps = {
  targetType: BookmarkTargetType;
};

type Props = {
  item?: SupportObject;
  fade?: boolean;
  numCols?: number;
  soParentType: 'outcrop' | 'study';
  soParentId: number;
  bookmarkParentType: BookmarkParentType;
  bookmarkParentId: number;
  showUnpublished?: boolean;
  refetchQueries: PureQueryOptions[];
};

export function SupportingObjectTabRowItem({
  item,
  fade = false,
  soParentType,
  soParentId,
  bookmarkParentType,
  bookmarkParentId,
  showUnpublished,
  refetchQueries,
}: Props) {
  if (!item) return null;

  function pictures() {
    if (!item || !('pictures' in item)) return [];
    return R.pipe(
      () => item.pictures,
      pictures => R.sortBy(p => p.priority ?? Infinity, pictures),
      pictures => {
        if (showUnpublished) {
          return pictures;
        } else {
          return pictures.filter(p => p.published);
        }
      },
    )();
  }

  function files() {
    if (!item || !('files' in item)) return [];
    return item.files ?? [];
  }

  return (
    <div
      className={cn('space-y-1', {
        'border-4 border-info border-dotted p-2 relative': !item.published,
      })}
    >
      <Heading level={4}>{item.name}</Heading>

      <div className="text-sm text-slate-700 space-y-1">
        {'author' in item && item.author && (
          <div className="space-x-2">
            <Badge color="ghost">Author</Badge>
            <span>{item.author}</span>
          </div>
        )}

        {'interpretation' in item && item.interpretation && (
          <div className="space-x-2">
            <Tooltip message="Interpretation">
              <Badge color="ghost">Interp.</Badge>
            </Tooltip>
            <span>{item.interpretation}</span>
          </div>
        )}

        {'description' in item && (
          <div className="text-base">{item.description}</div>
        )}
      </div>

      <table>
        <tbody>
          {'nugget' in item && (
            <NumberField
              label="Nugget"
              value={item.nugget}
              helpText={fields.NUGGET.helpText}
            />
          )}
          {'sill' in item && (
            <NumberField
              label="Sill"
              value={item.sill}
              helpText={fields.SILL.helpText}
            />
          )}
          {'range' in item && (
            <NumberField
              label="Range"
              value={item.range}
              helpText={fields.RANGE.helpText}
            />
          )}
        </tbody>
      </table>

      <div className="space-y-2">
        {pictures().map(picture => (
          <PictureThumbnail
            key={picture.id}
            picture={picture}
            imgSrc={picture.file.signedUrl}
            fade={fade}
            bookmarkable={{
              parentType: bookmarkParentType,
              parentId: bookmarkParentId,
              targetType: BookmarkTargetType.Picture,
              targetId: picture.id,
              path: soBookmarkPath(
                soParentType,
                soParentId,
                bookmarkParentType,
                item.outcropTagId,
              ),
            }}
            refetchQueries={refetchQueries}
          />
        ))}
      </div>

      {files().map(file => (
        <div key={file.id}>
          <a
            href={file.signedUrl}
            target="_blank"
            rel="noreferrer"
            className="link break-words"
          >
            {file.name}
          </a>
        </div>
      ))}

      {!item.published && (
        <FontAwesomeIcon
          icon={faEyeSlash}
          className="absolute top-2 right-2 opacity-25 text-info text-3xl"
        />
      )}
    </div>
  );
}
