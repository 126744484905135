import { gql, useQuery } from '@apollo/client';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Cartesian3, Cartographic, HeadingPitchRoll } from 'cesium';
import { useState } from 'react';
import { Form as DaisyForm, Toggle } from 'react-daisyui';
import { Link, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import {
  CesiumAssetState,
  type VomViewerPageQuery,
  type VomViewerPageQueryVariables,
} from '~/apollo/generated/schema';
import { CesiumViewer } from '~/components/cesium/CesiumViewer';
import type { TilesetParams } from '~/components/cesium/cesiumUtils';
import { TerrainProvider } from '~/components/cesium/cesiumUtils';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import type { BreadcrumbItem } from '~/components/layout/Breadcrumb';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import {
  outcropRoute,
  regionRoute,
  searchRoute,
  uploadVomUpdateModelDataRoute,
  vomRoute,
} from '~/paths';

const VOM_VIEWER_PAGE = gql`
  query VomViewerPage($vomId: Int!) {
    virtualOutcropModelList(id: $vomId) {
      id
      name
      cesiumAsset {
        id
        assetToken
        localPath
        state
        isClipping
        location {
          latitude
          longitude
          height
          heading
          pitch
          roll
        }
        defaultCamera {
          latitude
          longitude
          height
          heading
          pitch
          roll
        }
      }
      outcrop {
        id
        name
        region {
          id
          name
        }
      }
    }
  }
`;

export default function VomViewerRouteWrapper() {
  const params = useParams();
  invariant(params.vomId);
  const vomId = parseInt(params.vomId);

  const { data, loading } = useQuery<
    VomViewerPageQuery,
    VomViewerPageQueryVariables
  >(VOM_VIEWER_PAGE, {
    variables: { vomId },
  });
  const vom = data?.virtualOutcropModelList.find(v => v.id === vomId);

  if (loading) return <SpinnerPlaceholder />;
  if (!vom) return <NotFound />;
  if (!vom.cesiumAsset) return <NotFound />;

  const cesiumAsset = vom.cesiumAsset;

  return <VomViewerRoute vom={vom} cesiumAsset={cesiumAsset} />;
}

function VomViewerRoute({
  vom,
  cesiumAsset,
}: {
  vom: VomViewerPageQuery['virtualOutcropModelList'][number];
  cesiumAsset: NonNullable<
    VomViewerPageQuery['virtualOutcropModelList'][number]['cesiumAsset']
  >;
}) {
  const [showGlobe, setShowGlobe] = useState(true);
  const [tilesetClipping] = useState(cesiumAsset.isClipping);
  const breadcrumb: BreadcrumbItem[] | undefined = vom?.outcrop
    ? [
        {
          routeId: 'routes/search',
          pathname: searchRoute(),
          title: 'Search Analogues',
        },
        {
          routeId: 'routes/region/$regionId',
          pathname: regionRoute(vom.outcrop.region.id),
          title: vom.outcrop.region.name,
        },
        {
          routeId: 'routes/outcrop/$outcropId',
          pathname: outcropRoute(vom.outcrop.id),
          title: vom.outcrop.name,
        },
        {
          routeId: 'routes/outcrop/$outcropId/virtual-outcrops/$vomId',
          pathname: vomRoute(vom.outcrop.id, vom.id),
          title: vom.name,
        },
      ]
    : undefined;

  useBreadcrumb(
    'routes/outcrop/$outcropId/virtual-outcrops/$vomId/viewer',
    'VOM Viewer',
    undefined,
    breadcrumb,
  );

  if (!cesiumAsset.localPath) return <NotFound />;
  if (!cesiumAsset.assetToken) return <NotFound />;

  const ca: TilesetParams = {
    assetToken: cesiumAsset.assetToken,
    localPath: cesiumAsset.localPath,
    transform: { local: true },
  };

  if (cesiumAsset.location) {
    ca.transform = {
      location: Cartographic.fromDegrees(
        cesiumAsset.location.longitude,
        cesiumAsset.location.latitude,
        cesiumAsset.location.height,
      ),
      orientation: new HeadingPitchRoll(
        cesiumAsset.location.heading,
        cesiumAsset.location.pitch,
        cesiumAsset.location.roll,
      ),
    };
  }

  if (cesiumAsset.defaultCamera) {
    ca.defaultCamera = {
      position: Cartesian3.fromDegrees(
        cesiumAsset.defaultCamera.longitude,
        cesiumAsset.defaultCamera.latitude,
        cesiumAsset.defaultCamera.height,
      ),
      orientation: new HeadingPitchRoll(
        cesiumAsset.defaultCamera.heading,
        cesiumAsset.defaultCamera.pitch,
        cesiumAsset.defaultCamera.roll,
      ),
    };
  }

  const setTerrain = () => {
    if (cesiumAsset.state === CesiumAssetState.Complete) {
      return TerrainProvider.World;
    }
    return TerrainProvider.None;
  };

  return (
    <>
      <div className="flex gap-6 justify-between items-baseline">
        <div>
          <PageHeading hasSubtitle className="mt-0">
            VOM Viewer
          </PageHeading>
          <PageHeading.Subtitle>{vom.name}</PageHeading.Subtitle>
        </div>
        <div>
          <Link
            to={uploadVomUpdateModelDataRoute(vom.id)}
            className="btn btn-ghost btn-sm gap-1"
          >
            <FontAwesomeIcon icon={faPencil} /> Edit
          </Link>
        </div>
      </div>

      <CesiumViewer
        initialTilesets={[ca]}
        terrainProvider={setTerrain()}
        enableNavigation={true}
        tilesetClipping={tilesetClipping}
        showGlobe={showGlobe}
      />
      <div className="float-right	">
        <DaisyForm.Label title="Show Globe">
          <Toggle
            className="m-2"
            color="primary"
            checked={showGlobe}
            onChange={e => {
              setShowGlobe(e.target.checked);
            }}
          />
        </DaisyForm.Label>
      </div>
    </>
  );
}
