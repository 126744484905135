import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';

type Props = {
  disabled?: boolean;
};

export function BookmarkCollectionFormFields({ disabled = false }: Props) {
  return (
    <div className="space-y-2">
      <Field
        name="name"
        label="Collection name"
        component={FormikField}
        required
        disabled={disabled}
      />
      <Field
        name="description"
        label="Description"
        component={FormikField}
        type="textarea"
        rows={3}
        disabled={disabled}
      />
    </div>
  );
}
