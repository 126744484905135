import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';

import * as fragments from '~/apollo/fragments';
import {
  CREATE_TRAINING_IMAGE,
  DELETE_TRAINING_IMAGE,
  UPDATE_TRAINING_IMAGE,
} from '~/apollo/operations/supportingObject';
import type {
  UploadOutcropUpdateTrainingImagesTabQuery,
  UploadOutcropUpdateTrainingImagesTabQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

export const TRAINING_IMAGES_TAB = gql`
  query UploadOutcropUpdateTrainingImagesTab($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      trainingImages {
        ...trainingImageParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        files {
          ...fileParts
        }
        outcropTag {
          ...outcropParts
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.trainingImageParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function UpdateOutcropTrainingImagesRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UploadOutcropUpdateTrainingImagesTabQuery,
    UploadOutcropUpdateTrainingImagesTabQueryVariables
  >(TRAINING_IMAGES_TAB, {
    variables: { id: outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropUpdateTrainingImagesTabQueryVariables>,
  ] = [
    {
      query: TRAINING_IMAGES_TAB,
      variables: { id: outcropId },
    },
  ];

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);
  const trainingImages = outcrop?.trainingImages ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <SupportObjectManager
      outcropId={outcropId}
      supportObject={trainingImages}
      supportObjectType="trainingImage"
      parentType="TRAINING_IMAGE"
      createMutation={CREATE_TRAINING_IMAGE}
      updateMutation={UPDATE_TRAINING_IMAGE}
      deleteMutation={DELETE_TRAINING_IMAGE}
      refetchQueries={refetchQueries}
    />
  );
}
