import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';

import { STUDY_ENUMERATIONS } from '~/apollo/operations/study';
import type { StudyEnumerationsQuery } from '~/apollo/generated/schema';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { CreateStudyForm } from '~/components/upload/study/CreateStudyForm';
import { UploadStudyNavigation } from './$studyId/__UploadStudyNavigation';

export default function UploadStudyCreatePage() {
  useBreadcrumb('routes/upload/model.study/create', 'Create Study');

  // An outcropId may be provided in the query string to set an initial OC link
  const [query] = useSearchParams();
  const queryOCId = query.get('outcropId');
  const outcropId = queryOCId ? parseInt(queryOCId) : null;

  const { data, loading } = useQuery<StudyEnumerationsQuery>(
    STUDY_ENUMERATIONS,
    {},
  );

  return (
    <>
      <PageHeading>Create Study</PageHeading>

      <div className="grid grid-cols-12">
        <div className="lg:col-span-2">
          <UploadStudyNavigation studyId={-1} disabled />
        </div>

        <div className="lg:col-span-10">
          <SpinnerPlaceholder show={loading} />
          {!loading && data && (
            <CreateStudyForm outcropId={outcropId} enumOptions={data} />
          )}
        </div>
      </div>
    </>
  );
}
