import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import type {
  CreateLithostratFormationMutation,
  CreateLithostratFormationMutationVariables,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { PageHeading } from '~/components/common/PageHeading';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { FormationFormFields } from '~/components/upload/lithostrat/formation/FormationFormFields';
import { useQueryString } from '~/hooks/routing';
import { uploadLithostratFormationUpdateRoute } from '~/paths';
import type { FormationFormValues } from '~/utils/modules/lithostrat';
import {
  formValuesToFormationInput,
  formationValidationSchema,
  initialLithostratFormation,
} from '~/utils/modules/lithostrat';

export const CREATE_LITHOSTRAT_FORMATION = gql`
  mutation createLithostratFormation($formation: LithostratFormationInput!) {
    createLithostratFormation(formation: $formation) {
      id
    }
  }
`;

type SearchQuery = {
  groupId?: string;
};

export default function UploadFormationCreateRoute() {
  useBreadcrumb(
    'routes/upload/lithostrat/formation/create',
    'Create Formation',
  );

  const { query } = useQueryString<SearchQuery>();
  const navigate = useNavigate();

  const [createFormation, { loading, error }] = useMutation<
    CreateLithostratFormationMutation,
    CreateLithostratFormationMutationVariables
  >(CREATE_LITHOSTRAT_FORMATION, {});

  async function handleSubmit(values: FormationFormValues) {
    const formationInput = formValuesToFormationInput(values);

    try {
      const { data } = await createFormation({
        variables: { formation: formationInput },
      });
      invariant(data);
      toast.success('Formation created successfully.');
      const formationId = data.createLithostratFormation.id;
      navigate(uploadLithostratFormationUpdateRoute(formationId));
    } catch (err) {
      console.log('Error creating formation', err);
      toast.error('There was a problem creating the formation.');
    }
  }

  return (
    <>
      <PageHeading>Create Formation</PageHeading>

      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          ...initialLithostratFormation(),
          groupId: query.groupId ? parseInt(query.groupId) : null,
        }}
        validationSchema={formationValidationSchema()}
      >
        <Form className="space-y-4">
          <FormationFormFields />
          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  );
}
