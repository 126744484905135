import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { CompanyOptionsFields } from './CompanyOptionsFields';

type Props = {
  isSSO?: boolean;
};

function CompanyFormFields({ isSSO = false }: Props) {
  return (
    <>
      <Field
        name="name"
        label="Company name"
        component={FormikField}
        required
      />

      <Field
        name="safariLevel"
        label="Safari level"
        component={FormikField}
        type="select"
        options={[1, 2, 3].map(value => ({ value, label: value }))}
        required
      />

      <CompanyOptionsFields isSSO={isSSO} />
    </>
  );
}

export default CompanyFormFields;
