import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';

export function ProjectFormFields() {
  return (
    <div className="space-y-2">
      <Field name="name" label="Name" component={FormikField} required />
      <Field
        name="description"
        label="Description"
        component={FormikField}
        type="textarea"
        required
      />
      <Field
        name="comments"
        label="Comments"
        component={FormikField}
        type="textarea"
      />
      <Field
        name="hidden"
        label="Hidden"
        component={FormikField}
        type="checkbox"
      />
    </div>
  );
}
