import type { PureQueryOptions } from '@apollo/client';
import { useMutation , gql } from '@apollo/client';
import { toast } from 'react-toastify';

import type {
  DeleteGroupMutation,
  DeleteGroupMutationVariables,
} from '~/apollo/generated/schema';

const DELETE_GROUP = gql`
  mutation DeleteGroup($id: Int!) {
    deleteLithostratGroup(id: $id)
  }
`;

type Props = {
  id: number;
  children: (onDelete: () => void, loading: boolean) => JSX.Element;
  onDeleteSuccess?: () => any;
  refetchQueries?: PureQueryOptions[];
};

export function DeleteGroup({
  id,
  children,
  onDeleteSuccess,
  refetchQueries,
}: Props) {
  const [deleteMember, { loading }] = useMutation<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >(DELETE_GROUP, {
    variables: { id },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteMember();
      if (onDeleteSuccess) {
        onDeleteSuccess();
      }
    } catch (err) {
      console.log('Error deleting group', err);
      toast.error('There was a problem deleting the group.');
    }
  }

  return children(handleDelete, loading);
}
