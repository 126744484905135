import * as R from 'ramda';
import { Button, Carousel } from 'react-daisyui';
import type { OutcropOverviewTabQuery } from '~/apollo/generated/schema';

type OutcropPicture =
  OutcropOverviewTabQuery['outcropList'][number]['pictures'][number];

type Props = {
  pictures: OutcropPicture[];
};

export function OutcropPicturesCarousel({ pictures }: Props) {
  const sortedPictures = R.sortBy(R.propOr(Infinity, 'priority'), pictures);

  return (
    <Carousel
      display="sequential"
      width="full"
      className="rounded-box h-48"
      buttonStyle={(value: string) => (
        <Button color="ghost" size="sm">
          {value}
        </Button>
      )}
    >
      {sortedPictures.map((picture: OutcropPicture) => (
        <Carousel.Item key={picture.id}>
          <img
            src={picture.file.signedUrl}
            alt={picture.file.name ?? undefined}
            className="w-full object-cover"
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
