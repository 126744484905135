import { KeyParametersPanel } from '~/components/keyParameter/KeyParametersPanel';
import type { ReportDisplayReportItem } from '~/components/report/ReportDisplay/ReportItem';
import { WikiDescriptionText } from '~/components/wiki/lithostrat/WikiPageDescriptionText';

type Props = {
  formation: Extract<
    NonNullable<
      ReportDisplayReportItem['bookmark']
    >['targetLithostratFormation'],
    { __typename?: 'LithostratFormation' }
  >;
};

export function ReportItemLithostratFormation({ formation }: Props) {
  if (!formation.wikiPage) {
    return <p>{formation.description ?? formation.name}</p>;
  }

  return (
    <div className="grid lg:grid-cols-3 gap-6">
      <div className="lg:col-span-2">
        <WikiDescriptionText wiki={formation.wikiPage} />
      </div>
      <div>
        <KeyParametersPanel
          keyParameters={formation.wikiPage.keyParameters ?? []}
        />
      </div>
    </div>
  );
}
