import { Field, useFormikContext } from 'formik';
import * as R from 'ramda';
import React, { useState } from 'react';
import { FormikField } from '~/components/common/FormikField';
import { Panel } from '~/components/common/Panel';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { sortByArray } from '~/utils/common';
import type {
  AnalogueSearchFormValues,
  AnalogueSearchOptionItem,
} from '~/utils/modules/analogueSearch';
import { ageSortOrder } from '~/utils/modules/lithostratAge';
import { Expander } from './Expander';
import { OptionLabel } from './OptionLabel';
import SelectAllOption from './SelectAllOption';

type Props = {
  title: string;
  name: keyof AnalogueSearchFormValues;
  options: AnalogueSearchOptionItem[];
  disabled: boolean;
  /** (optional) Only show the top X items sorted by count, others will be collapsed into submenu */
  onlyTop?: number;
  /** (optional - for use with `onlyTop`) defines a custom order for the "top" items to be sorted by, instead of using the count */
  topOrder?: string[];
  renderName?: (optionName: string) => React.ReactNode;
};

export function SearchFilter({
  title,
  name,
  options,
  disabled,
  onlyTop,
  topOrder,
  renderName,
}: Props) {
  const { values } = useFormikContext<AnalogueSearchFormValues>();
  const [isPanelExpanded, setIsPanelExpanded] = useState(true);

  // Custom sort orders can be defined here
  let sortOrder: string[] | null;
  switch (name) {
    case 'geologyType':
      sortOrder = [
        'clastic',
        'carbonate',
        'structural',
        'volcanic',
        'undefined',
      ];
      break;

    case 'grossDepositionalEnvironment':
      sortOrder = ['Continental', 'Paralic and Shallow marine', 'Deep marine'];
      break;

    case 'geologyAge':
      sortOrder = ageSortOrder;
      break;

    default:
      sortOrder = null;
  }

  // If a custom sort order was defined, the options list will use it for sorting.
  // Otherwise, the options will be sorted ascending by name instead
  const sortFn = (
    opts: AnalogueSearchOptionItem[],
  ): AnalogueSearchOptionItem[] =>
    sortOrder !== null
      ? sortByArray(opts, sortOrder, x => x.name ?? '')
      : R.sortBy(R.propOr('', 'name'), opts);

  let sortedOptions = sortFn(options).filter(opt => opt.name !== null);

  /** Options where a count does not exist (i.e. not present in response from API) */
  const nonzeroOptions = sortedOptions.filter(option => {
    if (!('count' in option)) return true;
    return option.count > 0;
  });
  /** Options for which data exists based on the current filters (i.e. was present in response from API) */
  const zeroOptions = sortedOptions.filter(option => {
    if (!('count' in option)) return false;
    return option.count === 0;
  });

  /** If `onlyTop` prop was set, select the top X items from the nonzero list */
  let topItems: AnalogueSearchOptionItem[] | null;
  let bottomItems: AnalogueSearchOptionItem[] | null;

  if (onlyTop) {
    const sortAlgo = topOrder
      ? (items: AnalogueSearchOptionItem[]) =>
          sortByArray(items, topOrder, x => x.name ?? '')
      : (items: AnalogueSearchOptionItem[]) =>
          R.sort(R.descend(R.propOr(0, 'count')), items);

    const prioritySorted = R.pipe(
      sortAlgo,
      (items: AnalogueSearchOptionItem[]) => {
        if (items.length <= onlyTop) {
          // If there are only X items in the list, don't exclude unknowns
          return items;
        }
        return items.slice().sort((a, b) => {
          const deprioritizedList = ['undefined', 'unknown', 'Various'];
          const isBad = (name: string | null | undefined) =>
            !name || deprioritizedList.includes(name);

          if (isBad(a.name) && !isBad(b.name)) return 1;
          if (!isBad(a.name) && isBad(b.name)) return -1;
          return 0;
        });
      },
    )(nonzeroOptions);

    // Re-sort the top sliced items if needed
    const topResortAlgo = topOrder ? R.identity : sortFn;
    topItems = R.pipe(
      () => prioritySorted,
      ps => R.slice(0, onlyTop, ps),
      topResortAlgo,
    )();
    bottomItems = R.pipe(
      () => prioritySorted,
      ps => R.slice(onlyTop, Infinity, ps),
      sortFn,
    )();
  }

  function numSelected(items: AnalogueSearchOptionItem[]) {
    const selectedValues = R.pathOr<string[]>([], [name], values);

    if (R.any(R.isEmpty, [selectedValues, items])) {
      return 0;
    }

    const zeroValues = items.map(optionValue);

    return R.pipe(R.intersection(zeroValues), R.length)(selectedValues);
  }

  /** Returns true if any items in the given list are present in the values object */
  function isAnySelected(items: AnalogueSearchOptionItem[]) {
    return numSelected(items) !== 0;
  }

  function optionValue(option: AnalogueSearchOptionItem): string {
    // Previously this was used to select "id" or "name" when the project id
    // was used. Leaving this here in case anything needs it in the future
    return option.name ?? '';
  }

  const mapOption = (option: AnalogueSearchOptionItem) => (
    <Field
      key={option.name}
      name={name}
      label={
        <OptionLabel name={name} option={option} renderName={renderName} />
      }
      value={optionValue(option)}
      component={FormikField}
      type="checkbox"
      size="sm"
      className="items-start"
      disabled={disabled}
    />
  );

  return React.useMemo(
    () => (
      <Panel>
        <Panel.Heading>
          <button
            type="button"
            onClick={() => setIsPanelExpanded(prevValue => !prevValue)}
            className="w-full h-full flex justify-between items-center"
          >
            <Panel.Title>{title}</Panel.Title>
            <ExpandedIcon expanded={isPanelExpanded} />
          </button>
        </Panel.Heading>

        <Panel.Body className={isPanelExpanded ? 'block' : 'hidden'}>
          <SelectAllOption name={name} options={nonzeroOptions} />

          {!nonzeroOptions.length && !zeroOptions.length && (
            <small>
              <em className="text-muted">
                No options matching the selected filters.
                <br />
                Try deselecting some other options to show more selections.
              </em>
            </small>
          )}

          {!onlyTop ? (
            nonzeroOptions.map(mapOption)
          ) : (
            <div className="">
              {!bottomItems?.length && topItems?.map(mapOption)}
              {!!bottomItems?.length && (
                <Expander
                  defaultExpanded={isAnySelected(bottomItems || [])}
                  label={exp => (exp ? 'Fewer options' : 'More options')}
                  tooltipText={exp => `Show ${exp ? 'fewer' : 'more'} options`}
                  renderWhenCollapsed={() => topItems?.map(mapOption)}
                  numSelected={numSelected(bottomItems)}
                >
                  {nonzeroOptions.map(mapOption)}
                </Expander>
              )}
            </div>
          )}

          {zeroOptions.length > 0 && (
            <Expander
              defaultExpanded={isAnySelected(zeroOptions)}
              label={() => 'Options with no analogue data'}
              tooltipText={isExpanded =>
                `${
                  isExpanded ? 'Hide' : 'Show'
                } options that do not have any outcrops matching the current filters`
              }
              numSelected={numSelected(zeroOptions)}
            >
              {zeroOptions.map(mapOption)}
            </Expander>
          )}
        </Panel.Body>
      </Panel>
    ),
    [options, disabled, isPanelExpanded],
  );
}
