import { useQuery , gql } from '@apollo/client';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { otherWikiParts } from '~/apollo/fragments';
import type {
  OtherWikiUpdatePageQuery,
  OtherWikiUpdatePageQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { UpdateOtherWikiForm } from '~/components/upload/other/UpdateOtherWikiForm';
import { otherWikiDetailRoute } from '~/paths';

const OTHER_WIKI_UPDATE_PAGE = gql`
  query OtherWikiUpdatePage($id: Int) {
    otherList(id: $id) {
      ...otherWikiParts
    }
  }

  ${otherWikiParts}
`;

export default function OtherWikiUpdatePage() {
  const params = useParams();
  invariant(params.otherWikiId, 'otherWikiId param required');
  const wikiPageId = parseInt(params.otherWikiId);

  const { data, loading } = useQuery<
    OtherWikiUpdatePageQuery,
    OtherWikiUpdatePageQueryVariables
  >(OTHER_WIKI_UPDATE_PAGE, {
    variables: { id: wikiPageId },
  });

  const otherList = data?.otherList ?? [];
  const wikiPage = otherList.find(ow => ow.id === wikiPageId);

  useBreadcrumb(
    'routes/upload/other/$otherWikiId',
    wikiPage?.title ?? 'Update Other Wiki',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!wikiPage) return <NotFound />;

  return (
    <>
      <Link
        className="btn btn-primary btn-sm float-right gap-1"
        to={otherWikiDetailRoute(wikiPage.id)}
        target="_blank"
        rel="noopener noreferrer"
      >
        View page <FontAwesomeIcon icon={faExternalLink} />
      </Link>

      <PageHeading>{wikiPage.title}</PageHeading>
      <UpdateOtherWikiForm wikiPageId={wikiPageId} wikiPage={wikiPage} />
    </>
  );
}
