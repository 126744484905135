import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { NotFound } from '~/components/common/NotFound';
import { Page } from '~/components/page/Page';
import { useAboutRouteOutletContext } from '~/routes/about';

export default function AboutPageRoute() {
  const params = useParams();
  invariant(params.pageName, 'pageName param required');

  const { pageListData } = useAboutRouteOutletContext();
  const page = pageListData.find(p => p.pageName === params.pageName);

  if (!page) return <NotFound />;

  return <Page pageData={page} />;
}
