import type {
  SavedDataSearchDataPartsFragment,
  SavedDataSearchWithOutcropPartsFragment,
} from '~/apollo/generated/schema';
import { SavedDataSearch } from '~/components/dataSearch/savedDataSearch/SavedDataSearch';

export function ReportItemSavedDataSearch({
  savedDataSearch,
  savedDataSearchData,
}: {
  savedDataSearch: SavedDataSearchWithOutcropPartsFragment;
  savedDataSearchData: SavedDataSearchDataPartsFragment;
}) {
  return (
    <SavedDataSearch
      savedDataSearch={savedDataSearch}
      data={savedDataSearchData}
    />
  );
}
