import { faImage, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from 'react-daisyui';
import type { SearchOutcropsOutcropResultsQuery } from '~/apollo/generated/schema';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import type { SortIndicatorProps } from '~/components/common/icons/SortIndicator';
import type { SortChangeFn } from '~/hooks/data';
import { ListView } from './ListView';
import { ThumbnailView } from './ThumbnailView';

type Props = {
  outcrops: SearchOutcropsOutcropResultsQuery['searchOutcrops']['outcrops'];
  loading: boolean;
  sortIndicatorProps: SortIndicatorProps;
  onSortChange: SortChangeFn;
};

export function OutcropResults({
  outcrops,
  loading,
  sortIndicatorProps,
  onSortChange,
}: Props) {
  const [viewMode, setViewMode] = useState<'thumbnail' | 'list'>('list');

  const handleViewModeChange = (vm: 'thumbnail' | 'list') => () => {
    if (vm === 'thumbnail') {
      onSortChange('name', true);
    }
    setViewMode(vm);
  };

  const catCount = (category: string): number =>
    outcrops.filter(oc => oc.outcropCategory === category).length;

  const countSummary: Array<[number, string]> = [
    [catCount('outcrop'), 'Outcrops'],
    [catCount('production'), 'Production'],
    [catCount('seismic'), 'Seismic'],
    [catCount('modern'), 'Modern'],
  ];
  const counts = countSummary.filter(([count]) => count > 0);

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex-grow flex justify-center items-center text-base">
          {counts.length > 0 && (
            <>
              <small className="text-muted mr-2">Results:</small>
              {counts.map(([count, label]) => `${count} ${label}`).join('; ')}
            </>
          )}
        </div>

        <div className="flex-shrink flex justify-end gap-1">
          <Tooltip message="Thumbnail view">
            <Button
              type="button"
              color={viewMode === 'thumbnail' ? 'primary' : 'ghost'}
              size="sm"
              onClick={handleViewModeChange('thumbnail')}
            >
              <FontAwesomeIcon icon={faImage} />
            </Button>
          </Tooltip>

          <Tooltip message="List view">
            <Button
              type="button"
              color={viewMode === 'list' ? 'primary' : 'ghost'}
              size="sm"
              onClick={handleViewModeChange('list')}
            >
              <FontAwesomeIcon icon={faList} />
            </Button>
          </Tooltip>
        </div>
      </div>

      <SpinnerPlaceholder show={loading} />

      {!loading && viewMode === 'thumbnail' && (
        <ThumbnailView outcrops={outcrops} />
      )}
      {!loading && viewMode === 'list' && (
        <ListView outcrops={outcrops} sortIndicatorProps={sortIndicatorProps} />
      )}

      {!loading && !outcrops.length && (
        <p className="text-center text-muted">
          No analogues found matching the selected filters.
          <br />
          Try deselecting some options or zooming out on the map to see more
          results.
        </p>
      )}
    </>
  );
}
