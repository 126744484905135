import { SortTrigger } from '~/components/common/SortTrigger';
import { Tooltip } from '~/components/common/Tooltip';
import type { SortHistory } from '~/hooks/data';
import { useSortFilter } from '~/hooks/data';
import { kpFieldAbbr, kpFieldName } from '~/utils/modules/keyParameters';
import { LinkedDepositionalValue } from './LinkedDepositionalValue';
import type { Props as KPPanelProps } from './index';

type Props = {
  geologyType: string | null;
  keyParameters: KPPanelProps['keyParameters'];
  sortable?: boolean;
};

export function TabularView({
  geologyType,
  keyParameters,
  sortable = true,
}: Props) {
  const initialSortOrder: SortHistory = [
    ['dominant', 'desc'],
    ['depositionalEnvironment', 'asc'],
    ['depositionalSubEnvironment', 'asc'],
    ['architecturalElement', 'asc'],
  ];
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    keyParameters,
    initialSortOrder,
    'architecturalElement',
    'outcropKeyParameters',
  );

  const fieldNameFn = kpFieldName(geologyType);
  const fieldAbbrFn = kpFieldAbbr(geologyType);

  return (
    <table className="table table-compact w-full">
      <thead>
        <tr>
          <th>
            <SortTrigger
              colName="grossDepositionalEnvironment"
              filterable
              sortIndicatorProps={siProps}
              disabled={!sortable}
            >
              <Tooltip message={fieldNameFn('grossDepositionalEnvironment')}>
                <span>{fieldAbbrFn('grossDepositionalEnvironment')}</span>
              </Tooltip>
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="depositionalEnvironment"
              filterable
              sortIndicatorProps={siProps}
              disabled={!sortable}
            >
              <Tooltip message={fieldNameFn('depositionalEnvironment')}>
                <span>{fieldAbbrFn('depositionalEnvironment')}</span>
              </Tooltip>
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="depositionalSubEnvironment"
              filterable
              sortIndicatorProps={siProps}
              disabled={!sortable}
            >
              <Tooltip message={fieldNameFn('depositionalSubEnvironment')}>
                <span>{fieldAbbrFn('depositionalSubEnvironment')}</span>
              </Tooltip>
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="architecturalElement"
              filterable
              sortIndicatorProps={siProps}
              disabled={!sortable}
            >
              <Tooltip message={fieldNameFn('architecturalElement')}>
                <span>{fieldAbbrFn('architecturalElement')}</span>
              </Tooltip>
            </SortTrigger>
          </th>
        </tr>
      </thead>

      <tbody>
        {items.map(kp => (
          <tr key={kp.id}>
            <td>
              <LinkedDepositionalValue
                geologyType={geologyType}
                type="GROSS_DEPOSITIONAL_ENVIRONMENT"
                value={kp.grossDepositionalEnvironment}
                dominant={kp.dominant}
              >
                {kp.grossDepositionalEnvironment}
              </LinkedDepositionalValue>
            </td>
            <td>
              <LinkedDepositionalValue
                geologyType={geologyType}
                type="DEPOSITIONAL_ENVIRONMENT"
                value={kp.depositionalEnvironment}
                dominant={kp.dominant}
              >
                {kp.depositionalEnvironment}
              </LinkedDepositionalValue>
            </td>
            <td>
              <LinkedDepositionalValue
                geologyType={geologyType}
                type="DEPOSITIONAL_SUB_ENVIRONMENT"
                value={kp.depositionalSubEnvironment}
                dominant={kp.dominant}
              >
                {kp.depositionalSubEnvironment}
              </LinkedDepositionalValue>
            </td>
            <td>
              <LinkedDepositionalValue
                geologyType={geologyType}
                type="ARCHITECTURAL_ELEMENT"
                value={kp.architecturalElement}
                dominant={kp.dominant}
              >
                {kp.architecturalElement}
              </LinkedDepositionalValue>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
