import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { Panel } from '~/components/common/Panel';
import { queryClient } from '~/main';
import {
  getGetApiV4VirtualOutcropModelsIdQueryKey,
  usePostApiV4CesiumAssets,
} from '~/openapi/api-v4';

export function CreateCesiumAsset({ vomId }: { vomId: string }) {
  const postCesiumAssets = usePostApiV4CesiumAssets();

  async function handleCreateClick() {
    try {
      await postCesiumAssets.mutateAsync(
        {
          data: {
            data: {
              attributes: {
                vom_id: parseInt(vomId),
              },
            },
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: getGetApiV4VirtualOutcropModelsIdQueryKey(vomId),
            });
          },
        },
      );
      toast.success('Cesium asset created successfully.');
    } catch (err) {
      console.log('Error creating cesium asset', err);
      toast.error(
        'There was a problem creating the cesium asset. Please reload the page; one may have already been created.',
      );
    }
  }
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Cesium Asset</Panel.Title>
      </Panel.Heading>

      <Panel.Body>
        <div className="text-center space-y-4">
          <p>This Virtual Outcrop doesn't have a Cesium Asset created yet.</p>

          <Button
            type="button"
            onClick={handleCreateClick}
            loading={postCesiumAssets.isLoading}
            color="success"
            startIcon={<FontAwesomeIcon icon={faCirclePlay} />}
          >
            Initialize Cesium Asset
          </Button>
        </div>
      </Panel.Body>
    </Panel>
  );
}
