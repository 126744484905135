import { gql, useQuery } from '@apollo/client';
import invariant from 'tiny-invariant';
import { companyParts } from '~/apollo/fragments';
import type {
  CompanyAdminOptionsPageQuery,
  CompanyAdminOptionsPageQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { UpdateCompanyPanel } from '~/components/company/UpdateCompanyPanel';
import { useAuth } from '~/contexts/auth';

const COMPANY_ADMIN_OPTIONS_PAGE = gql`
  query companyAdminOptionsPage($id: Int!) {
    companyList(id: $id) {
      ...companyParts
    }
  }

  ${companyParts}
`;

export default function CompanyAdminOptionsPage() {
  const { authority } = useAuth();
  invariant(authority, 'Must be authenticated');
  const companyId = authority.user.companyId;

  const { data, loading } = useQuery<
    CompanyAdminOptionsPageQuery,
    CompanyAdminOptionsPageQueryVariables
  >(COMPANY_ADMIN_OPTIONS_PAGE, {
    variables: { id: companyId },
  });

  const company = data?.companyList.find(c => c.id === companyId);

  if (loading) return <SpinnerPlaceholder />;
  if (!company) return <NotFound />;

  return <UpdateCompanyPanel company={company} />;
}
