import {
  faCheckCircle,
  faRemove,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import type { QueueItem, QueueItemStatus } from './SOFileUpload';

export function itemStatus(status: QueueItemStatus) {
  switch (status) {
    case 'pending':
      return <span>Pending</span>;

    case 'uploading':
      return <span className="text-muted">Uploading...</span>;

    case 'success':
      return (
        <span className="text-success">
          <FontAwesomeIcon icon={faCheckCircle} /> Success
        </span>
      );

    case 'failed':
      return (
        <span className="text-error">
          <FontAwesomeIcon icon={faRemove} /> Failed
        </span>
      );

    default:
      return null;
  }
}

type Props = {
  item: QueueItem;
  onRemove: (itemId: string) => void;
};

export function FileUploadQueueItem({ item, onRemove }: Props) {
  const isRemoveDisabled = ['success', 'failed'].includes(item.status);

  return (
    <tr>
      <td className="w-1/2">{item.file.name}</td>

      <td className="w-3/12">
        <div className="text-center">{itemStatus(item.status)}</div>
      </td>

      <td className="w-3/12 text-right">
        <Button
          type="button"
          color="ghost"
          size="xs"
          onClick={() => onRemove(item.id)}
          disabled={isRemoveDisabled}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
}
