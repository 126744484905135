import { cn } from '~/utils/common';
import * as R from 'ramda';
import React, { Fragment } from 'react';
import {
  kpFieldAbbr,
  kpFieldName,
  mergeKeyParameters,
} from '~/utils/modules/keyParameters';
import { LinkedDepositionalValue } from './LinkedDepositionalValue';
import type { Props as KPPanelProps } from './index';
import { KPTitle } from './index';

type KPGridProps = {
  children: React.ReactNode;
};
export function KPGrid({ children }: KPGridProps) {
  return (
    <div className="grid grid-cols-12 gap-4 p-2 border-b border-b-base-200 last:border-b-0">
      {children}
    </div>
  );
}

type KPColProps = {
  span: number;
  children: React.ReactNode;
};
export function KPCol({ span, children }: KPColProps) {
  return (
    <div
      className={cn('text-base', {
        'lg:col-span-1': span === 1,
        'lg:col-span-2': span === 2,
        'lg:col-span-3': span === 3,
        'lg:col-span-4': span === 4,
        'lg:col-span-5': span === 5,
        'lg:col-span-6': span === 6,
        'lg:col-span-7': span === 7,
        'lg:col-span-8': span === 8,
        'lg:col-span-9': span === 9,
        'lg:col-span-10': span === 10,
        'lg:col-span-11': span === 11,
        'lg:col-span-12': span === 12,
      })}
    >
      {children}
    </div>
  );
}

type El = JSX.Element;
function intercalateCommas(acc: El[], cur: El, idx: number, ar: El[]): El[] {
  acc.push(cur);
  if (idx < ar.length - 1) acc.push(<Fragment key={idx}>, </Fragment>);
  return acc;
}

type Props = {
  geologyType: string | null;
  keyParameters: KPPanelProps['keyParameters'];
  leftColMd: number;
  rightColMd: number;
};

export function CombinedView({
  geologyType,
  keyParameters,
  leftColMd,
  rightColMd,
}: Props) {
  const {
    grossDepositionalEnvironment: gdes,
    depositionalEnvironment: des,
    depositionalSubEnvironment: ses,
    architecturalElement: aes,
    diageneticProduct: dps,
  } = mergeKeyParameters(keyParameters);

  const dominantKPs = keyParameters.filter(kp => kp.dominant === true);
  type KPLevel = keyof Props['keyParameters'][number];
  const isDominant = (kpLevel: KPLevel, value: string) => {
    return dominantKPs.map(kps => kps[kpLevel]).includes(value);
  };

  const sortKPs = (kpLevel: KPLevel, items: string[]) => {
    const sortedByDominant = R.sortWith(
      [R.descend(item => isDominant(kpLevel, item)), R.ascend(R.identity)],
      items,
    );
    return sortedByDominant;
  };

  const fieldNameFn = kpFieldName(geologyType);
  const fieldAbbrFn = kpFieldAbbr(geologyType);

  return (
    <div className="">
      {gdes && gdes.length > 0 && (
        <KPGrid>
          <KPCol span={leftColMd}>
            <KPTitle
              name={fieldNameFn('grossDepositionalEnvironment')}
              abbreviation={fieldAbbrFn('grossDepositionalEnvironment')}
            />
          </KPCol>
          <KPCol span={rightColMd}>
            {gdes
              ? sortKPs('grossDepositionalEnvironment', gdes)
                  .map(gde => (
                    <LinkedDepositionalValue
                      key={gde}
                      geologyType={geologyType}
                      value={gde}
                      type="GROSS_DEPOSITIONAL_ENVIRONMENT"
                      dominant={isDominant('grossDepositionalEnvironment', gde)}
                    >
                      {gde}
                    </LinkedDepositionalValue>
                  ))
                  .reduce(intercalateCommas, [])
              : 'undefined'}
          </KPCol>
        </KPGrid>
      )}

      {des && des.length > 0 && (
        <KPGrid>
          <KPCol span={leftColMd}>
            <KPTitle
              name={fieldNameFn('depositionalEnvironment')}
              abbreviation={fieldAbbrFn('depositionalEnvironment')}
            />
          </KPCol>
          <KPCol span={rightColMd}>
            {sortKPs('depositionalEnvironment', des)
              .map(de => (
                <LinkedDepositionalValue
                  key={de}
                  geologyType={geologyType}
                  type="DEPOSITIONAL_ENVIRONMENT"
                  value={de}
                  dominant={isDominant('depositionalEnvironment', de)}
                >
                  {de}
                </LinkedDepositionalValue>
              ))
              .reduce(intercalateCommas, [])}
          </KPCol>
        </KPGrid>
      )}

      {ses && ses.length > 0 && (
        <KPGrid>
          <KPCol span={leftColMd}>
            <KPTitle
              name={fieldNameFn('depositionalSubEnvironment')}
              abbreviation={fieldAbbrFn('depositionalSubEnvironment')}
            />
          </KPCol>
          <KPCol span={rightColMd}>
            {sortKPs('depositionalSubEnvironment', ses)
              .map(se => (
                <LinkedDepositionalValue
                  key={se}
                  geologyType={geologyType}
                  type="DEPOSITIONAL_SUB_ENVIRONMENT"
                  value={se}
                  dominant={isDominant('depositionalSubEnvironment', se)}
                >
                  {se}
                </LinkedDepositionalValue>
              ))
              .reduce(intercalateCommas, [])}
          </KPCol>
        </KPGrid>
      )}

      {aes && aes.length > 0 && (
        <KPGrid>
          <KPCol span={leftColMd}>
            <KPTitle
              name={fieldNameFn('architecturalElement')}
              abbreviation={fieldAbbrFn('architecturalElement')}
            />
          </KPCol>
          <KPCol span={rightColMd}>
            {sortKPs('architecturalElement', aes)
              .map(ae => (
                <LinkedDepositionalValue
                  key={ae}
                  geologyType={geologyType}
                  type="ARCHITECTURAL_ELEMENT"
                  value={ae}
                  dominant={isDominant('architecturalElement', ae)}
                >
                  {ae}
                </LinkedDepositionalValue>
              ))
              .reduce(intercalateCommas, [])}
          </KPCol>
        </KPGrid>
      )}

      {dps && dps.length > 0 && (
        <KPGrid>
          <KPCol span={leftColMd}>
            <KPTitle name="Diagenetic product" abbreviation="DP" />
          </KPCol>
          <KPCol span={rightColMd}>
            {sortKPs('architecturalElement', dps)
              .map(dp => (
                <LinkedDepositionalValue
                  key={dp}
                  geologyType={geologyType}
                  type="ARCHITECTURAL_ELEMENT"
                  value={dp}
                  dominant={isDominant('architecturalElement', dp)}
                >
                  {dp}
                </LinkedDepositionalValue>
              ))
              .reduce(intercalateCommas, [])}
          </KPCol>
        </KPGrid>
      )}
    </div>
  );
}
