import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import * as fragments from '~/apollo/fragments';
import type {
  OutcropPicturesRouteQuery,
  OutcropPicturesRouteQueryVariables,
} from '~/apollo/generated/schema';
import {
  BookmarkParentType,
  BookmarkTargetType,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { PictureThumbnail } from '~/components/common/PictureThumbnail';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { studyRoute } from '~/paths';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';
import { soBookmarkPath } from '~/utils/modules/bookmark';

const OUTCROP_PICTURES_ROUTE = gql`
  query OutcropPicturesRoute($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts
      pictures {
        ...pictureParts
        file {
          ...fileParts
          signedUrl
        }
      }

      studies {
        ...studyParts

        dataHistory {
          ...dataHistoryParts
        }

        pictures {
          ...pictureParts
          file {
            ...fileParts
            signedUrl
          }
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.studyParts}
  ${fragments.dataHistoryParts}
  ${fragments.pictureParts}
`;

export default function OutcropPicturesRoute() {
  const ctx = useOutcropOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    OutcropPicturesRouteQuery,
    OutcropPicturesRouteQueryVariables
  >(OUTCROP_PICTURES_ROUTE, { variables: { outcropId } });

  const refetchQueries: PureQueryOptions[] = [
    { query: OUTCROP_PICTURES_ROUTE, variables: { outcropId } },
  ];

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);

  const pictures = R.sortBy(
    R.propOr(Infinity, 'priority'),
    outcrop?.pictures ?? [],
  );

  const studies = outcrop?.studies ?? [];
  type Study = (typeof studies)[number];

  function getTaggedPictures(s: Study) {
    const studyPictures = s.pictures.filter(
      p => p.outcropTagId && p.outcropTagId === outcropId,
    );
    return R.sortBy(R.propOr(Infinity, 'priority'), studyPictures);
  }

  function authorLine(dataHistory?: Study['dataHistory']) {
    if (!dataHistory) return null;
    if (!dataHistory.collectedBy && !dataHistory.date) {
      return null;
    }

    return (
      <div className="space-x-2">
        {dataHistory.collectedBy && <span>{dataHistory.collectedBy}</span>}
        {dataHistory.date && (
          <span className="text-muted">{dataHistory.date}</span>
        )}
      </div>
    );
  }

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-6">
      <div className="grid md:grid-cols-2 gap-6">
        {pictures.map(picture => (
          <div key={picture.id}>
            <PictureThumbnail
              picture={picture}
              imgSrc={picture.file.signedUrl}
              bookmarkable={{
                parentType: BookmarkParentType.Outcrop,
                parentId: outcropId,
                targetType: BookmarkTargetType.Picture,
                targetId: picture.id,
                path: soBookmarkPath(
                  'outcrop',
                  outcropId,
                  BookmarkParentType.Outcrop,
                  picture.outcropTagId,
                ),
              }}
              refetchQueries={refetchQueries}
            />
          </div>
        ))}
      </div>

      {studies.map(study => {
        const taggedPics = getTaggedPictures(study);
        if (!taggedPics.length) return null;

        return (
          <Panel key={study.id}>
            <Panel.Heading className="flex justify-between items-center">
              <Panel.Title>
                <strong>{study.name}</strong>
                {authorLine(study.dataHistory)}
              </Panel.Title>

              <Link
                to={studyRoute(study.id)}
                target="_blank"
                className="btn btn-ghost btn-xs gap-1"
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} /> View Study
              </Link>
            </Panel.Heading>

            <Panel.Body>
              <div className="grid md:grid-cols-2 gap-6">
                {taggedPics.map(picture => (
                  <div key={picture.id}>
                    <PictureThumbnail
                      picture={picture}
                      imgSrc={picture.file.signedUrl}
                      fade
                      bookmarkable={{
                        parentType: BookmarkParentType.Study,
                        parentId: study.id,
                        targetType: BookmarkTargetType.Picture,
                        targetId: picture.id,
                        path: soBookmarkPath(
                          'study',
                          study.id,
                          BookmarkParentType.Study,
                          picture.outcropTagId,
                        ),
                      }}
                      refetchQueries={refetchQueries}
                    />
                  </div>
                ))}
              </div>
            </Panel.Body>
          </Panel>
        );
      })}
    </div>
  );
}
