import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { outcropParts } from '~/apollo/fragments';
import type { OutcropEnumerationsQuery } from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { OutcropQPsFormFields } from '~/components/upload/outcrop/OutcropQPsFormFields';
import { uploadOutcropUpdateRoute } from '~/paths';
import type { OutcropFormValues } from '~/utils/modules/outcrop';
import {
  initialOutcropFormValues,
  toOutcropInput,
} from '~/utils/modules/outcrop';
import { BasinFormFields } from './BasinFormFields';
import { OutcropOverviewFormFields } from './OutcropOverviewFormFields';
import { SourceAreaFormFields } from './SourceAreaFormFields';

const CREATE_OUTCROP = gql`
  mutation CreateOutcrop($outcrop: OutcropInput!) {
    createOutcrop(outcrop: $outcrop) {
      ...outcropParts
    }
  }
  ${outcropParts}
`;

type Props = {
  outcropEnums: OutcropEnumerationsQuery;
};

export function CreateOutcropForm({ outcropEnums }: Props) {
  const navigate = useNavigate();
  const [createOutcrop, { loading, error }] = useMutation(CREATE_OUTCROP, {});

  async function handleSubmit(values: OutcropFormValues) {
    const outcropInput = toOutcropInput(values);

    try {
      const res = await createOutcrop({ variables: { outcrop: outcropInput } });
      invariant(res.data?.createOutcrop);
      const { id: outcropId } = res.data.createOutcrop;
      const redirectPath = uploadOutcropUpdateRoute(outcropId);
      navigate(redirectPath);
      toast.success('Outcrop details saved successfully.');
    } catch (err) {
      console.log('Error creating outcrop', err);
      toast.error('There was a problem saving the outcrop.');
    }
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialOutcropFormValues()}>
      <Form className="space-y-6">
        <div className="grid lg:grid-cols-12 gap-6">
          <div className="lg:col-span-8">
            <Panel>
              <Panel.Heading>
                <Panel.Title>Overview</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <OutcropOverviewFormFields outcropEnums={outcropEnums} />
              </Panel.Body>
            </Panel>
          </div>

          <div className="lg:col-span-4 space-y-6">
            <Panel>
              <Panel.Heading>
                <Panel.Title>Basin</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <BasinFormFields />
              </Panel.Body>
            </Panel>

            <Panel>
              <Panel.Heading>
                <Panel.Title>Source Area</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <SourceAreaFormFields />
              </Panel.Body>
            </Panel>

            <Panel>
              <Panel.Heading>
                <Panel.Title>Quality Parameters</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <OutcropQPsFormFields enumOptions={outcropEnums} />
              </Panel.Body>
            </Panel>
          </div>
        </div>

        <FormErrors graphQLError={error} />

        <div className="text-center">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            Save
          </button>
        </div>
      </Form>
    </Formik>
  );
}
