import { faScroll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import { createReportRoute } from '~/paths';

export function AddCollectionToReportModal({
  children,
  collectionId,
  isCompany,
}: {
  children: (showModal: () => void) => JSX.Element;
  collectionId: number;
  isCompany: boolean;
}) {
  const { show, showModal, hideModal } = useModalState();

  const createReportLink = (): LinkProps['to'] => {
    const search = new URLSearchParams();
    search.set('collectionId', String(collectionId));

    if (isCompany) {
      search.set('company', 't');
    }

    return {
      pathname: createReportRoute(),
      search: search.toString(),
    };
  };

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Modal.Body heading="Create report from collection">
          <div className="space-y-4">
            <p>
              Use the following link to create a new{' '}
              {isCompany ? 'company ' : ''}report based on this collection.
              After creation, the collection's bookmarks will be automatically
              added to it.
            </p>

            <div className="text-center">
              <Link to={createReportLink()} className="btn btn-primary">
                <FontAwesomeIcon icon={faScroll} /> Create Report
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
