import { gql, useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as fragments from '~/apollo/fragments';
import type * as schema from '~/apollo/generated/schema';
import { GeologyType } from '~/apollo/generated/schema';
import {
  DEPOSITIONAL_WIKI_ENUMS,
  GEOLOGY_TYPE_ENUMS,
} from '~/apollo/operations/wiki';
import { Heading } from '~/components/common/Heading';
import { ListGroup } from '~/components/common/ListGroup';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useQueryString } from '~/hooks/routing';
import { uploadGeologyCreateRoute, uploadGeologyUpdateRoute } from '~/paths';
import { supportedGeologyTypes } from '~/utils/modules/keyParameters';
import { snakeCapsToHuman, ucwords } from '~/utils/text';

const DEPOSITIONAL_OVERVIEW_ROUTE = gql`
  query DepositionalOverviewRoute {
    depositionalList {
      ...depositionalWikiParts
    }
  }

  ${fragments.depositionalWikiParts}
`;

export default function DepositionalOverviewRoute() {
  useBreadcrumb(
    'routes/upload/depositional.overview',
    'Depositional Article Overview',
  );
  const { stringify } = useQueryString();

  const [showExisting, setShowExisting] = useState<boolean>(true);
  const [hiddenGeologyTypes, setHiddenGeologyTypes] = useState<
    Record<string, boolean>
  >({});

  const toggleShowExisting = () => setShowExisting(!showExisting);

  const toggleHiddenGeologyType = (geologyType: string) => () => {
    const isHidden = hiddenGeologyTypes[geologyType] || false;
    setHiddenGeologyTypes({
      ...hiddenGeologyTypes,
      [geologyType]: !isHidden,
    });
  };

  /** Whether a geology type is currently hidden */
  function isGeologyTypeHidden(geologyType: string) {
    return hiddenGeologyTypes[geologyType] || false;
  }

  const { data: geologyTypeEnumsData, loading: loadingGeologyTypeEnums } =
    useQuery<
      schema.GeologyTypeEnumsQuery,
      schema.GeologyTypeEnumsQueryVariables
    >(GEOLOGY_TYPE_ENUMS, {});

  const { data: enumDataClastic, loading: loadingEnumsClastic } = useQuery<
    schema.DepositionalWikiEnumsQuery,
    schema.DepositionalWikiEnumsQueryVariables
  >(DEPOSITIONAL_WIKI_ENUMS, {
    variables: { geologyType: GeologyType.Clastic },
  });
  const { data: enumDataCarbonate, loading: loadingEnumsCarbonate } = useQuery<
    schema.DepositionalWikiEnumsQuery,
    schema.DepositionalWikiEnumsQueryVariables
  >(DEPOSITIONAL_WIKI_ENUMS, {
    variables: { geologyType: GeologyType.Carbonate },
  });
  const { data: enumDataStructural, loading: loadingEnumsStructural } =
    useQuery<
      schema.DepositionalWikiEnumsQuery,
      schema.DepositionalWikiEnumsQueryVariables
    >(DEPOSITIONAL_WIKI_ENUMS, {
      variables: { geologyType: GeologyType.Structural },
    });

  type EnumData = Record<string, schema.DepositionalWikiEnumsQuery | undefined>;

  const loadingEnums =
    loadingEnumsCarbonate || loadingEnumsClastic || loadingEnumsStructural;

  const enumData: EnumData = {
    clastic: enumDataClastic,
    carbonate: enumDataCarbonate,
    structural: enumDataStructural,
  };

  const {
    data: depositionalWikiListData,
    loading: loadingDepositionalWikiList,
  } = useQuery<
    schema.DepositionalOverviewRouteQuery,
    schema.DepositionalOverviewRouteQueryVariables
  >(DEPOSITIONAL_OVERVIEW_ROUTE, {});

  function findArticle(
    geologyType: string,
    type: schema.DepositionalWikiType,
    value: string,
  ) {
    const wikis = depositionalWikiListData?.depositionalList ?? [];
    return wikis.find(
      article =>
        article.geologyType === geologyType &&
        article.type === type &&
        (article.value ?? []).includes(value),
    );
  }

  const geologyTypes = R.pipe(
    R.pathOr<string[]>([], ['outcropEnumerations', 'values']),
    R.filter(R.contains(R.__, supportedGeologyTypes)),
  )(geologyTypeEnumsData);

  const wikiTypes = (geologyType: string) =>
    R.pipe(
      R.defaultTo({}),
      R.pathOr({}, [geologyType]),
      R.keys,
      R.sortBy(R.identity),
      result => result as schema.DepositionalWikiType[],
    )(enumData);

  function wikiValuesByType(
    geologyType: keyof EnumData,
    type: schema.DepositionalWikiType,
  ): string[] {
    return R.pipe(
      R.pathOr([], [geologyType, type, 'values']),
      R.sortBy(R.identity),
    )(enumData);
  }

  const loading =
    loadingGeologyTypeEnums || loadingEnums || loadingDepositionalWikiList;

  if (loading) {
    return (
      <SpinnerPlaceholder>
        Loading all depositional articles, this may take a moment...
      </SpinnerPlaceholder>
    );
  }

  return (
    <>
      <div className="flex justify-between items-end p-2 mb-2">
        <div>
          <PageHeading className="m-0">
            Depositional Article Overview
          </PageHeading>
          <p>
            Listing all depositional wiki articles overlaid on the current
            depositional schema.
          </p>
        </div>

        <div className="form-control">
          <label
            htmlFor="toggleShowExisting"
            className="label justify-start gap-2 cursor-pointer"
          >
            <input
              type="checkbox"
              id="toggleShowExisting"
              value="whatever"
              onChange={toggleShowExisting}
              checked={showExisting}
              className="checkbox"
            />
            <span className="label-text">Show existing articles</span>
          </label>
        </div>
      </div>

      <div className="space-y-6">
        {geologyTypes.map(geologyType => {
          return (
            <Panel key={geologyType}>
              <Panel.Heading>
                <Panel.Title>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={toggleHiddenGeologyType(geologyType)}
                  >
                    <Tooltip
                      message={`Click to ${
                        isGeologyTypeHidden(geologyType) ? 'show' : 'collapse'
                      } this geology type`}
                    >
                      <span>
                        {ucwords(geologyType)}{' '}
                        <ExpandedIcon
                          expanded={!isGeologyTypeHidden(geologyType)}
                        />
                      </span>
                    </Tooltip>
                  </div>
                </Panel.Title>
              </Panel.Heading>

              {!isGeologyTypeHidden(geologyType) && (
                <Panel.Body>
                  {wikiTypes(geologyType).map(type => (
                    <React.Fragment key={type}>
                      <Heading level={4} className="pl-2 my-2">
                        {snakeCapsToHuman(type)}
                      </Heading>

                      <ListGroup>
                        {wikiValuesByType(geologyType, type).map(value => {
                          const article = findArticle(geologyType, type, value);
                          if (!showExisting && article) return null;

                          return (
                            <ListGroup.Item
                              key={value}
                              className="flex justify-between items-center"
                            >
                              <div>
                                {article && (
                                  <Link
                                    to={uploadGeologyUpdateRoute(article.id)}
                                    target="_blank"
                                  >
                                    {value}
                                  </Link>
                                )}
                                {!article && <>{value}</>}
                              </div>

                              {!article && (
                                <Link
                                  to={{
                                    pathname: uploadGeologyCreateRoute(),
                                    search: stringify({
                                      geologyType,
                                      type,
                                      value,
                                    }),
                                  }}
                                  target="_blank"
                                  className="btn btn-primary btn-xs gap-1"
                                >
                                  <FontAwesomeIcon icon={faPlus} /> Create
                                </Link>
                              )}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </React.Fragment>
                  ))}
                </Panel.Body>
              )}
            </Panel>
          );
        })}
      </div>
    </>
  );
}
