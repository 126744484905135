import { defaultDateFormat, toLocalDateString } from '~/utils/date';

type Props = React.HTMLProps<HTMLSpanElement> & {
  date: Date | string;
  format?: Intl.DateTimeFormatOptions;
};

export function LocalDate({
  date,
  format = defaultDateFormat,
  ...props
}: Props) {
  return <span {...props}>{toLocalDateString(date, format)}</span>;
}
