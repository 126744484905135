import { cn } from '~/utils/common';
import type { FieldProps } from 'formik';
import { Field } from 'formik';
import type { CSSProperties } from 'react';
import React from 'react';
import type { PopoverContentProps } from '~/components/common/Popover';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/common/Popover';
import { FormikField } from './index';
import { FormLabel } from '~/components/common/FormikField/FormLabel';

export type CheckboxArrayOption = {
  value: any;
  label: React.ReactNode;
  disabled?: boolean;
};

type Props = FieldProps & {
  label?: string;
  name: string;
  dropdownBtnLabel: JSX.Element;
  dropdownBtnClass?: string;
  dropdownBtnStyle?: CSSProperties;
  options: CheckboxArrayOption[];
  disabled?: boolean;
  align?: PopoverContentProps['align'];
  width?: CSSProperties['width'];
  required?: boolean;
};

export function FormikCheckboxArrayDropdown({
  field,
  label,
  dropdownBtnLabel,
  dropdownBtnClass,
  dropdownBtnStyle,
  options,
  disabled = false,
  align = 'start',
  width = '400px',
  required = false,
}: Props) {
  return (
    <div className="form-control">
      {label && (
        <FormLabel name={field.name} label={label} required={required} />
      )}

      <Popover>
        {/* A checkbox-array trigger disguised as a select! 🥸 */}
        <PopoverTrigger
          className={cn(
            'select select-bordered items-center',
            dropdownBtnClass,
          )}
          style={dropdownBtnStyle}
        >
          {dropdownBtnLabel ??
            `${
              field.value.length
                ? `${field.value.length} selected`
                : '- Select -'
            }`}
        </PopoverTrigger>

        <PopoverContent align={align} style={{ width }}>
          {options.map(({ value, label, disabled: optionDisabled }) => (
            <Field
              key={value}
              name={field.name}
              label={label}
              component={FormikField}
              type="checkbox"
              value={value}
              disabled={disabled || optionDisabled}
              size="sm"
            />
          ))}
        </PopoverContent>
      </Popover>
    </div>
  );
}
