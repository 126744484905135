import * as fragments from '~/apollo/fragments';
import { gql } from '@apollo/client';

export const PAGES_BY_PAGE_NAME = gql`
  query PagesByPageName($pageNames: [String!]!) {
    pageList(pageNames: $pageNames) {
      ...pageParts
    }
  }

  ${fragments.pageParts}
`;
