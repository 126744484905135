import { PageLoader } from '~/components/page/PageLoader';
import { ucwords } from '~/utils/text';

type Props = {
  pageName: string;
  iconPath?: string;
};

export function PageIntro({ pageName, iconPath }: Props) {
  return (
    <div className="flex justify-between items-center">
      <div className="flex-grow">
        <PageLoader pageName={pageName} />
      </div>

      {iconPath && (
        <img
          src={iconPath}
          className=" h-28 w-28 ml-6 mb-6"
          alt={`${ucwords(pageName)} icon`}
        />
      )}
    </div>
  );
}
