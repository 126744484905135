import { cn } from '~/utils/common';
import type { HTMLProps } from 'react';
import React from 'react';

type ListGroupProps = HTMLProps<HTMLDivElement> & {
  border?: boolean;
};

export function ListGroup({
  border = true,
  className,
  children,
  ...props
}: ListGroupProps) {
  if (children instanceof Array && !children.length) {
    // Hide the border if there isn't anything in the group
    return null;
  }

  return (
    <div
      className={cn({ 'border border-slate-200': border }, className)}
      {...props}
    >
      {children}
    </div>
  );
}

type ListGroupItemProps<T extends React.ElementType> = {
  as?: T;
} & React.ComponentPropsWithoutRef<T>;

function ListGroupItem<T extends React.ElementType = 'div'>({
  as: AsElement,
  className,
  children,
  ...props
}: ListGroupItemProps<T>) {
  const elProps = {
    className: cn(
      'block border border-slate-200 hover:bg-slate-100 p-2 text-base',
      className,
    ),
    ...props,
  };

  const Element = AsElement || 'div';
  return <Element {...elProps}>{children}</Element>;
}

ListGroup.Item = ListGroupItem;
