import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';
import * as R from 'ramda';

import * as fragments from '~/apollo/fragments';
import type {
  CompanyPartsFragment,
  StudyAccessManagerCompaniesQuery,
  StudyAccessPartsFragment,
} from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { AddStudyAccess } from './CreateStudyAccess';
import { StudyAccessItem } from './StudyAccessItem';

export const STUDY_ACCESS_MANAGER_COMPANIES = gql`
  query StudyAccessManagerCompanies {
    companyList {
      ...companyParts
      studyAccess {
        ...studyAccessParts
        project {
          ...projectParts
        }
      }
      user {
        ...userParts
      }
    }
  }

  ${fragments.companyParts}
  ${fragments.userParts}
  ${fragments.projectParts}
  ${fragments.studyAccessParts}
`;

export enum StudyAccessParent {
  Study = 'study',
  Project = 'project',
}

type StudyAccess = StudyAccessPartsFragment & {
  company: Pick<CompanyPartsFragment, 'id' | 'name'>;
};

type Props = {
  parentType: StudyAccessParent;
  parentId: number;
  studyAccess: StudyAccess[];
  projectStudyAccess?: StudyAccess[];
  refetchQueries: PureQueryOptions[];
};

export function StudyAccessManager({
  parentType,
  parentId,
  studyAccess,
  projectStudyAccess = [],
  refetchQueries,
}: Props) {
  const { data, loading } = useQuery<StudyAccessManagerCompaniesQuery>(
    STUDY_ACCESS_MANAGER_COMPANIES,
    {},
  );

  if (loading) return <SpinnerPlaceholder />;

  const companyList = data?.companyList ?? [];

  // Generate list of companies to select from that have not already been
  // granted access through the project or Study Access form

  const projectCompanyIds = projectStudyAccess
    .map(sa => sa.company?.id)
    .filter((id): id is number => !!id);
  const studyAccessCompanyIds = studyAccess
    .map(sa => sa.company?.id)
    .filter((id): id is number => !!id);

  const currentCompanyIds = R.union(projectCompanyIds, studyAccessCompanyIds);

  const availableCompanies = companyList
    .filter(c => !currentCompanyIds.includes(c.id))
    .sort(R.ascend(c => c.name));

  const sortedSA = R.sortBy(R.pathOr('', ['company', 'name']))(studyAccess);

  return (
    <div className="grid lg:grid-cols-2 gap-6">
      <div
        className={
          parentType === StudyAccessParent.Study ? '' : 'lg:col-span-2'
        }
      >
        <Panel>
          <Panel.Heading>
            <Panel.Title>Manage Company Access</Panel.Title>
          </Panel.Heading>
          <Panel.Body className="space-y-4">
            <AddStudyAccess
              parentType={parentType}
              parentId={parentId}
              availableCompanies={availableCompanies}
              refetchQueries={refetchQueries}
            />

            {sortedSA.length > 0 && (
              <div className="space-y-2">
                <Heading level={4}>Access granted to:</Heading>
                {sortedSA.map(sa => (
                  <StudyAccessItem
                    key={sa.id}
                    studyAccessId={sa.id}
                    companyName={sa.company.name}
                    refetchQueries={refetchQueries}
                  />
                ))}
              </div>
            )}
          </Panel.Body>
        </Panel>
      </div>

      {parentType === StudyAccessParent.Study && (
        <div>
          <table className="table table-compact w-full">
            <thead>
              <tr>
                <th>Project Companies:</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {projectStudyAccess.length > 0
                ? projectStudyAccess
                    .slice()
                    .sort(R.ascend(sa => sa.company.name))
                    .map(sa => (
                      <tr key={sa.company.id}>
                        <td>{sa.company.name}</td>
                      </tr>
                    ))
                : 'No companies have access'}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
