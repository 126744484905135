import { useQuery , gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import * as fragments from '~/apollo/fragments';
import { STUDY_ENUMERATIONS } from '~/apollo/operations/study';
import type {
  StudyEnumerationsQuery,
  UploadStudyUpdateOverviewTabQuery,
  UploadStudyUpdateOverviewTabQueryVariables,
} from '~/apollo/generated/schema';

import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { UpdateStudyForm } from '~/components/upload/study/UpdateStudyForm';

const STUDY_OVERVIEW_TAB = gql`
  query UploadStudyUpdateOverviewTab($id: Int!) {
    studyList(id: $id) {
      ...studyParts
      dataHistory {
        ...dataHistoryParts
      }
      qualityParameters {
        ...studyQualityParametersParts
      }
      outcrops {
        id
        geologyType
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.dataHistoryParts}
  ${fragments.studyQualityParametersParts}
`;

export default function UploadStudyUpdateOverviewPage() {
  const params = useParams<{ studyId: string }>();
  invariant(params.studyId, 'studyId param required');
  const studyId = parseInt(params.studyId);

  const { data, loading } = useQuery<
    UploadStudyUpdateOverviewTabQuery,
    UploadStudyUpdateOverviewTabQueryVariables
  >(STUDY_OVERVIEW_TAB, {
    variables: { id: studyId },
  });

  const { data: enumsData, loading: loadingEnums } =
    useQuery<StudyEnumerationsQuery>(STUDY_ENUMERATIONS, {});

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);

  const isLoading = loading || loadingEnums;

  if (isLoading) return <SpinnerPlaceholder />;
  if (!study || !enumsData) return null;

  return (
    <UpdateStudyForm studyId={studyId} study={study} enumOptions={enumsData} />
  );
}
