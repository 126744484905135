import type { FieldProps } from 'formik';
import { FormikSelectField } from '~/components/common/FormikField/FormikSelectField';

type Props = FieldProps & {};

export function LithostratOrderSelector({ form, field, ...props }: Props) {
  return (
    <FormikSelectField
      form={form}
      field={field}
      {...props}
      options={Array(100)
        .fill(undefined)
        .slice()
        .map((_, i) => ({ value: i + 1, label: String(i + 1) }))}
    />
  );
}
