import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import * as fragments from '~/apollo/fragments';
import type {
  UploadStudyUpdateCrossSectionsTabQuery,
  UploadStudyUpdateCrossSectionsTabQueryVariables,
} from '~/apollo/generated/schema';
import {
  CREATE_CROSS_SECTION,
  DELETE_CROSS_SECTION,
  UPDATE_CROSS_SECTION,
} from '~/apollo/operations/supportingObject';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

export const CROSS_SECTIONS_TAB = gql`
  query UploadStudyUpdateCrossSectionsTab($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      crossSections {
        ...crossSectionParts
        outcropTag {
          ...outcropParts
        }
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        files {
          ...fileParts
        }
        georeference {
          ...georeferenceParts
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.crossSectionParts}
  ${fragments.outcropParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.georeferenceParts}
`;

export default function UpdateStudyCrossSectionsRoute() {
  const ctx = useUpdateStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    UploadStudyUpdateCrossSectionsTabQuery,
    UploadStudyUpdateCrossSectionsTabQueryVariables
  >(CROSS_SECTIONS_TAB, {
    variables: { studyId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadStudyUpdateCrossSectionsTabQueryVariables>,
  ] = [
    {
      query: CROSS_SECTIONS_TAB,
      variables: { studyId },
    },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const crossSections = study?.crossSections ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <SupportObjectManager
      studyId={studyId}
      supportObject={crossSections}
      supportObjectType="crossSection"
      parentType="CROSS_SECTION"
      createMutation={CREATE_CROSS_SECTION}
      updateMutation={UPDATE_CROSS_SECTION}
      deleteMutation={DELETE_CROSS_SECTION}
      refetchQueries={refetchQueries}
    />
  );
}
