import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  CreateLithostratOutcropLinkMutation,
  CreateLithostratOutcropLinkMutationVariables,
  LithostratLinkTargetType,
  LithostratOutcropLinkInput,
} from '~/apollo/generated/schema';
import { CREATE_LITHOSTRAT_OUTCROP_LINK } from '~/apollo/operations/lithostrat';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import { Modal } from '~/components/common/Modal';
import { OutcropSelect } from '~/components/upload/outcrop/OutcropSelect';
import { useModalState } from '~/hooks/modal';
import { yup } from '~/utils/validation';

type FormValues = {
  parentId: string;
};
const initialFormValues = (): FormValues => ({ parentId: '' });

type Props = {
  children: (
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  ) => JSX.Element;
  targetType: LithostratLinkTargetType;
  targetId: number;
  refetchQueries: PureQueryOptions[];
};

/** Creates a LithostratOutcropLink from an existing lithostrat entity */
export function CreateLithostratOutcropLink({
  children,
  targetType,
  targetId,
  refetchQueries,
}: Props) {
  const { show, showModal, hideModal } = useModalState();
  const [createLink, { loading, error }] = useMutation<
    CreateLithostratOutcropLinkMutation,
    CreateLithostratOutcropLinkMutationVariables
  >(CREATE_LITHOSTRAT_OUTCROP_LINK, {
    refetchQueries,
  });

  async function handleSubmit(values: FormValues) {
    const lithostratOutcropLink: LithostratOutcropLinkInput = {
      targetType,
      targetId,
      parentId: parseInt(values.parentId),
    };
    try {
      await createLink({ variables: { lithostratOutcropLink } });
      toast.success('Link created successfully.');
      hideModal();
    } catch (err) {
      console.log('Error creating link', err);
      toast.error('There was a problem creating the link.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialFormValues()}
          validationSchema={yup.object({
            parentId: yup.number().integer().required(),
          })}
        >
          <Form>
            <Modal.Body heading="Create Outcrop Link">
              <div className="space-y-4">
                <Field
                  name="parentId"
                  label="Outcrop"
                  component={FormikField}
                  type={OutcropSelect}
                  required
                />
                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" loading={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
