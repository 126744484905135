import type { PureQueryOptions } from '@apollo/client';
import { faLink, faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { Link } from 'react-router-dom';
import type { UpdatePaleomapPageQuery } from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';
import { ListGroup } from '~/components/common/ListGroup';
import { Panel } from '~/components/common/Panel';
import { Tooltip } from '~/components/common/Tooltip';
import { uploadOutcropUpdateRoute } from '~/paths';
import { CreatePaleoMapOutcropLink } from './CreatePaleoMapOutcropLink';
import { DeletePaleoMapOutcropLink } from './DeletePaleoMapOutcropLink';

type Props = {
  paleoMap: UpdatePaleomapPageQuery['paleoMapList'][number];
  refetchQueries: PureQueryOptions[];
};

export function PaleoMapOutcropManager({ paleoMap, refetchQueries }: Props) {
  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>Outcrops</Panel.Title>

        <CreatePaleoMapOutcropLink
          paleoMapId={paleoMap.id}
          outcrops={paleoMap.outcrops}
          refetchQueries={refetchQueries}
        >
          {showModal => (
            <Button
              type="button"
              color="primary"
              size="xs"
              onClick={showModal}
              startIcon={<FontAwesomeIcon icon={faLink} />}
            >
              Link Outcrop
            </Button>
          )}
        </CreatePaleoMapOutcropLink>
      </Panel.Heading>

      <Panel.Body>
        <ListGroup className="space-y-2">
          {!paleoMap.outcrops.length && (
            <div className="text-center text-muted">
              <em>No outcrops linked.</em>
            </div>
          )}
          {paleoMap.outcrops.map(oc => (
            <ListGroup.Item key={oc.id}>
              <Link
                to={uploadOutcropUpdateRoute(oc.id)}
                target="_blank"
                className="flex justify-between items-center"
              >
                <div>
                  <strong>{oc.name}</strong>
                  <br />
                  {oc.region?.name}
                </div>

                <DeletePaleoMapOutcropLink
                  paleoMapId={paleoMap.id}
                  outcropId={oc.id}
                  refetchQueries={refetchQueries}
                >
                  {handleDelete => (
                    <Confirm
                      onConfirm={handleDelete}
                      text={`The link with ${oc.name} will be deleted.`}
                      submitButtonColor="error"
                      submitText="Delete Link"
                    >
                      {handleConfirm => (
                        <Button
                          type="button"
                          color="ghost"
                          size="xs"
                          onClick={handleConfirm}
                        >
                          <Tooltip message="Unlink outcrop">
                            <FontAwesomeIcon icon={faLinkSlash} />
                          </Tooltip>
                        </Button>
                      )}
                    </Confirm>
                  )}
                </DeletePaleoMapOutcropLink>
              </Link>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Panel.Body>
    </Panel>
  );
}
