import { useEffect } from 'react';
import { RadialProgress } from 'react-daisyui';
import { queryClient } from '~/main';
import {
  getGetApiV4VirtualOutcropModelsIdQueryKey,
  usePatchApiV4CesiumAssetsIdPollDownloading,
} from '~/openapi/api-v4';

const INTERVAL_DELAY = 1000 * 5;

export function DownloadingState({
  vomId,
  cesiumAssetId,
}: {
  vomId: string;
  cesiumAssetId: string;
}) {
  const { data, mutate, isLoading } =
    usePatchApiV4CesiumAssetsIdPollDownloading();

  useEffect(() => {
    function loadProgress() {
      mutate(
        {
          id: cesiumAssetId,
          data: {
            data: {
              id: cesiumAssetId,
            },
          },
          params: {
            fields: {
              cesium_asset: 'cesium_download_total,cesium_download_todo',
            },
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: getGetApiV4VirtualOutcropModelsIdQueryKey(vomId),
            });
          },
        },
      );
    }

    const interval = window.setInterval(loadProgress, INTERVAL_DELAY);
    loadProgress();

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [vomId, cesiumAssetId, mutate]);

  const total = data?.data?.attributes?.cesium_download_total;
  const todo = data?.data?.attributes?.cesium_download_todo ?? 0;
  const progress = total ? Math.floor(((total - todo) / total) * 100) : 0;

  return (
    <div className="text-center">
      <RadialProgress value={progress} color="success">
        <span className={isLoading ? 'text-muted' : undefined}>
          {progress}%
        </span>
      </RadialProgress>
    </div>
  );
}
