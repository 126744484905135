import * as R from 'ramda';
import { SupportingObjectPictureList } from '~/components/upload/supportingObject/SupportingObjectPictureList';
import { useUpdatePaleoMapOutletContext } from '~/routes/upload/paleomap/$paleoMapId';
import { PictureParent } from '~/apollo/generated/schema';
import { useState } from 'react';
import { Badge } from 'react-daisyui';

export default function UpdatePaleoMapPicturesRoute() {
  const { paleoMap, refetchQueries } = useUpdatePaleoMapOutletContext();

  const unpublishedCount =
    paleoMap.pictures.reduce((acc, cur) => acc + (cur.published ? 0 : 1), 0) ??
    0;

  const [showUnpublished, setShowUnpublished] = useState(!unpublishedCount);

  const disableUploadFromLimit = paleoMap.pictures.length >= 3;

  const pictures = R.pipe(
    () => paleoMap.pictures,
    pics => (showUnpublished ? pics : pics.filter(p => p.published)),
    R.sortBy(R.propOr(Infinity, 'priority')),
  )();

  return (
    <>
      <div className="form-control items-end">
        <label
          htmlFor="showUnpublished"
          className="label justify-start gap-2 cursor-pointer"
        >
          <input
            type="checkbox"
            id="showUnpublished"
            checked={showUnpublished}
            onChange={() => setShowUnpublished(!showUnpublished)}
            className="checkbox checkbox-sm"
            disabled={!unpublishedCount}
          />
          Show unpublished items{' '}
          <Badge color="ghost" className="font-mono">
            {unpublishedCount}
          </Badge>
        </label>
      </div>

      <SupportingObjectPictureList
        parentType={PictureParent.PaleoMap}
        parentId={paleoMap.id}
        pictures={pictures}
        refetchQueries={refetchQueries}
        disableUploadFromLimit={disableUploadFromLimit}
        showUnpublished={showUnpublished}
      />
    </>
  );
}
