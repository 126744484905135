import { companyParts } from '~/apollo/fragments';
import { gql } from '@apollo/client';

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: Int!, $company: CompanyInput!) {
    updateCompany(id: $id, company: $company) {
      ...companyParts
    }
  }

  ${companyParts}
`;
