import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { Input } from 'react-daisyui';
import { SearchFilter } from '~/components/analogueSearch/SearchFilter';
import { InputGroup } from '~/components/common/InputGroup';
import { useDebounce } from '~/hooks/debounce';
import type {
  AnalogueSearchFormValues,
  AnalogueSearchOptions,
} from '~/utils/modules/analogueSearch';
import { availableDataTopOptions } from '~/utils/modules/analogueSearch';
import { capitalize, ucwords } from '~/utils/text';

type Props = {
  options: AnalogueSearchOptions;
  loading: boolean;
};

export function SearchFilters({ options, loading }: Props) {
  const { values, setFieldValue } =
    useFormikContext<AnalogueSearchFormValues>();
  const [textSearch, setTextSearch] = React.useState(values.textSearch ?? '');
  const dbTextSearch = useDebounce(textSearch, 500);

  useEffect(() => {
    setFieldValue('textSearch', dbTextSearch);
  }, [dbTextSearch, setFieldValue]);

  function formatAvailableDataNames(name: string) {
    if (name === 'virtual_outcrop_mini_models') {
      return 'Virtual outcrop mini-models';
    } else if (name === 'virtual_outcrop_models_with_cesium') {
      return 'Virtual outcrop models (new viewer)';
    } else {
      return R.pipe(ucwords, str => str.toLowerCase(), capitalize)(name);
    }
  }

  return (
    <div className="space-y-4">
      <div className="form-control">
        <InputGroup>
          <InputGroup.Addon>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </InputGroup.Addon>
          <Input
            type="text"
            value={textSearch}
            onChange={e => setTextSearch(e.target.value)}
            placeholder="Text search"
            bordered
            className="w-full join-item"
          />
        </InputGroup>
      </div>

      <div className="grid lg:grid-cols-2 gap-6">
        <div className="space-y-4">
          <SearchFilter
            title="Geology Type"
            name="geologyType"
            options={options.geologyType}
            disabled={loading}
          />
          <SearchFilter
            title="Architectural Element"
            name="architecturalElement"
            options={options.architecturalElement}
            disabled={loading}
            onlyTop={99999}
          />
        </div>

        <div className="space-y-4">
          <SearchFilter
            title="Gross Depositional Environment"
            name="grossDepositionalEnvironment"
            options={options.grossDepositionalEnvironment}
            disabled={loading}
            onlyTop={3}
          />
          <SearchFilter
            title="Depositional Environment"
            name="depositionalEnvironment"
            options={options.depositionalEnvironment}
            disabled={loading}
            onlyTop={3}
          />
          <SearchFilter
            title="Subenvironment"
            name="depositionalSubEnvironment"
            options={options.depositionalSubEnvironment}
            disabled={loading}
            onlyTop={3}
          />
          <SearchFilter
            title="Available Data"
            name="supportingObjectTypes"
            options={options.supportingObjectTypes}
            disabled={loading}
            onlyTop={3}
            topOrder={availableDataTopOptions}
            renderName={formatAvailableDataNames}
          />
          <SearchFilter
            title="Basin Type"
            name="basinType"
            options={options.basinType}
            disabled={loading}
            onlyTop={3}
          />
          <SearchFilter
            title="Climate"
            name="climate"
            options={options.climate}
            disabled={loading}
            onlyTop={3}
          />
          <SearchFilter
            title="Geological Age"
            name="geologyAge"
            options={options.geologyAge}
            disabled={loading}
            onlyTop={3}
          />
          <SearchFilter
            title="Net to Gross"
            name="netToGross"
            options={options.netToGross}
            disabled={loading}
            onlyTop={3}
          />
          <SearchFilter
            title="Estimated Distance to Source Area"
            name="distanceToSourceArea"
            options={options.distanceToSourceArea}
            disabled={loading}
            onlyTop={3}
          />

          <SearchFilter
            title="Country"
            name="country"
            options={options.country}
            disabled={loading}
            onlyTop={3}
          />
        </div>
      </div>
    </div>
  );
}
