import React from 'react';
import { Button } from 'react-daisyui';

type Props = {
  value: string;
  onFilterChange: (value: string) => any;
  letterStyle?: React.CSSProperties;
};
export type AlphabetFilterProps = React.HTMLAttributes<HTMLDivElement> & Props;

export function AlphabetFilter({
  value,
  onFilterChange,
  letterStyle = {},
  ...props
}: AlphabetFilterProps) {
  const handleClick = (letter: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    onFilterChange(letter);
  };

  const alphabet = 'abcdefghijklmnopqrstuvwxyzæøå'.split('');

  return (
    <div {...props} className={props.className ?? 'text-center space-x-1'}>
      {alphabet.map(letter => (
        <Button
          key={letter}
          type="button"
          color={letter === value ? 'warning' : 'ghost'}
          size="sm"
          onClick={handleClick(letter)}
          className="px-1 py-0"
        >
          {letter.toUpperCase()}
        </Button>
      ))}
    </div>
  );
}
