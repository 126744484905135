import type { ComponentPropsWithoutRef, HTMLProps } from 'react';
import React, { useState } from 'react';
import type {
  KeyParametersPartsFragment,
  Outcrop,
} from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { Tooltip } from '~/components/common/Tooltip';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import { KPPanelContextProvier } from '~/components/keyParameter/KeyParametersPanel/KPPanelContext';
import { cn } from '~/utils/common';
import { ucwords } from '~/utils/text';
import { GeologyTypeTab } from './GeologyTypeTab';

function KPTitleWrapper({ className, ...props }: HTMLProps<HTMLSpanElement>) {
  return (
    <span
      className={cn('font-bold cursor-default break-words', className)}
      {...props}
    />
  );
}

type KPTitleProps = { name: string; abbreviation?: string };
export function KPTitle({ name, abbreviation }: KPTitleProps) {
  if (!abbreviation) {
    return (
      <KPTitleWrapper>
        {/* This allows us to put <wbr> elements into the label */}
        <span
          dangerouslySetInnerHTML={{ __html: name.replace('/', '/<wbr>') }}
        />
      </KPTitleWrapper>
    );
  }

  return (
    <Tooltip message={name}>
      <KPTitleWrapper>{abbreviation}</KPTitleWrapper>
    </Tooltip>
  );
}

type TabButtonContainerProps = ComponentPropsWithoutRef<'div'> & {
  active: boolean;
};
export function TabButtonContainer({
  active,
  className,
  ...props
}: TabButtonContainerProps) {
  return (
    <div
      className={cn(
        'inline-block mr-1 p-0.5  border-slate-300 border-b-0 rounded-t-md',
        active ? 'bg-slate-100 border border-slate-100' : 'bg-transparent',
      )}
      {...props}
    />
  );
}

type TabButtonProps = ComponentPropsWithoutRef<'button'> & {
  active: boolean;
};
export function TabButton({
  active,
  className,
  children,
  ...props
}: TabButtonProps) {
  return (
    <button
      type="button"
      className="p-1 rounded-md flex items-center justify-center bg-transparent"
      {...props}
    >
      {children}
    </button>
  );
}

export type OCAdditionalFields = Pick<
  Outcrop,
  | 'channelMorphology'
  | 'diageneticGeometry'
  | 'diageneticProcess'
  | 'diageneticSetting'
  | 'dominantLithology'
  | 'duneShape'
  | 'faultRocksMembranes'
  | 'interactionNetwork'
  | 'multipleFolds'
  | 'netToGross'
  | 'reactivation1stPhase'
  | 'reactivation2ndPhase'
  | 'riverProfileLocation'
  | 'secondaryStructures'
  | 'shorelineTractory'
  | 'symmetryGeometry'
  | 'synSedimentaryDeformation'
  | 'systemsTract'
  | 'tectonicSetting'
  | 'waterTemperature'
>;

type KeyParameters = Pick<
  KeyParametersPartsFragment,
  | 'id'
  | 'geologyType'
  | 'grossDepositionalEnvironment'
  | 'depositionalEnvironment'
  | 'depositionalSubEnvironment'
  | 'architecturalElement'
  | 'dominant'
>;

export type Props = {
  keyParameters: KeyParameters[];
  dominantGeologyType?: string | null;
  outcrop?: OCAdditionalFields;
  noHeading?: boolean;
  leftColMd?: number;
  rightColMd?: number;
  children?: (kps: React.ReactNode, tabs: React.ReactNode) => JSX.Element;
  view?: 'table' | 'combined';
  forceShow?: boolean;
};

export function KeyParametersPanel({
  keyParameters,
  dominantGeologyType,
  outcrop,
  noHeading: headingDisabled = false,
  leftColMd = 4,
  rightColMd = 8,
  view,
  children,
  forceShow,
}: Props) {
  const implTabs = ['clastic', 'carbonate', 'structural', 'unknown'];
  const tabs: Record<string, KeyParameters[]> = {
    clastic: keyParameters.filter(kp => kp.geologyType === 'clastic'),
    carbonate: keyParameters.filter(kp => kp.geologyType === 'carbonate'),
    structural: keyParameters.filter(kp => kp.geologyType === 'structural'),
    unknown: keyParameters.filter(
      kp => !kp.geologyType || !implTabs.includes(kp.geologyType),
    ),
  };

  // Show the tabs as long as there's at least one KP that has a geology type set
  const showTabs = tabs.unknown.length !== keyParameters.length;

  // Try to set the dominant geology type as the first one visible if it has
  // key parameters set, otherwise pick the first geology type that has KPs
  const firstVisibleTab = () => {
    if (dominantGeologyType) {
      const domGTKPs = tabs[dominantGeologyType] ?? [];
      if (domGTKPs.length > 0) {
        return dominantGeologyType;
      }
    }

    return (
      implTabs.reduce<string | null>((acc, cur) => {
        if (acc) return acc;
        if (tabs[cur].length > 0) return cur;
        return null;
      }, null) ?? 'unknown'
    );
  };

  const [currentTab, setCurrentTab] = useState(
    showTabs ? firstVisibleTab() : 'unknown',
  );

  if (!keyParameters.length && !forceShow) return null;

  const tabButtons = showTabs ? (
    <>
      {implTabs.map(gt => {
        const kps = tabs[gt] ?? [];
        const dominant = dominantGeologyType === gt;
        if (!kps.length) return null;
        return (
          <Tooltip
            key={gt}
            message={`${ucwords(gt)}${dominant ? ' (dominant)' : ''}`}
            position="top"
          >
            <TabButtonContainer active={gt === currentTab}>
              <TabButton
                active={gt === currentTab}
                onClick={() => setCurrentTab(gt)}
              >
                <GeologyTypeIcon
                  geologyType={gt}
                  dominant={dominant}
                  hideTooltip
                  className={cn('h-6 w-6', {
                    'b-orange-500 border-2': dominant,
                  })}
                />
              </TabButton>
            </TabButtonContainer>
          </Tooltip>
        );
      })}
    </>
  ) : null;

  const kpContent = (
    <GeologyTypeTab
      geologyType={currentTab}
      keyParameters={tabs[currentTab] ?? []}
      outcrop={outcrop}
      leftColMd={leftColMd}
      rightColMd={rightColMd}
      view={view}
    />
  );

  return (
    <KPPanelContextProvier>
      {children ? (
        children(kpContent, tabButtons)
      ) : (
        <>
          <div className="float-right">{showTabs && tabButtons}</div>
          {!headingDisabled && <Heading level={3}>Key Parameters</Heading>}
          <div className="clear-both" />
          {kpContent}
        </>
      )}
    </KPPanelContextProvier>
  );
}
