import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import type {
  Authority,
  SavedDataSearchData,
  SearchOutcropResult,
} from '~/apollo/generated/schema';
import { envVars } from '~/environment';

export const inMemoryCache = new InMemoryCache({
  possibleTypes: {
    Georeference: ['GeoreferencePoint', 'GeoreferencePolygon'],
    BookmarkParent: [
      'Outcrop',
      'Region',
      'Study',
      'PaleoMap',
      'DepositionalWiki',
      'OtherWiki',
      'LithostratFormation',
      'LithostratGroup',
      'LithostratMember',
      'Facies',
      'CrossSection',
      'SedimentaryLog',
      'WellLog',
      'Production',
      'ReservoirModel',
      'TrainingImage',
      'Variogram',
      'GigaPan',
      'SavedDataSearch',
    ],
    BookmarkTarget: [
      'Outcrop',
      'Region',
      'Study',
      'VirtualOutcropModel',
      'Picture',
      'GigaPan',
      'DepositionalWiki',
      'OtherWiki',
      'LithostratFormation',
      'LithostratGroup',
      'LithostratMember',
      'SavedDataSearch',
    ],
  },
  typePolicies: {
    // Singleton, always replace it with whatever the current result is
    Authority: {
      merge: (_existing: Authority, incoming: any[]) => incoming,
    },
    // There is no top-level key in search results --
    // manually throw away previous data here to hide the apollo warning
    SearchOutcropResult: {
      merge: (_existing: SearchOutcropResult[], incoming: any[]) => incoming,
    },
    // This appears to be needed for deleting SDS data objs and reloading the whole array
    SavedDataSearch: {
      fields: {
        data: {
          merge: (_existing: SavedDataSearchData[], incoming: any[]) =>
            incoming,
        },
      },
    },
  },
});

const v3Link = new HttpLink({
  uri: envVars.VITE_GRAPHQL_URL_V3,
  credentials: 'include',
});

export const apolloClient = new ApolloClient({
  cache: inMemoryCache,
  link: v3Link,
  ssrMode: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});
