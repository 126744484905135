import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import type {
  SavedDataSearchPartsFragment,
  UpdateSavedDataSearchMutation,
  UpdateSavedDataSearchMutationVariables,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import type { FormikSubmitHandler } from '~/utils/forms';
import type { SavedDataSearchFormValuesUpdate } from '~/utils/modules/dataSearch';
import { toUpdateSavedDataSearchInput } from '~/utils/modules/dataSearch';
import { yup } from '~/utils/validation';
import { SavedDataSearchFormFields } from './SavedDataSearchFormFields';
import { Panel } from '~/components/common/Panel';

export const UPDATE_SAVED_DATA_SEARCH = gql`
  mutation UpdateSavedDataSearch(
    $id: Int!
    $savedDataSearch: UpdateSavedDataSearchInput!
  ) {
    updateSavedDataSearch(id: $id, savedDataSearch: $savedDataSearch) {
      id
      name
    }
  }
`;

type Props = {
  sdsId: number;
  savedDataSearch: Pick<SavedDataSearchPartsFragment, 'name'>;
  children: (toggleEditing: () => void) => JSX.Element;

  /** Overrides the state variable if set */
  isEditing?: boolean;
  onUpdateSuccess?: () => void;
};

/** Form for updating METADATA of a saved data search (name, note, etc) */
export function UpdateSdsForm({
  sdsId,
  savedDataSearch,
  children,
  isEditing: isEditingProp,
  onUpdateSuccess,
}: Props) {
  const [isEditing, setIsEditing] = useState(isEditingProp ?? false);
  const [updateSds, { loading, error }] = useMutation<
    UpdateSavedDataSearchMutation,
    UpdateSavedDataSearchMutationVariables
  >(UPDATE_SAVED_DATA_SEARCH, {});

  useEffect(() => {
    if (typeof isEditingProp !== 'undefined') {
      setIsEditing(isEditingProp);
    }
  }, [isEditingProp]);

  const toggleEditing = () => {
    if (onUpdateSuccess) onUpdateSuccess();
    else setIsEditing(prev => !prev);
  };

  const handleSubmit: FormikSubmitHandler<
    SavedDataSearchFormValuesUpdate
  > = async values => {
    try {
      await updateSds({
        variables: {
          id: sdsId,
          savedDataSearch: toUpdateSavedDataSearchInput(values),
        },
      });
      toast.success('Saved search updated successfully.');

      if (onUpdateSuccess) {
        onUpdateSuccess();
      } else {
        setIsEditing(false);
      }
    } catch (err) {
      console.log('Error updating saved data search', err);
      toast.error(
        'There was a problem updating the bookmarked data. Please reload the page and try again.',
      );
    }
  };

  if (!isEditing) return children(toggleEditing);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        name: savedDataSearch.name ?? '',
      }}
      validationSchema={yup.object({
        name: yup.string().required(),
      })}
    >
      <Form className="space-y-2">
        <Panel>
          <Panel.Heading>
            <Panel.Title>Edit Bookmarked Data Details</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <div className="space-y-4">
              <SavedDataSearchFormFields mode="update" />
              <FormErrors graphQLError={error} />
            </div>
          </Panel.Body>

          <Panel.Footer>
            <div className="text-right space-x-1">
              <button
                type="button"
                className="btn btn-ghost"
                onClick={toggleEditing}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Save
              </button>
            </div>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}
