import { gql, useMutation, useQuery } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { outcropSubregionParts } from '~/apollo/fragments';
import type {
  UpdateOutcropSubregionMutation,
  UpdateOutcropSubregionMutationVariables,
  UploadOutcropSubregionUpdatePageQuery,
  UploadOutcropSubregionUpdatePageQueryVariables,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { DeleteOutcropSubregion } from '~/components/upload/outcropSubregion/DeleteOutcropSubregion';
import { OutcropSubregionFormFields } from '~/components/upload/outcropSubregion/OutcropSubregionFormFields';
import type { OutcropSubregionFormValues } from '~/utils/modules/outcropSubregion';
import {
  outcropSubregionFormValuesToInput,
  outcropSubregionInitialFormValues,
  outcropSubregionValidationSchema,
} from '~/utils/modules/outcropSubregion';

const updateOutcropSubregionParts = gql`
  fragment updateOutcropSubregionParts on OutcropSubregion {
    ...outcropSubregionParts
    project {
      id
      name
    }
    regions {
      id
      name
    }
    outcrops {
      id
      name
    }
  }

  ${outcropSubregionParts}
`;

const UPLOAD_OUTCROP_SUBREGION_UPDATE_PAGE = gql`
  query UploadOutcropSubregionUpdatePage($id: Int!) {
    outcropSubregionList(id: $id) {
      ...updateOutcropSubregionParts
    }
  }

  ${updateOutcropSubregionParts}
`;

const UPDATE_OUTCROP_SUBREGION = gql`
  mutation UpdateOutcropSubregion(
    $id: Int!
    $outcropSubregion: OutcropSubregionInput!
  ) {
    updateOutcropSubregion(id: $id, outcropSubregion: $outcropSubregion) {
      ...updateOutcropSubregionParts
    }
  }

  ${updateOutcropSubregionParts}
`;

type OutcropSubregion =
  UploadOutcropSubregionUpdatePageQuery['outcropSubregionList'][number];

function PageInner({
  outcropSubregion,
}: {
  outcropSubregion: OutcropSubregion;
}) {
  const [updateSubregion, { loading, error }] = useMutation<
    UpdateOutcropSubregionMutation,
    UpdateOutcropSubregionMutationVariables
  >(UPDATE_OUTCROP_SUBREGION);

  async function handleSubmit(values: OutcropSubregionFormValues) {
    try {
      await updateSubregion({
        variables: {
          id: outcropSubregion.id,
          outcropSubregion: outcropSubregionFormValuesToInput(values),
        },
      });
      toast.success('Outcrop Subregion updated successfully.');
    } catch (err) {
      console.log('Error updating Outcrop Subregion', err);
      toast.error('Error saving Outcrop Subregion.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={outcropSubregionInitialFormValues(outcropSubregion)}
      validationSchema={outcropSubregionValidationSchema}
    >
      <Form>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Edit Outcrop Subregion Details</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <div className="space-y-4">
              <OutcropSubregionFormFields />
              <FormErrors graphQLError={error} />
            </div>
          </Panel.Body>

          <Panel.Footer>
            <div className="text-right">
              <Button type="submit" color="primary" disabled={loading}>
                Save
              </Button>
            </div>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}

export function UploadOutcropSubregionUpdatePage() {
  const params = useParams();
  invariant(params.srId);
  const srId = parseInt(params.srId);

  const { data, loading } = useQuery<
    UploadOutcropSubregionUpdatePageQuery,
    UploadOutcropSubregionUpdatePageQueryVariables
  >(UPLOAD_OUTCROP_SUBREGION_UPDATE_PAGE, { variables: { id: srId } });

  const subregion = data?.outcropSubregionList.find(sr => sr.id === srId);

  useBreadcrumb(
    'routes/upload/outcrop-subregions/$srId',
    subregion?.name ?? 'Update Details',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!subregion) return <NotFound />;

  return (
    <div className="space-y-4">
      <PageInner outcropSubregion={subregion} />

      <div className="text-center">
        <DeleteOutcropSubregion id={subregion.id}>
          {(handleDelete, isDeleting) => (
            <Button
              type="button"
              onClick={handleDelete}
              color="error"
              variant="outline"
              size="xs"
              loading={isDeleting}
              startIcon={<FontAwesomeIcon icon={faTrash} />}
            >
              Delete Outcrop Subregion
            </Button>
          )}
        </DeleteOutcropSubregion>
      </div>
    </div>
  );
}
