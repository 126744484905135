import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Panel } from '~/components/common/Panel';
import { useQueryString } from '~/hooks/routing';
import { dashboardRoute } from '~/paths';
import type { UnifiedSearchFormValues } from '~/utils/modules/unifiedSearch';
import {
  initialUniSearchValues,
  unifiedSearchValidationSchema,
} from '~/utils/modules/unifiedSearch';
import { UnifiedSearchFormFields } from './UnifiedSearchFormFields';

type Props = {
  query?: string;
  criteria?: string[];
};

export function DashboardUnifiedSearch({ query, criteria }: Props) {
  const { stringify } = useQueryString();
  const navigate = useNavigate();

  async function handleSubmit(values: UnifiedSearchFormValues) {
    const params: any = {};

    if (values.query.length) {
      params.q = values.query;
      params.criteria = values.criteria.join(',');
    }

    const search = stringify(params);
    navigate({ pathname: dashboardRoute(), search });
  }

  return (
    <Panel>
      <Panel.Body>
        <div className="p-4">
          <Formik
            initialValues={initialUniSearchValues(query, criteria)}
            onSubmit={handleSubmit}
            validationSchema={unifiedSearchValidationSchema}
            validateOnBlur={false}
            validateOnChange
          >
            <Form>
              <UnifiedSearchFormFields />
            </Form>
          </Formik>
        </div>
      </Panel.Body>
    </Panel>
  );
}
