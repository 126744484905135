import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  RegisterUserMutation,
  RegisterUserMutationVariables,
  UserRegisterInput,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import type { RegisterCompany } from '~/routes/register';
import type { RegisterUserFormValues } from '~/utils/modules/user';
import {
  initialValuesRegister,
  validationSchemaRegister,
} from '~/utils/modules/user';
import { buildAuthUrl } from '../layout/Navbar/LoginButton';
import { RegisterFormFields } from './RegistrationFormFields';

export const REGISTER_USER = gql`
  mutation RegisterUser($user: UserRegisterInput!) {
    registerUser(user: $user) {
      id
    }
  }
`;

type Props = {
  company: RegisterCompany;
};

export function RegistrationForm({ company }: Props) {
  const [registerUser, { data, loading, error }] = useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(REGISTER_USER, {});

  async function handleSubmit(values: RegisterUserFormValues) {
    const user: UserRegisterInput = {
      companyId: company.id,
      username: `${values.usernamePrefix}${values.usernameSuffix}`,
      name: values.name,
      password: values.password,
    };

    try {
      await registerUser({ variables: { user } });
    } catch (err) {
      console.log('Error registering', err);

      let toastMessage =
        'There was a problem submitting the form. Please try again.';

      if (err instanceof Error) {
        if (err.message === 'username_exists') {
          toastMessage = `This username is already registered with an account in SafariDB. You can try resetting your password through the login form, or contact your company administrator for help accessing your account.`;
        }
      }

      toast.error(toastMessage);
    }
  }

  if (company.requireSSO) {
    return (
      <div>
        This company uses your work Microsoft account to log in. To register
        with SafariDB, use the link below and choose the "Continue with
        Microsoft" option.
        <div className="mt-4 text-center">
          <a href={buildAuthUrl()} className="btn btn-primary">
            Proceed to Registration
          </a>
        </div>
      </div>
    );
  }

  if (data?.registerUser?.id) {
    return (
      <p>
        Account created successfully. Please click the link in the email address
        you provided to complete registration.
      </p>
    );
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValuesRegister(company.mails)}
      validationSchema={validationSchemaRegister(company.mails)}
    >
      <Form>
        <RegisterFormFields companyMails={company.mails} />
        <FormErrors graphQLError={error} className="mt-4" />

        <div className="text-center mt-4">
          <Button
            type="submit"
            color="primary"
            className="btn btn-primary"
            loading={loading}
          >
            Create Account
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
