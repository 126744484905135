import { useRef, useState } from 'react';
import { Button } from 'react-daisyui';
import { Heading } from '~/components/common/Heading';
import { Prose } from '~/components/common/Prose';
import { stripHtml } from '~/utils/text';

type HtmlSectionProps = {
  title: string;
  content: string | null | undefined;
  truncate?: boolean;
  truncateLength?: number;
};

export function HtmlSection({
  title,
  content,
  truncate = true,
  truncateLength = 300,
}: HtmlSectionProps) {
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  if (!content) return null;

  function toggleExpand() {
    const next = !expanded;

    setExpanded(next);

    if (!next) {
      containerRef.current?.scrollIntoView();
    }
  }

  const charLength = stripHtml(content).length;
  const shouldTruncate = truncate && charLength > truncateLength;

  return (
    <div>
      <Heading level={3}>{title}</Heading>

      <div
        className={
          shouldTruncate && !expanded
            ? 'relative max-h-20 overflow-y-hidden'
            : ''
        }
      >
        {shouldTruncate && !expanded && (
          <div className="absolute top-0 w-full h-full bg-gradient-to-t from-white to-transparent via-transparent" />
        )}
        <Prose dangerouslySetInnerHTML={{ __html: content }} />
      </div>

      {shouldTruncate && !expanded && (
        <Button
          type="button"
          size="xs"
          color="ghost"
          onClick={toggleExpand}
          className="text-primary hover:text-primary"
        >
          read more...
        </Button>
      )}
      {shouldTruncate && expanded && (
        <Button
          type="button"
          size="xs"
          color="ghost"
          onClick={toggleExpand}
          className="text-primary hover:text-primary"
        >
          read less
        </Button>
      )}
    </div>
  );
}
