import { gql, useQuery } from '@apollo/client';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { Button, Join, Select } from 'react-daisyui';
import type { OutcropSubregionFormFieldOptionsQuery } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import { Heading } from '~/components/common/Heading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { ProjectSelect } from '~/components/upload/project/ProjectSelect';
import { cn, rejectNil } from '~/utils/common';
import type { OutcropSubregionFormValues } from '~/utils/modules/outcropSubregion';

const OUTCROP_SUBREGION_FORM_FIELD_OPTIONS = gql`
  query OutcropSubregionFormFieldOptions {
    regionList {
      id
      name
      outcrops {
        id
        name
      }
    }
  }
`;

type RegionOption = OutcropSubregionFormFieldOptionsQuery['regionList'][number];

export function OutcropSubregionFormFields() {
  const { data, loading } = useQuery<OutcropSubregionFormFieldOptionsQuery>(
    OUTCROP_SUBREGION_FORM_FIELD_OPTIONS,
  );

  const { values } = useFormikContext<OutcropSubregionFormValues>();

  const regions = useMemo(() => {
    return R.sortBy(r => r.name, data?.regionList ?? []);
  }, [data?.regionList]);

  const selectedRegions = values.regionIds
    .map(regionId => regions.find(r => r.id === regionId))
    .filter(rejectNil);

  return (
    <div className="">
      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-2">
          <Field name="name" label="Name" component={FormikField} required />
          <Field
            name="description"
            label="Description"
            component={FormikField}
          />
          <Field
            name="projectId"
            label="Project"
            component={FormikField}
            type={ProjectSelect}
            required
          />
        </div>
        <div className="space-y-2">
          {selectedRegions.map(region => (
            <Region key={region.id} region={region} />
          ))}

          {loading ? <SpinnerPlaceholder /> : <AddRegion regions={regions} />}
        </div>
      </div>
    </div>
  );
}

function AddRegion({ regions }: { regions: RegionOption[] }) {
  const [isAdding, setIsAdding] = useState(false);
  const [regionId, setRegionId] = useState<number | null>(null);
  const { values, setFieldValue } =
    useFormikContext<OutcropSubregionFormValues>();

  function handleAdd() {
    if (regionId) {
      const nextRegions = values.regionIds.concat([regionId]);
      setFieldValue('regionIds', nextRegions);
      setIsAdding(false);
      setRegionId(null);
    }
  }

  if (!isAdding) {
    return (
      <div className="border-4 border-dotted border-slate-200 bg-slate-50 text-center p-6">
        <Button
          type="button"
          onClick={() => setIsAdding(true)}
          color="ghost"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          Add Region
        </Button>
      </div>
    );
  }

  return (
    <div className="border-4 border-dotted border-slate-200 bg-slate-50 text-center p-6">
      <Join className="w-full">
        <Select
          value={regionId ?? ''}
          onChange={event => setRegionId(~~event.target.value || null)}
          className="join-item w-full"
        >
          <option value="">- Select -</option>
          {regions.map(region => (
            <option
              key={region.id}
              value={region.id}
              disabled={values.regionIds.includes(region.id)}
            >
              {region.name}
            </option>
          ))}
        </Select>

        <Button
          type="button"
          onClick={handleAdd}
          disabled={!regionId}
          color="primary"
          className="join-item rounded-r-md"
        >
          Add
        </Button>
      </Join>
    </div>
  );
}

function Region({ region }: { region: RegionOption }) {
  const { values, setFieldValue } =
    useFormikContext<OutcropSubregionFormValues>();

  const regionOutcrops = R.sortBy(oc => oc.name, region.outcrops);
  const regionOutcropIds = regionOutcrops.map(oc => oc.id);

  function handleRemove() {
    const nextRegionIds = values.regionIds.filter(
      regionId => regionId !== region.id,
    );
    const nextOutcropIds = values.outcropIds.filter(
      outcropId => !regionOutcropIds.includes(outcropId),
    );

    setFieldValue('regionIds', nextRegionIds);
    setFieldValue('outcropIds', nextOutcropIds);
  }

  const hasOutcropsSelected =
    values.outcropIds.findIndex(ocId => regionOutcropIds.includes(ocId)) > -1;

  return (
    <div
      key={region.id}
      className={cn({
        'border border-slate-100': hasOutcropsSelected,
        'border-2 border-error': !hasOutcropsSelected,
      })}
    >
      <div className="bg-slate-100 p-2 flex items-center justify-between">
        <Heading level={4}>{region.name}</Heading>
        <Button type="button" onClick={handleRemove} color="ghost" size="xs">
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div>

      <div className="p-2">
        {regionOutcrops.map(oc => (
          <Field
            key={oc.id}
            name="outcropIds"
            value={oc.id}
            label={oc.name}
            component={FormikField}
            type="checkbox"
          />
        ))}
      </div>
    </div>
  );
}
