import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';

import * as fragments from '~/apollo/fragments';
import {
  CREATE_CROSS_SECTION,
  UPDATE_CROSS_SECTION,
  DELETE_CROSS_SECTION,
} from '~/apollo/operations/supportingObject';
import type {
  UploadOutcropCrossSectionsTabQuery,
  UploadOutcropCrossSectionsTabQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

export const UPLOAD_OUTCROP_CROSS_SECTIONS_TAB = gql`
  query UploadOutcropCrossSectionsTab($id: Int!) {
    outcropList(id: $id) {
      id
      crossSections {
        ...crossSectionParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
          outcropTag {
            ...outcropParts
          }
        }
        files {
          ...fileParts
        }
        georeference {
          ...georeferenceParts
        }
        outcropTag {
          ...outcropParts
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.crossSectionParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.georeferenceParts}
`;

export default function UpdateOutcropCrossSectionsRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UploadOutcropCrossSectionsTabQuery,
    UploadOutcropCrossSectionsTabQueryVariables
  >(UPLOAD_OUTCROP_CROSS_SECTIONS_TAB, {
    variables: { id: outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropCrossSectionsTabQueryVariables>,
  ] = [
    {
      query: UPLOAD_OUTCROP_CROSS_SECTIONS_TAB,
      variables: { id: outcropId },
    },
  ];

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);
  const crossSections = outcrop?.crossSections ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <SupportObjectManager
      outcropId={outcropId}
      supportObject={crossSections}
      supportObjectType="crossSection"
      parentType="CROSS_SECTION"
      createMutation={CREATE_CROSS_SECTION}
      updateMutation={UPDATE_CROSS_SECTION}
      deleteMutation={DELETE_CROSS_SECTION}
      refetchQueries={refetchQueries}
    />
  );
}
