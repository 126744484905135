import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { FormikTagsinputField } from '~/components/common/FormikField/FormikTagsinputField';

type Props = {
  isSSO: boolean;
};

export function CompanyOptionsFields({ isSSO }: Props) {
  return (
    <>
      <Field
        name="mails"
        label="Valid email domains"
        component={FormikField}
        type={FormikTagsinputField}
        required
        disabled={isSSO}
      />
      <Field
        name="allowRegister"
        label="Allow new user registration"
        component={FormikField}
        type="checkbox"
        required
      />
    </>
  );
}
