import type { PureQueryOptions } from '@apollo/client';
import { faExternalLink, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import * as R from 'ramda';
import {
  useEffect,
  useRef,
  useState,
  type CSSProperties,
  type ReactNode,
} from 'react';
import { Button } from 'react-daisyui';
import {
  type FilePartsFragment,
  type PicturePartsFragment,
} from '~/apollo/generated/schema';
import type { TargetBookmarksManagerModalProps } from '~/components/bookmark/TargetBookmarksManagerModal';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import { Modal } from '~/components/common/Modal';
import { useRefetchQueries } from '~/hooks/apollo';
import { useModalState } from '~/hooks/modal';
import { truncateText } from '~/utils/common';

type Picture = PicturePartsFragment & {
  file: FilePartsFragment;
};

type DescriptionRowProps = {
  label: ReactNode;
  value: string | null | undefined;
};
function DescriptionRow({ label, value }: DescriptionRowProps) {
  if (R.isNil(value)) return null;
  return (
    <tr>
      <th>{label}</th>
      <td>{value}</td>
    </tr>
  );
}

type Props = {
  picture: Picture;
  imgSrc: string;
  fade?: boolean;
  imgStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  bookmarkable?: TargetBookmarksManagerModalProps;
  showCaption?: boolean;
  refetchQueries: PureQueryOptions[];
};
export function PictureThumbnail({
  picture: p,
  imgSrc,
  fade = false,
  imgStyle,
  containerStyle,
  bookmarkable,
  showCaption = true,
  refetchQueries,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [shouldOpenInNewWindow, setShouldOpenInNewWindow] = useState(false);
  const [refetch] = useRefetchQueries(refetchQueries);
  const signedUrl = useRef(p.file.signedUrl);

  useEffect(() => {
    if (shouldOpenInNewWindow && signedUrl.current !== p.file.signedUrl) {
      setShouldOpenInNewWindow(false);
      window.open(p.file.signedUrl, '_blank');
    }

    signedUrl.current = p.file.signedUrl;
  }, [p.file.signedUrl, shouldOpenInNewWindow]);

  async function reloadAndOpenInNewWindow() {
    setShouldOpenInNewWindow(true);
    await refetch();
  }

  return (
    <>
      <div
        className={cn('thumbnail', {
          'border-4 border-info border-dotted p-2 relative': !p.published,
        })}
        style={containerStyle}
      >
        <a
          href="#enlarge-image"
          onClick={showModal}
          className="thumbnail fade-img"
        >
          <div
            // For non-faded images, use a bg image to make a nice thumbnail
            className={cn(fade ? '' : 'w-full h-0 bg-cover bg-center')}
            style={
              fade
                ? {}
                : { paddingTop: '50%', backgroundImage: `url(${imgSrc})` }
            }
          >
            {/* On faded images use an img tag for the ::after effect */}
            {fade && <img src={imgSrc} alt="" />}
          </div>
        </a>

        {p && p.description && showCaption && (
          <div className="caption text-center">
            {truncateText(p.description, 50)}
          </div>
        )}

        {!p.published && (
          <FontAwesomeIcon
            icon={faEyeSlash}
            className="absolute top-2 right-2 opacity-75 text-info text-3xl"
          />
        )}
      </div>

      <Modal open={show} onHide={hideModal} size="lg">
        <Modal.Body heading={p.name ?? 'Picture'}>
          {bookmarkable && (
            <div className="text-right">
              <TargetBookmarksManagerModal {...bookmarkable} />
            </div>
          )}

          <div className="w-full space-y-4">
            <div className="text-center space-y-0.5">
              <img src={imgSrc} alt={p.name} className="inline" />
              <div className="text-right">
                <Button
                  type="button"
                  onClick={reloadAndOpenInNewWindow}
                  color="ghost"
                  size="sm"
                  endIcon={<FontAwesomeIcon icon={faExternalLink} />}
                  loading={shouldOpenInNewWindow}
                >
                  View in full resolution
                </Button>
              </div>
            </div>

            {p && (
              <div>
                <table className="table table-compact w-full">
                  <tbody>
                    <DescriptionRow label="Description" value={p.description} />
                    <DescriptionRow label="Author" value={p.author} />
                    <DescriptionRow label="Type" value={p.type} />
                    <DescriptionRow label="Scale" value={p.scale} />
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
