import { useMutation , gql } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import type {
  DeleteSavedDataSearchDataMutation,
  DeleteSavedDataSearchDataMutationVariables,
} from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';
import { savedDataSearchRoute } from '~/paths';
import { Button } from 'react-daisyui';

const DELETE_SAVED_DATA_SEARCH_DATA = gql`
  mutation DeleteSavedDataSearchData($dataId: Int!) {
    deleteSavedDataSearchData(id: $dataId)
  }
`;

type Props = {
  searchId: number;
  dataId: number;
  onDeleteSuccess: () => Promise<void>;
};

export function DeleteRevisionButton({
  searchId,
  dataId,
  onDeleteSuccess,
}: Props) {
  const navigate = useNavigate();

  const [deleteSdsData, { loading }] = useMutation<
    DeleteSavedDataSearchDataMutation,
    DeleteSavedDataSearchDataMutationVariables
  >(DELETE_SAVED_DATA_SEARCH_DATA, {
    variables: { dataId },
  });

  async function handleDelete() {
    try {
      await deleteSdsData();
      await onDeleteSuccess();
      toast.success('Data snapshot deleted successfully.');
      navigate(savedDataSearchRoute(searchId), { replace: true });
    } catch (err) {
      console.log('Error deleting sds data', err);
      toast.error(
        'There was a problem deleting the snapshot. Please reload the page and try again.',
      );
    }
  }

  return (
    <Confirm
      onConfirm={handleDelete}
      text="This data snapshot will be permanently deleted. Are you sure you want to proceed?"
    >
      {confirmDelete => (
        <Button
          type="button"
          color="ghost"
          size="xs"
          onClick={confirmDelete}
          disabled={loading}
          className="text-error hover:text-error gap-1"
        >
          <FontAwesomeIcon icon={faTrash} /> Delete this Revision
        </Button>
      )}
    </Confirm>
  );
}
