import type { TooltipProps as DaisyTooltipProps } from 'react-daisyui';
import { Tooltip as DaisyTooltip } from 'react-daisyui';

export type TooltipProps = {
  /** Disable the tooltip, just show the content instead */
  disabled?: boolean;
} & DaisyTooltipProps;

export function Tooltip({
  children,
  disabled,
  ...props
}: TooltipProps): JSX.Element {
  if (disabled) return <>{children}</>;
  return <DaisyTooltip {...props}>{children}</DaisyTooltip>;
}
