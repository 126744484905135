import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import * as fragments from '~/apollo/fragments';
import type {
  StudyVariogramsRouteQuery,
  StudyVariogramsRouteQueryVariables,
} from '~/apollo/generated/schema';
import { BookmarkParentType } from '~/apollo/generated/schema';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportingObjectList } from '~/components/supportingObject/SupportingObjectList';
import { useStudyOutletContext } from '~/routes/study/$studyId';

const STUDY_VARIOGRAMS_ROUTE = gql`
  query StudyVariogramsRoute($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      variograms {
        ...variogramParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        files {
          ...fileParts
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.variogramParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function StudyVariogramsRoute() {
  const ctx = useStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    StudyVariogramsRouteQuery,
    StudyVariogramsRouteQueryVariables
  >(STUDY_VARIOGRAMS_ROUTE, { variables: { studyId } });

  const refetchQueries: PureQueryOptions[] = [
    { query: STUDY_VARIOGRAMS_ROUTE, variables: { studyId } },
  ];

  const items = data?.studyList.find(s => s.id === studyId)?.variograms ?? [];

  if (loading) return <SpinnerPlaceholder />;

  return (
    <SupportingObjectList
      items={items}
      soParentType="study"
      soParentId={studyId}
      bookmarkParentType={BookmarkParentType.Variogram}
      refetchQueries={refetchQueries}
    />
  );
}
