import type { PureQueryOptions } from '@apollo/client';
import { cn } from '~/utils/common';
import type {
  FilePartsFragment,
  PicturePartsFragment,
} from '~/apollo/generated/schema';
import { BookmarkTargetType } from '~/apollo/generated/schema';
import type { TargetBookmarksManagerModalProps } from '~/components/bookmark/TargetBookmarksManagerModal';
import { PictureThumbnail } from '~/components/common/PictureThumbnail';
import type { BookmarkableSOProps } from './SupportingObjectList';

type Picture = PicturePartsFragment & {
  file: FilePartsFragment;
};

type Props = {
  pictures?: Picture[];
  bookmarkable?: BookmarkableSOProps & { parentId: number };
  refetchQueries: PureQueryOptions[];
};

export function PanoramaListItem({
  pictures,
  bookmarkable,
  refetchQueries,
}: Props) {
  if (!pictures?.length) return null;

  const bookmarkableProps = (
    picture: Picture,
  ): TargetBookmarksManagerModalProps | undefined => {
    if (!bookmarkable) return undefined;
    return {
      ...bookmarkable,
      targetType: BookmarkTargetType.Picture,
      targetId: picture.id,
    };
  };

  return (
    <div
      className={cn('grid gap-6', {
        'lg:grid-cols-2': pictures.length === 2,
        'lg:grid-cols-3': pictures.length === 3,
        'lg:grid-cols-4': pictures.length > 3,
      })}
    >
      {pictures.map(p => (
        <div key={p.id}>
          <PictureThumbnail
            picture={p}
            imgSrc={p.file.signedUrl}
            bookmarkable={bookmarkableProps(p)}
            refetchQueries={refetchQueries}
          />
        </div>
      ))}
    </div>
  );
}
