import type {
  MarkerProps,
  PolygonProps,
  PolylineProps,
} from '@react-google-maps/api';
import { MarkerF, PolygonF, PolylineF } from '@react-google-maps/api';
import React from 'react';
import type {
  GeoreferenceInput,
  GeoreferencePartsFragment,
  LatLng,
} from '~/apollo/generated/schema';
import { GeoreferenceDataType } from '~/apollo/generated/schema';
import { colorList } from '~/utils/chart';
import { toGeoreferenceDataType } from '~/utils/modules/georeference';
import { yup } from '~/utils/validation';

const red = colorList[1];
const blue = colorList[0];

export const georefColors = {
  outcrop: red,
  study: blue,
};

// Using a data URL is significantly more performant than google's builtin
// symbol drawing API when we have more than a few markers on the map.
// We need to base64 encode the SVG so that the color hex values are escaped.
export type MarkerIconSvgOptions = {
  size: number;
  fill: string;
  fillOpacity: number;
  stroke: string;
  strokeWidth: number;
  strokeOpacity: number;
};
const defaultOpts: MarkerIconSvgOptions = {
  size: 14,
  fill: red,
  fillOpacity: 0.8,
  stroke: red,
  strokeWidth: 1,
  strokeOpacity: 1,
};
export function markerIconSvgUrl(opts?: Partial<MarkerIconSvgOptions>) {
  const o = { ...defaultOpts, ...opts };
  const size = o.size + o.strokeWidth;

  const svg = `
    <svg width="${size}" height="${size}" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="50%"
        cy="50%"
        r="${Math.floor(size / 2) - o.strokeWidth}"
        fill="${o.fill}"
        fill-opacity="${o.fillOpacity}"
        stroke="${o.stroke}"
        stroke-width="${o.strokeWidth}"
        stroke-opacity="${o.strokeOpacity}"
      />
    </svg>
  `;

  return `data:image/svg+xml;base64, ${window.btoa(svg)}`;
}

export const markerIcon = (
  options?: Partial<MarkerIconSvgOptions>,
): NonNullable<google.maps.MarkerOptions['icon']> => ({
  // path: google.maps.SymbolPath.CIRCLE,
  // fillColor: '#ff4d4d',
  // fillOpacity: 0.8,
  // strokeColor: red,
  // strokeOpacity: 1,
  // strokeWeight: 1,
  // scale: 5,
  // ...options,
  url: markerIconSvgUrl(options),
});

export const polygonOptions = (options?: Partial<PolygonProps['options']>) => ({
  fillColor: red,
  fillOpacity: 0.1,
  strokeColor: red,
  strokeWeight: 5,
  ...options,
});

export const polylineOptions = (
  options?: Partial<PolylineProps['options']>,
) => ({
  fillColor: red,
  fillOpacity: 0.1,
  strokeColor: red,
  strokeWeight: 5,
  ...options,
});

type FixedProps<T> = Partial<T> & JSX.IntrinsicAttributes;
type EntityProps = {
  sharedProps?: FixedProps<MarkerProps> &
    FixedProps<PolygonProps> &
    FixedProps<PolylineProps>;
  markerProps?: FixedProps<MarkerProps>;
  polygonProps?: FixedProps<PolygonProps>;
  polylineProps?: FixedProps<PolylineProps>;
};

export function createEntity(
  georeference: GeoreferencePartsFragment,
  props?: EntityProps & JSX.IntrinsicAttributes,
): React.ReactNode {
  switch (georeference.dataType) {
    case GeoreferenceDataType.Point:
    case GeoreferenceDataType.Centre:
      return createMarker(georeference, {
        ...props?.sharedProps,
        ...props?.markerProps,
      });

    case GeoreferenceDataType.Polyline:
      return createPolyline(georeference, {
        ...props?.sharedProps,
        ...props?.polylineProps,
      });

    case GeoreferenceDataType.Outline:
    case GeoreferenceDataType.Polygon:
      return createPolygon(georeference, {
        ...props?.sharedProps,
        ...props?.polygonProps,
      });

    default:
      return null;
  }
}

export function createMarker(
  georeference: Pick<GeoreferencePartsFragment, 'data'>,
  props?: Partial<MarkerProps> & JSX.IntrinsicAttributes,
) {
  return (
    <MarkerF
      position={georeference.data[0]}
      icon={props?.icon ?? markerIcon()}
      {...props}
      key={props?.key}
    />
  );
}

export function createPolyline(
  georeference: Pick<GeoreferencePartsFragment, 'data'>,
  props?: Partial<PolylineProps> & JSX.IntrinsicAttributes,
) {
  return (
    <PolylineF
      path={georeference.data}
      options={polylineOptions(props?.options)}
      {...props}
      key={props?.key}
    />
  );
}

export function createPolygon(
  georeference: Pick<GeoreferencePartsFragment, 'data'>,
  props?: Partial<PolygonProps> & JSX.IntrinsicAttributes,
) {
  return (
    <PolygonF
      path={georeference.data}
      options={polygonOptions(props?.options)}
      {...props}
      key={props?.key}
    />
  );
}

export function toLatLng({ lat, lng }: LatLng) {
  return new google.maps.LatLng(lat, lng);
}

export function createBounds(
  georeferences: Pick<GeoreferencePartsFragment, 'data'>[],
) {
  const bounds = new google.maps.LatLngBounds();

  for (const g of georeferences) {
    for (const c of g.data) {
      bounds.extend(c);
    }
  }

  if (!georeferences.length) {
    bounds.extend({ lat: -50, lng: -50 });
    bounds.extend({ lat: 50, lng: 50 });
  }

  return bounds;
}

export const markerTypes = [
  GeoreferenceDataType.Point,
  GeoreferenceDataType.Centre,
];
export const polygonTypes = [
  GeoreferenceDataType.Polygon,
  GeoreferenceDataType.Outline,
];
export const polylineTypes = [GeoreferenceDataType.Polyline];

const isDataType = (matchTypes: GeoreferenceDataType[]) => (input: string) =>
  matchTypes.some(t => t === input);

export const isCenterType = isDataType([GeoreferenceDataType.Centre]);
export const isMarkerType = isDataType(markerTypes);
export const isPolygonType = isDataType(polygonTypes);
export const isPolylineType = isDataType(polylineTypes);

export function getDrawingModes(dataType: GeoreferenceDataType | '') {
  if (isMarkerType(dataType)) {
    return [google.maps.drawing.OverlayType.MARKER];
  } else if (isPolygonType(dataType)) {
    return [google.maps.drawing.OverlayType.POLYGON];
  } else if (isPolylineType(dataType)) {
    return [google.maps.drawing.OverlayType.POLYLINE];
  } else {
    return [];
  }
}

export function firstOutlineOrPolygon(
  georeferences: GeoreferencePartsFragment[],
): GeoreferencePartsFragment | null {
  const polys = georeferences.filter(g => isPolygonType(g.dataType));
  const outline = polys.find(g => g.dataType === GeoreferenceDataType.Outline);
  return outline ?? polys.at(0) ?? null;
}

export function calculateCenter(georefs: GeoreferencePartsFragment[]) {
  const bounds = createBounds(georefs);
  return bounds.getCenter();
}

export interface GeoreferenceFormValues {
  name: string;
  description: string;
  dataType: string;
  data: null | LatLng[];
}

export function defaultGeoreference(): GeoreferenceFormValues {
  return {
    name: '',
    description: '',
    dataType: '',
    data: null,
  };
}

export function toGeoreferenceInput(
  formValues: GeoreferenceFormValues,
): GeoreferenceInput {
  return {
    name: formValues.name.trim(),
    description: formValues.description.trim() || null,
    dataType: toGeoreferenceDataType(formValues.dataType),
    data: formValues.data ?? [],
  };
}

export const georeferenceValidationSchema = (dataType: string) => {
  const dataSchema = yup
    .array(
      yup.object({
        lat: yup.number().required(),
        lng: yup.number().required(),
      }),
    )
    .min(1);

  // A point may only have a single coordinate
  const dataPointSchema = dataSchema.max(1).required();
  const dataPolygonSchema = dataSchema.required();

  return yup.object({
    name: yup.string().min(1).required(),
    description: yup.string().nullable(),
    dataType: yup
      .string()
      .oneOf(Object.values(GeoreferenceDataType))
      .required(),
    data: isMarkerType(dataType) ? dataPointSchema : dataPolygonSchema,
  });
};

/** Sorting function to put polygons first and everything else last, as an attempt
 * to prevent polygons from covering other elements that cannot be clicked */
type GeorefForSort = Pick<GeoreferencePartsFragment, 'dataType'>;
export function sortGeoreferenceVisibility(a: GeorefForSort, b: GeorefForSort) {
  if (a.dataType === b.dataType) {
    return 0;
  }

  if (isPolygonType(a.dataType) && !isPolygonType(b.dataType)) {
    return -1;
  } else {
    return 1;
  }
}
