import { useParams } from 'react-router-dom';
import { Menu } from 'react-daisyui';
import { NavLink } from '~/components/common/NavLink';
import { useAuth } from '~/contexts/auth';
import { aboutRoute } from '~/paths';

export type AboutMenuItem = {
  pageName: string;
  title: string;
  subMenu?: AboutMenuItem[];
};

export function createMenuStructure(isAuthenticated: boolean) {
  const menu: AboutMenuItem[] = [
    {
      pageName: 'background',
      title: 'Background',
      subMenu: [
        { pageName: 'safari1', title: 'Safari 1' },
        { pageName: 'safari2', title: 'Safari 2' },
        { pageName: 'safari3', title: 'Safari 3' },
        { pageName: 'safari4', title: 'Safari 4' },
        { pageName: 'safari5', title: 'Safari 5' },
      ],
    },
    {
      pageName: 'partners',
      title: 'Partners',
    },
    {
      pageName: 'people',
      title: 'People',
      subMenu: [{ pageName: 'students', title: 'Student Education' }],
    },
  ];

  if (isAuthenticated) {
    menu.push({ pageName: 'tech', title: 'Technical Overview' });
  }

  menu.push({ pageName: 'contact', title: 'Contact' });

  if (isAuthenticated) {
    menu.push({ pageName: 'sc-docs', title: 'SC Documents' });
  }

  if (isAuthenticated) {
    menu.push({ pageName: 'publications', title: 'Publications' });
  }

  return menu;
}

const getClassName = (pageName: string, currentPage: string) =>
  pageName === currentPage ? 'active' : undefined;

const createMenuLink = (item: AboutMenuItem, currentPage: string) => (
  <div key={item.pageName} className={getClassName(item.pageName, currentPage)}>
    <Menu.Item>
      <NavLink to={aboutRoute(item.pageName)}>{item.title}</NavLink>
    </Menu.Item>

    {item.subMenu && (
      <Menu className="ml-6">
        {item.subMenu.map(child => createMenuLink(child, currentPage))}
      </Menu>
    )}
  </div>
);

export function SideNavigation() {
  const { isAuthenticated } = useAuth();
  const params = useParams();

  return (
    <Menu>
      {createMenuStructure(isAuthenticated).map(item =>
        createMenuLink(item, params.currentPage ?? ''),
      )}
    </Menu>
  );
}
