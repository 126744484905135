import type { PureQueryOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  ProjectPartsFragment,
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import type { ProjectFormValues } from '~/utils/modules/project';
import {
  defaultProject,
  projectValidationSchema,
} from '~/utils/modules/project';
import { ProjectFormFields } from './ProjectFormFields';

export const UPDATE_PROJECT = gql`
  mutation updateProject($id: Int!, $project: ProjectInput!) {
    updateProject(id: $id, project: $project) {
      id
      name
    }
  }
`;

type Props = {
  project: ProjectPartsFragment;
  refetchQueries: PureQueryOptions[];
};

export function UpdateProjectForm({ project, refetchQueries }: Props) {
  const [updateProject, { loading, error }] = useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(UPDATE_PROJECT, {
    refetchQueries,
  });

  async function handleSubmit(values: ProjectFormValues) {
    try {
      await updateProject({
        variables: {
          id: project.id,
          project: values,
        },
      });
      toast.success('Project updated successfully.');
    } catch (err) {
      console.log('Error updating project', err);
      toast.error('There was a problem updating the project.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultProject(project)}
      validationSchema={projectValidationSchema}
    >
      <Form>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Project details</Panel.Title>
          </Panel.Heading>

          <Panel.Body className="space-y-6">
            <ProjectFormFields />
            <FormErrors graphQLError={error} />
          </Panel.Body>

          <Panel.Footer className="text-right">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}
