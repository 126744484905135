import { useQuery , gql } from '@apollo/client';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import React, { useState } from 'react';
import { Badge } from 'react-daisyui';

import type {
  AekPsValidityQuery,
  LithostratKPsValidityQuery,
  OutcropKPsValidityQuery,
  StudyKPsValidityQuery,
} from '~/apollo/generated/schema';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { ListGroup } from '~/components/common/ListGroup';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import {
  uploadLithostratFormationUpdateWikiRoute,
  uploadLithostratGroupUpdateWikiRoute,
  uploadLithostratMemberUpdateWikiRoute,
  uploadOutcropUpdateKeyParametersRoute,
  uploadStudyUpdateAEDetailRoute,
  uploadStudyUpdateKeyParametersRoute,
} from '~/paths';

const OUTCROP_KPS_VALIDITY = gql`
  query OutcropKPsValidity {
    outcropList {
      id
      name
      keyParameters {
        id
        valid
      }
    }
  }
`;

const STUDY_KPS_VALIDITY = gql`
  query StudyKPsValidity {
    studyList {
      id
      name
      keyParameters {
        id
        valid
      }
    }
  }
`;

const AE_KPS_VALIDITY = gql`
  query AEKPsValidity {
    studyList {
      id
      name
      architecturalElements {
        id
        name
        validKeyParameters
      }
    }
  }
`;

const LITHOSTRAT_KPS_VALIDITY = gql`
  query LithostratKPsValidity {
    lithostratFormationList {
      id
      name
      wikiPage {
        id
        keyParameters {
          id
          valid
        }
      }
    }

    lithostratGroupList {
      id
      name
      wikiPage {
        id
        keyParameters {
          id
          valid
        }
      }
    }

    lithostratMemberList {
      id
      name
      wikiPage {
        id
        keyParameters {
          id
          valid
        }
      }
    }
  }
`;

type SpinnerIconProps = { show: boolean };
const SpinnerIcon: React.FC<SpinnerIconProps> = ({ show }) => {
  if (!show) return null;
  return (
    <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: '10px' }} />
  );
};

type CollapsibleSectionProps = {
  isCollapsed: boolean;
  toggleCollapse: () => void;
  title: React.ReactNode;
  invalidCount: number;
  children: React.ReactNode;
};
const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  isCollapsed,
  toggleCollapse,
  title,
  invalidCount,
  children,
}) => {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title onClick={toggleCollapse} className="cursor-pointer">
          <div className="float-right">
            {invalidCount > 0 ? (
              <Badge color="error">{invalidCount} invalid</Badge>
            ) : (
              <Badge color="success">
                <FontAwesomeIcon icon={faCheckCircle} /> All valid
              </Badge>
            )}
          </div>
          {title} <ExpandedIcon expanded={!isCollapsed} />
        </Panel.Title>
      </Panel.Heading>
      {!isCollapsed && <Panel.Body>{children}</Panel.Body>}
    </Panel>
  );
};

type ExpandableSectionState = {
  outcrops: boolean;
  studies: boolean;
  aes: boolean;
  formations: boolean;
  groups: boolean;
  members: boolean;
};
export default function InvalidKeyParametersPage() {
  useBreadcrumb(
    'routes/upload/invalid-key-parameters',
    'Invalid Key Parameters',
  );

  const [expandedSections, setExpandedSections] =
    useState<ExpandableSectionState>({
      outcrops: false,
      studies: false,
      aes: false,
      formations: false,
      groups: false,
      members: false,
    });

  const toggleExpandedSection =
    (sectionName: keyof ExpandableSectionState) => () => {
      setExpandedSections(prev => ({
        ...prev,
        [sectionName]: !prev[sectionName],
      }));
    };

  const { data: dataOutcrops, loading: loadingOutcrops } =
    useQuery<OutcropKPsValidityQuery>(OUTCROP_KPS_VALIDITY, {});
  const { data: dataStudies, loading: loadingStudies } =
    useQuery<StudyKPsValidityQuery>(STUDY_KPS_VALIDITY, {});
  const { data: dataAEs, loading: loadingAEs } = useQuery<AekPsValidityQuery>(
    AE_KPS_VALIDITY,
    {},
  );
  const { data: dataLithostrat, loading: loadingLithostrat } =
    useQuery<LithostratKPsValidityQuery>(LITHOSTRAT_KPS_VALIDITY, {});

  // Outcrops
  const ocInvalidKPCount = (
    oc: OutcropKPsValidityQuery['outcropList'][number],
  ) => oc.keyParameters.filter(kp => !kp.valid).length;
  const outcrops = R.pipe(
    () => dataOutcrops?.outcropList ?? [],
    R.sortBy(R.prop('name')),
    outcrops => outcrops.filter(oc => ocInvalidKPCount(oc) > 0),
  )();
  const invalidOCKPCount = outcrops.reduce((acc, cur) => {
    return acc + ocInvalidKPCount(cur);
  }, 0);

  // Studies/AEs
  const studies = R.pipe(
    () => dataStudies?.studyList ?? [],
    R.sortBy(R.prop('name')),
  )();
  const studyInvalidKPCount = (
    study: StudyKPsValidityQuery['studyList'][number],
  ) => study.keyParameters.filter(kp => !kp.valid).length;
  const invalidStudies = studies.filter(s => studyInvalidKPCount(s) > 0);
  const invalidStudyKPCount = invalidStudies.reduce((acc, cur) => {
    return acc + studyInvalidKPCount(cur);
  }, 0);

  const getInvalidAEs = (study: AekPsValidityQuery['studyList'][number]) => {
    const aes = study.architecturalElements.filter(a => !a.validKeyParameters);
    return R.sortBy(R.prop('name'), aes);
  };
  const studiesWithAEs = dataAEs?.studyList ?? [];
  const studiesWithInvalidAEs = studiesWithAEs.filter(
    s => getInvalidAEs(s).length > 0,
  );
  const invalidAECount = studiesWithInvalidAEs.reduce((acc, cur) => {
    return acc + getInvalidAEs(cur).length;
  }, 0);

  // Formations
  const formations = R.pipe(
    () => dataLithostrat?.lithostratFormationList ?? [],
    R.sortBy(R.prop('name')),
  )();
  const fmInvalidKPCount = (
    fm: LithostratKPsValidityQuery['lithostratFormationList'][number],
  ) => {
    const fmKPs = fm.wikiPage?.keyParameters ?? [];
    return fmKPs.filter(kp => !kp.valid).length;
  };
  const invalidFormations = formations.filter(fm => fmInvalidKPCount(fm) > 0);
  const invalidFMKPCount = invalidFormations.reduce((acc, cur) => {
    return acc + fmInvalidKPCount(cur);
  }, 0);

  // Groups
  const groups = R.pipe(
    () => dataLithostrat?.lithostratGroupList ?? [],
    R.sortBy(R.prop('name')),
  )();
  const groupInvalidKPCount = (
    group: LithostratKPsValidityQuery['lithostratGroupList'][number],
  ) => {
    const groupKPs = group.wikiPage?.keyParameters ?? [];
    return groupKPs.filter(kp => !kp.valid).length;
  };
  const invalidGroups = groups.filter(g => groupInvalidKPCount(g) > 0);
  const invalidGroupKPCount = invalidGroups.reduce((acc, cur) => {
    return acc + groupInvalidKPCount(cur);
  }, 0);

  // Members
  const members = R.pipe(
    () => dataLithostrat?.lithostratMemberList ?? [],
    R.sortBy(R.prop('name')),
  )();
  const mbInvalidKPCount = (
    mb: LithostratKPsValidityQuery['lithostratMemberList'][number],
  ) => {
    const mbKPs = mb.wikiPage?.keyParameters ?? [];
    return mbKPs.filter(kp => !kp.valid).length;
  };
  const invalidMembers = members.filter(mb => mbInvalidKPCount(mb) > 0);
  const invalidMBKPCount = invalidMembers.reduce((acc, cur) => {
    return acc + mbInvalidKPCount(cur);
  }, 0);

  return (
    <>
      <PageHeading>Invalid Key Parameters</PageHeading>
      <p className="my-2">
        Listing of key parameters across Safari that are not valid according to
        the current depositional hierarchy standard.
      </p>

      <div className="space-y-6">
        <CollapsibleSection
          isCollapsed={!expandedSections.outcrops}
          toggleCollapse={toggleExpandedSection('outcrops')}
          title={
            <>
              Outcrops <SpinnerIcon show={loadingOutcrops} />
            </>
          }
          invalidCount={invalidOCKPCount}
        >
          <ListGroup>
            {outcrops.map(oc => (
              <ListGroup.Item
                as={Link}
                key={oc.id}
                to={uploadOutcropUpdateKeyParametersRoute(oc.id)}
                className="block border border-slate-200 p-2 gap-1"
              >
                {oc.name}
                <Badge color="error" className="gap-1 ml-1">
                  <b>{ocInvalidKPCount(oc)}</b> invalid
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </CollapsibleSection>

        <CollapsibleSection
          isCollapsed={!expandedSections.studies}
          toggleCollapse={toggleExpandedSection('studies')}
          title={
            <>
              Studies <SpinnerIcon show={loadingStudies} />
            </>
          }
          invalidCount={invalidStudyKPCount}
        >
          <ListGroup>
            {invalidStudies.map(s => (
              <ListGroup.Item
                key={s.id}
                as={Link}
                to={uploadStudyUpdateKeyParametersRoute(s.id)}
              >
                {s.name}
                <Badge color="error" className="ml-1 gap-1">
                  <b>{studyInvalidKPCount(s)}</b> invalid
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </CollapsibleSection>

        <CollapsibleSection
          isCollapsed={!expandedSections.aes}
          toggleCollapse={toggleExpandedSection('aes')}
          title={
            <>
              Architectural Elements <SpinnerIcon show={loadingAEs} />
            </>
          }
          invalidCount={invalidAECount}
        >
          <ListGroup>
            {studiesWithInvalidAEs.map(s => (
              <ListGroup.Item key={s.id}>
                {s.name}
                <Badge color="error" className="ml-1 gap-1">
                  {getInvalidAEs(s).length} invalid
                </Badge>
                <ul>
                  {getInvalidAEs(s).map(ae => (
                    <li key={ae.id}>
                      <Link to={uploadStudyUpdateAEDetailRoute(s.id, ae.id)}>
                        {ae.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </CollapsibleSection>

        <CollapsibleSection
          isCollapsed={!expandedSections.formations}
          toggleCollapse={toggleExpandedSection('formations')}
          title={
            <>
              Lithostrat Formations <SpinnerIcon show={loadingLithostrat} />
            </>
          }
          invalidCount={invalidFMKPCount}
        >
          <ListGroup>
            {invalidFormations.map(fm => (
              <ListGroup.Item
                key={fm.id}
                as={Link}
                to={uploadLithostratFormationUpdateWikiRoute(fm.id)}
              >
                {fm.name}
                <Badge color="error" className="ml-1 gap-1">
                  {fmInvalidKPCount(fm)} invalid
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </CollapsibleSection>

        <CollapsibleSection
          isCollapsed={!expandedSections.groups}
          toggleCollapse={toggleExpandedSection('groups')}
          title={
            <>
              Lithostrat Groups <SpinnerIcon show={loadingLithostrat} />
            </>
          }
          invalidCount={invalidGroupKPCount}
        >
          <ListGroup>
            {invalidGroups.map(g => (
              <ListGroup.Item
                key={g.id}
                as={Link}
                to={uploadLithostratGroupUpdateWikiRoute(g.id)}
              >
                {g.name}
                <Badge color="error" className="ml-1 gap-1">
                  {groupInvalidKPCount(g)} invalid
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </CollapsibleSection>

        <CollapsibleSection
          isCollapsed={!expandedSections.members}
          toggleCollapse={toggleExpandedSection('members')}
          title={
            <>
              Lithostrat Members <SpinnerIcon show={loadingLithostrat} />
            </>
          }
          invalidCount={invalidMBKPCount}
        >
          <ListGroup>
            {invalidMembers.map(mb => (
              <ListGroup.Item
                key={mb.id}
                as={Link}
                to={uploadLithostratMemberUpdateWikiRoute(mb.id)}
              >
                {mb.name}
                <Badge color="error" className="ml-1 gap-1">
                  {mbInvalidKPCount(mb)} invalid
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </CollapsibleSection>
      </div>
    </>
  );
}
