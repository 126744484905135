import { useQuery , gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import * as fragments from '~/apollo/fragments';
import type {
  OutcropEnumerationsQuery,
  UploadOutcropOverviewTabQuery,
  UploadOutcropOverviewTabQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import UpdateOutcropForm from '~/components/upload/outcrop/UpdateOutcropForm';
import { OUTCROP_ENUMS_V3 } from '~/apollo/operations/outcrop';

export const OVERVIEW_TAB = gql`
  query UploadOutcropOverviewTab($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      qualityParameters {
        ...outcropQualityParametersParts
      }
      basin {
        ...basinParts
      }
      sourceArea {
        ...sourceAreaParts
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.outcropQualityParametersParts}
  ${fragments.basinParts}
  ${fragments.sourceAreaParts}
`;

export default function UploadOutcropUpdateOverviewPage() {
  const params = useParams<{ outcropId: string }>();
  invariant(params.outcropId, 'outcropId param required');
  const outcropId = parseInt(params.outcropId);

  const { data, loading } = useQuery<
    UploadOutcropOverviewTabQuery,
    UploadOutcropOverviewTabQueryVariables
  >(OVERVIEW_TAB, {
    variables: { id: outcropId },
  });
  const { data: outcropEnums, loading: loadingEnums } =
    useQuery<OutcropEnumerationsQuery>(OUTCROP_ENUMS_V3, {});

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);

  const isLoading = loading || loadingEnums;

  if (isLoading || loadingEnums) return <SpinnerPlaceholder />;
  if (!outcrop || !outcropEnums) return <NotFound />;

  return <UpdateOutcropForm outcrop={outcrop} outcropEnums={outcropEnums} />;
}
