import {
  faFilter,
  faFilterCircleXmark,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router-dom';
import { Heading } from '~/components/common/Heading';
import { StickyBar } from '~/components/common/StickyBar';
import { Tooltip } from '~/components/common/Tooltip';
import { useDataSearchContext } from '~/components/dataSearch/dataSearchContext';
import { dataSearchRoute } from '~/paths';
import {
  dataSearchInitialValues,
  type DataSearchFormValues,
} from '~/utils/modules/dataSearch';
import { ucwords } from '~/utils/text';

function fieldLabel(field: keyof DataSearchFormValues) {
  switch (field) {
    case 'outcropCategory':
      return 'Analogue Type';
    default:
      return ucwords(field);
  }
}

export function SelectedFilters() {
  const navigate = useNavigate();
  const { values, setFieldValue, resetForm } =
    useFormikContext<DataSearchFormValues>();
  // const { loadingOptions, loadingMeasurements } = useDataSearchContext();

  const { clearMeasurements } = useDataSearchContext();

  // These fields will have their currently selected filters displayed here
  const fields: Array<keyof DataSearchFormValues> = [
    'geologyType',
    'outcropCategory', // "Analogue Type"
    'grossDepositionalEnvironment',
    'depositionalEnvironment',
    'depositionalSubEnvironment',
    'architecturalElement',
    'basinType',
    'climate',
    'netToGross',
    'distanceToSourceAreaDesc',
    'startAge',
    'country',
  ];
  type SelectedFilter = {
    fieldName: keyof DataSearchFormValues;
    value: string;
  };
  const selectedFilters = fields.reduce<SelectedFilter[]>((acc, cur) => {
    const selectedFilterValues = (values[cur] ?? []) as string[];
    return acc.concat(
      selectedFilterValues.map(value => ({ fieldName: cur, value })),
    );
  }, []);

  const handleRemove =
    (fieldName: keyof DataSearchFormValues, value: string) => () => {
      const curValues = values[fieldName] as string[];
      setFieldValue(fieldName, R.without([value], curValues));
    };

  const handleReset = () => {
    const text = 'Are you sure you would like to clear the selected options?';
    if (window.confirm(text)) {
      resetForm({ values: dataSearchInitialValues() });
      clearMeasurements();
    }

    // Push to the data search route in case a query string sds is set
    navigate(dataSearchRoute());
  };

  if (!selectedFilters.length) return null;

  return (
    <StickyBar
      stickTo="top"
      className="h-16 bg-slate-100 shadow-none z-50 py-2 px-4"
    >
      <div className="w-full h-auto flex justify-between items-center gap-6">
        <div className="">
          <Heading level={4} className="inline-block grow whitespace-nowrap">
            <FontAwesomeIcon icon={faFilter} className="mr-2" />
            Selected Filters
          </Heading>
        </div>

        <div className="grow flex flex-wrap justify-items-start items-center max-h-16 overflow-y-auto overflow-x-clip">
          {selectedFilters.map(filter => (
            <Tooltip
              key={`${filter.fieldName}--${filter.value}`}
              message={`Remove ${fieldLabel(filter.fieldName)} filter`}
              position="top"
            >
              <Button
                type="button"
                onClick={handleRemove(filter.fieldName, filter.value)}
                color="ghost"
                size="xs"
                className="block"
              >
                <FontAwesomeIcon icon={faMinusCircle} />
                &nbsp;
                <span>{filter.value}</span>
              </Button>
            </Tooltip>
          ))}
        </div>

        <div className="flex items-center gap-2 shrink-0">
          <Button
            type="button"
            onClick={handleReset}
            color="ghost"
            startIcon={<FontAwesomeIcon icon={faFilterCircleXmark} />}
          >
            Reset All Filters
          </Button>
        </div>
      </div>
    </StickyBar>
  );
}
