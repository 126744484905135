import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { OutcropTagField } from '~/components/upload/supportingObject/OutcropTagField';
import type { SupportObjectType } from '~/utils/modules/supportObject';
import { supportObjectFields } from '~/utils/modules/supportObject';

type Props = {
  studyId?: number | null;
  supportObjectType: SupportObjectType;
};

export function SupportObjectForm({ studyId, supportObjectType }: Props) {
  const fields = supportObjectFields(supportObjectType);

  return (
    <>
      {fields.map(({ name, label, helpText }) => (
        <Field
          key={name}
          name={name}
          label={label}
          component={FormikField}
          type={name === 'description' ? 'textarea' : 'text'}
          helpText={helpText}
          helpTextPlacement="left"
        />
      ))}

      {studyId && (
        <Field
          name="outcropTagId"
          label="Outcrop Tag"
          component={FormikField}
          type={OutcropTagField}
          studyId={studyId}
        />
      )}
    </>
  );
}
