import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  CreateLithostratStudyLinkMutation,
  CreateLithostratStudyLinkMutationVariables,
  LithostratLinkTargetType,
  LithostratStudyLinkInput,
} from '~/apollo/generated/schema';
import { CREATE_LITHOSTRAT_STUDY_LINK } from '~/apollo/operations/lithostrat';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import { yup } from '~/utils/validation';
import { StudyLinkFormFields } from './StudyLinkFormFields';

export type LithostratStudyLinkFormValues = {
  /** outcropId for filtering on studies */
  outcropId: string;
  parentId: string;
};

type Props = {
  children: (
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  ) => JSX.Element;
  targetType: LithostratLinkTargetType;
  targetId: number;
  refetchQueries: PureQueryOptions[];
};

/** Creates a LithostratStudyLink from an existing lithostrat entity */
export function CreateLithostratStudyLink({
  children,
  targetType,
  targetId,
  refetchQueries,
}: Props) {
  const { show, showModal, hideModal } = useModalState();
  const [createLink, { loading, error }] = useMutation<
    CreateLithostratStudyLinkMutation,
    CreateLithostratStudyLinkMutationVariables
  >(CREATE_LITHOSTRAT_STUDY_LINK, {
    refetchQueries,
  });

  async function handleSubmit(values: LithostratStudyLinkFormValues) {
    const lithostratStudyLink: LithostratStudyLinkInput = {
      targetType,
      targetId,
      parentId: parseInt(values.parentId),
    };
    try {
      await createLink({ variables: { lithostratStudyLink } });
      toast.success('Link created successfully.');
      hideModal();
    } catch (err) {
      console.log('Error creating link', err);
      toast.error('There was a problem creating the link.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            outcropId: '',
            parentId: '',
          }}
          validationSchema={yup.object({
            parentId: yup.number().integer().positive().required(),
          })}
        >
          <Form>
            <Modal.Body heading="Create Study Link" className="space-y-4">
              <StudyLinkFormFields />
              <FormErrors graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary"
                loading={loading}
              >
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
