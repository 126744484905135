import { useMutation } from '@apollo/client';
import {
  faEnvelope,
  faEnvelopeCircleCheck,
  faPowerOff,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import { toast } from 'react-toastify';
import type {
  SetUserAccountLockedMutation,
  SetUserAccountLockedMutationVariables,
  SetUserCompanyAdminMutation,
  SetUserCompanyAdminMutationVariables,
  SetUserEnabledMutation,
  SetUserEnabledMutationVariables,
  UserPartsFragment,
} from '~/apollo/generated/schema';
import {
  SET_USER_ACCOUNT_LOCKED,
  SET_USER_COMPANY_ADMIN,
  SET_USER_ENABLED,
} from '~/apollo/operations/user';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { LocalDate } from '~/components/common/LocalDate';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';

type Props = {
  user: UserPartsFragment;
};

function CompanyAdminUserListItem({ user }: Props): JSX.Element {
  const userId = user.id;

  const [toggleUserEnabled] = useMutation<
    SetUserEnabledMutation,
    SetUserEnabledMutationVariables
  >(SET_USER_ENABLED, {
    variables: {
      userId,
      enabled: !user.enabled,
    },
  });

  const [toggleUserAccountLocked] = useMutation<
    SetUserAccountLockedMutation,
    SetUserAccountLockedMutationVariables
  >(SET_USER_ACCOUNT_LOCKED, {
    variables: {
      userId,
      accountLocked: !user.accountLocked,
    },
  });

  const [toggleUserCompanyAdmin] = useMutation<
    SetUserCompanyAdminMutation,
    SetUserCompanyAdminMutationVariables
  >(SET_USER_COMPANY_ADMIN, {
    variables: {
      userId,
      isCompanyAdmin: !user.isCompanyAdmin,
    },
  });

  const clickHandler = (mutation: Function) => async () => {
    try {
      await mutation();
      toast.success('User updated successfully.');
    } catch (err) {
      console.log('Error updating user', err);
      toast.error('There was a problem updating the user.');
    }
  };

  const tdClassName = user.accountLocked ? 'text-muted' : '';

  return (
    <tr>
      <td className={tdClassName}>{user.name}</td>
      <td className={tdClassName}>{user.username}</td>
      <td className={cn('text-center', tdClassName)}>
        <LocalDate date={user.insertedAt} />
      </td>
      <td className={cn('flex justify-center items-center gap-2', tdClassName)}>
        <FontAwesomeIcon
          icon={user.enabled ? faEnvelopeCircleCheck : faEnvelope}
          className={user.enabled ? 'text-success' : 'text-error'}
        />
        {user.enabled ? 'Verified' : 'No'}
      </td>
      <td className={cn('text-center', tdClassName)}>
        <EnabledIndicator
          value={user.isCompanyAdmin}
          trueText={
            <div className="flex justify-center items-center gap-2">
              <FontAwesomeIcon icon={faUserCircle} className="text-info" />
              Admin
            </div>
          }
          falseText=""
        />
      </td>
      <td className="text-right text-neutral">
        <DropdownMenu label="Actions" color="ghost" size="xs">
          <DropdownMenu.Items>
            <DropdownMenu.Item>
              <button
                type="button"
                onClick={clickHandler(toggleUserCompanyAdmin)}
                className="flex items-center gap-2"
              >
                <FontAwesomeIcon icon={faUserCircle} />
                {user.isCompanyAdmin ? 'Revoke' : 'Grant'} Company Admin
              </button>
            </DropdownMenu.Item>
            <DropdownMenu.Item>
              <button
                type="button"
                onClick={clickHandler(toggleUserEnabled)}
                className="flex items-center gap-2"
              >
                <FontAwesomeIcon icon={faEnvelope} />
                <div>{user.enabled ? 'Unverify email' : 'Verify email'}</div>
              </button>
            </DropdownMenu.Item>
            <DropdownMenu.Item>
              <button
                type="button"
                onClick={clickHandler(toggleUserAccountLocked)}
                className="flex items-center gap-2"
              >
                <FontAwesomeIcon icon={faPowerOff} />
                {user.accountLocked ? 'Un-delete User' : 'Delete User'}
              </button>
            </DropdownMenu.Item>
          </DropdownMenu.Items>
        </DropdownMenu>
      </td>
    </tr>
  );
}

export default CompanyAdminUserListItem;
