import { Form, Formik } from 'formik';
import { SearchOutcrops } from '~/components/outcrop/SearchOutcrops';
import type {
  AnalogueSearchDefaults,
  AnalogueSearchFormValues,
} from '~/utils/modules/analogueSearch';
import { defaultAnalogueSearch } from '~/utils/modules/analogueSearch';

type Props = {
  initialQuery?: AnalogueSearchDefaults;
  onSearchChange?: (query: AnalogueSearchFormValues) => void;
};

export function AnalogueSearchForm({ initialQuery, onSearchChange }: Props) {
  return (
    <Formik
      onSubmit={() => {}}
      initialValues={defaultAnalogueSearch(initialQuery)}
    >
      <Form>
        <SearchOutcrops onSearchChange={onSearchChange} />
      </Form>
    </Formik>
  );
}
