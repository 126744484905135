import { gql, useQuery } from '@apollo/client';
import * as R from 'ramda';
import * as fragments from '~/apollo/fragments';
import type {
  OutcropOverviewTabQuery,
  OutcropOverviewTabQueryVariables,
} from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { HtmlSection } from '~/components/common/HtmlSection';
import { LastUpdated } from '~/components/common/LastUpdated';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { KeyParametersPanel } from '~/components/keyParameter/KeyParametersPanel';
import { OutcropDetailsPanel } from '~/components/outcrop/OutcropDetailsPanel';
import { OutcropMap } from '~/components/outcrop/OutcropMap';
import { OutcropPicturesCarousel } from '~/components/outcrop/OutcropPicturesCarousel';
import { OutcropStudyLinks } from '~/components/outcrop/OutcropStudyLinks';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';
import { fieldLabel } from '~/utils/modules/outcrop';

const OUTCROP_OVERVIEW_TAB = gql`
  query OutcropOverviewTab($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      pictures {
        ...pictureParts
        file {
          ...fileParts
          signedUrl
        }
      }
      basin {
        ...basinParts
      }
      keyParameters {
        ...keyParametersParts
      }
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
      studies {
        ...studyParts
        dataHistory {
          ...dataHistoryParts
        }
        qualityParameters {
          ...studyQualityParametersParts
        }
        crossSections {
          ...crossSectionParts
          georeference {
            ...georeferenceParts
          }
        }
        sedimentaryLogs {
          ...sedimentaryLogParts
          georeference {
            ...georeferenceParts
          }
        }
        gigaPans {
          ...gigaPanParts
          georeference {
            ...georeferenceParts
          }
        }
      }
      georeferences {
        ...georeferenceParts
      }
      lithostratOutcropLinks {
        ...lithostratOutcropLinkParts
      }
      # Load supporting objects to show georeferences in map
      crossSections {
        ...crossSectionParts
        georeference {
          ...georeferenceParts
        }
      }
      sedimentaryLogs {
        ...sedimentaryLogParts
        georeference {
          ...georeferenceParts
        }
      }
      gigaPans {
        ...gigaPanParts
        georeference {
          ...georeferenceParts
        }
      }
      virtualOutcropModels {
        ...vomParts
        georeference {
          ...georeferenceParts
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.basinParts}
  ${fragments.keyParametersParts}
  ${fragments.studyParts}
  ${fragments.dataHistoryParts}
  ${fragments.lithostratAgeParts}
  ${fragments.lithostratOutcropLinkParts}
  ${fragments.crossSectionParts}
  ${fragments.sedimentaryLogParts}
  ${fragments.gigaPanParts}
  ${fragments.georeferenceParts}
  ${fragments.vomParts}
  ${fragments.studyQualityParametersParts}
`;

export default function OutcropDetailIndexRoute() {
  const ctx = useOutcropOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    OutcropOverviewTabQuery,
    OutcropOverviewTabQueryVariables
  >(OUTCROP_OVERVIEW_TAB, { variables: { id: outcropId } });

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  const pictures = R.sortBy(
    R.propOr(Infinity, 'priority'),
    outcrop.pictures ?? [],
  );

  const studies = R.sortBy(R.prop('name'), outcrop.studies ?? []);

  const lithostratLinks = R.sortWith(
    [
      R.ascend(link => link.targetType ?? Infinity),
      R.ascend(link => link.targetName ?? Infinity),
    ],
    outcrop.lithostratOutcropLinks,
  );

  const hasPictures = pictures.length > 0;
  const hasStudies = studies.length > 0;

  return (
    <>
      <div className="grid lg:grid-cols-12 gap-6">
        <div className="lg:col-span-7 space-y-4">
          <HtmlSection
            title={fieldLabel('introduction', outcrop.outcropCategory)}
            content={outcrop.introduction}
          />
          <HtmlSection
            title={fieldLabel('environments', outcrop.outcropCategory)}
            content={outcrop.environments}
          />
          <HtmlSection
            title={fieldLabel('stratigraphy', outcrop.outcropCategory)}
            content={outcrop.stratigraphy}
          />
          <HtmlSection
            title={fieldLabel(
              'diagenesisAndPetrophysicalProperties',
              outcrop.outcropCategory,
            )}
            content={outcrop.diagenesisAndPetrophysicalProperties}
          />
          <HtmlSection
            title={fieldLabel('structuralGeology', outcrop.outcropCategory)}
            content={outcrop.structuralGeology}
          />
          {/* Production has these two fields reversed from other outcrops: */}
          {outcrop.outcropCategory === 'production' && (
            <>
              {/* Reservoir Engineering Aspects */}
              <HtmlSection
                title={fieldLabel('location', outcrop.outcropCategory)}
                content={outcrop.location}
              />
              {/* Production Aspects */}
              <HtmlSection
                title={fieldLabel(
                  'notablesAndAnalogues',
                  outcrop.outcropCategory,
                )}
                content={outcrop.notablesAndAnalogues}
              />
            </>
          )}

          {outcrop.outcropCategory !== 'production' && (
            <>
              <HtmlSection
                title={fieldLabel(
                  'notablesAndAnalogues',
                  outcrop.outcropCategory,
                )}
                content={outcrop.notablesAndAnalogues}
              />
              <HtmlSection
                title={fieldLabel('location', outcrop.outcropCategory)}
                content={outcrop.location}
              />
            </>
          )}

          <HtmlSection
            title={fieldLabel('safari', outcrop.outcropCategory)}
            content={outcrop.safari} // Key References and Links
          />

          <OutcropMap outcrop={outcrop} />
        </div>

        <div className="lg:col-span-5 space-y-4">
          {hasPictures && (
            <div>
              <OutcropPicturesCarousel pictures={pictures} />
            </div>
          )}

          <div>
            <KeyParametersPanel
              keyParameters={outcrop.keyParameters ?? []}
              dominantGeologyType={outcrop.dominantGeologyType}
              outcrop={outcrop}
              leftColMd={4}
              rightColMd={8}
            >
              {(kps, tabs) => (
                <div className="mt-8">
                  <div className="relative bottom-0">
                    <Heading level={3}>Key Parameters</Heading>
                    <div className="absolute bottom-[-2px] right-0">{tabs}</div>
                  </div>

                  <Panel>
                    <Panel.Body>{kps}</Panel.Body>
                  </Panel>
                </div>
              )}
            </KeyParametersPanel>
          </div>

          {outcrop.basin && (
            <Panel>
              <Panel.Body>
                <OutcropDetailsPanel
                  outcropId={outcrop.id}
                  basinName={outcrop.basin.name}
                  basinType={outcrop.basin.basinType}
                  climate={outcrop.basin.climate}
                  netToGross={outcrop.netToGross}
                  startAge={outcrop.startAge}
                  endAge={outcrop.endAge}
                  lithostratLinks={lithostratLinks}
                  leftColMd={4}
                  rightColMd={8}
                />
              </Panel.Body>
            </Panel>
          )}

          {outcrop.keyWords && (
            <>
              <Heading level={3}>
                {fieldLabel('keyWords', outcrop.outcropCategory)}
              </Heading>
              <Panel>
                <Panel.Body>
                  {outcrop.keyWords.split(',').join(', ')}
                </Panel.Body>
              </Panel>
            </>
          )}

          {hasStudies && (
            <div>
              <Heading level={3}>Studies</Heading>
              <OutcropStudyLinks studies={studies} />
            </div>
          )}
        </div>
      </div>

      <div className="flex gap-2">
        {outcrop.author && (
          <p className="text-sm text-muted my-2">
            <i>Author: {outcrop.author}</i>
          </p>
        )}

        <LastUpdated date={outcrop.updatedAt} />
      </div>
    </>
  );
}
