import { gql, useQuery } from '@apollo/client';
import { Field } from 'formik';
import React from 'react';
import type { SourceAreaEnumsQuery } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import { FormLabel } from '~/components/common/FormikField/FormLabel';
import { InputGroup } from '~/components/common/InputGroup';

const SOURCE_AREA_ENUMS = gql`
  query SourceAreaEnums {
    climate: sourceAreaEnumerations(type: CLIMATE) {
      values
    }
    geography: sourceAreaEnumerations(type: GEOGRAPHY) {
      values
    }
    structure: sourceAreaEnumerations(type: STRUCTURE) {
      values
    }
    distance: sourceAreaEnumerations(type: DISTANCE) {
      values
    }
  }
`;

type Props = {
  disabled?: boolean;
};

export function SourceAreaFormFields({ disabled = false }: Props) {
  const { data, loading } = useQuery<SourceAreaEnumsQuery>(SOURCE_AREA_ENUMS);

  function enumOptions(field: keyof Omit<SourceAreaEnumsQuery, '__typename'>) {
    const values = data?.[field]?.values ?? [];
    return values.map((val: string) => ({ value: val, label: val }));
  }

  const isDisabled = disabled || loading;

  return (
    <>
      <Field name="sourceArea.name" label="Name" component={FormikField} />

      <Field
        name="sourceArea.description"
        label="Description"
        component={FormikField}
        disabled={isDisabled}
        type="textarea"
      />

      <Field
        name="sourceArea.geography"
        label="Geography"
        component={FormikField}
        type="select"
        options={enumOptions('geography')}
        disabled={isDisabled}
      />

      <Field
        name="sourceArea.structure"
        label="Structure"
        component={FormikField}
        type="select"
        options={enumOptions('structure')}
        disabled={isDisabled}
      />

      <Field
        name="sourceArea.climate"
        label="Source Area Climate"
        component={FormikField}
        type="select"
        options={enumOptions('climate')}
        disabled={isDisabled}
      />

      <div className="form-group">
        <FormLabel
          name="sourceArea.distanceToSourceAreaDesc"
          label="Estimated Distance to Source Area"
        />
        <div className="grid grid-cols-2 gap-2">
          <Field
            name="sourceArea.distanceToSourceAreaDesc"
            component={FormikField}
            type="select"
            disabled={isDisabled}
            options={enumOptions('distance')}
          />
          <Field
            name="sourceArea.distanceToSourceArea"
            component={FormikField}
            type="number"
            step="1"
            disabled={isDisabled}
            className="w-full join-item"
            renderInput={(input: React.ReactNode) => (
              <InputGroup>
                {input}
                <InputGroup.Addon>km</InputGroup.Addon>
              </InputGroup>
            )}
          />
        </div>
      </div>

      <FormLabel
        name="sourceArea.distanceToShorelineDesc"
        label="Estimated Distance to Shoreline"
      />
      <div className="grid grid-cols-2 gap-2">
        <Field
          name="sourceArea.distanceToShorelineDesc"
          component={FormikField}
          type="select"
          disabled={isDisabled}
          options={enumOptions('distance')}
        />
        <Field
          name="sourceArea.distanceToShoreline"
          component={FormikField}
          type="number"
          step="1"
          disabled={isDisabled}
          className="w-full join-item"
          renderInput={(input: React.ReactNode) => (
            <InputGroup>
              {input}
              <InputGroup.Addon>km</InputGroup.Addon>
            </InputGroup>
          )}
        />
      </div>

      <FormLabel
        name="sourceArea.distanceToShelEdgeDesc"
        label="Estimated Distance to Shelf Edge"
      />
      <div className="grid grid-cols-2 gap-2">
        <Field
          name="sourceArea.distanceToShelfEdgeDesc"
          component={FormikField}
          type="select"
          disabled={isDisabled}
          options={enumOptions('distance')}
        />
        <Field
          name="sourceArea.distanceToShelfEdge"
          component={FormikField}
          type="number"
          step="1"
          disabled={isDisabled}
          className="w-full join-item"
          renderInput={(input: React.ReactNode) => (
            <InputGroup>
              {input}
              <InputGroup.Addon>km</InputGroup.Addon>
            </InputGroup>
          )}
        />
      </div>

      <Field
        name="sourceArea.comments"
        label="Comments"
        component={FormikField}
        type="textarea"
        disabled={isDisabled}
      />
    </>
  );
}
