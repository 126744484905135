/** Copies the given text to the user's clipboard by creating a temporary input element out of view */
export function copyToClipboard(textToCopy: string): void {
  const inp = document.createElement('input');

  inp.style.cssText = 'position: absolute; left: -1000px; top: -1000px';
  inp.value = textToCopy;
  document.body.appendChild(inp);
  inp.select();

  // Deprecated: https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
  document.execCommand('copy');
  document.body.removeChild(inp);
}
