import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { Heading } from '~/components/common/Heading';

const climateOptions = [
  'Arid',
  'Equatorial',
  'Warm Temperate',
  'Polar',
  'Snow',
];

type Props = {
  disabled: boolean;
};

export function ModernFiltersClimate({ disabled }: Props) {
  return (
    <>
      <Heading level={4}>Climate</Heading>

      {climateOptions.map(value => (
        <Field
          key={value}
          name="climate"
          component={FormikField}
          type="checkbox"
          value={value}
          label={value}
          disabled={disabled}
          size="sm"
        />
      ))}
    </>
  );
}
