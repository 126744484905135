import { Transition } from '@headlessui/react';

type Props = {
  show: boolean;
  children: React.ReactNode;
};
export function ScaleTransition({ show, children }: Props) {
  return (
    <Transition
      show={show}
      enter="transition duration-200 ease-out"
      enterFrom="transform scale-50 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      {children}
    </Transition>
  );
}
