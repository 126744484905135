import type { PicturePartsFragment } from '~/apollo/generated/schema';

type Props = {
  picture: PicturePartsFragment;
};

export function VomPictureDetails({ picture }: Props) {
  return (
    <table className="table table-compact w-full">
      <tbody>
        <tr>
          <th>Name:</th>
          <td>{picture.name}</td>
        </tr>
        <tr>
          <th>Description:</th>
          <td>{picture.description}</td>
        </tr>
        {picture.type && picture.type !== 'undefined' && (
          <tr>
            <th>Type:</th>
            <td>{picture.type}</td>
          </tr>
        )}
        {picture.author && (
          <tr>
            <th>Author:</th>
            <td>{picture.author}</td>
          </tr>
        )}
        {picture.comments && (
          <tr>
            <th>Comments:</th>
            <td>{picture.comments}</td>
          </tr>
        )}
        {picture.scale && (
          <tr>
            <th>Scale:</th>
            <td>{picture.scale}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
