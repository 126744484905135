import { cn } from '~/utils/common';
import type { HTMLProps } from 'react';

function FieldSetComponent({
  className,
  ...fieldSetProps
}: HTMLProps<HTMLFieldSetElement>) {
  return (
    <fieldset
      className={cn('border border-slate-200 p-2', className)}
      {...fieldSetProps}
    />
  );
}

function Legend({ className, ...legendProps }: HTMLProps<HTMLLegendElement>) {
  return (
    <legend className={cn('px-2 font-bold', className)} {...legendProps} />
  );
}

FieldSetComponent.Legend = Legend;

export const FieldSet = FieldSetComponent;
