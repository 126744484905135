import { gql, useMutation } from '@apollo/client';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import type {
  DeleteOutcropSubregionMutation,
  DeleteOutcropSubregionMutationVariables,
} from '~/apollo/generated/schema';
import { uploadOutcropSubregionsRoute } from '~/paths';

const DELETE_OUTCROP_SUBREGION = gql`
  mutation DeleteOutcropSubregion($id: Int!) {
    deleteOutcropSubregion(id: $id)
  }
`;

export function DeleteOutcropSubregion({
  children,
  id,
}: {
  children: (deleteModel: () => Promise<void>, loading: boolean) => ReactNode;
  id: number;
}) {
  const navigate = useNavigate();

  const [deleteModel, { loading }] = useMutation<
    DeleteOutcropSubregionMutation,
    DeleteOutcropSubregionMutationVariables
  >(DELETE_OUTCROP_SUBREGION, {
    variables: { id },
  });

  async function handleDelete() {
    const confirmMsg =
      'Are you sure you want to delete this model? This action cannot be undone!';
    if (!window.confirm(confirmMsg)) return;

    try {
      await deleteModel();
      toast.success('Outcrop 3D Model deleted successfully.');
      navigate(uploadOutcropSubregionsRoute());
    } catch (err) {
      console.log('Error deleting Outcrop 3D Model', err);
      toast.error(
        'There was a problem deleting the model. Please reload the page and try again.',
      );
    }
  }

  return children(handleDelete, loading);
}
