import { GoogleMap } from '@react-google-maps/api';
import type { GeoreferencePartsFragment } from '~/apollo/generated/schema';
import {
  createBounds,
  createMarker,
  createPolygon,
  createPolyline,
  isMarkerType,
  isPolygonType,
  isPolylineType,
} from '~/utils/georeference';

type Props = {
  georeferences: GeoreferencePartsFragment[];
};

export function VomGeoreferencesMap({ georeferences }: Props) {
  const points = georeferences.filter(g => isMarkerType(g.dataType));
  const polygons = georeferences.filter(g => isPolygonType(g.dataType));
  const polylines = georeferences.filter(g => isPolylineType(g.dataType));

  const handleLoad = (map: google.maps.Map) => {
    const bounds = createBounds(georeferences);
    map.fitBounds(bounds);
  };

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '300px' }}
      onLoad={handleLoad}
      mapTypeId={google.maps.MapTypeId.TERRAIN}
    >
      {points.map(georef => createMarker(georef, { key: georef.id }))}
      {polygons.map(georef => createPolygon(georef, { key: georef.id }))}
      {polylines.map(georef => createPolyline(georef, { key: georef.id }))}
    </GoogleMap>
  );
}
