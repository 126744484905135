import type { LithostratTypePartsFragment } from '~/apollo/generated/schema';
import { yup } from '~/utils/validation';

// TODO: Move to schema file
export interface LithostratTypeInput {
  name: string;
  description?: string | null;
}

export type LithostratTypeFormValues = {
  name: string;
  description?: string | null;
};

export const initialLithostratType = (
  lt?: LithostratTypePartsFragment,
): LithostratTypeFormValues => ({
  name: lt?.name ?? '',
  description: lt?.description || null,
});

export const lithostratTypeValidationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().nullable(),
});

export const formValuesToLithostratTypeInput = (
  fv: LithostratTypeFormValues,
): LithostratTypeInput => ({
  name: fv.name,
  description: fv.description?.trim() || null,
});
