import { yup } from '~/utils/validation';

/**
 * o: outcrops
 * m: measurements
 * w: wiki pages
 */
export type UnifiedSearchCriteria = 'o' | 'm' | 'w';
export const unifiedSearchCriteriaOptions: UnifiedSearchCriteria[] = [
  'o',
  'm',
  'w',
];

export type UnifiedSearchFormValues = {
  query: string;
  criteria: UnifiedSearchCriteria[];
};

export function initialUniSearchValues(
  query?: string,
  criteria?: string[],
): UnifiedSearchFormValues {
  return {
    query: query ?? '',
    criteria: (criteria?.filter(c =>
      (unifiedSearchCriteriaOptions as string[]).includes(c),
    ) as UnifiedSearchCriteria[]) ?? ['o', 'w'],
  };
}

export const unifiedSearchValidationSchema = yup.object().shape({
  query: yup.string(),
  criteria: yup
    .array(yup.mixed().oneOf(unifiedSearchCriteriaOptions))
    .min(1, 'Please select at least one type of criteria')
    .required(),
});

type SearchCriteria = {
  includeOutcrops: boolean;
  includeMeasurements: boolean;
  includeWikis: boolean;
};
export function queryStringToSearchCriteria(qsCriteria?: string) {
  const criteria = qsCriteria?.split(',') ?? ['o'];

  return {
    includeOutcrops: criteria.includes('o'),
    includeMeasurements: criteria.includes('m'),
    includeWikis: criteria.includes('w'),
  };
}
export function searchCriteriaObjectToQuery(
  criteria: SearchCriteria,
): UnifiedSearchCriteria[] {
  const criteriaArray: UnifiedSearchCriteria[] = [];

  if (criteria.includeOutcrops) criteriaArray.push('o');
  if (criteria.includeMeasurements) criteriaArray.push('m');
  if (criteria.includeWikis) criteriaArray.push('w');

  return criteriaArray;
}
