import { useMutation , gql } from '@apollo/client';
import { toast } from 'react-toastify';

import { interpretationParts } from '~/apollo/fragments';
import type {
  PublishInterpretationMutation,
  PublishInterpretationMutationVariables,
} from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';

const PUBLISH_INTERPRETATION = gql`
  mutation PublishInterpretation(
    $interpretationId: Int!
    $published: Boolean!
  ) {
    publishVirtualOutcropInterpretation(
      id: $interpretationId
      published: $published
    ) {
      ...interpretationParts
    }
  }

  ${interpretationParts}
`;

type Props = {
  interpretationId: number;
  isPublished: boolean;
  children: (confirmPublish: () => void, loading: boolean) => JSX.Element;
};

export function PublishVomInterpretation({
  interpretationId,
  isPublished,
  children,
}: Props) {
  const [publish, { loading }] = useMutation<
    PublishInterpretationMutation,
    PublishInterpretationMutationVariables
  >(PUBLISH_INTERPRETATION, {});

  async function handlePublish() {
    try {
      await publish({
        variables: {
          interpretationId,
          published: !isPublished,
        },
      });

      toast.success(
        `Interpretation ${isPublished ? 'un' : ''}published successfully`,
      );
    } catch (err) {
      console.log('Error changing published state', err);
      toast.error('There was an error, see console for more details.');
    }
  }

  return (
    <Confirm
      onConfirm={handlePublish}
      title={`${isPublished ? 'Unpublish' : 'Publish'} Interpretation`}
      text={
        isPublished ? (
          <>
            This interpretation will be <b>unpublished</b>.
          </>
        ) : (
          <>
            This interpretation will be <b>published</b>.
          </>
        )
      }
    >
      {handleConfirm => children(handleConfirm, loading)}
    </Confirm>
  );
}
