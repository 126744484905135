import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';
import * as R from 'ramda';

import * as fragments from '~/apollo/fragments';
import type {
  GroupUpdateExamplesRouteQuery,
  GroupUpdateExamplesRouteQueryVariables,
} from '~/apollo/generated/schema';
import { LithostratLinkTargetType } from '~/apollo/generated/schema';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { ExampleManager } from '~/components/upload/lithostrat/examples/ExampleManager';
import { useGroupUpdateOutletContext } from '~/routes/upload/lithostrat/group/$groupId';

const EXAMPLES_TAB = gql`
  query GroupUpdateExamplesRoute($id: Int!) {
    lithostratGroupList(id: $id) {
      ...lithostratGroupParts
      wikiPage {
        ...lithostratWikiPageParts
      }
      outcropLinkAnalogues {
        ...outcropParts
      }
      studyLinkAnalogues {
        ...outcropParts
        studies {
          ...studyParts
          lithostratStudyLinks {
            ...lithostratStudyLinkParts
          }
        }
      }
    }
  }

  ${fragments.lithostratGroupParts}
  ${fragments.lithostratWikiPageParts}
  ${fragments.outcropParts}
  ${fragments.studyParts}
  ${fragments.lithostratStudyLinkParts}
`;

export default function GroupUpdateExamplesRoute() {
  const ctx = useGroupUpdateOutletContext();
  const groupId = ctx.group.id;

  const { data, loading } = useQuery<
    GroupUpdateExamplesRouteQuery,
    GroupUpdateExamplesRouteQueryVariables
  >(EXAMPLES_TAB, {
    variables: { id: groupId },
  });

  const refetchQueries: [
    PureQueryOptions<GroupUpdateExamplesRouteQueryVariables>,
  ] = [
    {
      query: EXAMPLES_TAB,
      variables: { id: groupId },
    },
  ];

  const groups = data?.lithostratGroupList ?? [];
  const group = groups.find(g => g.id === groupId);

  const outcropLinks = R.sortBy(
    R.prop('name'),
    group?.outcropLinkAnalogues ?? [],
  );
  const studyLinks = R.sortBy(R.prop('name'), group?.studyLinkAnalogues ?? []);

  if (loading) return <SpinnerPlaceholder />;

  if (!group?.wikiPage?.published) {
    return (
      <div className="alert alert-info">
        This group's wiki page needs to be published before examples may be
        added.
      </div>
    );
  }

  return (
    <ExampleManager
      targetType={LithostratLinkTargetType.Group}
      targetId={groupId}
      refetchQueries={refetchQueries}
      outcropLinks={outcropLinks}
      studyLinks={studyLinks}
    />
  );
}
