import * as R from 'ramda';
import { Link } from 'react-router-dom';
import type { LithostratFormationPageQuery } from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import { FullAgePopover } from '~/components/geologicalAge/FullAgePopover';
import { KeyParametersPanel } from '~/components/keyParameter/KeyParametersPanel';
import {
  KPCol,
  KPGrid,
} from '~/components/keyParameter/KeyParametersPanel/CombinedView';
import { OutcropDetailsPanel } from '~/components/outcrop/OutcropDetailsPanel';
import {
  lithostratFormationRoute,
  lithostratGroupRoute,
  lithostratMemberRoute,
} from '~/paths';

type FormationType =
  LithostratFormationPageQuery['lithostratFormationList'][number];
type GroupType = NonNullable<FormationType['group']>;
type MemberType = FormationType['members'][number];
type NeighborType =
  | NonNullable<FormationType['formationAbove']>
  | NonNullable<FormationType['formationBelow']>;

type BelongsToProps = {
  group: GroupType;
};

function BelongsTo({ group }: BelongsToProps) {
  if (group.wikiPage?.published) {
    return (
      <Link to={lithostratGroupRoute(group.id)} className="link">
        {group.name}
      </Link>
    );
  }
  return <span>{group.name}</span>;
}

type FormationNeighborProps = {
  neighbor: NeighborType;
};
function FormationNeighbor({ neighbor }: FormationNeighborProps) {
  if (neighbor.wikiPage?.published) {
    return (
      <Link to={lithostratFormationRoute(neighbor.id)} className="link">
        {neighbor.name}
      </Link>
    );
  }

  return <span>{neighbor.name}</span>;
}

type MemberListProps = {
  members: MemberType[];
};
function MemberList({ members }: MemberListProps) {
  return (
    <span>
      {members.map(member => {
        if (member.wikiPage?.published) {
          return (
            <div key={member.id}>
              <Link to={lithostratMemberRoute(member.id)} className="link">
                {member.name}
              </Link>
            </div>
          );
        }
        return <div key={member.id}>{member.name}</div>;
      })}
    </span>
  );
}

type Formation =
  LithostratFormationPageQuery['lithostratFormationList'][number];

type SidebarProps = {
  formation: Formation;
};
export function FormationSidebar({ formation }: SidebarProps) {
  const { wikiPage } = formation;
  if (!wikiPage) return null;

  const showHierarchyPanel =
    formation.group ||
    formation.formationAbove ||
    formation.formationBelow ||
    formation.members.length > 0;

  return (
    <div className="space-y-6">
      {showHierarchyPanel && <HierarchyPanel formation={formation} />}

      <div>
        <Heading level={4} className="mb-1">
          Key Parameters
        </Heading>
        <Panel>
          <Panel.Body>
            <KeyParametersPanel
              keyParameters={wikiPage.keyParameters ?? []}
              noHeading
            />
            {(formation.startAge || formation.endAge) && (
              <KPGrid>
                <KPCol span={4}>
                  <strong>Basin Age</strong>
                </KPCol>
                <KPCol span={8}>
                  <FullAgePopover
                    startAge={formation.startAge || null}
                    endAge={formation.endAge || null}
                  />
                </KPCol>
              </KPGrid>
            )}
          </Panel.Body>
        </Panel>

        <Panel className="mt-4">
          <Panel.Body>
            <OutcropDetailsPanel
              basinType={wikiPage.basinType}
              climate={wikiPage.climate}
            />
          </Panel.Body>
        </Panel>
      </div>

      {(wikiPage.country || wikiPage.region) && (
        <div>
          <Heading level={4} className="mb-1">
            Location
          </Heading>
          <Panel>
            <Panel.Body>
              {wikiPage.country && (
                <KPGrid>
                  <KPCol span={3}>
                    <strong>Country</strong>
                  </KPCol>
                  <KPCol span={9}>{wikiPage.country}</KPCol>
                </KPGrid>
              )}
              {wikiPage.region && (
                <KPGrid>
                  <KPCol span={3}>
                    <strong>Region</strong>
                  </KPCol>
                  <KPCol span={9}>{wikiPage.region}</KPCol>
                </KPGrid>
              )}
            </Panel.Body>
          </Panel>
        </div>
      )}
    </div>
  );
}

function HierarchyPanel({ formation }: { formation: Formation }) {
  const members = R.sortBy(
    mb => mb.lithostratOrder ?? Infinity,
    formation.members,
  );

  return (
    <div>
      <Heading level={4} className="mb-1">
        Lithostratigraphic Hierarchy
      </Heading>
      <Panel>
        <Panel.Body>
          {formation.group && (
            <KPGrid>
              <KPCol span={4}>
                <strong>Belongs To</strong>
              </KPCol>
              <KPCol span={8}>
                <BelongsTo group={formation.group} />
              </KPCol>
            </KPGrid>
          )}

          {formation.formationAbove && (
            <KPGrid>
              <KPCol span={4}>
                <strong>Formation Above</strong>
              </KPCol>
              <KPCol span={8}>
                <FormationNeighbor neighbor={formation.formationAbove} />
              </KPCol>
            </KPGrid>
          )}

          {formation.formationBelow && (
            <KPGrid>
              <KPCol span={4}>
                <strong>Formation Below</strong>
              </KPCol>
              <KPCol span={8}>
                <FormationNeighbor neighbor={formation.formationBelow} />
              </KPCol>
            </KPGrid>
          )}

          {members.length > 0 && (
            <KPGrid>
              <KPCol span={4}>
                <strong>Contains</strong>
              </KPCol>
              <KPCol span={8}>
                <MemberList members={members} />
              </KPCol>
            </KPGrid>
          )}
        </Panel.Body>
      </Panel>
    </div>
  );
}
