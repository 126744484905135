import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCog,
  faHeadset,
  faPersonThroughWindow,
  faUser,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { myProfileRoute, supportRoute } from '~/paths';
import { logoutUrl } from '~/utils/auth';

type UserMenuItemProps = {
  to: string;
  children: React.ReactNode;
  external?: boolean;
};
function UserMenuItem({ to, children, external = false }: UserMenuItemProps) {
  const baseClasses = 'block px-4 py-2 text-sm text-zinc-700';
  const activeClasses = `${baseClasses} bg-zinc-100`;

  return (
    <Menu.Item>
      {({ active }) => {
        if (external) {
          return (
            <a href={to} className={active ? activeClasses : baseClasses}>
              {children}
            </a>
          );
        }
        return (
          <Link to={to} className={active ? activeClasses : baseClasses}>
            {children}
          </Link>
        );
      }}
    </Menu.Item>
  );
}

type Props = {
  name: string;
};

export function UserMenu({ name }: Props) {
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button
          id="userMenu"
          className="px-3 py-3 rounded-md text-base text-zinc-300 hover:bg-zinc-500 hover:text-white"
        >
          <span className="sr-only">Open user menu</span>
          <span className="hidden lg:inline">
            <FontAwesomeIcon icon={faUser} className="mr-2" />
            {name}
          </span>
          <FontAwesomeIcon icon={faUserCircle} className="lg:hidden" />
          <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <UserMenuItem to={myProfileRoute()}>
            <FontAwesomeIcon icon={faCog} /> Profile
          </UserMenuItem>
          <UserMenuItem to={supportRoute()}>
            <FontAwesomeIcon icon={faHeadset} /> Support
          </UserMenuItem>
          <UserMenuItem to={logoutUrl()} external>
            <FontAwesomeIcon icon={faPersonThroughWindow} /> Log out
          </UserMenuItem>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
