import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { Link } from 'react-router-dom';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  CompanyAdminMachineTokensPageQuery,
  CompanyAdminMachineTokensPageQueryVariables,
} from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { DeleteMachineToken } from '~/components/company/DeleteMachineToken';
import { useAuth } from '~/contexts/auth';
import {
  companyAdminMachineTokensCreateRoute,
  companyAdminMachineTokenUpdateRoute,
} from '~/paths';

const COMPANY_ADMIN_MACHINE_TOKENS_PAGE = gql`
  query CompanyAdminMachineTokensPage($companyId: Int!) {
    companyMachineTokens(companyId: $companyId) {
      ...machineTokenParts
    }
  }

  ${fragments.machineTokenParts}
`;

export default function CompanyAdminMachineTokensPage() {
  const { authority } = useAuth();
  invariant(authority, 'Must be authenticated');
  const companyId = authority.user.companyId;

  const { data, loading } = useQuery<
    CompanyAdminMachineTokensPageQuery,
    CompanyAdminMachineTokensPageQueryVariables
  >(COMPANY_ADMIN_MACHINE_TOKENS_PAGE, { variables: { companyId } });

  const refetchQueries: [
    PureQueryOptions<CompanyAdminMachineTokensPageQueryVariables>,
  ] = [{ query: COMPANY_ADMIN_MACHINE_TOKENS_PAGE, variables: { companyId } }];

  const activeTokens =
    data?.companyMachineTokens.filter(t => t.deletedAt === null) ?? [];

  const deletedTokens =
    data?.companyMachineTokens.filter(t => !!t.deletedAt) ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!data) return <NotFound />;

  return (
    <div className="space-y-4">
      <Panel>
        <Panel.Heading className="flex justify-between gap-6">
          <Panel.Title>Active Machine Tokens</Panel.Title>
          <Link
            to={companyAdminMachineTokensCreateRoute()}
            className="btn btn-primary btn-sm gap-1"
          >
            <FontAwesomeIcon icon={faPlus} />
            Create New Token
          </Link>
        </Panel.Heading>

        <Panel.Body>
          <table className="table w-full">
            <thead>
              <tr>
                <th>Description</th>
                <th>Created</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {!activeTokens.length && (
                <tr>
                  <td colSpan={3}>
                    <div className="text-center text-gray-600">
                      <em>No tokens created yet.</em>
                    </div>
                  </td>
                </tr>
              )}

              {activeTokens.map(token => (
                <tr key={token.id} className="bordered">
                  <td>{token.description}</td>
                  <td>{new Date(token.insertedAt).toLocaleDateString()}</td>
                  <td className="text-right space-x-2">
                    <Link
                      to={companyAdminMachineTokenUpdateRoute(token.id)}
                      className="btn btn-ghost btn-xs"
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </Link>

                    <DeleteMachineToken
                      tokenId={token.id}
                      refetchQueries={refetchQueries}
                    >
                      {(deleteMachineToken, loadingDelete) => (
                        <Confirm
                          onConfirm={deleteMachineToken}
                          text="Are you sure you want to deactivate this token? It will no longer be able to be used for authentication. This action cannot be undone."
                        >
                          {confirmDelete => (
                            <Button
                              type="button"
                              color="ghost"
                              size="xs"
                              onClick={confirmDelete}
                              loading={loadingDelete}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          )}
                        </Confirm>
                      )}
                    </DeleteMachineToken>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>

      {deletedTokens.length > 0 && (
        <Panel>
          <Panel.Heading className="flex justify-between gap-6">
            <Panel.Title>Deleted Machine Tokens</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <table className="table w-full">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Created</th>
                  <th>Deleted</th>
                </tr>
              </thead>

              <tbody>
                {deletedTokens.map(token => (
                  <tr key={token.id} className="text-muted">
                    <td>{token.description}</td>
                    <td>{new Date(token.insertedAt).toLocaleDateString()}</td>
                    <td>{new Date(token.deletedAt).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Panel.Body>
        </Panel>
      )}
    </div>
  );
}
