import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import React from 'react';
import { Button } from 'react-daisyui';
import type { AnalogueSearchFormValues } from '~/utils/modules/analogueSearch';

type FilterButtonProps = {
  label: string;
  onClick: (e: React.MouseEvent) => void;
  isSelected: boolean;
  disabled?: boolean;
};

function FilterButton({
  label,
  onClick,
  isSelected,
  disabled = false,
}: FilterButtonProps) {
  return React.useMemo(
    () => (
      <Button
        type="button"
        color={isSelected ? 'primary' : 'ghost'}
        size="sm"
        fullWidth
        onClick={onClick}
        disabled={disabled}
        className="gap-1"
      >
        <FontAwesomeIcon icon={isSelected ? faSquareCheck : faSquare} /> {label}
      </Button>
    ),
    [isSelected, disabled, label, onClick],
  );
}

type Props = {
  loading: boolean;
};

export function ResultTypeField({ loading }: Props) {
  const { values, setFieldValue } =
    useFormikContext<AnalogueSearchFormValues>();

  function isSelected(name: string) {
    return values.outcropCategory.includes(name);
  }

  const handleClick = (value: string) => (e: React.MouseEvent) => {
    e.preventDefault();

    const curVal = values.outcropCategory;
    let nextVal: string[];

    if (isSelected(value)) {
      nextVal = R.without([value], curVal);
    } else {
      nextVal = R.append(value, curVal);
    }

    setFieldValue('outcropCategory', nextVal);
  };

  const buttons: Array<[value: string, label: string]> = [
    ['outcrop', 'Outcrop'],
    ['production', 'Production'],
    ['seismic', 'Seismic'],
    ['modern', 'Modern'],
  ];

  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-6">
      {buttons.map(([value, label]) => (
        <div key={value}>
          <FilterButton
            label={label}
            onClick={handleClick(value)}
            isSelected={isSelected(value)}
            disabled={loading}
          />
        </div>
      ))}
    </div>
  );
}
