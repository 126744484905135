import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { Link } from 'react-router-dom';

import * as fragments from '~/apollo/fragments';
import type {
  UploadGroupListPageQuery,
  UploadGroupListPageQueryVariables,
} from '~/apollo/generated/schema';
import { FilterSearch } from '~/components/common/FilterSearch';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreateGroupModal } from '~/components/upload/lithostrat/group/CreateGroupModal';
import { useSortFilter } from '~/hooks/data';
import {
  uploadLithostratGroupFormationsRoute,
  uploadLithostratGroupUpdateRoute,
  uploadLithostratTypeUpdateRoute,
} from '~/paths';
import { truncateText } from '~/utils/common';
import { mostSpecificAge } from '~/utils/modules/lithostratAge';

export const UPLOAD_GROUP_LIST_PAGE = gql`
  query UploadGroupListPage {
    lithostratGroupList {
      ...lithostratGroupParts
      lithostratType {
        ...lithostratTypeParts
      }
      startAge {
        ...lithostratAgeParts
      }
      wikiPage {
        ...lithostratWikiPageParts
      }
      formations {
        ...lithostratFormationParts
      }
    }
  }

  ${fragments.lithostratGroupParts}
  ${fragments.lithostratTypeParts}
  ${fragments.lithostratAgeParts}
  ${fragments.lithostratWikiPageParts}
  ${fragments.lithostratFormationParts}
`;

type SortableGroup = UploadGroupListPageQuery['lithostratGroupList'][number] & {
  wikiPublished: boolean;
  formationCount: number;
};

export default function UploadGroupListRoute() {
  const { data, loading } = useQuery<
    UploadGroupListPageQuery,
    UploadGroupListPageQueryVariables
  >(UPLOAD_GROUP_LIST_PAGE, {});

  const refetchQueries: [PureQueryOptions<UploadGroupListPageQueryVariables>] =
    [{ query: UPLOAD_GROUP_LIST_PAGE }];

  const groupList = data?.lithostratGroupList ?? [];
  const sortableGroups: SortableGroup[] = groupList.map(g => ({
    ...g,
    wikiPublished: g.wikiPage?.published || false,
    formationCount: g.formations.length,
  }));
  const { items, sortIndicatorProps, filterSearchProps } = useSortFilter(
    sortableGroups,
    'name',
    'name',
    'uploadGroupList',
  );

  if (loading) return <SpinnerPlaceholder />;

  return (
    <Panel>
      <Panel.Heading className="flex justify-between items-center">
        <Panel.Title>
          Groups
          <small className="text-muted" style={{ marginLeft: '10px' }}>
            (<strong>{items.length}</strong> results displayed)
          </small>
        </Panel.Title>

        <CreateGroupModal refetchQueries={refetchQueries}>
          {showCreateModal => (
            <Button
              type="button"
              color="primary"
              size="xs"
              onClick={showCreateModal}
              className="gap-1"
            >
              <FontAwesomeIcon icon={faPlus} /> Create Group
            </Button>
          )}
        </CreateGroupModal>
      </Panel.Heading>
      <Panel.Body>
        <FilterSearch {...filterSearchProps} />

        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>
                <SortTrigger
                  colName="id"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  ID
                </SortTrigger>
              </th>
              <th className="lg:w-2/12">
                <SortTrigger
                  colName="name"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  Name
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="lithostratType.name"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Lithostrat Type
                </SortTrigger>
              </th>
              <th className="lg:w-6/12">Description</th>
              <th>Start Age</th>
              <th className="text-center">
                <SortTrigger
                  colName="formationCount"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  Formations
                </SortTrigger>
              </th>
              <th className="text-center">
                <SortTrigger
                  colName="isWikiPublished"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Wiki Published
                </SortTrigger>
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map(g => (
              <tr key={g.id}>
                <td>{g.id}</td>
                <td>
                  <Link
                    to={uploadLithostratGroupUpdateRoute(g.id)}
                    className="link"
                  >
                    {g.name}
                  </Link>
                </td>
                <td>
                  {g.lithostratType && (
                    <Link
                      to={uploadLithostratTypeUpdateRoute(g.lithostratType.id)}
                      className="link"
                    >
                      {g.lithostratType.name}
                    </Link>
                  )}
                </td>
                <td>{truncateText(g.description, 100)}</td>
                <td>{mostSpecificAge(g.startAge)}</td>
                <td className="text-center">
                  <Link
                    to={uploadLithostratGroupFormationsRoute(g.id)}
                    className="link"
                  >
                    {g.formationCount}
                  </Link>
                </td>
                <td className="text-center">
                  <EnabledIndicator value={g.wikiPublished} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
}
