import { Field } from 'formik';
import { IssueType } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import { snakeCapsToHuman } from '~/utils/text';

const issueTypes = Object.values(IssueType); // ['Bug', 'Feature request', 'Support'];
const issueTypeOptions = issueTypes.map(opt => ({
  value: opt,
  label: snakeCapsToHuman(opt),
}));

export function IssueFormFields() {
  return (
    <>
      <Field
        name="issueType"
        label="Issue type"
        component={FormikField}
        type="select"
        options={issueTypeOptions}
        required
      />
      <Field name="title" label="Title" component={FormikField} required />
      <Field
        name="content"
        label="Description"
        component={FormikField}
        type="textarea"
        required
      />
    </>
  );
}
