import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';

import { CREATE_LITHOSTRAT_STUDY_LINK } from '~/apollo/operations/lithostrat';
import type {
  CreateLithostratStudyLinkMutation,
  CreateLithostratStudyLinkMutationVariables,
  LithostratStudyLinkInput,
} from '~/apollo/generated/schema';
import { LithostratLinkTargetType } from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import { Modal } from '~/components/common/Modal';
import { FormationSelector } from '~/components/upload/lithostrat/formation/FormationSelector';
import { GroupSelector } from '~/components/upload/lithostrat/group/GroupSelector';
import { MemberSelector } from '~/components/upload/lithostrat/member/MemberSelector';
import { LithostratTypeSelector } from '~/components/upload/lithostratType/LithostratTypeSelector';
import { useModalState } from '~/hooks/modal';
import { ucwords } from '~/utils/text';
import { yup } from '~/utils/validation';

export type FormValues = {
  /** Optional for filtering entities */
  lithostratTypeId: string;
  targetType: LithostratLinkTargetType;
  targetId: string;
};

type Props = {
  studyId: number;
  refetchQueries: PureQueryOptions[];
  children: (showModal: () => void) => React.ReactNode;
};

export function CreateLithostratStudyLinkModal({
  studyId,
  children,
  refetchQueries,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [createLink, { loading, error }] = useMutation<
    CreateLithostratStudyLinkMutation,
    CreateLithostratStudyLinkMutationVariables
  >(CREATE_LITHOSTRAT_STUDY_LINK, {
    refetchQueries,
  });

  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    const lithostratStudyLink: LithostratStudyLinkInput = {
      parentId: studyId,
      targetId: parseInt(values.targetId),
      targetType: values.targetType,
    };

    try {
      await createLink({ variables: { lithostratStudyLink } });
      toast.success('Link created successfully.');
      hideModal();
      helpers.resetForm();
    } catch (err) {
      console.log('Error creating link', err);
      toast.error(
        'There was a problem creating the link. Check the data entered and try again.',
      );
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            lithostratTypeId: '',
            targetType: LithostratLinkTargetType.Formation,
            targetId: '',
          }}
          validationSchema={yup.object({
            targetType: yup
              .string()
              .oneOf(Object.values(LithostratLinkTargetType))
              .required(),
            targetId: yup.number().integer().required(),
          })}
        >
          <Form>
            <Modal.Body heading="Create Lithostrat Link">
              <div className="space-y-4">
                <StudyLithostratLinkFormFields />
                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" loading={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}

function TargetIdSelect({
  name,
  targetType,
  targetLithostratTypeId,
}: {
  name: string;
  targetType: LithostratLinkTargetType;
  targetLithostratTypeId?: number;
}) {
  let fieldType = null;
  if (targetType === 'group') fieldType = GroupSelector;
  if (targetType === 'formation') fieldType = FormationSelector;
  if (targetType === 'member') fieldType = MemberSelector;
  if (!fieldType) return null;

  return (
    <Field
      name={name}
      label={ucwords(targetType)}
      component={FormikField}
      type={fieldType}
      disabled={!fieldType}
      lithostratTypeId={targetLithostratTypeId}
      onlyPublished
      required
    />
  );
}

function StudyLithostratLinkFormFields() {
  const { values } = useFormikContext<FormValues>();

  // Cast to int so it can be matched, if set
  const lithostratTypeId = values.lithostratTypeId
    ? parseInt(values.lithostratTypeId)
    : undefined;

  return (
    <div className="space-y-2">
      <Field
        name="targetType"
        label="Type"
        component={FormikField}
        type="select"
        required
        options={Object.values(LithostratLinkTargetType).map(opt => ({
          value: opt,
          label: opt,
        }))}
      />

      {values.targetType && (
        <>
          <Field
            name="lithostratTypeId"
            label="Lithostrat Type &ndash; Use to refine options if needed"
            component={FormikField}
            type={LithostratTypeSelector}
          />
          <TargetIdSelect
            name="targetId"
            targetType={values.targetType}
            targetLithostratTypeId={lithostratTypeId}
          />
        </>
      )}
    </div>
  );
}
