import { KeyParametersPanel } from '~/components/keyParameter/KeyParametersPanel';
import type { ReportDisplayReportItem } from '~/components/report/ReportDisplay/ReportItem';
import { WikiDescriptionText } from '~/components/wiki/lithostrat/WikiPageDescriptionText';

type Props = {
  member: Extract<
    NonNullable<ReportDisplayReportItem['bookmark']>['targetLithostratMember'],
    { __typename?: 'LithostratMember' }
  >;
};

export function ReportItemLithostratMember({ member }: Props) {
  if (!member.wikiPage) {
    return <p>{member.description ?? member.name}</p>;
  }

  return (
    <div className="grid lg:grid-cols-3 gap-6">
      <div className="lg:col-span-2">
        <WikiDescriptionText wiki={member.wikiPage} />
      </div>

      <div>
        <KeyParametersPanel
          keyParameters={member.wikiPage.keyParameters ?? []}
        />
      </div>
    </div>
  );
}
