import { cn } from '~/utils/common';
import type { HTMLProps } from 'react';
import React from 'react';

export type HeadingProps = HTMLProps<HTMLHeadingElement> & {
  level: 1 | 2 | 3 | 4 | 5;
};

export function Heading({ level, className, ...props }: HeadingProps) {
  let el: string;
  if (level === 1) el = 'h1';
  else if (level === 2) el = 'h2';
  else if (level === 3) el = 'h3';
  else if (level === 4) el = 'h4';
  else el = 'h5';

  return React.createElement(el, {
    className: cn('font-serif font-semibold', className, {
      'text-3xl': level === 1,
      'text-2xl': level === 2,
      'text-xl': level === 3,
      'text-lg': level === 4,
      'text-base': level === 5,
    }),
    ...props,
  });
}
