import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useDataSearchContext } from '~/components/dataSearch/dataSearchContext';
import type { CrossPlotDataPoint } from '~/utils/chart';
import { rejectNil } from '~/utils/common';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';

export function useCrossPlotData() {
  const { measurements } = useDataSearchContext();
  const { values } = useFormikContext<DataSearchFormValues>();

  const dataCrossPlot = useMemo((): CrossPlotDataPoint[] => {
    // When the graph type isn't a cross plot, short circuit to prevent unnecessary calculations
    if (values.graphType !== 'crossPlot') {
      return [];
    }

    return measurements
      .map(d => {
        if (!d.y) return null;
        return {
          x: d.x,
          y: d.y,
          label: d[values.groupDataBy] ?? 'undefined',
        };
      })
      .filter(rejectNil);
  }, [values.graphType, measurements, values.groupDataBy]);

  return { dataCrossPlot };
}
