import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';

import * as fragments from '~/apollo/fragments';
import {
  GeoreferenceDataType,
  GeoreferenceParent,
  type UploadOutcropUpdateGeoreferencesTabQuery,
  type UploadOutcropUpdateGeoreferencesTabQueryVariables,
} from '~/apollo/generated/schema';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { GeoreferenceManager } from '~/components/upload/georeference/GeoreferenceManager';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

const GEOREFERENCES_TAB = gql`
  query UploadOutcropUpdateGeoreferencesTab($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts
      georeferences {
        ...georeferenceParts
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.georeferenceParts}
`;

export default function UpdateOutcropGeoreferencesRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UploadOutcropUpdateGeoreferencesTabQuery,
    UploadOutcropUpdateGeoreferencesTabQueryVariables
  >(GEOREFERENCES_TAB, {
    variables: { outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropUpdateGeoreferencesTabQueryVariables>,
  ] = [
    {
      query: GEOREFERENCES_TAB,
      variables: { outcropId },
    },
  ];

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);
  const georeferences = outcrop?.georeferences ?? [];

  if (loading) return <SpinnerPlaceholder />;

  return (
    <GeoreferenceManager
      georeferences={georeferences}
      dataTypes={[
        GeoreferenceDataType.Centre,
        GeoreferenceDataType.Outline,
        GeoreferenceDataType.Polygon,
        GeoreferenceDataType.Polyline,
        GeoreferenceDataType.Point,
      ]}
      limits={{ OUTLINE: 1, CENTRE: 1 }}
      parentType={GeoreferenceParent.Outcrop}
      parentId={outcropId}
      refetchQueries={refetchQueries}
    />
  );
}
