import { Field, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { FormikField } from '~/components/common/FormikField';
import { Heading } from '~/components/common/Heading';
import type { MAFToolFormValues } from '~/routes/modern/index';

export const classificationOptions = {
  Continental: ['F', 'FA', 'FL', 'A', 'AF', 'AL', 'L', 'LF', 'LA'],
  'Paralic and Shallow Marine': [
    'F',
    'FW',
    'WF',
    'W',
    'WT',
    'TW',
    'T',
    'TF',
    'FT',
  ],
};

type Props = {
  disabled: boolean;
};

export function ModernFiltersClassification({ disabled }: Props) {
  const { values, setFieldValue } = useFormikContext<MAFToolFormValues>();
  const gdeRef = useRef(values.gde);

  useEffect(() => {
    // Reset the classification when GDE is updated
    if (gdeRef.current !== values.gde && values.classification.length > 0) {
      setFieldValue('classification', []);
    }
    gdeRef.current = values.gde;
  }, [values.gde]);

  return (
    <>
      <Heading level={4}>Classification</Heading>

      {classificationOptions[values.gde].map(value => (
        <Field
          key={value}
          name="classification"
          label={value}
          value={value}
          component={FormikField}
          type="checkbox"
          disabled={disabled}
          size="sm"
        />
      ))}
    </>
  );
}
