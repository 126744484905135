import * as d3 from 'd3';
import moment from 'moment';
import * as R from 'ramda';
import * as ss from 'simple-statistics';

export type CrossPlotDataPoint = {
  x: number;
  y: number;
  label: string;
};
type DataFrequency = Record<string, number | string>;

export const crossPlotRegressionFormula = (
  data: CrossPlotDataPoint[],
  isLogX: boolean,
  isLogY: boolean,
) => {
  const mapped = data.map(d => [
    isLogX ? Math.log(d.x) : d.x,
    isLogY ? Math.log(d.y) : d.y,
  ]);
  return ss.linearRegression(mapped);
};

export const crossPlotRegression = (
  data: CrossPlotDataPoint[],
  isLogX: boolean,
  isLogY: boolean,
): [x: number, y: number][] => {
  const sortedData = R.sortBy(d => d.x, data);
  const { m, b } = crossPlotRegressionFormula(sortedData, isLogX, isLogY);
  const [minX = 0, maxX = 0] = d3.extent(sortedData.map(d => d.x));

  const ticks = d3.ticks(minX, maxX, 10);

  // Ticks doesn't always use the exact start/end values, but we want those.
  if (ticks[0] !== minX) ticks.unshift(minX);
  if (ticks[ticks.length - 1] !== maxX) ticks.push(maxX);

  return ticks.map(x => {
    const xTranslated = isLogX ? Math.log(x) : x;
    let yValue = m * xTranslated + b;

    // When Y axis is log, back-transform the value
    if (isLogY) yValue = Math.exp(yValue);

    return [
      x, // Translation is never applied to X
      yValue,
    ];
  });
};

export const crossPlotRSquared = (
  data: CrossPlotDataPoint[],
  regression: ReturnType<typeof ss.linearRegression>,
) => {
  const dataPoints = data.map(d => [d.x, d.y]);
  const regressionLine = ss.linearRegressionLine(regression);
  const rSquaredValue = ss.rSquared(dataPoints, regressionLine);
  return rSquaredValue;
};

export function zeroInterceptRegressionFormula(data: CrossPlotDataPoint[]) {
  const sumXY = data.reduce((acc, d) => acc + d.x * d.y, 0);
  const sumXSquared = data.reduce((acc, d) => acc + d.x ** 2, 0);
  return {
    m: sumXY / sumXSquared,
    b: 0,
  };
}

export function zeroInterceptRegression(data: CrossPlotDataPoint[]) {
  const sortedData = R.sortBy(d => d.x, data);
  const { m, b } = zeroInterceptRegressionFormula(sortedData);

  const [minX = 0, maxX = 1] = d3.extent(sortedData.map(d => d.x));

  const ticks = d3.ticks(0, maxX, 10);

  // Ensure the ticks include 0 and maxX
  if (ticks[0] !== 0) ticks.unshift(0);
  if (ticks[1] !== minX) {
    // Ensure the line starts, at a minimum, from the first data point.
    // In a log axis, this will be the start point, otherwise it will go through the origin
    ticks.shift(); // Remove the 0 we just added
    ticks.unshift(minX);
    ticks.unshift(0);
  }
  if (ticks[ticks.length - 1] !== maxX) ticks.push(maxX);

  return ticks.map(x => [x, m * x + b]);
}

///
type Ticks = [range: number[], domain: [start: number, stop: number]];
function createTicks(
  data: number[],
  numBins?: number | null,
  binWidth?: number | null,
  nice?: boolean,
): Ticks {
  const [min = 0, max = 1] = d3.extent(data);

  if (numBins && binWidth) {
    const start = min + binWidth;
    const stop = min + (binWidth * numBins - 1);

    if (nice) return [d3.ticks(start, stop, numBins), [min, stop]];

    const range = d3.range(start, stop, binWidth);
    return [range, [min, stop]];
  } else if (numBins && !binWidth) {
    const calculatedBinWidth = (max - min) / numBins;
    const start = min;
    const stop = min + (calculatedBinWidth * numBins - 1);

    if (nice) return [d3.ticks(start, stop, numBins), [min, stop]];

    const range = d3.range(start, stop, calculatedBinWidth).slice(1);
    return [range, [min, stop]];
  } else if (!numBins && binWidth) {
    const start = min;
    const stop = max;

    if (nice) {
      const numBins = Math.ceil((max - min) / binWidth);
      return [d3.ticks(start, stop, numBins), [min, stop]];
    }

    const range = d3.range(start, stop, binWidth).slice(1);
    return [range, [min, stop]];
  }

  const range = d3.ticks(min, max, 10);
  return [range, [min, max]];
}

function binLabelNew(min: number, max: number, index: number, length: number) {
  const formatNumber = (num: number) => parseFloat(num.toFixed(2));

  if (index === 0) return `<${formatNumber(max)}`;
  if (index === length - 1) return `>${formatNumber(min)}`;
  return `${formatNumber(min)}-${formatNumber(max)}`;
}

export type HistogramDataPoint = {
  label: string;
  value: number;
};
export type BinnedData = {
  binWidth: number;
  numBins: number;
  data: DataFrequency[];
};
export function binData(
  data: HistogramDataPoint[],
  numBins?: number | null,
  binWidth?: number | null,
  nice: boolean = true,
): BinnedData {
  const aeNames = data.map(d => d.label);
  const values = data.map(d => d.value);

  const [ticks, domain] = createTicks(values, numBins, binWidth, nice);

  const binnedData = d3
    .bin<HistogramDataPoint, number>()
    .domain(domain)
    .value(d => d.value)
    .thresholds(ticks)(data);

  const tickWidths: number[] = [];

  const dataFrequency = binnedData.reduce<DataFrequency[]>(
    (acc, cur, i, ar) => {
      if (!cur.x0 || !cur.x1) return acc;
      tickWidths.push(cur.x1 - cur.x0);
      const label = binLabelNew(cur.x0, cur.x1, i, ar.length);
      const frequencies = aeNames.reduce<Record<string, number>>(
        (aeNameMap, aeName) => {
          const numAEs = cur.filter(d => d.label === aeName).length;
          return { ...aeNameMap, [aeName]: numAEs };
        },
        {},
      );

      return [...acc, { label, ...frequencies }];
    },
    [],
  );

  const tickWidth = d3.mode(tickWidths);
  const finalNumBins = dataFrequency.length;

  return {
    numBins: finalNumBins,
    binWidth: tickWidth,
    data: dataFrequency,
  };
}

export const colorList = [
  '#2196f3',
  '#ff1d58',
  '#8458B3',
  '#f75990',
  '#00DDFF',
  '#ffa8B6',
  '#B22222',
  '#FF00FF',
  '#E9967A',
  '#FFD700',
  '#483D8B',
  '#00008B',
  '#B8860B',
  '#FA8072',
  '#7B68EE',
  '#0000FF',
  '#00CED1',
  '#FFA07A',
  '#D8BFD8',
  '#00FA9A',
  '#CD5C5C',
  '#A0522D',
  '#8B0000',
  '#DC143C',
  '#FF0000',
  '#9370DB',
  '#FFB6C1',
  '#7FFFD4',
  '#191970',
  '#20B2AA',
  '#40E0D0',
  '#DAA520',
  '#FF7F50',
  '#4682B4',
  '#FF8C00',
  '#8B008B',
  '#CD853F',
  '#6495ED',
  '#7FFF00',
  '#9ACD32',
  '#000080',
  '#FF69B4',
  '#2E8B57',
  '#556B2F',
  '#8A2BE2',
  '#0000CD',
  '#4169E1',
  '#ADD8E6',
  '#DEB887',
  '#00FFFF',
  '#00FF00',
  '#66CDAA',
  '#1E90FF',
  '#4B0082',
  '#6B8E23',
  '#008B8B',
  '#008000',
  '#00BFFF',
  '#BDB76B',
  '#D2B48C',
  '#32CD32',
  '#00FF7F',
  '#87CEFA',
  '#7CFC00',
  '#48D1CC',
  '#DA70D6',
  '#8B4513',
  '#98FB98',
  '#FFA500',
  '#BC8F8F',
  '#D2691E',
  '#800000',
  '#006400',
  '#EE82EE',
  '#5F9EA0',
  '#FF1493',
  '#9400D3',
  '#DB7093',
  '#A52A2A',
  '#800080',
  '#008080',
  '#F08080',
  '#663399',
  '#F4A460',
  '#8FBC8B',
  '#808000',
  '#B0C4DE',
  '#C71585',
  '#FF6347',
  '#228B22',
  '#6A5ACD',
  '#ADFF2F',
  '#FF4500',
  '#9932CC',
  '#3CB371',
  '#FFFF00',
  '#90EE90',
  '#87CEEB',
  '#DDA0DD',
  '#BA55D3',
];

/** Get the color at the selected index. Repeats once the list of colors has been exhausted. */
export function colorByIndex(index: number): string {
  return colorList[index % (colorList.length - 1)];
}

export function addWatermark(this: Highcharts.Chart): void {
  const img = 'https://safaridb.com/assets/images/common/safari-logo-black.png';
  this.renderer.image(img, 85, 20, 125, 45).attr('style', 'opacity: 0.1').add();
}

export const chartExportOptions: Highcharts.Options['exporting'] = {
  buttons: {
    contextButton: {
      menuItems: [
        'viewFullscreen',
        'printChart',
        'separator',
        'downloadPNG',
        'downloadJPEG',
        'downloadPDF',
        'downloadSVG',
        'separator',
        'downloadCSV',
        'downloadXLS',
      ],
    },
  },
  chartOptions: {
    credits: {
      enabled: true,
      text: `Extracted from SafariDB.com on ${moment().format('LLL')}`,
      style: { fontSize: '7pt' },
      href: 'https://safaridb.com',
    },
    chart: {
      events: {
        load: addWatermark,
      },
    },
  },
};
