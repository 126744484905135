import type { FieldProps } from 'formik';
import * as R from 'ramda';
import React from 'react';
import type { Props as CheckboxFieldProps } from './FormikCheckboxField';
import { FormikCheckboxField } from './FormikCheckboxField';
import type { Props as FileFieldProps } from './FormikFileField';
import FormikFileField from './FormikFileField';
import type { FormikSelectFieldProps } from './FormikSelectField';
import { FormikSelectField } from './FormikSelectField';
import type { Props as TextFieldProps } from './FormikTextField';
import { FormikTextField } from './FormikTextField';
// import FormikDateField, { Props as DateFieldProps } from './FormikDateField';
import type { Props as RadioFieldProps } from './FormikRadioField';
import { FormikRadioField } from './FormikRadioField';
import type { Props as NumberFieldProps } from './FormikNumberField';
import { FormikNumberField } from './FormikNumberField';
import type { Props as JoystickNumberProps } from './FormikJoystickNumberField';
import { FormikJoystickNumberField } from './FormikJoystickNumberField';

const textTypes = ['text', 'email', 'password', 'textarea'];
function isTextType(t?: string): t is TextFieldProps['type'] {
  return !t || textTypes.includes(t);
}

type Props = FieldProps &
  (
    | ({ type: React.ReactElement } & FieldProps)
    | ({ type: TextFieldProps['type'] } & TextFieldProps)
    | ({ type: 'file' } & FileFieldProps)
    // | ({ type: 'date' } & DateFieldProps)
    | ({ type: 'select' } & FormikSelectFieldProps)
    | ({ type: 'checkbox' } & CheckboxFieldProps)
    | ({ type: 'radio' } & RadioFieldProps)
    | ({ type: 'number' } & NumberFieldProps)
    | ({ type: 'joystick-number' } & JoystickNumberProps)
    | ({ type: Function } & any)
  );

export function FormikField(props: Props) {
  const { field, form, type } = props;

  const fieldPath = R.pipe(R.split('.'), R.path)(field.name);
  const hasValue = R.complement(R.isNil);

  const isFieldTouched = R.pipe(fieldPath, hasValue)(form.touched);
  const hasError = R.pipe(fieldPath, hasValue)(form.errors);
  const isFormSubmitted = form.submitCount > 0;
  const isInvalid = (isFormSubmitted || isFieldTouched) && hasError;

  if (typeof type === 'function') {
    return React.createElement(type, { ...props, isInvalid });
  }

  if (type && typeof type !== 'string') {
    console.log('Type is:', type);
    throw new Error('`type` must be a string for a React component');
  }

  if (!type || isTextType(type)) {
    return <FormikTextField {...props} type={type} isInvalid={isInvalid} />;
  }

  if (type === 'file') {
    return <FormikFileField {...props} isInvalid={isInvalid} />;
  }

  // if (type === 'date') {
  //   return <FormikDateField {...props} isInvalid={isInvalid} />;
  // }

  if (type === 'checkbox') {
    return <FormikCheckboxField {...props} isInvalid={isInvalid} />;
  }

  if (type === 'radio') {
    return <FormikRadioField {...props} isInvalid={isInvalid} />;
  }

  if (type === 'select') {
    return <FormikSelectField {...props} isInvalid={isInvalid} />;
  }

  if (type === 'number') {
    return <FormikNumberField {...props} isInvalid={isInvalid} />;
  }
  
  if (type === 'joystick-number') {
    return <FormikJoystickNumberField {...props} isInvalid={isInvalid} />;
  }

  return null;
}
