import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import invariant from 'tiny-invariant';
import type {
  MySafariCompanyCollectionsPageQuery,
  MySafariCompanyCollectionsPageQueryVariables,
} from '~/apollo/generated/schema';
import {
  BookmarkCollectionList,
  bookmarkCollectionListParts,
} from '~/components/bookmark/BookmarkCollectionList';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useAuth } from '~/contexts/auth';

const MY_SAFARI_COMPANY_COLLECTIONS_PAGE = gql`
  query MySafariCompanyCollectionsPage {
    bookmarkCollectionList(includePersonal: false, includeCompany: true) {
      ...bookmarkCollectionListParts
    }
  }

  ${bookmarkCollectionListParts}
`;

export default function MySafariCompanyCollectionsPage() {
  const { authority } = useAuth();
  invariant(authority, 'Must be authenticated');

  const { data, loading } = useQuery<
    MySafariCompanyCollectionsPageQuery,
    MySafariCompanyCollectionsPageQueryVariables
  >(MY_SAFARI_COMPANY_COLLECTIONS_PAGE);

  const refetchQueries: [PureQueryOptions] = [
    { query: MY_SAFARI_COMPANY_COLLECTIONS_PAGE },
  ];

  if (loading) return <SpinnerPlaceholder />;
  if (!data?.bookmarkCollectionList) return <NotFound />;

  return (
    <BookmarkCollectionList
      collections={data.bookmarkCollectionList}
      isCompany={true}
      refetchQueries={refetchQueries}
    />
  );
}
