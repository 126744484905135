import type { FieldProps } from 'formik';
import type { FormikSelectFieldProps } from '~/components/common/FormikField/FormikSelectField';
import { FormikSelectField } from '~/components/common/FormikField/FormikSelectField';
import { countryList } from './countryList';

type Props = FieldProps & FormikSelectFieldProps;

export function CountryPicker({ form, field, disabled, ...props }: Props) {
  const options = countryList.map(country => ({
    value: country,
    label: country,
  }));

  return (
    <FormikSelectField form={form} field={field} {...props} options={options} />
  );
}
