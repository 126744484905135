import type { Cesium3DTileset, CustomDataSource, Event, Viewer } from 'cesium';
import { useEffect, useRef, useState } from 'react';
import CesiumNavigation from 'cesium-navigation-es6';
import type { TilesetParams } from './cesiumUtils';
import {
  addCustomPinStyle,
  addTilesetToViewer,
  cameraInViewHandler,
  createCustomDataSourcePoints,
  setupCesiumViewer,
  TerrainProvider,
} from './cesiumUtils';

export type vomLocations = {
  latitude: number;
  longitude: number;
  name: string;
};

type ViewerProps = {
  initialTilesets?: TilesetParams[];
  enableNavigation?: boolean;
  initialOutcropLocations?: vomLocations[];
  setOutcropIdsInView?: React.Dispatch<React.SetStateAction<number[]>>;
  viewerCallback?: React.Dispatch<React.SetStateAction<Viewer | null>>;
  zoomTo?: boolean;
};

export type LatLngHeightHPR = {
  latitude: number;
  longitude: number;
  height: number;
  heading: number;
  pitch: number;
  roll: number;
};

export function CesiumGlobeViewer({
  initialTilesets = [],
  enableNavigation = true,
  initialOutcropLocations = [],
  zoomTo = false,
  setOutcropIdsInView = () => {},
  viewerCallback = () => {},
}: ViewerProps) {
  const [viewer, setViewer] = useState<Viewer>();
  const [nav, setNav] = useState<CesiumNavigation>();
  // const [tilesets, setTilesets] = useState<Cesium3DTileset[]>([]);
  const [dataSource, setDataSource] = useState<CustomDataSource | null>(null);
  const tilesetsLoaded = useRef<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    async function initialSetup() {
      if (ref.current !== null) {
        console.log('ref: ', ref.current);
        if (ref.current.childElementCount == 0) {
          console.log('No children found, adding viewer!');

          const v = await setupCesiumViewer(ref.current, TerrainProvider.World);
          setViewer(v);
          viewerCallback(v);
        }
      }
    }
    initialSetup();
  }, [viewerCallback]);

  useEffect(() => {
    if (viewer && !dataSource) {
      if (initialOutcropLocations.length > 0) {
        console.log('reset datasource');
        // if (dataSource) {
        //   viewer.dataSources.remove(dataSource);
        // }
        const ds = createCustomDataSourcePoints(initialOutcropLocations);
        ds.clustering.enabled = true;
        ds.clustering.pixelRange = 15;
        ds.clustering.minimumClusterSize = 2;
        viewer.dataSources.add(ds);
        addCustomPinStyle(ds);
        setDataSource(ds);
      }
    }
  }, [viewer, initialOutcropLocations, dataSource]);

  useEffect(() => {
    async function loadTilesets() {
      if (viewer && initialTilesets.length > 0 && !tilesetsLoaded.current) {
        console.log('reloading tilesets');
        const tempTilesets: Cesium3DTileset[] = [];
        tilesetsLoaded.current = true;
        for (let tsParams of initialTilesets) {
          addTilesetToViewer(viewer, tsParams, false).then(ts => {
            if (ts) {
              tempTilesets.push(ts);
            }
          });
        }
        // setTilesets(tempTilesets);
      }
    }
    loadTilesets();
  }, [initialTilesets, viewer]);

  useEffect(() => {
    async function loadCameraEventHandler() {
      if (dataSource && viewer) {
        console.log('set inviewhandler');
        await cameraInViewHandler(viewer, dataSource, setOutcropIdsInView);
      }
    }
    loadCameraEventHandler();
  }, [dataSource, viewer, setOutcropIdsInView]);

  return <div ref={ref} id="cesiumviewer"></div>;
}
