import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import * as fragments from '~/apollo/fragments';
import type {
  OtherWikiIndexRouteQuery,
  OtherWikiIndexRouteQueryVariables,
} from '~/apollo/generated/schema';
import { FilterSearch } from '~/components/common/FilterSearch';
import { ListGroup } from '~/components/common/ListGroup';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useSortFilter } from '~/hooks/data';
import { otherWikiDetailRoute } from '~/paths';

const OTHER_WIKI_INDEX_ROUTE = gql`
  query OtherWikiIndexRoute {
    otherList {
      ...otherWikiParts
    }
  }

  ${fragments.otherWikiParts}
`;

export default function OtherWikiIndexRoute() {
  const { data, loading } = useQuery<
    OtherWikiIndexRouteQuery,
    OtherWikiIndexRouteQueryVariables
  >(OTHER_WIKI_INDEX_ROUTE, {});

  const { items, filterSearchProps } = useSortFilter(
    data?.otherList ?? [],
    'title',
    'title',
    'otherWikiList',
  );

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <PageHeading className="mt-0">Other Articles</PageHeading>
      <p className="mb-4">
        The wiki articles in this section describe the SAFARI standard aside
        from the depositional schema, and other related topics.
      </p>

      <FilterSearch {...filterSearchProps} />

      <ListGroup>
        {items.map(otherWiki => (
          <ListGroup.Item key={otherWiki.id}>
            <Link to={otherWikiDetailRoute(otherWiki.id)} className="block p-1">
              {otherWiki.title}
            </Link>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
}
