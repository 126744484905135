import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { faPlus, faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useState } from 'react';
import { Button } from 'react-daisyui';
import { Link } from 'react-router-dom';
import {
  publicCompanyParts,
  publicUserParts,
  reportItemSummaryParts,
  reportParts,
} from '~/apollo/fragments';
import {
  Role,
  type ReportListingQuery,
  type ReportListingQueryVariables,
} from '~/apollo/generated/schema';
import { FilterSearch } from '~/components/common/FilterSearch';
import { PageHeading } from '~/components/common/PageHeading';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { ReportItemCard } from '~/components/report/ReportItemCard';
import { useAuth } from '~/contexts/auth';
import { useSortFilter } from '~/hooks/data';
import { createReportRoute } from '~/paths';

const REPORT_LISTING = gql`
  query ReportListing($includePersonal: Boolean!, $includeCompany: Boolean!) {
    reportList(
      includePersonal: $includePersonal
      includeCompany: $includeCompany
    ) {
      ...reportParts
      itemSummary {
        ...reportItemSummaryParts
      }
      user {
        ...publicUserParts
      }
      company {
        ...publicCompanyParts
      }
    }
  }

  ${reportParts}
  ${reportItemSummaryParts}
  ${publicUserParts}
  ${publicCompanyParts}
`;

export function ReportListing({ isCompany }: { isCompany: boolean }) {
  const { authority, hasAnyRole } = useAuth();
  const [showSorting, setShowSorting] = useState(false);

  const queryVariables: ReportListingQueryVariables = {
    includePersonal: !isCompany,
    includeCompany: isCompany,
  };

  const { data, loading } = useQuery<
    ReportListingQuery,
    ReportListingQueryVariables
  >(REPORT_LISTING, { variables: queryVariables });

  const refetchQueries: [PureQueryOptions<ReportListingQueryVariables>] = [
    { query: REPORT_LISTING, variables: queryVariables },
  ];

  const allReports = R.sortBy(r => r.updatedAt, data?.reportList ?? []);

  const sortFilterStorageKey = isCompany
    ? 'company-report-listing'
    : 'personal-report-listing';

  const {
    items: reports,
    sortIndicatorProps: siProps,
    filterSearchProps,
  } = useSortFilter(
    allReports,
    'updatedAt',
    'title',
    sortFilterStorageKey,
    'desc',
  );

  const createReportSearchParams = new URLSearchParams();
  if (isCompany) {
    createReportSearchParams.set('company', 't');
  }

  function canEdit(report: ReportListingQuery['reportList'][number]) {
    if (!authority) return false;

    if (hasAnyRole([Role.RoleAdmin])) return true;
    if (authority.user.id === report.userId) return true;
    return (
      authority.user.companyId === report.companyId &&
      hasAnyRole([Role.RoleCompanyAdmin])
    );
  }

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <div className="flex justify-between items-center">
        <PageHeading>{isCompany ? 'Company' : 'My'} Reports</PageHeading>
        <Link
          to={{
            pathname: createReportRoute(),
            search: createReportSearchParams.toString(),
          }}
          className="btn btn-primary btn-sm"
        >
          <div className="space-x-1">
            <FontAwesomeIcon icon={faPlus} />
            <span>Create Report</span>
          </div>
        </Link>
      </div>

      <div className="flex justify-between items-center gap-2 mb-2">
        <div className="grow">
          <FilterSearch
            {...filterSearchProps}
            stick={false}
            showAlphabet={false}
            renderSearch={input => <div>{input}</div>}
          />
        </div>

        <div className="space-x-2">
          {showSorting && (
            <>
              <SortTrigger colName="updatedAt" sortIndicatorProps={siProps}>
                Last Updated
              </SortTrigger>
              <SortTrigger colName="title" sortIndicatorProps={siProps}>
                Title
              </SortTrigger>
            </>
          )}
          <Button
            type="button"
            color="ghost"
            size="sm"
            onClick={() => setShowSorting(!showSorting)}
            startIcon={<FontAwesomeIcon icon={faSort} />}
          >
            Sort
          </Button>
        </div>
      </div>

      {!reports.length && (
        <p className="text-muted italic text-center">
          {isCompany ? "Your company doesn't" : "You don't"} have any reports
          yet.
        </p>
      )}

      <div className="space-y-4">
        {reports.map(report => (
          <ReportItemCard
            key={report.id}
            report={report}
            canEdit={canEdit(report)}
            refetchQueries={refetchQueries}
          />
        ))}
      </div>
    </>
  );
}
