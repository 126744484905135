import type { UserPartsFragment } from '~/apollo/generated/schema';
import { SortTrigger } from '~/components/common/SortTrigger';
import { useSortFilter } from '~/hooks/data';
import { AdminUserListDeletedUsers } from './AdminUserListDeletedUsers';
import { AdminUserListItem } from './AdminUserListItem';

type Props = {
  userList: UserPartsFragment[];
};

export function AdminUserList({ userList }: Props) {
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    userList,
    [
      ['isCompanyAdmin', 'desc'],
      ['name', 'asc'],
    ],
    'name',
    'adminUserList',
  );

  const activeUsers = items.filter(user => !user.accountLocked);
  const lockedUsers = items.filter(user => user.accountLocked);

  return (
    <table className="table table-compact table-auto w-full">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="name" sortIndicatorProps={siProps}>
              Name
            </SortTrigger>
          </th>
          <th>
            <SortTrigger colName="username" sortIndicatorProps={siProps}>
              Email
            </SortTrigger>
          </th>
          <th className="text-center">
            <SortTrigger colName="lastActivity" sortIndicatorProps={siProps}>
              Last Activity
            </SortTrigger>
          </th>
          <th className="text-center">
            <SortTrigger colName="insertedAt" sortIndicatorProps={siProps}>
              Registered
            </SortTrigger>
          </th>
          <th className="text-center">
            <SortTrigger colName="activityCount" sortIndicatorProps={siProps}>
              Activity Count
            </SortTrigger>
          </th>
          <th className="text-center">Email Verified</th>
          <th className="text-center">
            <SortTrigger
              colName="isCompanyAdmin"
              sortIndicatorProps={siProps}
              filterable
            >
              Company Admin
            </SortTrigger>
          </th>
          <th />
        </tr>
      </thead>

      <tbody>
        {activeUsers.map(user => (
          <AdminUserListItem key={user.id} user={user} />
        ))}
      </tbody>

      {lockedUsers.length > 0 && (
        <AdminUserListDeletedUsers userList={lockedUsers} colSpan={8} />
      )}
    </table>
  );
}
