import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { CSSProperties } from 'react';
import React from 'react';
import { Tooltip } from '~/components/common/Tooltip';
import { ucwords } from '~/utils/text';

const iconPath = (filename: string) =>
  `/assets/images/geology-type/${filename}.PNG`;

type Props = React.HTMLAttributes<HTMLImageElement> & {
  geologyType: string;
  // Only strings are supported on tooltips with daisy-ui
  // overlay?: React.ReactNode;
  hideTooltip?: boolean;
  dominant?: boolean | null;
  style?: CSSProperties | undefined;
  render?: (imgSrc: string) => JSX.Element;
};

export function GeologyTypeIcon({
  geologyType,
  dominant,
  // overlay,
  hideTooltip = false,
  style,
  render,
  ...props
}: Props) {
  let imgSrc = null;
  const icons = ['carbonate', 'clastic', 'glacial', 'structural', 'volcanic'];
  if (icons.indexOf(geologyType) > -1) {
    imgSrc = iconPath(geologyType);
  } else if (geologyType === 'igneous') {
    // Use volcano icon for the previously-defined igneous types, ref #1242
    imgSrc = iconPath('volcanic');
  }

  if (!imgSrc)
    return (
      <FontAwesomeIcon
        {...(props as FontAwesomeIconProps)}
        style={style}
        icon={faQuestionCircle}
      />
    );

  if (render) return render(imgSrc);

  const getOverlay = (): string => {
    const gtLabel = ucwords(geologyType);
    // if (overlay) return overlay;
    if (dominant) return `${gtLabel} (dominant)`;
    return gtLabel;
  };

  const imgStyle = (): CSSProperties => {
    const dominantStyles: CSSProperties = {
      filter: 'brightness(110%) contrast(70%) sepia(30%)',
      border: '4px solid hsl(36, 100%, 80%)',
    };

    if (dominant) return { ...dominantStyles, ...style };
    return style ?? {};
  };

  return (
    <Tooltip message={getOverlay()} disabled={hideTooltip} position="top">
      <img src={imgSrc} style={imgStyle()} alt={geologyType} {...props} />
    </Tooltip>
  );
}
