import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { envVars } from '~/environment';

export function ItemPermalink({ path }: { path: string }) {
  const href = new URL(path, envVars.VITE_CLIENT_URL).toString();

  return (
    <span className="space-x-2">
      <FontAwesomeIcon icon={faGlobeAmericas} className="text-muted text-sm" />
      <a href={href} target="_blank" rel="noreferrer" className="link">
        {href}
      </a>
    </span>
  );
}
