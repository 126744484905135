import { Field, useFormikContext } from 'formik';
import { useEffect, useState, type ChangeEvent } from 'react';
import type {
  DataSearchOptionsQuery,
  DataSearchOptionsQueryVariables,
  DataSearchQueryInput,
  QueryOptionField,
} from '~/apollo/generated/schema';
import { SavedDataSearchGraphType } from '~/apollo/generated/schema';
import { FieldSet } from '~/components/common/FieldSet';
import { FormikField } from '~/components/common/FormikField';
import { DATA_SEARCH_OPTIONS } from '~/components/dataSearch/dataSearchContext';
import type { DmvFormValues } from '~/components/upload/defaultMeasurementsView/UpdateDmvForm';
import { useImperativeQuery } from '~/hooks/apollo';
import { ucwords } from '~/utils/text';

function buildParams(params: {
  outcropId?: number | null;
  studyId?: number | null;
  dataTypeX?: string | null;
  dataTypeY?: string | null;
}): DataSearchQueryInput {
  return {
    outcropIds: params.outcropId ? [params.outcropId] : null,
    studyIds: params.studyId ? [params.studyId] : null,
    dataTypeX: params.dataTypeX || null,
    dataTypeY: params.dataTypeY || null,
  };
}

export function DmvFormFields({
  outcropId,
  studyId,
}: {
  outcropId: number | null;
  studyId: number | null;
}) {
  const [optsCrossPlotX, setOptsCrossPlotX] = useState<QueryOptionField[]>([]);
  const [optsCrossPlotY, setOptsCrossPlotY] = useState<QueryOptionField[]>([]);
  const [optsHistogram, setOptsHistogram] = useState<QueryOptionField[]>([]);

  const { values, setFieldValue } = useFormikContext<DmvFormValues>();

  const [loadCrossPlotData] = useImperativeQuery<
    DataSearchOptionsQuery,
    DataSearchOptionsQueryVariables
  >(DATA_SEARCH_OPTIONS, {
    onCompleted(data) {
      setOptsCrossPlotX(data.dataSearchOptions.dataTypeX);
      setOptsCrossPlotY(data.dataSearchOptions.dataTypeY);
    },
  });

  const [loadHistogramData] = useImperativeQuery<
    DataSearchOptionsQuery,
    DataSearchOptionsQueryVariables
  >(DATA_SEARCH_OPTIONS, {
    onCompleted(data) {
      setOptsHistogram(data.dataSearchOptions.dataTypeX);
    },
  });

  useEffect(() => {
    loadCrossPlotData({
      variables: { params: buildParams({ outcropId, studyId }) },
    });
    loadHistogramData({
      variables: { params: buildParams({ outcropId, studyId }) },
    });
  }, [outcropId, studyId, loadCrossPlotData, loadHistogramData]);

  function handleChange_crossPlotX(event: ChangeEvent<HTMLSelectElement>) {
    let dataTypeX = event.target.value;
    let dataTypeY = values.crossPlotDataTypeY;

    setFieldValue('crossPlotDataTypeX', dataTypeX);

    if (dataTypeX && dataTypeX === dataTypeY) {
      dataTypeY = '';
      setFieldValue('crossPlotDataTypeY', dataTypeY);
    }

    if (dataTypeX && dataTypeY) {
      loadCrossPlotData({
        variables: {
          params: buildParams({ outcropId, studyId, dataTypeX, dataTypeY }),
        },
      });
    }
  }

  function handleChange_crossPlotY(event: ChangeEvent<HTMLSelectElement>) {
    let dataTypeX = values.crossPlotDataTypeX;
    let dataTypeY = event.target.value;

    setFieldValue('crossPlotDataTypeY', dataTypeY);

    if (dataTypeY && dataTypeY === dataTypeX) {
      dataTypeX = '';
      setFieldValue('crossPlotDataTypeX', dataTypeX);
    }

    if (dataTypeX && dataTypeY) {
      loadCrossPlotData({
        variables: {
          params: buildParams({ outcropId, studyId, dataTypeX, dataTypeY }),
        },
      });
    }
  }

  function handleChange_histogram(event: ChangeEvent<HTMLSelectElement>) {
    let dataType = event.target.value;
    setFieldValue('histogramDataType', dataType);

    if (dataType) {
      loadHistogramData({
        variables: {
          params: buildParams({ outcropId, studyId, dataTypeX: dataType }),
        },
      });
    }
  }

  return (
    <div className="space-y-4">
      <div>
        <Field
          name="graphType"
          type="radio"
          label="Default graph type"
          component={FormikField}
          inline
          options={[
            { value: SavedDataSearchGraphType.CrossPlot, text: 'Cross Plot' },
            { value: SavedDataSearchGraphType.Histogram, text: 'Histogram' },
          ]}
        />
      </div>

      <FieldSet>
        <FieldSet.Legend>Cross Plot</FieldSet.Legend>
        <div className="grid lg:grid-cols-2 gap-6">
          <div>
            <Field
              type="select"
              name="crossPlotDataTypeX"
              label="Data type X"
              component={FormikField}
              onChange={handleChange_crossPlotX}
              options={optsCrossPlotX.map(option => ({
                value: option.name ?? '',
                label: `${ucwords(option.name ?? '') ?? ''} (${option.count})`,
                disabled: option.name === values.crossPlotDataTypeY,
              }))}
              required={values.graphType === SavedDataSearchGraphType.CrossPlot}
            />
            <Field
              name="crossPlotLogScaleX"
              label="Logarimthic"
              component={FormikField}
              type="checkbox"
            />
          </div>

          <div>
            <Field
              type="select"
              name="crossPlotDataTypeY"
              label="Data type Y"
              component={FormikField}
              onChange={handleChange_crossPlotY}
              options={optsCrossPlotY.map(option => ({
                value: option.name ?? '',
                label: `${ucwords(option.name ?? '') ?? ''} (${option.count})`,
                disabled: option.name === values.crossPlotDataTypeX,
              }))}
              required={values.graphType === SavedDataSearchGraphType.CrossPlot}
            />
            <Field
              name="crossPlotLogScaleY"
              label="Logarimthic"
              component={FormikField}
              type="checkbox"
            />
          </div>
        </div>
      </FieldSet>

      <FieldSet>
        <FieldSet.Legend>Histogram</FieldSet.Legend>
        <Field
          name="histogramDataType"
          label="Data type"
          component={FormikField}
          type="select"
          options={optsHistogram.map(option => ({
            value: option.name ?? '',
            label: `${ucwords(option.name ?? '') ?? ''} (${option.count})`,
          }))}
          onChange={handleChange_histogram}
          required={values.graphType === SavedDataSearchGraphType.Histogram}
        />
      </FieldSet>
    </div>
  );
}
