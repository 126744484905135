import { PaleoMapOutcropManager } from '~/components/upload/paleomap/PaleoMapOutcropManager';
import { UpdatePaleoMap } from '~/components/upload/paleomap/UpdatePaleomap';
import { useUpdatePaleoMapOutletContext } from '~/routes/upload/paleomap/$paleoMapId';

export default function UpdatePaleomapDetailsRoute() {
  const { paleoMap: paleomap, refetchQueries } =
    useUpdatePaleoMapOutletContext();

  return (
    <div className="grid lg:grid-cols-2 gap-6">
      <div>
        <UpdatePaleoMap paleoMap={paleomap} />
      </div>

      <div>
        <PaleoMapOutcropManager
          paleoMap={paleomap}
          refetchQueries={refetchQueries}
        />
      </div>
    </div>
  );
}
