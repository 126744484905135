import { Link, NavLink, useMatches } from 'react-router-dom';
import { Heading } from '~/components/common/Heading';
import { WikiMenuGroup } from '~/components/wiki/WikiNavigation/WikiMenuGroup';
import * as routes from '~/paths';
import { GeologyWikiNavMenu } from './GeologyWikiNavMenu';

export function WikiRouteNavigation() {
  const onRegionPage = !!useMatches().find(
    r => r.id === 'routes/wiki/regional',
  );

  return (
    <div className="space-y-4">
      <div className="text-center">
        <Link to={routes.wikiRoute()} className="">
          <img
            src="/assets/images/page/icon-wiki.jpg"
            className="w-1/2 h-auto mx-auto"
            alt=""
          />

          <Heading level={3} className="mt-2">
            Knowledge Base
          </Heading>
        </Link>
      </div>

      <hr />

      <GeologyWikiNavMenu />

      <WikiMenuGroup
        label="Regional"
        sectionHeading
        defaultExpanded={onRegionPage}
      >
        <li>
          <NavLink to={routes.regionWikiOverviewRoute()}>
            Regions/Basins
          </NavLink>
        </li>
        <li>
          <NavLink to={routes.searchRoute()} target="_blank">
            Outcrops
          </NavLink>
        </li>
        <li>
          <Link to={routes.lithostratWikiOverviewRoute()}>Lithostrat</Link>
        </li>
      </WikiMenuGroup>

      <WikiMenuGroup
        label="Other Articles"
        sectionHeading
        href={routes.otherWikiOverviewRoute()}
      />
    </div>
  );
}
