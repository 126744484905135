import * as fragments from '~/apollo/fragments';
import { gql } from '@apollo/client';

export const CURRENT_AUTHORITY = gql`
  query CurrentAuthority {
    currentAuthority {
      ...authorityParts
    }
  }

  ${fragments.authorityParts}
  ${fragments.authorityUserParts}
  ${fragments.companyParts}
`;
