import { PageHeading } from '~/components/common/PageHeading';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { BulkFieldPictureUploader } from '~/components/upload/fieldPicture/BulkFieldPictureUploader';

export default function BulkUploadFieldPicsRoute() {
  useBreadcrumb('routes/upload/field-pictures', 'Bulk Upload Field Pictures');

  return (
    <>
      <PageHeading>Upload Field Pictures</PageHeading>
      <BulkFieldPictureUploader />
    </>
  );
}
