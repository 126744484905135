import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';

import * as fragments from '~/apollo/fragments';
import {
  CREATE_RESERVOIR_MODEL,
  DELETE_RESERVOIR_MODEL,
  UPDATE_RESERVOIR_MODEL,
} from '~/apollo/operations/supportingObject';
import type {
  UploadOutcropUpdateReservoirModelsTabQuery,
  UploadOutcropUpdateReservoirModelsTabQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

export const RESERVOIR_MODELS_TAB = gql`
  query UploadOutcropUpdateReservoirModelsTab($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      reservoirModels {
        ...reservoirModelParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        files {
          ...fileParts
        }
        outcropTag {
          ...outcropParts
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.reservoirModelParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function UpdateOutcropReservoirModelsRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UploadOutcropUpdateReservoirModelsTabQuery,
    UploadOutcropUpdateReservoirModelsTabQueryVariables
  >(RESERVOIR_MODELS_TAB, {
    variables: { id: outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropUpdateReservoirModelsTabQueryVariables>,
  ] = [
    {
      query: RESERVOIR_MODELS_TAB,
      variables: { id: outcropId },
    },
  ];

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);
  const reservoirModels = outcrop?.reservoirModels ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <SupportObjectManager
      outcropId={outcropId}
      supportObject={reservoirModels}
      supportObjectType="reservoirModel"
      parentType="RESERVOIR_MODEL"
      createMutation={CREATE_RESERVOIR_MODEL}
      updateMutation={UPDATE_RESERVOIR_MODEL}
      deleteMutation={DELETE_RESERVOIR_MODEL}
      refetchQueries={refetchQueries}
    />
  );
}
