import type { PureQueryOptions } from '@apollo/client';
import type {
  KeyParametersParent,
  KeyParametersPartsFragment,
} from '~/apollo/generated/schema';
import { CreateKeyParameters } from './CreateKeyParameters';
import { CurrentKeyParameters } from './CurrentKeyParameters';

type Props = {
  parentType: KeyParametersParent;
  parentId: number;
  geologyTypes: string[];
  keyParameters?: Omit<KeyParametersPartsFragment, '__typename'>[];
  refetchQueries?: PureQueryOptions[];
  disabled?: boolean;
};

export function KeyParametersUpload({
  parentType,
  parentId,
  geologyTypes,
  keyParameters = [],
  refetchQueries,
  disabled,
}: Props) {
  if (!geologyTypes.length) {
    return (
      <p style={{ marginBottom: 0 }}>
        A geology type is required before Key Parameters are available.
      </p>
    );
  }

  return (
    <div className="space-y-6">
      <CreateKeyParameters
        parentType={parentType}
        parentId={parentId}
        geologyTypes={geologyTypes}
        refetchQueries={refetchQueries}
        disabled={disabled}
      />

      <CurrentKeyParameters
        keyParameters={keyParameters}
        refetchQueries={refetchQueries}
        disabled={disabled}
      />
    </div>
  );
}
