import { faMagnifyingGlass, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import type { OutcropOverviewTabQuery } from '~/apollo/generated/schema';
import { Role } from '~/apollo/generated/schema';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import { studyRoute, uploadStudyUpdateRoute } from '~/paths';

type OutcropStudy =
  OutcropOverviewTabQuery['outcropList'][number]['studies'][number];

type Props = {
  studies: OutcropStudy[];
};

export function OutcropStudyLinks({ studies }: Props) {
  function collectionText(study: OutcropStudy): string | null {
    const cb = study.dataHistory?.collectedBy;
    const pt = study.qualityParameters?.publicationType?.join('/');

    if (cb && pt) return `${cb}, ${pt}`;
    return cb || pt || null;
  }

  return (
    <div className="space-y-2">
      {studies.map((study: OutcropStudy) => (
        <Panel key={study.id}>
          <Panel.Body className="space-y-4">
            <div>
              <Heading level={5}>
                {study.name}
                <RoleSecured roles={[Role.RoleAdmin]}>
                  <Link
                    to={uploadStudyUpdateRoute(study.id)}
                    target="_blank"
                    className="badge badge-ghost ml-2 gap-1 font-body"
                  >
                    <FontAwesomeIcon icon={faPencil} /> {study.id}
                  </Link>
                </RoleSecured>
              </Heading>

              {collectionText(study) && <p>{collectionText(study)}</p>}
            </div>

            <Link
              to={studyRoute(study.id)}
              className="btn btn-primary btn-sm gap-1"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} /> View
            </Link>
          </Panel.Body>
        </Panel>
      ))}
    </div>
  );
}
