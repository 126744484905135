import type { PureQueryOptions } from '@apollo/client';
import { useMutation , gql } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { bookmarkCollectionParts } from '~/apollo/fragments';
import type {
  CreateBookmarkCollectionMutation,
  CreateBookmarkCollectionMutationVariables,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import { bookmarkCollectionRoute } from '~/paths';
import type { BookmarkCollectionFormValues } from '~/utils/modules/bookmark';
import {
  bookmarkCollectionValidationSchema,
  initialBookmarkCollection,
  toBookmarkCollectionInput,
} from '~/utils/modules/bookmark';
import { BookmarkCollectionFormFields } from './BookmarkCollectionFormFields';

export const CREATE_BOOKMARK_COLLECTION = gql`
  mutation CreateBookmarkCollection(
    $collection: BookmarkCollectionInput!
    $isCompany: Boolean!
  ) {
    createBookmarkCollection(collection: $collection, isCompany: $isCompany) {
      ...bookmarkCollectionParts
    }
  }

  ${bookmarkCollectionParts}
`;

type Props = {
  children: (showModal: () => void) => React.ReactNode;
  isCompany?: boolean;
  refetchQueries: PureQueryOptions[];
};

const CreateCollectionModal: React.FC<Props> = ({
  children,
  isCompany = false,
  refetchQueries,
}) => {
  const navigate = useNavigate();
  const { show, showModal, hideModal } = useModalState();
  const [createCollection, { loading, error }] = useMutation<
    CreateBookmarkCollectionMutation,
    CreateBookmarkCollectionMutationVariables
  >(CREATE_BOOKMARK_COLLECTION, {
    refetchQueries,
  });

  async function handleSubmit(values: BookmarkCollectionFormValues) {
    // Create the new collection
    let createdCollectionId: number;
    try {
      const createRes = await createCollection({
        variables: {
          collection: toBookmarkCollectionInput(values),
          isCompany,
        },
      });
      if (!createRes.data) {
        console.log('Response:', createRes);
        throw new Error('Error parsing create collection response');
      }
      createdCollectionId = createRes.data.createBookmarkCollection.id;
      hideModal();
      toast.success('Collection created successfully.');
      navigate(bookmarkCollectionRoute(createdCollectionId));
    } catch (err) {
      console.log('Error creating bookmark collection', err);
      toast.error(
        'There was a problem creating the collection, please reload the page and try again.',
      );
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialBookmarkCollection()}
          validationSchema={bookmarkCollectionValidationSchema}
        >
          <Form>
            <Modal.Body
              heading={`Create ${isCompany ? 'Company ' : ''}Collection`}
            >
              {isCompany && (
                <p>
                  Choose a name for your company's new bookmark collection. Once
                  created, any bookmarks shared within your company can be added
                  to the collection. The collection will be visible to all
                  members of your company.
                </p>
              )}
              {!isCompany && (
                <p>
                  Choose a name for your new bookmark collection. Once created,
                  any of your personal bookmarks can be added to it.
                </p>
              )}

              <BookmarkCollectionFormFields disabled={loading} />

              <FormErrors graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" loading={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default CreateCollectionModal;
