import { Link } from 'react-router-dom';

import { useAuth } from '~/contexts/auth';
import { dashboardRoute } from '~/paths';
import { LoginButton } from '~/components/layout/Navbar/LoginButton';

export function Hero() {
  const { isAuthenticated } = useAuth();

  return (
    <div
      className="absolute inset-0 text-center bg-cover bg-no-repeat h-[90vh] md:h-[75vh]"
      style={{
        backgroundPosition: 'center top', // This is apparently not in tailwind?
        backgroundImage: "url('/assets/images/1_BookCliffs.jpg')",
      }}
    >
      <div className="w-full h-full flex items-center justify-center bg-gradient-to-t from-[#00000080] to-transparent pt-20">
        <div>
          <div className="font-display font-bold text-4xl text-white shadow-lg">
            SafariDB
          </div>
          <div className="font-display font-semibold text-2xl text-white shadow-lg">
            The Geological Outcrop Database
          </div>

          {isAuthenticated ? (
            <Link
              to={dashboardRoute()}
              className="btn btn-primary my-10 shadow-xl"
            >
              My Dashboard
            </Link>
          ) : (
            <LoginButton className="btn btn-primary my-10 shadow-xl" />
          )}
        </div>
      </div>
    </div>
  );
}
