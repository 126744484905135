import { gql, useQuery } from '@apollo/client';
import type {
  OutcropAuditLogsQuery,
  OutcropAuditLogsQueryVariables,
} from '~/apollo/generated/schema';
import {
  AuditLogTable,
  auditLogTableFragment,
} from '~/components/auditLogs/AuditLogTable';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

const OUTCROP_AUDIT_LOGS = gql`
  query OutcropAuditLogs($id: Int!) {
    outcropList(id: $id) {
      id
      auditLogs {
        ...auditLogTableFragment
      }
    }
  }

  ${auditLogTableFragment}
`;

export default function OutcropAuditLogsRoute() {
  const { outcrop } = useUploadOutcropUpdateRouteOutletContext();

  const { data, loading } = useQuery<
    OutcropAuditLogsQuery,
    OutcropAuditLogsQueryVariables
  >(OUTCROP_AUDIT_LOGS, { variables: { id: outcrop.id } });

  const queryOutcrop = data?.outcropList.find(oc => oc.id === outcrop.id);
  const auditLogs = queryOutcrop?.auditLogs ?? [];

  if (loading) return <SpinnerPlaceholder />;

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Audit Logs</Panel.Title>
      </Panel.Heading>

      <Panel.Body>
        <AuditLogTable
          auditLogs={auditLogs}
          sortStorageName="outcropAuditLogs"
        />
      </Panel.Body>
    </Panel>
  );
}
