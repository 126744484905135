import type { PureQueryOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import type { ReactNode } from 'react';
import { toast } from 'react-toastify';
import type {
  DeleteMachineTokenMutation,
  DeleteMachineTokenMutationVariables,
} from '~/apollo/generated/schema';

const DELETE_MACHINE_TOKEN = gql`
  mutation DeleteMachineToken($id: Int!) {
    deleteMachineToken(id: $id)
  }
`;

export function DeleteMachineToken({
  tokenId,
  refetchQueries,
  children,
}: {
  tokenId: number;
  refetchQueries: PureQueryOptions[];
  children: (deleteToken: () => Promise<void>, loading: boolean) => ReactNode;
}) {
  const [deleteToken, { loading }] = useMutation<
    DeleteMachineTokenMutation,
    DeleteMachineTokenMutationVariables
  >(DELETE_MACHINE_TOKEN, {
    variables: { id: tokenId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteToken();
      toast.success('Machine token deactivated successfully.');
    } catch (err) {
      toast.error(
        'There was a problem deleting the token; it may have already been deleted. Please go back and try again.',
      );
    }
  }

  return children(handleDelete, loading);
}
