import { gql, useQuery } from '@apollo/client';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decode } from 'he';
import * as R from 'ramda';
import React, { useState } from 'react';
import { Button, Tooltip } from 'react-daisyui';
import { Link } from 'react-router-dom';
import * as fragments from '~/apollo/fragments';
import type {
  OutcropStudiesTabQuery,
  OutcropStudiesTabQueryVariables,
} from '~/apollo/generated/schema';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { studyRoute } from '~/paths';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';
import { stripHtml, truncate, ucwords } from '~/utils/text';

const STUDIES_TAB = gql`
  query OutcropStudiesTab($outcropId: Int!) {
    studyList(outcropId: $outcropId) {
      ...studyParts
      qualityParameters {
        ...studyQualityParametersParts
      }
      dataHistory {
        ...dataHistoryParts
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.studyQualityParametersParts}
  ${fragments.dataHistoryParts}
`;

export default function OutcropStudiesRoute() {
  const ctx = useOutcropOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    OutcropStudiesTabQuery,
    OutcropStudiesTabQueryVariables
  >(STUDIES_TAB, {
    variables: { outcropId },
  });

  const studies = R.sortBy(R.prop('name'), data?.studyList ?? []);

  if (loading) return <SpinnerPlaceholder />;

  return (
    <div className="space-y-4">
      {studies.map(study => (
        <StudyListItem key={study.id} study={study} />
      ))}

      {studies.length === 0 && (
        <p>No studies have been added to this outcrop yet.</p>
      )}
    </div>
  );
}

type StudyListItemProps = {
  study: OutcropStudiesTabQuery['studyList'][number];
};
function StudyListItem({ study }: StudyListItemProps) {
  const [showAbstract, setShowAbstract] = useState(false);

  const toggleShow = () => setShowAbstract(prev => !prev);

  const year = study.dataHistory?.date ?? '';

  const ReadMoreButton = (
    <Link to={studyRoute(study.id)} className="link">
      ...read more
    </Link>
  );

  const abstract: React.ReactNode = R.pipe(
    R.pathOr<string>('', ['abstract']),
    stripHtml,
    decode,
    truncate(300, true, ReadMoreButton),
  )(study);

  return (
    <Panel>
      <Panel.Body>
        <div className="grid lg:grid-cols-12 gap-6">
          <div className="lg:col-span-5">
            <h4 style={{ margin: 0 }}>
              <Link to={studyRoute(study.id)} className="link">
                {study.name}
              </Link>
            </h4>
          </div>
          <div className="lg:col-span-1">{year}</div>
          <div className="lg:col-span-2">{study.dataHistory?.collectedBy}</div>
          <div className="lg:col-span-2">
            {!!study.qualityParameters?.publicationType?.length &&
              study.qualityParameters.publicationType
                // Don't show undefined/unknown
                .filter(pt => !['undefined', 'unknown'].includes(pt))
                .map(ucwords)
                .join(', ')}
          </div>
          <div className="lg:col-span-2 text-right">
            <Link
              to={studyRoute(study.id)}
              className="btn btn-sm btn-primary gap-1"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} /> View
            </Link>
          </div>
        </div>
      </Panel.Body>

      {!R.isNil(study.abstract) && (
        <Panel.Footer>
          <Tooltip message="Read this study's abstract">
            <Button
              type="button"
              color="ghost"
              size="xs"
              onClick={toggleShow}
              className="gap-1"
            >
              Abstract <ExpandedIcon expanded={showAbstract} />
            </Button>
          </Tooltip>
          <div className={showAbstract ? 'block text-base' : 'hidden'}>
            {abstract}
          </div>
        </Panel.Footer>
      )}
    </Panel>
  );
}
