import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import * as R from 'ramda';
import * as fragments from '~/apollo/fragments';
import type {
  StudyPanoramasRouteQuery,
  StudyPanoramasRouteQueryVariables,
} from '~/apollo/generated/schema';
import {
  BookmarkParentType,
  BookmarkTargetType,
} from '~/apollo/generated/schema';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { GigaPanViewer } from '~/components/supportingObject/GigaPanViewer';
import { PanoramaListItem } from '~/components/supportingObject/PanoramaListItem';
import { outcropPanoramasTabRoute, studyPanoramasTabRoute } from '~/paths';
import { useStudyOutletContext } from '~/routes/study/$studyId';

const STUDY_PANORAMAS_ROUTE = gql`
  query StudyPanoramasRoute($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      gigaPans {
        ...gigaPanParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.gigaPanParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function StudyPanoramasRoute() {
  const ctx = useStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    StudyPanoramasRouteQuery,
    StudyPanoramasRouteQueryVariables
  >(STUDY_PANORAMAS_ROUTE, { variables: { studyId } });

  const refetchQueries: PureQueryOptions[] = [
    { query: STUDY_PANORAMAS_ROUTE, variables: { studyId } },
  ];

  const study = data?.studyList.find(s => s.id === studyId);

  const panoramas = R.pipe(
    () => study?.gigaPans ?? [],
    R.sortBy(gp => gp.priority ?? Infinity),
  )();

  const panoramaPictures = (panorama: (typeof panoramas)[number]) =>
    R.sortBy(p => p.priority ?? Infinity, panorama.pictures);

  const hasGigaPan = !!panoramas.find(p => !!p.gigaPanHash);

  if (loading) return <SpinnerPlaceholder />;

  return (
    <div className="space-y-4">
      {hasGigaPan && (
        <p>
          Note: Please be aware that the Gigapans load extremely slowly because
          they are embedded externally from the Gigapan website. A better
          hosting solution based on the Safari database is under discussion.
        </p>
      )}

      {panoramas.map(p => (
        <div key={p.id}>
          <GigaPanViewer
            hash={p.gigaPanHash}
            bookmarkable={{
              parentType: BookmarkParentType.Study,
              parentId: studyId,
              targetType: BookmarkTargetType.GigaPan,
              targetId: p.id,
              path: p.outcropTagId
                ? outcropPanoramasTabRoute(p.outcropTagId)
                : studyPanoramasTabRoute(studyId),
            }}
          />
          <PanoramaListItem
            pictures={panoramaPictures(p)}
            bookmarkable={{
              parentType: BookmarkParentType.GigaPan,
              parentId: p.id,
              path: p.outcropTagId
                ? outcropPanoramasTabRoute(p.outcropTagId)
                : studyPanoramasTabRoute(studyId),
            }}
            refetchQueries={refetchQueries}
          />
        </div>
      ))}
    </div>
  );
}
