import invariant from 'tiny-invariant';
import { GroupSidebar } from '~/components/wiki/lithostrat/GroupSidebar';
import { WikiDescriptionText } from '~/components/wiki/lithostrat/WikiPageDescriptionText';
import { useGroupDetailOutletContext } from '~/routes/wiki/regional/lithostrat/group.$groupId';

export default function LithostratGroupIndexRoute() {
  const { group } = useGroupDetailOutletContext();
  invariant(group.wikiPage, 'Wiki page does not exist or is not published');

  return (
    <div className="grid lg:grid-cols-12 gap-6">
      <div className="lg:col-span-7">
        <WikiDescriptionText wiki={group.wikiPage} />
      </div>

      <div className="lg:col-span-5">
        <GroupSidebar group={group} />
      </div>
    </div>
  );
}
