import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';

export function ReportFormFields({ disabled }: { disabled: boolean }) {
  return (
    <div className="space-y-2">
      <Field
        name="report.title"
        label="Title"
        component={FormikField}
        type="text"
        disabled={disabled}
        required
      />
      <Field
        name="report.description"
        label="Description"
        component={FormikField}
        type="textarea"
        disabled={disabled}
      />
    </div>
  );
}
