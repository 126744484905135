import { faPaperPlane, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { QueueableMultipartUpload } from '~/components/upload/file/QueueableMultipartUpload';
import type {
  CompletedFilePart,
  UseUploadQueueConfig,
} from '~/components/upload/file/QueueableMultipartUpload/useUploadQueue';
import { queryClient } from '~/main';
import {
  getGetApiV4CesiumAssetsIdListStagedFilesQueryKey,
  getGetApiV4VirtualOutcropModelsIdQueryKey,
  postApiV4CesiumAssetsIdMultipartAbort,
  postApiV4CesiumAssetsIdMultipartComplete,
  postApiV4CesiumAssetsIdMultipartStart,
  useGetApiV4CesiumAssetsIdListStagedFiles,
  usePatchApiV4CesiumAssetsIdDeleteStagedFile,
  usePatchApiV4CesiumAssetsIdUploadToCesium,
} from '~/openapi/api-v4';

const initializeUploadFn = (cesiumAssetId: string) => async (file: File) => {
  try {
    const res = await postApiV4CesiumAssetsIdMultipartStart(cesiumAssetId, {
      data: {
        content_type: file.type,
        file_name: file.name,
      },
    });
    return { token: res.token, uploadPath: res.upload_path };
  } catch (err) {
    console.log(err);
    throw new Error('Error initiating multipart upload');
  }
};

const completeUploadFn =
  (cesiumAssetId: string) =>
  async (token: string, parts: CompletedFilePart[]) => {
    await postApiV4CesiumAssetsIdMultipartComplete(cesiumAssetId, {
      data: { token, parts },
    });
  };

const abortUploadFn = (cesiumAssetId: string) => async (token: string) => {
  await postApiV4CesiumAssetsIdMultipartAbort(cesiumAssetId, {
    data: { token },
  });
};

export function InitializedState({
  vomId,
  cesiumAssetId,
}: {
  vomId: string;
  cesiumAssetId: string;
}) {
  const { data, isLoading, refetch } =
    useGetApiV4CesiumAssetsIdListStagedFiles(cesiumAssetId);

  const deleteMutation = usePatchApiV4CesiumAssetsIdDeleteStagedFile();

  const uploadConfig: UseUploadQueueConfig = {
    initializeUploadFn: initializeUploadFn(cesiumAssetId),
    completeUploadFn: completeUploadFn(cesiumAssetId),
    abortUploadFn: abortUploadFn(cesiumAssetId),
    onItemCompleted: refetch,
  };

  async function handleDeleteFile(filename: string) {
    const confirmMsg = 'Are you you sure you want to delete this staged file?';
    if (!window.confirm(confirmMsg)) return;

    try {
      await deleteMutation.mutateAsync(
        {
          id: cesiumAssetId,
          data: {
            data: {
              file_name: filename,
            },
          },
        },
        {
          onSuccess() {
            queryClient.invalidateQueries({
              queryKey:
                getGetApiV4CesiumAssetsIdListStagedFilesQueryKey(cesiumAssetId),
            });
          },
        },
      );
    } catch (err) {}
  }

  const currentFiles = data ?? [];

  return (
    <div className="space-y-6">
      <div className="grid lg:grid-cols-3 gap-6">
        <div>
          <Panel>
            <Panel.Heading>
              <Panel.Title>Uploaded Files</Panel.Title>
            </Panel.Heading>
            <Panel.Body className="p-2">
              <SpinnerPlaceholder show={isLoading} />

              <CurrentFileList
                files={currentFiles}
                deleteFile={handleDeleteFile}
              />

              {currentFiles.length > 0 && (
                <div className="text-center">
                  <SendToCesium vomId={vomId} cesiumAssetId={cesiumAssetId} />
                </div>
              )}
            </Panel.Body>
          </Panel>
        </div>

        <div className="col-span-2">
          <QueueableMultipartUpload uploadConfig={uploadConfig} />
        </div>
      </div>
    </div>
  );
}

function CurrentFileList({
  files,
  deleteFile,
}: {
  files: string[];
  deleteFile: (filename: string) => Promise<void>;
}) {
  return (
    <div>
      {!files.length && (
        <div className="text-center my-3 text-muted italic">
          No files uploaded yet.
        </div>
      )}

      {files.map((f, i) => (
        <div
          key={i}
          className={cn('my-1 py-1 w-full flex justify-between gap-2', {
            'border-b border-b-slate-50': i < files.length - 1,
          })}
        >
          <div className="grow grid grid-cols-12 gap-2">
            <div className="col-span-2 text-sm px-2 pt-[3px] text-slate-400 text-right">
              {i + 1}
            </div>
            <div className="col-span-10 text-left text-slate-800 break-all">
              {f}
            </div>
          </div>

          <div className="shrink">
            <Button
              type="button"
              onClick={() => deleteFile(f)}
              color="ghost"
              size="xs"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}

function SendToCesium({
  vomId,
  cesiumAssetId,
}: {
  vomId: string;
  cesiumAssetId: string;
}) {
  const sendMutation = usePatchApiV4CesiumAssetsIdUploadToCesium();

  async function handleSend() {
    try {
      await sendMutation.mutateAsync(
        {
          id: cesiumAssetId,
          data: { data: { id: cesiumAssetId } },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: getGetApiV4VirtualOutcropModelsIdQueryKey(vomId),
            });
          },
        },
      );
    } catch (err) {
      console.log('Error sending to cesium', err);
      toast.error(
        'There was an error sending to Cesium. Are all the required files uploaded?',
      );
    }
  }

  return (
    <Button
      type="button"
      onClick={handleSend}
      startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
      color="primary"
      size="sm"
    >
      Send to Cesium
    </Button>
  );
}
