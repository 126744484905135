import { faFolder, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { HTMLProps } from 'react';
import { cn } from '~/utils/common';

type BookmarkCollectionIconProps =
  | ({ isCompany?: false } & Omit<FontAwesomeIconProps, 'icon'>)
  | ({ isCompany: true } & HTMLProps<HTMLDivElement>);

export function BookmarkCollectionIcon(props: BookmarkCollectionIconProps) {
  if (props.isCompany) {
    const { isCompany, ...containerProps } = props;
    return <BookmarkCollectionIconCompany {...containerProps} />;
  } else {
    const { isCompany, ...iconProps } = props;
    return (
      <FontAwesomeIcon
        {...iconProps}
        className={cn('text-sky-500', props.className)}
        icon={faFolder}
      />
    );
  }
}

export function BookmarkCollectionIconCompany({
  className,
  ...props
}: HTMLProps<HTMLDivElement>) {
  return (
    <span className={cn('relative', className)} {...props}>
      <FontAwesomeIcon icon={faFolder} className="text-wine-500" />
      <FontAwesomeIcon
        icon={faPeopleGroup}
        className="absolute text-inherit text-wine-200 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-50"
      />
    </span>
  );
}
