import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  CompanyPartsFragment,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
} from '~/apollo/generated/schema';
import { UPDATE_COMPANY } from '~/apollo/operations/company';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { CompanyOptionsFields } from '~/components/company/CompanyOptionsFields';
import type { CompanyFormValues } from '~/utils/modules/company';
import {
  companyValidationSchema,
  initialCompanyValues,
} from '~/utils/modules/company';

type Props = {
  company: CompanyPartsFragment;
};

export function UpdateCompanyPanel({ company }: Props) {
  const [updateCompany, { loading, error }] = useMutation<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >(UPDATE_COMPANY, {});

  async function handleSubmit(values: CompanyFormValues) {
    try {
      await updateCompany({
        variables: {
          id: company.id,
          company: values,
        },
      });
      toast.success('Company updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('There was a problem updating the company.');
    }
  }

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Company Options</Panel.Title>
      </Panel.Heading>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialCompanyValues(company)}
        validationSchema={companyValidationSchema}
      >
        <Form>
          <Panel.Body>
            <CompanyOptionsFields isSSO={company.isAadConfigured} />
            <FormErrors graphQLError={error} />
          </Panel.Body>
          <Panel.Footer>
            <div className="text-right">
              <Button type="submit" color="primary" disabled={loading}>
                Save Changes
              </Button>
            </div>
          </Panel.Footer>
        </Form>
      </Formik>
    </Panel>
  );
}
