import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import React from 'react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/common/Popover';

type Props = {
  name: React.ReactNode;
  options: string[];
  values: string[];
  onChange: (value: string) => void;
  renderFilterOption?: (value: string) => React.ReactNode;
};

const FilterButton: React.FC<Props> = ({
  name,
  options,
  values,
  onChange,
  renderFilterOption = val => val,
}: Props) => {
  const hasFilters = values.length > 0;

  return (
    <>
      <Popover>
        <PopoverTrigger
          className="btn btn-ghost btn-sm"
          style={{ padding: '0 1px' }}
        >
          <FontAwesomeIcon
            icon={faFilter}
            className={cn('text-sm', {
              'text-error': hasFilters,
            })}
          />
        </PopoverTrigger>

        <PopoverContent side="bottom" className="max-h-72 overflow-scroll">
          {options.map(opt => (
            <div key={opt} className="form-control">
              <label className="label cursor-pointer justify-start">
                <input
                  type="checkbox"
                  value={opt}
                  onChange={e => onChange(e.target.value)}
                  checked={values.includes(opt)}
                  className="checkbox"
                />
                <span className="label-text ml-2">
                  {renderFilterOption(opt)}
                </span>
              </label>
            </div>
          ))}
        </PopoverContent>
      </Popover>
    </>
  );
};

export default FilterButton;
