import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';

export function ItemFormFieldsText() {
  return (
    <div className="space-y-2">
      <Field name="title" label="Title" component={FormikField} required />
      <Field name="text" label="Text" component={FormikField} type="textarea" />
    </div>
  );
}
