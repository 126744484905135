import type { PureQueryOptions } from '@apollo/client';
import { useMutation , gql } from '@apollo/client';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import * as R from 'ramda';
import React from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';

import type {
  RefreshSavedDataSearchMutation,
  RefreshSavedDataSearchMutationVariables,
} from '~/apollo/generated/schema';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import { savedDataSearchRevisionRoute } from '~/paths';

const REFRESH_SAVED_DATA_SEARCH = gql`
  mutation RefreshSavedDataSearch($searchId: Int!) {
    refreshSavedDataSearch(id: $searchId) {
      id
      data {
        id
        revision
      }
    }
  }
`;

type Props = {
  children: (showModal: () => void) => JSX.Element;
  searchId: number;
  refetchQueries: PureQueryOptions[];
};

export function UpdateDataModal({ children, searchId, refetchQueries }: Props) {
  const { show, showModal, hideModal } = useModalState();
  const navigate = useNavigate();

  const [refreshSearch, { loading }] = useMutation<
    RefreshSavedDataSearchMutation,
    RefreshSavedDataSearchMutationVariables
  >(REFRESH_SAVED_DATA_SEARCH, {
    variables: { searchId },
    refetchQueries,
  });

  async function handleUpdate() {
    try {
      const result = await refreshSearch();
      const refreshResult = result.data?.refreshSavedDataSearch.data ?? [];
      const sortedData = R.sortWith(
        [R.descend(R.prop('revision'))],
        refreshResult,
      );
      const latestData = sortedData[0];
      if (!latestData) throw new Error('Could not parse refresh response');
      navigate(savedDataSearchRevisionRoute(searchId, latestData.revision));
      toast.success('Search updated successfully and saved to a new revision.');
      hideModal();
    } catch (err) {
      console.log('Error refreshing sds', err);
      toast.error(
        'There was a problem creating a new revision. Please refresh the page and try again.',
      );
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Modal.Body heading="Update Data">
          <p>
            This process will perform a new data search with the given filters
            to look for any new measurements that have been added to SafariDB.
            The resulting data snapshot will be saved into a new, timestamped
            revision.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            color="ghost"
            onClick={hideModal}
            disabled={loading}
          >
            Cancel
          </Button>

          <Button
            type="button"
            color="primary"
            onClick={handleUpdate}
            loading={loading}
            className="gap-1"
          >
            <FontAwesomeIcon icon={faRefresh} /> Create new revision
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
