import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';

import * as fragments from '~/apollo/fragments';
import type {
  UploadStudyUpdatePicturesTabQuery,
  UploadStudyUpdatePicturesTabQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportingObjectPictureList } from '~/components/upload/supportingObject/SupportingObjectPictureList';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';
import { PictureParent } from '~/apollo/generated/schema';
import { useState } from 'react';
import { Badge } from 'react-daisyui';

const PICTURES_TAB = gql`
  query UploadStudyUpdatePicturesTab($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      pictures {
        ...pictureParts
        file {
          ...fileParts
        }
        outcropTag {
          ...outcropParts
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.outcropParts}
`;

export default function UpdateStudyPicturesRoute() {
  const ctx = useUpdateStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    UploadStudyUpdatePicturesTabQuery,
    UploadStudyUpdatePicturesTabQueryVariables
  >(PICTURES_TAB, {
    variables: { studyId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadStudyUpdatePicturesTabQueryVariables>,
  ] = [
    {
      query: PICTURES_TAB,
      variables: { studyId },
    },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const pictures = study?.pictures ?? [];

  const unpublishedCount =
    pictures.reduce((acc, cur) => acc + (cur.published ? 0 : 1), 0) ?? 0;

  const [showUnpublished, setShowUnpublished] = useState(!unpublishedCount);

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <>
      <div className="form-control items-end">
        <label
          htmlFor="showUnpublished"
          className="label justify-start gap-2 cursor-pointer"
        >
          <input
            type="checkbox"
            id="showUnpublished"
            checked={showUnpublished}
            onChange={() => setShowUnpublished(!showUnpublished)}
            className="checkbox checkbox-sm"
            disabled={!unpublishedCount}
          />
          Show unpublished items{' '}
          <Badge color="ghost" className="font-mono">
            {unpublishedCount}
          </Badge>
        </label>
      </div>

      <SupportingObjectPictureList
        parentType={PictureParent.Study}
        parentId={studyId}
        pictures={pictures}
        refetchQueries={refetchQueries}
        showUnpublished={showUnpublished}
      />
    </>
  );
}
