import { userParts } from '~/apollo/fragments';
import { gql } from '@apollo/client';

export const CONFIRM_NEW_USERNAME = gql`
  mutation ConfirmNewUsername($hash: String!) {
    confirmNewUsername(hash: $hash)
  }
`;

export const SET_USER_ENABLED = gql`
  mutation SetUserEnabled($userId: Int!, $enabled: Boolean!) {
    setUserEnabled(id: $userId, enabled: $enabled) {
      ...userParts
    }
  }

  ${userParts}
`;

export const SET_USER_ACCOUNT_LOCKED = gql`
  mutation SetUserAccountLocked($userId: Int!, $accountLocked: Boolean!) {
    setUserAccountLocked(id: $userId, accountLocked: $accountLocked) {
      ...userParts
    }
  }

  ${userParts}
`;

export const SET_USER_COMPANY_ADMIN = gql`
  mutation SetUserCompanyAdmin($userId: Int!, $isCompanyAdmin: Boolean!) {
    setUserCompanyAdmin(id: $userId, isCompanyAdmin: $isCompanyAdmin) {
      ...userParts
    }
  }

  ${userParts}
`;
