import React from 'react';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import type {
  AnalogueSearchFormValues,
  AnalogueSearchOptionItem,
} from '~/utils/modules/analogueSearch';
import { OptionLabel } from './OptionLabel';

interface Props {
  name: keyof AnalogueSearchFormValues;
  options: AnalogueSearchOptionItem[];
}

const SelectAllOption: React.FC<Props> = ({ name, options }: Props) => {
  const { values, setFieldValue } =
    useFormikContext<AnalogueSearchFormValues>();
  if (!options.length) return null;

  const validOpts = options.map(opt => opt.name);
  const selectedOpts = values[name] as string[];

  const isAllGoodItemsSelected =
    R.symmetricDifference(validOpts, selectedOpts).length === 0;
  const isBadItemSelected = R.any(
    R.complement(R.contains(R.__, validOpts)),
    selectedOpts,
  );

  const allSelected =
    values[name].length > 0 && (isAllGoodItemsSelected || isBadItemSelected);

  function valueMap(option: AnalogueSearchOptionItem) {
    return option.name;
  }

  function handleChange() {
    if (allSelected) {
      setFieldValue(name, []);
    } else {
      setFieldValue(name, options.map(valueMap));
    }
  }

  return (
    <div className="form-control border-b border-b-base-200 pb-1 mb-1">
      <label className="label cursor-pointer justify-start items-start gap-2 py-1">
        <input
          type="checkbox"
          value={1}
          onChange={handleChange}
          checked={allSelected}
          className="checkbox checkbox-sm"
        />
        <OptionLabel
          option={{ name: allSelected ? 'Deselect all' : 'Select all' }}
        />
      </label>
    </div>
  );
};

export default SelectAllOption;
