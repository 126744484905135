import { FastField, Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { FormikTagsinputField } from '~/components/common/FormikField/FormikTagsinputField';
import { FormikTinyMceField } from '~/components/common/FormikField/FormikTinyMceField';
import { Panel } from '~/components/common/Panel';
import { OutcropSelect } from '~/components/upload/outcrop/OutcropSelect';
import { ProjectSelect } from '~/components/upload/project/ProjectSelect';

export function VomDescriptionForm() {
  return (
    <div className="grid lg:grid-cols-2 gap-6">
      <div className="space-y-6">
        <Panel>
          <Panel.Heading>
            <Panel.Title>General Info</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="space-y-2">
              <FastField
                name="vom.name"
                label="Name"
                component={FormikField}
                required
              />
              <Field
                name="vom.projectId"
                label="Project"
                component={FormikField}
                type={ProjectSelect}
                required
              />
              <Field
                name="vom.outcropId"
                label="Outcrop"
                component={FormikField}
                type={OutcropSelect}
                required
              />
              <FastField
                name="vom.size"
                label="VOM size"
                component={FormikField}
                type="select"
                options={[
                  'small (0-200m)',
                  'medium (200-2000m)',
                  'large (2000+m)',
                ].map(opt => ({ value: opt, label: opt }))}
                required
              />
              <FastField
                name="vom.smallestVisibleFeature"
                label="Smallest visible feature"
                component={FormikField}
                type="select"
                required
                options={[
                  '< 1cm',
                  '1-5 cm',
                  '5-20 cm',
                  '20-50 cm',
                  '> 50cm',
                ].map(opt => ({ value: opt, label: opt }))}
              />
              <FastField
                name="vom.v3GeoId"
                label="V3Geo ID"
                component={FormikField}
                type="number"
                helpText="Enter the ID of the model in V3Geo if applicable. The VO viewer will embed the V3Geo model viewer instead of Safari's."
              />
              <Field
                name="vom.approved"
                label="Approved"
                component={FormikField}
                type="checkbox"
              />
            </div>
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading>
            <Panel.Title>Acquisition and Processing</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="space-y-2">
              <FastField
                name="vom.acquisitionYear"
                label="Acquisition year"
                component={FormikField}
                type="number"
                required
              />
              <FastField
                name="vom.aquiringPerson" // sic
                label="Acquiring person and institute"
                component={FormikField}
                required
              />
              <FastField
                name="vom.acquisitionType"
                label="Acquisition type"
                component={FormikField}
                type="select"
                required
                options={[
                  'TLS - Terrestrial laser scanning',
                  'HLS - Helicopter-based laser scanning',
                  'MLS - Mobile phone-based laser scanning',
                  'UAV - Drone based photogrammetry',
                  'Photogrammetry',
                  'other',
                  'undefined',
                ].map(opt => ({ value: opt, label: opt }))}
              />
              <FastField
                name="vom.acquisitionEquipment"
                label="Acquisition equipment"
                component={FormikField}
                required
              />
              <FastField
                name="vom.processingYear"
                label="Processing year"
                component={FormikField}
                type="number"
                required
              />
              <FastField
                name="vom.processingPerson"
                label="Processing person and institute"
                component={FormikField}
                required
              />
              <FastField
                name="vom.comments"
                label="Comments"
                component={FormikField}
              />
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className="space-y-6">
        <Panel>
          <Panel.Heading>
            <Panel.Title>Description</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Field
              name="vom.quality"
              label="VOM Quality"
              component={FormikField}
              type={FormikTinyMceField}
            />
            <Field
              name="vom.notableAspects"
              label="Notable aspects"
              component={FormikField}
              type={FormikTinyMceField}
            />
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading>
            <Panel.Title>Author and Copyright</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Field
              name="vom.copyright"
              label="VOM authors who whole copyright"
              component={FormikField}
              type={FormikTagsinputField}
              required
            />
            <Field
              name="vom.copyrightLogo"
              label="Links to logos of copyright holders"
              component={FormikField}
              type={FormikTagsinputField}
            />
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
}
