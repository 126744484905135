import { useMutation , gql } from '@apollo/client';
import { toast } from 'react-toastify';

import { Confirm } from '~/components/common/Confirm';

const DELETE_DEPOSITIONAL_WIKI = gql`
  mutation DeleteDepositionalWiki($id: Int!) {
    deleteDepositionalWiki(id: $id)
  }
`;

type Props = {
  id: number;
  onDeleteSuccess: () => void;
  children: (onDelete: () => void, loading: boolean) => JSX.Element;
};

export function DeleteDepositionalWiki({
  id,
  onDeleteSuccess,
  children,
}: Props) {
  const [deleteDepositionalWiki, { loading }] = useMutation(
    DELETE_DEPOSITIONAL_WIKI,
    {
      variables: { id },
    },
  );

  async function handleDelete() {
    try {
      await deleteDepositionalWiki();
      toast.info('Wiki deleted successfully.');
      onDeleteSuccess();
    } catch (e) {
      console.log('Error deleting depositional wiki:', e);
      toast.error(
        'There was a problem deleting the wiki page. Please reload the page and try again.',
      );
    }
  }

  return (
    <Confirm
      text="Deleting the wiki page will also destroy its associated diagram."
      onConfirm={handleDelete}
    >
      {onConfirm => children(onConfirm, loading)}
    </Confirm>
  );
}
