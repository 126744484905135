import type { PureQueryOptions } from '@apollo/client';
import * as R from 'ramda';
import React, { useState } from 'react';
import { Button } from 'react-daisyui';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import type { PictureParent } from '~/apollo/generated/schema';
import { DropzoneContainer } from '~/components/common/DropzoneContainer';
import { PictureUploadQueueItem } from '~/components/upload/file/PictureUploadQueueItem';
import { useRefetchQueries } from '~/hooks/apollo';

const maxLimitWarning = (
  <p className="text-warning">
    The maximum number of uploads for this resource has been reached.
  </p>
);

export type QueueItemStatus = 'pending' | 'uploading' | 'success' | 'failed';

export type QueueItem = {
  file: File;
  status: QueueItemStatus;
  name: string;
  description: string;
  caption: string;
  published: boolean;
};

export type QueueItemEditableField = keyof Pick<
  QueueItem,
  'name' | 'description' | 'caption' | 'status'
>;

export type PictureUploadField = {
  field: QueueItemEditableField;
  label: string;
  required?: boolean;
};

type Props = {
  parentId: number;
  parentType: PictureParent;
  limit?: number;
  fields?: PictureUploadField[];
  refetchQueries?: PureQueryOptions[];
  onUploadSuccess?: () => void;
};

const defaultFields: PictureUploadField[] = [
  { field: 'name', label: 'Name', required: true },
  { field: 'description', label: 'Description', required: true },
];

export function PictureUpload({
  limit,
  parentId,
  parentType,
  fields = defaultFields,
  refetchQueries,
  onUploadSuccess,
}: Props) {
  const [queue, setQueue] = useState<QueueItem[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [refetch] = useRefetchQueries(refetchQueries);

  const handleDrop = (files: File[]) => {
    const newItems: QueueItem[] = files.map(file => ({
      file,
      status: 'pending',
      name: file.name,
      description: file.name,
      caption: '',
      published: true,
    }));

    setQueue(prevQueue => [...prevQueue, ...newItems]);
  };

  const handleFieldChange = <T extends QueueItemEditableField>(
    file: File,
    field: T,
    value: QueueItem[T],
  ) => {
    setQueue(prevQueue =>
      prevQueue.map(item => {
        if (file === item.file) {
          return { ...item, [field]: value };
        }
        return item;
      }),
    );
  };

  const updateQueueItemStatus = (file: File, status: QueueItemStatus) => {
    console.log(`updating ${file.name} status to: ${status}`);
    handleFieldChange(file, 'status', status);
  };

  const handleRemove = (file: File) => {
    setQueue(prevQueue => prevQueue.filter(item => item.file !== file));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const pendingItems = queue.filter(item => item.status === 'pending');

    setIsUploading(true);

    for (const item of pendingItems) {
      try {
        updateQueueItemStatus(item.file, 'uploading');

        const body = new FormData();
        body.append('parentType', parentType);
        body.append('parentId', String(parentId));
        body.append('name', item.name.trim());
        body.append('description', item.description.trim());
        body.append('published', 'true');
        body.append('file', item.file);

        const res = await fetch('/api/v3/file/picture', {
          method: 'post',
          body,
        });
        if (!res.ok) {
          throw new Error(await res.json());
        }
        updateQueueItemStatus(item.file, 'success');
      } catch (err) {
        console.log(`Error uploading ${item.name}`, err);
        toast.error(`There was a problem uploading ${item.name}`);
        updateQueueItemStatus(item.file, 'failed');
      }
    }

    await refetch();
    setIsUploading(false);

    if (onUploadSuccess) onUploadSuccess();
  };

  function reachedMaxUploads() {
    if (!limit) return false;

    const pendingItems = queue.filter(item => item.status === 'pending');
    return pendingItems.length > limit;
  }

  const isUploadAllowed =
    !isUploading &&
    !reachedMaxUploads() &&
    R.any(R.propEq('status', 'pending'))(queue);

  return (
    <div>
      <Dropzone accept="image/*" onDropAccepted={handleDrop} multiple>
        {({ getRootProps, getInputProps }) => (
          <DropzoneContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <p style={{ marginBottom: 0 }}>
              Drop files here or click to browse.
            </p>
          </DropzoneContainer>
        )}
      </Dropzone>

      <form onSubmit={handleSubmit}>
        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>Picture:</th>
              <th>Details:</th>
              <th>Status:</th>
            </tr>
          </thead>
          <tbody>
            {queue.map((item, index) => (
              <PictureUploadQueueItem
                key={index}
                item={item}
                fields={fields}
                onFieldChange={handleFieldChange}
                onRemove={handleRemove}
              />
            ))}
          </tbody>
        </table>

        <div className="text-center mt-4">
          <Button type="submit" color="primary" disabled={!isUploadAllowed}>
            Upload
          </Button>

          {reachedMaxUploads() && maxLimitWarning}
        </div>
      </form>
    </div>
  );
}
