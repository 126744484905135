import { Link } from 'react-router-dom';
import * as R from 'ramda';
import type { StudyIndexRouteQuery } from '~/apollo/generated/schema';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { ListGroup } from '~/components/common/ListGroup';
import { Tooltip } from '~/components/common/Tooltip';
import { outcropRoute, outcropVirtualOutcropsTabRoute } from '~/paths';

type StudyOutcrop =
  StudyIndexRouteQuery['studyList'][number]['outcrops'][number];

type Props = {
  outcrops: StudyOutcrop[];
};
export function StudyOutcrops({ outcrops }: Props) {
  const sorted = R.sortBy(R.prop('name'), outcrops);

  return (
    <ListGroup>
      {sorted.map(oc => (
        <ListGroup.Item
          key={oc.id}
          className="flex justify-between items-center gap-6"
        >
          <Link to={outcropRoute(oc.id)} className="link">
            {oc.name}
          </Link>

          {oc.virtualOutcropModels.length > 0 && (
            <div>
              <Tooltip
                message={`View Virtual Outcrop Model${
                  oc.virtualOutcropModels.length === 1 ? '' : 's'
                }`}
              >
                <DropdownMenu
                  label={`View VO${
                    oc.virtualOutcropModels.length === 1 ? '' : 's'
                  }`}
                  color="primary"
                  size="sm"
                >
                  <DropdownMenu.Items>
                    {oc.virtualOutcropModels.map(vom => (
                      <DropdownMenu.Item key={vom.id}>
                        <Link
                          to={outcropVirtualOutcropsTabRoute(oc.id, vom.id)}
                          target="_blank"
                          className="btn btn-ghost btn-block btn-sm"
                        >
                          {vom.name}
                        </Link>
                      </DropdownMenu.Item>
                    ))}
                  </DropdownMenu.Items>
                </DropdownMenu>
              </Tooltip>
            </div>
          )}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}
