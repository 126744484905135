import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { vomParts } from '~/apollo/fragments';
import type * as schema from '~/apollo/generated/schema';

const UPDATE_PRIORITIES = gql`
  mutation UpdateVomPriorities($outcropId: Int!, $priorities: [VomPriority!]!) {
    updateVirtualOutcropModelPriorities(
      outcropId: $outcropId
      priorities: $priorities
    ) {
      ...vomParts
    }
  }

  ${vomParts}
`;

type Props = {
  outcropId: number;
  priorities: schema.VomPriority[];
  children: (update: () => Promise<void>, loading: boolean) => JSX.Element;
};

export function UpdateVomPriorities({
  outcropId,
  priorities,
  children,
}: Props) {
  const [updatePriorities, { loading }] = useMutation<
    schema.UpdateVomPrioritiesMutation,
    schema.UpdateVomPrioritiesMutationVariables
  >(UPDATE_PRIORITIES, {
    variables: { outcropId, priorities },
  });

  async function handleUpdate() {
    try {
      await updatePriorities();
      toast.success('VOM priorities updated successfully.');
    } catch (e) {
      console.log('Error updating priorities', e);
      toast.error(
        'There was a problem updating the priorities. Please try again.',
      );
    }
  }

  return children(handleUpdate, loading);
}
