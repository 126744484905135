import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import * as R from 'ramda';
import { useEffect, useMemo, useRef, useState } from 'react';
import { LegendItemsHiddenNote } from '~/components/statistics/MeasurementGraph/LegendItemsHiddenNote';
import type { HistogramDataPoint } from '~/utils/chart';
import { binData, chartExportOptions, colorByIndex } from '~/utils/chart';
import { dataTypeUnit } from '~/utils/modules/architecturalMeasurement';
import { ucwords } from '~/utils/text';

Highcharts.setOptions({
  lang: {
    thousandsSep: '',
    decimalPoint: '.',
  },
});

if (typeof window !== 'undefined') {
  HighchartsExporting(Highcharts);
  HighchartsExportData(Highcharts);
}

type Props = {
  title?: string;
  data: HistogramDataPoint[];
  xAxisDataType: string;
  numBins?: number | null;
  binWidth?: number | null;
  nice?: boolean;
  expanded?: boolean;
};

export function MeasurementGraphHistogram({
  title,
  data,
  xAxisDataType,
  numBins,
  binWidth,
  nice,
  expanded,
}: Props) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  /** A list of all series items that were toggled invisible on the graph */
  const [itemsHidden, setItemsHidden] = useState<string[]>([]);

  const handleLegendItemClick: Highcharts.SeriesLegendItemClickCallbackFunction =
    event => {
      const { name, visible } = event.target;

      if (visible) {
        // Series is about to be hidden
        setItemsHidden(R.pipe(R.append(name), R.uniq));
      } else {
        // Series is about to be visible again
        setItemsHidden(R.without([name]));
      }
    };

  const formatAxisLabel = (dataType: string) => {
    const unit = dataTypeUnit(dataType) ?? '';
    return `${ucwords(dataType)}${unit ? ` (${unit})` : ''}`;
  };

  // The chart is responsive, but only with window resizing.
  // This manually reflows the chart when the options panel is opened or closed.
  useEffect(() => {
    chartRef?.current?.chart.reflow();
  }, [expanded]);

  const options: Highcharts.Options = useMemo(() => {
    const aes = R.pipe(
      () => data,
      R.map(d => d.label),
      R.flatten,
      R.uniq,
    )();
    const binned = binData(data, numBins, binWidth, nice);

    const series: Highcharts.Options['series'] = aes.map((ae, i) => ({
      type: 'column',
      id: ae,
      name: ae,
      data: R.pluck(ae, binned.data),
      color: colorByIndex(i),
      // boostThreshold: 100,
    }));

    return {
      credits: { enabled: false },
      accessibility: { enabled: false },
      chart: {
        type: 'column',
        style: {
          color: '#555555',
          fontFamily: 'Cabin, sans-serif',
          fontSize: '10pt',
        },
      },
      title: { text: title },
      plotOptions: {
        column: {
          stacking: 'normal',
          groupPadding: 0,
          pointPadding: 0.05,
        },
        series: {
          events: {
            legendItemClick: handleLegendItemClick,
          },
        },
      },
      xAxis: {
        title: {
          text: formatAxisLabel(xAxisDataType),
        },
        gridLineColor: '#dddddd',
        gridLineWidth: 1,
        gridLineDashStyle: 'Dash',
        zoomEnabled: false,
        categories: binned.data.map(bin => String(bin.label)),
        labels: {
          rotation: -45,
        },
      },
      yAxis: {
        title: {
          text: 'Frequency',
        },
        gridLineDashStyle: 'Dash',
        gridLineColor: '#dddddd',
        gridLineWidth: 1,
        zoomEnabled: false,
      },
      legend: {
        itemStyle: {
          color: '#555555',
          fontSize: '9pt',
        },
      },
      series,
      exporting: chartExportOptions,
      // boost: {
      //   seriesThreshold: 4,
      // },
    };
  }, [data, numBins, binWidth, nice, xAxisDataType, title]);

  // When a series is no longer on the graph, remove it from the itemsHidden list
  useEffect(() => {
    const seriesNames = options.series?.map(s => s.name ?? 'unknown') ?? [];
    setItemsHidden(prevItemsHidden =>
      prevItemsHidden.filter(item => seriesNames.includes(item)),
    );
  }, [options.series]);

  return (
    <>
      <div className="w-full h-[600px]">
        <HighchartsReact
          highcharts={Highcharts}
          containerProps={{ className: 'w-full h-full' }}
          options={options}
          ref={chartRef}
          allowChartUpdate
        />
      </div>

      <LegendItemsHiddenNote numHidden={itemsHidden.length} />
    </>
  );
}
