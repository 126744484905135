import { gql, useQuery } from '@apollo/client';
import { faMobileScreen, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Outlet, useOutletContext } from 'react-router-dom';
import type {
  OutcropVomsTabQuery,
  OutcropVomsTabQueryVariables,
} from '~/apollo/generated/schema';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { NavTabs } from '~/components/navigation/NavTabs';
import { outcropVirtualOutcropsTabRoute } from '~/paths';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';
import { isMini } from '~/utils/modules/vom';

const OUTCROP_VOMS_TAB = gql`
  query OutcropVomsTab($outcropId: Int!) {
    virtualOutcropModelList(filterBy: { outcropId: $outcropId }) {
      id
      name
      priority
      interpreted
      acquisitionType
    }
  }
`;

type OutletContext = {
  outcropId: number;
  linkedVoms: OutcropVomsTabQuery['virtualOutcropModelList'];
};

export default function OutcropVomsRoute() {
  const ctx = useOutcropOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    OutcropVomsTabQuery,
    OutcropVomsTabQueryVariables
  >(OUTCROP_VOMS_TAB, {
    variables: { outcropId },
  });

  const vomList = data?.virtualOutcropModelList ?? [];
  const voms = R.sortWith(
    [R.ascend(R.propOr(Infinity, 'priority')), R.ascend(R.prop('name'))],
    vomList,
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!voms.length) {
    return (
      <p>This outcrop does not have any virtual outcrops associated with it.</p>
    );
  }

  const outletContext: OutletContext = {
    outcropId,
    linkedVoms: voms,
  };

  return (
    <>
      {/* Show navigation tabs if more than one VOM */}
      {voms.length > 1 && (
        <NavTabs className="mb-2">
          {voms.map(vom => (
            <NavTabs.Tab
              key={vom.id}
              to={outcropVirtualOutcropsTabRoute(outcropId, vom.id)}
              style={{
                fontWeight: vom.interpreted ? 'bolder' : undefined,
              }}
            >
              {vom.name}
              {vom.interpreted && (
                <Tooltip message="Interpreted">
                  <sup>
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-warning text-sm mx-1"
                    />
                  </sup>
                </Tooltip>
              )}
              {isMini(vom) && (
                <Tooltip message="Mini-Model">
                  <sup>
                    <FontAwesomeIcon
                      icon={faMobileScreen}
                      className="text-warning text-sm mx-1"
                    />
                  </sup>
                </Tooltip>
              )}
            </NavTabs.Tab>
          ))}
        </NavTabs>
      )}

      <Outlet context={outletContext} />
    </>
  );
}

export function useOutcropVomsOutletContext() {
  return useOutletContext<OutletContext>();
}
