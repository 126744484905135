import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import type { ReportVomPartsFragment } from '~/apollo/generated/schema';
import { ExternalVOLink } from '~/components/vom/ExternalVOLink';

type Props = {
  vom: ReportVomPartsFragment;
};

export function ReportItemVom({ vom }: Props) {
  const sortedPics = R.sortBy(pic => pic.priority ?? Infinity, vom.pictures);
  const thumbnail = sortedPics.at(0);

  return (
    <>
      {thumbnail && (
        <div className="thumbnail">
          <img src={thumbnail.file.signedUrl} alt="VOM image" />
          <div className="caption text-center">
            <ExternalVOLink
              vom={vom}
              interpreted={vom.interpreted}
              linkText={
                <div>
                  Open Virtual Outcrop Viewer
                  <FontAwesomeIcon icon={faExternalLink} className="ml-2" />
                </div>
              }
            />
          </div>
        </div>
      )}
    </>
  );
}
