import type { PureQueryOptions } from '@apollo/client';
import { useQuery, gql } from '@apollo/client';
import * as R from 'ramda';

import * as fragments from '~/apollo/fragments';
import type {
  UploadStudyUpdateLiteratureTabQuery,
  UploadStudyUpdateLiteratureTabQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreateLiteratureForm } from '~/components/upload/study/literature/CreateLiteratureForm';
import { StudyLiteratureListItem } from '~/components/upload/study/literature/LiteratureListItem';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

export const LITERATURE_TAB = gql`
  query UploadStudyUpdateLiteratureTab($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      literatureReferences {
        ...literatureParts
        outcropTag {
          ...outcropParts
        }
        files {
          ...fileParts
          signedUrl
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.literatureParts}
  ${fragments.outcropParts}
  ${fragments.fileParts}
`;

export default function UpdateStudyLiteratureRoute() {
  const ctx = useUpdateStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    UploadStudyUpdateLiteratureTabQuery,
    UploadStudyUpdateLiteratureTabQueryVariables
  >(LITERATURE_TAB, {
    variables: { studyId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadStudyUpdateLiteratureTabQueryVariables>,
  ] = [
    {
      query: LITERATURE_TAB,
      variables: { studyId },
    },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const literatureReferences = R.sortBy(
    lr => lr.priority ?? Infinity,
    study?.literatureReferences ?? [],
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <div className="grid lg:grid-cols-12 gap-6">
      <div className="lg:col-span-8 space-y-6">
        {literatureReferences.length === 0 && (
          <h2>No literature references have been added yet</h2>
        )}
        {literatureReferences.map(l => (
          <StudyLiteratureListItem
            key={l.id}
            studyId={studyId}
            literature={l}
            refetchQueries={refetchQueries}
          />
        ))}
      </div>

      <div className="lg:col-span-4">
        <Panel>
          <Panel.Heading>
            <Panel.Title>Create Literature</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <CreateLiteratureForm
              studyId={studyId}
              refetchQueries={refetchQueries}
            />
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
}
