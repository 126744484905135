import type { MutationHookOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { reportItemParts } from '~/apollo/fragments';
import type {
  CreateReportItemMutation,
  CreateReportItemMutationVariables,
  UpdateReportItemMutation,
  UpdateReportItemMutationVariables,
} from '~/apollo/generated/schema';

export const CREATE_REPORT_ITEM = gql`
  mutation CreateReportItem(
    $reportId: Int!
    $reportItem: CreateReportItemInput!
  ) {
    addItemToReport(reportId: $reportId, reportItem: $reportItem) {
      id
    }
  }
`;

export function useCreateReportItemMutation(
  options: MutationHookOptions<
    CreateReportItemMutation,
    CreateReportItemMutationVariables
  >,
) {
  return useMutation<
    CreateReportItemMutation,
    CreateReportItemMutationVariables
  >(CREATE_REPORT_ITEM, options);
}

export const UPDATE_REPORT_ITEM = gql`
  mutation UpdateReportItem(
    $reportItemId: Int!
    $reportItem: UpdateReportItemInput!
  ) {
    updateReportItem(reportItemId: $reportItemId, reportItem: $reportItem) {
      ...reportItemParts
    }
  }

  ${reportItemParts}
`;

export function useUpdateReportItemMutation() {
  return useMutation<
    UpdateReportItemMutation,
    UpdateReportItemMutationVariables
  >(UPDATE_REPORT_ITEM);
}

export const REPORT_AVAILABLE_SDS_LIST = gql`
  query ReportAvailableSdsList(
    $includePersonal: Boolean
    $includeCompany: Boolean
    $id: Int
  ) {
    savedDataSearchList(
      includePersonal: $includePersonal
      includeCompany: $includeCompany
      id: $id
    ) {
      id
      name
      data {
        id
        revision
        insertedAt
      }
    }
  }
`;
