import { gql, useQuery } from '@apollo/client';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { fieldPictureParts, fileParts } from '~/apollo/fragments';
import type {
  OcFieldPicsTabQuery,
  OcFieldPicsTabQueryVariables,
} from '~/apollo/generated/schema';
import { Modal } from '~/components/common/Modal';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Well } from '~/components/common/Well';
import { useModalState } from '~/hooks/modal';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';

const OC_FIELD_PICS_TAB = gql`
  query OCFieldPicsTab($outcropId: Int!) {
    outcropList(id: $outcropId) {
      id
      fieldPictures {
        ...fieldPictureParts
        file {
          ...fileParts
        }
      }
    }
  }

  ${fieldPictureParts}
  ${fileParts}
`;

export function OutcropFieldPicturesRoute() {
  const { outcrop } = useOutcropOutletContext();

  const { data, loading } = useQuery<
    OcFieldPicsTabQuery,
    OcFieldPicsTabQueryVariables
  >(OC_FIELD_PICS_TAB, {
    variables: { outcropId: outcrop.id },
  });

  const fieldPics =
    data?.outcropList.find(o => o.id === outcrop.id)?.fieldPictures ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  if (!fieldPics.length) {
    return <Well>This outcrop doesn't have any field pictures.</Well>;
  }

  return (
    <>
      <div className="space-y-6">
        <div className="grid md:grid-cols-4 gap-6">
          {fieldPics.map(picture => (
            <FieldPictureModal key={picture.id} fieldPicture={picture} />
          ))}
        </div>
      </div>
    </>
  );
}

type FieldPic =
  OcFieldPicsTabQuery['outcropList'][number]['fieldPictures'][number];

type FieldPictureModalProps = {
  fieldPicture: FieldPic;
};

function FieldPictureModal({ fieldPicture }: FieldPictureModalProps) {
  const { show, showModal, hideModal } = useModalState();

  return (
    <>
      <div>
        <button
          type="button"
          className="w-full aspect-square bg-cover border border-slate-300 hover:border-primary cursor-pointer bg-center"
          style={{ backgroundImage: `url(${fieldPicture.file.signedUrl})` }}
          onClick={showModal}
        />
      </div>

      <Modal open={show} onHide={hideModal} closeable size="lg">
        <Modal.Body title={fieldPicture.description || 'Field Picture Detail'}>
          <div className="space-y-4 text-center">
            <img
              src={fieldPicture.file.signedUrl}
              className="w-full h-auto border"
              alt={fieldPicture.description || 'Field picture'}
            />

            <div className="space-y-1">
              {fieldPicture.description && <p>{fieldPicture.description}</p>}
              {fieldPicture.author && (
                <div>
                  <span className="font-bold">Author:</span>{' '}
                  {fieldPicture.author}
                </div>
              )}
            </div>

            {fieldPicture.location && (
              <GoogleMap
                center={fieldPicture.location}
                zoom={10}
                mapContainerClassName="w-96 h-60 rounded-md mx-auto"
                options={{
                  fullscreenControl: false,
                  zoomControl: false,
                  mapTypeId: google.maps.MapTypeId.TERRAIN,
                }}
              >
                <MarkerF
                  position={new google.maps.LatLng(fieldPicture.location)}
                />
              </GoogleMap>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
