import type { PureQueryOptions } from '@apollo/client';
import type { UploadOutcropPicturesTabQuery } from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import {
  ThumbnailParent,
  ThumbnailUpload,
} from '~/components/upload/file/ThumbnailUpload';

type Props = {
  outcropId: number;
  thumbnail?: UploadOutcropPicturesTabQuery['outcropList'][number]['thumbnail'];
  refetchQueries?: PureQueryOptions[];
};

export function OutcropThumbnailManager({
  outcropId,
  thumbnail,
  refetchQueries,
}: Props) {
  return (
    <div className="grid lg:grid-cols-2 gap-6">
      {thumbnail && (
        <div>
          <Heading level={4}>Current Thumbnail</Heading>
          <img
            src={thumbnail.signedUrl}
            className="max-w-full h-auto"
            alt="Current thumbnail"
          />
        </div>
      )}

      <div className={thumbnail ? '' : 'lg:col-span-2'}>
        <Heading level={4}>
          {thumbnail ? 'Replace' : 'Upload'} Thumbnail
        </Heading>
        <ThumbnailUpload
          parentType={ThumbnailParent.Outcrop}
          parentId={outcropId}
          refetchQueries={refetchQueries}
        />
      </div>
    </div>
  );
}
