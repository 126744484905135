import { Field, useFormikContext } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { Heading } from '~/components/common/Heading';
import type { MAFToolFormValues } from '~/routes/modern/index';
import { validGDEs } from '~/utils/modules/modern';

type Props = {
  disabled: boolean;
};

export function ModernFiltersGDE({ disabled }: Props) {
  const {
    values: { gde },
  } = useFormikContext<MAFToolFormValues>();

  let gdeImagePath: string;
  if (gde === 'Continental') {
    gdeImagePath = 'assets/images/modern/continental-plot.png';
  } else if (gde === 'Paralic and Shallow Marine') {
    gdeImagePath = 'assets/images/modern/ternary-plot.png';
  } else {
    throw new Error(`Unhandled GDE ${gde}`);
  }

  return (
    <>
      <Heading level={4}>Gross Depositional Environment</Heading>

      <Field
        name="gde"
        component={FormikField}
        type="radio"
        disabled={disabled}
        options={validGDEs.map(value => ({ value, text: value }))}
        size="sm"
      />

      <div className="text-center mt-4">
        {<img src={gdeImagePath} alt="" className="w-full" />}
      </div>
    </>
  );
}
