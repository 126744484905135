import { envVars } from '~/environment';
import type { Event, MapProjection } from 'cesium';
import {
  CameraEventType,
  Cartesian2,
  Cartesian3,
  Cartographic,
  Cesium3DTileset,
  Color,
  ConstantProperty,
  ConstantPositionProperty,
  CustomDataSource,
  Ellipsoid,
  Entity,
  Fullscreen,
  Globe,
  HeadingPitchRoll,
  Ion,
  KeyboardEventModifier,
  LabelStyle,
  Math as CesiumMath,
  Matrix4,
  NearFarScalar,
  PinBuilder,
  PointGraphics,
  PrimitiveCollection,
  Rectangle,
  Resource,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Terrain,
  Transforms,
  VerticalOrigin,
  Viewer,
} from 'cesium';
import type { LatLngHeightHPR } from './CesiumViewer';
import type { PolygonEntityStruct } from './limeParse';
import { useDebounce } from '~/hooks/debounce';

type LatLngHeight = {
  latitude: number;
  longitude: number;
  height: number;
};

export type entityView = {
  entity: Entity;
  show: boolean;
};

export type CameraParams = {
  position: Cartesian3;
  orientation: HeadingPitchRoll;
};

export type DataSourcePoint = {
  latitude: number;
  longitude: number;
  name: string;
  id?: number;
};

type TransformParams =
  | {
      location: Cartographic;
      orientation: HeadingPitchRoll;
    }
  | { none: boolean }
  | { local: boolean };

export enum TerrainProvider {
  None = 'NONE',
  World = 'WORLD',
  Bathymetry = 'BATHYMETRY',
}

export type TilesetParams = {
  transform: TransformParams;
  assetToken: string;
  assetTokenExpiry?: number;
  localPath: string;
  defaultCamera?: CameraParams;
};

export async function setupCesiumViewer(
  element: HTMLElement,
  terrainProvider: TerrainProvider,
  enableCollision: boolean = false,
): Promise<Viewer> {
  Ion.defaultAccessToken = envVars.VITE_ION_ASSET_TOKEN;
  let terrain: undefined | Terrain;
  let globe: false | Globe | undefined = undefined;
  let mapProjection: undefined | MapProjection;
  let projectionPicker = false;
  switch (terrainProvider) {
    case TerrainProvider.None:
      terrain = undefined;
      // hide globe instead of no globe for cesium navigation
      globe = new Globe();
      projectionPicker = true;
      globe.show = false;
      break;
    case TerrainProvider.World:
      // mapProjection = new MapProjection()
      globe = new Globe(Ellipsoid.WGS84);
      terrain = Terrain.fromWorldTerrain();
      break;
    case TerrainProvider.Bathymetry:
      // mapProjection = new Cesium.MapProjection()
      // globe = new Cesium.Globe(mapProjection.ellipsoid)
      terrain = Terrain.fromWorldTerrain();
      break;
  }

  const viewerOptions: Viewer.ConstructorOptions = {
    terrain: terrain,
    animation: false,
    baseLayerPicker: false,
    fullscreenButton: true,
    fullscreenElement: element,
    geocoder: false,
    homeButton: false,
    infoBox: false,
    globe: globe,
    mapProjection: mapProjection,
    sceneModePicker: false,
    projectionPicker: projectionPicker,
    selectionIndicator: false,
    navigationHelpButton: false,
    navigationInstructionsInitiallyVisible: false,
    scene3DOnly: true,
    clockViewModel: undefined,
    timeline: false,
  };
  const viewer = await new Viewer(element, viewerOptions);
  if (terrainProvider == TerrainProvider.None) {
    updateCameraControls(viewer);
    panCamera(viewer);
  }
  if (!enableCollision) {
    viewer.scene.screenSpaceCameraController.enableCollisionDetection = false;
  }

  if (viewerOptions.fullscreenButton) {
    // fullscreen resizes the canvas so when it comes back from full screen
    // we need to resize it back to the original size
    const canvas = viewer.canvas;
    const height = canvas.height;
    const width = canvas.width;
    const fullScreenHandler = () => {
      if (Fullscreen.fullscreen) {
        canvas.height = height;
        canvas.width = width;
      }
      Fullscreen.requestFullscreen(element);
    };

    viewer.fullscreenButton.viewModel.command.beforeExecute.addEventListener(
      fullScreenHandler,
    );
  }
  return viewer;
}

export async function updateCameraControls(viewer: Viewer) {
  const cameraController = viewer.scene.screenSpaceCameraController;
  cameraController.rotateEventTypes = [];
  cameraController.tiltEventTypes = [CameraEventType.LEFT_DRAG];

  cameraController.inertiaZoom = 0;
  viewer.scene.screenSpaceCameraController.maximumMovementRatio = 0.2;
  // @ts-ignore
  viewer.scene.screenSpaceCameraController._maximumRotateRate = 0.4;
  // @ts-ignore
  viewer.scene.screenSpaceCameraController._zoomFactor = 0.5;
}

async function panCamera(viewer: Viewer) {
  let looking: boolean = false;
  let startMousePosition: Cartesian2;
  let mousePosition: Cartesian2;
  let moveRate = 15;

  const handler = new ScreenSpaceEventHandler(viewer.canvas);

  handler.setInputAction(function (
    movement: ScreenSpaceEventHandler.PositionedEvent,
  ) {
    looking = true;
    const position = movement.position;
    viewer.scene.screenSpaceCameraController.enableZoom = false;
    mousePosition = startMousePosition = Cartesian2.clone(position);
    const picked = viewer.scene.pick(position);
    const pickPosition = viewer.scene.pickPosition(position);
    if (picked && picked.content.tileset) {
      const camera_position = viewer.camera.position;
      const distance = Math.abs(
        Cartesian3.distance(camera_position, pickPosition),
      );
      moveRate = distance / 50;
    } else {
      moveRate = 15;
    }
  }, ScreenSpaceEventType.RIGHT_DOWN);

  handler.setInputAction(function (
    movement: ScreenSpaceEventHandler.MotionEvent,
  ) {
    mousePosition = movement.endPosition;
  }, ScreenSpaceEventType.MOUSE_MOVE);

  handler.setInputAction(function (
    position: ScreenSpaceEventHandler.PositionedEvent,
  ) {
    looking = false;
    viewer.scene.screenSpaceCameraController.enableZoom = true;
  }, ScreenSpaceEventType.RIGHT_UP);

  viewer.clock.onTick.addEventListener(function (clock) {
    const camera = viewer.camera;

    if (looking) {
      const width = viewer.canvas.clientWidth;
      const height = viewer.canvas.clientHeight;

      // Coordinate (0.0, 0.0) will be where the mouse was clicked.
      const x = -(mousePosition.x - startMousePosition.x) / width;
      const y = (mousePosition.y - startMousePosition.y) / height;
      // const cameraHeight = Cesium.Ellipsoid.WGS84.cartesianToCartographic(
      //   camera.position
      // ).height;
      // const moveRate = cameraHeight / 100.0;
      camera.moveRight(x * moveRate);
      camera.moveUp(y * moveRate);
    }
  });
}

export async function loadTileset(
  modelUrl: string,
  assetToken: string,
  assetTokenExpiry: number = 60,
  tokenFetcher: Function = () => undefined,
  transform: TransformParams = { none: true },
): Promise<Cesium3DTileset | undefined> {
  const retryCallback: Resource.RetryCallback = async (resource, error) => {
    if (error?.statusCode == 401 || error?.statusCode == 403) {
      const newToken = await new Promise((resolve, reject) => {
        tokenFetcher(resolve);
      });
      if (newToken != undefined && resource != undefined) {
        resource.queryParameters['token'] = newToken;
        return true;
      }
    }
    return false;
  };
  const resource = new Resource({
    url: modelUrl,
    queryParameters: {
      token: assetToken,
      tokenExpiry: assetTokenExpiry,
    },
    retryCallback: retryCallback,
    retryAttempts: 5,
  });
  const modelMatrix = transformToModelMatrix(transform);
  return Cesium3DTileset.fromUrl(resource, { modelMatrix: modelMatrix });
}

export const transformToModelMatrix = (transform: TransformParams): Matrix4 => {
  if ('orientation' in transform && 'location' in transform) {
    const position = Cartesian3.fromRadians(
      transform.location.longitude,
      transform.location.latitude,
      transform.location.height,
    );
    const hpr = new HeadingPitchRoll(
      CesiumMath.toRadians(transform.orientation.heading),
      CesiumMath.toRadians(transform.orientation.pitch),
      CesiumMath.toRadians(transform.orientation.roll),
    );
    return Transforms.headingPitchRollToFixedFrame(position, hpr);
  }

  console.log('No location or hpr included, adding 0,0 fixed frame');
  if ('local' in transform) {
    return Transforms.eastNorthUpToFixedFrame(Cartesian3.fromDegrees(0, 0));
  }
  return Transforms.eastNorthUpToFixedFrame(Cartesian3.fromDegrees(0, 0));
};

export async function addTilesetToViewer(
  viewer: Viewer,
  tileset: TilesetParams,
  zoomTo = true,
) {
  const t = await loadTileset(
    tileset.localPath,
    tileset.assetToken,
    tileset.assetTokenExpiry,
    () => {},
    tileset.transform,
  );
  viewer.scene.primitives.add(t);
  if (t && 'local' in tileset.transform && zoomTo) {
    viewer.zoomTo(t);
  }
  if (t && 'location' in tileset.transform && zoomTo) {
    viewer.zoomTo(t);
  }
  return t;
}

export async function enableTilesetPlacement(
  viewer: Viewer,
  tileset: Cesium3DTileset,
  callback: (placement: LatLngHeightHPR) => void,
) {
  const handler = new ScreenSpaceEventHandler(viewer.canvas);
  handler.setInputAction(
    function (movement: ScreenSpaceEventHandler.PositionedEvent) {
      const cartesian =
        viewer.scene.pickPosition(movement.position) ||
        viewer.camera.pickEllipsoid(
          movement.position,
          viewer.scene.globe.ellipsoid,
        );
      if (!cartesian) {
        return;
      }
      const cartographic = Cartographic.fromCartesian(cartesian);
      const currentHPR = reverseRotationMatrix(tileset.modelMatrix);
      const newModelMatrix = updateTransformationMatrixTranslation(
        tileset.modelMatrix,
        CesiumMath.toDegrees(cartographic.longitude),
        CesiumMath.toDegrees(cartographic.latitude),
        cartographic.height,
        // 0, // mamually set cartographic.height to 0 since pickposition might provide incorrect results
        // https://github.com/CesiumGS/cesium/issues/4368
      );
      tileset.modelMatrix = newModelMatrix;
      callback({
        latitude: CesiumMath.toDegrees(cartographic.latitude),
        longitude: CesiumMath.toDegrees(cartographic.longitude),
        height: 0, // same as above
        heading: currentHPR.heading,
        pitch: currentHPR.pitch,
        roll: currentHPR.roll,
      });
    },
    ScreenSpaceEventType.LEFT_DOWN,
    KeyboardEventModifier.ALT,
  );
}

export function convertFromCartesian(cartesian: Cartesian3): LatLngHeight {
  const cartographic = Cartographic.fromCartesian(cartesian);
  return {
    latitude: CesiumMath.toDegrees(cartographic.latitude),
    longitude: CesiumMath.toDegrees(cartographic.longitude),
    height: cartographic.height,
  };
}

export function updateTransformationMatrixTranslation(
  mtx: Matrix4,
  longitude: number,
  latitude: number,
  height: number,
) {
  const newTranslation = Cartesian3.fromDegrees(
    longitude,
    latitude,
    height,
    Ellipsoid.WGS84,
    new Cartesian3(),
  );
  const updatedTransformation = Matrix4.setTranslation(
    mtx,
    newTranslation,
    new Matrix4(),
  );
  return updatedTransformation;
}

export function reverseTransformationMatrix(mtx: Matrix4) {
  const translationMatrix = Matrix4.getTranslation(mtx, new Cartesian3());
  const cartesian = Cartographic.fromCartesian(translationMatrix);
  return {
    longitude: CesiumMath.toDegrees(cartesian.longitude),
    latitude: CesiumMath.toDegrees(cartesian.latitude),
    height: cartesian.height,
  };
}

export function reverseRotationMatrix(mtx: Matrix4): HeadingPitchRoll {
  return Transforms.fixedFrameToHeadingPitchRoll(
    mtx,
    Ellipsoid.WGS84,
    Transforms.eastNorthUpToFixedFrame,
    new HeadingPitchRoll(),
  );
}

export function modifyhpr(
  tilesetLocation: LatLngHeight,
  hpr: HeadingPitchRoll,
) {
  const modelMatrix = Transforms.headingPitchRollToFixedFrame(
    Cartesian3.fromDegrees(
      tilesetLocation.longitude,
      tilesetLocation.latitude,
      tilesetLocation.height,
    ),
    hpr,
  );
  return modelMatrix;
}
export async function addPolygonsToViewer(
  viewer: Viewer,
  polygons: PolygonEntityStruct[],
): Promise<Entity[]> {
  const polygonEntities = polygons.map(polygon => {
    return viewer.entities.add({
      polygon: {
        hierarchy: polygon.heirarchy,
        material: polygon.material,
        perPositionHeight: polygon.perPositionHeight,
      },
    });
  });
  return polygonEntities;
}

export function getCamera(viewer: Viewer | null): CameraParams | null {
  if (!viewer) {
    return null;
  }
  const camera = viewer.camera;
  const position = camera.position;
  const heading = camera.heading;
  const pitch = camera.pitch;
  const roll = camera.roll;
  return {
    position: position,
    orientation: new HeadingPitchRoll(heading, pitch, roll),
  };
}

export function resetCameraOrientation(viewer: Viewer | null): Viewer | null {
  if (!viewer) {
    return null;
  }
  const camera = viewer.camera;
  camera.flyTo({
    destination: camera.position,
    orientation: {
      heading: 0,
    },
  });
  return viewer;
}

export function updateTilesetModelMatrixWithPositionHpr(
  tileset: Cesium3DTileset,
  values: LatLngHeightHPR,
): Cesium3DTileset {
  const latLngHeight = {
    latitude: values.latitude,
    longitude: values.longitude,
    height: values.height,
  };
  const hpr = new HeadingPitchRoll(
    CesiumMath.toRadians(values.heading),
    CesiumMath.toRadians(values.pitch),
    CesiumMath.toRadians(values.roll),
  );
  const hprMatrix = modifyhpr(latLngHeight, hpr);
  const mm = updateTransformationMatrixTranslation(
    hprMatrix,
    values.longitude,
    values.latitude,
    values.height,
  );
  tileset.modelMatrix = mm;
  return tileset;
}

export function getCurrentZoomDistance(viewer: Viewer | null): number | null {
  if (!viewer) {
    return null;
  }
  const cameraPositionCart = viewer.scene.camera.positionCartographic;
  const globeHeight = viewer.scene.globe.getHeight(cameraPositionCart);
  // const detailedHeight = await sampleTerrainMostDetailed(viewer.terrainProvider, [cameraPositionCart])
  if (globeHeight) {
    return cameraPositionCart.height - globeHeight;
  }
  return null;
}

export function setDepthTest(viewer: Viewer | null, clippingEnabled: boolean) {
  if (!viewer) {
    console.log('viewer not set yet');
    return;
  }
  viewer.scene.globe.depthTestAgainstTerrain = clippingEnabled;
}

export function setShowGlobe(viewer: Viewer | null, showGlobe: boolean) {
  if (!viewer) {
    console.log('viewer not set yet');
    return;
  }
  viewer.scene.globe.show = showGlobe;
  viewer.scene.skyAtmosphere.show = showGlobe;
  viewer.scene.skyBox.show = showGlobe;
  viewer.scene.sun.show = showGlobe;
}

export function createCustomDataSourcePoints(
  dataPoints: DataSourcePoint[],
): CustomDataSource {
  const customDataSource = new CustomDataSource('customDataSource');
  dataPoints.forEach((dp: DataSourcePoint) => {
    let entity = new Entity({
      id: dp.id?.toString(),
      name: dp.name,
      position: new ConstantPositionProperty(
        Cartesian3.fromDegrees(dp.longitude, dp.latitude),
      ),
    });
    entity.point = new PointGraphics();
    entity.point.pixelSize = new ConstantProperty(5);
    entity.point.color = new ConstantProperty(Color.RED.withAlpha(0.9));
    customDataSource.entities.add(entity);
  });
  return customDataSource;
}

export function addCustomPinStyle(
  dataSource: CustomDataSource,
): Event.RemoveCallback {
  const pinBuilder = new PinBuilder();
  const pin200 = pinBuilder.fromText('200+', Color.BLACK, 48).toDataURL();
  const pin100 = pinBuilder.fromText('100+', Color.BLACK, 48).toDataURL();
  const pin50 = pinBuilder.fromText('50+', Color.RED, 48).toDataURL();
  const pin40 = pinBuilder.fromText('40+', Color.ORANGE, 48).toDataURL();
  const pin30 = pinBuilder.fromText('30+', Color.YELLOW, 48).toDataURL();
  const pin20 = pinBuilder.fromText('20+', Color.GREEN, 48).toDataURL();
  const pin10 = pinBuilder.fromText('10+', Color.BLUE, 48).toDataURL();

  const singleDigitPins = new Array(9);
  for (let i = 0; i < singleDigitPins.length; ++i) {
    singleDigitPins[i] = pinBuilder
      .fromText(`${i + 1}`, Color.VIOLET, 48)
      .toDataURL();
  }

  const removeListener = dataSource.clustering.clusterEvent.addEventListener(
    function (clusteredEntities, cluster) {
      cluster.label.show = false;
      cluster.billboard.show = true;
      cluster.billboard.id = cluster.label.id;
      cluster.billboard.verticalOrigin = VerticalOrigin.BOTTOM;

      if (clusteredEntities.length >= 200) {
        cluster.billboard.image = pin200;
      } else if (clusteredEntities.length >= 100) {
        cluster.billboard.image = pin100;
      } else if (clusteredEntities.length >= 50) {
        cluster.billboard.image = pin50;
      } else if (clusteredEntities.length >= 40) {
        cluster.billboard.image = pin40;
      } else if (clusteredEntities.length >= 30) {
        cluster.billboard.image = pin30;
      } else if (clusteredEntities.length >= 20) {
        cluster.billboard.image = pin20;
      } else if (clusteredEntities.length >= 10) {
        cluster.billboard.image = pin10;
      } else {
        cluster.billboard.image = singleDigitPins[clusteredEntities.length - 1];
      }
    },
  );
  return removeListener;
}

// export function createTilesetCollection(viewer: Viewer, tilesets: Cesium3DTileset[]): PrimitiveCollection {
//   const collection = new PrimitiveCollection();
//   for (let ts of tilesets) {
//     ts.properties
//     collection.add(tileset);
//   }
// }

export async function cameraInViewHandler(
  viewer: Viewer,
  dataSource: CustomDataSource,
  callback: (ids: number[]) => void,
) {
  const camera = viewer.camera;

  function inViewCheck() {
    const inViewIds: number[] = [];
    
    const calculatedEntities = calculateInViewEntities(viewer, dataSource);
    for (let e of calculatedEntities) {
      const ent = dataSource.entities.getById(e.entity.id);
      if (ent) {
        ent.show = e.show;
      }
      if (e.show && e.entity.id && parseFloat(e.entity.id)) {
        inViewIds.push(parseFloat(e.entity.id))
      }
    }
    callback(inViewIds)
  }
  return camera.moveEnd.addEventListener(debounce(inViewCheck, 500))
}

export function calculateInViewEntities(
  viewer: Viewer,
  dataSource: CustomDataSource,
): entityView[] {
  const cameraRectangle = viewer.camera.computeViewRectangle();
  const entitiesVisibility = dataSource.entities.values.map(entity => {
    const currentPosition = entity.position?.getValue(viewer.clock.currentTime);
    if (cameraRectangle && currentPosition) {
      if (
        Rectangle.contains(
          cameraRectangle,
          Cartographic.fromCartesian(currentPosition),
        )
      ) {
        return { entity: entity, show: true };
      }
    }
    return { entity: entity, show: false };
  });
  return entitiesVisibility;
}

const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};