import qs from 'qs';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export function useQueryString<T>() {
  const location = useLocation();

  // Strip leading ? in search string if exists
  const queryString = location.search.replace(/^\?/, '');

  const query = qs.parse(queryString) as unknown as T;

  const stringify = useCallback((obj: any) => qs.stringify(obj), []);

  return { queryString, query, stringify };
}
