import { useQuery , gql } from '@apollo/client';
import type { FieldProps } from 'formik';

import type { BasinTypeOptionsQuery } from '~/apollo/generated/schema';
import { FormikSelectField } from '~/components/common/FormikField/FormikSelectField';

export const BASIN_TYPE_OPTIONS = gql`
  query BasinTypeOptions {
    wikiEnum(enumType: OTHER_BASIN_TYPE) {
      values
    }
  }
`;

type Props = FieldProps & {
  disabled?: boolean;
};

export function BasinTypeSelect({
  form,
  field,
  disabled = false,
  ...props
}: Props) {
  const { data, loading } = useQuery<BasinTypeOptionsQuery>(
    BASIN_TYPE_OPTIONS,
    {},
  );

  const values = data?.wikiEnum.values ?? [];
  const options = values.map(opt => ({ value: opt, label: opt }));

  return (
    <FormikSelectField
      {...props}
      form={form}
      field={field}
      options={options}
      disabled={disabled || loading}
    />
  );
}
