import type { ProjectPartsFragment } from '~/apollo/generated/schema';
import { yup } from '~/utils/validation';

export type ProjectFormValues = {
  name: string;
  description: string;
  comments: string;
  hidden: boolean;
};

export function defaultProject(
  project?: ProjectPartsFragment,
): ProjectFormValues {
  return {
    name: project?.name ?? '',
    description: project?.description ?? '',
    comments: project?.comments ?? '',
    hidden: project?.hidden ?? false,
  };
}

export const projectValidationSchema = yup.object({
  name: yup.string().label('name').min(1).required(),
  description: yup.string().label('description').min(1).required(),
  hidden: yup.boolean().label('hidden').required(),
  comments: yup.string().label('comments').nullable(),
});
