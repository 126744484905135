export function Footer() {
  const date = new Date();

  return (
    <div className="h-20">
      <footer className="footer absolute bottom-0 h-20 bg-slate-100 text-slate-500 flex justify-center items-center">
        Copyright {date.getFullYear()} University of Aberdeen
      </footer>
    </div>
  );
}
