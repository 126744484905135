import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { FormikField } from '~/components/common/FormikField';
import { InputGroup } from '~/components/common/InputGroup';
import type { MeasurementFormValues } from '~/utils/modules/architecturalMeasurement';
import {
  completenessTypes,
  dataTypeUnitHint,
  dataTypes,
  measurementQualities,
} from '~/utils/modules/architecturalMeasurement';
import { snakeToHuman } from '~/utils/text';

const formatLabel = snakeToHuman;

export function MeasurementFormFields() {
  const { values, setFieldValue } = useFormikContext<MeasurementFormValues>();
  const dataType = values.dataType;

  useEffect(() => {
    if (values.value && !dataType) {
      console.log('No value for data type, unsetting value.');
      setFieldValue('value', '');
    }
  }, [dataType, values.value, setFieldValue]);

  return (
    <>
      <Field
        name="dataType"
        label="Data Type"
        component={FormikField}
        type="select"
        options={dataTypes.map(opt => ({
          value: opt,
          label: formatLabel(opt),
        }))}
        required
      />

      <Field
        name="value"
        label="Value"
        component={FormikField}
        type="number"
        required
        renderInput={(input?: React.ReactNode) =>
          dataType ? (
            <InputGroup>
              {input}
              <InputGroup.Addon>{dataTypeUnitHint(dataType)}</InputGroup.Addon>
            </InputGroup>
          ) : (
            input
          )
        }
        className="w-full join-item"
        disabled={!dataType}
      />

      <Field
        name="completeness"
        label="Completeness"
        component={FormikField}
        type="select"
        options={completenessTypes.map(opt => ({
          value: opt,
          label: formatLabel(opt),
        }))}
        required
      />

      <Field
        name="quality"
        label="Data source confidence"
        component={FormikField}
        type="select"
        options={measurementQualities.map(opt => ({
          value: opt,
          label: formatLabel(opt),
        }))}
        required
      />
    </>
  );
}
