import { Field, Form, Formik } from 'formik';
import type {
  GeoreferencePartsFragment,
  OutcropOverviewTabQuery,
} from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import { Well } from '~/components/common/Well';
import { colorList } from '~/utils/chart';
import { firstOutlineOrPolygon, georefColors } from '~/utils/georeference';
import { GeoreferenceMap } from './GeoreferenceMap';
import { rejectNil } from '~/utils/common';

type Outcrop = OutcropOverviewTabQuery['outcropList'][number];

export type VomWithOutline = {
  vom: Outcrop['virtualOutcropModels'][number];
  outline: GeoreferencePartsFragment;
};

export type VisibilityFilter = {
  outcropOutline: boolean;
  voms: boolean;
  crossSections: boolean;
  sedimentaryLogs: boolean;
  gigaPans: boolean;
};

type MapField = {
  label: string;
  color: string;
};

const fields: Record<keyof VisibilityFilter, MapField> = {
  outcropOutline: { label: 'Outcrop Outline', color: colorList[1] },
  voms: { label: 'Virtual Outcrop Models', color: colorList[1] },
  crossSections: { label: 'Cross Sections', color: colorList[1] },
  sedimentaryLogs: { label: 'Sedimentary Logs', color: colorList[1] },
  gigaPans: { label: 'Panoramas', color: colorList[1] },
};

type Props = {
  outcrop: Outcrop;
};

export function OutcropMap({ outcrop }: Props) {
  const outcropOutline = firstOutlineOrPolygon(outcrop.georeferences);

  const vomsWithOutlines = outcrop.virtualOutcropModels
    .filter(vom => firstOutlineOrPolygon(vom.georeference))
    .map(vom => {
      const outline = firstOutlineOrPolygon(vom.georeference);
      if (!outline) return null;
      return { vom, outline };
    })
    .filter(rejectNil);
  const crossSections = outcrop.crossSections.filter(
    cs => cs.georeference.length,
  );
  const sedLogs = outcrop.sedimentaryLogs.filter(sl => sl.georeference.length);
  const gigaPans = outcrop.gigaPans.filter(gp => gp.georeference.length);

  const studyCrossSections = outcrop.studies
    .flatMap(s => ({
      studyId: s.id,
      crossSections: s.crossSections
        .filter(cs => cs.outcropTagId === outcrop.id)
        .filter(cs => cs.georeference.length),
    }))
    .filter(scs => scs.crossSections.length);

  const studySedLogs = outcrop.studies
    .flatMap(s => ({
      studyId: s.id,
      sedimentaryLogs: s.sedimentaryLogs
        .filter(sl => sl.outcropTagId === outcrop.id)
        .filter(sl => sl.georeference.length),
    }))
    .filter(ssl => ssl.sedimentaryLogs.length);

  const studyGigaPans = outcrop.studies
    .flatMap(s => ({
      studyId: s.id,
      gigaPans: s.gigaPans
        .filter(gp => gp.outcropTagId === outcrop.id)
        .filter(gp => gp.georeference.length),
    }))
    .filter(sgp => sgp.gigaPans.length);

  const hasCrossSections =
    !!crossSections.length || !!studyCrossSections.length;
  const hasSedimentaryLogs = !!sedLogs.length || !!studySedLogs.length;
  const hasGigaPans = !!gigaPans.length || !!studyGigaPans.length;

  const initialValues: VisibilityFilter = {
    outcropOutline: !!outcropOutline,
    voms: vomsWithOutlines.length > 0,
    crossSections: hasCrossSections,
    sedimentaryLogs: hasSedimentaryLogs,
    gigaPans: hasGigaPans,
  };

  const showOutcropKey =
    !!outcropOutline ||
    vomsWithOutlines.length > 0 ||
    crossSections.length > 0 ||
    sedLogs.length > 0 ||
    gigaPans.length > 0;
  const showStudyKey =
    studyCrossSections.length > 0 ||
    studySedLogs.length > 0 ||
    studyGigaPans.length > 0;

  const visibleFields = (Object.keys(fields) as (keyof typeof fields)[]).filter(
    f => initialValues[f],
  );

  if (!visibleFields.length) {
    return null;
  }

  return (
    <Formik onSubmit={() => {}} initialValues={initialValues}>
      <Form className="form-inline">
        <GeoreferenceMap
          outcrop={outcrop}
          outcropOutline={outcropOutline}
          vomsWithOutlines={vomsWithOutlines}
          crossSections={crossSections}
          sedimentaryLogs={sedLogs}
          gigaPans={gigaPans}
          studyCrossSections={studyCrossSections}
          studySedimentaryLogs={studySedLogs}
          studyGigaPans={studyGigaPans}
        />

        <Well>
          <div className="space-y-2">
            <div className="flex gap-2 justify-center">
              {visibleFields.map(field => (
                <Field
                  key={field}
                  name={field}
                  label={fields[field].label}
                  component={FormikField}
                  type="checkbox"
                  size="sm"
                />
              ))}
            </div>
            <div className="flex gap-2 items-center justify-center text-sm">
              <div className="text-xs text-muted">Key:</div>
              {showOutcropKey && (
                <div className="flex gap-1 items-center">
                  <span>Outcrop Data</span>
                  <LegendCircle color={georefColors.outcrop} />
                </div>
              )}
              {showStudyKey && (
                <div className="flex gap-1 items-center">
                  <span>Study Data</span>
                  <LegendCircle color={georefColors.study} />
                </div>
              )}
            </div>
          </div>
        </Well>
      </Form>
    </Formik>
  );
}

export function LegendCircle({ color }: { color: string }) {
  return (
    <svg height="20px" width="20px">
      <circle
        cx="10px"
        cy="10px"
        r="8px"
        strokeWidth="1"
        stroke={color}
        fill={color}
        fillOpacity="60%"
      />
    </svg>
  );
}
