import { camelize, decamelize } from 'humps';
import moment from 'moment';
import * as R from 'ramda';

/** Capitalizes the first letter of each word */
export const ucwords = (str: string): string =>
  R.pipe(
    camelize,
    str => decamelize(str, { separator: ' ' }),
    str => str.replace(/\b\w/g, m => m.toUpperCase()),
  )(str);

export const capitalize = (str: string): string => {
  const first = str.substring(0, 1);
  const rest = str.substring(1);
  return `${first.toUpperCase()}${rest}`;
};

export const snakeToHuman = (str: string) =>
  str.replace(/_/g, ' ').replace(/\b\w/g, m => m.toUpperCase());

/** Removes all HTML tags */
export const stripHtml = (str: string): string =>
  str.replace(/<(?:.|\n)*?>/gm, '');

export const truncate = (
  numCharacters: number,
  append = true,
  appendText: string | JSX.Element = '...',
) =>
  function TruncatedText(text: string) {
    if (!text) return '';
    if (text.length <= numCharacters) return text;

    let newString = text.substring(0, numCharacters).trim();
    return append ? (
      <>
        {newString}
        {appendText}
      </>
    ) : (
      newString
    );
  };

export const camelToHuman = (text: string) =>
  R.pipe(
    (val: string) => decamelize(val, { separator: ' ' }),
    ucwords,
    //
  )(text);

export const snakeCapsToHuman = (text: string) =>
  R.pipe(
    R.toLower,
    camelize,
    val => decamelize(val, { separator: ' ' }),
    ucwords,
  )(text);

/** Converts a full Date or parseable date-string to YYYY-MM-DD format */
export function toISODateString(date: Date | string): string {
  return moment(date).format('YYYY-MM-DD');
}

export function fileSizeText(size: number | null): string | null {
  if (!size) return null;
  const toOneDecimal = (num: number) => Math.round(num * 10) / 10;
  const convertBy = (num: number, by: number) => num / by;

  if (size < 0) return null;
  if (size > 1000000) return `${toOneDecimal(convertBy(size, 1000000))} MB`;
  if (size > 10000) return `${toOneDecimal(convertBy(size, 1000))} KB`;
  return `${toOneDecimal(size)} B`;
}
