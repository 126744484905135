type Props = {
  numHidden: number;
};

export function LegendItemsHiddenNote({ numHidden }: Props) {
  if (!numHidden) return null;

  return (
    <div className="text-center text-muted text-sm p-2">
      <em>
        {numHidden} {numHidden === 1 ? 'item is' : 'items are'} hidden from the
        graph, but {numHidden === 1 ? 'is' : 'are'} still being used to
        calculate statistics.
      </em>
    </div>
  );
}
