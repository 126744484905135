import { useFormikContext } from 'formik';
import { useDataSearchContext } from '~/components/dataSearch/dataSearchContext';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';

export function CountSummary() {
  const { options } = useDataSearchContext();
  const { values } = useFormikContext<DataSearchFormValues>();

  const aeCount = options.architecturalElement
    .filter(aeOpt => {
      if (!values.architecturalElement.length) return true;
      return values.architecturalElement.includes(aeOpt.name);
    })
    .reduce((acc, opt) => acc + opt.count, 0);

  const measurementCount = options.dataTypeX.reduce(
    (acc, opt) => acc + opt.count,
    0,
  );

  const studyCount = options.studies.filter(studyOpt => {
    if (!values.studyIds.length) return true;
    return values.studyIds.includes(studyOpt.id);
  }).length;

  return (
    <div className="text-sm text-muted text-left pl-3">
      {aeCount} AEs with {measurementCount} Measurements
      <br />
      in {studyCount} Analogues Studies
    </div>
  );
}
