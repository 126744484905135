import type { DocumentNode, PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { FormErrors } from '~/components/common/FormErrors';
import type {
  SupportObjectFormValues,
  SupportObjectType,
} from '~/utils/modules/supportObject';
import {
  initialSupportingObject,
  toSOInputType,
} from '~/utils/modules/supportObject';
import { SupportObjectForm } from './SupportObjectForm';

type Props = {
  studyId?: number;
  outcropId?: number;
  supportObjectType: SupportObjectType;
  createMutation: DocumentNode;
  refetchQueries: PureQueryOptions[];
};

export function CreateSupportObject({
  studyId,
  outcropId,
  supportObjectType,
  createMutation,
  refetchQueries,
}: Props) {
  const [createSupportObject, { loading, error }] = useMutation(
    createMutation,
    { refetchQueries },
  );

  async function handleSubmit(
    values: SupportObjectFormValues,
    formikHelpers: FormikHelpers<SupportObjectFormValues>,
  ) {
    const variables = {
      [supportObjectType]: toSOInputType(supportObjectType, values),
    };
    if (outcropId) variables.outcropId = outcropId;
    if (studyId) variables.studyId = studyId;

    if (!studyId && !outcropId) {
      throw new Error('A study or outcrop id must be provided.');
    }

    try {
      await createSupportObject({ variables });
      formikHelpers.resetForm();
      toast.success(`${supportObjectType} created successfully.`);
    } catch (err) {
      console.log('Error creating support object', err);
      const defaultError = `There was a problem creating the ${supportObjectType}.`;
      toast.error(defaultError);
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialSupportingObject(undefined, supportObjectType)}
      validationSchema={null} // TODO
    >
      <Form className="space-y-4">
        <div>
          <SupportObjectForm
            studyId={studyId}
            supportObjectType={supportObjectType}
          />
        </div>

        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" size="sm" disabled={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
