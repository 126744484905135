import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { useDebounce } from '~/hooks/debounce';

type Props = {
  /** Delay in milliseconds to debounce form values. Default = 300 */
  debounceDelay?: number;
  limit?: number;
};

export function FormikAutosave({
  debounceDelay = 300,
  limit = Infinity,
}: Props) {
  const { values, submitForm, submitCount } = useFormikContext();

  const debouncedValues = useDebounce(values, debounceDelay);
  const valuesRef = useRef(debouncedValues);

  useEffect(() => {
    console.log('Autosaving form...');
    if (submitCount < limit && debouncedValues !== valuesRef.current) {
      submitForm();
      valuesRef.current = debouncedValues;
    }
  }, [debouncedValues, submitForm, submitCount, limit]);

  return null;
}
