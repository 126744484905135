import { LocalDate } from '~/components/common/LocalDate';

export function LastUpdated({ date }: { date: Date | null | undefined }) {
  if (!date) return null;

  return (
    <p className="text-sm text-muted my-2">
      <i>
        Last updated: <LocalDate date={date} />
      </i>
    </p>
  );
}
