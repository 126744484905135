import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { useFormikContext } from 'formik';
import { FormLabel } from '~/components/common/FormikField/FormLabel';
import type { UpdateFieldPictureFormValues } from '~/components/upload/supportingObject/fieldPicture/UpdateFieldPictureForm';

export function UpdateFieldPictureLocationField() {
  const { values, setFieldValue } =
    useFormikContext<UpdateFieldPictureFormValues>();

  function handleMapLoaded(map: google.maps.Map) {
    map.setCenter(values.location);
    map.setZoom(10);
  }

  function handleMapMouseEvent(event: google.maps.MapMouseEvent) {
    const position = event.latLng;
    if (position) {
      setFieldValue('location', position);
    }
  }

  return (
    <>
      <div className="form-control">
        <FormLabel name="location" label="Location" />

        <GoogleMap
          mapContainerClassName="w-full h-[500px]"
          onLoad={handleMapLoaded}
          onClick={handleMapMouseEvent}
          options={{
            mapTypeId: google.maps.MapTypeId.TERRAIN,
            fullscreenControl: false,
            streetViewControl: false,
          }}
        >
          <MarkerF
            position={values.location}
            draggable
            onDragEnd={handleMapMouseEvent}
          />
        </GoogleMap>
      </div>
    </>
  );
}
