import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import type {
  UploadStudyListQuery,
  UploadStudyListQueryVariables,
} from '~/apollo/generated/schema';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import StudyList from '~/components/upload/study/StudyList';
import { useSortFilter } from '~/hooks/data';
import { uploadStudyCreateRoute } from '~/paths';
import {
  toSortableStudy,
  UPLOAD_STUDY_LIST,
} from '~/routes/upload/model/__uploadModelsPage/studies';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

export default function UpdateOutcropStudiesRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UploadStudyListQuery,
    UploadStudyListQueryVariables
  >(UPLOAD_STUDY_LIST, {
    variables: { outcropId },
  });

  const studyList = data?.studyList ?? [];
  const sortableStudies = studyList.map(toSortableStudy);

  const { items, sortIndicatorProps, filterSearchProps } = useSortFilter(
    sortableStudies,
    'id',
    'searchableText',
    'uploadStudyList',
  );

  return (
    <Panel>
      <Panel.Heading className="flex justify-between items-center">
        <Panel.Title>Studies</Panel.Title>
        <Link
          to={`${uploadStudyCreateRoute()}?outcropId=${outcropId}`}
          className="btn btn-primary btn-xs gap-1"
        >
          <FontAwesomeIcon icon={faPlus} /> Create Study
        </Link>
      </Panel.Heading>

      <Panel.Body>
        <SpinnerPlaceholder show={loading} />
        {!loading && (
          <StudyList
            studies={items}
            sortIndicatorProps={sortIndicatorProps}
            filterSearchProps={filterSearchProps}
          />
        )}
      </Panel.Body>
    </Panel>
  );
}
