import { gql, useMutation, useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as fragments from '~/apollo/fragments';
import type {
  BasinPartsFragment,
  KeyParametersPartsFragment,
  OutcropPartsFragment,
  OutcropQualityParametersPartsFragment,
  SourceAreaPartsFragment,
  UpdateOutcropMutation,
  UpdateOutcropMutationVariables,
  UploadOutcropAdditionalFieldsTabQuery,
  UploadOutcropAdditionalFieldsTabQueryVariables,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { OutcropAdditionalFields } from '~/components/upload/outcrop/OutcropAdditionalFields';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';
import type { OutcropFormValues } from '~/utils/modules/outcrop';
import {
  initialOutcropFormValues,
  toOutcropInput,
} from '~/utils/modules/outcrop';

const UPDATE_OUTCROP_ADDTL_FIELDS = gql`
  mutation UpdateOutcropAdditionalFields($id: Int!, $outcrop: OutcropInput!) {
    updateOutcrop(id: $id, outcrop: $outcrop) {
      ...outcropParts
    }
  }

  ${fragments.outcropParts}
`;

type OutcropProp = OutcropPartsFragment & {
  basin?: BasinPartsFragment | null;
  sourceArea?: SourceAreaPartsFragment | null;
  qualityParameters?: OutcropQualityParametersPartsFragment | null;
};

type Props = {
  outcrop: OutcropProp;
  keyParameters: KeyParametersPartsFragment[];
};

function AdditionalFieldsRoute({ outcrop, keyParameters }: Props) {
  const [updateOutcrop, { loading: loadingUpdateOutcrop, error }] = useMutation<
    UpdateOutcropMutation,
    UpdateOutcropMutationVariables
  >(UPDATE_OUTCROP_ADDTL_FIELDS, {});

  const handleSubmit = async (values: OutcropFormValues) => {
    const outcropInput = toOutcropInput(values);

    try {
      await updateOutcrop({
        variables: {
          id: outcrop.id,
          outcrop: outcropInput,
        },
      });
      toast.success('Outcrop updated successfully.');
    } catch (err) {
      console.log('Error updating outcrop', err);
      toast.error('There was a problem saving the outcrop.');
    }
  };

  return (
    <>
      <Formik
        initialValues={initialOutcropFormValues(outcrop)}
        onSubmit={handleSubmit}
      >
        <Form className="space-y-6">
          <OutcropAdditionalFields
            entity="outcrop"
            geologyTypes={outcrop.geologyType}
            keyParameters={keyParameters}
          />

          <FormErrors graphQLError={error} />

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loadingUpdateOutcrop}
            >
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </>
  );
}

const OUTCROP_ADDITIONAL_FIELDS_TAB = gql`
  query UploadOutcropAdditionalFieldsTab($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      basin {
        ...basinParts
      }
      sourceArea {
        ...sourceAreaParts
      }
      qualityParameters {
        ...outcropQualityParametersParts
      }
      keyParameters {
        ...keyParametersParts
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.basinParts}
  ${fragments.sourceAreaParts}
  ${fragments.outcropQualityParametersParts}
  ${fragments.keyParametersParts}
`;

export default function UploadOutcropUpdateAdditionalFieldsRoute() {
  const { outcrop: contextOC } = useUploadOutcropUpdateRouteOutletContext();

  const { data: dataOutcrop, loading: loadingOutcrop } = useQuery<
    UploadOutcropAdditionalFieldsTabQuery,
    UploadOutcropAdditionalFieldsTabQueryVariables
  >(OUTCROP_ADDITIONAL_FIELDS_TAB, {
    variables: { id: contextOC.id },
  });

  const outcropList = dataOutcrop?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === contextOC.id);

  if (loadingOutcrop) return <SpinnerPlaceholder />;
  if (!dataOutcrop || !outcrop) return <NotFound />;

  return (
    <AdditionalFieldsRoute
      outcrop={outcrop}
      keyParameters={outcrop.keyParameters}
    />
  );
}
