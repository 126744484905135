import {
  faCheckCircle,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { HTMLProps } from 'react';
import React from 'react';

const defaultTrue = (
  <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
);
const defaultFalse = (
  <FontAwesomeIcon icon={faXmarkCircle} className="text-error" />
);

type Props = {
  value: boolean;
  trueText?: React.ReactNode;
  falseText?: React.ReactNode;
} & Omit<HTMLProps<HTMLSpanElement>, 'value'>;

export function EnabledIndicator({
  value = false,
  trueText = defaultTrue,
  falseText = defaultFalse,
  ...spanProps
}: Props): JSX.Element {
  if (value) return <span {...spanProps}>{trueText}</span>;
  return <span {...spanProps}>{falseText}</span>;
}
