import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { FormikTinyMceField } from '~/components/common/FormikField/FormikTinyMceField';
import { CountryPicker } from '~/components/gis/CountryPicker';

export function RegionFormFields() {
  return (
    <>
      <Field name="name" component={FormikField} label="Name" required />
      <Field
        name="description"
        label="Description"
        component={FormikTinyMceField}
        type="textarea"
        required
      />
      <Field
        name="basins"
        label="Basins"
        component={FormikTinyMceField}
        type="textarea"
        required
      />
      <Field
        name="location.location"
        label="Location"
        component={FormikField}
        required
      />
      <Field
        name="location.country"
        label="Country"
        component={FormikField}
        type={CountryPicker}
        required
      />
    </>
  );
}
