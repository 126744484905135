import type { PureQueryOptions } from '@apollo/client';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import type { FileParent } from '~/apollo/generated/schema';
import { DropzoneContainer } from '~/components/common/DropzoneContainer';
import { FileUploadQueueItem } from '~/components/upload/file/FileUploadQueueItem';
import { useRefetchQueries } from '~/hooks/apollo';

export type QueueItemStatus = 'pending' | 'uploading' | 'success' | 'failed';

export interface QueueItem {
  id: string;
  status: QueueItemStatus;
  file: File;
}

type Props = {
  parentType: FileParent;
  parentId: number;
  /** MIME types accepted */
  accept?: string[];
  onDropRejected?: () => void;
  refetchQueries: PureQueryOptions[];
  onUploadSuccess?: () => Promise<void> | void;
};

export function SOFileUpload({
  parentType,
  parentId,
  accept,
  onDropRejected,
  onUploadSuccess,
  refetchQueries,
}: Props) {
  const [queue, setQueue] = useState<QueueItem[]>([]);
  const [refetch] = useRefetchQueries(refetchQueries);

  function handleDrop(files: File[]) {
    setQueue(prevQueue => {
      const newFiles: QueueItem[] = files.map(file => ({
        id: uuid(),
        status: 'pending',
        file,
      }));

      return [...prevQueue, ...newFiles];
    });
  }

  function updateQueueItemStatus(id: string, status: QueueItemStatus) {
    setQueue(prevQueue =>
      prevQueue.map(item => {
        if (item.id === id) return { ...item, status };
        return item;
      }),
    );
  }

  function handleRemove(id: string) {
    setQueue(prevQueue => prevQueue.filter(item => item.id !== id));
  }

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    const pendingItems = queue.filter(item => item.status === 'pending');
    for (var item of pendingItems) {
      try {
        updateQueueItemStatus(item.id, 'uploading');

        const body = new FormData();
        body.append('parentType', parentType);
        body.append('parentId', String(parentId));
        body.append('file', item.file);

        await fetch('/api/v3/file/so', { method: 'post', body });

        updateQueueItemStatus(item.id, 'success');
        if (onUploadSuccess) await onUploadSuccess();
      } catch (err) {
        console.log(`Error uploading ${item.file.name}`, err);
        updateQueueItemStatus(item.id, 'failed');
        toast.error(`There was a problem uploading the file ${item.file.name}`);
      }
    }

    await refetch();
  }

  const isAnyPending = !!queue.find(item => item.status === 'pending');

  return (
    <>
      <Dropzone
        accept={accept || undefined}
        onDropAccepted={handleDrop}
        onDropRejected={onDropRejected || undefined}
      >
        {({ getRootProps, getInputProps }) => (
          <DropzoneContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drop files here or click to browse.</p>
          </DropzoneContainer>
        )}
      </Dropzone>

      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <table className="table">
          <thead>
            <tr>
              <th>File:</th>
              <th>Status:</th>
            </tr>
          </thead>
          <tbody>
            {queue.map((item, index) => (
              <FileUploadQueueItem
                key={index}
                item={item}
                onRemove={handleRemove}
              />
            ))}
          </tbody>
        </table>

        <div className="text-center">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={!isAnyPending}
          >
            Upload
          </button>
        </div>
      </form>
    </>
  );
}
