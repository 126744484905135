import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  colName: string;
};

export type SortIndicatorProps = {
  sort: string;
  sortReverse: boolean;
  onSortChange: (key: string) => any;
  categoryFilterOptions: (colName: string) => string[];
  getFiltersForCategory: (colName: string) => string[];
  onCategoryFilterChange: (colName: string) => (value: string) => void;
};

type ComponentProps = SortIndicatorProps & Props;

export function SortIndicator({
  colName,
  sort,
  sortReverse = false,
}: ComponentProps): JSX.Element | null {
  if (sort !== colName) {
    return null;
  }

  return (
    <FontAwesomeIcon
      icon={sortReverse ? faChevronDown : faChevronUp}
      className="ml-2"
    />
  );
}
