import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { faExternalLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import {
  Link,
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  MemberUpdateRouteQuery,
  MemberUpdateRouteQueryVariables,
} from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import { DeleteMember } from '~/components/upload/lithostrat/member/DeleteMember';
import * as routes from '~/paths';

export const MEMBER_UPDATE_ROUTE = gql`
  query MemberUpdateRoute($memberId: Int!) {
    lithostratMemberList(id: $memberId) {
      ...lithostratMemberParts
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
      formation {
        ...lithostratFormationParts
        wikiPage {
          ...lithostratWikiPageParts
        }
      }
      wikiPage {
        ...lithostratWikiPageParts
        keyParameters {
          ...keyParametersParts
        }
        regions {
          id
          name
        }
      }
    }
  }

  ${fragments.lithostratMemberParts}
  ${fragments.lithostratAgeParts}
  ${fragments.lithostratFormationParts}
  ${fragments.lithostratWikiPageParts}
  ${fragments.keyParametersParts}
`;

type OutletContext = {
  member: MemberUpdateRouteQuery['lithostratMemberList'][number];
  refetchQueries: PureQueryOptions[];
};

export default function MemberUpdateRoute() {
  const navigate = useNavigate();
  const params = useParams();
  invariant(params.memberId);
  const memberId = parseInt(params.memberId);

  const { data, loading } = useQuery<
    MemberUpdateRouteQuery,
    MemberUpdateRouteQueryVariables
  >(MEMBER_UPDATE_ROUTE, {
    variables: { memberId },
  });

  const refetchQueries: [PureQueryOptions<MemberUpdateRouteQueryVariables>] = [
    { query: MEMBER_UPDATE_ROUTE, variables: { memberId } },
  ];

  const member = data?.lithostratMemberList.find(m => m.id === memberId);

  function handleDeleteSuccess() {
    navigate(routes.uploadLithostratMemberListRoute());
  }

  useBreadcrumb(
    'routes/upload/lithostrat/member/$memberId',
    member?.name ?? 'Update Member',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!member) return <NotFound />;

  const outletContext: OutletContext = { member, refetchQueries };

  return (
    <>
      <Link
        to={routes.lithostratMemberRoute(member.id)}
        className="btn btn-primary btn-sm float-right gap-1"
        target="_blank"
      >
        Wiki Page <FontAwesomeIcon icon={faExternalLink} />
      </Link>

      <PageHeading>{member.name}</PageHeading>

      <NavTabs className="mb-2">
        <NavTabs.Tab
          to={routes.uploadLithostratMemberUpdateRoute(member.id)}
          end
        >
          Member
        </NavTabs.Tab>
        <NavTabs.Tab
          to={routes.uploadLithostratMemberUpdateGeologicalAgeRoute(member.id)}
        >
          Geological Age
        </NavTabs.Tab>
        <NavTabs.Tab
          to={routes.uploadLithostratMemberUpdateExamplesRoute(member.id)}
        >
          Examples
        </NavTabs.Tab>
        <NavTabs.Tab
          to={routes.uploadLithostratMemberUpdateWikiRoute(member.id)}
        >
          Wiki
        </NavTabs.Tab>
      </NavTabs>

      <Outlet context={outletContext} />

      <div className="text-center mt-6">
        <DeleteMember id={member.id} onDeleteSuccess={handleDeleteSuccess}>
          {(onDelete, loading) => (
            <Confirm
              onConfirm={onDelete}
              text="This member will be permanently deleted."
            >
              {confirmDelete => (
                <Button
                  type="button"
                  variant="link"
                  className="text-error hover:text-error gap-1"
                  onClick={confirmDelete}
                  disabled={loading}
                >
                  <FontAwesomeIcon icon={faTrash} /> Delete Member
                </Button>
              )}
            </Confirm>
          )}
        </DeleteMember>
      </div>
    </>
  );
}

export function useMemberUpdateOutletContext() {
  return useOutletContext<OutletContext>();
}
