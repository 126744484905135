import { cn } from '~/utils/common';
import * as R from 'ramda';
import type { OutcropPalaeogeographyRouteQuery } from '~/apollo/generated/schema';
import { PaleoMapPictureModal } from './PaleoMapPictureModal';

type Props = {
  outcropId: number;
  paleoMap: OutcropPalaeogeographyRouteQuery['outcropList'][number]['paleoMaps'][number];
};

export function PaleomapListItem({ outcropId, paleoMap }: Props) {
  if (!paleoMap.pictures || !paleoMap.pictures.length) return null;

  const pictures = R.sortBy(R.propOr(Infinity, 'priority'), paleoMap.pictures);

  // 2 pictures uploaded gives a 2-column layout
  // 3 pictures uploaded gives a 3- column layout
  // all other amounts will have one picture per column (usually just one picture is uploaded in this case)

  return (
    <div className="grid lg:grid-cols-12 gap-6">
      <div
        className={cn({
          'lg:col-span-6': pictures.length === 2,
          'lg:col-span-4': pictures.length === 3,
          'lg:col-span-12': ![2, 3].includes(pictures.length),
        })}
      >
        {pictures[0] && (
          <PaleoMapPictureModal
            outcropId={outcropId}
            paleoMapId={paleoMap.id}
            picture={pictures[0]}
          />
        )}
        {pictures[2] && (
          <PaleoMapPictureModal
            outcropId={outcropId}
            paleoMapId={paleoMap.id}
            picture={pictures[2]}
          />
        )}
      </div>

      <div
        className={cn({
          'lg:col-span-6': pictures.length === 2,
          'lg:col-span-8': pictures.length === 3,
          'lg:col-span-12': ![2, 3].includes(pictures.length),
        })}
      >
        {pictures[1] && (
          <PaleoMapPictureModal
            outcropId={outcropId}
            paleoMapId={paleoMap.id}
            picture={pictures[1]}
          />
        )}
      </div>
    </div>
  );
}
