import type {
  RegionInput,
  RegionPartsFragment,
} from '~/apollo/generated/schema';
import { yup } from '~/utils/validation';

// Hide the map for these regions
// https://github.com/omt-tech/safari/issues/1953
export function isGlobalRegion(region: {
  location: Pick<RegionPartsFragment['location'], 'country'>;
}) {
  return region.location.country === 'Unassigned';
}

export type RegionFormValues = {
  name: string;
  description: string;
  basins: string;
  location: LocationFormValues;
};
export type LocationFormValues = {
  id?: number | null;
  country: string;
  location: string;
};

export function locationString(location: RegionPartsFragment['location']) {
  let ret = '';

  if (location.location) {
    ret += location.location;
    if (location.country) ret += ', ';
  }

  if (location.country) ret += location.country;

  return ret;
}

export const initialRegion = (
  region?: RegionPartsFragment,
): RegionFormValues => ({
  name: region?.name ?? '',
  description: region?.description ?? '',
  basins: region?.basins ?? '',
  location: {
    id: region?.location?.id ?? null,
    country: region?.location?.country ?? '',
    location: region?.location?.location ?? '',
  },
});

export const regionValidationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().required(),
  basins: yup.string().nullable(),
  location: yup.object({
    id: yup.number().nullable(),
    location: yup.string().required().label('location'),
    country: yup.string().required().label('country'),
  }),
});

export const toRegionInput = (values: RegionFormValues): RegionInput => ({
  ...values,
});
