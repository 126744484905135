import type { TooltipFormatterCallbackFunction } from 'highcharts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3D from 'highcharts/highcharts-3d';
import type { CSSProperties } from 'react';

if (typeof window !== 'undefined') {
  Highcharts3D(Highcharts);
}

type Props = {
  title: string;
  /** [label, total] */
  data: [string, number][];
  legend?: boolean;
  containerStyle?: CSSProperties;
};

export function PieChart({
  data,
  title,
  legend = true,
  containerStyle,
}: Props) {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        credits: { enabled: false },
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 60,
            beta: 0,
          },
          spacingTop: -20,
          spacingBottom: 0,
        },
        title: { text: undefined },
        exporting: { enabled: false },
        accessibility: { enabled: false },
        legend: {
          enabled: legend,
          layout: 'vertical',
          maxHeight: 80,
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
            depth: 25,
            showInLegend: true,
          },
        },
        series: [
          {
            type: 'pie',
            name: title,
            data,
          },
        ],
        tooltip: {
          formatter: function (): string {
            const pointName = this.point.name;
            const yValue = this.y;
            return `
              <div>
                <b style="font-size: 11pt;">${pointName}</b><br />
                ${yValue}
              </div>
            `;
          } as TooltipFormatterCallbackFunction,
        },

        // colors: measurementCounts.map(gdeCount => gdeCount.color),
      }}
      containerProps={{
        style: containerStyle ?? {
          height: '240px',
          width: '100%',
        },
      }}
    />
  );
}
