import { useQuery , gql } from '@apollo/client';
import { Field } from 'formik';
import * as R from 'ramda';

import * as fragments from '~/apollo/fragments';
import type { VomExampleOptionsQuery } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import { FormikReactSelectField } from '~/components/common/FormikField/FormikReactSelectField';

const VOM_EXAMPLE_OPTIONS = gql`
  query VomExampleOptions {
    virtualOutcropModelList {
      ...vomParts
      outcrop {
        ...outcropParts
      }
    }
  }

  ${fragments.vomParts}
  ${fragments.outcropParts}
`;

export function VomExampleField() {
  const { data, loading } = useQuery<VomExampleOptionsQuery>(
    VOM_EXAMPLE_OPTIONS,
    {},
  );

  const vomList = R.sortBy(R.prop('name'), data?.virtualOutcropModelList ?? []);

  return (
    <>
      <Field
        name="vomExampleId"
        label="VOM example"
        component={FormikField}
        type={FormikReactSelectField}
        disabled={loading}
        options={vomList.map(vom => ({
          value: vom.id,
          label: `${vom.name} (${vom.id})`,
          disabled: !vom.outcrop?.approved,
        }))}
      />
    </>
  );
}
