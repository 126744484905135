import { PopoverTrigger } from '@radix-ui/react-popover';
import React from 'react';
import { InfoIcon } from '~/components/common/icons/InfoIcon';
import { RequiredField } from '~/components/common/icons/RequiredField';
import type { PopoverContentProps } from '~/components/common/Popover';
import { Popover, PopoverContent } from '~/components/common/Popover';
import {
  WikiPopover,
  type WikiPopoverProps,
} from '~/components/wiki/WikiPopover';

export type FormLabelProps = {
  name: string;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  helpTextPlacement?: PopoverContentProps['align'];
  required?: boolean;
  wikiPopoverId?: number;
  wikiPopoverProps?: WikiPopoverProps;
};

export function FormLabel({
  name,
  label,
  helpText,
  helpTextPlacement,
  required = false,
  wikiPopoverId,
  wikiPopoverProps,
}: FormLabelProps) {
  if (!label) return null;

  return (
    <>
      <label htmlFor={name} className="label">
        <span className="label-text text-slate-600">
          {label}
          {required && <RequiredField />}
        </span>
        {helpText && (
          <span className="label-text">
            <Popover>
              <PopoverTrigger>
                <InfoIcon />
              </PopoverTrigger>
              <PopoverContent align={helpTextPlacement}>
                {helpText}
              </PopoverContent>
            </Popover>
          </span>
        )}
        {wikiPopoverId && (
          <WikiPopover {...wikiPopoverProps} otherWikiId={wikiPopoverId} />
        )}
      </label>
    </>
  );
}
