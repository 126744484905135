import type { PureQueryOptions } from '@apollo/client';
import { cn } from '~/utils/common';
import * as R from 'ramda';
import { useState } from 'react';
import { Badge } from 'react-daisyui';
import { Role, type BookmarkParentType } from '~/apollo/generated/schema';
import { RoleSecured } from '~/components/auth/RoleSecured';
import type { TargetBookmarksManagerModalProps } from '~/components/bookmark/TargetBookmarksManagerModal';
import { SupportingObjectTabRowItem } from '~/components/supportingObject/SupportingObjectTabRowItem';
import type {
  SupportObject,
  SupportObjectParentType,
} from '~/utils/modules/supportObject';

export type BookmarkableSOProps = Pick<
  TargetBookmarksManagerModalProps,
  'parentType' | 'path'
>;

type Props = {
  items: Array<SupportObject>;
  fade?: boolean;
  soParentType: SupportObjectParentType;
  soParentId: number;
  bookmarkParentType: BookmarkParentType;
  refetchQueries: PureQueryOptions[];
};

export function SupportingObjectList({
  items: unsortedItems,
  fade = false,
  soParentType,
  soParentId,
  bookmarkParentType,
  refetchQueries,
}: Props) {
  const [showUnpublished, setShowUnpublished] = useState(false);

  const items = R.pipe(
    () => unsortedItems,
    items => {
      if (showUnpublished) return items;
      return items.filter(item => item.published);
    },
    R.sortBy(so => so.priority ?? Infinity),
  )();

  const unpublishedCount = unsortedItems.reduce((acc, cur) => {
    const itemCount = cur.published ? 0 : 1;

    const unpublishedPictures = cur.pictures.reduce((picAcc, picCur) => {
      if (!picCur.published) return picAcc + 1;
      return picAcc;
    }, 0);

    return acc + itemCount + unpublishedPictures;
  }, 0);

  return (
    <div className="space-y-6">
      <RoleSecured roles={[Role.RoleAdmin]}>
        <div className="form-control items-end">
          <label
            htmlFor="showUnpublished"
            className="label justify-start gap-2 cursor-pointer"
          >
            <input
              type="checkbox"
              id="showUnpublished"
              checked={showUnpublished}
              onChange={() => setShowUnpublished(!showUnpublished)}
              className="checkbox"
              disabled={!unpublishedCount}
            />
            Show unpublished items{' '}
            <Badge color="ghost" className="font-mono">
              {unpublishedCount}
            </Badge>
          </label>
        </div>
      </RoleSecured>

      <div
        className={cn('grid gap-6', {
          'lg:grid-cols-2': items.length === 2,
          'lg:grid-cols-3': items.length === 3,
          'lg:grid-cols-4': items.length > 3,
        })}
      >
        {items.map(so => (
          <SupportingObjectTabRowItem
            key={so.id}
            item={so}
            fade={fade}
            soParentType={soParentType}
            soParentId={soParentId}
            bookmarkParentType={bookmarkParentType}
            bookmarkParentId={so.id}
            showUnpublished={showUnpublished}
            refetchQueries={refetchQueries}
          />
        ))}
      </div>
    </div>
  );
}
