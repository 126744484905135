import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  AddBookmarkToCollectionMutation,
  AddBookmarkToCollectionMutationVariables,
  CreateBookmarkCollectionMutation,
  CreateBookmarkCollectionMutationVariables,
} from '~/apollo/generated/schema';
import { ADD_BOOKMARK_TO_COLLECTION } from '~/apollo/operations/bookmark';
import { CREATE_BOOKMARK_COLLECTION } from '~/components/bookmark/CreateCollectionModal';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import { useAuth } from '~/contexts/auth';
import type { BookmarkCollectionFormValues } from '~/utils/modules/bookmark';
import {
  bookmarkCollectionValidationSchema,
  initialBookmarkCollection,
  toBookmarkCollectionInput,
} from '~/utils/modules/bookmark';

type Props = {
  bookmarkId: number;
  isCompany: boolean;
  refetchQueries: PureQueryOptions[];
};

export function CreateCollectionAndAdd({
  bookmarkId,
  isCompany,
  refetchQueries,
}: Props) {
  const { authority } = useAuth();
  if (!authority) throw new Error('Must be authenticated');

  const [createCollection, { loading: loadingCreate, error }] = useMutation<
    CreateBookmarkCollectionMutation,
    CreateBookmarkCollectionMutationVariables
  >(CREATE_BOOKMARK_COLLECTION, {
    // We will refetchQueries after adding the bookmark to the new collection
    // refetchQueries,
  });

  const [addToCollectionMutation, { loading: loadingAdd }] = useMutation<
    AddBookmarkToCollectionMutation,
    AddBookmarkToCollectionMutationVariables
  >(ADD_BOOKMARK_TO_COLLECTION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const loading = loadingCreate || loadingAdd;

  async function handleSubmit(
    values: BookmarkCollectionFormValues,
    helpers: FormikHelpers<BookmarkCollectionFormValues>,
  ) {
    // Create the new collection
    let createdCollectionId: number;
    try {
      const createRes = await createCollection({
        variables: {
          collection: toBookmarkCollectionInput(values),
          isCompany,
        },
      });
      if (!createRes.data) {
        console.log('Response:', createRes);
        throw new Error('Error parsing create collection response');
      }
      createdCollectionId = createRes.data.createBookmarkCollection.id;
    } catch (err) {
      console.log('Error creating bookmark collection', err);
      toast.error(
        'There was a problem creating the collection, please reload the page and try again.',
      );
      return;
    }

    // Add the bookmark passed in to the newly created collection
    try {
      await addToCollectionMutation({
        variables: {
          bookmarkId,
          collectionId: createdCollectionId,
        },
      });
      toast.success('Bookmark successfully added to the new collection.');
      helpers.resetForm({ values: initialBookmarkCollection() });
    } catch (err) {
      console.log('Error adding bookmark to collection', err);
      toast.error(
        `The collection was created successfully, but the bookmark couldn't be aded to it. Please reload the page and try again.`,
      );
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialBookmarkCollection()}
      validationSchema={bookmarkCollectionValidationSchema}
    >
      <Form>
        <div className="flex gap-2 justify-between items-center">
          <div className="flex-grow">
            <Field
              name="name"
              component={FormikField}
              required
              disabled={loading}
              placeholder="New collection"
              className="input-sm"
            />
          </div>

          {/* Small right-margin to put the button in-line with the coll list */}
          <div className="">
            <Button
              type="submit"
              color="ghost"
              size="xs"
              disabled={loading}
              className="gap-1"
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Add
            </Button>
          </div>
        </div>

        <FormErrors graphQLError={error} />
      </Form>
    </Formik>
  );
}
