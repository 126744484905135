import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { Heading } from '~/components/common/Heading';

const basinTypeOptions = [
  'Foreland',
  'Extensional',
  'Intracratonic',
  'Strike-Slip',
  'Fore-arc',
  'Passive Margin',
];

type Props = {
  disabled: boolean;
};

export function ModernFiltersBasinType({ disabled }: Props) {
  return (
    <>
      <Heading level={4}>Basin Type</Heading>

      {basinTypeOptions.map(value => (
        <Field
          key={value}
          name="basinType"
          component={FormikField}
          type="checkbox"
          value={value}
          label={value}
          disabled={disabled}
          size="sm"
        />
      ))}
    </>
  );
}
