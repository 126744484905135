import { faCheckCircle, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { Button } from 'react-daisyui';
import {
  GeoreferenceDataType,
  type GeoreferencePartsFragment,
} from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import type { GeoreferenceFormValues } from '~/utils/georeference';
import { snakeCapsToHuman } from '~/utils/text';
import type { DataTypeLimits } from './GeoreferenceManager';

const ToolImage = ({ filename }: { filename: string }) => (
  <img
    src={`/assets/images/common/${filename}`}
    className="h-5 w-auto -mt-1"
    alt="Tool"
  />
);

function renderToolImage(dataType: string) {
  switch (dataType) {
    case GeoreferenceDataType.Point:
    case GeoreferenceDataType.Centre:
      return <ToolImage filename="marker_tool.png" />;

    case GeoreferenceDataType.Outline:
    case GeoreferenceDataType.Polygon:
      return <ToolImage filename="polygon_tool.png" />;

    case GeoreferenceDataType.Polyline:
      return <ToolImage filename="polyline_tool.png" />;

    default:
      return null;
  }
}

const NoCoordinatesSet = ({ dataType }: { dataType: string }) => (
  <div>
    <FontAwesomeIcon icon={faX} className="text-danger" /> No coordinates
    selected.
    <div className="text-muted">
      Use the map's <b>{dataType} tool</b> {renderToolImage(dataType)} to select
      the coordinates.
    </div>
  </div>
);

type CoordinatesSetProps = {
  handleClick: (...args: any[]) => any;
};
const CoordinatesSet = ({ handleClick }: CoordinatesSetProps) => (
  <div className="text-center">
    <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-1" />
    Coordinates set
    <br />
    <Button type="button" color="ghost" size="xs" onClick={() => handleClick()}>
      Reset
    </Button>
  </div>
);

type Props = {
  dataTypes: GeoreferenceDataType[];
  limits?: DataTypeLimits;
  maxAllowed?: number;
  georeferences: GeoreferencePartsFragment[];
};

export function GeoreferenceFormFields({
  dataTypes,
  limits = {},
  maxAllowed,
  georeferences,
}: Props) {
  const { values, setFieldValue } = useFormikContext<GeoreferenceFormValues>();

  const resetCoordinates = useCallback(
    () => setFieldValue('data', null),
    [setFieldValue],
  );

  useEffect(() => {
    resetCoordinates();
  }, [values.dataType, resetCoordinates]);

  const dataTypeOptions = dataTypes.map(dt => {
    const numGeorefs = georeferences.filter(g => g.dataType === dt).length;

    return {
      value: dt.toUpperCase(),
      label: `${snakeCapsToHuman(dt)}${
        limits[dt] ? ` (${numGeorefs}/${limits[dt]})` : ''
      }`,
      disabled: typeof limits[dt] === 'number' && limits[dt] <= numGeorefs,
    };
  });

  return (
    <div className="space-y-2">
      <Field
        name="dataType"
        label="Georeference type"
        component={FormikField}
        type="select"
        disabled={!!maxAllowed && georeferences.length >= maxAllowed}
        required
        options={dataTypeOptions}
      />
      {maxAllowed && georeferences.length >= maxAllowed && (
        <div className="alert alert-info" style={{ marginTop: '5px' }}>
          The maximum number of allowed geoereferences has been reached for this
          element.
        </div>
      )}

      {values.dataType && (
        <>
          <Field name="name" label="Name" component={FormikField} required />
          <Field
            name="description"
            label="Description"
            component={FormikField}
            required
          />

          {/* Display indicator if coordinates are set from the map */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Coordinates</span>
            </label>
            {values.data ? (
              <CoordinatesSet handleClick={resetCoordinates} />
            ) : (
              <NoCoordinatesSet dataType={values.dataType} />
            )}
          </div>
        </>
      )}
    </div>
  );
}
