import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { PublicationTypeSelect } from '~/components/upload/study/PublicationTypeSelectFormik';
import { OutcropTagField } from '~/components/upload/supportingObject/OutcropTagField';

type Props = {
  studyId: number;
};

export function LiteratureFormFields({ studyId }: Props) {
  return (
    <div>
      <Field name="title" label="Title" component={FormikField} required />
      <Field name="author" label="Author" component={FormikField} required />
      <Field
        name="year"
        label="Year"
        component={FormikField}
        minLength="4"
        maxLength="4"
        required
      />
      <Field
        name="publicationSource"
        label="Publication Source"
        component={FormikField}
        required
      />
      <Field
        name="publicationType"
        label="Publication Type"
        component={FormikField}
        type={PublicationTypeSelect}
        required
      />
      <Field
        name="digitalObjectIdentifier"
        label="Digital Object Identifier"
        component={FormikField}
      />
      <Field name="webSource" label="Web Source" component={FormikField} />
      <Field name="comments" label="Comments" component={FormikField} />
      <Field name="priority" label="Priority" component={FormikField} />
      <Field
        name="outcropTagId"
        label="Outcrop Tag"
        component={FormikField}
        type={OutcropTagField}
        studyId={studyId}
      />
    </div>
  );
}
