import type { PureQueryOptions } from '@apollo/client';
import { useMutation , gql } from '@apollo/client';
import { toast } from 'react-toastify';

import type {
  DeleteReviewCommentMutation,
  DeleteReviewCommentMutationVariables,
} from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';
import { Tooltip } from '~/components/common/Tooltip';

export const DELETE_REVIEW_COMMENT = gql`
  mutation DeleteReviewComment($id: Int!) {
    deleteReviewComment(id: $id)
  }
`;

type Props = {
  children: (confirmDelete: () => void, loading: boolean) => JSX.Element;
  commentId: number;
  refetchQueries: PureQueryOptions[];
};

export function DeleteReviewComment({
  children,
  commentId,
  refetchQueries,
}: Props) {
  const [deleteReviewComment, { loading }] = useMutation<
    DeleteReviewCommentMutation,
    DeleteReviewCommentMutationVariables
  >(DELETE_REVIEW_COMMENT, {
    refetchQueries,
    variables: { id: commentId },
  });

  const handleDelete = async () => {
    try {
      await deleteReviewComment();
      toast.success('Review comment deleted.');
    } catch (err) {
      console.log('Error deleting review comment', err);
      toast.error(
        'There was a problem deleting the review comment. Please try again.',
      );
    }
  };

  return (
    <Confirm onConfirm={handleDelete}>
      {confirm => (
        <Tooltip message="Delete comment">{children(confirm, loading)}</Tooltip>
      )}
    </Confirm>
  );
}
