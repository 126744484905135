import type { PureQueryOptions } from '@apollo/client';
import {
  faBan,
  faExternalLink,
  faFolder,
  faMagnifyingGlassPlus,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ComponentPropsWithoutRef } from 'react';
import React, { useState } from 'react';
import { Button } from 'react-daisyui';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { type BookmarkCollectionPartsFragment } from '~/apollo/generated/schema';
import { BookmarkCollectionManager } from '~/components/bookmark/BookmarkCollectionManager';
import { BookmarkIcon } from '~/components/bookmark/BookmarkIcon';
import { DeleteBookmark } from '~/components/bookmark/DeleteBookmark';
import { UpdateBookmark } from '~/components/bookmark/UpdateBookmark';
import { LocalDate } from '~/components/common/LocalDate';
import { PictureThumbnail } from '~/components/common/PictureThumbnail';
import { Tooltip } from '~/components/common/Tooltip';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { ExternalVOLink } from '~/components/vom/ExternalVOLink';
import { useAuth } from '~/contexts/auth';
import { dataSearchRoute } from '~/paths';
import { cn, rejectNil } from '~/utils/common';
import type { Bookmark, StandardizedBookmark } from '~/utils/modules/bookmark';
import {
  canEditBookmark,
  isCompanyBookmark,
  prettyParentType,
  prettyTargetType,
} from '~/utils/modules/bookmark';
import { ucwords } from '~/utils/text';
import { CopyBookmarkModal } from './CopyBookmarkModal';

type BookmarkContainerProps = ComponentPropsWithoutRef<'div'> & {
  isCompany: boolean;
  deleted: boolean;
};
export function BookmarkContainer({
  isCompany,
  deleted,
  className,
  children,
  ...props
}: BookmarkContainerProps) {
  return (
    <div
      className={cn(
        'border border-l-4 shadow-sm bg-base-100',
        {
          'border-l-slate-400': deleted,
          'border-l-wine-500': !deleted && isCompany,
          'border-l-sky-500': !deleted && !isCompany,
        },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

type BookmarkBodyProps = ComponentPropsWithoutRef<'div'>;
export function BookmarkBody({ className, ...props }: BookmarkBodyProps) {
  return <div className={cn('w-full h-full p-2', className)} {...props} />;
}

type BookmarkFooterProps = ComponentPropsWithoutRef<'div'>;
export function BookmarkFooter({ className, ...props }: BookmarkFooterProps) {
  return <div className={cn('mt-0 p-1 bg-slate-50', className)} {...props} />;
}

export function CollectionNameBadge({
  to,
  className,
  children,
  ...props
}: LinkProps) {
  return (
    <Link
      to={to}
      className={cn(
        'block float-left px-2 py-1 m-1 bg-slate-200 text-slate-500 text-sm rounded-md break-words',
      )}
      {...props}
    >
      {children}
    </Link>
  );
}

type TargetNameProps = { isCompany: boolean } & ComponentPropsWithoutRef<'div'>;
export function TargetName({
  isCompany,
  className,
  ...props
}: TargetNameProps) {
  return (
    <div
      className={cn('text-lg font-bold text-slate-700', className)}
      {...props}
    />
  );
}

type TargetTypeProps = ComponentPropsWithoutRef<'span'>;
export function TargetType({ className, ...props }: TargetTypeProps) {
  return (
    <span className={cn('text-base text-slate-400', className)} {...props} />
  );
}

type NoteContainerProps = ComponentPropsWithoutRef<'span'>;
export function NoteContainer({ className, ...props }: NoteContainerProps) {
  return (
    <span
      className={cn(
        'whitespace-pre-line break-words text-slate-700 text-base',
        className,
      )}
      {...props}
    />
  );
}

export function SecondLine(props: ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={cn('mb-2 text-base text-slate-500', props.className)}
      {...props}
    />
  );
}

export function BelongsToSection(props: ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={cn(
        '-mt-1 text-base shadow-none font-normal pl-4 mb-1',
        props.className,
      )}
      {...props}
    />
  );
}

type BelongsToProps = { bookmark: StandardizedBookmark };
export const BelongsTo: React.FC<BelongsToProps> = ({ bookmark }) => {
  if (bookmark.parentType.toString() === bookmark.targetType.toString()) {
    return null;
  }

  return (
    <BelongsToSection>
      on {prettyParentType(bookmark.parentType)}:{' '}
      <strong>{bookmark.parentName}</strong>
    </BelongsToSection>
  );
};

type Props = {
  bookmark: StandardizedBookmark;
  collections: BookmarkCollectionPartsFragment[];
  refetchQueries: PureQueryOptions[];
};

export function BookmarkItem({ bookmark, collections, refetchQueries }: Props) {
  const { authority } = useAuth();
  if (!authority) throw new Error('Must be authenticated.');

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [areCollectionsVisible, setCollectionsVisibility] = useState(false);

  const handleUpdateSuccess = () => setIsInEditMode(false);

  const toggleCollectionsVisibility = () =>
    setCollectionsVisibility(prev => !prev);

  const hasDeletedEntity = (b: Bookmark) => !b.parent || !b.target;

  /** Prevent navigating away if editing bookmark or bookmark entity deleted */
  const overrideLink = (b: Bookmark) => (e: React.MouseEvent) => {
    if (hasDeletedEntity(b)) e.preventDefault();
  };

  const isOwner = (b: Bookmark) => b.userId === authority?.user.id;

  const bookmarkThumbnail = () => {
    if (!bookmark.target) return null;

    if (bookmark.target.__typename === 'Picture') {
      return bookmark.target;
    } else if (bookmark.target.__typename === 'VirtualOutcropModel') {
      return bookmark.target?.pictures?.at(0) ?? null;
    }

    return null;
  };
  const thumb = bookmarkThumbnail();

  const isCompany = bookmark.companyId !== null;

  return (
    <BookmarkContainer
      isCompany={isCompany}
      deleted={hasDeletedEntity(bookmark)}
    >
      <BookmarkBody>
        <div className="float-right text-right">
          <TargetType>
            {prettyTargetType(bookmark.targetType, bookmark.parentType)}
          </TargetType>
          {bookmark.target?.__typename === 'SavedDataSearch' && (
            <div>
              <TargetType>{ucwords(bookmark.target.graphType)}</TargetType>
            </div>
          )}

          {bookmark.target?.__typename === 'VirtualOutcropModel' && (
            <div className="mb-2">
              <ExternalVOLink
                vom={bookmark.target}
                interpreted={bookmark.target.interpreted}
                linkText={
                  <div>
                    Virtual outcrop viewer{' '}
                    <FontAwesomeIcon icon={faExternalLink} />
                  </div>
                }
              />
            </div>
          )}
        </div>

        <Link
          to={bookmark.path}
          onClick={overrideLink(bookmark)}
          className="hover:underline"
        >
          <TargetName isCompany={isCompany}>
            <BookmarkIcon
              bookmark={bookmark}
              className={cn(
                'mr-2',
                bookmark.companyId ? 'text-wine-500' : 'text-sky-500',
              )}
            />
            {bookmark.targetName}
            <BelongsTo bookmark={bookmark} />
          </TargetName>
        </Link>
        {bookmark.target?.__typename === 'SavedDataSearch' && (
          <div className="font-normal text-muted">
            {[bookmark.target.dataTypeX, bookmark.target.dataTypeY]
              .filter(rejectNil)
              .map(ucwords)
              .join(' vs. ')}
          </div>
        )}
        <SecondLine>
          {bookmark.company && (
            <strong className="mr-2">{bookmark.company.name}</strong>
          )}
          <span className="text-sm">
            Bookmarked{' '}
            {(!isOwner(bookmark) || isCompanyBookmark(bookmark)) && (
              <>by {bookmark.user.name} - </>
            )}
            <LocalDate date={bookmark.insertedAt} />
          </span>
        </SecondLine>

        {thumb && (
          <PictureThumbnail
            picture={thumb}
            imgSrc={thumb.file.signedUrl}
            containerStyle={{
              width: '100px',
              height: '100px',
              float: 'left',
              margin: '0 10px 10px 0',
            }}
            imgStyle={{}}
            showCaption={false}
            refetchQueries={refetchQueries}
          />
        )}

        {isInEditMode && (
          <UpdateBookmark
            bookmark={bookmark}
            onUpdateSuccess={handleUpdateSuccess}
          />
        )}
        {!isInEditMode && bookmark.note && (
          <NoteContainer>{bookmark.note}</NoteContainer>
        )}

        <div className="clear-both" />
      </BookmarkBody>

      <BookmarkFooter>
        <div className="flex items-center justify-between">
          {canEditBookmark(authority, bookmark) && (
            <Tooltip message="Manage this bookmark's collections">
              <Button
                type="button"
                color="ghost"
                size="xs"
                className="gap-1"
                onClick={toggleCollectionsVisibility}
              >
                <FontAwesomeIcon
                  icon={faFolder}
                  style={{ marginRight: '5px' }}
                />
                {!bookmark.collections.length && <span>Add to collection</span>}
                {bookmark.collections.length > 0 && (
                  <>
                    Collections
                    <span className="badge badge-ghost">
                      {bookmark.collections.length}
                    </span>
                    <ExpandedIcon expanded={areCollectionsVisible} />
                  </>
                )}
              </Button>
            </Tooltip>
          )}

          <div>
            {bookmark.target?.__typename === 'SavedDataSearch' && (
              <Link
                to={{
                  pathname: dataSearchRoute(),
                  search: new URLSearchParams({
                    sdsId: String(bookmark.target.id),
                  }).toString(),
                }}
                className="btn btn-ghost btn-sm gap-1"
              >
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                Copy to new search
              </Link>
            )}

            {/* Hide the copy button if the bookmark has been unlinked from its target or parent */}
            {bookmark.target && bookmark.parent && (
              <CopyBookmarkModal
                bookmark={bookmark}
                refetchQueries={refetchQueries}
              >
                {(showModal, icon, label) => (
                  <Button
                    type="button"
                    color="ghost"
                    size="sm"
                    onClick={showModal}
                    className="gap-1"
                  >
                    {<FontAwesomeIcon icon={icon} />} {label}
                  </Button>
                )}
              </CopyBookmarkModal>
            )}

            {canEditBookmark(authority, bookmark) && (
              <>
                {/* Edit and delete controls */}
                <DeleteBookmark
                  bookmark={bookmark}
                  refetchQueries={refetchQueries}
                >
                  {deleteBookmark => (
                    <Button
                      type="button"
                      color="ghost"
                      size="sm"
                      className="gap-1"
                      onClick={deleteBookmark}
                    >
                      <FontAwesomeIcon icon={faTrash} /> Delete
                    </Button>
                  )}
                </DeleteBookmark>

                <Button
                  type="button"
                  color="ghost"
                  size="sm"
                  className="gap-1"
                  onClick={() => setIsInEditMode(prevVal => !prevVal)}
                >
                  {isInEditMode ? (
                    <>
                      <FontAwesomeIcon icon={faBan} /> Cancel
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faPencil} /> Edit
                    </>
                  )}
                </Button>
              </>
            )}
          </div>
        </div>

        {areCollectionsVisible && (
          <div className="p-2">
            <BookmarkCollectionManager
              bookmark={bookmark}
              collections={collections}
              refetchQueries={refetchQueries}
            />
          </div>
        )}
      </BookmarkFooter>
    </BookmarkContainer>
  );
}
