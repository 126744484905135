import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { FormikField } from '~/components/common/FormikField';
import { RequiredField } from '~/components/common/icons/RequiredField';
import type { RegisterUserFormValues } from '~/utils/modules/user';

type Props = {
  companyMails: string[];
};

export function RegisterFormFields({ companyMails }: Props) {
  const { values, setFieldValue } = useFormikContext<RegisterUserFormValues>();

  useEffect(() => {
    setFieldValue(
      'username',
      `${values.usernamePrefix}${values.usernameSuffix}`,
    );
  }, [values.usernamePrefix, values.usernameSuffix, setFieldValue]);

  return (
    <>
      <Field name="name" label="Full Name" component={FormikField} required />
      <div className="mt-4">
        <label htmlFor="usernamePrefix">
          Email address <RequiredField />
        </label>
        <div className="grid grid-cols-2 gap-2">
          <Field name="usernamePrefix" component={FormikField} required />
          <Field
            name="usernameSuffix"
            component={FormikField}
            type="select"
            options={companyMails.map(value => ({ value, label: value }))}
            required
          />
        </div>
      </div>
      {values.usernamePrefix && values.usernameSuffix && (
        <div className="mt-2 text-muted text-sm">
          You will log in with the username:
          <strong className="text-base text-base-content ml-1">
            {values.username}
          </strong>
        </div>
      )}
      <div className="grid grid-cols-2 gap-2 mt-4">
        <Field
          name="password"
          label="Password"
          component={FormikField}
          type="password"
          required
        />
        <Field
          name="passwordConfirm"
          label="Confirm password"
          component={FormikField}
          type="password"
          required
        />
      </div>
    </>
  );
}
