import { gql, useQuery } from '@apollo/client';
import { Form, Formik, useFormikContext } from 'formik';
import * as R from 'ramda';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import type {
  MafSearchQuery,
  MafSearchQueryVariables,
} from '~/apollo/generated/schema';
import { ModernFiltersBasinType } from '~/components/modern/FiltersBasinType';
import { ModernFiltersClassification } from '~/components/modern/FiltersClassification';
import { ModernFiltersClimate } from '~/components/modern/FiltersClimate';
import { ModernFiltersGDE } from '~/components/modern/FiltersGDE';
import { ModernMap } from '~/components/modern/ModernMap';
import { ModernMapLegend } from '~/components/modern/ModernMapLegend';
import { useQueryString } from '~/hooks/routing';
import type { GDEValue } from '~/utils/modules/modern';
import { validGDEs } from '~/utils/modules/modern';

export const MODERN_QUERY = gql`
  query MafSearch($params: ModernAnalogueFinderInput!) {
    modernAnalogueFinderSearch(params: $params) {
      coordinates {
        classification
        latitude
        longitude
      }
      count
      resolution
    }
  }
`;

type Coords = {
  latitude: number;
  longitude: number;
};
export type ModernMapBounds = {
  southwest: Coords;
  northeast: Coords;
};

type QueryStringParams = {
  gde?: string;
  classification?: string[];
  basinType?: string[];
  climate?: string[];
};

export type MAFToolFormValues = {
  gde: GDEValue;
  classification: string[];
  basinType: string[];
  climate: string[];
  bounds: ModernMapBounds;
  limit: number;
};

// Wrapped by formik component below
function ModernToolPage() {
  const { values } = useFormikContext<MAFToolFormValues>();
  const [, setSearchParams] = useSearchParams();

  const { loading, data, refetch } = useQuery<
    MafSearchQuery,
    MafSearchQueryVariables
  >(MODERN_QUERY, {
    variables: { params: values },
  });

  useEffect(() => {
    console.log('Search updated');
    // Update the query string when values change
    const nextSearch: QueryStringParams = {
      gde: values.gde,
      classification: values.classification,
      basinType: values.basinType,
      climate: values.climate,
    };

    setSearchParams(nextSearch, { replace: true, preventScrollReset: true });
    refetch(); // Shallow diffing doesnt auto update values
  }, [values]);

  const coordinates = data?.modernAnalogueFinderSearch.coordinates ?? [];

  return (
    <>
      <div className="grid lg:grid-cols-4 gap-6">
        <div>
          <ModernFiltersGDE disabled={loading} />
        </div>

        <div>
          <ModernFiltersClassification disabled={loading} />
        </div>

        <div>
          <ModernFiltersBasinType disabled={loading} />
        </div>

        <div>
          <ModernFiltersClimate disabled={loading} />
        </div>
      </div>

      <div className="mt-4 space-y-0 border border-slate-200 p-2">
        <ModernMap coordinates={coordinates} />
        <ModernMapLegend coordinates={coordinates} />
      </div>
    </>
  );
}

const initialValues = (query: QueryStringParams): MAFToolFormValues => {
  let gde: GDEValue;
  if (query.gde && (validGDEs as string[]).includes(query.gde)) {
    gde = query.gde as GDEValue;
  } else {
    gde = 'Continental';
  }

  const qsValues = (propName: string): string[] =>
    R.pipe(R.pathOr<string[]>([], [propName]), R.reject(R.isEmpty))(query);

  const classification = qsValues('classification');
  const basinType = qsValues('basinType');
  const climate = qsValues('climate');

  return {
    gde,
    classification,
    basinType,
    climate,
    bounds: {
      northeast: {
        latitude: 0.0,
        longitude: 0.0,
      },
      southwest: {
        latitude: 0.0,
        longitude: 0.0,
      },
    },
    limit: 1000,
  };
};

export default function ModernToolRouteFormWrapper() {
  const { query } = useQueryString<QueryStringParams>();

  // No-op; form is never submitted
  const handleSubmit = () => {};

  return (
    <Formik initialValues={initialValues(query)} onSubmit={handleSubmit}>
      <Form>
        <ModernToolPage />
      </Form>
    </Formik>
  );
}
