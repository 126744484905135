import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import * as R from 'ramda';
import * as fragments from '~/apollo/fragments';
import type {
  OutcropFaciesTabQuery,
  OutcropFaciesTabQueryVariables,
} from '~/apollo/generated/schema';
import { BookmarkParentType } from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportingObjectList } from '~/components/supportingObject/SupportingObjectList';
import { TaggedSupportingObjectList } from '~/components/supportingObject/TaggedSupportingObjectList';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';

const OUTCROP_FACIES_TAB = gql`
  query OutcropFaciesTab($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts
      facies {
        ...faciesParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
            signedUrl
          }
        }
      }

      studies {
        ...studyParts

        dataHistory {
          ...dataHistoryParts
        }

        facies {
          ...faciesParts
          pictures {
            ...pictureParts
            file {
              ...fileParts
              signedUrl
            }
          }
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.faciesParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.studyParts}
  ${fragments.dataHistoryParts}
`;

export default function OutcropFaciesRoute() {
  const ctx = useOutcropOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    OutcropFaciesTabQuery,
    OutcropFaciesTabQueryVariables
  >(OUTCROP_FACIES_TAB, { variables: { outcropId } });

  const refetchQueries: PureQueryOptions[] = [
    { query: OUTCROP_FACIES_TAB, variables: { outcropId } },
  ];

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);
  const facies = outcrop?.facies ?? [];
  const outcropStudies = R.sortBy(R.prop('name'), outcrop?.studies ?? []);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-4">
      <SupportingObjectList
        items={facies}
        soParentType="outcrop"
        soParentId={outcropId}
        bookmarkParentType={BookmarkParentType.Facies}
        refetchQueries={refetchQueries}
      />

      <TaggedSupportingObjectList
        outcropId={outcropId}
        studies={outcropStudies}
        supportObjectKey="facies"
        refetchQueries={refetchQueries}
      />
    </div>
  );
}
