import { gql, useQuery } from '@apollo/client';
import { faExternalLink, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  GeologyWikiPageQuery,
  GeologyWikiPageQueryVariables,
} from '~/apollo/generated/schema';
import {
  BookmarkParentType,
  BookmarkTargetType,
  ReviewCommentParentType,
  Role,
} from '~/apollo/generated/schema';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import { TargetReviewCommentsModal } from '~/components/reviewComment/TargetReviewCommentsModal';
import {
  geologyWikiPageExamplesRoute,
  geologyWikiPageRoute,
  geologyWikiPageVirtualOutcropRoute,
  modernRoute,
  uploadGeologyUpdateRoute,
} from '~/paths';

const GEOLOGY_WIKI_PAGE = gql`
  query GeologyWikiPage($id: Int!) {
    depositionalList(id: $id) {
      ...depositionalWikiParts
      diagram {
        ...diagramParts
        file {
          ...fileParts
        }
        diagramAreas {
          ...diagramAreaParts
        }
      }
      vomExample {
        ...vomParts
      }
    }
  }

  ${fragments.depositionalWikiParts}
  ${fragments.diagramParts}
  ${fragments.fileParts}
  ${fragments.diagramAreaParts}
  ${fragments.vomParts}
`;

type OutletContext = {
  wikiPage: GeologyWikiPageQuery['depositionalList'][number];
};

export default function GeologyWikiPage() {
  const params = useParams<{ wikiId: string }>();
  invariant(params.wikiId, 'wikiId param required');
  const wikiPageId = parseInt(params.wikiId);

  const { loading, data } = useQuery<
    GeologyWikiPageQuery,
    GeologyWikiPageQueryVariables
  >(GEOLOGY_WIKI_PAGE, {
    variables: { id: wikiPageId },
  });

  const wikiPage = data?.depositionalList.find(wiki => wiki.id === wikiPageId);

  useBreadcrumb(
    'routes/wiki/geology/$wikiId',
    wikiPage?.title ?? 'Geology Wiki Page',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!wikiPage) return <NotFound />;

  const outletContext: OutletContext = { wikiPage };

  return (
    <>
      <div className="float-right space-x-2">
        <TargetBookmarksManagerModal
          parentType={BookmarkParentType.DepositionalWiki}
          parentId={wikiPage.id}
          targetType={BookmarkTargetType.DepositionalWiki}
          targetId={wikiPage.id}
          path={geologyWikiPageRoute(wikiPage.id)}
        />

        <TargetReviewCommentsModal
          parentId={wikiPage.id}
          parentType={ReviewCommentParentType.WikiDepositional}
          name={wikiPage.title}
        />

        <RoleSecured roles={[Role.RoleAdmin]}>
          <Link
            to={uploadGeologyUpdateRoute(wikiPage.id)}
            className="btn btn-ghost btn-sm gap-1"
          >
            <FontAwesomeIcon icon={faPencil} /> Edit
          </Link>
        </RoleSecured>
      </div>

      <PageHeading hasSubtitle={!!wikiPage.author}>
        {wikiPage.geologyType && (
          <GeologyTypeIcon
            geologyType={wikiPage.geologyType}
            className="h-6 mr-2"
          />
        )}
        {wikiPage.title}
      </PageHeading>
      {wikiPage.author && (
        <PageHeading.Subtitle className="italic">
          Author: {wikiPage.author}
        </PageHeading.Subtitle>
      )}

      <NavTabs>
        <NavTabs.Tab to={geologyWikiPageRoute(wikiPage.id)} end>
          Overview
        </NavTabs.Tab>
        <NavTabs.Tab to={geologyWikiPageExamplesRoute(wikiPage.id)}>
          Examples
        </NavTabs.Tab>

        {wikiPage.vomExampleId && (
          <NavTabs.Tab to={geologyWikiPageVirtualOutcropRoute(wikiPage.id)}>
            VOM Example
          </NavTabs.Tab>
        )}

        {wikiPage.mafLink && (
          <div className="mt-2">
            <Link
              to={`${modernRoute()}?${wikiPage.mafLink}`}
              target="_blank"
              className="btn btn-primary btn-sm gap-1 ml-4"
            >
              Modern Analogue Finder <FontAwesomeIcon icon={faExternalLink} />
            </Link>
          </div>
        )}
      </NavTabs>

      <div className="mt-2" />

      <Outlet context={outletContext} />
    </>
  );
}

export function useGeologyWikiDetailOutletContext() {
  return useOutletContext<OutletContext>();
}
