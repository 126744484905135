import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  SavedDataSearchQuery,
  SavedDataSearchQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { DeleteRevisionButton } from '~/components/dataSearch/savedDataSearch/DeleteRevisionButton';
import {
  SavedDataSearch,
  savedDataSearchWithOutcropParts,
} from '~/components/dataSearch/savedDataSearch/SavedDataSearch';
import { useSdsOutletContext } from '~/routes/bookmarked-data/$sdsId';

const SAVED_DATA_SEARCH = gql`
  query SavedDataSearch($searchId: Int!, $revisionNumber: Int!) {
    savedDataSearchList(id: $searchId) {
      ...savedDataSearchWithOutcropParts
      data(revision: $revisionNumber) {
        ...savedDataSearchDataParts
      }
    }
  }

  ${savedDataSearchWithOutcropParts}
  ${fragments.savedDataSearchParts}
  ${fragments.savedDataSearchDataParts}
`;

export default function SdsRevisionDetailRoute() {
  const ctx = useSdsOutletContext();
  const searchId = ctx.sds.id;

  const params = useParams<{ revisionNumber: string }>();
  invariant(params.revisionNumber, 'revisionNumber param required');
  const revisionNumber = parseInt(params.revisionNumber);

  const { data, loading } = useQuery<
    SavedDataSearchQuery,
    SavedDataSearchQueryVariables
  >(SAVED_DATA_SEARCH, {
    variables: { searchId, revisionNumber },
  });

  const sdsList = data?.savedDataSearchList ?? [];
  const sds = sdsList.find(s => s.id === searchId);
  const revisionData = sds?.data.find(d => d.revision === revisionNumber);

  if (loading) return <SpinnerPlaceholder />;
  if (!sds || !revisionData) {
    console.log('SDS or revision not found', searchId, revisionNumber);
    return <NotFound />;
  }

  return (
    <div className="space-y-4">
      <SavedDataSearch savedDataSearch={sds} data={revisionData} />

      <div className="text-center">
        <DeleteRevisionButton
          searchId={sds.id}
          dataId={revisionData.id}
          onDeleteSuccess={ctx.onDeleteSuccess}
        />
      </div>
    </div>
  );
}
