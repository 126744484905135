import { gql, useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { fieldPictureParts } from '~/apollo/fragments';
import type * as schema from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import { UpdateFieldPictureLocationField } from '~/components/upload/supportingObject/fieldPicture/UpdateFieldPictureLocationField';
import { yup } from '~/utils/validation';

export type UpdateFieldPictureFormValues = {
  author: string;
  description: string;
  location: google.maps.LatLngLiteral;
  locationApproximate: boolean;
  published: boolean;
};

function initialValues(fp: FieldPic): UpdateFieldPictureFormValues {
  return {
    author: fp.author ?? '',
    description: fp.description ?? '',
    location: { lat: fp.location.lat, lng: fp.location.lng },
    locationApproximate: fp.locationApproximate,
    published: fp.published,
  };
}

const validationSchema = yup.object({
  author: yup.string().optional().nullable(),
  description: yup.string().optional().nullable(),
  published: yup.boolean().required(),
});

const UPDATE_FIELD_PICTURE = gql`
  mutation UpdateFieldPicture(
    $id: Int!
    $fieldPicture: UpdateFieldPictureInput!
  ) {
    updateFieldPicture(id: $id, fieldPicture: $fieldPicture) {
      ...fieldPictureParts
    }
  }

  ${fieldPictureParts}
`;

type FieldPic =
  schema.UploadOutcropFieldPicsPageQuery['outcropList'][number]['fieldPictures'][number];

type Props = {
  fieldPicture: FieldPic;
  onUpdateSuccess: () => void;
};

export function UpdateFieldPictureForm({
  fieldPicture,
  onUpdateSuccess,
}: Props) {
  const [updateFieldPicture, { loading, error }] = useMutation<
    schema.UpdateFieldPictureMutation,
    schema.UpdateFieldPictureMutationVariables
  >(UPDATE_FIELD_PICTURE);

  async function handleSubmit(values: UpdateFieldPictureFormValues) {
    const input: schema.UpdateFieldPictureInput = {
      author: values.author?.trim() || null,
      description: values.description?.trim() || null,
      location: values.location,
      locationApproximate: values.locationApproximate,
      published: values.published,
    };

    try {
      await updateFieldPicture({
        variables: {
          id: fieldPicture.id,
          fieldPicture: input,
        },
      });
      toast.success('Changes saved successfully.');
      onUpdateSuccess();
    } catch (err) {
      console.log('Error updating field picture', err);
      toast.error('There was a problem saving, please try again.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues(fieldPicture)}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="space-y-2">
          <Field name="author" label="Author" component={FormikField} />
          <Field
            name="description"
            label="Description"
            component={FormikField}
          />

          <UpdateFieldPictureLocationField />

          <Field
            name="locationApproximate"
            label="Approximate location"
            component={FormikField}
            type="checkbox"
          />

          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
