import React from 'react';

type Props = {
  issueType: string;
  onChange: (value: string) => void;
};

export function IssueTypeFilter({ issueType, onChange }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    onChange(event.target.value);

  const filterLabel = issueType !== '' ? 'All types' : 'Filter issue types:';

  return (
    <div className="form-control inline-block">
      <select
        value={issueType}
        onChange={handleChange}
        className="select select-bordered select-sm"
      >
        <option value="">{filterLabel}</option>
        <option value="Bug">Bug</option>
        <option value="Feature request">Feature Request</option>
        <option value="Support">Support</option>
      </select>
    </div>
  );
}
