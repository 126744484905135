import type {
  DepositionalWiki,
  DepositionalWikiInput,
} from '~/apollo/generated/schema';
import { DepositionalWikiType } from '~/apollo/generated/schema';
import { yup } from '~/utils/validation';

export const depositionalWikiTypes: DepositionalWikiType[] = [
  DepositionalWikiType.GrossDepositionalEnvironment,
  DepositionalWikiType.DepositionalEnvironment,
  DepositionalWikiType.DepositionalSubEnvironment,
  DepositionalWikiType.ArchitecturalElement,
  DepositionalWikiType.NotSet,
];

export interface DepositionalWikiFormValues {
  title: string;
  intro: string;
  description: string;
  diagnostic: string;
  reservoir: string;
  subsurface: string;
  modeling: string;
  mafLink: string;
  geologyType: string;
  type: DepositionalWikiType;
  value: string[];
  author: string;
  modernExample: string;
  references: string;
  published: boolean;
  readyForApproval: boolean;
  vomExampleId: number | null;
}

export function defaultWikiPage(
  wiki?: Partial<DepositionalWiki>,
): DepositionalWikiFormValues {
  return {
    title: wiki?.title ?? '',
    intro: wiki?.intro ?? '',
    description: wiki?.description ?? '',
    diagnostic: wiki?.diagnostic ?? '',
    reservoir: wiki?.reservoir ?? '',
    subsurface: wiki?.subsurface ?? '',
    modeling: wiki?.modeling ?? '',
    mafLink: wiki?.mafLink ?? '',
    geologyType: wiki?.geologyType ?? '',
    type: wiki?.type ?? depositionalWikiTypes[0],
    value: wiki?.value ?? [],
    author: wiki?.author ?? '',
    modernExample: wiki?.modernExample ?? '',
    references: wiki?.references ?? '',
    published: wiki?.published ?? false,
    readyForApproval: wiki?.readyForApproval ?? false,
    vomExampleId: wiki?.vomExampleId ?? null,
  };
}

export function toDepositionalWikiInput(
  values: DepositionalWikiFormValues,
): DepositionalWikiInput {
  return {
    title: values.title,
    geologyType: values.geologyType,
    type: values.type ?? [],
    value: values.value ?? [],
    intro: values.intro || null,
    description: values.description || null,
    diagnostic: values.diagnostic || null,
    reservoir: values.reservoir || null,
    subsurface: values.subsurface || null,
    modeling: values.modeling || null,
    mafLink: values.mafLink || null,
    author: values.author || null,
    modernExample: values.modernExample || null,
    references: values.references || null,
    published: values.published,
    readyForApproval: values.readyForApproval,
    vomExampleId: values.vomExampleId || null,
  };
}

export const validationSchema = yup.object({
  // Required
  title: yup.string().label('title').required(),
  geologyType: yup.string().label('geology type').required(),
  type: yup.string().label('type').required(),
  value: yup.array(yup.string()).min(1).label('value').required(),
  published: yup.bool().required(),
  readyForApproval: yup.bool().required(),

  // Nullable
  intro: yup.string().label('intro').nullable(),
  description: yup.string().label('description').nullable(),
  diagnostic: yup.string().label('diagnostic').nullable(),
  reservoir: yup.string().label('reservoir').nullable(),
  subsurface: yup.string().label('subsurface').nullable(),
  modeling: yup.string().label('modeling').nullable(),
  mafLink: yup.string().label('maf link').nullable(),
  author: yup.string().nullable(),
  modernExample: yup.string().nullable(),
  references: yup.string().nullable(),
  vomExampleId: yup.number().integer().nullable().label('vom example'),
});
