import { gql, useQuery } from '@apollo/client';
import { faPencil, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import type { UploadProjectListPageQuery } from '~/apollo/generated/schema';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useSortFilter } from '~/hooks/data';
import { uploadProjectCreateRoute, uploadProjectUpdateRoute } from '~/paths';

const UPLOAD_PROJECT_LIST_PAGE = gql`
  query UploadProjectListPage {
    projectList {
      id
      name
      studyAccess {
        id
        company {
          id
          name
        }
      }
    }
  }
`;

export default function UploadProjectListPage() {
  const { data, loading } = useQuery<UploadProjectListPageQuery>(
    UPLOAD_PROJECT_LIST_PAGE,
  );

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    data?.projectList ?? [],
    'name',
    'name',
    'uploadProjectList',
  );

  if (loading) return <SpinnerPlaceholder />;

  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>
          Project List
          <small className="text-muted ml-2">
            (<strong>{items.length}</strong> results displayed)
          </small>
        </Panel.Title>
        <Link
          to={uploadProjectCreateRoute()}
          className="btn btn-primary btn-xs gap-1"
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Create Project
        </Link>
      </Panel.Heading>

      <Panel.Body>
        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>
                <SortTrigger colName="id" sortIndicatorProps={siProps}>
                  ID
                </SortTrigger>
              </th>
              <th>
                <SortTrigger colName="name" sortIndicatorProps={siProps}>
                  Project
                </SortTrigger>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {items.map(project => (
              <tr key={project.id}>
                <td>{project.id}</td>
                <td>
                  <Link
                    to={uploadProjectUpdateRoute(project.id)}
                    className="link"
                  >
                    {project.name}
                  </Link>
                </td>
                <td className="text-right">
                  <Link
                    to={uploadProjectUpdateRoute(project.id)}
                    className="btn btn-ghost btn-sm gap-1"
                  >
                    <FontAwesomeIcon icon={faPencil} /> Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
}
