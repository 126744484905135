import { useQuery , gql } from '@apollo/client';
import { faEyeSlash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-daisyui';
import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import * as fragments from '~/apollo/fragments';
import type {
  StudyRouteQuery,
  StudyRouteQueryVariables,
} from '~/apollo/generated/schema';
import {
  BookmarkParentType,
  BookmarkTargetType,
  ReviewCommentParentType,
  Role,
} from '~/apollo/generated/schema';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import type { BreadcrumbItem } from '~/components/layout/Breadcrumb';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { TargetReviewCommentsModal } from '~/components/reviewComment/TargetReviewCommentsModal';
import * as routes from '~/paths';
import { StudyRouteNavigation } from '~/routes/study/__StudyRouteNavigation';

const STUDY_ROUTE = gql`
  query StudyRoute($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      dataHistory {
        ...dataHistoryParts
      }
      qualityParameters {
        ...studyQualityParametersParts
      }
      outcrops {
        id
        name
        region {
          id
          name
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.studyQualityParametersParts}
  ${fragments.dataHistoryParts}
`;

export function prependBreadcrumb(
  study?: StudyRouteQuery['studyList'][number],
): BreadcrumbItem[] {
  if (!study) return [];

  const breadcrumb: BreadcrumbItem[] = [];

  // If the study only belongs to a single outcrop,
  // prepend the outcrop's region and outcrop name to the breadcrumb
  if (study.outcrops.length === 1) {
    const oc = study.outcrops[0];
    if (!oc.region) {
      throw new Error('study.outcrops.region is not loaded');
    }

    breadcrumb.push({
      routeId: routes.regionRoute(oc.region.id),
      pathname: routes.regionRoute(oc.region.id),
      title: oc.region.name,
    });
    breadcrumb.push({
      routeId: routes.outcropRoute(oc.id),
      pathname: routes.outcropRoute(oc.id),
      title: oc.name,
    });
  }

  return breadcrumb;
}

type OutletContext = {
  study: StudyRouteQuery['studyList'][number];
};

export default function StudyRoute() {
  const params = useParams<{ studyId: string }>();
  invariant(params.studyId, 'studyId param required');
  const studyId = parseInt(params.studyId);

  const { data, loading } = useQuery<StudyRouteQuery, StudyRouteQueryVariables>(
    STUDY_ROUTE,
    {
      variables: { studyId },
    },
  );

  const study = data?.studyList.find(s => s.id === studyId);

  useBreadcrumb(
    'routes/study/$studyId',
    study?.name ?? 'Study',
    null,
    prependBreadcrumb(study),
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  const outletContext: OutletContext = {
    study,
  };

  return (
    <>
      {!study.approved && (
        <Alert status="info">
          <FontAwesomeIcon icon={faEyeSlash} className="text-2xl" />

          <div>
            <Heading level={3}>Study Not Approved</Heading>
            <p>
              This outcrop has not been approved and is only visible to admins.
            </p>
          </div>
        </Alert>
      )}

      <div className="float-right space-x-1">
        <TargetBookmarksManagerModal
          parentType={BookmarkParentType.Study}
          parentId={studyId}
          targetType={BookmarkTargetType.Study}
          targetId={studyId}
          path={routes.studyRoute(studyId)}
        />

        <TargetReviewCommentsModal
          parentType={ReviewCommentParentType.Study}
          parentId={study.id}
          name={study.name}
        />

        <RoleSecured roles={[Role.RoleAdmin]}>
          <Link
            to={routes.uploadStudyUpdateRoute(studyId)}
            className="btn btn-ghost btn-sm gap-1"
          >
            <FontAwesomeIcon icon={faPencil} /> Edit
          </Link>
        </RoleSecured>
      </div>

      <PageHeading>{study.name}</PageHeading>

      {study.dataHistory?.collectedBy && (
        <div>
          <strong>Author:</strong> {study.dataHistory.collectedBy}
        </div>
      )}
      {study.dataHistory?.date && (
        <div>
          <strong>Year:</strong> {study.dataHistory.date}
        </div>
      )}
      {!!study.qualityParameters?.publicationType?.length && (
        <div>
          <strong>Publication type:</strong>{' '}
          {study.qualityParameters.publicationType.join(', ')}
        </div>
      )}

      <div className="clear-both" />

      <div className="grid lg:grid-cols-12 gap-6 mt-4">
        <div className="lg:col-span-2">
          <StudyRouteNavigation studyId={studyId} />
        </div>

        <div className="lg:col-span-10">
          <Outlet context={outletContext} />
        </div>
      </div>
    </>
  );
}

export function useStudyOutletContext() {
  return useOutletContext<OutletContext>();
}
