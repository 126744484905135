import sanitize from 'sanitize-filename';

export function saveAs(data: string, filename: string, type: string) {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  a.setAttribute('href', url);
  a.setAttribute('download', sanitize(filename));

  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

export function saveAsCSV(data: string, filename: string) {
  return saveAs(data, filename, 'text/csv');
}

export function timestamp() {
  return new Date().toISOString().split('.')[0].replace(/\D/g, '');
}
