import React from 'react';
import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';

export function PaleoMapFormFields() {
  return (
    <>
      <Field
        name="paleoMap.description"
        label="Description"
        component={FormikField}
      />
    </>
  );
}
