import type { Viewer } from 'cesium';
import { Cartographic, HeadingPitchRoll } from 'cesium';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import type { SearchOutcropsOutcropResultsQuery } from '~/apollo/generated/schema';
import type { vomLocations } from '~/components/cesium/CesiumGlobeViewer';
import { CesiumGlobeViewer } from '~/components/cesium/CesiumGlobeViewer';
import type {
  DataSourcePoint,
  TilesetParams,
} from '~/components/cesium/cesiumUtils';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { locationSchema } from '~/utils/modules/placement';

const cesiumAssetSchema = z.object({
  id: z.number().optional(),
  assetToken: z.string(),
  localPath: z.string(),
  location: locationSchema,
});

export type OutcropData = {
  id: number;
  point: DataSourcePoint;
  tilesets: TilesetParams[];
};

type Outcrop =
  SearchOutcropsOutcropResultsQuery['searchOutcrops']['outcrops'][number];

type Props = {
  outcropData: Outcrop[];
  loading: boolean;
  setOutcropIdsInView: React.Dispatch<React.SetStateAction<number[]>>;
};

export default function GlobeViewer({
  outcropData,
  loading,
  setOutcropIdsInView,
}: Props) {
  const [centersState, setCentersState] = useState<vomLocations[]>([]);
  const [tilesetsState, setTilesetsState] = useState<TilesetParams[]>([]);

  useEffect(() => {
    if (outcropData && !loading) {
      const outcropDatas: OutcropData[] = [];
      outcropData.forEach(outcrop => {
        if (outcrop.center) {
          const outcropCenterPoint = {
            latitude: outcrop.center.lat,
            longitude: outcrop.center.lng,
            name: outcrop.name,
            id: outcrop.id,
          };
          const cesiumAssets = outcrop.virtualOutcropModels.map(vom => {
            return vom.cesiumAsset;
          });
          const ts = cesiumAssets.flatMap(cesiumAsset => {
            const parsed = cesiumAssetSchema.safeParse(cesiumAsset);
            if (parsed.success) {
              const ca = parsed.data;
              return [
                {
                  id: parsed.data.id,
                  assetToken: parsed.data.assetToken,
                  localPath: parsed.data.localPath,
                  transform: {
                    location: Cartographic.fromDegrees(
                      ca.location.longitude,
                      ca.location.latitude,
                      ca.location.height,
                    ),
                    orientation: new HeadingPitchRoll(
                      ca.location.heading,
                      ca.location.pitch,
                      ca.location.roll,
                    ),
                  },
                },
              ];
            } else {
              return [];
            }
          });
          if (ts.length > 0 && outcropCenterPoint) {
            outcropDatas.push({
              id: outcrop.id,
              point: outcropCenterPoint,
              tilesets: ts,
            } as OutcropData);
          }
        }
      });
      const centers = outcropDatas.map(outcropData => {
        return outcropData.point;
      });
      setCentersState(centers)
      const tilesets = outcropDatas
        .map(outcropData => {
          return outcropData.tilesets;
        })
        .flat();
        setTilesetsState(tilesets)
    }
  }, [outcropData, loading]);

  if (loading) {
    return <SpinnerPlaceholder />
  }

  return (
    <CesiumGlobeViewer
      initialOutcropLocations={centersState}
      initialTilesets={tilesetsState}
      setOutcropIdsInView={setOutcropIdsInView}
      zoomTo={false}
    />
  );
}
