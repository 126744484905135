import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import {
  Role,
  type CompanyAdminUsersPageQuery,
  type CompanyAdminUsersPageQueryVariables,
} from '~/apollo/generated/schema';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { LocalDate } from '~/components/common/LocalDate';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CompanyAdminUserList } from '~/components/company/CompanyAdminUserList';
import { CreateUserModal } from '~/components/company/CreateUserModal';
import { useAuth } from '~/contexts/auth';

const COMPANY_ADMIN_USERS_PAGE = gql`
  query CompanyAdminUsersPage($id: Int!) {
    companyList(id: $id) {
      ...companyParts
      user {
        ...userParts
      }
    }
  }

  ${fragments.companyParts}
  ${fragments.userParts}
`;

export default function CompanyAdminUsersPage() {
  const { authority } = useAuth();
  invariant(authority, 'Must be authenticated');
  const companyId = authority.user.companyId;

  const { data, loading } = useQuery<
    CompanyAdminUsersPageQuery,
    CompanyAdminUsersPageQueryVariables
  >(COMPANY_ADMIN_USERS_PAGE, {
    variables: { id: companyId },
  });

  const refetchQueries: [
    PureQueryOptions<CompanyAdminUsersPageQueryVariables>,
  ] = [
    {
      query: COMPANY_ADMIN_USERS_PAGE,
      variables: { id: companyId },
    },
  ];

  const company = data?.companyList.find(c => c.id === companyId);

  if (loading) return <SpinnerPlaceholder />;
  if (!company) return <NotFound />;

  const activeUsers = company.user.filter(user => !user.accountLocked);

  return (
    <Panel>
      <Panel.Heading>
        <div className="float-right">
          <CreateUserModal company={company} refetchQueries={refetchQueries}>
            {toggleCreateUserModal => (
              <Button
                color="primary"
                size="xs"
                onClick={toggleCreateUserModal}
                className="gap-2"
              >
                <FontAwesomeIcon icon={faPlus} />
                Create User
              </Button>
            )}
          </CreateUserModal>
        </div>
        <Panel.Title>
          <strong>Users</strong> {activeUsers.length} active users
          <RoleSecured roles={[Role.RoleAdmin]}>
            <div className="text-slate-600 text-sm">
              {company.lastActivity && (
                <>
                  Last Activity <LocalDate date={company.lastActivity} />
                </>
              )}
              {company.activityCount && (
                <>
                  {' - '}
                  Activity Count {company.activityCount}
                </>
              )}
            </div>
          </RoleSecured>
        </Panel.Title>
      </Panel.Heading>

      <Panel.Body>
        <CompanyAdminUserList users={company.user} />
      </Panel.Body>
    </Panel>
  );
}
