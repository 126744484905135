import { gql, useMutation } from '@apollo/client';
import type { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { bookmarkCollectionParts } from '~/apollo/fragments';
import type {
  PinCollectionMutation,
  PinCollectionMutationVariables,
} from '~/apollo/generated/schema';

const PIN_COLLECTION = gql`
  mutation PinCollection($collectionId: Int!, $isPinned: Boolean!) {
    pinBookmarkCollection(collectionId: $collectionId, isPinned: $isPinned) {
      ...bookmarkCollectionParts
    }
  }

  ${bookmarkCollectionParts}
`;

export function PinCollectionToggle({
  collectionId,
  isPinned,
  children,
}: {
  collectionId: number;
  isPinned: boolean;
  children: (pinCollection: () => Promise<void>, loading: boolean) => ReactNode;
}) {
  const [pinCollection, { loading }] = useMutation<
    PinCollectionMutation,
    PinCollectionMutationVariables
  >(PIN_COLLECTION, {
    variables: {
      collectionId,
      isPinned: !isPinned,
    },
  });

  async function handlePin() {
    if (isPinned) {
      const msg = 'Are you sure you want to unpin this collection?';
      if (!window.confirm(msg)) {
        return;
      }
    }

    try {
      const result = await pinCollection();
      const newPinned = result.data?.pinBookmarkCollection.isPinned;
      toast.success(
        `Collection ${newPinned ? 'pinned to' : 'unpinned from'} sidebar.`,
      );
    } catch (err) {
      console.log('Error pinning/unpinning', err);
      toast.error(
        'There was a problem pinning or unpinning your collection. Please reload the page and try again.',
      );
    }
  }

  return children(handlePin, loading);
}
