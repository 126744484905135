import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useDataSearchContext } from '~/components/dataSearch/dataSearchContext';
import { useDebounce } from '~/hooks/debounce';
import { binData, type HistogramDataPoint } from '~/utils/chart';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';

const DEBOUNCE_DUR = 1000;

export function useHistogramData() {
  const { values } = useFormikContext<DataSearchFormValues>();
  const { measurements } = useDataSearchContext();

  const minX = useDebounce(values.histogram.minX, DEBOUNCE_DUR);
  const maxX = useDebounce(values.histogram.maxX, DEBOUNCE_DUR);
  const groupDataBy = useDebounce(values.groupDataBy, DEBOUNCE_DUR);
  const numBins = useDebounce(values.histogram.numBins, DEBOUNCE_DUR);
  const binWidth = useDebounce(values.histogram.binWidth, DEBOUNCE_DUR);

  const dataHistogram = useMemo<HistogramDataPoint[]>(() => {
    // When the graph type isn't a histogram, short circuit to prevent unnecessary calculations
    if (values.graphType !== 'histogram') {
      return [];
    }

    return measurements
      .filter(d => {
        // If the data is constrained, filter data outside provided values
        if (minX && d.x < minX) return false;
        if (maxX && d.x > maxX) return false;
        return true;
      })
      .map(d => ({
        label: d[groupDataBy] ?? 'unknown',
        value: d.x,
      }));
  }, [values.graphType, measurements, minX, maxX, groupDataBy]);

  const numBinsValue = useMemo(() => {
    const val = numBins;
    if (val && val > 1) return val;
    return undefined;
  }, [numBins]);

  const binWidthValue = useMemo(() => {
    const val = binWidth;
    if (val && val > 0) return val;
    return undefined;
  }, [binWidth]);

  const binnedData = useMemo(() => {
    if (!dataHistogram.length) return null;
    return binData(
      dataHistogram,
      numBinsValue,
      binWidthValue,
      values.histogram.nice,
    );
  }, [dataHistogram, numBinsValue, binWidthValue, values.histogram.nice]);

  const dataRange = useMemo<[null, null] | [number, number]>(() => {
    if (!measurements.length || values.graphType !== 'histogram') {
      return [null, null];
    }

    const xValues = measurements.map(m => m.x);
    return [Math.min(...xValues), Math.max(...xValues)];
  }, [values.graphType, measurements]);

  return {
    dataHistogram,
    binnedData,
    dataRange,
  };
}
