import * as R from 'ramda';
import { Link } from 'react-router-dom';
import type {
  LithostratAgePartsFragment,
  LithostratOutcropLinkPartsFragment,
} from '~/apollo/generated/schema';
import { FullAgePopover } from '~/components/geologicalAge/FullAgePopover';
import {
  KPCol,
  KPGrid,
} from '~/components/keyParameter/KeyParametersPanel/CombinedView';
import { WikiPopover } from '~/components/wiki/WikiPopover';
import { LithostratWikiLink } from '~/components/wiki/lithostrat/LithostratWikiLink';
import { otherWikiDetailRoute, outcropPalaeogeographyTabRoute } from '~/paths';

type Props = {
  outcropId?: number;
  basinName?: string | null;
  basinType?: string | null;
  climate?: string | null;
  netToGross?: string | null;
  startAge?: LithostratAgePartsFragment | null;
  endAge?: LithostratAgePartsFragment | null;
  lithostratLinks?: LithostratOutcropLinkPartsFragment[];
  leftColMd?: number;
  rightColMd?: number;
};

export function OutcropDetailsPanel({
  outcropId,
  basinName,
  basinType,
  climate,
  netToGross,
  startAge,
  endAge,
  lithostratLinks,
  leftColMd = 4,
  rightColMd = 8,
}: Props) {
  const isNilOrEmpty = R.either(R.isNil, R.isEmpty);
  if (R.all(isNilOrEmpty, [basinName, climate, basinType])) {
    return null;
  }

  return (
    <>
      {basinName && outcropId && (
        <KPGrid>
          <KPCol span={leftColMd}>
            <strong>Basin name</strong>
          </KPCol>
          <KPCol span={rightColMd}>
            <Link
              to={outcropPalaeogeographyTabRoute(outcropId)}
              target="_blank"
              className="link"
            >
              {basinName}
            </Link>
          </KPCol>
        </KPGrid>
      )}

      {basinType && (
        <KPGrid>
          <KPCol span={leftColMd}>
            <strong>Basin type</strong>{' '}
            <WikiPopover
              otherWikiId={53}
              popoverProps={{
                align: 'start',
                side: 'left',
              }}
            />
          </KPCol>
          <KPCol span={rightColMd}>
            <Link
              to={otherWikiDetailRoute(53)}
              target="_blank"
              className="link"
            >
              {basinType}
            </Link>
          </KPCol>
        </KPGrid>
      )}

      {climate && (
        <KPGrid>
          <KPCol span={leftColMd}>
            <strong>Palaeoclimate</strong>{' '}
            <WikiPopover
              otherWikiId={46}
              popoverProps={{
                align: 'start',
                side: 'left',
              }}
            />
          </KPCol>
          <KPCol span={rightColMd}>
            <Link
              to={otherWikiDetailRoute(46)}
              target="_blank"
              className="link"
            >
              {climate}
            </Link>
          </KPCol>
        </KPGrid>
      )}

      {netToGross && (
        <KPGrid>
          <KPCol span={leftColMd}>
            <strong>Net to gross</strong>{' '}
            <WikiPopover
              otherWikiId={71}
              popoverProps={{
                align: 'start',
                side: 'left',
              }}
            />
          </KPCol>
          <KPCol span={rightColMd}>
            <Link
              to={otherWikiDetailRoute(71)}
              target="_blank"
              className="link"
            >
              {netToGross}
            </Link>
          </KPCol>
        </KPGrid>
      )}

      {(startAge || endAge) && outcropId && (
        <KPGrid>
          <KPCol span={leftColMd}>
            <strong>Outcrop age</strong>
          </KPCol>
          <KPCol span={rightColMd}>
            <FullAgePopover
              startAge={startAge}
              endAge={endAge}
              renderAgeText={basinAgeText => (
                <Link
                  to={outcropPalaeogeographyTabRoute(outcropId)}
                  className="link"
                >
                  {basinAgeText}
                </Link>
              )}
            />
          </KPCol>
        </KPGrid>
      )}

      {lithostratLinks && lithostratLinks.length > 0 && (
        <KPGrid>
          <KPCol span={4}>
            <strong>Lithostratigraphy</strong>
          </KPCol>
          <KPCol span={8}>
            {lithostratLinks.map(link => (
              <div key={link.id}>
                <LithostratWikiLink lithostratLink={link} />
              </div>
            ))}
          </KPCol>
        </KPGrid>
      )}
    </>
  );
}
