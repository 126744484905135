import React from 'react';
import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { FormikTinyMceField } from '~/components/common/FormikField/FormikTinyMceField';

export function OtherWikiFormFields() {
  return (
    <div className="space-y-2">
      <Field name="title" label="Title" component={FormikField} required />

      <Field
        name="text"
        label="Text"
        component={FormikField}
        type={FormikTinyMceField}
        config={{ height: 300 }}
        required
      />
    </div>
  );
}
