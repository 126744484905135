import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = Omit<FontAwesomeIconProps, 'icon'> & {
  expanded: boolean;
};

export function ExpandedIcon({ expanded, ...faProps }: Props) {
  return (
    <FontAwesomeIcon
      icon={faChevronRight}
      style={{
        transform: expanded ? 'rotate(90deg)' : undefined,
        transition: 'transform 0.5s',
      }}
      {...faProps}
    />
  );
}
