import { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { FormikField } from '~/components/common/FormikField';

export type UtmFormValues = {
  northing: number;
  easting: number;
  zone: number;
  hemisphere: string;
};

type UtmPlacementFieldsProps = {
  utmPlacement: UtmFormValues;
};

export default function VomUtmFields({
  utmPlacement,
}: UtmPlacementFieldsProps) {
  const { setFieldValue } = useFormikContext<UtmFormValues>();

  useEffect(() => {
    setFieldValue('northing', utmPlacement.northing);
    setFieldValue('easting', utmPlacement.easting);
    setFieldValue('zone', utmPlacement.zone);
    setFieldValue('hemisphere', utmPlacement.hemisphere);
  }, [utmPlacement]);

  return (
    <div className="m-4">
      <div className="grid grid-cols-subgrid gap-4 col-span-1">
        <Field
          name="northing"
          label="northing"
          component={FormikField}
          type="number"
          placeholder="Northing"
        />
        <Field
          name="easting"
          label="easting"
          component={FormikField}
          type="number"
          placeholder="Easting"
        />
        <Field
          name="zone"
          label="zone"
          component={FormikField}
          type="number"
          placeholder="Zone"
        />
        <Field
          name="hemisphere"
          label="hemisphere"
          component={FormikField}
          type="radio"
          inline={true}
          options={[
            { text: 'North', value: 'north', disabled: false },
            { text: 'South', value: 'south', disabled: false },
          ]}
        />
      </div>
    </div>
  );
}
