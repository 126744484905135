export const defaultDateFormat: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export function toLocalDateString(
  date: Date | string,
  format: Intl.DateTimeFormatOptions = defaultDateFormat,
) {
  const locale = window.navigator.language;
  const dateObj = new Date(date);
  const formatter = new Intl.DateTimeFormat(locale, format);
  return formatter.format(dateObj);
}
