import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import type {
  CreateStudyMutation,
  CreateStudyMutationVariables,
  StudyEnumerationsQuery,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { uploadStudyUpdateRoute } from '~/paths';
import type { StudyFormValues } from '~/utils/modules/study';
import {
  defaultStudy,
  toStudyInput,
  validationSchema,
} from '~/utils/modules/study';
import { StudyOverviewFormFields } from './StudyOverviewFormFields';

const CREATE_STUDY = gql`
  mutation CreateStudy($study: StudyInput!) {
    createStudy(study: $study) {
      id
    }
  }
`;

type Props = {
  outcropId?: number | null;
  enumOptions: StudyEnumerationsQuery;
};

export function CreateStudyForm({ outcropId = null, enumOptions }: Props) {
  const navigate = useNavigate();
  const [createStudy, { loading, error }] = useMutation<
    CreateStudyMutation,
    CreateStudyMutationVariables
  >(CREATE_STUDY, {});

  async function handleSubmit(values: StudyFormValues) {
    const study = toStudyInput(values);

    try {
      const res = await createStudy({ variables: { study } });
      const studyId = res.data?.createStudy.id;
      invariant(studyId);

      toast.success('Study created successfully');
      navigate(uploadStudyUpdateRoute(studyId));
    } catch (err) {
      console.log('Error creating study', err);
      toast.error('There was a problem creating the study.');
    }
  }

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Study Overview</Panel.Title>
      </Panel.Heading>

      <Formik
        onSubmit={handleSubmit}
        initialValues={defaultStudy(null, outcropId)}
        validationSchema={validationSchema()}
      >
        <Form>
          <Panel.Body>
            <StudyOverviewFormFields
              action="create"
              enumOptions={enumOptions}
            />
            <FormErrors graphQLError={error} />
          </Panel.Body>
          <Panel.Footer className="flex justify-end">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              Save
            </button>
          </Panel.Footer>
        </Form>
      </Formik>
    </Panel>
  );
}
