import { Form, Formik } from 'formik';
import * as R from 'ramda';
import type {
  DepositionalWikiPartsFragment,
  KeyParametersPartsFragment,
  LithostratFormationPartsFragment,
  LithostratGroupPartsFragment,
  LithostratMemberPartsFragment,
  LithostratWikiPagePartsFragment,
} from '~/apollo/generated/schema';
import { SearchFilter } from '~/components/analogueSearch/SearchFilter';
import SearchOutcropsQuery from '~/components/analogueSearch/SearchOutcropsQuery';
import { SearchResultItem } from '~/components/analogueSearch/SearchResultItem';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import type { AnalogueSearchDefaults } from '~/utils/modules/analogueSearch';
import { defaultAnalogueSearch } from '~/utils/modules/analogueSearch';
import type { MergedKeyParameters } from '~/utils/modules/keyParameters';
import { mergeKeyParameters } from '~/utils/modules/keyParameters';

function hasAnyKPs(kps: MergedKeyParameters) {
  const values = Object.values(kps) as string[][];
  const flat = R.flatten(values);
  return filterUndefined(flat).length > 0;
}

function filterUndefined(items: string[]) {
  return items.filter(
    item => !!item && !['undefined', 'unknown'].includes(item),
  );
}

type LithostratParent = (
  | LithostratGroupPartsFragment
  | LithostratFormationPartsFragment
  | LithostratMemberPartsFragment
) & {
  wikiPage?:
    | (LithostratWikiPagePartsFragment & {
        keyParameters?: KeyParametersPartsFragment[] | null;
      })
    | null;
};

type ExampleType = LithostratParent | DepositionalWikiPartsFragment;

type Props = {
  parentType: 'group' | 'formation' | 'member' | 'depositionalWiki';
  parent: ExampleType;
};

/**
 * Outcrops found by searching with the parent's key parameters
 * NOTE: Called "Linked Analogues" in the UI
 */
export function OutcropExamples({ parentType, parent }: Props) {
  if ('wikiPage' in parent) {
    const wp = parent.wikiPage;
    const kps = mergeKeyParameters(wp?.keyParameters ?? []);
    if (
      // No lithowiki pages have a geology type set, so this must be ignored for now
      // !wp.geologyType ||
      !hasAnyKPs(kps) ||
      !wp?.basinType ||
      !wp?.climate
    ) {
      console.log(
        `${parentType} missing one of the following: basin type, climate, or key parameters.`,
      );
      return (
        <div className="text-center text-muted" style={{ marginTop: '20px' }}>
          <em>No outcrops have been linked to this object yet.</em>
        </div>
      );
    }
  }

  const initialQuery: AnalogueSearchDefaults = {};

  // Build the default query based on parameters defined in the parent
  if (
    parentType === 'depositionalWiki' &&
    parent.__typename === 'DepositionalWiki'
  ) {
    // Depositional wiki pages have a "type" and "value"
    // Type corresponds to either GDE, DE, DSE, or AE
    const dw = parent;
    initialQuery.geologyType = [dw.geologyType];
    if (dw.type === 'GROSS_DEPOSITIONAL_ENVIRONMENT')
      initialQuery.grossDepositionalEnvironment = filterUndefined(
        dw.value ?? [],
      );
    else if (dw.type === 'DEPOSITIONAL_ENVIRONMENT')
      initialQuery.depositionalEnvironment = filterUndefined(dw.value ?? []);
    else if (dw.type === 'DEPOSITIONAL_SUB_ENVIRONMENT')
      initialQuery.depositionalSubEnvironment = filterUndefined(dw.value ?? []);
    else if (dw.type === 'ARCHITECTURAL_ELEMENT')
      initialQuery.architecturalElement = filterUndefined(dw.value ?? []);
  } else if ('wikiPage' in parent && parent.wikiPage) {
    const lw = parent.wikiPage;

    if (lw.geologyType) initialQuery.geologyType = lw.geologyType;

    const kps = mergeKeyParameters(lw.keyParameters ?? []);
    initialQuery.grossDepositionalEnvironment = filterUndefined(
      kps.grossDepositionalEnvironment,
    );
    initialQuery.depositionalEnvironment = filterUndefined(
      kps.depositionalEnvironment,
    );
    initialQuery.depositionalSubEnvironment = filterUndefined(
      kps.depositionalSubEnvironment,
    );
    initialQuery.architecturalElement = filterUndefined(
      kps.architecturalElement,
    );
  } else {
    return (
      <div className="text-center text-muted" style={{ marginTop: '20px' }}>
        <em>No outcrops have been linked to this object yet.</em>
      </div>
    );
  }

  return (
    <Formik
      initialValues={defaultAnalogueSearch(initialQuery)}
      onSubmit={() => {}}
    >
      <Form>
        <SearchOutcropsQuery>
          {({ outcrops, options, loadingOptions, loadingOutcrops }) => (
            <div className="grid lg:grid-cols-3 gap-6">
              <div className="space-y-4">
                <SearchFilter
                  title="Gross Depositional Environment"
                  name="grossDepositionalEnvironment"
                  options={options.grossDepositionalEnvironment}
                  disabled={loadingOptions}
                />
                <SearchFilter
                  title="Depositional Environment"
                  name="depositionalEnvironment"
                  options={options.depositionalEnvironment}
                  disabled={loadingOptions}
                  onlyTop={3}
                />
                <SearchFilter
                  title="Subenvironment"
                  name="depositionalSubEnvironment"
                  options={options.depositionalSubEnvironment}
                  disabled={loadingOptions}
                  onlyTop={3}
                />
                <SearchFilter
                  title="Architectural Element"
                  name="architecturalElement"
                  options={options.architecturalElement}
                  disabled={loadingOptions}
                  onlyTop={3}
                />
              </div>

              <div className="lg:col-span-2">
                <SpinnerPlaceholder show={loadingOutcrops && !outcrops.length}>
                  Searching for similar outcrops...
                </SpinnerPlaceholder>

                {outcrops.map(oc => (
                  <SearchResultItem key={oc.id} outcrop={oc} />
                ))}
              </div>
            </div>
          )}
        </SearchOutcropsQuery>
      </Form>
    </Formik>
  );
}
