import {
  faCheckCircle,
  faPencilRuler,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button } from 'react-daisyui';
import invariant from 'tiny-invariant';
import { ReportState } from '~/apollo/generated/schema';
import { ReportEditor } from '~/components/report/ReportEditor';
import { UpdateReportState } from '~/components/report/UpdateReportState';
import { useAuth } from '~/contexts/auth';
import { useEditReportOutletContext } from '~/routes/my-safari/reports/$reportId';
import { canEditReport } from '~/utils/modules/report';

export default function EditReportPage() {
  const { report, refetchQueries } = useEditReportOutletContext();
  const { authority } = useAuth();
  invariant(authority, 'Must be logged in');

  return (
    <>
      {report.state === ReportState.Completed && (
        <Alert status="success" className="mb-2">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} className="text-xl" />
          </div>
          <div className="space-y-2">
            <p>
              This report is currently marked Completed and is locked for
              editing.
            </p>
            {canEditReport(authority, report) && (
              <div>
                <UpdateReportState
                  reportId={report.id}
                  isCompany={!!report.companyId}
                  isCompleted
                >
                  {confirmPublish => (
                    <Button
                      type="button"
                      onClick={confirmPublish}
                      size="sm"
                      startIcon={<FontAwesomeIcon icon={faPencilRuler} />}
                    >
                      Mark as Draft
                    </Button>
                  )}
                </UpdateReportState>
              </div>
            )}
          </div>
        </Alert>
      )}

      <ReportEditor report={report} refetchQueries={refetchQueries} />
    </>
  );
}
