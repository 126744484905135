import { BookmarkTargetType } from '~/apollo/generated/schema';
import { BelongsTo } from '~/components/bookmark/BookmarkList/BookmarkItem';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import type { ReportDisplayReportItem } from '~/components/report/ReportDisplay/ReportItem';
import { ItemPermalink } from '~/components/report/ReportDisplay/ReportItem/ItemPermalink';
import {
  prettyTargetType,
  toStandardizedBookmark,
} from '~/utils/modules/bookmark';
import { latestRevision } from '~/utils/modules/dataSearch';
import { ReportItemGeologyWiki } from './ReportItemGeologyWiki';
import { ReportItemGigaPan } from './ReportItemGigaPan';
import { ReportItemHtmlContent } from './ReportItemHtmlContent';
import { ReportItemLithostratFormation } from './ReportItemLithostratFormation';
import { ReportItemLithostratGroup } from './ReportItemLithostratGroup';
import { ReportItemLithostratMember } from './ReportItemLithostratMember';
import { ReportItemOutcrop } from './ReportItemOutcrop';
import { ReportItemPicture } from './ReportItemPicture';
import { ReportItemRegion } from './ReportItemRegion';
import { ReportItemSavedDataSearch } from './ReportItemSavedDataSearch';
import { ReportItemStudy } from './ReportItemStudy';
import { ReportItemVom } from './ReportItemVom';

type BookmarkItem = NonNullable<ReportDisplayReportItem['bookmark']>;

function ReportItemContent({
  reportItem,
  bookmark,
}: {
  reportItem: ReportDisplayReportItem;
  bookmark: BookmarkItem;
}) {
  if (
    bookmark.targetType === BookmarkTargetType.Outcrop &&
    bookmark.targetOutcrop?.__typename === 'Outcrop'
  ) {
    return (
      <ReportItemOutcrop
        reportItem={reportItem}
        outcrop={bookmark.targetOutcrop}
      />
    );
  } else if (
    bookmark.targetType === BookmarkTargetType.Study &&
    bookmark.targetStudy?.__typename === 'Study'
  ) {
    return (
      <ReportItemStudy reportItem={reportItem} study={bookmark.targetStudy} />
    );
  } else if (
    bookmark.targetType === BookmarkTargetType.Region &&
    bookmark.targetRegion?.__typename === 'Region'
  ) {
    return <ReportItemRegion region={bookmark.targetRegion} />;
  } else if (
    bookmark.targetType === BookmarkTargetType.Vom &&
    bookmark.targetVirtualOutcropModel?.__typename === 'VirtualOutcropModel'
  ) {
    return <ReportItemVom vom={bookmark.targetVirtualOutcropModel} />;
  } else if (
    bookmark.targetType === BookmarkTargetType.Picture &&
    bookmark.targetPicture?.__typename === 'Picture'
  ) {
    return <ReportItemPicture picture={bookmark.targetPicture} />;
  } else if (
    bookmark.targetType === BookmarkTargetType.Formation &&
    bookmark.targetLithostratFormation?.__typename === 'LithostratFormation'
  ) {
    return (
      <ReportItemLithostratFormation
        formation={bookmark.targetLithostratFormation}
      />
    );
  } else if (
    bookmark.targetType === BookmarkTargetType.Group &&
    bookmark.targetLithostratGroup?.__typename === 'LithostratGroup'
  ) {
    return <ReportItemLithostratGroup group={bookmark.targetLithostratGroup} />;
  } else if (
    bookmark.targetType === BookmarkTargetType.Member &&
    bookmark.targetLithostratMember?.__typename === 'LithostratMember'
  ) {
    return (
      <ReportItemLithostratMember member={bookmark.targetLithostratMember} />
    );
  } else if (
    bookmark.targetType === BookmarkTargetType.GigaPan &&
    bookmark.targetGigaPan?.__typename === 'GigaPan'
  ) {
    return <ReportItemGigaPan gigaPan={bookmark.targetGigaPan} />;
  } else if (
    bookmark.targetType === BookmarkTargetType.DepositionalWiki &&
    bookmark.targetDepositionalWiki?.__typename === 'DepositionalWiki'
  ) {
    return (
      <ReportItemGeologyWiki geologyWiki={bookmark.targetDepositionalWiki} />
    );
  } else if (
    bookmark.targetType === BookmarkTargetType.OtherWiki &&
    bookmark.targetOtherWiki?.__typename === 'OtherWiki'
  ) {
    return <ReportItemHtmlContent content={bookmark.targetOtherWiki.text} />;
  } else if (
    bookmark.targetType === BookmarkTargetType.SavedDataSearch &&
    bookmark.targetSavedDataSearch?.__typename === 'SavedDataSearch'
  ) {
    const sds = bookmark.targetSavedDataSearch;
    const sdsData = latestRevision(bookmark.targetSavedDataSearch.data);
    if (!sdsData) {
      console.log(`Saved Data Search ${sds.id} missing latest revision!`);
      return null;
    }

    return (
      <ReportItemSavedDataSearch
        savedDataSearch={sds}
        savedDataSearchData={sdsData}
      />
    );
  }

  return null;
}

export function ReportItemBookmark({
  item,
  bookmark,
}: {
  item: ReportDisplayReportItem;
  bookmark: BookmarkItem;
}) {
  const stdBookmark = toStandardizedBookmark(bookmark);

  return (
    <div id={`bookmark-${bookmark.id}`}>
      <Panel>
        <Panel.Body>
          <div className="float-right text-base text-slate-500">
            {prettyTargetType(bookmark.targetType, bookmark.parentType)}
          </div>

          <Heading level={2}>
            {stdBookmark.targetName}
            <BelongsTo bookmark={stdBookmark} />
          </Heading>
          {item.text && (
            <p className="whitespace-pre-line text-slate-600">{item.text}</p>
          )}

          <div className="clear-both mb-2" />
          <ReportItemContent reportItem={item} bookmark={bookmark} />
        </Panel.Body>
        <Panel.Footer>
          <div className="text-right">
            <ItemPermalink path={stdBookmark.path} />
          </div>
        </Panel.Footer>
      </Panel>
    </div>
  );
}
