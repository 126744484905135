import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import * as R from 'ramda';
import { useEffect, useMemo, useRef, useState } from 'react';
import { LegendItemsHiddenNote } from '~/components/statistics/MeasurementGraph/LegendItemsHiddenNote';
import type { CrossPlotDataPoint } from '~/utils/chart';
import {
  chartExportOptions,
  colorByIndex,
  crossPlotRegression,
  zeroInterceptRegression,
} from '~/utils/chart';
import { dataTypeUnit } from '~/utils/modules/architecturalMeasurement';
import { ucwords } from '~/utils/text';

Highcharts.setOptions({
  lang: {
    thousandsSep: '',
    decimalPoint: '.',
  },
});

if (typeof window !== 'undefined') {
  HighchartsExporting(Highcharts);
  HighchartsExportData(Highcharts);
  HighchartsBoost(Highcharts);
}

type Props = {
  title?: string;
  xAxisDataType: string;
  yAxisDataType: string;
  logScaleX: boolean;
  logScaleY: boolean;
  data: CrossPlotDataPoint[];
  expanded: boolean;
  showRegressionLine: boolean;
  showZIRegressionLine: boolean;
};

export function MeasurementGraphCrossPlot({
  title,
  xAxisDataType,
  yAxisDataType,
  logScaleX,
  logScaleY,
  data,
  expanded,
  showRegressionLine,
  showZIRegressionLine,
}: Props) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  /** A list of all series items that were toggled invisible on the graph */
  const [itemsHidden, setItemsHidden] = useState<string[]>([]);

  const handleLegendItemClick: Highcharts.SeriesLegendItemClickCallbackFunction =
    event => {
      const { name, visible } = event.target;

      if (visible) {
        // Series is about to be hidden
        setItemsHidden(R.pipe(R.append(name), R.uniq));
      } else {
        // Series is about to be visible again
        setItemsHidden(R.without([name]));
      }
    };

  // Reflow the chart when its container is expanded or collapsed
  useEffect(() => {
    chartRef?.current?.chart.reflow();
  }, [expanded]);

  // Reset the zoom whenever data is changed
  useEffect(() => {
    chartRef?.current?.chart.zoomOut();
  }, [data]);

  const regressionLine = useMemo(() => {
    return crossPlotRegression(data, logScaleX, logScaleY);
  }, [data, logScaleX, logScaleY]);

  const ziRegressionLine = useMemo(() => {
    return zeroInterceptRegression(data);
  }, [data]);

  const formatAxisLabel = (dataType: string) => {
    const unit = dataTypeUnit(dataType) ?? '';
    return `${ucwords(dataType)}${unit ? ` (${unit})` : ''}`;
  };

  const options: Highcharts.Options = useMemo((): Highcharts.Options => {
    const dataByAE = data.reduce<Record<string, CrossPlotDataPoint[]>>(
      (acc, cur) => {
        const prevAEData = acc[cur.label] ?? [];
        return {
          ...acc,
          [cur.label]: [...prevAEData, cur],
        };
      },
      {},
    );
    const aes = Object.keys(dataByAE);

    const series: Highcharts.SeriesOptionsType[] = aes.map((ae, i) => ({
      type: 'scatter',
      id: ae,
      name: ae,
      data: dataByAE[ae].map(d => [d.x, d.y]),
      color: colorByIndex(i),
      boostThreshold: 20,
    }));

    if (showRegressionLine) {
      series.push({
        // Regular lines lose their styling for some reason when redrawing, but splines don't
        type: 'spline',
        id: 'Regression',
        name: 'Regression',
        data: regressionLine,
        color: 'black',
        dashStyle: 'Dash',
        marker: { enabled: false },
        enableMouseTracking: false,
        includeInDataExport: false,
        // boostThreshold: 100000,
      });
    }

    if (showZIRegressionLine) {
      series.push({
        type: 'spline',
        id: 'Regression through origin',
        name: 'Regression through origin',
        data: ziRegressionLine,
        color: '#8874d9',
        dashStyle: 'Dash',
        marker: { enabled: false },
        enableMouseTracking: false,
        includeInDataExport: false,
        pointStart: 0,
        // boostThreshold: 100000,
      });
    }

    // Calculate the minimum values for x and y. If they are ≥ 0, use 0 as the min
    // values for the x/y axes. If they are a negative number, auto-calculate the min
    const min = (values: number[], isLogScale: boolean) => {
      if (isLogScale) return undefined;
      return Math.min(...values) > 0 ? 0 : null;
    };
    const minX = min(
      data.map(d => d.x),
      logScaleX,
    );
    const minY = min(
      data.map(d => d.y),
      logScaleY,
    );

    return {
      credits: { enabled: false },
      accessibility: { enabled: false },
      chart: {
        zooming: {
          type: 'xy',
        },
        style: {
          color: '#555555',
          fontFamily: 'Cabin, sans-serif',
          fontSize: '10pt',
        },
      },
      title: { text: title },
      xAxis: {
        title: {
          text: formatAxisLabel(xAxisDataType),
        },
        gridLineColor: '#dddddd',
        gridLineWidth: 1,
        gridLineDashStyle: 'Dash',
        type: logScaleX ? 'logarithmic' : 'linear',
        min: minX,
      },
      yAxis: {
        title: {
          text: formatAxisLabel(yAxisDataType),
        },
        gridLineDashStyle: 'Dash',
        gridLineColor: '#dddddd',
        gridLineWidth: 1,
        type: logScaleY ? 'logarithmic' : 'linear',
        min: minY,
      },
      legend: {
        itemStyle: {
          color: '#555555',
          fontSize: '9pt',
        },
      },
      series,
      exporting: chartExportOptions,
      plotOptions: {
        series: {
          events: {
            legendItemClick: handleLegendItemClick,
          },
        },
      },
      boost: {
        seriesThreshold: 4,
      },
    };
  }, [
    xAxisDataType,
    yAxisDataType,
    data,
    showRegressionLine,
    showZIRegressionLine,
    logScaleX,
    logScaleY,
    regressionLine,
    ziRegressionLine,
    title,
  ]);

  // When a series is no longer on the graph, remove it from the itemsHidden list
  useEffect(() => {
    const seriesNames = options.series?.map(s => s.name ?? 'unknown') ?? [];
    setItemsHidden(prevItemsHidden =>
      prevItemsHidden.filter(item => seriesNames.includes(item)),
    );
  }, [options.series]);

  return (
    <>
      <div style={{ width: '100%', height: '600px' }}>
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          containerProps={{ style: { width: '100%', height: '100%' } }}
          options={options}
          allowChartUpdate
        />
      </div>

      <LegendItemsHiddenNote numHidden={itemsHidden.length} />
    </>
  );
}
