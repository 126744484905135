import { gql, useQuery } from '@apollo/client';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  OtherWikiDetailRouteQuery,
  OtherWikiDetailRouteQueryVariables,
} from '~/apollo/generated/schema';
import {
  BookmarkParentType,
  BookmarkTargetType,
  ReviewCommentParentType,
  Role,
} from '~/apollo/generated/schema';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import { LastUpdated } from '~/components/common/LastUpdated';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { Prose } from '~/components/common/Prose';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { TargetReviewCommentsModal } from '~/components/reviewComment/TargetReviewCommentsModal';
import { otherWikiDetailRoute, uploadOtherWikiUpdateRoute } from '~/paths';

const OTHER_WIKI_DETAIL_ROUTE = gql`
  query OtherWikiDetailRoute($id: Int!) {
    otherList(id: $id) {
      ...otherWikiParts
    }
  }

  ${fragments.otherWikiParts}
`;

export default function OtherWikiDetailRoute() {
  const params = useParams<{ otherId: string }>();
  invariant(params.otherId, 'otherId param required');
  const otherId = parseInt(params.otherId);

  const { data, loading } = useQuery<
    OtherWikiDetailRouteQuery,
    OtherWikiDetailRouteQueryVariables
  >(OTHER_WIKI_DETAIL_ROUTE, {
    variables: { id: otherId },
  });

  const wikiPage = data?.otherList.find(o => o.id === otherId);

  if (loading) return <SpinnerPlaceholder />;
  if (!wikiPage) return <NotFound />;

  return (
    <>
      <div className="float-right space-x-2">
        <TargetBookmarksManagerModal
          parentType={BookmarkParentType.OtherWiki}
          parentId={wikiPage.id}
          targetType={BookmarkTargetType.OtherWiki}
          targetId={wikiPage.id}
          path={otherWikiDetailRoute(wikiPage.id)}
        />

        <TargetReviewCommentsModal
          parentId={wikiPage.id}
          parentType={ReviewCommentParentType.WikiOther}
          name={wikiPage.title}
        />
        <RoleSecured roles={[Role.RoleAdmin]}>
          <Link
            to={uploadOtherWikiUpdateRoute(wikiPage.id)}
            className="btn btn-ghost btn-sm gap-1"
          >
            <FontAwesomeIcon icon={faPencil} /> Edit Page
          </Link>
        </RoleSecured>
      </div>

      <PageHeading>{wikiPage.title}</PageHeading>

      {wikiPage.text && (
        <Prose dangerouslySetInnerHTML={{ __html: wikiPage.text }} />
      )}

      <LastUpdated date={wikiPage.updatedAt} />
    </>
  );
}
