import type { PureQueryOptions } from '@apollo/client';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import { useEffect, useReducer } from 'react';
import { Alert } from 'react-daisyui';
import type {
  EditReportPageQuery,
  UpdateReportItemsOrderInput,
} from '~/apollo/generated/schema';
import { ReportState } from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { rejectNil } from '~/utils/common';
import { ReportEditorItem } from './ReportEditorItem';

// Items in the API may not have an order set
type IncomingOrderItem = {
  id: number;
  order?: number | null;
};

// When items are initialized into state, they will be assigned an order
type OrderItem = {
  id: number;
  order: number;
};

type EditorState = {
  items: OrderItem[];
  isDirty: boolean;
  lastTouchedId: number | null;
};

type EditorAction =
  | { type: 'INITIALIZE'; payload: IncomingOrderItem[] }
  | { type: 'INCREMENT_ORDER'; payload: number }
  | { type: 'DECREMENT_ORDER'; payload: number };

const reducer = (state: EditorState, action: EditorAction): EditorState => {
  console.log(`Dispatched ${action.type}`, action.payload);

  const curOrders = state.items.map(item => item.order);
  const curMaxOrder = curOrders.length > 0 ? Math.max(...curOrders) : -1;

  const redistribute = (items: IncomingOrderItem[]): OrderItem[] => {
    return items
      .map<OrderItem>((item, index) => ({
        id: item.id,
        order: index,
      }))
      .sort(R.ascend(item => item.order ?? Infinity));
  };

  if (action.type === 'INITIALIZE') {
    return {
      ...state,
      items: redistribute(action.payload),
      isDirty: false,
      lastTouchedId: null,
    };
  }

  if (action.type === 'INCREMENT_ORDER') {
    const itemId = action.payload;

    const curItemIdx = state.items.findIndex(item => item.id === itemId);
    const curItem = state.items.at(curItemIdx);
    if (!curItem) {
      return state;
    }
    const nextOrder = curItem.order + 1;
    if (nextOrder > curMaxOrder) {
      return state;
    }

    const toBeDecrementedIdx = state.items.findIndex(
      item => item.order === nextOrder,
    );
    const toBeDecremented = state.items.at(toBeDecrementedIdx);

    let nextState: EditorState = {
      ...state,
      isDirty: true,
      lastTouchedId: itemId,
    };

    nextState.items = R.update(
      curItemIdx,
      {
        id: curItem.id,
        order: nextOrder,
      } satisfies OrderItem,
      nextState.items,
    );

    if (toBeDecremented) {
      nextState.items = R.update(
        toBeDecrementedIdx,
        {
          id: toBeDecremented.id,
          order: toBeDecremented.order - 1,
        } satisfies OrderItem,
        nextState.items,
      );
    }

    return nextState;
  }

  if (action.type === 'DECREMENT_ORDER') {
    const itemId = action.payload;

    const curItemIdx = state.items.findIndex(item => item.id === itemId);
    const curItem = state.items.at(curItemIdx);
    if (!curItem) {
      return state;
    }
    const nextOrder = curItem.order - 1;
    if (nextOrder < 0) {
      return state;
    }

    const toBeIncrementedIdx = state.items.findIndex(
      item => item.order === nextOrder,
    );
    const toBeIncremented = state.items.at(toBeIncrementedIdx);

    let nextState: EditorState = {
      ...state,
      isDirty: true,
      lastTouchedId: itemId,
    };

    nextState.items = R.update(
      curItemIdx,
      { id: curItem.id, order: nextOrder } satisfies OrderItem,
      nextState.items,
    );

    if (toBeIncremented) {
      nextState.items = R.update(
        toBeIncrementedIdx,
        {
          id: toBeIncremented.id,
          order: toBeIncremented.order + 1,
        } satisfies OrderItem,
        nextState.items,
      );
    }

    return nextState;
  }

  return state;
};

type Report = EditReportPageQuery['reportList'][number];
type ReportItem = Report['items'][number];

type Props = {
  report: Report;
  items: ReportItem[];
  refetchQueries: PureQueryOptions[];
};

export function ReportEditorItems({ report, items, refetchQueries }: Props) {
  const { setFieldValue } = useFormikContext();

  const [state, dispatch] = useReducer(reducer, {
    items: [],
    isDirty: false,
    lastTouchedId: null,
  });

  useEffect(() => {
    dispatch({ type: 'INITIALIZE', payload: items });
  }, [items]);

  const sortedItems = state.items
    .slice()
    .sort(R.ascend(item => item.order))
    .map(orderItem => items.find(item => item.id === orderItem.id))
    .filter(rejectNil);

  const isFirstItem = (id: number) => sortedItems.at(0)?.id === id;
  const isLastItem = (id: number) => sortedItems.at(-1)?.id === id;
  const isLastTouched = (id: number) => state.lastTouchedId === id;

  const increment = (id: number) =>
    dispatch({ type: 'INCREMENT_ORDER', payload: id });

  const decrement = (id: number) =>
    dispatch({ type: 'DECREMENT_ORDER', payload: id });

  useEffect(() => {
    // Use our own dirty-checking to prevent unnecessary formik updates
    if (state.isDirty) {
      const changes: UpdateReportItemsOrderInput[] = state.items.map(item => ({
        reportItemId: item.id,
        order: item.order,
      }));

      setFieldValue('ordering', changes);
    }
  }, [state.items, state.isDirty, setFieldValue]);

  return (
    <>
      {!sortedItems.length && (
        <Alert>
          <div>
            <Heading level={3}>No items in report</Heading>
            <p>This report doesn't contain any items.</p>
          </div>
        </Alert>
      )}

      {sortedItems.map(item => (
        <ReportEditorItem
          key={item.id}
          reportId={report.id}
          item={item}
          onMoveUp={decrement}
          onMoveDown={increment}
          canMoveUp={!isFirstItem(item.id)}
          canMoveDown={!isLastItem(item.id)}
          isLastTouched={isLastTouched(item.id)}
          isDirty={state.isDirty}
          refetchQueries={refetchQueries}
          disabled={report.state !== ReportState.Draft}
        />
      ))}
    </>
  );
}
