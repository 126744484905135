import { gql, useQuery } from '@apollo/client';
import { otherWikiParts } from '~/apollo/fragments';
import type {
  WikiPopoverOtherQuery,
  WikiPopoverOtherQueryVariables,
} from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import type { PopoverContentProps } from '~/components/common/Popover';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/common/Popover';
import { Prose } from '~/components/common/Prose';
import { InfoIcon } from '~/components/common/icons/InfoIcon';

const WIKI_POPOVER_OTHER = gql`
  query WikiPopoverOther($id: Int!) {
    otherList(id: $id) {
      ...otherWikiParts
    }
  }

  ${otherWikiParts}
`;

export type WikiPopoverProps = {
  children?: JSX.Element;
  otherWikiId: number;
  popoverProps?: Partial<PopoverContentProps>;
};

export function WikiPopover({
  children,
  otherWikiId,
  popoverProps,
}: WikiPopoverProps) {
  const { data } = useQuery<
    WikiPopoverOtherQuery,
    WikiPopoverOtherQueryVariables
  >(WIKI_POPOVER_OTHER, {
    variables: { id: otherWikiId },
  });

  const otherWikis = data?.otherList ?? [];
  const wikiPage = otherWikis.find(ow => ow.id === otherWikiId);

  if (!wikiPage?.text) return null;

  return (
    <Popover>
      <PopoverTrigger>{children ?? <InfoIcon />}</PopoverTrigger>
      <PopoverContent
        {...popoverProps}
        className="w-[600px] overflow-scroll max-h-screen"
      >
        <Heading level={3}>{wikiPage.title}</Heading>
        <Prose dangerouslySetInnerHTML={{ __html: wikiPage.text }} />
      </PopoverContent>
    </Popover>
  );
}
