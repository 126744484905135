import React from 'react';

type Props = {
  picture: any; // TODO
};

export function ReportItemPicture({ picture }: Props) {
  return (
    <div className="text-center">
      <div className="thumbnail">
        <img
          src={picture.file.signedUrl}
          style={{ maxHeight: '80vh', width: 'auto' }}
          alt={picture.file.name}
        />
        <div className="text-sm">
          {picture.description && <div>{picture.description}</div>}
          {picture.author && (
            <div>
              <b>Author:</b> {picture.author}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
