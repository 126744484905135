import { cn } from '~/utils/common';
import type { FieldProps } from 'formik';
import { ErrorMessage } from 'formik';
import React from 'react';
import type { Props as ReactSelectProps } from 'react-select';
import ReactSelect from 'react-select';
import { FormLabel } from './FormLabel';

type SelectOption = {
  value: string | number | boolean;
  label: string;
  disabled?: boolean;
};

type Props = FieldProps & {
  options: SelectOption[];
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
  isInvalid?: boolean;
  label?: React.ReactNode;
  helpText?: string;
  renderInput?: (input: JSX.Element) => JSX.Element;
  reactSelectProps?: ReactSelectProps;
};

export function FormikReactSelectField({
  field,
  form,
  options,
  loading = false,
  disabled = false,
  required = false,
  isInvalid = false,
  label = null,
  renderInput,
  helpText,
  reactSelectProps,
}: Props) {
  function handleChange(option: any) {
    const value = option?.value || null;
    form.setFieldValue(field.name, value);
  }

  const selectedValue = options.find(opt => opt.value === field.value);

  // Convert generic `disabled` used everywhere else into react-select's `isDisabled` prop
  const selectOptions = options.map(opt => {
    const nextOpt: any = { ...opt }; // Todo maybe proper type, the OptionType<any> doesn't seem to fit this
    if (opt.disabled) {
      nextOpt.isDisabled = true;
      delete nextOpt.disabled;
    }
    return nextOpt;
  });

  const input = (
    <ReactSelect
      value={selectedValue}
      isLoading={loading}
      isDisabled={disabled}
      onChange={handleChange}
      options={selectOptions}
      isSearchable={reactSelectProps?.isSearchable ?? true}
      isClearable={reactSelectProps?.isClearable ?? true}
    />
  );

  return (
    <div
      className={cn('form-group', {
        'has-error': isInvalid,
        'text-error': isInvalid,
      })}
    >
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        required={required}
      />
      {renderInput ? renderInput(input) : input}
      <ErrorMessage name={field.name} />
    </div>
  );
}
