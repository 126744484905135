import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';

export function SuppDocFormFields({ disabled }: { disabled: boolean }) {
  return (
    <Field
      name="note"
      label=""
      component={FormikField}
      placeholder="Note"
      className="input-sm"
      disabled={disabled}
    />
  );
}
