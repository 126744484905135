import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONFIRM_NEW_USERNAME } from '~/apollo/operations/user';
import type {
  ConfirmNewUsernameMutation,
  ConfirmNewUsernameMutationVariables,
} from '~/apollo/generated/schema';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useAuth } from '~/contexts/auth';
import { rootRoute } from '~/paths';

export default function ConfirmNewUsernameRoute() {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const { hash } = useParams();
  if (!hash) throw new Error('hash param required');

  const [confirmNewUsername] = useMutation<
    ConfirmNewUsernameMutation,
    ConfirmNewUsernameMutationVariables
  >(CONFIRM_NEW_USERNAME, {
    variables: { hash },
  });

  useEffect(() => {
    async function handleConfirm() {
      try {
        const res = await confirmNewUsername();
        if (isAuthenticated && res.data) {
          // Changing a user's email address in Auth0 invalidates all existing
          // sessions. Call logout here to clear the frontend auth cache.
          logout();
          toast.success(
            <>
              Your username has been updated successfully, you may now use it to
              log in.
            </>,
          );
        }
      } catch (err) {
        console.log('Error confirming requested username:', err);
        toast.error(
          'There was a problem confirming your new username. The link you used may be invalid or already used.',
        );
      } finally {
        navigate(rootRoute());
      }
    }

    handleConfirm();
  }, []);

  return <SpinnerPlaceholder />;
}
