import { useLocation } from 'react-router-dom';
import { Panel } from '~/components/common/Panel';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { LoginButton } from '~/components/layout/Navbar/LoginButton';
import { envVars } from '~/environment';

// This is the shape of the possible state when landing on this page.
// It's possible to navigate here without setting state, so it might be undefined.
type LocationState = undefined | { postLoginRedirect?: Location };

function buildPostLoginRedirect(ls: LocationState): string | null {
  if (!ls?.postLoginRedirect) return null;

  // The query string needs to be decoded in case there are any brackets used,
  // they are automatically encoded when calling qs.stringify
  const url = new URL(ls.postLoginRedirect.pathname, envVars.VITE_CLIENT_URL);
  url.search = decodeURIComponent(ls.postLoginRedirect.search);

  return url.toString();
}

export default function LoginRoute() {
  const location = useLocation();
  useBreadcrumb('routes/login', 'Login');

  const postLoginRedirect = buildPostLoginRedirect(location.state);

  return (
    <div className="lg:w-1/2 mx-auto">
      <Panel>
        <Panel.Heading>
          <Panel.Title>Login</Panel.Title>
        </Panel.Heading>
        <Panel.Body className="space-y-4">
          <p>Please log in to SafariDB:</p>

          <div className="text-center">
            <LoginButton
              postLoginRedirect={postLoginRedirect}
              className="btn btn-primary btn-lg"
            />
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
}
