import { CombinedView } from './CombinedView';
import OCAdditionalFieldsDisplay from './OCAdditionalFieldsDisplay';
import { TabularView } from './TabularView';
import type { Props as KPPanelProps, OCAdditionalFields } from './index';

type Props = {
  geologyType: string | null;
  keyParameters: KPPanelProps['keyParameters'];
  outcrop?: OCAdditionalFields;
  leftColMd: number;
  rightColMd: number;
  view?: 'table' | 'combined';
};

export function GeologyTypeTab({
  geologyType,
  keyParameters,
  outcrop,
  leftColMd,
  rightColMd,
  view = 'combined',
}: Props) {
  return (
    <>
      <div>
        {view === 'table' && (
          <TabularView
            geologyType={geologyType}
            keyParameters={keyParameters}
          />
        )}

        {view === 'combined' && (
          <CombinedView
            geologyType={geologyType}
            keyParameters={keyParameters}
            leftColMd={leftColMd}
            rightColMd={rightColMd}
          />
        )}
      </div>

      {outcrop && geologyType && (
        <OCAdditionalFieldsDisplay
          geologyType={geologyType}
          outcrop={outcrop}
          keyParameters={keyParameters}
          leftColMd={leftColMd}
          rightColMd={rightColMd}
        />
      )}
    </>
  );
}
