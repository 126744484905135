import { useFormikContext } from 'formik';
import Papa from 'papaparse';
import type { ReactNode } from 'react';
import { useDataSearchContext } from '~/components/dataSearch/dataSearchContext';
import { saveAsCSV, timestamp } from '~/utils/export';
import {
  formatGroupBy,
  type DataSearchFormValues,
} from '~/utils/modules/dataSearch';

export function ExportData({
  children,
}: {
  children: (exportData: () => void, canExport: boolean) => ReactNode;
}) {
  const { values } = useFormikContext<DataSearchFormValues>();
  const { measurements } = useDataSearchContext();

  function exportData() {
    const groupByLabel = formatGroupBy(values.groupDataBy);

    const data = measurements.map(m => ({
      x: m.x,
      y: m.y,
      [groupByLabel]: m[values.groupDataBy],
    }));

    const rows = Papa.unparse(data, { header: true });
    saveAsCSV(rows, `Data Export ${timestamp()}.csv`);
  }

  const canExport = measurements.length > 0;

  return children(exportData, canExport);
}
