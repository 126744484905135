import { Heading } from './Heading';

export function NotFound() {
  return (
    <div className="text-center">
      <Heading level={1}>Not Found</Heading>
      <p>The resource you're looking for couldn't be found.</p>
    </div>
  );
}
