import type { PureQueryOptions } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { DocumentNode } from 'graphql';
import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { Alert, Badge } from 'react-daisyui';
import { Panel } from '~/components/common/Panel';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import type {
  SupportObject,
  SupportObjectParent,
  SupportObjectType,
} from '~/utils/modules/supportObject';
import { ucwords } from '~/utils/text';
import { CreateSupportObject } from './CreateSupportObject';
import { SupportObjectListItem } from './SupportObjectListItem';

type Props = {
  outcropId?: number;
  studyId?: number;
  supportObjectType: SupportObjectType;
  supportObject: SupportObject[];
  parentType: SupportObjectParent;
  createMutation: DocumentNode;
  updateMutation: DocumentNode;
  deleteMutation: DocumentNode;
  refetchQueries: PureQueryOptions[];
};

export function SupportObjectManager({
  studyId,
  outcropId,
  supportObject,
  supportObjectType,
  parentType,
  createMutation,
  updateMutation,
  deleteMutation,
  refetchQueries,
}: Props) {
  const unpublishedCount = supportObject.reduce((acc, cur) => {
    const itemCount = cur.published ? 0 : 1;

    const unpublishedPictures = cur.pictures.reduce((picAcc, picCur) => {
      if (!picCur.published) return picAcc + 1;
      return picAcc;
    }, 0);

    return acc + itemCount + unpublishedPictures;
  }, 0);

  const [isCreating, setIsCreating] = useState(false);
  const [showUnpublished, setShowUnpublished] = useState(unpublishedCount > 0);

  const soDisplayName = ucwords(supportObjectType);

  const sortedAndFilteredObjects = useMemo(() => {
    const filteredObjects = showUnpublished
      ? supportObject
      : supportObject.filter(so => so.published);

    return R.sortBy(so => so.priority ?? Infinity, filteredObjects);
  }, [supportObject, showUnpublished]);

  return (
    <>
      <div className="form-control items-end">
        <label
          htmlFor="showUnpublished"
          className="label justify-start gap-2 cursor-pointer"
        >
          <input
            type="checkbox"
            id="showUnpublished"
            checked={showUnpublished}
            onChange={() => setShowUnpublished(!showUnpublished)}
            className="checkbox checkbox-sm"
            disabled={!unpublishedCount}
          />
          Show unpublished items{' '}
          <Badge color="ghost" className="font-mono">
            {unpublishedCount}
          </Badge>
        </label>
      </div>

      <div className="space-y-6">
        <Panel>
          <Panel.Heading>
            <button
              type="button"
              onClick={() => setIsCreating(!isCreating)}
              className="w-full flex justify-between items-center"
            >
              <Panel.Title>Create {soDisplayName}</Panel.Title>
              <div>
                <ExpandedIcon expanded={isCreating} />
              </div>
            </button>
          </Panel.Heading>

          {isCreating && (
            <Panel.Body>
              <CreateSupportObject
                studyId={studyId}
                outcropId={outcropId}
                supportObjectType={supportObjectType}
                createMutation={createMutation}
                refetchQueries={refetchQueries}
              />
            </Panel.Body>
          )}
        </Panel>

        <div className="space-y-6">
          {supportObject.length === 0 && (
            <Alert
              icon={<FontAwesomeIcon icon={faInfoCircle} className="text-xl" />}
            >
              No {soDisplayName.toLowerCase()} have been added yet.
            </Alert>
          )}

          {sortedAndFilteredObjects.map(so => (
            <SupportObjectListItem
              key={so.id}
              outcropId={outcropId}
              studyId={studyId}
              supportObject={so}
              supportObjectType={supportObjectType}
              parentType={parentType}
              updateMutation={updateMutation}
              deleteMutation={deleteMutation}
              refetchQueries={refetchQueries}
              showUnpublished={showUnpublished}
            />
          ))}
        </div>
      </div>
    </>
  );
}
