import { Checkbox } from 'react-daisyui';
import { Panel } from '~/components/common/Panel';

type Props = {
  name: string;
  values: string[] | null | undefined;
};

export function FilterValues({ name, values }: Props) {
  if (!values || !values.length) return null;

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>{name}</Panel.Title>
      </Panel.Heading>
      <Panel.Body className="p-2">
        {values.map(value => (
          <div key={value} className="form-control">
            <label className="label justify-start gap-2">
              <Checkbox size="sm" checked disabled />
              <span className="label-text">{value}</span>
            </label>
          </div>
        ))}
      </Panel.Body>
    </Panel>
  );
}
