import type { ApolloError } from '@apollo/client';
import { Button } from 'react-daisyui';
import type {
  GeoreferenceDataType,
  GeoreferencePartsFragment,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { GeoreferenceFormFields } from './GeoreferenceFormFields';
import type { DataTypeLimits } from './GeoreferenceManager';

type Props = {
  dataTypes: GeoreferenceDataType[];
  limits?: DataTypeLimits;
  maxAllowed?: number;
  georeferences: GeoreferencePartsFragment[];
  disabled: boolean;
  errors?: ApolloError;
};

export function CreateGeoreference({
  dataTypes,
  limits = {},
  maxAllowed,
  georeferences,
  disabled,
  errors,
}: Props) {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Add Element</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <div className="text-left space-y-4">
          <GeoreferenceFormFields
            dataTypes={dataTypes}
            maxAllowed={maxAllowed}
            limits={limits}
            georeferences={georeferences}
          />

          <FormErrors graphQLError={errors} />

          <div className="text-center">
            <Button type="submit" color="primary" disabled={disabled}>
              Save
            </Button>
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
}
