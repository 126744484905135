import React from 'react';
import { Badge } from 'react-daisyui';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import { Tooltip } from '~/components/common/Tooltip';
import type {
  AnalogueSearchFormValues,
  AnalogueSearchOptionItem,
} from '~/utils/modules/analogueSearch';
import { capitalize } from '~/utils/text';

type Props = {
  /** The name of the parent field. Only used for geologyType so it knows to show the icon. */
  name?: keyof AnalogueSearchFormValues;
  option: AnalogueSearchOptionItem;
  renderName?: (optionName: string) => React.ReactNode;
};

export function OptionLabel({ name, option, renderName }: Props) {
  // ~~Temporarily hide counts everywhere except geology type. #1312~~
  // Reenabled to see if it works 😊
  if ('count' in option /* && name === 'geologyType' */) {
    return (
      <span className="label-text">
        {name === 'geologyType' && option.name && (
          <div className="w-4 h-4 mx-4 inline-block">
            <GeologyTypeIcon
              geologyType={option.name}
              className="h-full w-full"
            />
          </div>
        )}
        {option.name ? capitalize(option.name) : 'undefined'}{' '}
        <Tooltip
          message={`${option.count} outcrop${option.count === 1 ? '' : 's'}`}
        >
          <Badge color="ghost" className="text-muted">
            {option.count}
          </Badge>
        </Tooltip>
      </span>
    );
  }
  return (
    <span className="label-text">
      {renderName ? renderName(option.name) : option.name}
    </span>
  );
}
