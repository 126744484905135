import type { PureQueryOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import type {
  BookmarkCollectionPartsFragment,
  DeleteCollectionMutation,
  DeleteCollectionMutationVariables,
} from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';

const DELETE_COLLECTION = gql`
  mutation DeleteCollection($collectionId: Int!) {
    deleteBookmarkCollection(collectionId: $collectionId)
  }
`;

type Props = {
  children: (confirmDelete: () => void) => JSX.Element;
  collection: Pick<BookmarkCollectionPartsFragment, 'id' | 'companyId'>;
  onDeleteSuccess?: () => Promise<void>;
  refetchQueries: PureQueryOptions[];
};

export function DeleteBookmarkCollection({
  children,
  collection,
  refetchQueries,
}: Props) {
  const [deleteCollection] = useMutation<
    DeleteCollectionMutation,
    DeleteCollectionMutationVariables
  >(DELETE_COLLECTION, {
    variables: { collectionId: collection.id },
    refetchQueries,
  });

  const confirmText = (
    <div className="space-y-2">
      {collection.companyId && (
        <p>
          This collection will be removed for all users of your company.
          Existing bookmarks will not be deleted.
        </p>
      )}

      <p>Are you sure you want to delete the collection?</p>
    </div>
  );

  async function handleDelete() {
    try {
      await deleteCollection();
      toast.success('Collection deleted successfully.');
    } catch (err) {
      console.log('Error deleting bookmark collection', err);
      toast.error(
        'There was a problem deleting the collection. Please reload the page and try again.',
      );
    }
  }

  return (
    <Confirm
      onConfirm={handleDelete}
      text={confirmText}
      submitButtonColor="error"
      submitText="Delete Collection"
    >
      {onConfirm => children(onConfirm)}
    </Confirm>
  );
}
