import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import * as fragments from '~/apollo/fragments';
import type {
  UploadProjectUpdatePageQuery,
  UploadProjectUpdatePageQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { UpdateProjectForm } from '~/components/upload/project/UpdateProjectForm';
import {
  StudyAccessManager,
  StudyAccessParent,
} from '~/components/upload/study/studyAccessManager/StudyAccessManager';

const UPLOAD_PROJECT_UPDATE_PAGE = gql`
  query UploadProjectUpdatePage($id: Int!) {
    projectList(id: $id) {
      ...projectParts

      studyAccess {
        ...studyAccessParts
        company {
          ...companyParts
        }
      }
    }
  }

  ${fragments.projectParts}
  ${fragments.studyAccessParts}
  ${fragments.companyParts}
`;

export default function UploadProjectUpdatePage() {
  const params = useParams<{ projectId: string }>();
  invariant(params.projectId, 'projectId param required');
  const projectId = parseInt(params.projectId);

  const { data, loading } = useQuery<
    UploadProjectUpdatePageQuery,
    UploadProjectUpdatePageQueryVariables
  >(UPLOAD_PROJECT_UPDATE_PAGE, {
    variables: { id: projectId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadProjectUpdatePageQueryVariables>,
  ] = [
    {
      query: UPLOAD_PROJECT_UPDATE_PAGE,
      variables: { id: projectId },
    },
  ];

  const projectList = data?.projectList ?? [];
  const project = projectList.find(p => p.id === projectId);
  const studyAccess = (project?.studyAccess ?? []).filter(
    (p): p is typeof p & { company: NonNullable<(typeof p)['company']> } =>
      !!p.company,
  );

  useBreadcrumb(
    'routes/upload/model/project/$projectId',
    project?.name ?? 'Update Project',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!project) return <NotFound />;

  return (
    <>
      <PageHeading>{project.name}</PageHeading>

      <div className="grid lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <UpdateProjectForm
            project={project}
            refetchQueries={refetchQueries}
          />
        </div>

        <div className="lg:col-span-1">
          <StudyAccessManager
            parentType={StudyAccessParent.Project}
            parentId={projectId}
            studyAccess={studyAccess}
            refetchQueries={refetchQueries}
          />
        </div>
      </div>
    </>
  );
}
