import { Heading } from '~/components/common/Heading';
import type { ReportDisplayReportItem } from '~/components/report/ReportDisplay/ReportItem';

export function ReportItemText({ item }: { item: ReportDisplayReportItem }) {
  return (
    <div className="py-12">
      <Heading level={3}>{item.title}</Heading>
      {item.text && (
        <p className="whitespace-pre-line text-slate-600">{item.text}</p>
      )}
    </div>
  );
}
