import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { adminUploadRoute } from '~/paths';

export default function UploadRouteWrapper() {
  useBreadcrumb('routes/upload', 'Upload', adminUploadRoute());
  return <Outlet />;
}

export function UploadRouteRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(adminUploadRoute());
  });

  return null;
}
