import { gql } from '@apollo/client';

export const STUDY_ENUMERATIONS = gql`
  query StudyEnumerations {
    studyType: studyEnumerations(enumType: TYPE) {
      values
    }
    qpSpatialObservationType: studyQualityParameterEnumerations(
      enumType: SPATIAL_OBSERVATION_TYPE
    ) {
      values
    }
    qpDataAcquisitionMethod: studyQualityParameterEnumerations(
      enumType: DATA_ACQUISITION_METHOD
    ) {
      values
    }
    qpPublicationType: studyQualityParameterEnumerations(
      enumType: PUBLICATION_TYPE
    ) {
      values
    }
  }
`;
