import type { PureQueryOptions } from '@apollo/client';
import type { FileParent, FilePartsFragment } from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { SOFileUploadModal } from './SOFileUploadModal';
import { SupportingObjectFileListItem } from './SupportingObjectFileListItem';

type Props = {
  outcropId?: number;
  studyId?: number;
  parentId: number;
  parentType: FileParent;
  files: FilePartsFragment[];
  refetchQueries: PureQueryOptions[];
};

export function SOFileList({
  outcropId,
  studyId,
  parentId,
  parentType,
  files,
  refetchQueries,
}: Props) {
  return (
    <div className="space-y-1">
      <div className="flex justify-between">
        <Heading level={4}>Files</Heading>
        <SOFileUploadModal
          parentType={parentType}
          parentId={parentId}
          outcropId={outcropId}
          studyId={studyId}
          refetchQueries={refetchQueries}
        />
      </div>

      {!files.length && (
        <div className="text-muted text-sm">No files added.</div>
      )}

      {files.map(file => (
        <SupportingObjectFileListItem
          key={file.id}
          parentId={parentId}
          parentType={parentType}
          refetchQueries={refetchQueries}
          file={file}
          onDeleteSuccess={() => {}}
        />
      ))}
    </div>
  );
}
