import { FastField } from 'formik';
import type { GeoreferenceDataType } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import {
  isMarkerType,
  isPolygonType,
  isPolylineType,
  markerTypes,
  polygonTypes,
  polylineTypes,
} from '~/utils/georeference';

type Props = {
  dataType: GeoreferenceDataType;
};

export function ConfirmGeoreferenceFormFields({ dataType }: Props) {
  const mapOpts = (value: string) => ({ value, label: value });
  const markerOpts = markerTypes.map(mapOpts);
  const polygonOpts = polygonTypes.map(mapOpts);
  const polylineOpts = polylineTypes.map(mapOpts);

  function dataTypeOptions(dataType: GeoreferenceDataType) {
    if (isMarkerType(dataType)) return markerOpts;
    if (isPolygonType(dataType)) return polygonOpts;
    if (isPolylineType(dataType)) return polylineOpts;
    throw new Error(`Unhandled georef dataType '${dataType}'`);
  }

  return (
    <>
      <FastField
        name="dataType"
        label="Type"
        component={FormikField}
        type="select"
        options={dataTypeOptions(dataType)}
        required
      />
      <FastField name="name" label="Name" component={FormikField} required />
      <FastField
        name="description"
        label="Description"
        component={FormikField}
        type="textarea"
        required
      />
    </>
  );
}
