import { Panel } from '~/components/common/Panel';
import { CreateDepositionalWikiForm } from '~/components/upload/depositional/CreateDepositionalWikiForm';
import { PageHeading } from '~/components/common/PageHeading';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';

export default function DepositionalWikiCreateRoute() {
  useBreadcrumb('routes/upload/depositional/create', 'Create Geology Wiki');

  return (
    <>
      <PageHeading>Create Geology Wiki</PageHeading>

      <Panel>
        <Panel.Heading>
          <Panel.Title>Wiki Page</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <CreateDepositionalWikiForm />
        </Panel.Body>
      </Panel>
    </>
  );
}
