import type { PureQueryOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import * as fragments from '~/apollo/fragments';
import type {
  CreateReviewCommentMutation,
  CreateReviewCommentMutationVariables,
  ReviewCommentParentType,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';

export const CREATE_REVIEW_COMMENT = gql`
  mutation CreateReviewComment(
    $parentType: ReviewCommentParentType!
    $parentId: Int!
    $comment: String!
  ) {
    createReviewComment(
      parentType: $parentType
      parentId: $parentId
      comment: $comment
    ) {
      ...reviewCommentParts
      author {
        ...publicUserParts
      }
    }
  }

  ${fragments.reviewCommentParts}
  ${fragments.publicUserParts}
`;

type FormValues = { comment: string };

type Props = {
  parentType: ReviewCommentParentType;
  parentId: number;
  name: string;
  refetchQueries: PureQueryOptions[];
};

export function CreateReviewComment({
  parentType,
  parentId,
  name,
  refetchQueries,
}: Props) {
  const [createComment, { loading, error }] = useMutation<
    CreateReviewCommentMutation,
    CreateReviewCommentMutationVariables
  >(CREATE_REVIEW_COMMENT, {
    refetchQueries,
  });

  async function handleSubmit(
    values: FormValues,
    formikBag: FormikHelpers<FormValues>,
  ) {
    try {
      await createComment({
        variables: {
          parentType,
          parentId,
          comment: values.comment,
        },
      });
      toast.success('Your comment has been added successfully.');
      formikBag.resetForm();
    } catch (err) {
      console.log('Error creating review comment', err);
      toast.error(
        'There was a problem saving your review comment. Please try again.',
      );
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ comment: '' }}
      validationSchema={yup.object().shape({
        comment: yup.string().required(),
      })}
    >
      <Form className="space-y-4">
        <div className="flex items-center gap-4">
          <div className="flex-grow">
            <Field
              name="comment"
              component={FormikField}
              type="textarea"
              placeholder={`Add a comment for ${name}`}
              required
            />
          </div>

          <Button type="submit" color="primary" size="sm" loading={loading}>
            Add Comment
          </Button>
        </div>

        <FormErrors graphQLError={error} />
      </Form>
    </Formik>
  );
}
