import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { PageIntro } from '~/components/layout/PageIntro';
import { AnalogueSearchForm } from '~/components/outcrop/AnalogueSearchForm';
import { useQueryString } from '~/hooks/routing';
import type {
  AnalogueSearchDefaults,
  AnalogueSearchFormValues,
} from '~/utils/modules/analogueSearch';

export default function SearchRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const { query, stringify } = useQueryString<AnalogueSearchDefaults>();

  useBreadcrumb('routes/search', 'Search Analogues');

  // Callback to fire when the search is updated
  // This stringifies the values and pushes it to the browser's querystring
  const handleSearchChange = useCallback((query: AnalogueSearchFormValues) => {
    const nextLocation = {
      ...location,
      search: stringify(query),
    };

    const nextUrl = `${window.location.protocol}${window.location.host}${nextLocation.pathname}${nextLocation.hash}?${nextLocation.search}`;
    const nextLength = nextUrl.length;

    const maxUrlLength = 2048;
    if (nextLength <= maxUrlLength) {
      navigate(nextLocation, { preventScrollReset: true });
    } else {
      console.log(
        `Search query too long to push to URL: ${nextLength} characters (max ${maxUrlLength})`,
      );
    }
  }, []);

  const initialQuery = useMemo(() => {
    console.log('Parsed initial query to:', query);
    return query;
  }, []);

  return (
    <>
      <PageIntro
        pageName="intro_browse"
        iconPath="/assets/images/page/icon-outcrop.jpg"
      />

      <AnalogueSearchForm
        initialQuery={initialQuery}
        onSearchChange={handleSearchChange}
      />
    </>
  );
}
