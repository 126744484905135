import { toast, ToastContainer } from 'react-toastify';

export function ToastifyContainer() {
  return (
    <ToastContainer
      position={toast.POSITION.TOP_CENTER}
      autoClose={5000}
      draggable={false}
    />
  );
}
