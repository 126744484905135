import { cn } from '~/utils/common';
import type { HTMLProps } from 'react';

type Props = HTMLProps<HTMLHeadingElement> & {
  hasSubtitle?: boolean;
};

export function PageHeading({ className, hasSubtitle, ...props }: Props) {
  return (
    <h1
      className={cn('text-3xl font-serif font-bold', className, {
        'mt-4': !className,
        'mb-2': !className && !hasSubtitle,
        'mb-0': !className && hasSubtitle,
      })}
      {...props}
    />
  );
}

type SubtitleProps = HTMLProps<HTMLHeadingElement>;
function Subtitle({ className, ...props }: SubtitleProps) {
  return (
    <h3
      className={cn('text-xl font-serif text-slate-500', className, {
        '-mt-1 mb-2': !className,
      })}
      {...props}
    />
  );
}

PageHeading.Subtitle = Subtitle;
