import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  LithostratGroupInput,
  UpdateLithostratGroupMutation,
  UpdateLithostratGroupMutationVariables,
} from '~/apollo/generated/schema';
import { UPDATE_LITHOSTRAT_GROUP } from '~/apollo/operations/lithostrat';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { LithostratAgeFormFields } from '~/components/upload/lithostratAge/LithostratAgeFormFields';
import { useGroupUpdateOutletContext } from '~/routes/upload/lithostrat/group/$groupId';
import {
  defaultGroup,
  formValuesToGroupInput,
} from '~/utils/modules/lithostrat';
import type { LithostratAgeFormValues } from '~/utils/modules/lithostratAge';
import { initialLithostratAge } from '~/utils/modules/lithostratAge';

type FormValues = {
  startAge: LithostratAgeFormValues;
  endAge: LithostratAgeFormValues;
};

export default function GroupUpdateGeologicalAgeRoute() {
  const { group, refetchQueries } = useGroupUpdateOutletContext();

  const [updateGroup, { loading, error }] = useMutation<
    UpdateLithostratGroupMutation,
    UpdateLithostratGroupMutationVariables
  >(UPDATE_LITHOSTRAT_GROUP, {
    refetchQueries,
  });

  async function handleSubmit(values: FormValues) {
    const groupFormValues = defaultGroup(group);
    const groupInput: LithostratGroupInput = {
      ...formValuesToGroupInput(groupFormValues),
      startAge: values.startAge,
      endAge: values.endAge,
    };

    try {
      await updateGroup({
        variables: {
          id: group.id,
          group: groupInput,
        },
      });
      toast.success('Group updated successfully.');
    } catch (err) {
      console.log('Error updating group', err);
      toast.error('There was a problem updating the group. Please try again.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        startAge: initialLithostratAge(group.startAge),
        endAge: initialLithostratAge(group.endAge),
      }}
    >
      <Form className="space-y-4">
        <div className="grid lg:grid-cols-2 gap-6">
          <div>
            <Panel>
              <Panel.Heading>
                <Panel.Title>Start Age</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <LithostratAgeFormFields ageName="startAge" />
              </Panel.Body>
            </Panel>
          </div>

          <div>
            <Panel>
              <Panel.Heading>
                <Panel.Title>Start Age</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <LithostratAgeFormFields ageName="endAge" />
              </Panel.Body>
            </Panel>
          </div>
        </div>

        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
