import { gql } from '@apollo/client';

export const ADD_BOOKMARK_TO_COLLECTION = gql`
  mutation AddBookmarkToCollection($collectionId: Int!, $bookmarkId: Int!) {
    addBookmarkToCollection(
      collectionId: $collectionId
      bookmarkId: $bookmarkId
    ) {
      id
    }
  }
`;
