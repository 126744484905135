import type { PureQueryOptions } from '@apollo/client';
import { useMutation , gql } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';

import { userParts } from '~/apollo/fragments';
import type {
  CompanyPartsFragment,
  RegisterCompanyUserMutation,
  RegisterCompanyUserMutationVariables,
  UserRegisterInput,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import CreateCompanyUserFormFields from '~/components/company/CreateCompanyUserFormFields';
import { useModalState } from '~/hooks/modal';
import type { RegisterCompanyUserFormValues } from '~/utils/modules/user';
import {
  initialValuesCompanyUser,
  validationSchemaCompanyUser,
} from '~/utils/modules/user';

export const REGISTER_COMPANY_USER = gql`
  mutation RegisterCompanyUser($user: UserRegisterInput!) {
    registerCompanyUser(user: $user) {
      ...userParts
    }
  }

  ${userParts}
`;

type Props = {
  children: (showModal: () => void) => JSX.Element;
  company: CompanyPartsFragment;
  refetchQueries: PureQueryOptions[];
};

export function CreateUserModal({ children, company, refetchQueries }: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [createUserMutation, { loading, error }] = useMutation<
    RegisterCompanyUserMutation,
    RegisterCompanyUserMutationVariables
  >(REGISTER_COMPANY_USER, { refetchQueries });

  async function handleSubmit(
    values: RegisterCompanyUserFormValues,
    helpers: FormikHelpers<RegisterCompanyUserFormValues>,
  ) {
    const user: UserRegisterInput = {
      companyId: company.id,
      username: values.username,
      name: values.name,
      password: values.password,
    };

    try {
      await createUserMutation({
        variables: { user },
      });
      helpers.resetForm();
      hideModal();
      toast.success('User created successfully. They may now log in.');
    } catch (err) {
      toast.error('There was a problem creating the user. Please try again.');
      console.log('Error creating user:', err);
    }
  }

  return (
    <span>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValuesCompanyUser(company.mails)}
          validationSchema={validationSchemaCompanyUser(company.mails)}
        >
          <Form>
            <Modal.Body heading={`Create User - ${company.name}`}>
              <CreateCompanyUserFormFields mails={company.mails} />
              <FormErrors graphQLError={error} className="my-2" />
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                className="btn btn-default"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </span>
  );
}
