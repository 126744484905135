import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { savedDataSearchParts } from '~/apollo/fragments';
import type {
  DataSearchFromSdsQuery,
  DataSearchFromSdsQueryVariables,
} from '~/apollo/generated/schema';
import { PageHeading } from '~/components/common/PageHeading';
import { DataSearch } from '~/components/dataSearch/DataSearch';
import { DataSearchContextProvider } from '~/components/dataSearch/dataSearchContext';
import { DataSearchInfoIcon } from '~/components/dataSearch/DataSearchInfoIcon';
import { MySavedSearchesModal } from '~/components/dataSearch/savedDataSearch/MySavedSearchesModal';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useImperativeQuery } from '~/hooks/apollo';
import { useQueryString } from '~/hooks/routing';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';
import { savedDataSearchToDataSearchFormValues } from '~/utils/modules/dataSearch';

const DATA_SEARCH_FROM_SDS = gql`
  query DataSearchFromSds($searchId: Int!) {
    savedDataSearchList(id: $searchId) {
      ...savedDataSearchParts
    }
  }

  ${savedDataSearchParts}
`;

export default function DataSearchRoute() {
  const { query } = useQueryString<{ sdsId?: string }>();
  const sdsId = query.sdsId ? parseInt(query.sdsId) : undefined;

  useBreadcrumb('routes/data', 'Data Search');

  const [initialValues, setInitialValues] = useState<DataSearchFormValues>();

  const [loadSds, { loading }] = useImperativeQuery<
    DataSearchFromSdsQuery,
    DataSearchFromSdsQueryVariables
  >(DATA_SEARCH_FROM_SDS, {});

  useEffect(() => {
    const handleSdsIdPresent = async (sid: number) => {
      const result = await loadSds({ variables: { searchId: sid } });
      const sdsList = result?.data?.savedDataSearchList ?? [];
      const sds = sdsList.find(s => s.id === sid);
      if (sds) {
        const fv = savedDataSearchToDataSearchFormValues(sds);
        setInitialValues(fv);
      }
    };

    if (sdsId) {
      handleSdsIdPresent(sdsId);
    }
  }, [sdsId, loadSds]);

  return (
    <>
      <div className="flex justify-between items-end gap-6 mb-2">
        <PageHeading className="mt-0">
          Data Search <DataSearchInfoIcon />
        </PageHeading>

        <MySavedSearchesModal />
      </div>

      {!loading && (
        <DataSearchContextProvider initialValues={initialValues}>
          <DataSearch />
        </DataSearchContextProvider>
      )}
    </>
  );
}
