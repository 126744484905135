import type { PureQueryOptions } from '@apollo/client';
import * as R from 'ramda';
import type {
  FilePartsFragment,
  OutcropPartsFragment,
  PictureParent,
  PicturePartsFragment,
} from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { Tooltip } from '~/components/common/Tooltip';
import { PictureListCard } from '~/components/upload/supportingObject/SupportObjectManager/PictureListCard';
import { SupportingObjectUploadPictureModal } from './SupportingObjectUploadPictureModal';
import { cn } from '~/utils/common';
import { useMemo } from 'react';

export type Picture = PicturePartsFragment & {
  file: FilePartsFragment;
  outcropTag?: OutcropPartsFragment | null;
};

type Props = {
  parentId: number;
  parentType: PictureParent;
  pictures: Picture[];
  condensed?: boolean;
  disableUploadFromLimit?: boolean;
  refetchQueries: PureQueryOptions[];
  showUnpublished: boolean;
};

export function SupportingObjectPictureList({
  parentId,
  parentType,
  pictures,
  condensed = false,
  disableUploadFromLimit = false,
  refetchQueries,
  showUnpublished,
}: Props) {
  const sortedAndFilteredPictures = useMemo(() => {
    const filtered = showUnpublished
      ? pictures
      : pictures.filter(p => p.published);

    return R.sortBy(R.propOr(Infinity, 'priority'), filtered);
  }, [pictures, showUnpublished]);

  return (
    <div className="space-y-1">
      <div className="flex justify-between">
        <Heading level={4}>Pictures</Heading>
        <Tooltip
          message="Upload limit reached"
          disabled={!disableUploadFromLimit}
          className="inline"
        >
          <SupportingObjectUploadPictureModal
            parentType={parentType}
            parentId={parentId}
            refetchQueries={refetchQueries}
            disabled={disableUploadFromLimit}
          />
        </Tooltip>
      </div>

      {!pictures.length && (
        <div className="text-muted text-sm">No pictures added.</div>
      )}

      <div
        className={cn({
          'space-y-1': condensed,
          'space-y-4': !condensed,
        })}
      >
        {sortedAndFilteredPictures.map(picture => (
          <PictureListCard
            key={picture.id}
            parentType={parentType}
            parentId={parentId}
            picture={picture}
            refetchQueries={refetchQueries}
            condensed={condensed}
          />
        ))}
      </div>
    </div>
  );
}
