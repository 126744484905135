import { Outlet } from 'react-router-dom';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { PageIntro } from '~/components/layout/PageIntro';
import { NavTabs } from '~/components/navigation/NavTabs';
import { modernBackgroundRoute, modernRoute } from '~/paths';

export default function ModernRoute() {
  useBreadcrumb('routes/modern', 'Modern');

  return (
    <>
      <PageIntro
        pageName="intro_modern"
        iconPath="/assets/images/page/icon-wiki.jpg"
      />

      <NavTabs className="my-2">
        <NavTabs.Tab to={modernRoute()} end>
          Tool
        </NavTabs.Tab>
        <NavTabs.Tab to={modernBackgroundRoute()}>Background</NavTabs.Tab>
      </NavTabs>

      <Outlet />
    </>
  );
}
