import { gql, useQuery } from '@apollo/client';
import {
  basinParts,
  keyParametersParts,
  lithostratAgeParts,
  outcropParts,
  outcropQualityParametersParts,
} from '~/apollo/fragments';
import type {
  OutcropKeyParametersTabQuery,
  OutcropKeyParametersTabQueryVariables,
} from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { KeyParametersPanel } from '~/components/keyParameter/KeyParametersPanel';
import { QualityParametersTableOutcrop } from '~/components/outcrop/QualityParametersTableOutcrop';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';

export const OUTCROP_KEY_PARAMETERS_TAB = gql`
  query OutcropKeyParametersTab($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
      basin {
        ...basinParts
      }
      qualityParameters {
        ...outcropQualityParametersParts
      }
      keyParameters {
        ...keyParametersParts
      }
    }
  }
  ${outcropParts}
  ${lithostratAgeParts}
  ${basinParts}
  ${outcropQualityParametersParts}
  ${keyParametersParts}
`;

export default function OutcropKeyParametersRoute() {
  const ctx = useOutcropOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    OutcropKeyParametersTabQuery,
    OutcropKeyParametersTabQueryVariables
  >(OUTCROP_KEY_PARAMETERS_TAB, { variables: { id: outcropId } });

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="grid lg:grid-cols-3 gap-6">
      <div className="lg:col-span-2">
        <KeyParametersPanel
          keyParameters={outcrop.keyParameters ?? []}
          dominantGeologyType={outcrop.dominantGeologyType}
          outcrop={outcrop}
          leftColMd={4}
          rightColMd={8}
          noHeading
          view="table"
        >
          {(kps, tabs) => (
            <>
              <div className="relative bottom-0">
                <Heading level={3}>Key Parameters</Heading>
                <div className="absolute bottom-0 right-0">{tabs}</div>
              </div>

              <Panel>
                <Panel.Body>{kps}</Panel.Body>
              </Panel>
            </>
          )}
        </KeyParametersPanel>
      </div>

      <div>
        <Heading level={3}>Quality Control Parameters</Heading>
        <Panel>
          <Panel.Body>
            <QualityParametersTableOutcrop
              qualityParameters={outcrop.qualityParameters}
              noHeading
            />
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
}
