import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';

export function LithostratTypeFormFields() {
  return (
    <div className="space-y-2">
      <Field name="name" label="Name" component={FormikField} required />
      <Field
        name="description"
        label="Description"
        component={FormikField}
        type="textarea"
      />
    </div>
  );
}
