import { gql, useQuery } from '@apollo/client';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Papa from 'papaparse';
import * as R from 'ramda';
import { Button } from 'react-daisyui';
import { Link } from 'react-router-dom';
import * as fragments from '~/apollo/fragments';
import type { ExportVomsPageQuery } from '~/apollo/generated/schema';
import { PageHeading } from '~/components/common/PageHeading';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useSortFilter } from '~/hooks/data';
import { uploadVomUpdateRoute } from '~/paths';
import { saveAsCSV } from '~/utils/export';
import { toISODateString } from '~/utils/text';

const toBoolStr = (value: boolean) => (value ? 'yes' : 'no');

const EXPORT_VOMS_PAGE = gql`
  query ExportVomsPage {
    virtualOutcropModelList {
      ...vomParts
      project {
        name
      }
      outcrop {
        name
        region {
          name
          location {
            country
          }
        }
      }
      pictures {
        id
      }
      georeference {
        id
      }
      interpretations {
        id
      }
    }
  }
  ${fragments.vomParts}
`;

class Row {
  'ID': number;
  'VOM name': string;
  'Project': string; // Project name
  'Outcrop': string; // Outcrop name
  'Region': string;
  'Country': string;
  'VOM size': string;
  'Smallest visible feature': string;
  'V3Geo ID': number | null;
  'Approved': string; // boolean
  'VOM Quality': string; // boolean
  'Notable aspects': string; // boolean
  'Acquisition year': string;
  'Acquisition person': string;
  'Acquisition type': string;
  'Acquisition equipment': string;
  'Processing year': string;
  'Processing person': string;
  'VOM author': string;
  'Picture': string; // boolean
  'Georeference': string; // boolean
  'Interpretation': string; // boolean

  constructor(vom: ExportVomsPageQuery['virtualOutcropModelList'][number]) {
    this['ID'] = vom.id;
    this['VOM name'] = vom.name;
    this['Project'] = vom.project.name ?? '';
    this['Outcrop'] = vom.outcrop?.name || '';
    this['Region'] = vom.outcrop?.region?.name || '';
    this['Country'] = vom.outcrop?.region?.location.country || '';
    this['VOM size'] = vom.size || '';
    this['Smallest visible feature'] = vom.smallestVisibleFeature || '';
    this['V3Geo ID'] = vom.v3GeoId || null;
    this['Approved'] = toBoolStr(vom.approved ?? false);
    this['VOM Quality'] = toBoolStr(!R.isNil(vom.quality));
    this['Notable aspects'] = toBoolStr(!R.isNil(vom.notableAspects));
    this['Acquisition year'] = String(vom.acquisitionYear || '');
    this['Acquisition person'] = vom.aquiringPerson || '';
    this['Acquisition type'] = vom.acquisitionType || '';
    this['Acquisition equipment'] = vom.acquisitionEquipment || '';
    this['Processing year'] = String(vom.processingYear || '');
    this['Processing person'] = vom.processingPerson || '';
    this['VOM author'] = vom.copyright?.join(', ') || '';
    this['Picture'] = toBoolStr((vom.pictures?.length || 0) > 0);
    this['Georeference'] = toBoolStr((vom.georeference?.length || 0) > 0);
    this['Interpretation'] = toBoolStr((vom.interpretations?.length || 0) > 0);
  }
}

export default function ExportVomsRoute() {
  useBreadcrumb('routes/upload/export/voms', 'VOM Master Sheet');

  const { data, loading } = useQuery<ExportVomsPageQuery>(EXPORT_VOMS_PAGE, {});

  const rows = R.pipe(
    () => data?.virtualOutcropModelList ?? [],
    R.sortBy(R.prop('id')),
    R.map(item => new Row(item)),
  )();

  const { items, sortIndicatorProps } = useSortFilter(
    rows,
    'ID',
    'VOM Name',
    'exportVomsList',
  );

  const fields = rows.length ? (Object.keys(rows[0]) as Array<keyof Row>) : [];

  function handleSave() {
    const data = Papa.unparse(rows);
    const date = toISODateString(new Date());
    saveAsCSV(data, `VOMs Export ${date}.csv`);
  }

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <div className="float-right">
        <Button
          type="button"
          variant="link"
          onClick={handleSave}
          className="gap-1"
        >
          <FontAwesomeIcon icon={faFileCsv} /> Save as CSV
        </Button>
      </div>

      <PageHeading>Virtual Outcrop Models</PageHeading>
      <p>Master list of all VOMs in the system</p>

      <table className="table table-compact">
        <thead className="sticky top-0 bg-sky-100">
          <tr>
            {fields.map(f => (
              <th key={f}>
                <SortTrigger
                  colName={f}
                  sortIndicatorProps={sortIndicatorProps}
                >
                  <span style={{ fontSize: '9pt' }}>{f}</span>
                </SortTrigger>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {items.map(row => (
            <tr key={row.ID}>
              {fields.slice(0, 1).map(f => (
                <td key={f} className="sticky left-0 bg-sky-100">
                  <Link
                    to={uploadVomUpdateRoute(row.ID)}
                    target="_blank"
                    className="link"
                  >
                    {row[f]}
                  </Link>
                </td>
              ))}
              {fields.slice(1).map(f => (
                <td key={f} className="border border-slate-100">
                  {row[f]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
