import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { dmvParts } from '~/apollo/fragments';
import type {
  OutcropDefaultMeasurementsViewPageQuery,
  OutcropDefaultMeasurementsViewPageQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { UpdateDmvForm } from '~/components/upload/defaultMeasurementsView/UpdateDmvForm';

const OUTCROP_DEFAULT_MEASUREMENTS_VIEW_PAGE = gql`
  query OutcropDefaultMeasurementsViewPage($outcropId: Int!) {
    outcropList(id: $outcropId) {
      id
      defaultMeasurementsView {
        ...dmvParts
      }
    }
  }

  ${dmvParts}
`;

export function OutcropDefaultMeasurementsViewPage() {
  const params = useParams();
  invariant(params.outcropId);
  const outcropId = parseInt(params.outcropId);

  const { data, loading } = useQuery<
    OutcropDefaultMeasurementsViewPageQuery,
    OutcropDefaultMeasurementsViewPageQueryVariables
  >(OUTCROP_DEFAULT_MEASUREMENTS_VIEW_PAGE, {
    variables: { outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<OutcropDefaultMeasurementsViewPageQueryVariables>,
  ] = [
    { query: OUTCROP_DEFAULT_MEASUREMENTS_VIEW_PAGE, variables: { outcropId } },
  ];

  useBreadcrumb(
    'routes/upload/model/outcrop/$outcropId/default-measurements-view',
    'Measurements Page',
  );

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  const dmv = outcrop.defaultMeasurementsView ?? null;

  return (
    <UpdateDmvForm
      type="outcrop"
      outcropId={outcrop.id}
      dmv={dmv}
      refetchQueries={refetchQueries}
    />
  );
}
