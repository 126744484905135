import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';

import { DELETE_PICTURE } from '~/apollo/operations/supportingObject';
import type {
  DeletePictureMutation,
  DeletePictureMutationVariables,
} from '~/apollo/generated/schema';
import { PictureParent } from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';

type Props = {
  vomId: number;
  pictureId: number;
  refetchQueries: PureQueryOptions[];
};

export function DeleteVomPicture({ vomId, pictureId, refetchQueries }: Props) {
  const [deletePicture, { loading }] = useMutation<
    DeletePictureMutation,
    DeletePictureMutationVariables
  >(DELETE_PICTURE, {
    variables: {
      parentType: PictureParent.Vom,
      parentId: vomId,
      id: pictureId,
    },
    refetchQueries,
  });

  async function handleClick() {
    try {
      await deletePicture();
      toast.success('Picture deleted successfully.');
    } catch (err) {
      console.log('Error deleting VOM picture', err);
      toast.error('There was a problem deleting the picture.');
    }
  }

  return (
    <Confirm
      onConfirm={handleClick}
      text="The selected picture will be permanently deleted."
    >
      {confirm => (
        <Button
          type="button"
          color="ghost"
          size="xs"
          onClick={confirm}
          disabled={loading}
          className="text-error"
        >
          Delete Picture
        </Button>
      )}
    </Confirm>
  );
}
