import { useQuery } from '@apollo/client';
import React from 'react';

import { PAGES_BY_PAGE_NAME } from '~/apollo/operations/page';
import type {
  PagesByPageNameQuery,
  PagesByPageNameQueryVariables,
} from '~/apollo/generated/schema';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Page } from '~/components/page/Page';

type TourSectionProps = {
  children: React.ReactNode;
};

export function TourSection({ children }: TourSectionProps) {
  return <div className="w-full py-12 px-8">{children}</div>;
}

const imgUrl = (filename: string): string => `/assets/images/tour/${filename}`;

const Divider = () => <div className="divider my-8" />;

export function TourPages() {
  const pageNames = [
    'tour-about-safaridb',
    'tour-search-safaridb',
    'tour-sponsor-benefits',
    'tour-partner-benefits',
    'tour-faq',
  ];
  const { loading, data } = useQuery<
    PagesByPageNameQuery,
    PagesByPageNameQueryVariables
  >(PAGES_BY_PAGE_NAME, {
    variables: { pageNames },
  });

  if (loading) return <SpinnerPlaceholder />;

  type PageDataMap = Record<string, any>;

  const pageList = data?.pageList ?? [];
  const pages = pageList.reduce<PageDataMap>((result, item) => {
    result[item.pageName] = item;
    return result;
  }, {});

  return (
    <div>
      <TourSection>
        <div className="grid lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2 flex items-center">
            <Page pageData={pages['tour-about-safaridb']} />
          </div>
          <div className="flex items-center">
            <img src={imgUrl('browse-map.png')} alt="" />
          </div>
        </div>
      </TourSection>

      <Divider />

      <TourSection>
        <div className="grid lg:grid-cols-3 gap-6">
          <div className="hidden sm:flex items-center">
            <img src={imgUrl('browse-outcrops.png')} alt="" />
          </div>
          <div className="lg:col-span-2 flex items-center">
            <Page pageData={pages['tour-search-safaridb']} />
          </div>
          <div className="flex items-center sm:hidden">
            <img src={imgUrl('browse-outcrops.png')} alt="" />
          </div>
        </div>
      </TourSection>

      <Divider />

      <TourSection>
        <div className="grid lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2 flex items-center">
            <Page pageData={pages['tour-sponsor-benefits']} />
          </div>
          <div className="flex items-center">
            <img src="/assets/images/about/safari4_sponsor_logos.png" alt="" />
          </div>
        </div>
      </TourSection>

      <Divider />

      <TourSection>
        <div className="grid lg:grid-cols-3 gap-6">
          <div className="hidden sm:flex items-center">
            <img src={imgUrl('partners.png')} alt="" />
          </div>
          <div className="lg:col-span-2 flex items-center">
            <Page pageData={pages['tour-partner-benefits']} />
          </div>
          <div className="flex items-center sm:hidden">
            <img src={imgUrl('partners.png')} alt="" />
          </div>
        </div>
      </TourSection>
    </div>
  );
}
