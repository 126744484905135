import { markerIcon } from '~/utils/georeference';

export type GDEValue = 'Continental' | 'Paralic and Shallow Marine';
export const validGDEs: GDEValue[] = [
  'Continental',
  'Paralic and Shallow Marine',
];

const colors = {
  RED: '#b30000',
  PINK: '#ff6699',
  ORANGE: '#ff6600',
  YELLOW: '#e6e600',
  LT_GREEN: '#00e673',
  DK_GREEN: '#006600',
  BLUE: '#0000ff',
  LT_PURPLE: '#bb99ff',
  DK_PURPLE: '#5900b3',
};

export function classificationColor(gde: GDEValue, classification: string) {
  if (gde === 'Continental') {
    switch (classification) {
      case 'A':
        return colors.RED;
      case 'AF':
        return colors.PINK;
      case 'FA':
        return colors.ORANGE;
      case 'F':
        return colors.YELLOW;
      case 'FL':
        return colors.LT_GREEN;
      case 'LF':
        return colors.DK_GREEN;
      case 'L':
        return colors.BLUE;
      case 'LA':
        return colors.DK_PURPLE;
      case 'AL':
        return colors.DK_PURPLE;
      default:
        return '#000000';
    }
  }

  switch (classification) {
    case 'F':
      return colors.RED;
    case 'FW':
      return colors.PINK;
    case 'WF':
      return colors.ORANGE;
    case 'W':
      return colors.YELLOW;
    case 'WT':
      return colors.LT_GREEN;
    case 'TW':
      return colors.DK_GREEN;
    case 'T':
      return colors.BLUE;
    case 'TF':
      return colors.DK_PURPLE;
    case 'FT':
      return colors.DK_PURPLE;
    default:
      return '#000000';
  }
}

export function modernPointIcon(
  gde: GDEValue,
  classification: string,
): NonNullable<google.maps.MarkerOptions['icon']> {
  const color = classificationColor(gde, classification);
  return markerIcon({
    size: 8,
    fill: color,
    fillOpacity: 1,
    strokeWidth: 0,
  });
}
