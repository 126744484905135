import { useQuery , gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

import * as fragments from '~/apollo/fragments';
import type {
  LithostratWikiOverviewPageQuery,
  LithostratWikiOverviewPageQueryVariables,
} from '~/apollo/generated/schema';
import { FilterSearch } from '~/components/common/FilterSearch';
import { PageHeading } from '~/components/common/PageHeading';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { LithostratTypeLink } from '~/components/wiki/lithostratType/LithostratTypeLink';
import { useSortFilter } from '~/hooks/data';
import {
  lithostratFormationRoute,
  lithostratGroupRoute,
  lithostratMemberRoute,
} from '~/paths';
import { mostSpecificAge } from '~/utils/modules/lithostratAge';

const LITHOSTRAT_WIKI_OVERVIEW_PAGE = gql`
  query LithostratWikiOverviewPage {
    lithostratFormationList(hasWiki: true) {
      ...lithostratFormationParts
      startAge {
        ...lithostratAgeParts
      }
      wikiPage {
        ...lithostratWikiPageParts
      }
    }
    lithostratMemberList(hasWiki: true) {
      ...lithostratMemberParts
      startAge {
        ...lithostratAgeParts
      }
      wikiPage {
        ...lithostratWikiPageParts
      }
    }
    lithostratGroupList(hasWiki: true) {
      ...lithostratGroupParts
      startAge {
        ...lithostratAgeParts
      }
      wikiPage {
        ...lithostratWikiPageParts
      }
    }

    lithostratTypeList {
      ...lithostratTypeParts
      diagram {
        ...diagramParts
        file {
          ...fileParts
          signedUrl
        }
      }
    }
  }

  ${fragments.lithostratFormationParts}
  ${fragments.lithostratGroupParts}
  ${fragments.lithostratMemberParts}
  ${fragments.lithostratAgeParts}
  ${fragments.lithostratTypeParts}
  ${fragments.diagramParts}
  ${fragments.fileParts}
  ${fragments.lithostratWikiPageParts}
`;

type MergedLithostratItem = {
  type: 'formation' | 'group' | 'member';
  id: number;
  name: string;
  region: string | null;
  age: string | null;
  link: string;
};

type Fm = LithostratWikiOverviewPageQuery['lithostratFormationList'][number];
type Gp = LithostratWikiOverviewPageQuery['lithostratGroupList'][number];
type Mb = LithostratWikiOverviewPageQuery['lithostratMemberList'][number];

const toMergedLithostratItem =
  (type: MergedLithostratItem['type']) =>
  (item: Fm | Gp | Mb): MergedLithostratItem => {
    let link: string;
    if (type === 'formation') link = lithostratFormationRoute(item.id);
    else if (type === 'group') link = lithostratGroupRoute(item.id);
    else link = lithostratMemberRoute(item.id);

    return {
      type,
      id: item.id,
      name: item.name,
      region: item.wikiPage?.region ?? null,
      age: mostSpecificAge(item.startAge),
      link,
    };
  };

export default function LithostratWikiOverviewRoute() {
  const { data, loading } = useQuery<
    LithostratWikiOverviewPageQuery,
    LithostratWikiOverviewPageQueryVariables
  >(LITHOSTRAT_WIKI_OVERVIEW_PAGE, {});

  const lithostratTypes = (data?.lithostratTypeList ?? []).filter(
    lt => lt.diagram !== null,
  );

  const formations = data?.lithostratFormationList ?? [];
  const groups = data?.lithostratGroupList ?? [];
  const members = data?.lithostratMemberList ?? [];

  const genericFms = formations.map(toMergedLithostratItem('formation'));
  const genericGroups = groups.map(toMergedLithostratItem('group'));
  const genericMembers = members.map(toMergedLithostratItem('member'));

  const mergedItems = R.flatten([genericFms, genericGroups, genericMembers]);

  const {
    items,
    sortIndicatorProps: siProps,
    filterSearchProps,
  } = useSortFilter(mergedItems, 'name', 'name');

  useBreadcrumb('routes/wiki/regional/lithostrat/index', 'Lithostratigraphy');

  if (loading) return <SpinnerPlaceholder />;

  return (
    <div className="space-y-4">
      <PageHeading>Lithostratigraphy</PageHeading>

      <p>
        The Safari Wiki contains description and analogue information for{' '}
        {mergedItems.length} lithostratigraphy units. Use keyword search tool or
        browse below.
      </p>

      <div className="grid lg:grid-cols-4 gap-6">
        <div className="lg:col-span-3">
          <FilterSearch
            {...filterSearchProps}
            showAlphabet={false}
            renderSearch={s => s}
          />

          <table className="table table-compact w-full">
            <thead>
              <tr>
                <th>
                  <SortTrigger colName="name" sortIndicatorProps={siProps}>
                    Name
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="region"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Region
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="age"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Age
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="type"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Type
                  </SortTrigger>
                </th>
              </tr>
            </thead>

            <tbody>
              {items.map(item => (
                <tr key={`${item.type}--${item.id}`}>
                  <td>
                    <Link to={item.link} className="link">
                      {item.name}
                    </Link>
                  </td>
                  <td>{item.region}</td>
                  <td>{item.age}</td>
                  <td>{item.type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="space-y-4">
          {lithostratTypes.map(lt => (
            <LithostratTypeLink key={lt.id} lithostratType={lt} />
          ))}
        </div>
      </div>
    </div>
  );
}
