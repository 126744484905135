import { faCheck, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button } from 'react-daisyui';
import { CesiumViewer } from '~/components/cesium/CesiumViewer';
import type { TilesetParams } from '~/components/cesium/cesiumUtils';
import { TerrainProvider } from '~/components/cesium/cesiumUtils';
import { queryClient } from '~/main';
import {
  getGetApiV4VirtualOutcropModelsIdQueryKey,
  usePatchApiV4CesiumAssetsIdApproveQuality,
  usePatchApiV4CesiumAssetsIdResetToInitialized,
} from '~/openapi/api-v4';
import { useUpdateVomOutletContext } from '~/routes/upload/vom/$vomId';

export function QualityAssuranceState({
  vomId,
  cesiumAssetId,
}: {
  vomId: string;
  cesiumAssetId: string;
}) {
  const ctx = useUpdateVomOutletContext();
  const cesiumAsset = ctx.vom.cesiumAsset;
  const [ca, setCa] = useState<TilesetParams | null>(null);

  useEffect(() => {
    if (cesiumAsset && cesiumAsset.assetToken && cesiumAsset.localPath) {
      const tilesetAsset: TilesetParams = {
        assetToken: cesiumAsset.assetToken,
        localPath: cesiumAsset.localPath,
        transform: { local: true },
      };
      setCa(tilesetAsset);
    }
  }, [cesiumAsset]);

  return (
    <div className="space-y-4">
      {ca && (
        <CesiumViewer
          initialTilesets={[ca]}
          terrainProvider={TerrainProvider.None}
        />
      )}

      <div className="text-center space-x-2">
        <ResetButton vomId={vomId} cesiumAssetId={cesiumAssetId} />
        <ApproveButton vomId={vomId} cesiumAssetId={cesiumAssetId} />
      </div>
    </div>
  );
}

function ApproveButton({
  vomId,
  cesiumAssetId,
}: {
  vomId: string;
  cesiumAssetId: string;
}) {
  const mutation = usePatchApiV4CesiumAssetsIdApproveQuality();

  async function handleApprove() {
    const confirmMsg = 'Are you sure you want to approve the asset?';
    if (!window.confirm(confirmMsg)) return;

    mutation.mutate(
      {
        id: cesiumAssetId,
        data: {
          data: {
            id: cesiumAssetId,
          },
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: getGetApiV4VirtualOutcropModelsIdQueryKey(vomId),
          });
        },
      },
    );
  }

  return (
    <Button
      type="button"
      onClick={handleApprove}
      color="success"
      startIcon={<FontAwesomeIcon icon={faCheck} />}
      loading={mutation.isLoading}
      disabled={mutation.isLoading}
    >
      Approve
    </Button>
  );
}

function ResetButton({
  vomId,
  cesiumAssetId,
}: {
  vomId: string;
  cesiumAssetId: string;
}) {
  const mutation = usePatchApiV4CesiumAssetsIdResetToInitialized();

  async function handleReset() {
    const confirmMsg = 'Are you sure you want to reset the asset?';
    if (!window.confirm(confirmMsg)) return;

    mutation.mutate(
      {
        id: cesiumAssetId,
        data: {
          data: {
            id: cesiumAssetId,
          },
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: getGetApiV4VirtualOutcropModelsIdQueryKey(vomId),
          });
        },
      },
    );
  }

  return (
    <Button
      type="button"
      onClick={handleReset}
      color="ghost"
      startIcon={<FontAwesomeIcon icon={faRefresh} />}
      loading={mutation.isLoading}
      disabled={mutation.isLoading}
    >
      Reset
    </Button>
  );
}
