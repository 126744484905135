import { useLazyQuery, useQuery } from '@apollo/client';
import { Field, useFormikContext } from 'formik';
import * as R from 'ramda';
import { useEffect, useRef } from 'react';
import type {
  DepositionalWikiEnumsQuery,
  GeologyTypeEnumsQuery,
} from '~/apollo/generated/schema';
import {
  DEPOSITIONAL_WIKI_ENUMS,
  GEOLOGY_TYPE_ENUMS,
} from '~/apollo/operations/wiki';
import { FormikField } from '~/components/common/FormikField';
import { FormikTinyMceField } from '~/components/common/FormikField/FormikTinyMceField';
import { Heading } from '~/components/common/Heading';
import { InputGroup } from '~/components/common/InputGroup';
import { Panel } from '~/components/common/Panel';
import type { DepositionalWikiFormValues } from '~/utils/modules/depositionalWiki';
import { depositionalWikiTypes } from '~/utils/modules/depositionalWiki';
import { geologyTypeValueToEnum } from '~/utils/modules/keyParameters';
import { snakeCapsToHuman } from '~/utils/text';
import { VomExampleField } from './VomExampleField';

export function DepositionalWikiFormFields() {
  const { data: geologyTypeEnumsData, loading: loadingGeologyTypeEnums } =
    useQuery<GeologyTypeEnumsQuery>(GEOLOGY_TYPE_ENUMS, {});

  const [
    loadDepositionalWikiEnums,
    { data: depositionalWikiEnumsData, loading: loadingWikiValue },
  ] = useLazyQuery<DepositionalWikiEnumsQuery>(DEPOSITIONAL_WIKI_ENUMS, {});

  const { values, setFieldValue } =
    useFormikContext<DepositionalWikiFormValues>();

  const geologyType = values.geologyType;
  const geologyTypeRef = useRef(geologyType);

  useEffect(() => {
    if (geologyType) {
      loadDepositionalWikiEnums({
        variables: { geologyType: geologyTypeValueToEnum(geologyType) },
      });
    }

    if (geologyType !== geologyTypeRef.current) {
      setFieldValue('value', '');
    }

    geologyTypeRef.current = geologyType;
    console.groupEnd();
  }, [geologyType]);

  useEffect(() => {
    if (values.readyForApproval && values.published) {
      setFieldValue('readyForReview', false);
    }
  }, [values.readyForApproval, values.published]);

  const geologyTypes = geologyTypeEnumsData?.outcropEnumerations.values ?? [];
  const geologyTypeOptions = geologyTypes.map(gt => ({
    value: gt,
    label: gt,
  }));

  function getValueOptions() {
    const typeValue = values.type;
    if (
      !depositionalWikiEnumsData ||
      !geologyType ||
      !typeValue ||
      typeValue === 'NOT_SET' ||
      !(typeValue in depositionalWikiEnumsData)
    ) {
      return [];
    }

    const dwEnums = depositionalWikiEnumsData[typeValue]?.values ?? [];
    const options = dwEnums.map(value => ({ value, label: value }));
    return R.sortBy(R.prop('label'), options);
  }

  return (
    <div className="space-y-2">
      <Field
        name="title"
        label="Title"
        component={FormikField}
        type="text"
        required
        className="input-lg"
      />
      <Field name="author" label="Author" component={FormikField} />

      <div className="grid lg:grid-cols-3 gap-6">
        <div>
          <Field
            name="geologyType"
            label="Geology type"
            component={FormikField}
            type="select"
            options={geologyTypeOptions}
            disabled={loadingGeologyTypeEnums}
            required
            helpText="Select the wiki type to get value options"
          />
        </div>

        <div>
          <Field
            name="type"
            label="Wiki type"
            component={FormikField}
            type="select"
            options={depositionalWikiTypes.map(opt => ({
              value: opt,
              label: snakeCapsToHuman(opt),
            }))}
            required
            helpText="Select the wiki type to get value options"
          />
        </div>

        <div>
          <Field
            name="value"
            label="Type value"
            component={FormikField}
            type="select"
            options={getValueOptions()}
            disabled={loadingWikiValue}
            required
            multiple
            helpText="Select the value for the wiki type"
            style={{ height: '200px' }}
          />
        </div>
      </div>

      <Field
        name="intro"
        label="Introduction"
        component={FormikField}
        type={FormikTinyMceField}
        config={{ height: 300 }}
      />

      <Field
        name="description"
        label="Description"
        component={FormikField}
        type={FormikTinyMceField}
        config={{ height: 300 }}
      />

      <Field
        name="diagnostic"
        label="Diagnostic"
        component={FormikField}
        type={FormikTinyMceField}
        config={{ height: 300 }}
      />

      <Field
        name="subsurface"
        label="Subsurface"
        component={FormikField}
        type={FormikTinyMceField}
        config={{ height: 300 }}
      />

      <Field
        name="reservoir"
        label="Reservoir"
        component={FormikField}
        type={FormikTinyMceField}
        config={{ height: 300 }}
      />

      <Field
        name="modeling"
        label="Modeling"
        component={FormikField}
        type={FormikTinyMceField}
        config={{ height: 300 }}
      />

      <Field
        name="modernExample"
        label="Modern example"
        component={FormikField}
        type={FormikTinyMceField}
        config={{ height: 300 }}
      />

      <Field
        name="references"
        label="References"
        component={FormikField}
        type={FormikTinyMceField}
        config={{ height: 300 }}
      />

      <Field
        name="mafLink"
        label="Modern tool search parameters"
        component={FormikField}
        helpText="To generate a link, copy MAF tool URL after the ? sign"
        renderInput={(input: JSX.Element) => (
          <InputGroup>
            <InputGroup.Addon>/modern?</InputGroup.Addon>
            {input}
          </InputGroup>
        )}
        className="w-full join-item"
        placeholder="gde=Continental&classification=F&basinType=Foreland&climate=arid"
      />

      <VomExampleField />

      <div className="grid lg:grid-cols-3 gap-6">
        <div className="lg:col-start-2">
          <Panel>
            <Panel.Body>
              <Heading level={4}>Publishing</Heading>

              <Field
                name="readyForApproval"
                label="Ready for approval"
                component={FormikField}
                type="checkbox"
                disabled={values.published && !values.readyForApproval}
                color="warning"
              />

              <Field
                name="published"
                label="Published"
                component={FormikField}
                type="checkbox"
                color="success"
              />
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </div>
  );
}
