import { cn } from '~/utils/common';
import type { FieldProps } from 'formik';
import { ErrorMessage } from 'formik';
import * as R from 'ramda';
import React from 'react';
import { Radio } from 'react-daisyui';
import { FormLabel } from './FormLabel';

export type RadioOption = {
  value: any;
  text: React.ReactNode;
  disabled?: boolean;
};

export type Props = FieldProps & {
  options: RadioOption[];
  className?: string;
  label?: React.ReactNode;
  helpText?: string;
  required?: boolean;
  inline?: boolean;
  isInvalid?: boolean;
};

export function FormikRadioField({
  form,
  field,
  className,
  label,
  helpText,
  options,
  required = false,
  inline = false,
  isInvalid,
  ...props
}: Props) {
  const handleChange = (value: any) => () => {
    form.setFieldValue(field.name, value);
  };

  const fieldPath = field.name.split('.');
  const currentValue = R.path(fieldPath, form.values);

  return (
    <>
      <div>
        <FormLabel
          name={field.name}
          label={label}
          helpText={helpText}
          required={required}
        />
      </div>

      <div className={inline ? 'flex gap-2 items-center' : ''}>
        {options.map((option, i) => (
          <div key={i} className="form-control">
            <label className={cn('label justify-start gap-2')}>
              <Radio
                {...field}
                {...props}
                type="radio"
                value={option.value}
                onChange={handleChange(option.value)}
                checked={option.value === currentValue}
                disabled={option.disabled}
                className="radio"
              />
              <span className="label-text">{option.text}</span>
            </label>
          </div>
        ))}
      </div>

      <div className="text-error">
        <ErrorMessage name={field.name} />
      </div>
    </>
  );
}
