import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Footer } from '~/components/layout/Footer';
import { Navbar } from '~/components/layout/Navbar';
import { BodyProvider, BodyContext } from './BodyContext';
import {
  Breadcrumb,
  BreadcrumbContext,
  BreadcrumbProvider,
} from './Breadcrumb';

type Props = {
  children: React.ReactNode;
};

export function AppShell({ children }: Props) {
  return (
    <>
      <HelmetProvider>
        <BreadcrumbProvider>
          <BreadcrumbContext.Consumer>
            {({ title }) => (
              <Helmet>
                <title>{title}</title>
              </Helmet>
            )}
          </BreadcrumbContext.Consumer>

          <Navbar />

          <div className="container mx-auto max-w-7xl">
            <Breadcrumb />
          </div>
          <BodyProvider>
            <BodyContext.Consumer>
              {({ bodyClass }) => (
                <main className={bodyClass.join(' ')}>{children}</main>
              )}
            </BodyContext.Consumer>
          </BodyProvider>
          <div className="h-36" />
          <Footer />
        </BreadcrumbProvider>
      </HelmetProvider>
    </>
  );
}
