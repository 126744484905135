import type { FormikConfig, FormikHelpers } from 'formik';
import { yupToFormErrors } from 'formik';
import * as R from 'ramda';
import { ValidationError } from 'yup';

export type FormikSubmitHandler<TValues = {}> =
  FormikConfig<TValues>['onSubmit'];

/** Looks for GraphQL-like validation errors in the response and sets them in the formik context */
export function setValidationErrors<TValues>(
  error: unknown,
  { setErrors }: Pick<FormikHelpers<TValues>, 'setErrors'>,
) {
  if (!error || typeof error !== 'object') {
    return;
  }

  // Apollo Server 4 error path
  const errorPath = ['graphQLErrors', 0, 'extensions', 'originalError'];

  const err = R.path(errorPath, error);
  const isValidationErr = R.pathEq(['error'], 'validation_error', err);
  const errorsObj = R.pathOr<unknown>(null, ['message'], err);

  // console.log('Converting to formik:', JSON.parse(JSON.stringify(error)));
  // console.log('Errors object:', errorsObj);

  // yupToFormErrors expects a proper ValidationError object, but it's serialized.
  // Re-instantiate error objects before converting
  if (isValidationErr && Array.isArray(errorsObj)) {
    const casted = errorsObj.map(e => new ValidationError(e));
    const single = new ValidationError(casted);
    const formErrors = yupToFormErrors<TValues>(single);
    setErrors(formErrors);
  }
}

export const imageMimes = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/svg+xml',
  'image/tiff',
  'image/png',
];
