import { useQuery } from '@apollo/client';
import { Outlet, useOutletContext } from 'react-router-dom';

import { PAGES_BY_PAGE_NAME } from '~/apollo/operations/page';
import type {
  PagesByPageNameQuery,
  PagesByPageNameQueryVariables,
} from '~/apollo/generated/schema';
import { SideNavigation } from '~/components/about/SideNavigation';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';

// "About" pages do not contain much data, so they can all be preloaded here.
// This way clicking between pages is instant.
const pageNames = [
  'background',
  'safari1',
  'safari2',
  'safari3',
  'safari4',
  'safari5',
  'partners',
  'academic-partners',
  'how-to-become-a-partner',
  'people',
  'students',
  'publications',
  'tech',
  'contact',
  'sc-docs',
];

type OutletContext = {
  pageNames: string[];
  pageListData: PagesByPageNameQuery['pageList'];
};

export default function AboutRoute() {
  useBreadcrumb('routes/about', 'About');

  const { loading, data } = useQuery<
    PagesByPageNameQuery,
    PagesByPageNameQueryVariables
  >(PAGES_BY_PAGE_NAME, {
    variables: { pageNames },
  });

  const pageList = data?.pageList ?? [];

  if (loading) return <SpinnerPlaceholder />;

  const outletContext: OutletContext = {
    pageNames,
    pageListData: pageList,
  };

  return (
    <div className="grid lg:grid-cols-4 gap-6">
      <div>
        <SideNavigation />
      </div>

      <div className="lg:col-span-3">
        <Outlet context={outletContext} />
      </div>
    </div>
  );
}

export function useAboutRouteOutletContext() {
  return useOutletContext<OutletContext>();
}
