import { useQuery , gql } from '@apollo/client';
import type { FieldProps } from 'formik';
import * as R from 'ramda';
import React from 'react';
import type { OutcropSelectDropdownQuery } from '~/apollo/generated/schema';
import { FormikSelectField } from '~/components/common/FormikField/FormikSelectField';

const OUTCROP_SELECT_DROPDOWN = gql`
  query OutcropSelectDropdown {
    outcropList {
      id
      name
    }
  }
`;

type Props = FieldProps & {
  disabled?: boolean;
  onChange?: (value: number | '') => void;
  disabledIds?: number[];
};

export function OutcropSelect({
  form,
  field,
  disabled = false,
  onChange,
  disabledIds = [],
  ...props
}: Props) {
  const { loading, data } = useQuery<OutcropSelectDropdownQuery>(
    OUTCROP_SELECT_DROPDOWN,
    {},
  );
  const outcrops = R.sortBy(R.prop('name'), data?.outcropList ?? []);

  const options = outcrops.map(oc => ({
    value: oc.id,
    label: oc.name,
    disabled: disabledIds.length > 0 && disabledIds.includes(oc.id),
  }));

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    let value: number | '' = parseInt(event.target.value);

    if (Number.isNaN(value)) {
      value = '';
    }

    if (onChange) onChange(value);
    else form.setFieldValue(field.name, value);
  }

  return (
    <FormikSelectField
      form={form}
      field={field}
      {...props}
      onChange={handleChange}
      options={options}
      disabled={disabled || loading}
    />
  );
}
