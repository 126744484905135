import type {
  DiagramAreaInput,
  PolygonVertexInput,
} from '~/apollo/generated/schema';
import { DiagramAreaLinkType } from '~/apollo/generated/schema';
import {
  geologyWikiPageRoute,
  lithostratFormationRoute,
  lithostratGroupRoute,
  lithostratMemberRoute,
} from '~/paths';
import { yup } from '~/utils/validation';

export const linkTargetTypes: DiagramAreaLinkType[] = [
  DiagramAreaLinkType.GeologyWiki,
  DiagramAreaLinkType.Formation,
  DiagramAreaLinkType.Group,
  DiagramAreaLinkType.Member,
];

export type DiagramAreaFormValues = {
  label: string;
  linkTargetType: DiagramAreaLinkType;
  linkTargetId: string;
  path: PolygonVertexInput[];
};

export function defaultDiagram(): DiagramAreaFormValues {
  return {
    label: '',
    linkTargetType: linkTargetTypes[0],
    linkTargetId: '',
    path: [] as PolygonVertexInput[],
  };
}

export const diagramValidationSchema = yup.object({
  label: yup.string().label('label').required(),
  linkTargetType: yup
    .string()
    .label('link type')
    .oneOf(linkTargetTypes)
    .required(),
  linkTargetId: yup.number().integer().label('link target').required(),
  path: yup
    .array()
    .min(3, 'Path must have at least 3 points')
    .label('path')
    .required(),
});

export function linkTarget(targetType: DiagramAreaLinkType, targetId: number) {
  switch (targetType) {
    case 'GEOLOGY_WIKI':
      return geologyWikiPageRoute(targetId);

    case 'FORMATION':
      return lithostratFormationRoute(targetId);

    case 'GROUP':
      return lithostratGroupRoute(targetId);

    case 'MEMBER':
      return lithostratMemberRoute(targetId);

    default:
      throw new Error(`Invalid link type ${targetType}, ${targetId}`);
  }
}

export function toDiagramAreaLinkTargetType(tt: string) {
  switch (tt) {
    case DiagramAreaLinkType.Formation:
      return DiagramAreaLinkType.Formation;
    case DiagramAreaLinkType.Group:
      return DiagramAreaLinkType.Group;
    case DiagramAreaLinkType.Member:
      return DiagramAreaLinkType.Member;
    case DiagramAreaLinkType.GeologyWiki:
      return DiagramAreaLinkType.GeologyWiki;
    default:
      throw new Error(`Unhandled diagram area link target type: ${tt}`);
  }
}

export function toDiagramAreaInput(
  fv: DiagramAreaFormValues,
): DiagramAreaInput {
  return {
    label: fv.label,
    linkTargetType: toDiagramAreaLinkTargetType(fv.linkTargetType),
    linkTargetId: parseInt(fv.linkTargetId),
    path: fv.path,
  };
}
