import { useQuery , gql } from '@apollo/client';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import * as fragments from '~/apollo/fragments';
import type {
  UploadLithostratTypeUpdateEntitiesTabQuery,
  UploadLithostratTypeUpdateEntitiesTabQueryVariables,
} from '~/apollo/generated/schema';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { NotFound } from '~/components/common/NotFound';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { useSortFilter } from '~/hooks/data';
import {
  uploadLithostratFormationUpdateRoute,
  uploadLithostratGroupUpdateRoute,
  uploadLithostratMemberUpdateRoute,
} from '~/paths';
import { useLithostratTypeUpdateOutletContext } from '~/routes/upload/lithostrat-type/$lithostratTypeId';

const ENTITIES_TAB = gql`
  query UploadLithostratTypeUpdateEntitiesTab($id: Int!) {
    lithostratTypeList(id: $id) {
      ...lithostratTypeParts
      groups {
        ...lithostratGroupParts
        wikiPage {
          ...lithostratWikiPageParts
        }
      }
      formations {
        ...lithostratFormationParts
        wikiPage {
          ...lithostratWikiPageParts
        }
      }
      members {
        ...lithostratMemberParts
        wikiPage {
          ...lithostratWikiPageParts
        }
      }
    }
  }

  ${fragments.lithostratTypeParts}
  ${fragments.lithostratGroupParts}
  ${fragments.lithostratFormationParts}
  ${fragments.lithostratMemberParts}
  ${fragments.lithostratWikiPageParts}
`;
type LithostratType =
  UploadLithostratTypeUpdateEntitiesTabQuery['lithostratTypeList'][number];
type Formation = LithostratType['formations'][number];
type Group = LithostratType['groups'][number];
type Member = LithostratType['members'][number];

export default function LithostratTypeUpdateEntitiesRoute() {
  const { lithostratType } = useLithostratTypeUpdateOutletContext();
  const lithostratTypeId = lithostratType.id;

  const { data, loading } = useQuery<
    UploadLithostratTypeUpdateEntitiesTabQuery,
    UploadLithostratTypeUpdateEntitiesTabQueryVariables
  >(ENTITIES_TAB, {
    variables: { id: lithostratTypeId },
  });

  const lithostratTypes = data?.lithostratTypeList ?? [];
  const lt = lithostratTypes.find(lt => lt.id === lithostratTypeId);

  if (loading) return <SpinnerPlaceholder />;
  if (!lt) return <NotFound />;

  return (
    <div className="grid lg:grid-cols-3 gap-6">
      <div>
        <EntityList label="Groups" entities={lt.groups ?? []} />
      </div>

      <div>
        <EntityList label="Formations" entities={lt.formations ?? []} />
      </div>

      <div>
        <EntityList label="Members" entities={lt.members ?? []} />
      </div>
    </div>
  );
}

type WikiState = 'no wiki' | 'created' | 'published';
type WikiMetadata = { wikiState: WikiState };
type Entity = Group | Formation | Member;

function WikiStateIcon({ state }: { state: WikiState }): JSX.Element {
  return (
    <Tooltip message={state}>
      <span>
        {state === 'no wiki' && <EnabledIndicator value={false} />}
        {state === 'created' && (
          <FontAwesomeIcon icon={faCheckCircle} className="text-muted" />
        )}
        {state === 'published' && <EnabledIndicator value={true} />}
      </span>
    </Tooltip>
  );
}

function withWikiMetadata<T extends Entity>(entity: T): T & WikiMetadata {
  let wikiState: WikiState = 'no wiki';
  if (typeof entity.wikiPage === 'object') wikiState = 'created';
  if (entity.wikiPage?.published) wikiState = 'published';

  return { ...entity, wikiState };
}

type EntityListProps = {
  entities: Entity[];
  label: string;
};
function EntityList({ entities, label }: EntityListProps): JSX.Element {
  const entitiesWithMetadata = entities.map(withWikiMetadata);
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    entitiesWithMetadata,
    'name',
    'name',
    'uploadLithostratTypeEntities',
  );

  function getRoute(entity: Entity): string {
    let route: string | null = null;
    if (label === 'Groups') {
      route = uploadLithostratGroupUpdateRoute(entity.id);
    } else if (label === 'Formations') {
      route = uploadLithostratFormationUpdateRoute(entity.id);
    } else if (label === 'Members') {
      route = uploadLithostratMemberUpdateRoute(entity.id);
    } else {
      throw new Error(`Unhandled entity type ${label} when creating link`);
    }

    return route;
  }

  return (
    <table className="table table-compact w-full">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="name" sortIndicatorProps={siProps}>
              {label}
            </SortTrigger>
          </th>
          <th className="text-center w-24">
            <SortTrigger
              colName="wikiState"
              sortIndicatorProps={siProps}
              filterable
            >
              Wiki
            </SortTrigger>
          </th>
        </tr>
      </thead>

      <tbody>
        <NoItemsRow show={!items.length} colSpan={2} />
        {items.map(item => (
          <tr key={item.id}>
            <td>
              <Link to={getRoute(item)}>{item.name}</Link>
            </td>
            <td className="text-center">
              <WikiStateIcon state={item.wikiState} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
