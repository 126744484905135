import { z } from 'zod';
import type { GeoreferenceInput } from '~/apollo/generated/schema';
import { GeoreferenceDataType } from '~/apollo/generated/schema';
import type { GeoreferenceImportInput } from '../geojson';

export function toGeoreferenceDataType(dataType: string): GeoreferenceDataType {
  switch (dataType) {
    case GeoreferenceDataType.Centre:
      return GeoreferenceDataType.Centre;
    case GeoreferenceDataType.Point:
      return GeoreferenceDataType.Point;
    case GeoreferenceDataType.Outline:
      return GeoreferenceDataType.Outline;
    case GeoreferenceDataType.Polygon:
      return GeoreferenceDataType.Polygon;
    case GeoreferenceDataType.Polyline:
      return GeoreferenceDataType.Polyline;
    default:
      throw new Error(`Invalid data type ${dataType}`);
  }
}

export function importToGeoreferenceInput(
  g: GeoreferenceImportInput,
): GeoreferenceInput {
  return {
    name: g.name,
    description: g.description,
    dataType: toGeoreferenceDataType(g.dataType),
    data: g.data,
  };
}

export const georeferenceInputSchema = z.object({
  dataType: z.nativeEnum(GeoreferenceDataType),
  name: z.string().min(1),
  description: z.string().nullable(),
  data: z
    .array(
      z.object({
        lat: z.number(),
        lng: z.number(),
      }),
    )
    .min(1),
});
