import { Link } from 'react-router-dom';
import React from 'react';
import { Heading } from '~/components/common/Heading';
import { LoginButton } from '~/components/layout/Navbar/LoginButton';
import { useAuth } from '~/contexts/auth';
import { dashboardRoute } from '~/paths';

export function FeaturedLinkContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="flex justify-center items-center text-center p-4 text-sm text-slate-700 border border-transparent hover:no-underline hover:border-primary hover:bg-slate-50">
      {children}
    </div>
  );
}

type SponsorButtonProps = {
  isAuthenticated: boolean;
};
function SponsorButton({ isAuthenticated }: SponsorButtonProps) {
  return (
    <div className="text-center">
      <img
        src="/assets/images/page/handshake.png"
        alt=""
        className="inline-block w-20 h-auto"
      />
      <Heading level={3}>Sponsor</Heading>
      <p>
        {isAuthenticated ? 'Access' : 'Log in to access'} numerical data, the
        modern analogue finder, the knowledge base, and over 500 virtual
        outcrops.
      </p>
    </div>
  );
}

type WrapperProps = { isAuthenticated: boolean };
function SponsorButtonWrapper({ isAuthenticated }: WrapperProps) {
  const children = <SponsorButton isAuthenticated={isAuthenticated} />;

  if (isAuthenticated) return <Link to={dashboardRoute()}>{children}</Link>;
  return <LoginButton>{children}</LoginButton>;
}

export function FeaturedLinks() {
  const { isAuthenticated } = useAuth();

  return (
    <div className="text-center">
      <div className="md:w-1/2 mx-auto">
        <div className="grid md:grid-cols-2 gap-6">
          <FeaturedLinkContainer>
            <SponsorButtonWrapper isAuthenticated={isAuthenticated} />
          </FeaturedLinkContainer>

          <FeaturedLinkContainer>
            <a
              href="https://v3geo.com"
              className="block h-full w-full text-center"
            >
              <img
                src="/assets/images/page/search-globe.png"
                className="inline-block w-20 h-auto"
                alt=""
              />
              <Heading level={3}>Not a Sponsor?</Heading>
              <p>
                Browse for publically available virtual ouctrops on V3Geo - the
                Virtual 3D Geoscience Database.
              </p>
            </a>
          </FeaturedLinkContainer>
        </div>
      </div>
    </div>
  );
}
