import { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { JoystickShape } from 'react-joystick-component';
import { FormikField } from '~/components/common/FormikField';
import SignalJoystick from './Joystick';

type JoystickDirection = 'FORWARD' | 'RIGHT' | 'LEFT' | 'BACKWARD' | 'CENTER';

export interface IJoystickUpdateEvent {
  type: 'move' | 'stop' | 'start' | 'stream';
  x: number | null;
  y: number | null;
  direction: JoystickDirection | null;
  distance: number | null; // Percentile 0-100% of joystick
}

type FormValues = {
  latitude: number;
  longitude: number;
  height: number;
  heading: number;
  pitch: number;
  roll: number;
};

type PlacementFieldsProps = {
  placement: FormValues;
  zoomValue: number;
};

export default function VomPlacementFields({
  placement,
  zoomValue = 1,
}: PlacementFieldsProps) {
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const [joystickSpeed, setJoystickSpeed] = useState<number>(0.0001);

  useEffect(() => {
    setFieldValue('latitude', placement.latitude);
    setFieldValue('longitude', placement.longitude);
    setFieldValue('height', placement.height);
    setFieldValue('heading', placement.heading);
    setFieldValue('pitch', placement.pitch);
    setFieldValue('roll', placement.roll);
  }, [placement]);

  useEffect(() => {
    setJoystickSpeed(zoomValue * 0.0001);
  }, [zoomValue]);

  const handleMove = (event: IJoystickUpdateEvent | null) => {
    if (!event) {
      return;
    }
    if (event.type === 'move' && event.x && event.y) {
      const newPlacement = values;
      newPlacement.latitude = Number(newPlacement.latitude) + event.y * joystickSpeed;
      newPlacement.longitude =  Number(newPlacement.longitude) + event.x * joystickSpeed;
      setFieldValue('latitude', newPlacement.latitude.toFixed(5));
      setFieldValue('longitude', newPlacement.longitude.toFixed(5));
    }
  };

  const handleHeightMove = (event: IJoystickUpdateEvent | null) => {
    if (!event) {
      return;
    }
    if (event.type === 'move' && event.y) {
      const newPlacement = values;
      newPlacement.height = Number(newPlacement.height) + event.y;
      setFieldValue('height', newPlacement.height.toFixed(5));
    }
  };

  return (
    <div>
      <div className="m-2">
        <div className="row-span-full text-center">
          <p> Option/Alt + click to place model</p>
          <p> Use joystick or input for lat/lng. use input for height </p>
        </div>
        <div className="flex justify-center pt-2">
          <div className="flex flex-col items-center mx-4">
            <SignalJoystick
              size={100}
              label={'Latitude/Longitude'}
              signalRate={50}
              callback={handleMove}
              throttle={50}
            ></SignalJoystick>
          </div>
          <div className="flex flex-col items-center mx-4">
            <SignalJoystick
              size={100}
              label={'Height'}
              signalRate={50}
              callback={handleHeightMove}
              stickShape={JoystickShape.Square}
              baseShape={JoystickShape.Square}
              joystickdirection={'AxisY'}
              stickColor="#3d59ac"
              stickImage='/assets/images/up-down-caret-white.png'
              throttle={50}
            ></SignalJoystick>
          </div>
        </div>
      </div>
      <div className="m-2">
        <div className="grid grid-cols-subgrid gap-4 col-span-1">
          <Field
            name="latitude"
            label="latitude"
            component={FormikField}
            type="number"
            step={0.0001}
            placeholder="Latitude"
            showSpin={true}
          />
          <Field
            name="longitude"
            label="longitude"
            component={FormikField}
            type="number"
            step={0.0001}
            showSpin={true}
            placeholder="Longitude"
          />
          <Field
            name="height"
            label="height"
            component={FormikField}
            type="number"
            placeholder="height"
            showSpin={true}
          />
          <Field
            name="heading"
            label="heading"
            component={FormikField}
            type="number"
            placeholder="Heading"
            showSpin={true}
          />
          <Field
            name="pitch"
            label="pitch"
            component={FormikField}
            type="number"
            placeholder="Pitch"
            showSpin={true}
          />
          <Field
            name="roll"
            label="roll"
            component={FormikField}
            type="number"
            placeholder="Roll"
            showSpin={true}
          />
        </div>
      </div>
    </div>
  );
}
