import { Link } from 'react-router-dom';
import type {
  LithostratOutcropLinkPartsFragment,
  LithostratStudyLinkPartsFragment,
} from '~/apollo/generated/schema';
import {
  lithostratFormationRoute,
  lithostratGroupRoute,
  lithostratMemberRoute,
} from '~/paths';

type Props = {
  lithostratLink:
    | LithostratOutcropLinkPartsFragment
    | LithostratStudyLinkPartsFragment;
};

export function LithostratWikiLink({ lithostratLink }: Props) {
  const { targetType, targetId, targetName } = lithostratLink;

  if (!targetId) return <>{targetName}</>;

  let route;
  switch (targetType) {
    case 'formation':
      route = lithostratFormationRoute(targetId);
      break;
    case 'group':
      route = lithostratGroupRoute(targetId);
      break;
    case 'member':
      route = lithostratMemberRoute(targetId);
      break;
    default:
      throw new Error(
        `Unhandled lithostrat outcrop link parent type '${targetType}'.`,
      );
  }

  return (
    <Link to={route} className="link">
      {targetName}
    </Link>
  );
}
