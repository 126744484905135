import { useEffect } from 'react';
import { Progress } from 'react-daisyui';
import { queryClient } from '~/main';
import {
  getGetApiV4VirtualOutcropModelsIdQueryKey,
  usePatchApiV4CesiumAssetsIdPollArchivingAtCesium,
} from '~/openapi/api-v4';

const INTERVAL_DELAY = 1000 * 30;

export function ArchivingState({
  vomId,
  cesiumAssetId,
}: {
  vomId: string;
  cesiumAssetId: string;
}) {
  const { mutate, isLoading } =
    usePatchApiV4CesiumAssetsIdPollArchivingAtCesium();

  useEffect(() => {
    function loadProgress() {
      mutate(
        {
          id: cesiumAssetId,
          data: {
            data: {
              id: cesiumAssetId,
            },
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: getGetApiV4VirtualOutcropModelsIdQueryKey(vomId),
            });
          },
        },
      );
    }

    const interval = window.setInterval(loadProgress, INTERVAL_DELAY);
    loadProgress();

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [vomId, cesiumAssetId, mutate]);

  return (
    <div className="text-center">
      <Progress
        color={isLoading ? 'neutral' : 'success'}
        className="w-56 h-4"
      />
      <div className="italic text-muted">Archiving...</div>
    </div>
  );
}
