import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Button } from 'react-daisyui';
import { useSearchParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  MySavedSearchesModalQuery,
  MySavedSearchesModalQueryVariables,
} from '~/apollo/generated/schema';
import {
  BookmarkParentType,
  BookmarkTargetType,
} from '~/apollo/generated/schema';
import { BookmarkItem } from '~/components/bookmark/BookmarkList/BookmarkItem';
import { Modal } from '~/components/common/Modal';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useAuth } from '~/contexts/auth';
import { toStandardizedBookmark } from '~/utils/modules/bookmark';

const MY_SAVED_SEARCHES_MODAL = gql`
  query MySavedSearchesModal(
    $includePersonal: Boolean!
    $includeCompany: Boolean!
    $parentType: BookmarkParentType!
    $targetType: BookmarkTargetType!
  ) {
    bookmarkList(
      includePersonal: $includePersonal
      includeCompany: $includeCompany
      parentType: $parentType
      targetType: $targetType
    ) {
      ...bookmarkParts
      parent {
        ...bookmarkParentParts
      }
      target {
        ...bookmarkTargetParts
      }
      user {
        ...publicUserParts
      }
      collections {
        ...bookmarkCollectionParts
      }
    }
  }

  ${fragments.bookmarkParts}
  ${fragments.bookmarkCollectionParts}
  ${fragments.bookmarkParentParts}
  ${fragments.bookmarkTargetParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.publicUserParts}
`;

const MODAL_PARAM = 'sds-modal';
const MODAL_OPEN_VALUE = 'open';

export function MySavedSearchesModal() {
  const { authority } = useAuth();
  invariant(authority);

  const [searchParams, setSearchParams] = useSearchParams();

  const variables: MySavedSearchesModalQueryVariables = {
    includePersonal: true,
    includeCompany: true,
    parentType: BookmarkParentType.SavedDataSearch,
    targetType: BookmarkTargetType.SavedDataSearch,
  };

  const refetchQueries: [PureQueryOptions<MySavedSearchesModalQueryVariables>] =
    [{ query: MY_SAVED_SEARCHES_MODAL, variables }];

  const { data, loading, refetch } = useQuery<
    MySavedSearchesModalQuery,
    MySavedSearchesModalQueryVariables
  >(MY_SAVED_SEARCHES_MODAL, { variables });

  const handleShowModal = () => {
    refetch();
    const nextSearchParams = new URLSearchParams(searchParams);
    nextSearchParams.set(MODAL_PARAM, MODAL_OPEN_VALUE);
    setSearchParams(nextSearchParams);
  };

  const handleCloseModal = () => {
    const nextSearchParams = new URLSearchParams(searchParams);
    nextSearchParams.delete(MODAL_PARAM);
    setSearchParams(nextSearchParams);
  };

  const bookmarks = (data?.bookmarkList ?? []).map(toStandardizedBookmark);
  const items = R.sortBy(b => b.targetName, bookmarks);

  const isModalOpen = searchParams.get(MODAL_PARAM) === MODAL_OPEN_VALUE;

  return (
    <>
      <Button
        type="button"
        onClick={handleShowModal}
        color="primary"
        size="sm"
        startIcon={<FontAwesomeIcon icon={faChartSimple} />}
      >
        Data Bookmarks
      </Button>

      <Modal open={isModalOpen} onHide={handleCloseModal} size="lg">
        <Modal.Body heading="Bookmarked Data">
          <div className="space-y-4">
            <SpinnerPlaceholder show={loading} />

            {!loading && !items.length && (
              <div className="text-muted italic">
                You don't have any data bookmarks yet. You can save a data
                search by using the <i>Bookmark Data</i> button after loading
                measurements.
              </div>
            )}

            {items.map(bookmark => (
              <BookmarkItem
                key={bookmark.id}
                bookmark={bookmark}
                collections={bookmark.collections}
                refetchQueries={refetchQueries}
              />
            ))}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button type="button" color="ghost" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
