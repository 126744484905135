import { gql, useMutation } from '@apollo/client';
import {
  faFileDownload,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Papa from 'papaparse';
import React, { useState } from 'react';
import { Button } from 'react-daisyui';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as fragments from '~/apollo/fragments';
import type {
  ArchitecturalElement,
  ArchitecturalElementInput,
  BulkCreateAEsMutation,
  BulkCreateAEsMutationVariables,
  BulkUploadError,
  BulkValidateAEsMutation,
  BulkValidateAEsMutationVariables,
} from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';
import { DropzoneContainer } from '~/components/common/DropzoneContainer';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { uploadStudyUpdateAEsRoute } from '~/paths';
import { useStudyAEsOutletContext } from '~/routes/upload/model/study/$studyId/architectural-elements';
import { saveAsCSV } from '~/utils/export';
import type { BulkUploadAEField } from '~/utils/modules/architecturalElement';
import {
  bulkUploadAEFields,
  csvRowToAEInput,
  formatAsCSV,
  formatProcessedAEForBulkUpload,
} from '~/utils/modules/architecturalElement';
import { ucwords } from '~/utils/text';

const BULK_VALIDATE_MUTATION = gql`
  mutation BulkValidateAEs(
    $studyId: Int!
    $architecturalElements: [ArchitecturalElementInput!]!
  ) {
    bulkValidateArchitecturalElement(
      studyId: $studyId
      architecturalElements: $architecturalElements
    ) {
      architecturalElement {
        name
        geologyType
        grossDepositionalEnvironment
        depositionalEnvironment
        depositionalSubEnvironment
        architecturalElement
        background
        description
        comments
        outcropOrder
        connectivity
        depositionalConfinement
        shapeKind
        outcropId
        channelMorphology
        diageneticGeometry
        diageneticProcess
        diageneticSetting
        dominantLithology
        duneShape
        faultRocksMembranes
        interactionNetwork
        multipleFolds
        reactivation1stPhase
        reactivation2ndPhase
        riverProfileLocation
        secondaryStructures
        shorelineTractory
        symmetryGeometry
        synSedimentaryDeformation
        systemsTract
        tectonicSetting
        waterTemperature
        lateralAggregation
      }
      errors {
        field
        errorMessage
      }
    }
  }
`;

const BULK_CREATE_MUTATION = gql`
  mutation BulkCreateAEs(
    $studyId: Int!
    $architecturalElements: [ArchitecturalElementInput!]!
    $trashExistingAEs: Boolean
  ) {
    bulkCreateArchitecturalElement(
      studyId: $studyId
      architecturalElements: $architecturalElements
      trashExistingAEs: $trashExistingAEs
    ) {
      ...aeParts
    }
  }

  ${fragments.aeParts}
`;

const formattingGuidelines = (
  <ul className="list-disc">
    <li>
      The file format must be <b>.csv</b>
    </li>
    <li>
      The column separator must be a semicolon{' '}
      <b
        style={{
          backgroundColor: '#dddddd',
          padding: '0 5px 0 5px',
          fontFamily: 'monospace',
        }}
      >
        ;
      </b>
      .
    </li>
    <li>The first row will be ignored.</li>
    <li>
      If a field supports multiple values, each value must be separated by a
      vertical bar: <b>|</b>
      <br />
      E.g.
      <pre>
        systemsTract
        <br />
        transgressive<b>|</b>falling stage
      </pre>
    </li>
    <li>
      The columns must be in the following order:
      <table className="table table-compact table-zebra">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Multiple</th>
          </tr>
        </thead>
        <tbody>
          {bulkUploadAEFields.map((field, i) => (
            <tr key={field.name}>
              <td>{i + 1}</td>
              <td>
                {ucwords(field.name)}
                {field.name === 'name' && (
                  <div className="text-muted">
                    <em>
                      ('architecturalElementName' field on measurement import)
                    </em>
                  </div>
                )}
              </td>
              <td>{field.multiple && 'multiple'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ol></ol>
    </li>
  </ul>
);

const exampleAEs: ArchitecturalElement[] = [
  {
    architecturalElement: 'Dune core',
    background: false,
    comments: 'These are some comments',
    connectivity: 'high',
    depositionalConfinement: 'tectonic confinement',
    depositionalEnvironment: 'Aeolian',
    depositionalSubEnvironment: 'Dune',
    description: 'This is a description',
    geologyType: 'clastic',
    grossDepositionalEnvironment: 'Continental',
    name: 'Test AE 1',
    outcropId: 19558,
    outcropOrder: 'order 4th',
    shapeKind: 'belt',
    studyId: -1,
    id: -1,
    measurementCount: -1,
    measurements: [],
  },
  {
    architecturalElement: 'Channel belt, undifferentiated',
    background: true,
    comments: 'This is a comment',
    connectivity: 'high',
    depositionalConfinement: 'unspecified confinement',
    depositionalEnvironment: 'Distributive fluvial system (DFS)',
    depositionalSubEnvironment: 'Medial DFS (channel)',
    description: 'This is a description',
    geologyType: 'clastic',
    grossDepositionalEnvironment: 'Continental',
    name: 'Test AE 2',
    outcropId: 19558,
    outcropOrder: 'order 3rd',
    shapeKind: 'belt',
    studyId: -1,
    id: -1,
    measurementCount: -1,
    measurements: [],
  },
];
const exampleAEsCSV = formatAsCSV(exampleAEs);

export default function BulkAEImportRoute() {
  const { study, refetchQueries } = useStudyAEsOutletContext();
  const studyId = study.id;

  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [trashExistingAEs, setTrashExistingAEs] = useState(false);
  const [validationResult, setValidationResult] =
    useState<BulkValidateAEsMutation['bulkValidateArchitecturalElement']>();

  const [bulkValidate, { loading: loadingBulkValidate }] = useMutation<
    BulkValidateAEsMutation,
    BulkValidateAEsMutationVariables
  >(BULK_VALIDATE_MUTATION, {});

  const [bulkCreate, { loading: loadingBulkCreate, error: errorsBulkCreate }] =
    useMutation<BulkCreateAEsMutation, BulkCreateAEsMutationVariables>(
      BULK_CREATE_MUTATION,
      { refetchQueries },
    );

  const handleValidation = async (
    architecturalElements: ArchitecturalElementInput[],
  ) => {
    if (!architecturalElements)
      throw new Error('No AEs could be parsed for validation.');

    try {
      const res = await bulkValidate({
        variables: { studyId, architecturalElements },
      });

      setValidationResult(res.data?.bulkValidateArchitecturalElement);

      const numAEs = res.data?.bulkValidateArchitecturalElement.length ?? 0;
      const numErrors =
        res.data?.bulkValidateArchitecturalElement.filter(
          result => result.errors.length > 0,
        ).length ?? 0;

      if (numAEs > 0 && numErrors === 0) {
        setStep(3);
      }
    } catch (err) {
      toast.error(
        'There was a problem running the validation, check the console for raw output.',
      );
      console.log('Error validating AE input:', err);
    }
  };

  const handleDrop = async (files: File[]) => {
    setValidationResult(undefined);
    setStep(2);

    const contents = await files[0].text();

    if (!contents) {
      toast.error(
        `Couldn't read file or there was an error parsing its contents.`,
      );
      return;
    }

    const content = Papa.parse(contents, {
      // No header setting - we'll just ignore the first row below and transform the data manually
      header: false,
      skipEmptyLines: true,
    });
    console.log('Raw CSV input:', content.data);

    // Check if the column length matches the current schema
    const firstRow = content.data.at(0);
    if (
      !firstRow ||
      !Array.isArray(firstRow) ||
      firstRow.length !== bulkUploadAEFields.length
    ) {
      const errMessage =
        "This file couldn't be parsed or contains the incorrect number of columns.";
      window.alert(errMessage);
    }

    const aeInputs: ArchitecturalElementInput[] = content.data
      .slice(1) // Remove the first line to ignore the header row
      .map(item => csvRowToAEInput(item as string[]));
    console.log('Parsed input:', aeInputs);

    handleValidation(aeInputs);
    setStep(2);
  };

  const handleUpload = async () => {
    if (!validationResult?.length) {
      throw new Error('Nothing to create.');
    }
    try {
      const architecturalElements = validationResult.map(res => {
        return formatProcessedAEForBulkUpload(res.architecturalElement);
      });

      const res = await bulkCreate({
        variables: { studyId, architecturalElements, trashExistingAEs },
      });

      const createdAEs = res.data?.bulkCreateArchitecturalElement;
      if (createdAEs?.length) {
        toast.success(
          `Successfully created ${createdAEs.length} architectural elements.`,
        );
      }
      navigate(uploadStudyUpdateAEsRoute(studyId));
    } catch (err) {
      toast.error(
        'There was a problem creating one or more architectural elements.',
      );
      console.log('Error bulk creating AEs', err);
    }
  };

  const hasFieldErrors = (fieldName: string, errors: BulkUploadError[]) => {
    return !!errors.find(err => err.field === fieldName);
  };

  const downloadExampleCSV = () => {
    saveAsCSV(exampleAEsCSV, 'Example AE Import.csv');
  };

  const formatFieldValueForDisplay = (
    field: BulkUploadAEField,
    value: unknown,
  ): React.ReactNode => {
    if (value === undefined || value === null) {
      return <i className="text-muted">(null)</i>;
    }
    if (typeof value === 'string') return value;
    if (value instanceof Array) {
      return (
        <ul className="pl-2 list-disc">
          {value.map((val, i) => (
            <li key={i}>{val}</li>
          ))}
        </ul>
      );
    }
    if (typeof value === 'boolean') return value ? 'true' : 'false';
    return String(value);
  };

  const numAEs = validationResult?.length ?? 0;
  const numErrors =
    validationResult?.filter(result => result.errors.length > 0).length ?? 0;

  return (
    <>
      <PageHeading>Import Architectural Elements</PageHeading>

      <div className="space-y-6">
        <Panel variant={step === 1 ? 'primary' : 'default'}>
          <Panel.Heading>
            <Panel.Title>
              <strong>Step 1</strong>: Select CSV file
            </Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <div className="float-right">
              <Button
                type="button"
                color="primary"
                size="sm"
                onClick={downloadExampleCSV}
                className="gap-1"
              >
                <FontAwesomeIcon icon={faFileDownload} /> Download example file
              </Button>
            </div>

            <div className="space-y-2">
              <p>
                Select a CSV file that contains the Architectural Elements you
                wish to upload.
              </p>
              <Confirm
                onConfirm={() => {}}
                title="Formatting Guidelines"
                text={formattingGuidelines}
                submitText="Ok"
                showCancel={false}
              >
                {showGuidelines => (
                  <Button
                    type="button"
                    color="ghost"
                    size="sm"
                    className="gap-1"
                    onClick={showGuidelines}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} /> File formatting
                    guidelines
                  </Button>
                )}
              </Confirm>

              <Dropzone onDrop={handleDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <DropzoneContainer {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drop a CSV file here, or click to browse.</p>
                  </DropzoneContainer>
                )}
              </Dropzone>
            </div>
          </Panel.Body>
        </Panel>

        {step >= 2 && (
          <Panel variant={step === 2 ? 'primary' : 'default'}>
            <Panel.Heading>
              <Panel.Title>
                <strong>Step 2</strong>: Verify data
              </Panel.Title>
            </Panel.Heading>

            <Panel.Body>
              <p>
                Verify that the CSV file was processed correctly and correct any
                errors.
              </p>

              <SpinnerPlaceholder show={loadingBulkValidate} />

              <div className="w-full max-h-[600px] overflow-x-scroll overflow-y-auto relative">
                <table className="table table-compact table-zebra">
                  <thead>
                    <tr>
                      <th className="sticky top-0 bg-base-200" />
                      {bulkUploadAEFields.map(field => (
                        <th
                          key={field.name}
                          className="sticky top-0 bg-base-200"
                        >
                          {ucwords(field.name)}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {validationResult?.map((result, i) => (
                      <React.Fragment key={i}>
                        <tr>
                          <th>{i + 2}</th>
                          {bulkUploadAEFields.map(field => (
                            <td
                              key={`${field.name}-${i}`}
                              style={
                                hasFieldErrors(field.name, result.errors)
                                  ? {
                                      border: '2px solid red',
                                      backgroundColor: 'hsl(0, 100%, 90%)',
                                    }
                                  : {}
                              }
                            >
                              {formatFieldValueForDisplay(
                                field,
                                result.architecturalElement[field.name],
                              )}
                            </td>
                          ))}
                        </tr>
                        {result.errors.length > 0 && (
                          <tr>
                            <td
                              colSpan={bulkUploadAEFields.length + 1}
                              className="text-danger"
                              style={{
                                border: '2px solid red',
                                backgroundColor: 'hsl(0, 100%, 90%)',
                              }}
                            >
                              <strong>Row {i + 2}</strong> contained the
                              following errors:
                              <ul style={{ marginBottom: 0 }}>
                                {result.errors.map((err, j) => (
                                  <li key={`${err.field}-${j}`}>
                                    {err.errorMessage}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="text-center" style={{ marginTop: '10px' }}>
                <b>{numAEs}</b> total Architectural Elements to be uploaded
                {numErrors > 0 && (
                  <div>
                    <span className="text-danger">{numErrors}</span> rows
                    containing errors.
                    <p>Please correct these errors and re-upload the file.</p>
                  </div>
                )}
              </div>
            </Panel.Body>
          </Panel>
        )}

        {step >= 3 && (
          <Panel variant={step === 3 ? 'primary' : 'default'}>
            <Panel.Heading>
              <Panel.Title>Step 3: Upload bulk data</Panel.Title>
            </Panel.Heading>

            <Panel.Body>
              <p>
                The architectural elements have been validated and are ready to
                be created.
              </p>

              <div className="form-control bg-red-100 p-4 space-y-2">
                <label className="label cursor-pointer justify-start gap-2">
                  <input
                    type="checkbox"
                    checked={trashExistingAEs}
                    onChange={() => setTrashExistingAEs(prevVal => !prevVal)}
                    className="checkbox checkbox-error"
                  />
                  <span className="label-text font-bold">
                    Trash existing AEs
                  </span>
                </label>

                <p>
                  Use this option to prevent naming collisions with existing AEs
                  on this study. All existing AEs names will be appended with
                  "TRASHED (timestamp)".
                </p>
                <p className="text-error">
                  Trashed AEs will continue to be shown in any statistics. Once
                  you've verified all the data was uploaded correctly, empty the
                  trash from the study's main AE list.
                </p>
              </div>

              <div className="text-center">
                <Button
                  type="button"
                  color="primary"
                  onClick={handleUpload}
                  loading={loadingBulkCreate}
                >
                  Create Architectural Elements
                </Button>

                <SpinnerPlaceholder show={loadingBulkCreate} />
              </div>

              {errorsBulkCreate && (
                <div
                  className="alert alert-danger"
                  style={{ marginTop: '20px' }}
                >
                  <h3 style={{ marginTop: 0 }}>Error</h3>
                  <p>There was a problem creating the AEs.</p>
                  <ul>
                    {errorsBulkCreate.graphQLErrors.map((err, i) => (
                      <li key={i}>{err.message}</li>
                    ))}
                  </ul>
                </div>
              )}
            </Panel.Body>
          </Panel>
        )}
      </div>
    </>
  );
}
