import { gql, useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import * as fragments from '~/apollo/fragments';
import type {
  AddBookmarkToCollectionMutation,
  AddBookmarkToCollectionMutationVariables,
  BookmarkCollectionPartsFragment,
  BookmarkInput,
  BookmarkParentType,
  BookmarkTargetType,
  CreateBookmarkCollectionMutation,
  CreateBookmarkCollectionMutationVariables,
  CreateBookmarkMutation,
  CreateBookmarkMutationVariables,
} from '~/apollo/generated/schema';
import { ADD_BOOKMARK_TO_COLLECTION } from '~/apollo/operations/bookmark';
import { CREATE_BOOKMARK_COLLECTION } from '~/components/bookmark/CreateCollectionModal';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import { Heading } from '~/components/common/Heading';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import type { BookmarkFormValuesCreate } from '~/utils/modules/bookmark';
import {
  bookmarkValidationSchema,
  initialBookmarkCreate,
} from '~/utils/modules/bookmark';

const CREATE_BOOKMARK = gql`
  mutation CreateBookmark($bookmark: BookmarkInput!) {
    createBookmark(bookmark: $bookmark) {
      ...bookmarkParts
    }
  }

  ${fragments.bookmarkParts}
`;

type Props = {
  path: string;
  parentType: BookmarkParentType;
  parentId: number;
  targetType: BookmarkTargetType;
  targetId: number;
  isCompany?: boolean;
  collections: Pick<BookmarkCollectionPartsFragment, 'id' | 'name'>[];
  onCreateSuccess?: () => void;
};

export function CreateBookmark({
  path,
  parentType,
  parentId,
  targetType,
  targetId,
  isCompany = false,
  collections,
  onCreateSuccess,
}: Props) {
  const [isAdvancedVisible, setIsAdvancedVisible] = useState(false);

  const [createBookmark, { loading: loadingCreate, error }] = useMutation<
    CreateBookmarkMutation,
    CreateBookmarkMutationVariables
  >(CREATE_BOOKMARK, {});

  const [addToCollection, { loading: loadingAddToCollection }] = useMutation<
    AddBookmarkToCollectionMutation,
    AddBookmarkToCollectionMutationVariables
  >(ADD_BOOKMARK_TO_COLLECTION, {});

  const [
    createCollection,
    { loading: loadingCreateCollection, error: errorCreateCollection },
  ] = useMutation<
    CreateBookmarkCollectionMutation,
    CreateBookmarkCollectionMutationVariables
  >(CREATE_BOOKMARK_COLLECTION, {});

  async function handleSubmit(values: BookmarkFormValuesCreate) {
    const bookmark: BookmarkInput = {
      path,
      parentType,
      parentId,
      targetType,
      targetId,
      note: values.note.trim() || null,
      isCompany,
    };

    let createdBookmark: { id: number };
    try {
      const res = await createBookmark({ variables: { bookmark } });
      if (!res.data?.createBookmark.id) {
        throw new Error('Could not parse create bookmark response');
      }
      createdBookmark = res.data?.createBookmark;
    } catch (err) {
      console.log('Error creating bookmark', err);
      toast.error('There was a problem saving the bookmark. Please try again.');
      return;
    }

    // Create a new collection, if input has a value
    const newCollectionName = values.newCollection.trim();
    let createdCollectionId: number | null = null;
    if (newCollectionName.length > 0) {
      try {
        const createCollectionRes = await createCollection({
          variables: {
            collection: { name: newCollectionName },
            isCompany,
          },
        });
        if (createCollectionRes.data) {
          createdCollectionId =
            createCollectionRes.data?.createBookmarkCollection.id;
        }
      } catch (err) {
        console.log('Error creating collection', err);
      }
    }

    // Add bookmark to collections, if any were checked or just created
    const initialCollectionIds: string[] = [
      ...values.initialCollections,
      ...(createdCollectionId ? [String(createdCollectionId)] : []),
    ];
    await Promise.all(
      initialCollectionIds.map(async collectionId => {
        try {
          await addToCollection({
            variables: {
              bookmarkId: createdBookmark.id,
              collectionId: parseInt(collectionId),
            },
          });
        } catch (err) {
          console.log(`Error adding to collection ${collectionId}`, err);
        }
      }),
    );

    toast.success('Bookmark saved successfully.');
    if (onCreateSuccess) onCreateSuccess();
  }

  const loading =
    loadingCreate || loadingCreateCollection || loadingAddToCollection;

  return (
    <Formik
      initialValues={initialBookmarkCreate()}
      onSubmit={handleSubmit}
      validationSchema={bookmarkValidationSchema}
    >
      <Form className="mt-2 space-y-2">
        <div>
          <Heading level={5}>Add to collections</Heading>

          <div className="space-y-0">
            {collections.map(c => (
              <Field
                key={c.id}
                name="initialCollections"
                label={c.name}
                value={String(c.id)} // It's cast to string onChange so this cast is needed to show it as checked
                component={FormikField}
                type="checkbox"
                size="sm"
              />
            ))}
          </div>

          <Field
            name="newCollection"
            component={FormikField}
            type="text"
            placeholder="New collection"
            className="input-sm"
          />
        </div>

        <FormErrors graphQLError={errorCreateCollection} />

        <Button
          type="button"
          color="ghost"
          size="xs"
          onClick={() => setIsAdvancedVisible(prev => !prev)}
        >
          {isAdvancedVisible ? 'Hide' : 'Show'} more options
          <ExpandedIcon
            expanded={isAdvancedVisible}
            style={{ marginLeft: '5px' }}
          />
        </Button>

        {isAdvancedVisible && (
          <Field
            name="note"
            label="Note (optional)"
            component={FormikField}
            type="textarea"
            rows={2}
          />
        )}

        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button
            type="submit"
            color="primary"
            className="btn btn-primary"
            loading={loading}
          >
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
