import { Field, useFormikContext } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { useRegressionStatistics } from '~/components/statistics/MeasurementGraph/useMeasurementStatistics';
import type { CrossPlotDataPoint } from '~/utils/chart';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';

type Props = {
  data: CrossPlotDataPoint[];
};

export function RegressionFormFields({ data }: Props) {
  const { values } = useFormikContext<DataSearchFormValues>();
  const { regressionFormula, r2, ziRegressionFormula, ziR2 } =
    useRegressionStatistics(
      data,
      values.crossPlot.logScaleX,
      values.crossPlot.logScaleY,
    );

  return (
    <div className="grid lg:grid-cols-2 gap-6">
      <div>
        <Field
          name="crossPlot.showRegressionLine"
          label="Linear regression"
          component={FormikField}
          type="checkbox"
          size="sm"
        />
        {values.crossPlot.showRegressionLine && (
          <div className="pl-3">
            <div className="border-l-4 pl-4 border-l-slate-300 font-mono">
              <b>{regressionFormula}</b>
              <br />R<sup>2</sup> ≈ {r2}
            </div>
          </div>
        )}
      </div>

      <div>
        <Field
          name="crossPlot.showZIRegressionLine"
          label="Linear regression through origin"
          component={FormikField}
          type="checkbox"
          size="sm"
          disabled={values.crossPlot.logScaleX || values.crossPlot.logScaleY}
        />
        {values.crossPlot.showZIRegressionLine && (
          <div className="pl-3">
            <div className="border-l-4 pl-4 border-l-slate-300 font-mono">
              <b>{ziRegressionFormula}</b>
              <br />R<sup>2</sup> ≈ {ziR2}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
