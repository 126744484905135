import {
  faBookmark,
  faChartSimple,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { BookmarkPartsFragment } from '~/apollo/generated/schema';
import { BookmarkTargetType } from '~/apollo/generated/schema';

export function BookmarkIcon({
  bookmark,
  ...iconProps
}: {
  bookmark: Pick<BookmarkPartsFragment, 'targetType' | 'companyId'>;
} & Omit<FontAwesomeIconProps, 'icon'>) {
  const icon = (() => {
    switch (bookmark.targetType) {
      case BookmarkTargetType.Picture:
        return faImage;
      case BookmarkTargetType.SavedDataSearch:
        return faChartSimple;
      default:
        return faBookmark;
    }
  })();

  return <FontAwesomeIcon {...iconProps} icon={icon} />;
}
