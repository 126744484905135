import { gql } from '@apollo/client';
import { cn } from '~/utils/common';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { Outlet, useOutletContext, useSearchParams } from 'react-router-dom';
import * as fragments from '~/apollo/fragments';
import type {
  DashboardSearchOutcropsQuery,
  DashboardSearchOutcropsQueryVariables,
  DashboardSearchWikisQuery,
  DashboardSearchWikisQueryVariables,
} from '~/apollo/generated/schema';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { DashboardSidebar } from '~/components/dashboard/DashboardSidebar';
import { DashboardUnifiedSearch } from '~/components/unifiedSearch/DashboardSearch';
import { DashboardSearchResults } from '~/components/unifiedSearch/DashboardSearchResults';
import { useImperativeQuery } from '~/hooks/apollo';
import { queryStringToSearchCriteria } from '~/utils/modules/unifiedSearch';

const DASHBOARD_SEARCH_OUTCROPS = gql`
  query DashboardSearchOutcrops($textSearch: String!) {
    searchOutcrops(textSearch: $textSearch) {
      outcrops {
        id
        outcropCategory
      }
    }
  }
`;

const DASHBOARD_SEARCH_WIKIS = gql`
  query DashboardSearchWikis($textSearch: String!) {
    searchWikis(textSearch: $textSearch) {
      depositional {
        depositionalWiki {
          ...depositionalWikiParts
        }
        rank
      }
      lithostrat {
        formations {
          formation {
            ...lithostratFormationParts
          }
          rank
        }
        groups {
          group {
            ...lithostratGroupParts
          }
          rank
        }
        members {
          member {
            ...lithostratMemberParts
          }
          rank
        }
      }
      other {
        otherWiki {
          ...otherWikiParts
        }
        rank
      }
    }
  }

  ${fragments.depositionalWikiParts}
  ${fragments.lithostratFormationParts}
  ${fragments.lithostratGroupParts}
  ${fragments.lithostratMemberParts}
  ${fragments.otherWikiParts}
`;

export type MeasurementCounts = {
  measurements: number;
  studies: number;
};

export type OutcropCounts = {
  outcrops: number;
  production: number;
  seismic: number;
  modern: number;
};

export type WikiCounts = {
  geology: number;
  lithostrat: number;
  other: number;
};

type OutletContext = {
  wikiResults: {
    geology: DashboardSearchWikisQuery['searchWikis']['depositional'];
    lithostrat: DashboardSearchWikisQuery['searchWikis']['lithostrat'];
    other: DashboardSearchWikisQuery['searchWikis']['other'];
  };
};

export default function DashboardRoute() {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q');
  const criteria = queryStringToSearchCriteria(
    searchParams.get('criteria') ?? undefined,
  );

  const [outcropCount, setOutcropCount] = useState<OutcropCounts | null>(null);
  const [measurementCount, setMeasurementCount] =
    useState<MeasurementCounts | null>(null);
  const [wikiCount, setWikiCount] = useState<WikiCounts | null>(null);

  // Outcrop search query
  const [searchOutcropsQuery, { loading: loadingOutcrops }] =
    useImperativeQuery<
      DashboardSearchOutcropsQuery,
      DashboardSearchOutcropsQueryVariables
    >(DASHBOARD_SEARCH_OUTCROPS, {
      onCompleted: data => {
        const ocs = data.searchOutcrops.outcrops ?? [];
        const ocCounts: OutcropCounts = {
          outcrops: ocs.filter(oc => oc.outcropCategory === 'outcrop').length,
          production: ocs.filter(oc => oc.outcropCategory === 'production')
            .length,
          seismic: ocs.filter(oc => oc.outcropCategory === 'seismic').length,
          modern: ocs.filter(oc => oc.outcropCategory === 'modern').length,
        };

        setOutcropCount(ocCounts);
      },
    });

  // Measurement search query
  // TODO

  // Wiki search query
  const [searchWikis, { data: dataWikis, loading: loadingWikis }] =
    useImperativeQuery<
      DashboardSearchWikisQuery,
      DashboardSearchWikisQueryVariables
    >(DASHBOARD_SEARCH_WIKIS, {
      onCompleted: data => {
        const geologyCount = data.searchWikis.depositional.length;
        const lithostrats = data.searchWikis.lithostrat;
        const lithostratCount =
          lithostrats.formations.length +
          lithostrats.groups.length +
          lithostrats.members.length;
        const otherCount = data.searchWikis.other.length;

        setWikiCount({
          geology: geologyCount,
          lithostrat: lithostratCount,
          other: otherCount,
        });
      },
    });

  useEffect(() => {
    if (criteria.includeOutcrops && searchQuery) {
      searchOutcropsQuery({ variables: { textSearch: searchQuery } });
    } else {
      setOutcropCount(null);
    }

    if (criteria.includeMeasurements && searchQuery) {
      // searchMeasurements();
    } else {
      setMeasurementCount(null);
    }

    if (criteria.includeWikis && searchQuery) {
      searchWikis({ variables: { textSearch: searchQuery } });
    } else {
      setWikiCount(null);
    }
  }, [
    searchQuery,
    criteria.includeMeasurements,
    criteria.includeOutcrops,
    criteria.includeWikis,
    searchOutcropsQuery,
    searchWikis,
  ]);

  const loading = loadingOutcrops || loadingWikis; // || loadingMeasurements
  const hasResults = R.any(R.complement(R.isNil), [
    outcropCount,
    measurementCount,
    wikiCount,
  ]);

  const outletContext: OutletContext = {
    wikiResults: {
      geology: dataWikis?.searchWikis.depositional ?? [],
      lithostrat: {
        formations: dataWikis?.searchWikis.lithostrat.formations ?? [],
        groups: dataWikis?.searchWikis.lithostrat.groups ?? [],
        members: dataWikis?.searchWikis.lithostrat.members ?? [],
      },
      other: dataWikis?.searchWikis.other ?? [],
    },
  };

  const searching = false;

  // While searching (i.e. something set in query params), the sidebar gets hidden.
  // In mobile view, the sidebar is shown below the search content instead.

  return (
    <>
      <div className="grid lg:grid-cols-12 gap-6">
        <div
          className={
            searching
              ? 'hidden'
              : 'sm:hidden lg:block lg:col-span-4 xl:col-span-3 '
          }
        >
          <DashboardSidebar />
        </div>

        <div
          className={cn(
            'space-y-6',
            searching ? 'col-span-10 mx-auto' : 'lg:col-span-8 xl:col-span-9',
          )}
        >
          <DashboardUnifiedSearch
            query={searchQuery ?? undefined}
            criteria={searchParams.get('criteria')?.split(',')}
          />

          <SpinnerPlaceholder show={loading} />

          {!loading && hasResults && (
            <DashboardSearchResults
              measurementCount={measurementCount}
              outcropCount={outcropCount}
              wikiCount={wikiCount}
            />
          )}

          {/* {query.q && dataWikis && } */}
          <Outlet context={outletContext} />
        </div>

        {/* Repeat left column content only visible on XS layout */}
        <div className={searching ? 'hidden' : 'md:hidden mt-4'}>
          <DashboardSidebar />
        </div>
      </div>
    </>
  );
}

export function useDashboardOutletContext() {
  return useOutletContext<OutletContext>();
}
