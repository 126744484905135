import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';

type Props = {
  mode: 'create' | 'update';
};

export function SavedDataSearchFormFields({ mode }: Props) {
  return (
    <div className="space-y-2">
      <Field
        name="name"
        label="Name"
        placeholder="Saved search name"
        component={FormikField}
        type="text"
        required
      />

      {mode === 'create' && (
        <Field
          name="note"
          label="Note"
          component={FormikField}
          type="textarea"
          rows={2}
        />
      )}
    </div>
  );
}
