import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { measurementParts } from '~/apollo/fragments';
import type {
  AePartsFragment,
  MeasurementPartsFragment,
  UpdateMeasurementMutation,
  UpdateMeasurementMutationVariables,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import type { MeasurementFormValues } from '~/utils/modules/architecturalMeasurement';
import {
  formValuesToMeasurementInput,
  initialMeasurementFormValues,
  validationSchema,
} from '~/utils/modules/architecturalMeasurement';
import { MeasurementFormFields } from './MeasurementFormFields';

const UPDATE_MEASUREMENT = gql`
  mutation UpdateMeasurement(
    $id: Int!
    $architecturalMeasurement: ArchitecturalMeasurementInput!
  ) {
    updateArchitecturalMeasurement(
      id: $id
      architecturalMeasurement: $architecturalMeasurement
    ) {
      ...measurementParts
    }
  }

  ${measurementParts}
`;

type Props = {
  studyId: AePartsFragment['studyId'];
  measurement: MeasurementPartsFragment;
  children: (showModal: (event: React.MouseEvent) => void) => React.ReactNode;
};

export function UpdateMeasurementModal({
  studyId,
  measurement,
  children,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [updateMeasurement, { loading, error }] = useMutation<
    UpdateMeasurementMutation,
    UpdateMeasurementMutationVariables
  >(UPDATE_MEASUREMENT, {});

  async function handleSubmit(values: MeasurementFormValues) {
    const measurementInput = formValuesToMeasurementInput(
      values,
      measurement.outcropArchitecturalElementId,
    );

    try {
      await updateMeasurement({
        variables: {
          id: measurement.id,
          architecturalMeasurement: measurementInput,
        },
      });

      toast.success('Measurement updated successfully.');
      hideModal();
    } catch (err) {
      console.log('Error updating measurement', err);
      toast.error('There was a problem updating the measurement.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialMeasurementFormValues(measurement)}
          validationSchema={validationSchema}
        >
          <Form>
            <Modal.Body heading="Update Architectural Measurement">
              <div className="space-y-4">
                <MeasurementFormFields />
                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button type="button" color="ghost" onClick={hideModal}>
                Cancel
              </Button>

              <Button type="submit" color="primary" disabled={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
