import { gql, useQuery } from '@apollo/client';
import {
  faCloudDownload,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from 'react-daisyui';
import * as fragments from '~/apollo/fragments';
import type { AllRegionsGeorefsQuery } from '~/apollo/generated/schema';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { saveAs } from '~/utils/export';
import { createKmlDocument, createKmlFolder } from '~/utils/kml';

const ALL_REGIONS_GEOREFS = gql`
  query AllRegionsGeorefs {
    regionList {
      ...regionParts
      georeferences {
        ...georeferenceParts
      }
      outcrops {
        ...outcropParts
        georeferences {
          ...georeferenceParts
        }
      }
    }
  }

  ${fragments.regionParts}
  ${fragments.outcropParts}
  ${fragments.georeferenceParts}
`;

export default function ExportGeoreferencesAllRegionsRoute() {
  const [includeOutcrops, setIncludeOutcrops] = useState(false);
  const { data, loading, error } = useQuery<AllRegionsGeorefsQuery>(
    ALL_REGIONS_GEOREFS,
    {},
  );

  async function handleClick() {
    const regionList = data?.regionList ?? [];
    const regionFolders = regionList.map(region => {
      let regionOutcropsFolders: any = null;

      if (includeOutcrops) {
        const outcropFolders = region.outcrops.map(outcrop => {
          return createKmlFolder(
            outcrop.name,
            `${outcrop.name} Georeferences`,
            outcrop.georeferences,
          );
        });
        regionOutcropsFolders = [
          createKmlFolder('Outcrops', null, [], outcropFolders),
        ];
      }

      return createKmlFolder(
        region.name,
        `${region.name} Georeferences`,
        region.georeferences,
        regionOutcropsFolders,
      );
    });

    const document = await createKmlDocument(regionFolders);
    saveAs(document, 'SafariDB Regions.kml', 'application/vnd;charset=utf-8');
  }

  if (loading) {
    return (
      <SpinnerPlaceholder>
        Loading data for all regions
        {includeOutcrops ? ' and their outcrops' : ''}, this may take a long
        time...
      </SpinnerPlaceholder>
    );
  }

  return (
    <>
      <Panel>
        <Panel.Heading>
          <Panel.Title>Export All Region Georeferences</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <div className="form-control">
            <label className="label flex justify-start gap-2">
              <input
                type="checkbox"
                value="1"
                onChange={() => setIncludeOutcrops(!includeOutcrops)}
                checked={includeOutcrops}
                className="checkbox"
              />
              <span className="label-text">Include outcrops</span>
            </label>
          </div>

          {error && (
            <div className="alert alert-error">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-xl"
              />
              <span>Network error</span>
            </div>
          )}
        </Panel.Body>

        <Panel.Footer className="text-right">
          <Button
            type="button"
            color="primary"
            onClick={handleClick}
            disabled={loading}
            className="gap-1"
          >
            <FontAwesomeIcon icon={faCloudDownload} /> Save KML
          </Button>
        </Panel.Footer>
      </Panel>
    </>
  );
}
