import { Field, useFormikContext } from 'formik';
import type {
  OutcropEnumerationsQuery,
  OutcropPartsFragment,
} from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import { FormikTagsinputField } from '~/components/common/FormikField/FormikTagsinputField';
import { FormikTinyMceField } from '~/components/common/FormikField/FormikTinyMceField';
import { Panel } from '~/components/common/Panel';
import { OutcropEnumFields } from '~/components/upload/outcrop/OutcropEnumFields';
import { RegionSelect } from '~/components/upload/region/RegionSelect';
import type { OutcropFormValues } from '~/utils/modules/outcrop';
import { fieldLabel } from '~/utils/modules/outcrop';

type TinyMCEFieldTuple = [
  fieldName: keyof OutcropFormValues,
  required?: boolean,
];
const tinyMceFields: TinyMCEFieldTuple[] = [
  ['description', true],
  ['introduction'],
  ['environments'],
  ['stratigraphy'],
  ['diagenesisAndPetrophysicalProperties'],
  ['structuralGeology'],
  ['notablesAndAnalogues'],
  ['location', true],
  ['safari'],
];

type Props = {
  outcropEnums: OutcropEnumerationsQuery;
};

export function OutcropOverviewFormFields({ outcropEnums }: Props) {
  const { values } = useFormikContext<OutcropFormValues>();

  return (
    <>
      <div className="space-y-2">
        <Field name="name" label="Name" component={FormikField} required />

        <Field
          name="regionId"
          label="Region"
          component={FormikField}
          type={RegionSelect}
          required
        />

        <OutcropEnumFields outcropEnums={outcropEnums} />

        <Field
          name="keyWords"
          label="Keywords"
          component={FormikField}
          type={FormikTagsinputField}
          textField
        />

        <Field name="author" label="Author" component={FormikField} />

        {tinyMceFields.map(([name, required]) => (
          <Field
            key={name}
            name={name}
            label={fieldLabel(
              name as keyof OutcropPartsFragment,
              values.outcropCategory,
            )}
            component={FormikField}
            type={FormikTinyMceField}
            required={required}
          />
        ))}
      </div>

      <div className="space-y-4 mt-4">
        <Panel variant={values.approved ? 'success' : 'default'}>
          <Panel.Heading>
            <Panel.Title>Approval</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <p>
              Select whether this outcrop is ready to be reviewed and approved.
            </p>
            <Field
              name="readyForApproval"
              label="Ready for approval"
              component={FormikField}
              type="checkbox"
            />

            <p>
              Select to publish this outcrop. Approved outcrops will appear in
              the browse and data searches and are able to be viewed by Safari
              users.
            </p>
            <Field
              name="approved"
              label="Approved"
              component={FormikField}
              type="checkbox"
            />
          </Panel.Body>
        </Panel>

        <Panel variant={values.qcCompleted ? 'success' : 'default'}>
          <Panel.Heading>
            <Panel.Title>Quality Control Completed</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <p>Select to indicate that this outcrop has been QC'd.</p>

            <Field
              name="qcCompleted"
              label="QC Completed"
              component={FormikField}
              type="checkbox"
            />
          </Panel.Body>
        </Panel>
      </div>
    </>
  );
}
