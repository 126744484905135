import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import { LithostratLinkTargetType } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import { FormationSelector } from '~/components/upload/lithostrat/formation/FormationSelector';
import { GroupSelector } from '~/components/upload/lithostrat/group/GroupSelector';
import { MemberSelector } from '~/components/upload/lithostrat/member/MemberSelector';
import { LithostratTypeSelector } from '~/components/upload/lithostratType/LithostratTypeSelector';
import type { LithostratOutcropLinkFormValues } from '~/components/upload/outcrop/lithostratLink/CreateLinkModal';
import { ucwords } from '~/utils/text';

type TargetIdSelectProps = {
  name: string;
  targetType: LithostratLinkTargetType;
  lithostratTypeId: string;
};

function TargetIdSelect({
  name,
  targetType,
  lithostratTypeId,
}: TargetIdSelectProps) {
  let fieldType = null;
  if (targetType === LithostratLinkTargetType.Group) fieldType = GroupSelector;
  if (targetType === LithostratLinkTargetType.Formation)
    fieldType = FormationSelector;
  if (targetType === LithostratLinkTargetType.Member)
    fieldType = MemberSelector;
  if (!fieldType) return null;

  return (
    <>
      <Field
        name={name}
        label={ucwords(targetType)}
        component={FormikField}
        type={fieldType}
        disabled={!fieldType}
        lithostratTypeId={parseInt(lithostratTypeId)}
        onlyPublished
        required
      />
      <small className="text-muted">
        <FontAwesomeIcon icon={faInfoCircle} />{' '}
        <i>The {targetType}'s wiki page must be published to be linked.</i>
      </small>
    </>
  );
}

export function LithostratLinkFormFields() {
  const { values } = useFormikContext<LithostratOutcropLinkFormValues>();

  return (
    <>
      <Field
        name="targetType"
        label="Type"
        component={FormikField}
        type="select"
        required
        options={Object.values(LithostratLinkTargetType).map(opt => ({
          value: opt,
          label: opt,
        }))}
      />

      {values.targetType && (
        <>
          <Field
            name="lithostratTypeId"
            label="Lithostrat Type &ndash; Use to refine options if needed"
            component={FormikField}
            type={LithostratTypeSelector}
          />
          <TargetIdSelect
            name="targetId"
            targetType={values.targetType}
            lithostratTypeId={values.lithostratTypeId}
          />
        </>
      )}
    </>
  );
}
