import type { ReportStudyPartsFragment } from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { Prose } from '~/components/common/Prose';
import type { ReportDisplayReportItem } from '~/components/report/ReportDisplay/ReportItem';
import { rejectNil } from '~/utils/common';

export function ReportItemStudy({
  reportItem,
  study,
}: {
  reportItem: ReportDisplayReportItem;
  study: ReportStudyPartsFragment;
}) {
  const dataHistoryText = [
    study.dataHistory?.collectedBy,
    study.dataHistory?.date,
  ]
    .filter(rejectNil)
    .join(', ');

  return (
    <div className="space-y-4">
      {<div>{dataHistoryText}</div>}

      {reportItem.studyShowAbstract && study.abstract?.length && (
        <div>
          <Heading level={4}>Abstract</Heading>
          <Prose dangerouslySetInnerHTML={{ __html: study.abstract }} />
        </div>
      )}

      {study.keyReferences && (
        <div>
          <Heading level={4}>Citation</Heading>
          <Prose dangerouslySetInnerHTML={{ __html: study.keyReferences }} />
        </div>
      )}
    </div>
  );
}
