import type { PureQueryOptions } from '@apollo/client';
import { useMutation , gql } from '@apollo/client';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';

import * as fragments from '~/apollo/fragments';
import type {
  BookmarkPartsFragment,
  CopyBookmarkMutation,
  CopyBookmarkMutationVariables,
} from '~/apollo/generated/schema';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';

const COPY_BOOKMARK = gql`
  mutation CopyBookmark($bookmarkId: Int!) {
    copyBookmark(bookmarkId: $bookmarkId) {
      ...bookmarkParts
    }
  }

  ${fragments.bookmarkParts}
`;

type Props = {
  children: (
    showModal: () => void,
    icon: IconProp,
    label: React.ReactNode,
  ) => void;
  bookmark: BookmarkPartsFragment;
  refetchQueries: PureQueryOptions[];
};

export function CopyBookmarkModal({
  children,
  bookmark,
  refetchQueries,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [copyBookmark, { loading }] = useMutation<
    CopyBookmarkMutation,
    CopyBookmarkMutationVariables
  >(COPY_BOOKMARK, {
    variables: { bookmarkId: bookmark.id },

    refetchQueries,
  });

  const handleCopy = async () => {
    try {
      const res = await copyBookmark();
      const newBookmark = res.data?.copyBookmark;
      if (newBookmark) {
        console.log(`Bookmark successfully copied with id ${newBookmark.id}`);
        const toastMsg = newBookmark.companyId
          ? 'Bookmark successfully copied to Company Bookmarks.'
          : 'Bookmark successfully copied to My Bookmarks.';

        toast.success(toastMsg);
      } else {
        throw new Error('Unexpected error parsing copyBookmark result');
      }
    } catch (err) {
      console.log('Error copying bookmark', err);

      if (err instanceof Error) {
        if (err.message === 'may_not_copy_bookmark') {
          toast.error(
            'Sorry, you do not have permission to copy this bookmark.',
          );
        } else if (err.message === 'target_deleted') {
          toast.error(
            'Sorry, the item bookmarked appears to have been removed.',
          );
        } else if (err.message === 'parent_deleted') {
          toast.error(
            'Sorry, the page containing this bookmark appears to have been removed.',
          );
        }
      } else {
        toast.error(
          'There was a problem copying the bookmark, please refresh the page and try again.',
        );
      }
    } finally {
      hideModal();
    }
  };

  const copyLabel = bookmark.companyId
    ? 'Copy to My Bookmarks'
    : 'Copy to Company Bookmarks';

  return (
    <>
      {children(showModal, faCopy, copyLabel)}

      <Modal open={show} onHide={hideModal}>
        <Modal.Body heading={copyLabel}>
          {bookmark.companyId && (
            <>A copy of this bookmark will be added to My Bookmarks.</>
          )}
          {!bookmark.companyId && (
            <>
              This bookmark will be copied into your company's bookmarks and be
              available to all users within your company.
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            onClick={hideModal}
            disabled={loading}
            color="ghost"
          >
            Cancel
          </Button>

          <Button
            type="button"
            color="primary"
            onClick={handleCopy}
            loading={loading}
            className="gap-1"
          >
            <FontAwesomeIcon icon={faCopy} /> {copyLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
