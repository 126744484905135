import { LithostratWikiParentType } from '~/apollo/generated/schema';
import { CreateLithostratWikiPage } from '~/components/upload/lithostrat/wiki/CreateLithostratWikiPage';
import { UpdateLithostratWikiForm } from '~/components/upload/lithostrat/wiki/UpdateLithostratWiki';
import { useGroupUpdateOutletContext } from '~/routes/upload/lithostrat/group/$groupId';

export default function GroupUpdateWikiRoute() {
  const { group, refetchQueries } = useGroupUpdateOutletContext();

  if (!group.wikiPage) {
    return (
      <CreateLithostratWikiPage
        parentType={LithostratWikiParentType.Group}
        parentId={group.id}
        parentName={group.name}
        refetchQueries={refetchQueries}
      />
    );
  }

  return (
    <UpdateLithostratWikiForm
      wikiPage={group.wikiPage}
      refetchQueries={refetchQueries}
    />
  );
}
