import type { PureQueryOptions } from '@apollo/client';
import { useMutation , gql } from '@apollo/client';
import {
  faEarthAmericas,
  faLock,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from 'react-daisyui';
import { toast } from 'react-toastify';

import type {
  DeleteCmsFileMutation,
  DeleteCmsFileMutationVariables,
  FileManagerPageQuery,
} from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';
import { fileSizeText } from '~/utils/text';
import FilePreview from './FilePreview';

function visibilityIcon(isPublic: boolean) {
  if (isPublic) {
    return (
      <Tooltip message="Public - visible to anyone">
        <FontAwesomeIcon icon={faEarthAmericas} />
      </Tooltip>
    );
  }

  return (
    <Tooltip message="Private - must be logged in">
      <FontAwesomeIcon icon={faLock} className="text-warning" />
    </Tooltip>
  );
}

const DELETE_CMS_FILE = gql`
  mutation DeleteCmsFile($fileId: Int!) {
    deleteCmsFile(fileId: $fileId)
  }
`;

type Props = {
  file: FileManagerPageQuery['cmsFileList'][number];
  refetchQueries: PureQueryOptions[];
};

function CmsFile({ file, refetchQueries }: Props) {
  const [deleteCmsFile, { loading }] = useMutation<
    DeleteCmsFileMutation,
    DeleteCmsFileMutationVariables
  >(DELETE_CMS_FILE, {
    variables: { fileId: file.id },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteCmsFile();
      toast.success('File deleted successfully.');
    } catch (err) {
      console.log('Error deleting CMS file', err);
      toast.error(
        'There was a problem deleting the file. Please reload the page and try again.',
      );
    }
  }

  return (
    <tr>
      <td className="text-center">
        <FilePreview file={file} />
      </td>
      <td className="whitespace-normal break-all">{file.name}</td>
      <td className="whitespace-normal break-all">{file.contentType}</td>
      <td>{file.size ? fileSizeText(file.size) : ''}</td>
      <td className="text-center w-1/12">{visibilityIcon(file.public)}</td>
      <td className="text-right">
        <Confirm
          onConfirm={handleDelete}
          title="Delete File"
          text={
            <>
              <p>This file will be permanently deleted.</p>
              <p>
                <b>
                  Note that deleting the file WILL NOT automatically remove any
                  references to it where embedded in page content.
                </b>
              </p>
              <p>
                Please ensure it is not currently in use, or the embedded
                links/images will become broken.
              </p>
            </>
          }
          submitButtonColor="error"
          submitText="Delete File"
        >
          {confirmDelete => (
            <Tooltip message="Delete file...">
              <Button
                type="button"
                color="ghost"
                size="xs"
                onClick={confirmDelete}
                disabled={loading}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Tooltip>
          )}
        </Confirm>
      </td>
    </tr>
  );
}

export default CmsFile;
