import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import type { PagePartsFragment } from '~/apollo/generated/schema';
import { Role } from '~/apollo/generated/schema';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { Heading } from '~/components/common/Heading';
import { Prose } from '~/components/common/Prose';
import { adminPageUpdateRoute } from '~/paths';

type Props = {
  page?: PagePartsFragment;
  /** Alias for 'page' prop, used for backward-compat */
  pageData?: PagePartsFragment;
  showTitle?: boolean;
};

/** Render a loaded Page type with HTML content */
export function Page({ page, pageData, showTitle = true }: Props) {
  const pageInfo = page || pageData;
  if (!pageInfo) return null;

  return (
    <div id={pageInfo.pageName}>
      <div
        className={
          showTitle ? 'flex justify-between items-end' : 'float-right ml-6 mb-6'
        }
      >
        {showTitle && (
          <Heading level={1} className="flex-grow">
            {pageInfo.title}
          </Heading>
        )}

        <RoleSecured roles={[Role.RoleAdmin]}>
          <div className="text-right">
            <Link
              to={adminPageUpdateRoute(pageInfo.id)}
              className=" btn btn-sm btn-ghost gap-1"
            >
              <FontAwesomeIcon icon={faPencil} /> Edit
            </Link>
          </div>
        </RoleSecured>
      </div>

      <Prose dangerouslySetInnerHTML={{ __html: pageInfo.content }} />
    </div>
  );
}
