import React from 'react';

import { useAuth } from '~/contexts/auth';
import { Role } from '~/apollo/generated/schema';

type Props = {
  children: React.ReactNode;
  roles?: Role[];
  guest?: boolean;
};

export function RoleSecured({
  children,
  roles = [Role.RoleUser],
  guest = false,
}: Props) {
  const { isAuthenticated, hasAnyRole } = useAuth();

  // Only render when anonymous if guest prop is set
  if (guest) {
    return isAuthenticated ? null : <>{children}</>;
  }

  // Render nothing if the user is not authenticated or does not have one of the provided roles
  // Maybe add a fallback render prop for content to render instead of nothing
  if (!isAuthenticated || !hasAnyRole(roles)) {
    return null;
  }

  return <>{children}</>;
}
