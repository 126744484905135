import React from 'react';
import { Prose } from '~/components/common/Prose';

type Props = {
  content: string | null | undefined;
};
export function ReportItemHtmlContent({ content }: Props) {
  if (!content) return null;
  return <Prose dangerouslySetInnerHTML={{ __html: content }} />;
}
