import type { PureQueryOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import type { CompanyFormValues } from '~/utils/modules/company';
import {
  companyValidationSchema,
  initialCompanyValues,
} from '~/utils/modules/company';
import CompanyFormFields from './CompanyFormFields';

export const CREATE_COMPANY = gql`
  mutation CreateCompany($company: CompanyInput!) {
    createCompany(company: $company) {
      id
    }
  }
`;

type Props = {
  children: (showModal: () => void) => React.ReactNode;
  refetchQueries: PureQueryOptions[];
};

const CreateCompanyModal: React.FC<Props> = ({ children, refetchQueries }) => {
  const { show, showModal, hideModal } = useModalState();

  const [createCompany, { loading, error }] = useMutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >(CREATE_COMPANY, { refetchQueries });

  async function handleSubmit(values: CompanyFormValues) {
    try {
      await createCompany({ variables: { company: values } });
      hideModal();
      toast.success('Company created successfully.');
    } catch (err) {
      console.log(err);
      toast.error('There was a problem creating the company.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialCompanyValues()}
          validationSchema={companyValidationSchema}
        >
          <Form>
            <Modal.Body heading="Create New Company">
              <CompanyFormFields />
              <FormErrors graphQLError={error} className="mt-2" />
            </Modal.Body>
            <Modal.Footer className="space-x-2">
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default CreateCompanyModal;
