import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';
import * as R from 'ramda';
import { Alert } from 'react-daisyui';

import * as fragments from '~/apollo/fragments';
import type {
  MemberUpdateExamplesRouteQuery,
  MemberUpdateExamplesRouteQueryVariables,
} from '~/apollo/generated/schema';
import { LithostratLinkTargetType } from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { ExampleManager } from '~/components/upload/lithostrat/examples/ExampleManager';
import { useMemberUpdateOutletContext } from '~/routes/upload/lithostrat/member/$memberId';

const EXAMPLES_TAB = gql`
  query MemberUpdateExamplesRoute($id: Int!) {
    lithostratMemberList(id: $id) {
      ...lithostratMemberParts
      wikiPage {
        ...lithostratWikiPageParts
      }
      outcropLinkAnalogues {
        ...outcropParts
      }
      studyLinkAnalogues {
        ...outcropParts
        studies {
          ...studyParts
          lithostratStudyLinks {
            ...lithostratStudyLinkParts
          }
        }
      }
    }
  }

  ${fragments.lithostratMemberParts}
  ${fragments.lithostratWikiPageParts}
  ${fragments.outcropParts}
  ${fragments.studyParts}
  ${fragments.lithostratStudyLinkParts}
`;

export default function MemberUpdateExamplesRoute() {
  const ctx = useMemberUpdateOutletContext();
  const memberId = ctx.member.id;

  const { data, loading } = useQuery<
    MemberUpdateExamplesRouteQuery,
    MemberUpdateExamplesRouteQueryVariables
  >(EXAMPLES_TAB, {
    variables: { id: memberId },
  });

  const refetchQueries: [
    PureQueryOptions<MemberUpdateExamplesRouteQueryVariables>,
  ] = [{ query: EXAMPLES_TAB, variables: { id: memberId } }];

  const member = data?.lithostratMemberList.find(mb => mb.id === memberId);

  const outcropLinks = R.sortBy(
    R.prop('name'),
    member?.outcropLinkAnalogues ?? [],
  );
  const studyLinks = R.sortBy(R.prop('name'), member?.studyLinkAnalogues ?? []);

  if (loading) return <SpinnerPlaceholder />;
  if (!member) return <NotFound />;

  if (!member.wikiPage?.published) {
    return (
      <Alert status="info">
        This member's wiki page needs to be published before examples may be
        added.
      </Alert>
    );
  }

  return (
    <ExampleManager
      targetType={LithostratLinkTargetType.Member}
      targetId={memberId}
      refetchQueries={refetchQueries}
      outcropLinks={outcropLinks}
      studyLinks={studyLinks}
    />
  );
}
