import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import type {
  CreateProjectMutation,
  CreateProjectMutationVariables,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { ProjectFormFields } from '~/components/upload/project/ProjectFormFields';
import { uploadProjectUpdateRoute } from '~/paths';
import type { ProjectFormValues } from '~/utils/modules/project';
import {
  defaultProject,
  projectValidationSchema,
} from '~/utils/modules/project';

export const CREATE_PROJECT = gql`
  mutation createProject($project: ProjectInput!) {
    createProject(project: $project) {
      id
    }
  }
`;

export default function UploadProjectCreatePage() {
  useBreadcrumb('routes/upload/model/project/create', 'Create Project');

  const navigate = useNavigate();

  const [createProject, { loading, error }] = useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CREATE_PROJECT, {});

  async function handleSubmit(values: ProjectFormValues) {
    try {
      const res = await createProject({
        variables: {
          project: values,
        },
      });
      invariant(res.data);
      const projectId = res.data.createProject.id;
      navigate(uploadProjectUpdateRoute(projectId));
      toast.success('Project created successfully.');
    } catch (err) {
      console.log('Error saving project', err);
      toast.error('There was a problem saving the project.');
    }
  }

  return (
    <>
      <PageHeading>Create Project</PageHeading>

      <Formik
        onSubmit={handleSubmit}
        initialValues={defaultProject()}
        validationSchema={projectValidationSchema}
      >
        <Form>
          <Panel>
            <Panel.Heading>
              <Panel.Title>Create Project</Panel.Title>
            </Panel.Heading>

            <Panel.Body className="space-y-4">
              <ProjectFormFields />
              <FormErrors graphQLError={error} />
            </Panel.Body>

            <Panel.Footer className="text-right">
              <Button type="submit" color="primary" loading={loading}>
                Save
              </Button>
            </Panel.Footer>
          </Panel>
        </Form>
      </Formik>
    </>
  );
}
