import { Cartesian3, Color, Matrix4, PolygonHierarchy } from 'cesium';

export type PolygonEntityStruct = {
  heirarchy: PolygonHierarchy;
  material: Color;
  perPositionHeight: boolean;
};

export type ParsedPolygoneElement = {
  classId: string | null;
  ltw: number[][];
  points: number[][];
  color: string | null;
};

interface Legend {
  [key: string]: string;
}

export function parseLimeXml(lime_xml_polygons: string): XMLDocument {
  return new DOMParser().parseFromString(lime_xml_polygons, 'text/xml');
}

export function getPolygonElements(
  parsedLimeXml: XMLDocument,
): HTMLCollectionOf<Element> {
  const polygonXml: HTMLCollectionOf<Element> =
    parsedLimeXml.getElementsByTagName('polygon');
  return polygonXml;
}

export function parseLegendXml(parsedLimeXml: XMLDocument): Legend {
  const legends_tags: HTMLCollectionOf<Element> =
    parsedLimeXml.getElementsByTagName('legendClass');
  const legends: Record<string, string> = {};
  for (let l of legends_tags) {
    if (l.id && l.textContent) {
      legends[l.id] = l.textContent;
    }
  }
  return legends;
}

export function parseLimePolygonElement(
  lime_polygon_element: Element,
): ParsedPolygoneElement {
  const classId =
    lime_polygon_element.getElementsByTagName('classId')[0].textContent;
  const ltw_rows = Array.from(
    lime_polygon_element
      .getElementsByTagName('ltwMtx')[0]
      .getElementsByTagName('row'),
  ).map(row => (row.textContent ?? '').split(' ').map(val => parseFloat(val)));
  const points = Array.from(
    lime_polygon_element
      .getElementsByTagName('points')[0]
      .getElementsByTagName('point'),
  ).map(point =>
    (point.textContent ?? '').split(' ').map(val => parseFloat(val)),
  );
  return {
    color: null,
    classId: classId,
    ltw: ltw_rows,
    points: points,
  };
}

export function createPolygonEntityStruct(
  ltwMatrix: Matrix4,
  matrixTransformCenter: number[],
  tilesetModelMatrix: Matrix4,
  color: string,
  limePolygonPoints: number[][],
): PolygonEntityStruct {
  let transformedPoints = limePolygonPoints.map(point => {
    const cartesianPoint = new Cartesian3(
      point[0] + (ltwMatrix[12] - matrixTransformCenter[0]),
      point[1] + (ltwMatrix[13] - matrixTransformCenter[1]),
      point[2] + (ltwMatrix[14] - matrixTransformCenter[2]),
    );
    return Matrix4.multiplyByPoint(
      tilesetModelMatrix,
      cartesianPoint,
      new Cartesian3(),
    );
  });
  const positions = new PolygonHierarchy(transformedPoints);
  // const polygon = viewer.entities.add( return value of this function)
  return {
    heirarchy: positions,
    material: Color.fromCssColorString('#' + color).withAlpha(0.9),
    perPositionHeight: true,
  };
}

// export function createPolygonData(ltwMatrix: Matrix4, matrixTransformCenter: number[], tilesetModelMatrix: Cesium.Matrix4, color: string, limePolygonPoints: number[][]): PolygoEntityStructType {
//   let transformedPoints = limePolygonPoints.map((point) => {
//       const cartesianPoint = new Cesium.Cartesian3(point[0] + (ltwMatrix[12] - matrixTransformCenter[0]), point[1] + (ltwMatrix[13] - matrixTransformCenter[1]), point[2] + (ltwMatrix[14] - matrixTransformCenter[2]))
//       return Cesium.Matrix4.multiplyByPoint(tilesetModelMatrix, cartesianPoint, new Cesium.Cartesian3)
//     })
//   const positions = new Cesium.PolygonHierarchy(transformedPoints);
//   // const polygon = viewer.entities.add( return value of this function)
//   return transformedPoints
//   }
