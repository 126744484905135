import { cn } from '~/utils/common';
import type { HTMLProps } from 'react';
import React from 'react';

type Props = HTMLProps<HTMLDivElement> & {
  className?: string | null;
};

export const DropzoneContainer = React.forwardRef<HTMLInputElement, Props>(
  ({ className, ...props }: Props, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          'flex flex-1 flex-col items-center p-10 border-2 border-dashed border-slate-300 bg-slate-100 text-slate-400 outline-none cursor-pointer',
          className,
        )}
      />
    );
  },
);
DropzoneContainer.displayName = 'DropzoneContainer';
