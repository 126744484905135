import { gql, useQuery } from '@apollo/client';
import { Field, useFormikContext } from 'formik';
import * as R from 'ramda';
import { useEffect } from 'react';
import * as fragments from '~/apollo/fragments';
import type { DiagramTargetFieldOptionsQuery } from '~/apollo/generated/schema';
import { DiagramAreaLinkType } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import type { DiagramAreaFormValues } from '~/utils/modules/diagram';
import { snakeCapsToHuman } from '~/utils/text';

const LINK_TARGET_FIELD_OPTIONS = gql`
  query DiagramTargetFieldOptions {
    depositionalList {
      ...depositionalWikiParts
    }
    lithostratFormationList {
      ...lithostratFormationParts
      wikiPage {
        id
      }
    }
    lithostratGroupList {
      ...lithostratGroupParts
      wikiPage {
        id
      }
    }
    lithostratMemberList {
      ...lithostratMemberParts
      wikiPage {
        id
      }
    }
  }

  ${fragments.depositionalWikiParts}
  ${fragments.lithostratFormationParts}
  ${fragments.lithostratGroupParts}
  ${fragments.lithostratMemberParts}
`;

export function DiagramLinkTargetField() {
  const { values, setFieldValue } = useFormikContext<DiagramAreaFormValues>();
  const targetType = values.linkTargetType;

  const { data, loading } = useQuery<DiagramTargetFieldOptionsQuery>(
    LINK_TARGET_FIELD_OPTIONS,
    {},
  );

  useEffect(() => {
    setFieldValue('linkTargetId', '');
  }, [targetType, setFieldValue]);

  type Option = {
    value: any;
    label: string;
    disabled?: boolean;
  };

  const mapLithoOptions = (
    item: { id: number; name: string },
    hasWikiPage: boolean,
  ): Option => {
    const disabled = !hasWikiPage;
    return {
      value: item.id,
      label: `${item.name}${disabled ? ' (no wiki page)' : ''}`,
      disabled,
    };
  };

  const options: Record<DiagramAreaLinkType, Option[]> = {
    [DiagramAreaLinkType.GeologyWiki]:
      data?.depositionalList.map(gw => ({
        value: gw.id,
        label: gw.title,
      })) ?? [],
    [DiagramAreaLinkType.Formation]: (data?.lithostratFormationList ?? []).map(
      fm => mapLithoOptions(fm, Boolean(fm.wikiPage)),
    ),
    [DiagramAreaLinkType.Group]: (data?.lithostratGroupList ?? []).map(group =>
      mapLithoOptions(group, Boolean(group.wikiPage)),
    ),
    [DiagramAreaLinkType.Member]: (data?.lithostratMemberList ?? []).map(mb =>
      mapLithoOptions(mb, Boolean(mb.wikiPage)),
    ),
  };

  const curOptions = targetType
    ? R.sortBy(R.prop('label'), options[targetType])
    : [];

  return (
    <Field
      name="linkTargetId"
      label={targetType ? snakeCapsToHuman(targetType) : 'Link Target'}
      component={FormikField}
      type="select"
      options={curOptions}
      disabled={loading || !curOptions.length}
      required
    />
  );
}
