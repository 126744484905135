import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { supplementalDocumentParts } from '~/apollo/fragments';
import type * as schema from '~/apollo/generated/schema';
import type { SuppDocFormValues } from '~/apollo/operations/supportingObject';
import {
  initialSuppDoc,
  toSuppDocUpdateInput,
} from '~/apollo/operations/supportingObject';
import { FormErrors } from '~/components/common/FormErrors';
import { SuppDocFormFields } from '~/components/upload/supportingObject/supplementalDocs/SuppDocFormFields';

const UPDATE_SUPP_DOC = gql`
  mutation UpdateSuppDoc(
    $id: Int!
    $supplementalDocument: UpdateSupplementalDocumentInput!
  ) {
    updateSupplementalDocument(
      id: $id
      supplementalDocument: $supplementalDocument
    ) {
      ...supplementalDocumentParts
    }
  }

  ${supplementalDocumentParts}
`;

export function UpdateSuppDocForm({
  supplementalDocument,
  onUpdateSuccess,
}: {
  supplementalDocument: schema.SupplementalDocumentPartsFragment;
  onUpdateSuccess: () => void;
}) {
  const [updateSuppDoc, { loading, error }] = useMutation<
    schema.UpdateSuppDocMutation,
    schema.UpdateSuppDocMutationVariables
  >(UPDATE_SUPP_DOC);

  async function handleSubmit(formValues: SuppDocFormValues) {
    try {
      const input = toSuppDocUpdateInput(formValues);
      await updateSuppDoc({
        variables: {
          id: supplementalDocument.id,
          supplementalDocument: input,
        },
      });
      toast.success('Supplemental Document updated successfully.');
      onUpdateSuccess();
    } catch (err) {
      console.log('Error saving supp doc', err);
      toast.error(
        'There was a problem saving the changes, check the console for more information.',
      );
    }
  }

  return (
    <Formik
      initialValues={initialSuppDoc(supplementalDocument)}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className="space-y-4">
          <SuppDocFormFields disabled={loading} />

          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button type="submit" color="primary" size="sm" loading={loading}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
