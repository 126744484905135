import * as R from 'ramda';
import type { GeoreferenceImportInput } from '~/utils/geojson';
import { ConfirmGeoreferenceForm } from './ConfirmGeoreferenceForm';
import { ImportPreviewMap } from './ImportPreviewMap';

type Props = {
  georeferences: GeoreferenceImportInput[];
  setGeoreferences: (g: GeoreferenceImportInput[]) => void;
};

export function ConfirmData({ georeferences, setGeoreferences }: Props) {
  function handleSubmit(georeference: GeoreferenceImportInput) {
    const index = georeferences.findIndex(
      g => g.tempId === georeference.tempId,
    );
    const nextGeorefs = R.update(index, georeference, georeferences);
    setGeoreferences(nextGeorefs);
  }

  function handleRemove(tempId: string) {
    const index = georeferences.findIndex(g => g.tempId === tempId);
    const nextGeorefs = R.remove(index, 1, georeferences);
    setGeoreferences(nextGeorefs);
  }

  return (
    <div className="grid grid-cols-2 gap-6">
      <div>
        {georeferences.map(g => (
          <ConfirmGeoreferenceForm
            key={g.tempId}
            georeference={g}
            onSubmit={handleSubmit}
            onRemove={handleRemove}
          />
        ))}
      </div>
      <div>
        <ImportPreviewMap georeferences={georeferences} />
      </div>
    </div>
  );
}
