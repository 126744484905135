import { cn } from '~/utils/common';
import type { FieldProps } from 'formik';
import { ErrorMessage } from 'formik';
import React, { createRef, useEffect } from 'react';
import type { WikiPopoverProps } from '~/components/wiki/WikiPopover';
import { FormLabel } from './FormLabel';

export type Props = FieldProps & {
  className?: string;
  isInvalid?: boolean;
  label?: React.ReactNode;
  renderInput?: (input: JSX.Element) => JSX.Element;
  helpText?: string;
  required?: boolean;
  wikiPopoverId?: number;
  wikiPopoverProps?: WikiPopoverProps;
};

export const FormikFileField: React.FC<Props> = ({
  form,
  field,
  className,
  isInvalid,
  label,
  renderInput,
  helpText,
  required = false,
  wikiPopoverId,
  wikiPopoverProps,
  ...props
}: Props) => {
  const inputRef = createRef<HTMLInputElement>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    form.setFieldValue(field.name, event.currentTarget.files?.[0] ?? null);

  // File inputs are uncontrolled, so the file input gets stuck when the form is
  // reset. Watch the value here and manually push the change to the field.
  useEffect(() => {
    if (inputRef.current && !field.value) {
      inputRef.current.value = '';
    }
  }, [field.value, inputRef]);

  const input = (
    <input
      {...props}
      ref={inputRef}
      type="file"
      id={field.name}
      onChange={handleChange}
      className={cn(
        className,
        'file-input file-input-ghost file-input-bordered w-full',
      )}
    />
  );

  return (
    <div
      className={cn('form-group', {
        'has-error': isInvalid,
        'text-danger': isInvalid,
      })}
    >
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        required={required}
        wikiPopoverId={wikiPopoverId}
        wikiPopoverProps={wikiPopoverProps}
      />
      {renderInput ? renderInput(input) : input}
      <ErrorMessage name={field.name} />
    </div>
  );
};

export default FormikFileField;
