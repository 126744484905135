import { useQuery , gql } from '@apollo/client';
import type { FieldProps } from 'formik';
import * as R from 'ramda';
import React from 'react';

import * as fragments from '~/apollo/fragments';
import type { GroupSelectOptionsQuery } from '~/apollo/generated/schema';
import { FormikReactSelectField } from '~/components/common/FormikField/FormikReactSelectField';

const GROUP_SELECT_OPTIONS = gql`
  query GroupSelectOptions {
    lithostratGroupList {
      ...lithostratGroupParts
      wikiPage {
        ...lithostratWikiPageParts
      }
    }
  }

  ${fragments.lithostratGroupParts}
  ${fragments.lithostratWikiPageParts}
`;

type Props = FieldProps & {
  lithostratTypeId?: number;
  disabled?: boolean;
  onlyPublished?: boolean;
};

export function GroupSelector({
  lithostratTypeId,
  onlyPublished = false,
  ...props
}: Props) {
  const { data, loading } = useQuery<GroupSelectOptionsQuery>(
    GROUP_SELECT_OPTIONS,
    {},
  );

  const options = React.useMemo(() => {
    const groupList = data?.lithostratGroupList ?? [];

    // If a type ID is set, only show groups matching that type
    const filtered = groupList.filter(g => {
      if (!lithostratTypeId) return true;
      return g.lithostratTypeId && g.lithostratTypeId === lithostratTypeId;
    });

    const sorted = R.sortBy(g => g.name, filtered);

    return sorted.map(g => ({
      value: g.id,
      label: g.name,
      disabled: onlyPublished && !g.wikiPage?.published,
    }));
  }, [data?.lithostratGroupList, lithostratTypeId, onlyPublished]);

  return (
    <FormikReactSelectField {...props} options={options} loading={loading} />
  );
}
