import {
  faExternalLink,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FieldProps } from 'formik';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import { Button } from 'react-daisyui';
import { Link } from 'react-router-dom';
import { InputGroup } from '~/components/common/InputGroup';
import { useQueryString } from '~/hooks/routing';
import { dataSearchRoute, searchRoute } from '~/paths';
import { cn } from '~/utils/common';
import type {
  UnifiedSearchCriteria,
  UnifiedSearchFormValues,
} from '~/utils/modules/unifiedSearch';

export function SearchField({ field, form }: FieldProps) {
  const searchInputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  return (
    <div
      className={cn('form-control', {
        'has-error': form.errors.query,
      })}
    >
      <InputGroup>
        <InputGroup.Addon>
          <span className="px-2">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="text-2xl" />
          </span>
        </InputGroup.Addon>
        <input
          {...field}
          type="text"
          className="input input-lg join-item w-full input-bordered"
          placeholder="Search the SAFARI database"
          tabIndex={1}
          ref={searchInputRef}
        />
      </InputGroup>

      <ErrorMessage name="query">
        {err => <div className="text-error">{err}</div>}
      </ErrorMessage>
    </div>
  );
}

export function UnifiedSearchFormFields() {
  const { values, errors } = useFormikContext<UnifiedSearchFormValues>();
  const { stringify } = useQueryString();

  type SearchOption = {
    value: UnifiedSearchCriteria;
    label: React.ReactNode;
    disabled?: boolean;
  };

  const checkboxOptions: SearchOption[] = [
    { value: 'o', label: 'Analogue descriptions' },
    { value: 'w', label: 'Knowledge base' },
    // {
    //   value: 'm',
    //   label: 'Numerical data and statistics',
    //   disabled: true,
    // },
  ];

  return (
    <>
      {/* Must use the component render fn to use ref */}
      <Field name="query" component={SearchField} />

      <fieldset className={cn('mt-6', { 'has-error': errors.criteria })}>
        <legend className="pb-1 mb-2 border-b border-b-slate-100 w-full text-base font-semibold text-slate-500">
          Include results from:
        </legend>

        {checkboxOptions.map((opt, index) => (
          <div key={opt.value} className="form-control">
            <label className="label cursor-pointer justify-start gap-2">
              <Field
                type="checkbox"
                name="criteria"
                value={opt.value}
                disabled={opt.disabled}
                tabIndex={index + 2}
                className="checkbox checkbox-sm"
              />
              <span
                className={cn('label-text', {
                  'text-muted': opt.disabled,
                })}
              >
                {opt.label}
              </span>
            </label>
          </div>
        ))}

        {/* Temporary dummy option for numerical data link */}
        <div className="form-control">
          <label className="label justify-start gap-2">
            <input type="checkbox" className="checkbox checkbox-sm" disabled />
            <Link
              to={dataSearchRoute()}
              target="_blank"
              style={{ marginLeft: '-2px' }}
              className="label-text link"
            >
              Numerical data and statistics{' '}
              <FontAwesomeIcon icon={faExternalLink} />
            </Link>
          </label>
        </div>

        <ErrorMessage name="criteria">
          {err => <div className="text-error">{err}</div>}
        </ErrorMessage>
      </fieldset>

      <div className="mt-6 text-right space-x-2">
        <Link
          to={{
            pathname: searchRoute(),
            search: values.query.length
              ? stringify({ textSearch: values.query })
              : undefined,
          }}
          className="btn btn-ghost"
        >
          More Options
        </Link>

        <Button
          type="submit"
          color="primary"
          tabIndex={checkboxOptions.length + 1}
          className="gap-1"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} /> Search
        </Button>
      </div>

      <div className="clear-both" />
    </>
  );
}
