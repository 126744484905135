import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import * as fragments from '~/apollo/fragments';
import type {
  DepositionalWikiUpdatePageQuery,
  DepositionalWikiUpdatePageQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import {
  geologyWikiPageRoute,
  uploadGeologyUpdateDiagramRoute,
  uploadGeologyUpdateRoute,
} from '~/paths';

const DEPOSITIONAL_WIKI_UPDATE_PAGE = gql`
  query DepositionalWikiUpdatePage($id: Int) {
    depositionalList(id: $id) {
      ...depositionalWikiParts
      diagram {
        ...diagramParts
        diagramAreas {
          ...diagramAreaParts
        }
        file {
          ...fileParts
        }
      }
    }
  }

  ${fragments.depositionalWikiParts}
  ${fragments.fileParts}
  ${fragments.diagramParts}
  ${fragments.diagramAreaParts}
  ${fragments.fileParts}
`;

type OutletContext = {
  depositionalWiki: DepositionalWikiUpdatePageQuery['depositionalList'][number];
  refetchQueries: PureQueryOptions[];
};

export default function DepositionalUpdateRoute() {
  const params = useParams<{ depositionalId: string }>();
  invariant(params.depositionalId, 'depositionalId param required');
  const depositionalId = parseInt(params.depositionalId);

  const { data, loading } = useQuery<
    DepositionalWikiUpdatePageQuery,
    DepositionalWikiUpdatePageQueryVariables
  >(DEPOSITIONAL_WIKI_UPDATE_PAGE, {
    variables: { id: depositionalId },
  });

  const depositionalWiki = data?.depositionalList.find(
    wiki => wiki.id === depositionalId,
  );

  useBreadcrumb(
    'routes/upload/depositional/$depositionalId',
    depositionalWiki?.title ?? 'Update Geology Wiki',
  );

  const refetchQueries: [
    PureQueryOptions<DepositionalWikiUpdatePageQueryVariables>,
  ] = [
    {
      query: DEPOSITIONAL_WIKI_UPDATE_PAGE,
      variables: { id: depositionalId },
    },
  ];

  if (loading) return <SpinnerPlaceholder />;
  if (!depositionalWiki) return <NotFound />;

  const outletContext: OutletContext = {
    depositionalWiki,
    refetchQueries,
  };

  return (
    <>
      <Link
        className="btn btn-primary btn-sm float-right gap-1"
        to={geologyWikiPageRoute(depositionalWiki.id)}
      >
        Wiki Page <FontAwesomeIcon icon={faExternalLink} />
      </Link>

      <PageHeading>{depositionalWiki.title}</PageHeading>

      <NavTabs className="mb-2">
        <NavTabs.Tab to={uploadGeologyUpdateRoute(depositionalWiki.id)} end>
          Overview
        </NavTabs.Tab>
        <NavTabs.Tab to={uploadGeologyUpdateDiagramRoute(depositionalWiki.id)}>
          Diagram
        </NavTabs.Tab>
      </NavTabs>

      <Outlet context={outletContext} />
    </>
  );
}

export function useDepositionalWikiUpdateOutletContext() {
  return useOutletContext<OutletContext>();
}
