import { gql, useQuery } from '@apollo/client';
import { createContext, useContext } from 'react';
import type { KpTableWikiListQuery } from '~/apollo/generated/schema';

const KP_TABLE_WIKI_LIST = gql`
  query KPTableWikiList {
    depositionalList {
      id
      geologyType
      type
      value
    }
    otherList {
      id
      title
    }
  }
`;

type Context = NonNullable<KpTableWikiListQuery>;

const defaultValue: Context = {
  depositionalList: [],
  otherList: [],
};

const KPPanelContext = createContext<Context>(defaultValue);

export function KPPanelContextProvier({
  children,
}: {
  children: React.ReactNode;
}) {
  const { data } = useQuery<KpTableWikiListQuery>(KP_TABLE_WIKI_LIST);

  return (
    <KPPanelContext.Provider value={data ?? defaultValue}>
      {children}
    </KPPanelContext.Provider>
  );
}

export function useKPPanelContext() {
  return useContext(KPPanelContext);
}
