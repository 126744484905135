import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { InputGroup } from '~/components/common/InputGroup';
import { Panel } from '~/components/common/Panel';
import type { UpdateProfileFormValues } from '~/utils/modules/user';
import { UpdateUsernameField } from './UpdateUsernameField';

type Props = {
  currentUsername: string;
  companyMails: string[];
  disabled: boolean;
};

export function UserProfileFormFields({
  currentUsername,
  companyMails,
  disabled,
}: Props): JSX.Element {
  const { values } = useFormikContext<UpdateProfileFormValues>();

  return (
    <>
      <Panel variant="primary">
        <Panel.Heading>
          <Panel.Title>Account Details</Panel.Title>
        </Panel.Heading>
        <Panel.Body className="space-y-2">
          <Field
            name="name"
            label="Name"
            component={FormikField}
            className="w-full join-item"
            renderInput={(input: JSX.Element) => (
              <InputGroup>
                <InputGroup.Addon>
                  <FontAwesomeIcon icon={faUser} />
                </InputGroup.Addon>
                {input}
              </InputGroup>
            )}
            disabled={disabled}
            required
          />

          <div>
            <Field
              name="username"
              label="Username/Email Address"
              component={FormikField}
              value={currentUsername}
              onChange={() => {}}
              className="w-full join-item"
              renderInput={(input: JSX.Element) => (
                <InputGroup>
                  <InputGroup.Addon>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroup.Addon>
                  {input}
                </InputGroup>
              )}
              disabled
            />
            <UpdateUsernameField
              currentUsername={currentUsername}
              companyMails={companyMails}
              disabled={disabled}
            />
          </div>
        </Panel.Body>
      </Panel>

      {!disabled && (
        <Panel>
          <Panel.Heading>
            <Panel.Title>New Password</Panel.Title>
          </Panel.Heading>
          <Panel.Body className="space-y-2">
            <p>
              If you would like to update your password, enter a new one below.
              Password must be at least 8 characters in length and contain at
              least one uppercase letter, one lowercase letter, and a number:
            </p>

            <div className="grid lg:grid-cols-2 gap-6">
              <div>
                <Field
                  name="password"
                  label="New password"
                  component={FormikField}
                  type="password"
                />
              </div>
              <div>
                <Field
                  name="passwordConfirm"
                  label="Confirm new password"
                  component={FormikField}
                  type="password"
                  required={!!values.password}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}
    </>
  );
}
