import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Divider } from 'react-daisyui';
import type {
  AnaloguesByGeologyTypeStatsQuery,
  AnaloguesByGeologyTypeStatsQueryVariables,
  AnaloguesSummaryStatsQuery,
  AnaloguesSummaryStatsQueryVariables,
  MeasurementStatsQuery,
  MeasurementStatsQueryVariables,
  StudyStatsQuery,
  StudyStatsQueryVariables,
  VomStatsQuery,
  VomStatsQueryVariables,
  WikiStatsQuery,
  WikiStatsQueryVariables,
} from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { PageLoader } from '~/components/page/PageLoader';
import { PieChart } from '~/components/statistics/PieChart';

const ANALOGUES_SUMMARY_QUERY = gql`
  query AnaloguesSummaryStats {
    searchOutcrops {
      queryOptions {
        geologyType {
          name
          count
        }
        basinType {
          name
          count
        }
        climate {
          name
          count
        }
      }
      outcrops {
        id
      }
    }
  }
`;

const ANALOGUES_BY_GEOLOGY_TYPE_QUERY = gql`
  query AnaloguesByGeologyTypeStats($geologyType: [String!]!) {
    searchOutcrops(geologyType: $geologyType) {
      queryOptions {
        grossDepositionalEnvironment {
          name
          count
        }
        basinType {
          name
          count
        }
        climate {
          name
          count
        }
      }
    }
  }
`;

const VOMS_QUERY = gql`
  query VomStats {
    virtualOutcropModelList {
      id
      project {
        id
        name
      }
      outcrop {
        id
        region {
          id
          location {
            id
            country
          }
        }
      }
    }
  }
`;

const STUDY_STATS_QUERY = gql`
  query StudyStats {
    studyList {
      id
    }
  }
`;

const WIKI_STATS_QUERY = gql`
  query WikiStats {
    depositionalList {
      id
    }
    lithostratFormationList {
      id
    }
    lithostratGroupList {
      id
    }
    lithostratMemberList {
      id
    }
    otherList {
      id
    }
  }
`;

const MEASUREMENT_STATS_QUERY = gql`
  query MeasurementStats {
    studyList {
      id
      architecturalElements {
        id
        measurementCount
      }
    }
  }
`;

function PanelHR() {
  return <Divider />;
}

export default function StatisticsRoute() {
  const { data: dataOutcrops, loading: loadingOutcrops } = useQuery<
    AnaloguesSummaryStatsQuery,
    AnaloguesSummaryStatsQueryVariables
  >(ANALOGUES_SUMMARY_QUERY, {});

  const { data: dataStudies, loading: loadingStudies } = useQuery<
    StudyStatsQuery,
    StudyStatsQueryVariables
  >(STUDY_STATS_QUERY, {});

  const { data: dataClastics, loading: loadingClastics } = useQuery<
    AnaloguesByGeologyTypeStatsQuery,
    AnaloguesByGeologyTypeStatsQueryVariables
  >(ANALOGUES_BY_GEOLOGY_TYPE_QUERY, {
    variables: {
      geologyType: ['clastic'],
    },
  });

  const { data: dataCarbonates, loading: loadingCarbonates } = useQuery<
    AnaloguesByGeologyTypeStatsQuery,
    AnaloguesByGeologyTypeStatsQueryVariables
  >(ANALOGUES_BY_GEOLOGY_TYPE_QUERY, {
    variables: { geologyType: ['carbonate'] },
  });

  const { data: dataStructural, loading: loadingStructural } = useQuery<
    AnaloguesByGeologyTypeStatsQuery,
    AnaloguesByGeologyTypeStatsQueryVariables
  >(ANALOGUES_BY_GEOLOGY_TYPE_QUERY, {
    variables: { geologyType: ['structural'] },
  });

  const { data: dataVoms, loading: loadingVoms } = useQuery<
    VomStatsQuery,
    VomStatsQueryVariables
  >(VOMS_QUERY);

  const { data: dataWikiStats, loading: loadingWikiStats } = useQuery<
    WikiStatsQuery,
    WikiStatsQueryVariables
  >(WIKI_STATS_QUERY, {});

  const { data: dataMeasurementStats, loading: loadingMeasurementStats } =
    useQuery<MeasurementStatsQuery, MeasurementStatsQueryVariables>(
      MEASUREMENT_STATS_QUERY,
      {},
    );

  const analoguesCount = (
    dataOutcrops?.searchOutcrops.queryOptions.geologyType ?? []
  ).reduce((acc, cur) => acc + cur.count, 0);

  const studyCount = dataStudies?.studyList.length ?? 0;

  const vomCount = dataVoms?.virtualOutcropModelList.length ?? 0;

  const depositionalWikiCount = dataWikiStats?.depositionalList.length ?? 0;
  const lithostratWikiCount =
    (dataWikiStats?.lithostratFormationList.length ?? 0) +
    (dataWikiStats?.lithostratGroupList.length ?? 0) +
    (dataWikiStats?.lithostratMemberList.length ?? 0);
  const otherWikiCount = dataWikiStats?.otherList.length ?? 0;

  const allStudies = dataMeasurementStats?.studyList ?? [];
  const aeCount = allStudies.reduce((acc, cur) => {
    return acc + cur.architecturalElements.length;
  }, 0);
  const measurementCount = allStudies.reduce((acc, cur) => {
    const aeCountSum = cur.architecturalElements.reduce<number>(
      (acc1, cur1) => acc1 + cur1.measurementCount,
      0,
    );
    return acc + aeCountSum;
  }, 0);

  const vomTotalsByCountry = React.useMemo((): [string, number][] => {
    if (!dataVoms) return [];
    const totalsObj = dataVoms.virtualOutcropModelList.reduce((acc, cur) => {
      const curCountry = cur.outcrop?.region?.location.country;
      if (!curCountry) return acc;

      const curTotal = (acc[curCountry] ?? 0) + 1;
      return { ...acc, [curCountry]: curTotal };
    }, {} as Record<string, number>);

    return Object.keys(totalsObj).map(country => [country, totalsObj[country]]);
  }, [dataVoms]);

  const vomTotalsByProject = React.useMemo((): [string, number][] => {
    if (!dataVoms) return [];
    const totalsObj = dataVoms.virtualOutcropModelList.reduce((acc, cur) => {
      const curProject = cur.project?.name;
      if (!curProject) return acc;

      const curTotal = (acc[curProject] ?? 0) + 1;
      return { ...acc, [curProject]: curTotal };
    }, {} as Record<string, number>);

    return Object.keys(totalsObj).map(project => [project, totalsObj[project]]);
  }, [dataVoms]);

  const filterName = (name: string): boolean => !!name && name !== 'undefined';

  useBreadcrumb('routes/stats', 'Database Statistics');

  return (
    <>
      <PageHeading>Database Statistics</PageHeading>
      <PageLoader pageName="stats" showTitle={false} />

      <div className="space-y-6 mt-4">
        <div className="grid lg:grid-cols-12 gap-6">
          <div className="lg:col-span-10 lg:col-start-2">
            <table className="table table-compact w-full">
              <tbody>
                <tr>
                  <th>Number of analogues</th>
                  <td>
                    {loadingOutcrops && <>Loading outcrop statistics...</>}
                    {dataOutcrops ? analoguesCount : ''}
                  </td>
                  <th>Number of studies</th>
                  <td>
                    {loadingStudies && <>Loading study statistics...</>}
                    {dataStudies ? studyCount : ''}
                  </td>
                </tr>
                <tr>
                  <th>Number of VOs</th>
                  <td>
                    {loadingVoms && <>Loading VO statistics...</>}
                    {dataVoms ? vomCount : ''}
                  </td>
                  <th>Number of numerical data</th>
                  <td>
                    {loadingMeasurementStats && (
                      <>Loading measurement statistics...</>
                    )}
                    {dataMeasurementStats ? (
                      <>
                        {measurementCount} measurements on
                        <br />
                        {aeCount} architectural elements
                      </>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Number of wiki articles</th>
                  <td>
                    {loadingWikiStats && <>Loading wiki statistics...</>}
                    {dataWikiStats && (
                      <>
                        <div>{depositionalWikiCount} geology articles</div>
                        <div>{lithostratWikiCount} lithostrat articles</div>
                        <div>{otherWikiCount} other articles</div>
                      </>
                    )}
                  </td>
                  <td />
                  <td />
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <SpinnerPlaceholder show={loadingOutcrops}>
          Loading Outcrop data...
        </SpinnerPlaceholder>

        {dataOutcrops && (
          <Panel>
            <Panel.Heading>
              <Panel.Title>
                Analogues by geology type, basin type and climate
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body className="text-center">
              <div className="grid lg:grid-cols-3 gap-6">
                <div>
                  <PieChart
                    title="Analogues"
                    data={dataOutcrops.searchOutcrops.queryOptions.geologyType.map(
                      geologyType => [
                        geologyType.name ?? 'undefined',
                        geologyType.count,
                      ],
                    )}
                  />
                  <Heading level={5}>Geology Type</Heading>
                </div>
                <div>
                  <PieChart
                    title="Analogues"
                    data={dataOutcrops.searchOutcrops.queryOptions.basinType
                      .filter(bt => filterName(bt.name ?? 'undefined'))
                      .map(basinType => [
                        basinType.name ?? 'undefined',
                        basinType.count,
                      ])}
                  />
                  <Heading level={5}>Basin Type</Heading>
                </div>
                <div>
                  <PieChart
                    title="Analogues"
                    data={dataOutcrops.searchOutcrops.queryOptions.climate
                      .filter(c => filterName(c.name ?? 'undefined'))
                      .map(climate => [
                        climate.name ?? 'undefined',
                        climate.count,
                      ])}
                  />
                  <Heading level={5}>Climate</Heading>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        )}

        <Panel>
          <Panel.Heading>
            <Panel.Title>Analogues by Geology Type</Panel.Title>
          </Panel.Heading>
          <Panel.Body className="text-center">
            <Heading level={3}>Clastics</Heading>
            <div className="grid lg:grid-cols-3 gap-6">
              <div>
                <SpinnerPlaceholder show={loadingClastics} />
                {dataClastics && (
                  <PieChart
                    title="Analogues"
                    data={dataClastics.searchOutcrops.queryOptions.grossDepositionalEnvironment.map(
                      item => [item.name ?? 'undefined', item.count],
                    )}
                  />
                )}
                <Heading level={5}>
                  Gross Depositional Environment
                  <br />
                  &amp; Structure Class
                </Heading>
              </div>
              <div>
                <SpinnerPlaceholder show={loadingClastics} />
                {dataClastics && (
                  <PieChart
                    title="Analogues"
                    data={dataClastics.searchOutcrops.queryOptions.basinType
                      .filter(bt => filterName(bt.name ?? 'undefined'))
                      .map(item => [item.name ?? 'undefined', item.count])}
                  />
                )}
                <Heading level={5}>Basin Type</Heading>
              </div>
              <div>
                <SpinnerPlaceholder show={loadingClastics} />
                {dataClastics && (
                  <PieChart
                    title="Analogues"
                    data={dataClastics.searchOutcrops.queryOptions.climate
                      .filter(climate => filterName(climate.name ?? 'climate'))
                      .map(item => [item.name ?? 'undefined', item.count])}
                  />
                )}
                <Heading level={5}>Climate</Heading>
              </div>
            </div>

            <PanelHR />

            <Heading level={3}>Carbonates</Heading>
            <div className="grid lg:grid-cols-3 gap-6">
              <div>
                <SpinnerPlaceholder show={loadingCarbonates} />
                {dataCarbonates && (
                  <PieChart
                    title="Analogues"
                    data={dataCarbonates.searchOutcrops.queryOptions.grossDepositionalEnvironment.map(
                      item => [item.name ?? 'undefined', item.count],
                    )}
                  />
                )}
                <Heading level={5}>
                  Gross Depositional Environment
                  <br />
                  &amp; Structure Class
                </Heading>
              </div>
              <div>
                <SpinnerPlaceholder show={loadingCarbonates} />
                {dataCarbonates && (
                  <PieChart
                    title="Analogues"
                    data={dataCarbonates.searchOutcrops.queryOptions.basinType
                      .filter(basinType =>
                        filterName(basinType.name ?? 'basin'),
                      )
                      .map(item => [item.name ?? 'undefined', item.count])}
                  />
                )}
                <Heading level={5}>Basin Type</Heading>
              </div>
              <div>
                <SpinnerPlaceholder show={loadingCarbonates} />
                {dataCarbonates && (
                  <PieChart
                    title="Analogues"
                    data={dataCarbonates.searchOutcrops.queryOptions.climate
                      .filter(climate => filterName(climate.name ?? 'climate'))
                      .map(item => [item.name ?? 'undefined', item.count])}
                  />
                )}
                <Heading level={5}>Climate</Heading>
              </div>
            </div>

            <PanelHR />

            <Heading level={3}>Structural</Heading>
            <div className="grid lg:grid-cols-3 gap-6">
              <div>
                <SpinnerPlaceholder show={loadingStructural} />
                {dataStructural && (
                  <PieChart
                    title="Analogues"
                    data={dataStructural.searchOutcrops.queryOptions.grossDepositionalEnvironment.map(
                      item => [item.name ?? 'undefined', item.count],
                    )}
                  />
                )}
                <Heading level={5}>
                  Gross Depositional Environment
                  <br />
                  &amp; Structure Class
                </Heading>
              </div>

              <div>
                <SpinnerPlaceholder show={loadingStructural} />
                {dataStructural && (
                  <PieChart
                    title="Analogues"
                    data={dataStructural.searchOutcrops.queryOptions.basinType
                      .filter(basinType => basinType.name !== 'undefined')
                      .map(item => [item.name ?? 'undefined', item.count])}
                  />
                )}
                <Heading level={5}>Basin Type</Heading>
              </div>
              <div>
                <SpinnerPlaceholder show={loadingStructural} />
                {dataStructural && (
                  <PieChart
                    title="Analogues"
                    data={dataStructural.searchOutcrops.queryOptions.climate
                      .filter(climate => climate.name !== 'undefined')
                      .map(item => [item.name ?? 'undefined', item.count])}
                  />
                )}
                <Heading level={5}>Climate</Heading>
              </div>
            </div>
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading>
            <Panel.Title>Virtual Outcrops</Panel.Title>
          </Panel.Heading>

          <Panel.Body className="text-center">
            <SpinnerPlaceholder show={loadingVoms} />
            {dataVoms && (
              <div className="lg:w-2/3 mx-auto">
                <div className="grid lg:grid-cols-2 gap-6">
                  <div>
                    <PieChart
                      title="Virtual Outcrops"
                      data={vomTotalsByCountry}
                    />
                    <Heading level={5}>Country</Heading>
                  </div>
                  <div>
                    <PieChart
                      title="Virtual Outcrops"
                      data={vomTotalsByProject}
                    />
                    <Heading level={5}>Project</Heading>
                  </div>
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      </div>
    </>
  );
}
