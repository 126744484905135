import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Cartesian3, Matrix4, Transforms } from 'cesium';
import React, { useEffect } from 'react';
import type { DropEvent, FileRejection } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import type * as schema from '~/apollo/generated/schema';
import { CesiumViewer } from '~/components/cesium/CesiumViewer';
import type {
  ParsedPolygoneElement,
  PolygonEntityStruct,
} from '~/components/cesium/limeParse';
import {
  createPolygonEntityStruct,
  getPolygonElements,
  parseLegendXml,
  parseLimePolygonElement,
  parseLimeXml,
} from '~/components/cesium/limeParse';
import { DropzoneContainer } from '~/components/common/DropzoneContainer';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';

type Props = {
  vomId: number;
};

type SetupFileObject = {
  Info: string;
  ModelName: string;
  Sections: string[];
  YawPitch: number[];
};

const VO_SETUP_FILE = gql`
  query VOSetupFile($id: Int!) {
    virtualOutcropModelSetupFile(id: $id)
  }
`;

const VO_OSGJS_FILE = gql`
  query VOOgsjsFile($id: Int!, $section: String!) {
    virtualOutcropModelOsgjsFile(id: $id, section: $section)
  }
`;

const VOM_TILESETS = gql`
  query VomTilesets($vomId: Int!) {
    virtualOutcropModelList(id: $vomId) {
      id
      name
      cesiumAsset {
        id
        assetToken
        localPath
      }
      outcrop {
        id
        name
        region {
          id
          name
        }
      }
    }
  }
`;

export function LimeUpload({ vomId }: Props) {
  const [setupFile, setSetupFile] = React.useState<SetupFileObject | null>(
    null,
  );
  const [osgjsFiles, setOsgjsFiles] = React.useState<any[]>([]);
  const [userCenter, setUserCenter] = React.useState<number[]>([]);
  const [polygonStructs, setPolygonStructs] = React.useState<
    PolygonEntityStruct[]
  >([]);

  const [getOsgjsFile] = useLazyQuery<
    schema.VoOgsjsFileQuery,
    schema.VoOgsjsFileQueryVariables
  >(VO_OSGJS_FILE);

  const { data: vomData, loading: vomLoading } = useQuery<
    schema.VomViewerPageQuery,
    schema.VomViewerPageQueryVariables
  >(VOM_TILESETS, {
    variables: { vomId },
  });

  const { data } = useQuery<
    schema.VoSpecsTabQuery,
    schema.VoSpecsTabQueryVariables
  >(VO_SETUP_FILE, {
    skip: setupFile !== null,
    variables: { id: vomId },
    onCompleted: data => {
      console.log('setupData', data);
    },
    onError: error => {
      console.error(error);
      toast.error('Error loading setup file');
    },
  });

  useEffect(() => {
    if (data && data.virtualOutcropModelSetupFile) {
      const parsedSetupFile: SetupFileObject = JSON.parse(
        data.virtualOutcropModelSetupFile,
      );
      setSetupFile(parsedSetupFile);
    }
    let userCenter: number[] = [];
    if (setupFile) {
      setupFile['Sections'].forEach(section => {
        const osgjsFilePromise = getOsgjsFile({
          variables: { id: vomId, section: section },
        });
        osgjsFilePromise
          .then(({ data }) => {
            if (data && data.virtualOutcropModelOsgjsFile) {
              const parsed = JSON.parse(data.virtualOutcropModelOsgjsFile);
              setOsgjsFiles([...osgjsFiles, parsed]);
              if (
                parsed['osg.Node']['Children'][0].hasOwnProperty('osg.PagedLOD')
              ) {
                userCenter =
                  parsed['osg.Node']['Children'][0]['osg.PagedLOD'][
                    'UserCenter'
                  ];
              } else {
                userCenter =
                  parsed['osg.Node']['Children'][0]['osg.Node']['Children'][0][
                    'osg.PagedLOD'
                  ]['UserCenter'];
              }
              if (userCenter.length !== 4) {
                toast.error('Error loading osgjs usercenter');
                return;
              }
              setUserCenter(userCenter);
            }
          })
          .catch(error => {
            console.error('error:', error);
            toast.error('Error loading osgjs file');
          });
      });
    }
  }, [data]);

  const vom = vomData?.virtualOutcropModelList.find(v => v.id === vomId);
  if (vomLoading) return <SpinnerPlaceholder />;
  if (!vom) return <NotFound />;

  const cesiumAsset = vom?.cesiumAsset;
  if (!cesiumAsset) return <NotFound />;
  if (!cesiumAsset.localPath) return <NotFound />;
  if (!cesiumAsset.assetToken) return <NotFound />;

  const ca = {
    assetToken: cesiumAsset.assetToken,
    localPath: cesiumAsset.localPath,
    transform: { local: true },
  };

  const handleDrop = (files: File[]) => {
    console.log('handle drop files:', files);
    files.forEach(file => {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onabort = () => toast.error('File reading aborted');
      reader.onerror = () => toast.error('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
        const lime_xml = parseLimeXml(binaryStr as string);
        const polygonElements = getPolygonElements(lime_xml);
        const legend = parseLegendXml(lime_xml);
        const polygonsData: ParsedPolygoneElement[] = [];
        for (let polygon of polygonElements) {
          const parsedPolygon = parseLimePolygonElement(polygon);
          if (parsedPolygon.classId && parsedPolygon.classId in legend) {
            parsedPolygon.color = legend[parsedPolygon.classId];
          }
          polygonsData.push(parsedPolygon);
        }
        const polygonStructs = polygonsData.map(polygonData => {
          const ltw = Matrix4.fromArray(polygonData.ltw.flat());
          // if already placed, should get tileset placed model matrix instead of eastNorthUpToFixedFrame at 0,0
          const tilesetModelMatrix = Transforms.eastNorthUpToFixedFrame(
            Cartesian3.fromDegrees(0, 0),
          );
          return createPolygonEntityStruct(
            ltw,
            userCenter,
            tilesetModelMatrix,
            polygonData.color ?? '',
            polygonData.points,
          );
        });
        setPolygonStructs(polygonStructs);
        console.log('polygonStructs:', polygonStructs);
        // send polygon data to server here
      };
    });
  };

  const dropFailed = (rejectedFiles: FileRejection[], event: DropEvent) => {
    console.log('drop failed files:', rejectedFiles, event);
    toast.error('File upload failed');
  };

  return (
    <div className="space-y-6">
      <Dropzone
        onDropAccepted={handleDrop}
        onDropRejected={dropFailed}
        maxFiles={1}
      >
        {({ getRootProps, getInputProps }) => (
          <DropzoneContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <p style={{ marginBottom: 0 }}>
              Drop files here or click to browse.
            </p>
          </DropzoneContainer>
        )}
      </Dropzone>
      <CesiumViewer initialTilesets={[ca]} polygonsData={polygonStructs} />
    </div>
  );
}
