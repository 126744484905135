import { Field, Form, Formik, useFormikContext } from 'formik';
import React, { useState } from 'react';

import { FormikField } from '~/components/common/FormikField';
import { Panel } from '~/components/common/Panel';
import { OutcropSelect } from '~/components/upload/outcrop/OutcropSelect';
import { OutcropGeoreferencesExport } from '~/components/upload/georeference/OutcropGeoreferencesExport';

type FormValues = {
  outcropId: number | null;
};

type FormFieldsProps = {
  onChange: (value: number | null) => void;
};
const FormFields: React.FC<FormFieldsProps> = ({
  onChange,
}: FormFieldsProps) => {
  const { setFieldValue } = useFormikContext<FormValues>();

  function handleChange(value: number | null) {
    setFieldValue('outcropId', value);
    onChange(value);
  }

  return (
    <Field
      name="outcropId"
      label="Select Outcrop"
      component={FormikField}
      type={OutcropSelect}
      onChange={handleChange}
      required
    />
  );
};

export default function ExportGeoreferencesOutcropRoute() {
  const [outcropId, setOutcropId] = useState<number | null>(null);

  function handleSubmit(values: FormValues) {}

  return (
    <>
      <Panel>
        <Panel.Heading>
          <Panel.Title>Export Outcrop Georeferences</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Formik onSubmit={handleSubmit} initialValues={{ outcropId: null }}>
            <Form>
              <FormFields onChange={setOutcropId} />
            </Form>
          </Formik>
        </Panel.Body>

        {outcropId && (
          <Panel.Footer className="text-right">
            <OutcropGeoreferencesExport outcropId={outcropId} />
          </Panel.Footer>
        )}
      </Panel>
    </>
  );
}
