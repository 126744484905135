import { gql, useQuery } from '@apollo/client';
import * as fragments from '~/apollo/fragments';
import type {
  OutcropPalaeogeographyRouteQuery,
  OutcropPalaeogeographyRouteQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import {
  OutcropPaleomapsTab,
  outcropPaleogeographyParts,
} from '~/components/outcrop/PaleomapsTab';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';

const PALAEOGEOGRAPHY_ROUTE = gql`
  query OutcropPalaeogeographyRoute($id: Int!) {
    outcropList(id: $id) {
      ...outcropPaleogeographyParts
    }
  }

  ${outcropPaleogeographyParts}
  ${fragments.outcropParts}
  ${fragments.basinParts}
  ${fragments.sourceAreaParts}
  ${fragments.paleoMapParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.lithostratAgeParts}
`;

export default function OutcropPalaeogeographyRoute() {
  const ctx = useOutcropOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    OutcropPalaeogeographyRouteQuery,
    OutcropPalaeogeographyRouteQueryVariables
  >(PALAEOGEOGRAPHY_ROUTE, {
    variables: { id: outcropId },
  });

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return <OutcropPaleomapsTab outcrop={outcrop} />;
}
