import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { Panel } from '~/components/common/Panel';
import { FormationSelector } from '~/components/upload/lithostrat/formation/FormationSelector';
import { LithostratTypeSelector } from '~/components/upload/lithostratType/LithostratTypeSelector';
import { LithostratOrderSelector } from '~/components/upload/lithostrat/LithostratOrderSelector';

export function MemberFormFields() {
  return (
    <div className="grid lg:grid-cols-3 gap-6">
      <div className="lg:col-span-2">
        <Panel>
          <Panel.Heading>
            <Panel.Title>Member Details</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <Field
              name="name"
              label="Member name"
              component={FormikField}
              required
            />
            <Field
              name="description"
              label="Description"
              component={FormikField}
              type="textarea"
              required
            />

            <div className="grid lg:grid-cols-3 gap-6">
              <div className="lg:col-span-2">
                <Field
                  name="lithostratTypeId"
                  label="Lithostrat Type"
                  component={FormikField}
                  type={LithostratTypeSelector}
                  required
                />
              </div>

              <div>
                <Field
                  name="lithostratOrder"
                  label="Lithostrat Order"
                  component={FormikField}
                  type={LithostratOrderSelector}
                />
              </div>
            </div>

            <Field
              name="comments"
              label="Comments"
              component={FormikField}
              type="textarea"
            />
          </Panel.Body>
        </Panel>
      </div>

      <div>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Formation</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <Field
              name="formationId"
              label="Formation"
              component={FormikField}
              type={FormationSelector}
            />
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
}
