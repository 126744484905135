import { Link } from 'react-router-dom';
import { ListGroup } from '~/components/common/ListGroup';
import { Panel } from '~/components/common/Panel';
import { useDashboardOutletContext } from '~/routes/dashboard';
import { geologyWikiPageRoute } from '~/paths';

export default function DashboardSearchGeologyResultsRoute() {
  const ctx = useDashboardOutletContext();
  const results = ctx.wikiResults.geology;

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Geology Articles</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        {!results.length && (
          <p className="text-muted">
            <em>
              No geology articles found matching the given search criteria.
            </em>
          </p>
        )}

        <ListGroup>
          {results.map(dwResult => (
            <ListGroup.Item
              as={Link}
              key={dwResult.depositionalWiki.id}
              to={geologyWikiPageRoute(dwResult.depositionalWiki.id)}
              className="p-4"
            >
              {dwResult.depositionalWiki.title}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Panel.Body>
    </Panel>
  );
}
