import { cn } from '~/utils/common';
import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '~/components/common/Tooltip';
import { useKPPanelContext } from '~/components/keyParameter/KeyParametersPanel/KPPanelContext';
import { geologyWikiPageRoute } from '~/paths';

type Props = {
  geologyType: string | null;
  type: string;
  value: string | null | undefined;
  dominant: boolean;
  children: React.ReactNode;
};

export function LinkedDepositionalValue({
  geologyType,
  type,
  value,
  dominant,
  children,
}: Props) {
  const { depositionalList } = useKPPanelContext();

  if (!value) return null;

  const wikiPage = () => {
    const wp = depositionalList.find(wikiPage => {
      const wikiPageValues = wikiPage.value ?? [];
      // For old KPs with no geology type, look up wikis with no GT set, or clastic
      if (geologyType === 'unknown') {
        return (
          wikiPage.geologyType === 'clastic' &&
          wikiPage.type === type &&
          wikiPageValues.includes(value)
        );
      }
      return (
        wikiPage.geologyType === geologyType &&
        wikiPage.type === type &&
        wikiPageValues.includes(value)
      );
    });

    if (wp) return wp;

    // If a wiki page wasn't found with the given geology type, look again for any GT
    return depositionalList.find(wikiPage => {
      const wikiPageValues = wikiPage.value ?? [];
      return wikiPage.type === type && wikiPageValues.includes(value);
    });
  };

  const wp = wikiPage();

  const tooltip = 'Dominant Key Parameter';

  if (!wp) {
    return (
      <Tooltip message={tooltip} disabled={!dominant} className="inline">
        <span
          className={
            dominant
              ? 'font-bold border-b-2 border-b-yellow-300 rounded-sm cursor-default'
              : undefined
          }
        >
          {children}
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip message={tooltip} disabled={!dominant} className="inline">
      <Link
        to={geologyWikiPageRoute(wp.id)}
        target="_blank"
        className={cn('link', {
          'font-bold border-b-2 border-b-yellow-300 hover:border-b-yellow-500 rounded-sm cursor-pointer':
            dominant,
        })}
      >
        {children}
      </Link>
    </Tooltip>
  );
}
