import React from 'react';
import { Link } from 'react-router-dom';
import { searchRoute, wikiRoute } from '~/paths';
import { defaultAnalogueSearch } from '~/utils/modules/analogueSearch';
import { useQueryString } from '~/hooks/routing';
import { Heading } from '~/components/common/Heading';

type FeaturedLinkProps = {
  title: React.ReactNode;
  to: any;
  img: string;
  children: React.ReactChild;
  disabled?: boolean;
};

function FeaturedLink({ title, to, img, children }: FeaturedLinkProps) {
  return (
    <div>
      <Link
        to={to}
        className="block border border-transparent hover:border hover:border-primary hover:bg-base-200"
      >
        {/* <div className="featured-page-container sponsor"> */}
        <div className="p-6 text-center">
          <img
            src={`/assets/images/page/${img}`}
            className="w-1/2 h-auto mb-2 inline"
            alt=""
          />
          <Heading level={4}>{title}</Heading>
          <p className="text-sm text-base-content">{children}</p>
        </div>
      </Link>
    </div>
  );
}

export function FeaturedLinks() {
  const { stringify } = useQueryString();
  const productionSearchQuery = stringify(
    defaultAnalogueSearch({ outcropCategory: ['production'] }),
  );

  return (
    <div className="grid md:grid-cols-5 gap-6">
      <FeaturedLink to="/search" img="icon-outcrop.jpg" title="Search">
        Find an outcrop analogue with logs, sections, and virtual outcrops.
      </FeaturedLink>

      <FeaturedLink to="/data" img="icon-chart.jpg" title="Geometric Data">
        Extract statistical data in the format of body dimensions, variograms,
        and MPS TIs.
      </FeaturedLink>

      <FeaturedLink to={wikiRoute()} img="icon-wiki.jpg" title="Knowledge Base">
        Contains descriptions of all the SAFARI elements including the
        lithostrat wiki.
      </FeaturedLink>

      <FeaturedLink
        to={{
          pathname: searchRoute(),
          search: productionSearchQuery,
        }}
        img="icon-data.jpg"
        title="Production Data"
      >
        Extract analogue production data, classified within the Safari Standard.
      </FeaturedLink>

      <FeaturedLink to="/modern" img="icon-jeep.jpg" title="Modern">
        Tool to find an analogue for continental and shallow marine systems.
      </FeaturedLink>
    </div>
  );
}
