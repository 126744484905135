import { useQuery , gql } from '@apollo/client';
import type { FieldProps } from 'formik';

import type { PublicationTypeSelectQuery } from '~/apollo/generated/schema';
import { FormikSelectField } from '~/components/common/FormikField/FormikSelectField';

export const PUBLICATION_TYPE_SELECT = gql`
  query PublicationTypeSelect {
    studyEnumerations(enumType: PUBLICATION_TYPE) {
      values
    }
  }
`;

export function PublicationTypeSelect(props: FieldProps) {
  const { data, loading } = useQuery<PublicationTypeSelectQuery>(
    PUBLICATION_TYPE_SELECT,
    {},
  );

  const values = data?.studyEnumerations.values ?? [];
  const options = values.map(value => ({ value, label: value }));

  return <FormikSelectField {...props} options={options} disabled={loading} />;
}
