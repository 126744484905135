import { gql } from '@apollo/client';
import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import {
  faCheckCircle,
  faExclamationTriangle,
  faMapMarkerAlt,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import ExifReader from 'exifreader';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card } from 'react-daisyui';
import type {
  FieldPicsOutcropListQuery,
  NearestOutcropsQuery,
  NearestOutcropsQueryVariables,
} from '~/apollo/generated/schema';
import { FormikAutosave } from '~/components/common/FormikAutosave';
import { FormikField } from '~/components/common/FormikField';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { RequiredField } from '~/components/common/icons/RequiredField';
import type {
  UpdateMetadataHandler,
  UploadQueueItem,
} from '~/components/upload/fieldPicture/BulkFieldPictureUploader';
import { CoordinatesMapPreview } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/CoordinatesMapPreview';
import { CoordinatesText } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/CoordinatesText';
import type { BulkFieldPictureUploaderDefaultValues } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/DefaultValuesForm';
import { LocationEditor } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/LocationEditor';
import { OutcropPicker } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/OutcropPicker';
import { OutcropSuggestions } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/OutcropSuggestions';
import { useImperativeQuery } from '~/hooks/apollo';
import { yup } from '~/utils/validation';

const NEAREST_OUTCROPS = gql`
  query NearestOutcrops($location: LatLngInput!) {
    nearestOutcrops(location: $location) {
      distance
      outcrop {
        id
        name
        center {
          lat
          lng
        }
      }
    }
  }
`;

export type FieldPictureFormValues = {
  description: string;
  author: string;
  outcropId: string;
  location: google.maps.LatLngLiteral | null;
  locationApproximate: boolean;
};

function initialFormValues(
  defaultValues?: BulkFieldPictureUploaderDefaultValues,
): FieldPictureFormValues {
  return {
    description: '',
    author: defaultValues?.author ?? '',
    outcropId: defaultValues?.outcropId ?? '',
    location: null,
    locationApproximate: false,
  };
}

const validationSchema = yup.object({
  outcropId: yup.number().positive().required(),
  author: yup.string().optional().nullable(),
  description: yup.string().optional().nullable(),
  location: yup
    .object({
      lat: yup.number().min(-90).max(90).required(),
      lng: yup.number().min(-180).max(180).required(),
    })
    .nullable(),
  locationApproximate: yup.boolean(),
});

type MetadataEditorProps = {
  item: UploadQueueItem;
  defaultValues: BulkFieldPictureUploaderDefaultValues;
  outcropId?: number;
  outcrops: FieldPicsOutcropListQuery['outcropList'];
  onRemove: (tmpId: string) => void;
  onSubmit: UpdateMetadataHandler;
};

export function MetadataEditor({
  item,
  defaultValues,
  outcropId,
  outcrops,
  onRemove,
  onSubmit,
}: MetadataEditorProps) {
  async function handleSubmit(values: FieldPictureFormValues) {
    const metadata: UploadQueueItem['metadata'] = {
      outcropId: parseInt(values.outcropId) || null,
      location: values.location,
      author: values.author.trim() || null,
      description: values.description.trim() || null,
      locationApproximate: values.locationApproximate,
    };

    const valid = validationSchema.isValidSync(metadata);
    onSubmit(item.tmpId, metadata, valid);
  }

  const handleRemove = () => onRemove(item.tmpId);

  return (
    <Formik
      initialValues={initialFormValues(defaultValues)}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormikAutosave />
        <MetadataForm
          outcropId={outcropId}
          file={item.file}
          state={item.state}
          valid={item.valid}
          defaultValues={defaultValues}
          onRemove={handleRemove}
          outcrops={outcrops}
        />
      </Form>
    </Formik>
  );
}

type MetadataFormProps = {
  outcropId?: number;
  file: File;
  state: UploadQueueItem['state'];
  valid: boolean;
  defaultValues: BulkFieldPictureUploaderDefaultValues;
  onRemove: () => void;
  outcrops: FieldPicsOutcropListQuery['outcropList'];
};

function MetadataForm({
  outcropId,
  file,
  state,
  valid,
  defaultValues,
  onRemove,
  outcrops,
}: MetadataFormProps) {
  const [exifLocation, setExifLocation] =
    useState<google.maps.LatLngLiteral | null>();

  const { values, setFieldValue } = useFormikContext<FieldPictureFormValues>();

  const [loadNearestOutcrops, { data, loading }] = useImperativeQuery<
    NearestOutcropsQuery,
    NearestOutcropsQueryVariables
  >(NEAREST_OUTCROPS);

  const disabled = state !== 'pending' && state !== 'failed';

  useEffect(() => {
    if (values.location) {
      // Need to strip __typename here
      const { lat, lng } = values.location;
      loadNearestOutcrops({ variables: { location: { lat, lng } } });
    }
  }, [values.location, loadNearestOutcrops]);

  useEffect(() => {
    async function handleFileLoaded(f: File) {
      const exifData = await ExifReader.load(f, {
        // Expanded attempts to read the GPS data
        expanded: true,
      });
      const lat = exifData.gps?.Latitude;
      const lng = exifData.gps?.Longitude;

      if (lat && lng) {
        setExifLocation({ lat, lng });
        setFieldValue('location', { lat, lng });
      } else {
        setExifLocation(null);
        setFieldValue('location', defaultValues.location);
        setFieldValue('locationApproximate', true);
      }
    }

    handleFileLoaded(file);
    // This should not trigger when defaultValues changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, setFieldValue]);

  // Overwrite current value with the default one if it changes
  useEffect(() => {
    if (values.author !== defaultValues.author) {
      if (!disabled) {
        setFieldValue('author', defaultValues.author);
      }
    }
    // This should not trigger when value changes, only defaultValues
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues.author, setFieldValue]);

  useEffect(() => {
    if (values.outcropId !== defaultValues.outcropId) {
      if (!disabled) {
        setFieldValue('outcropId', defaultValues.outcropId);
      }
    }
    // This should not trigger when value changes, only defaultValues
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues.outcropId, setFieldValue]);

  if (typeof exifLocation === 'undefined') return null;

  const nearestOutcrops = values.location ? (data?.nearestOutcrops ?? []) : [];

  return (
    <>
      <Card
        bordered
        className={cn('shadow-md border-2 transition-colors', {
          'border-error': !valid,
          'border-success': valid,
        })}
      >
        <Card.Body>
          <div className="absolute right-1 top-1">
            <Tooltip message="Remove from queue">
              <button
                type="button"
                onClick={() => onRemove()}
                className="btn btn-ghost btn-sm rounded-lg text-slate-300 hover:text-slate-500"
              >
                <FontAwesomeIcon icon={faX} />
              </button>
            </Tooltip>
          </div>

          <Card.Title>{file.name}</Card.Title>

          <div className="flex justify-between items-start gap-6">
            <div className="space-y-6 text-center">
              <img
                src={URL.createObjectURL(file)}
                className="w-48 h-auto shadow-md border border-slate-300"
                alt={file.name}
              />

              <StateIcon state={state} />
            </div>

            <div className="grow space-y-4">
              {typeof exifLocation !== 'undefined' && (
                <CoordinatesText
                  exifLocation={exifLocation}
                  location={values.location}
                />
              )}

              <div className="grid lg:grid-cols-2 gap-6">
                <Field
                  name="author"
                  label="Author"
                  component={FormikField}
                  type="text"
                  disabled={disabled}
                />
                <Field
                  name="description"
                  label="Description"
                  component={FormikField}
                  type="text"
                  disabled={disabled}
                />
              </div>

              <div className="form-control space-y-1">
                <label htmlFor="oucropId">
                  <span className="label-text">
                    Outcrop
                    <RequiredField />
                  </span>
                </label>
                <OutcropPicker
                  value={values.outcropId}
                  onChange={ocId => setFieldValue('outcropId', ocId)}
                  outcrops={outcrops}
                  disabled={!!outcropId || disabled}
                />
              </div>

              <SpinnerPlaceholder show={loading} />
              {!loading && !outcropId && nearestOutcrops.length > 0 && (
                <OutcropSuggestions
                  nearestOutcrops={nearestOutcrops}
                  outcropId={values.outcropId}
                  onOutcropClick={outcropId =>
                    setFieldValue('outcropId', String(outcropId))
                  }
                />
              )}
            </div>

            <div className="block min-w-56 text-center space-y-2">
              <div>
                <CoordinatesMapPreview location={values.location} />
              </div>

              <div>
                <LocationEditor
                  exifLocation={exifLocation}
                  nearestOutcrops={nearestOutcrops}
                  outcropId={outcropId}
                  defaultValues={defaultValues}
                >
                  {showLocationEditor => (
                    <Button
                      type="button"
                      onClick={showLocationEditor}
                      color="ghost"
                      size="sm"
                      startIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
                      className="h-auto"
                      disabled={disabled}
                    >
                      {values.location ? 'Adjust location' : 'Set location'}
                    </Button>
                  )}
                </LocationEditor>

                {values.locationApproximate && (
                  <div className="text-warning">
                    <FontAwesomeIcon icon={faCircleDot} /> Approximate
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

function StateIcon({ state }: { state: UploadQueueItem['state'] }) {
  if (state === 'uploading') {
    return <SpinnerPlaceholder />;
  } else if (state === 'success') {
    return (
      <div className="space-y-2 text-center text-success">
        <FontAwesomeIcon icon={faCheckCircle} className="text-5xl" />
        <p>Uploaded successfully</p>
      </div>
    );
  } else if (state === 'failed') {
    return (
      <div className="space-y-2 text-center text-error">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-5xl" />
        <p>Upload error</p>
      </div>
    );
  }

  return null;
}
