import type { FilterSearchProps } from '~/components/common/FilterSearch';
import { FilterSearch } from '~/components/common/FilterSearch';
import type { SortIndicatorProps } from '~/components/common/icons/SortIndicator';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { SortTrigger } from '~/components/common/SortTrigger';
import { Tooltip } from '~/components/common/Tooltip';
import type { SortableOutcrop } from '~/utils/modules/outcrop';
import { ucwords } from '~/utils/text';
import { UploadOutcropListItem } from './ListItem';

type Props = {
  outcrops: SortableOutcrop[];
  sortIndicatorProps: SortIndicatorProps;
  filterSearchProps: FilterSearchProps;
};

export function UploadOutcropList({
  outcrops,
  sortIndicatorProps: siProps,
  filterSearchProps,
}: Props) {
  return (
    <>
      <FilterSearch {...filterSearchProps} />

      <table className="table table-compact w-full">
        <thead>
          <tr>
            <th className="text-center">
              <SortTrigger
                colName="approvalState"
                sortIndicatorProps={siProps}
                filterable
                renderFilterOption={ucwords}
              >
                <Tooltip message="Approved">
                  <span>Appr.</span>
                </Tooltip>
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="id" sortIndicatorProps={siProps}>
                ID
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="name" sortIndicatorProps={siProps}>
                Name
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="geologyType"
                sortIndicatorProps={siProps}
                filterable
              >
                Geo. Type
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="region.name"
                sortIndicatorProps={siProps}
                filterable
              >
                Region
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="region.location.country"
                sortIndicatorProps={siProps}
                filterable
              >
                Country
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="numStudies"
                sortIndicatorProps={siProps}
                filterable
              >
                Studies
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="hasPaleomaps"
                sortIndicatorProps={siProps}
                filterable
              >
                Paleomaps
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="numVoms"
                sortIndicatorProps={siProps}
                filterable
              >
                VOMs
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="qcCompleted"
                sortIndicatorProps={siProps}
                filterable
              >
                QC
              </SortTrigger>
            </th>
            <th>{/* Actions */}</th>
          </tr>
        </thead>

        <tbody>
          <NoItemsRow colSpan={10} show={!outcrops.length} />
          {outcrops.map(outcrop => (
            <UploadOutcropListItem key={outcrop.id} outcrop={outcrop} />
          ))}
        </tbody>
      </table>
    </>
  );
}
