import type { PureQueryOptions } from '@apollo/client';
import { useQuery, gql } from '@apollo/client';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as fragments from '~/apollo/fragments';
import type {
  CompanyBookmarksQuery,
  CompanyBookmarksQueryVariables,
} from '~/apollo/generated/schema';
import { BookmarkList } from '~/components/bookmark/BookmarkList';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useMySafariOutletContext } from '~/routes/my-safari';

const COMPANY_BOOKMARKS = gql`
  query CompanyBookmarks {
    unsortedBookmarkList(includePersonal: false, includeCompany: true) {
      ...bookmarkParts
      parent {
        ...bookmarkParentParts
      }
      target {
        ...bookmarkTargetParts
      }
      user {
        ...publicUserParts
      }
      company {
        ...companyParts
      }
      collections {
        ...bookmarkCollectionParts
      }
    }
  }

  ${fragments.bookmarkParts}
  ${fragments.bookmarkCollectionParts}
  ${fragments.bookmarkParentParts}
  ${fragments.bookmarkTargetParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.publicUserParts}
  ${fragments.companyParts}
`;

export default function CompanyBookmarksRoute() {
  const { companyCollections, refetchQueries } = useMySafariOutletContext();

  const { data, loading } = useQuery<
    CompanyBookmarksQuery,
    CompanyBookmarksQueryVariables
  >(COMPANY_BOOKMARKS, {});

  const localRefetchQueries: [
    PureQueryOptions<CompanyBookmarksQueryVariables>,
  ] = [{ query: COMPANY_BOOKMARKS }];

  const allRefetchQueries: PureQueryOptions[] = [
    ...refetchQueries,
    ...localRefetchQueries,
  ];

  const bookmarks = data?.unsortedBookmarkList ?? [];

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      {!bookmarks.length && (
        <div className="text-center" style={{ margin: '20px' }}>
          <p>Your company hasn't created any bookmarks yet.</p>
          <p>
            To create a company bookmark, click the Bookmarks button (
            <FontAwesomeIcon icon={faBookmark} />) on the corresponding page or
            element that you wish to bookmark and select the "Share with
            company" option.
          </p>
        </div>
      )}

      <BookmarkList
        bookmarks={bookmarks}
        collections={companyCollections}
        refetchQueries={allRefetchQueries}
        sortStateKey="companyBookmarks"
      />
    </>
  );
}
