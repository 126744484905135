import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';

import * as fragments from '~/apollo/fragments';
import {
  CREATE_GIGA_PAN,
  DELETE_GIGA_PAN,
  UPDATE_GIGA_PAN,
} from '~/apollo/operations/supportingObject';
import type {
  UploadStudyUpdatePanoramasTabQuery,
  UploadStudyUpdatePanoramasTabQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

export const PANORAMAS_TAB = gql`
  query UploadStudyUpdatePanoramasTab($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      gigaPans {
        ...gigaPanParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
            signedUrl
          }
        }
        georeference {
          ...georeferenceParts
        }
        outcropTag {
          ...outcropParts
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.gigaPanParts}
  ${fragments.outcropParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.georeferenceParts}
`;

export default function UpdateStudyPanoramasRoute() {
  const ctx = useUpdateStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    UploadStudyUpdatePanoramasTabQuery,
    UploadStudyUpdatePanoramasTabQueryVariables
  >(PANORAMAS_TAB, {
    variables: { studyId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadStudyUpdatePanoramasTabQueryVariables>,
  ] = [
    {
      query: PANORAMAS_TAB,
      variables: { studyId },
    },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const gigaPans = study?.gigaPans ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <SupportObjectManager
      studyId={studyId}
      supportObject={gigaPans}
      supportObjectType="gigaPan"
      parentType="GIGA_PAN"
      createMutation={CREATE_GIGA_PAN}
      updateMutation={UPDATE_GIGA_PAN}
      deleteMutation={DELETE_GIGA_PAN}
      refetchQueries={refetchQueries}
    />
  );
}
