import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { FormikTinyMceField } from '~/components/common/FormikField/FormikTinyMceField';

export function PageFormFields() {
  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-8">
          <Field name="title" label="Title" component={FormikField} required />
        </div>
        <div className="col-span-4">
          <Field
            name="pageName"
            label="Internal page name"
            component={FormikField}
            required
          />
        </div>
      </div>

      <Field
        name="content"
        label="Content"
        component={FormikField}
        type={FormikTinyMceField}
        config={{ height: 300 }}
        required
      />

      <Field
        name="secured"
        label="Secured"
        component={FormikField}
        type="checkbox"
      />
    </>
  );
}
