import { gql } from '@apollo/client';

export const GEOLOGY_TYPE_ENUMS = gql`
  query GeologyTypeEnums {
    outcropEnumerations(type: GEOLOGY_TYPE) {
      values
    }
  }
`;

export const DEPOSITIONAL_WIKI_ENUMS = gql`
  query DepositionalWikiEnums($geologyType: GeologyType!) {
    GROSS_DEPOSITIONAL_ENVIRONMENT: wikiEnum(
      geologyType: $geologyType
      enumType: DEPOSITIONAL_GROSS_DEPOSITIONAL_ENVIRONMENT
    ) {
      values
    }
    DEPOSITIONAL_ENVIRONMENT: wikiEnum(
      geologyType: $geologyType
      enumType: DEPOSITIONAL_DEPOSITIONAL_ENVIRONMENT
    ) {
      values
    }
    DEPOSITIONAL_SUB_ENVIRONMENT: wikiEnum(
      geologyType: $geologyType
      enumType: DEPOSITIONAL_DEPOSITIONAL_SUB_ENVIRONMENT
    ) {
      values
    }
    ARCHITECTURAL_ELEMENT: wikiEnum(
      geologyType: $geologyType
      enumType: DEPOSITIONAL_ARCHITECTURAL_ELEMENT
    ) {
      values
    }
  }
`;
