import { gql, useQuery } from '@apollo/client';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import * as fragments from '~/apollo/fragments';
import type {
  RegionPageQuery,
  RegionPageQueryVariables,
} from '~/apollo/generated/schema';
import {
  BookmarkParentType,
  BookmarkTargetType,
  GeoreferenceDataType,
  ReviewCommentParentType,
  Role,
} from '~/apollo/generated/schema';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { Prose } from '~/components/common/Prose';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { RegionOverview } from '~/components/region/RegionOverview';
import type { RegionWithOutline } from '~/components/region/RegionOverview/RegionMap';
import { TargetReviewCommentsModal } from '~/components/reviewComment/TargetReviewCommentsModal';
import { regionRoute, searchRoute, uploadRegionUpdateRoute } from '~/paths';

const REGION_PAGE = gql`
  query RegionPage($id: Int!) {
    regionList(id: $id) {
      ...regionParts
      georeferences {
        ...georeferenceParts
      }
      outcrops {
        ...regionMapOutcropParts
      }
    }

    allRegions: regionList {
      id
      name
      georeferences {
        ...georeferenceParts
      }
      outcrops {
        ...regionMapOutcropParts
      }
    }
  }

  fragment regionMapOutcropParts on Outcrop {
    id
    name
    geologyType
    outcropCategory
    center {
      lat
      lng
    }
  }

  ${fragments.regionParts}
  ${fragments.georeferenceParts}
`;

function allRegionsWithOutlines(
  regionId: number,
  data: RegionPageQuery['allRegions'],
) {
  return (
    data
      // Filter out the current region so it isn't duplicated in the georeferences
      .filter(r => r.id !== regionId)
      .reduce<Array<RegionWithOutline>>((acc, cur) => {
        const outline = cur.georeferences.find(
          g => g.dataType === GeoreferenceDataType.Outline,
        );

        if (!outline) return acc;

        const outlinedRegion: RegionWithOutline = { ...cur, outline };
        acc.push(outlinedRegion);
        return acc;
      }, [])
  );
}

export default function RegionDetailRoute() {
  const params = useParams();
  if (!params.regionId) throw new Error('regionId param required');
  const regionId = parseInt(params.regionId);

  const { data, loading } = useQuery<RegionPageQuery, RegionPageQueryVariables>(
    REGION_PAGE,
    { variables: { id: regionId } },
  );

  const region = data?.regionList.find(r => r.id === regionId);

  useBreadcrumb(
    'routes/region.$regionId',
    region?.name ?? 'Region Detail',
    null,
    [
      {
        routeId: searchRoute(),
        pathname: searchRoute(),
        title: 'Search Analogues',
      },
    ],
  );

  const locationName = (): string | null => {
    if (!region) return null;
    if (region.location.location) {
      return `${region.location.location}, ${region.location.country}`;
    } else {
      return region.location.country;
    }
  };

  const regionOutline = region?.georeferences.find(
    g => g.dataType === GeoreferenceDataType.Outline,
  );

  const allRegions = allRegionsWithOutlines(regionId, data?.allRegions ?? []);

  if (loading) return <SpinnerPlaceholder />;
  if (!region) return <NotFound />;

  return (
    <>
      <div className="float-right space-x-1">
        <TargetBookmarksManagerModal
          parentType={BookmarkParentType.Region}
          parentId={regionId}
          targetType={BookmarkTargetType.Region}
          targetId={regionId}
          path={regionRoute(regionId)}
        />

        <TargetReviewCommentsModal
          parentType={ReviewCommentParentType.Region}
          parentId={region.id}
          name={region.name}
        />

        <RoleSecured roles={[Role.RoleAdmin]}>
          <Link
            to={uploadRegionUpdateRoute(region.id)}
            target="_blank"
            className="btn btn-ghost btn-sm gap-1"
          >
            <FontAwesomeIcon icon={faPencil} /> Edit Region
          </Link>
        </RoleSecured>
      </div>

      <div className="space-y-2">
        <div>
          <PageHeading hasSubtitle>{region.name}</PageHeading>
          <PageHeading.Subtitle>{locationName()}</PageHeading.Subtitle>
        </div>

        <div>
          <Prose dangerouslySetInnerHTML={{ __html: region.description }} />
        </div>

        {region.basins && (
          <div>
            <Heading level={3}>Basins</Heading>
            <Prose dangerouslySetInnerHTML={{ __html: region.basins }} />
          </div>
        )}

        <div>
          <Heading level={3}>Outcrops</Heading>
          <RegionOverview
            region={region}
            regionOutline={regionOutline}
            outcrops={region.outcrops}
            allRegions={allRegions}
          />
        </div>
      </div>
    </>
  );
}
