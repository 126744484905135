import type { PureQueryOptions } from '@apollo/client';
import { useMutation , gql } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';

import type {
  DeleteLithostratOutcropLinkMutation,
  DeleteLithostratOutcropLinkMutationVariables,
  UploadOutcropLithostratigraphyTabQuery,
} from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';
import { SortTrigger } from '~/components/common/SortTrigger';
import { useSortFilter } from '~/hooks/data';

const DELETE_LITHOSTRAT_OUTCROP_LINK = gql`
  mutation DeleteLithostratOutcropLink($id: Int!) {
    deleteLithostratOutcropLink(id: $id)
  }
`;

type LinkType =
  UploadOutcropLithostratigraphyTabQuery['outcropList'][number]['lithostratOutcropLinks'][number];

type Props = {
  links: LinkType[];
  refetchQueries: PureQueryOptions[];
};

export function OutcropLithostratLinksTable({ links, refetchQueries }: Props) {
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    links,
    'targetName',
    'targetName',
    'uploadOCLithostratLinks',
  );

  const [deleteLink, { loading }] = useMutation<
    DeleteLithostratOutcropLinkMutation,
    DeleteLithostratOutcropLinkMutationVariables
  >(DELETE_LITHOSTRAT_OUTCROP_LINK, {
    refetchQueries,
  });

  const handleDelete = (linkId: number) => async () => {
    try {
      await deleteLink({ variables: { id: linkId } });
      toast.success('Link deleted successfully.');
    } catch (err) {
      toast.error('There was a problem deleting the link.');
      console.log('Error deleting link:', err);
    }
  };

  return (
    <table className="table table-compact w-full">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="targetName" sortIndicatorProps={siProps}>
              Name
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="targetType"
              sortIndicatorProps={siProps}
              filterable
            >
              Lithostrat Type
            </SortTrigger>
          </th>
          <th />
        </tr>
      </thead>

      <tbody>
        {items.map(link => (
          <tr key={link.id}>
            <td>{link.targetName}</td>
            <td>{link.targetType}</td>
            <td className="text-right">
              <Confirm
                onConfirm={handleDelete(link.id)}
                title={`Remove ${link.targetType} link`}
                text={`The ${link.targetName} will be removed.`}
              >
                {onConfirm => (
                  <Button
                    type="button"
                    color="ghost"
                    size="xs"
                    onClick={onConfirm}
                    disabled={loading}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                )}
              </Confirm>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
