import type { PureQueryOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import type {
  DeleteVomMutation,
  DeleteVomMutationVariables,
} from '~/apollo/generated/schema';

const DELETE_VOM = gql`
  mutation DeleteVom($vomId: Int!) {
    deleteVirtualOutcropModel(id: $vomId)
  }
`;

type Props = {
  vomId: number;
  refetchQueries: PureQueryOptions[];
  onSuccess?: () => any;
  children: (handleDelete: () => void, loading: boolean) => any;
};

export function DeleteVom({
  vomId,
  refetchQueries,
  onSuccess,
  children,
}: Props) {
  const [deleteVom, { loading }] = useMutation<
    DeleteVomMutation,
    DeleteVomMutationVariables
  >(DELETE_VOM, {
    variables: { vomId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteVom();
      toast.success('VOM successfully deleted.');
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.log('Error deleting vom', err);
      toast.error(
        'There was a problem deleting the VOM. It may have pictures associated with it,or has already been deleted.',
      );
    }
  }

  return children(handleDelete, loading);
}
