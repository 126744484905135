import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { type EditReportPageQuery } from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { ItemFormFieldsBookmark } from '~/components/report/ReportEditor/ItemFormFieldsBookmark';
import { useUpdateReportItemMutation } from '~/components/report/ReportEditor/operations';
import type { ReportItemFormValuesBookmark } from '~/utils/modules/report';
import {
  reportItemBookmarkInitialValues,
  reportItemBookmarkValidationSchema,
} from '~/utils/modules/report';

type Report = EditReportPageQuery['reportList'][number];
type Item = Report['items'][number];

export function EditItemBookmark({
  item,
  bookmark,
  onUpdateSuccess,
}: {
  item: Item;
  bookmark: NonNullable<Item['bookmark']>;
  onUpdateSuccess: () => void;
}) {
  const [updateReportItem, { loading, error }] = useUpdateReportItemMutation();

  async function handleSubmit(values: ReportItemFormValuesBookmark) {
    try {
      await updateReportItem({
        variables: {
          reportItemId: item.id,
          reportItem: {
            bookmark: {
              text: values.text.trim() || null,
              outcropShowDescription: values.outcropShowDescription ?? false,
              outcropShowIntroduction: values.outcropShowIntroduction ?? false,
              outcropShowEnvironments: values.outcropShowEnvironments ?? false,
              outcropShowStratigraphy: values.outcropShowStratigraphy ?? false,
              outcropShowDiagenesisAndPetrophysicalProperties:
                values.outcropShowDiagenesisAndPetrophysicalProperties ?? false,
              outcropShowStructuralGeology:
                values.outcropShowStructuralGeology ?? false,
              outcropShowNotablesAndAnalogues:
                values.outcropShowNotablesAndAnalogues ?? false,
              outcropShowLocation: values.outcropShowLocation ?? false,
              outcropShowSafari: values.outcropShowSafari ?? false,
              outcropShowKeyParameters:
                values.outcropShowKeyParameters ?? false,
              outcropShowPaleogeography:
                values.outcropShowPaleogeography ?? false,
              studyShowAbstract: values.studyShowAbstract ?? false,
            },
          },
        },
      });
      toast.success('Report item updated successfully.');
      onUpdateSuccess();
    } catch (err) {
      console.log('Error updating item', err);
      toast.error(
        'There was a problem updating the report item; please try again.',
      );
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={reportItemBookmarkInitialValues(item, bookmark)}
      validationSchema={reportItemBookmarkValidationSchema}
    >
      <Form>
        <div className="space-y-4">
          <ItemFormFieldsBookmark bookmark={bookmark} />
          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
