import { faMap, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

export function CoordinatesMapPreview({
  location,
}: {
  location: google.maps.LatLngLiteral | null;
}) {
  if (!location) {
    return (
      <div className="w-48 h-36 bg-slate-100 rounded-md flex items-center justify-center">
        <FontAwesomeIcon
          icon={faMap}
          className="text-5xl text-slate-300 ml-4"
        />
        <FontAwesomeIcon
          icon={faQuestionCircle}
          className="text-3xl text-slate-400 -ml-5 -mt-10"
        />
      </div>
    );
  }

  return (
    <GoogleMap
      center={location}
      zoom={10}
      mapContainerClassName="w-48 h-36 rounded-md"
      options={{
        fullscreenControl: false,
        zoomControl: false,
        mapTypeId: google.maps.MapTypeId.TERRAIN,
      }}
    >
      <MarkerF position={location} />
    </GoogleMap>
  );
}
