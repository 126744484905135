import { faPencil, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { useSortFilter } from '~/hooks/data';
import { useQueryString } from '~/hooks/routing';
import {
  uploadLithostratFormationCreateRoute,
  uploadLithostratFormationUpdateRoute,
} from '~/paths';
import { mostSpecificAge } from '~/utils/modules/lithostratAge';
import { truncate } from '~/utils/text';
import { useGroupUpdateOutletContext } from '~/routes/upload/lithostrat/group/$groupId';

export default function GroupUpdateFormationsRoute() {
  const { group } = useGroupUpdateOutletContext();
  const formations = group.formations;

  const { stringify } = useQueryString();
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    formations,
    'name',
    'name',
    'uploadGroupList',
  );

  return (
    <Panel>
      <Panel.Heading className="flex justify-between items-center">
        <Panel.Title>Formations</Panel.Title>

        <Link
          to={{
            pathname: uploadLithostratFormationCreateRoute(),
            search: stringify({ groupId: group.id }),
          }}
          className="btn btn-primary btn-xs gap-1"
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Create Formation
        </Link>
      </Panel.Heading>

      <Panel.Body>
        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>
                <SortTrigger colName="id" sortIndicatorProps={siProps}>
                  ID
                </SortTrigger>
              </th>
              <th>
                <SortTrigger colName="name" sortIndicatorProps={siProps}>
                  Name
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="lithostratType.name"
                  sortIndicatorProps={siProps}
                >
                  Type
                </SortTrigger>
              </th>
              <th>Description</th>
              <th>Start Age</th>
              <th className="text-center">
                <SortTrigger
                  colName="lithostratOrder"
                  sortIndicatorProps={siProps}
                >
                  Order
                </SortTrigger>
              </th>
              <th className="text-center">
                <SortTrigger
                  colName="wikiPage.published"
                  sortIndicatorProps={siProps}
                  filterable
                >
                  Published
                </SortTrigger>
              </th>
              <th />
            </tr>
          </thead>

          <tbody>
            {items.map(formation => (
              <tr key={formation.id}>
                <td>{formation.id}</td>
                <td>{formation.name}</td>
                <td>{formation.lithostratType?.name}</td>
                <td>{truncate(70)(formation.description ?? '')}</td>
                <td>
                  {formation.startAge
                    ? mostSpecificAge(formation.startAge)
                    : ''}
                </td>
                <td className="text-center">{formation.lithostratOrder}</td>
                <td className="text-center">
                  <EnabledIndicator value={!!formation.wikiPage?.published} />
                </td>
                <td className="w-1/12 text-right">
                  <Link
                    to={uploadLithostratFormationUpdateRoute(formation.id)}
                    target="_blank"
                    className="btn btn-sm btn-ghost gap-1"
                  >
                    <FontAwesomeIcon icon={faPencil} /> Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
}
