import type { ReportDisplayReportItem } from '~/components/report/ReportDisplay/ReportItem';
import { GeologyWikiOverview } from '~/components/wiki/GeologyWikiOverview';

type Props = {
  geologyWiki: Extract<
    NonNullable<ReportDisplayReportItem['bookmark']>['targetDepositionalWiki'],
    { __typename?: 'DepositionalWiki' }
  >;
};

export function ReportItemGeologyWiki({ geologyWiki }: Props) {
  return <GeologyWikiOverview wikiPage={geologyWiki} />;
}
