import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';
import { faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from 'react-daisyui';

import * as fragments from '~/apollo/fragments';
import type {
  UploadLithostratTypesPageQuery,
  UploadLithostratTypesPageQueryVariables,
} from '~/apollo/generated/schema';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreateLithostratTypeModal } from '~/components/upload/lithostratType/CreateLithostratTypeModal';
import { useSortFilter } from '~/hooks/data';
import { uploadLithostratTypeUpdateRoute } from '~/paths';

const UPLOAD_LITHOSTRAT_TYPES_PAGE = gql`
  query UploadLithostratTypesPage {
    lithostratTypeList {
      ...lithostratTypeParts
      diagram {
        ...diagramParts
      }
    }
  }

  ${fragments.lithostratTypeParts}
  ${fragments.diagramParts}
`;

type AugmentedLithostratType =
  UploadLithostratTypesPageQuery['lithostratTypeList'][number] & {
    hasDiagram: boolean;
  };

export default function UploadLithostratTypesRoute() {
  const { data, loading } = useQuery<
    UploadLithostratTypesPageQuery,
    UploadLithostratTypesPageQueryVariables
  >(UPLOAD_LITHOSTRAT_TYPES_PAGE, {});

  const refetchQueries: [
    PureQueryOptions<UploadLithostratTypesPageQueryVariables>,
  ] = [{ query: UPLOAD_LITHOSTRAT_TYPES_PAGE }];

  const lts: AugmentedLithostratType[] = (data?.lithostratTypeList ?? []).map(
    lt => ({ ...lt, hasDiagram: lt.diagram !== null }),
  );
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    lts,
    'name',
    'name',
    'uploadLithostratTypes',
  );

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <PageHeading>Lithostrat Types</PageHeading>

      <Panel>
        <Panel.Heading className="flex justify-between items-center">
          <Panel.Title>Lithostrat Types</Panel.Title>
          <CreateLithostratTypeModal refetchQueries={refetchQueries}>
            {showModal => (
              <Button
                type="button"
                color="primary"
                size="xs"
                onClick={showModal}
                className="gap-1"
              >
                <FontAwesomeIcon icon={faPlus} /> Create Type
              </Button>
            )}
          </CreateLithostratTypeModal>
        </Panel.Heading>

        <Panel.Body>
          <table className="table table-compact w-full">
            <thead>
              <tr>
                <th>
                  <SortTrigger colName="id" sortIndicatorProps={siProps}>
                    ID
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger colName="name" sortIndicatorProps={siProps}>
                    Name
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="description"
                    sortIndicatorProps={siProps}
                  >
                    Description
                  </SortTrigger>
                </th>
                <th className="text-center">
                  <SortTrigger
                    colName="hasDiagram"
                    sortIndicatorProps={siProps}
                    filterable
                    renderFilterOption={value =>
                      value === 'true' ? 'Has diagram' : 'No diagram'
                    }
                  >
                    Diagram
                  </SortTrigger>
                </th>
                <th />
              </tr>
            </thead>

            <tbody>
              {items.map(lt => (
                <tr key={lt.id}>
                  <td className="w-1/12">{lt.id}</td>
                  <td className="w-3/12">{lt.name}</td>
                  <td>{lt.description}</td>
                  <td className="text-center">
                    <EnabledIndicator value={lt.hasDiagram} />
                  </td>
                  <td className="text-right w-1/12">
                    <Link
                      to={uploadLithostratTypeUpdateRoute(lt.id)}
                      className="btn btn-ghost btn-sm gap-1"
                    >
                      <FontAwesomeIcon icon={faPencil} /> Edit
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
}
