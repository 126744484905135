import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Footer } from '~/components/layout/Footer';
import { Navbar } from '~/components/layout/Navbar';

export default function V3GeoModelViewerRoute() {
  const params = useParams<{ modelId: string }>();
  if (!params.modelId) throw new Error('modelId param required');
  const modelId = parseInt(params.modelId);

  const iframeSrc = `https://v3geo.com/viewer/index.html#/${modelId}`;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>V3Geo Model Viewer</title>
        </Helmet>

        <Navbar />

        <iframe
          title="V3Geo Model Viewer"
          className="block w-full h-screen"
          // style={{
          //   height: '40vw',
          //   width: '100%',
          //   border: 'none',
          //   marginBottom: '-45px',
          // }}
          src={iframeSrc}
        />

        <Footer />
      </HelmetProvider>
    </>
  );
}
