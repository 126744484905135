import * as R from 'ramda';
import { Link } from 'react-router-dom';
import type { LithostratGroupPageQuery } from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import { KeyParametersPanel } from '~/components/keyParameter/KeyParametersPanel';
import {
  KPCol,
  KPGrid,
} from '~/components/keyParameter/KeyParametersPanel/CombinedView';
import { lithostratFormationRoute } from '~/paths';

type Group = LithostratGroupPageQuery['lithostratGroupList'][number];
type Formation = Group['formations'][number];

type WikiPageLinkProps = {
  formation: Formation;
};
function WikiPageLink({ formation }: WikiPageLinkProps) {
  const wikiPage = R.prop('wikiPage', formation);
  if (!wikiPage || !wikiPage.published) {
    return <div>{formation.name}</div>;
  }

  return (
    <div>
      <Link to={lithostratFormationRoute(formation.id)} className="link">
        {formation.name}
      </Link>
    </div>
  );
}

type Props = {
  group: Group;
};
export function GroupSidebar({ group }: Props) {
  const formations = R.sortBy(
    fm => fm.lithostratOrder ?? Infinity,
    group.formations,
  );

  const keyParameters = group.wikiPage?.keyParameters ?? [];

  const wikiProp = (propName: keyof NonNullable<Group['wikiPage']>) =>
    R.pathOr(null, ['wikiPage', propName], group);

  return (
    <div className="space-y-4">
      <div className="space-y-1">
        <Heading level={4}>Lithostratigraphic Hierarchy</Heading>
        <Panel>
          <Panel.Body>
            <KPGrid>
              <KPCol span={3}>
                <strong>Contains</strong>
              </KPCol>
              <KPCol span={9}>
                {formations.map(f => (
                  <WikiPageLink key={f.id} formation={f} />
                ))}
              </KPCol>
            </KPGrid>
          </Panel.Body>
        </Panel>
      </div>

      <div className="space-y-1">
        <Heading level={4}>Key Parameters</Heading>
        <Panel>
          <Panel.Body>
            <KeyParametersPanel
              keyParameters={keyParameters}
              noHeading
              leftColMd={3}
              rightColMd={9}
            />
          </Panel.Body>
        </Panel>
      </div>

      <div className="space-y-1">
        <Heading level={4}>Location</Heading>
        <Panel>
          <Panel.Body>
            <KPGrid>
              <KPCol span={3}>
                <strong>Country</strong>
              </KPCol>
              <KPCol span={9}>{wikiProp('country')}</KPCol>
            </KPGrid>
            <KPGrid>
              <KPCol span={3}>
                <strong>Region</strong>
              </KPCol>
              <KPCol span={9}>{wikiProp('region')}</KPCol>
            </KPGrid>
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
}
