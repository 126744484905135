import { Editor } from '@tinymce/tinymce-react';
import type { IProps as EditorProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { cn } from '~/utils/common';
import type { FieldProps } from 'formik';
import { ErrorMessage } from 'formik';
import React from 'react';
import { FormLabel } from './FormLabel';

const toolbar = [
  'undo redo',
  'styleselect fontsizeselect forecolor',
  'bold italic underline',
  'justifyleft justifycenter justifyright',
  'bullist numlist',
  'link unlink',
  'image',
  'code removeformat',
];

const defaultConfig: EditorProps['init'] = {
  branding: false,
  promotion: false,
  plugins: 'link table code image',
  paste_as_text: true,
  toolbar: toolbar.join(' | '),
  convert_urls: false,
  browser_spellcheck: true,
  contextmenu: false,
};

type Props = FieldProps & {
  isInvalid?: boolean;
  label?: React.ReactNode;
  renderInput?: (input: JSX.Element) => JSX.Element;
  helpText?: string;
  config?: EditorProps['init'];
  required?: boolean;
};

export const FormikTinyMceField: React.FC<Props> = ({
  form,
  field,
  isInvalid,
  label = null,
  renderInput,
  helpText,
  config,
  required = false,
}) => {
  const tinyMceConfig: EditorProps['init'] = {
    ...defaultConfig,
    ...config,
  };

  const input = (
    <Editor
      // apiKey="ba3lpruzbaxqh33vt7kx84joq8x2i3p7zto3iuve2o1e09ic"
      licenseKey="gpl"
      plugins={tinyMceConfig.plugins}
      initialValue={field.value}
      onBlur={e => form.setFieldValue(field.name, e.target.getContent())}
      init={tinyMceConfig}
    />
  );

  return (
    <div className={cn('form-control')}>
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        required={required}
      />
      {renderInput ? renderInput(input) : input}
      {isInvalid && (
        <label className="label">
          <span className="label-text-alt text-error">
            <ErrorMessage name={field.name} />
          </span>
        </label>
      )}
    </div>
  );
};

export class TinyMceFieldWrapper extends React.Component<Props> {
  state = {
    caught: false,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.log('Caught an error in TinyMCE', error);
    console.log('Error info:', errorInfo);
    this.setState({ caught: true });
  }

  render() {
    if (this.state.caught) {
      return (
        <div>
          There was a problem rendering this field, please reload the page.
        </div>
      );
    }

    return <FormikTinyMceField {...this.props} />;
  }
}
