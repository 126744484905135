import { Field } from 'formik';
import type { StudyEnumerationsQuery } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';

type Props = {
  enumOptions: StudyEnumerationsQuery;
  disabled?: boolean;
};

export function StudyQualityParametersFormFields({
  enumOptions,
  disabled = false,
}: Props) {
  type EnumFieldKey = keyof Omit<StudyEnumerationsQuery, '__typename'>;
  function options(fieldName: EnumFieldKey) {
    const values = enumOptions[fieldName]?.values ?? [];
    return values.map(opt => ({ value: opt, label: opt }));
  }

  return (
    <>
      <Field
        name={'qualityParameters.dataAcquisitionMethod'}
        label="Data Acquisition Method"
        component={FormikField}
        type="select"
        helpText="Select all that are appropriate."
        options={options('qpDataAcquisitionMethod')}
        disabled={disabled}
        multiple
      />
      <Field
        name={'qualityParameters.spatialObservationType'}
        label="Spatial Observation Type"
        component={FormikField}
        type="select"
        helpText="Select appropriate."
        options={options('qpSpatialObservationType')}
        disabled={disabled}
      />
      <Field
        name={'qualityParameters.publicationType'}
        label="Publication Type"
        component={FormikField}
        type="select"
        helpText="Select how the data from this study has been published."
        options={options('qpPublicationType')}
        disabled={disabled}
        multiple
        required
      />
    </>
  );
}
