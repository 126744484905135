import { Outlet } from 'react-router-dom';
import { AppShell } from '~/components/layout/AppShell';

export function AppShellRoute() {
  return (
    <AppShell>
      <Outlet />
    </AppShell>
  );
}
