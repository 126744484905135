import { Outlet } from 'react-router-dom';
import { PageHeading } from '~/components/common/PageHeading';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import * as routes from '~/paths';

export default function UploadModelPage() {
  useBreadcrumb('routes/upload/model/__uploadModelsPage', 'Model Data');

  return (
    <>
      <PageHeading>Model Data</PageHeading>

      <NavTabs className="mb-2">
        <NavTabs.Tab to={routes.uploadProjectsRoute()}>Projects</NavTabs.Tab>
        <NavTabs.Tab to={routes.uploadRegionsRoute()}>Regions</NavTabs.Tab>
        <NavTabs.Tab to={routes.uploadOutcropsRoute()}>Outcrops</NavTabs.Tab>
        <NavTabs.Tab to={routes.uploadStudiesRoute()}>Studies</NavTabs.Tab>
      </NavTabs>

      <Outlet />
    </>
  );
}
