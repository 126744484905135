import { useQuery , gql } from '@apollo/client';
import { Field, useFormikContext } from 'formik';
import * as R from 'ramda';
import { useEffect, useMemo } from 'react';

import * as fragments from '~/apollo/fragments';
import type { StudyLithostratLinkOutcropSelectorQuery } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import type { LithostratStudyLinkFormValues } from '~/components/upload/lithostrat/examples/CreateLithostratStudyLink';
import { OutcropSelect } from '~/components/upload/outcrop/OutcropSelect';

const OUTCROP_LIST = gql`
  query StudyLithostratLinkOutcropSelector {
    outcropList {
      ...outcropParts
      studies {
        ...studyParts
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.studyParts}
`;

export function StudyLinkFormFields() {
  const { data, loading } = useQuery<StudyLithostratLinkOutcropSelectorQuery>(
    OUTCROP_LIST,
    {},
  );
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<LithostratStudyLinkFormValues>();
  const outcropId = values.outcropId ? ~~values.outcropId : null;

  // Reset parentId value if the outcrop id changes
  useEffect(() => {
    setFieldValue('parentId', '');
    setFieldTouched('parentId', false, false);
  }, [outcropId, setFieldTouched, setFieldValue]);

  const outcropStudies = useMemo(() => {
    if (!outcropId) return [];

    const outcropList = data?.outcropList ?? [];
    const studies = outcropList.find(oc => oc.id === outcropId)?.studies ?? [];

    return R.sortBy(R.prop('name'))(studies);
  }, [data?.outcropList, outcropId]);

  return (
    <div className="space-y-2">
      <Field
        name="outcropId"
        label="Outcrop"
        component={FormikField}
        type={OutcropSelect}
        disabled={loading}
        required
      />

      <Field
        name="parentId"
        label="Study"
        component={FormikField}
        type="select"
        options={outcropStudies.map(s => ({ value: s.id, label: s.name }))}
        disabled={loading || !values.outcropId}
        required
      />
    </div>
  );
}
